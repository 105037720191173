import { Button, Col, Pagination, Row, Select, Space } from "antd";
import React from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { ITablePaginationProps } from "./Table.d";
import { DEFAULT_PAGE_SIZE_OPTIONS } from "./constants";
import "./TablePaginationV3.scss";

const TablePaginationV3: React.FC<ITablePaginationProps & { pageItemSize: number }> = ({
  onPaginationChanged,
  pagination,
  pageItemSize,
}) => {
  return (
    <Row justify="space-between" align="middle" className="p-s bg-white">
      <Col>
        <Space>
          <span>
            {pagination.current} - {pageItemSize} of {pagination.total}
          </span>
          <span className="ml-s">Rows per page</span>
          <Select
            data-testid="page-size-selector"
            value={pagination.pageSize}
            onSelect={(pgSz) => onPaginationChanged({ ...pagination, pageSize: pgSz, current: 1 })}
            options={DEFAULT_PAGE_SIZE_OPTIONS.map((pgSz) => ({ value: pgSz, label: pgSz }))}
          />
        </Space>
      </Col>
      <Col>
        <Pagination
          prevIcon={
            <Button type="link">
              <CaretLeftOutlined />
            </Button>
          }
          nextIcon={
            <Button type="link">
              <CaretRightOutlined />
            </Button>
          }
          total={pagination.total}
          onChange={(page) => {
            window.scrollTo(0, 0);
            onPaginationChanged({ ...pagination, current: page });
          }}
          showSizeChanger={false}
          current={pagination.current}
          pageSize={pagination.pageSize}
          className="pagination-v3"
        />
      </Col>
    </Row>
  );
};

export default TablePaginationV3;
