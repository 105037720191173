import React, { useCallback, useMemo, useState } from "react";
import { Tabs, message } from "antd";

import "./JobChecklist.scss";
import {
  GetWorkInfoQuery,
  useStartTestingMutation,
  useRoomNodeStatusSubscription,
  useKeyEntryDetailSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { CheckCircleFilled } from "@ant-design/icons";
import groupBy from "lodash/groupBy";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { PositionFunction, TestRun } from "pages/Checklist/Checklist.d";
import { NodeHealth } from "pages/Node/ContractorNodeList/ContractorNodeList.d";
import Loading from "components/Loading";
import { moveToTheEnd } from "utils/functional";
import useInstallationMode from "hooks/use-installation-mode";
import { TaskParentEntityType, TaskStatus } from "../KeyEntryJobChecklist.d";
import { JobCheckListProps } from "./JobChecklist.d";
import { ETaskType } from "../types";
import TaskCard from "./TaskCard";
import { TaskTestCard } from "./TaskTestCard";
import { toTestRuns } from "./helpers";

const JobChecklist: React.FC<JobCheckListProps> = ({
  roomList = [],
  data = [],
  splitIntoRooms,
  keyId,
  isCompressor,
  infraData,
  keyType,
  workInfoLoading,
  isCurrentKeyEntry,
  reloadInfra,
}: JobCheckListProps) => {
  const [nodeHealthList, setNodeHealthList] = useState<NodeHealth[]>([]);
  const { currentKeyEntryOfInstaller: { keyEntryId: currentKeyEntryId } = {} } = useInstallationMode();
  const [testRuns, setTestRuns] = useState<TestRun[]>([]);

  useKeyEntryDetailSubscription({
    skip: !keyId,
    variables: {
      where: {
        keyPositionId: {
          _eq: keyId,
        },
        positionFunction: {
          _eq:
            isCompressor || keyType === TaskParentEntityType.COMPRESSOR
              ? PositionFunction.COMPRESSOR
              : PositionFunction.AUTOSET,
        },
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        const newTestRuns = toTestRuns(subscriptionData.data);
        if (newTestRuns) {
          setTestRuns(newTestRuns);
        }
      }
    },
  });

  useRoomNodeStatusSubscription({
    skip: !keyId && !splitIntoRooms,
    variables: {
      keyId: keyId || "",
      filterSlotMappings: {
        decomissionedTime: {
          _is_null: true,
        },
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const { data: { positions = [] } = {} } = subscriptionData;
      if (!positions.length) return;

      const mapNodeHealthList: NodeHealth[] = [];

      positions.forEach((position) => {
        const formatSlot = position.slotMappings.map((x) => ({
          slotId: x.slot?.id,
          nodeStatus: x.node.nodeOnlineStatus?.nodeStatus || "",
          nodeMacId: x.node.node_mac_id,
          nodeStatusInInstallationMode: x.node.nodeOnlineStatus?.nodeStatusInInstallationMode || "",
          type: x.node.node_type,
          subType: x.node.nodeSubType,
        }));
        mapNodeHealthList.push(...formatSlot);
      });
      setNodeHealthList(mapNodeHealthList);
    },
  });

  const tabRoomLabel = useCallback((roomName: string, isDone: boolean) => {
    return (
      <>
        {roomName}
        {isDone && <CheckCircleFilled className="text-green ml-xs" />}
      </>
    );
  }, []);

  const [startTesting] = useStartTestingMutation({
    onCompleted: () => {
      message.success("Start testing room successfully.");
    },
  });

  const handleTestFunctionClicked = useCallback(
    async (
      keyPositionId: string,
      positionFunction: string,
      isMeterPosition?: boolean,
      resumePreviousSession?: boolean
    ) => {
      await startTesting({
        variables: {
          keyPositionId,
          positionFunction,
          resumePreviousSession,
          isMeterPosition,
        },
      });
    },
    [startTesting]
  );

  const tasks = useCallback(
    (taskList: GetWorkInfoQuery["tasksWithKeys"], testRunsByRoom?: TestRun[]) => {
      if (workInfoLoading) {
        return <Loading className="h-auto my-l" />;
      }

      const filteredTaskList = splitIntoRooms
        ? taskList.map((task: any) => ({ task, count: 1 }))
        : // group tasks by node type for installation tasks
          Object.values(
            groupBy(taskList, (task) => {
              return task.taskType === ETaskType.Installation
                ? getNodeTypeDisplayValue(task.nodeType || "")
                : task.title;
            })
          ).map((taskGroup) => ({ task: taskGroup[0], count: taskGroup.length }));

      const testIndex = filteredTaskList.findIndex(({ task }) => task.taskType === ETaskType.Test);
      return (
        testIndex > -1 ? moveToTheEnd(filteredTaskList, testIndex, filteredTaskList.length) : filteredTaskList
      ).map(({ task, count }) => {
        if (task.taskType === ETaskType.Test)
          return (
            <TaskTestCard
              key={task.taskId}
              handleTestFunctionClicked={handleTestFunctionClicked}
              keyPositionId={isCompressor || keyType === TaskParentEntityType.COMPRESSOR ? keyId : task.roomId}
              positionFunction={
                isCompressor || keyType === TaskParentEntityType.COMPRESSOR
                  ? PositionFunction.COMPRESSOR
                  : PositionFunction.AUTOSET
              }
              isMeterPosition={!isCompressor && keyType === TaskParentEntityType.COMPRESSOR}
              task={task}
              testRuns={testRunsByRoom || []}
              splitIntoRooms={splitIntoRooms}
              isCompressor={isCompressor}
              isCurrentKeyEntry={isCurrentKeyEntry}
            />
          );
        return (
          <TaskCard
            currentKeyEntryId={currentKeyEntryId}
            nodeHealthList={nodeHealthList}
            reloadInfra={reloadInfra}
            task={task}
            infraData={infraData}
            isCompressor={isCompressor}
            splitIntoRooms={splitIntoRooms}
            count={count}
            key={task.taskId}
            isCurrentKeyEntry={isCurrentKeyEntry}
          />
        );
      });
    },
    [
      keyId,
      splitIntoRooms,
      isCompressor,
      infraData,
      workInfoLoading,
      isCurrentKeyEntry,
      keyType,
      currentKeyEntryId,
      nodeHealthList,
      reloadInfra,
      handleTestFunctionClicked,
    ]
  );

  const tabPane = useMemo(
    () =>
      roomList.map((room) => {
        const tasksByRoom = data.filter((task: { roomId: any }) => task.roomId === room.positionId);
        const countTaskNotDone = tasksByRoom.filter(
          (task: { status: TaskStatus }) => task.status !== TaskStatus.DONE
        ).length;
        const testRunsByRoom = testRuns.filter((test) => test.positionId === room.positionId);
        return (
          <Tabs.TabPane key={room.positionId} tab={tabRoomLabel(room.positionName, countTaskNotDone === 0)}>
            <div className="room-content">{tasks(tasksByRoom, testRunsByRoom)}</div>
          </Tabs.TabPane>
        );
      }),
    [data, roomList, tasks, tabRoomLabel, testRuns]
  );

  if (keyType === TaskParentEntityType.KEY && splitIntoRooms && !isCompressor) {
    return <Tabs className="room-tabs">{tabPane}</Tabs>;
  }

  return (
    <div className="mx-m">
      <h2 className="font-weight-bold text-primary">Work info</h2>
      <div className="room-content">{tasks(data, testRuns)}</div>
    </div>
  );
};

export default JobChecklist;
