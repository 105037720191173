import React from "react";
import { Tabs } from "antd";
import InsightsDashboardDetail from "../InsightsDasboardDetail/InsightsDasboardDetail";
import { DomoEmbed } from "../Analytics.d";

type InsightsDashboardListProps = {
  locationId: string;
  items: DomoEmbed[];
};

const InsightsDashboardList = ({ locationId, items }: InsightsDashboardListProps) => {
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="top"
      style={{ width: "100%", fontFamily: "Open Sans Condensed" }}
      items={items.map(({ name, embed_id }) => {
        return {
          label: `${name}`,
          key: `${embed_id}`,
          children: <InsightsDashboardDetail embedId={embed_id} locationId={locationId} />,
        };
      })}
    />
  );
};

export default InsightsDashboardList;
