import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { uniq } from "lodash";
import {
  useClaimKeyMutation,
  useGetEntryHasUnResolvedCommentLazyQuery,
  useUnClaimKeyMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import classNames from "classnames";
import errorHandler from "errorHandler";
import { CommentModal, CommentModalProps } from "components/InstallationMode/CommentModal";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { KeyEntryCommentStatus, PositionFunction } from "types/InstallationMode.d";
import { TaskParentEntityType } from "pages/KeyEntryJobChecklist";
import { useAuth0 } from "services/auth/authService";
import { ClaimKeyButtonProps, JobStatus } from "./ClaimKeyButton.d";

const ClaimKeyButton: React.FC<ClaimKeyButtonProps> = ({
  status,
  keyPositionId,
  possitionType,
  className,
  assigneeId,
}) => {
  const { isLoading, setKeyPositionIds, userId } = useContext(InstallationModeContext);
  const [openModal, setOpenModal] = useState(false);
  const { user } = useAuth0();
  const [modalComment, setModalComment] = useState<Pick<CommentModalProps, "comment" | "keyEntryId" | "open">>({
    open: false,
    comment: "",
  });

  const positionFunction = useMemo(() => {
    switch (possitionType) {
      case TaskParentEntityType.COMPRESSOR:
        return PositionFunction.COMPRESSOR;
      case TaskParentEntityType.GATEWAY:
        return PositionFunction.GATEWAY;
      default:
        return PositionFunction.AUTOSET;
    }
  }, [possitionType]);

  const [claimKey, { loading: isClaimKeyLoading }] = useClaimKeyMutation({
    variables: {
      keyPositionId,
      userId,
      positionFunction,
    },
    onError: errorHandler.handleError,
  });

  const [unClaimKey, { loading: isUnClaimKeyLoading }] = useUnClaimKeyMutation({
    variables: { keyPositionId, userId },
    onError: errorHandler.handleError,
  });

  const [getEntryHasUnResolved, { loading: isGetEntryHasUnResolved }] = useGetEntryHasUnResolvedCommentLazyQuery({
    variables: { keyPositionId },
    onCompleted(data) {
      if (!data?.keyEntries[0] || data?.keyEntries[0]?.commentStatus === KeyEntryCommentStatus.RESOLVED) {
        if (assigneeId) setOpenModal(true);
        else claimKey();
      } else {
        setModalComment({
          open: true,
          comment: data?.keyEntries[0]?.comment || "",
          keyEntryId: data?.keyEntries[0]?.keyEntryId,
        });
      }
    },
  });

  useEffect(() => {
    if (setKeyPositionIds) {
      setKeyPositionIds((prev) => uniq([...prev, keyPositionId]));
    }
  }, [keyPositionId, setKeyPositionIds]);

  const isUnClaimKey = useMemo(() => assigneeId === user.sub, [assigneeId, user]);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setModalComment({});
  }, [setOpenModal]);

  const handleOpen = useCallback(async () => {
    if (isUnClaimKey) setOpenModal(true);
    else getEntryHasUnResolved({ variables: { keyPositionId } });
  }, [setOpenModal, getEntryHasUnResolved, isUnClaimKey, keyPositionId]);

  const handleConfirm = useCallback(async () => {
    if (isUnClaimKey) await unClaimKey();
    else await claimKey();
    handleClose();
  }, [handleClose, claimKey, unClaimKey, isUnClaimKey]);

  return (
    <>
      <Button
        className={classNames("font-weight-bold claim-key-button w-100", className, {
          "text-primary": !(status?.[1] === JobStatus.PROCESSING || isLoading || isGetEntryHasUnResolved),
          "un-claim": isUnClaimKey,
        })}
        disabled={status?.[1] === JobStatus.PROCESSING || (isUnClaimKey && status?.[1] === JobStatus.ERROR)}
        loading={isLoading || isGetEntryHasUnResolved}
        onClick={handleOpen}
      >
        {isUnClaimKey ? "Un-claim" : "Claim key"}
      </Button>
      <Modal
        title={`${isUnClaimKey ? "Un-claim" : "Claim"} a key entry`}
        centered
        footer={null}
        onCancel={() => setOpenModal(false)}
        width={400}
        open={openModal}
      >
        <Typography.Text>
          {isUnClaimKey ? (
            <>
              When un-claiming a key, the key will be returned to the previously assigned person.
              <br />
              Do you want to un-claim this key entry?
            </>
          ) : (
            <>
              This key has been assigned to another one.
              <br />
              Do you want to claim this key entry?
            </>
          )}
        </Typography.Text>

        <Row gutter={[16, 16]} className="mt-xl">
          <Col span={12}>
            <Button
              type="default"
              className="text-primary w-100"
              onClick={handleClose}
              disabled={isClaimKeyLoading || isUnClaimKeyLoading}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              className="ant-btn ant-btn-primary w-100"
              onClick={handleConfirm}
              loading={isClaimKeyLoading || isUnClaimKeyLoading}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>

      <CommentModal
        open={modalComment?.open}
        comment={modalComment?.comment}
        keyEntryId={modalComment?.keyEntryId}
        onClose={handleClose}
        onResolved={() => {
          setModalComment({});
          setOpenModal(true);
        }}
      />
    </>
  );
};

export default ClaimKeyButton;
