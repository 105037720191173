import React from "react";

import { Button, Col, Input, Row } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { ACTUATOR, Category, Room } from "./DataTypes";
import RoomDetail from "./RoomDetail";
import "./CategoryDetail.scss";

const DEFAULT_LIMIT_ROOMS = 9;

type CategoryDetailProps = {
  category?: Category;
  onSessionChangeCallback: () => void;
};

const CategoryDetail = ({ category, onSessionChangeCallback }: CategoryDetailProps) => {
  if (!category) return null;

  const reachedTheLimit = category.rooms.length === DEFAULT_LIMIT_ROOMS;

  const handleRoomAdd = () => {
    if (!reachedTheLimit) {
      category.addRoom(`Room ${category!.rooms.length + 1}`, ACTUATOR.ACIR);
      onSessionChangeCallback();
    }
  };

  return (
    <div>
      <div>
        <p>Category Name</p>
        <Row className="input-field with-action">
          <Col flex="auto">
            <Input placeholder="Category Name" disabled value={category.name} />
          </Col>
          <Col className="action-icon disable hide">
            <CopyOutlined />
          </Col>

          <Col className="action-icon disable hide">
            <DeleteOutlined />
          </Col>
        </Row>
      </div>

      <Button type="primary" className="add-button" onClick={handleRoomAdd} disabled={reachedTheLimit}>
        Add Room
      </Button>

      {category.rooms.map((room: Room) => (
        <RoomDetail key={room.id} room={room} onSessionChangeCallback={onSessionChangeCallback} />
      ))}
    </div>
  );
};

export default CategoryDetail;
