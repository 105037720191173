import moment from "moment";
import React from "react";
import { useStopwatch } from "react-timer-hook";

const StopWatch = (props: { startTime: any }) => {
  const { startTime } = props;
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(
    stopwatchOffset.getSeconds() + (stopwatchOffset.getTime() - moment(startTime).toDate().getTime()) / 1000
  );
  const { seconds, minutes, hours, days } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });

  const renderTime = () => {
    let dayString = "";
    let hourString = "";
    if (days > 0) {
      dayString = `${String(days).padStart(2, "0")}:`;
    }
    if (hours > 0) {
      hourString = `${String(hours).padStart(2, "0")}:`;
    }

    return `${dayString}${hourString}${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <span className="ml-s font-weight-semi-bold text-gray" style={{ fontSize: "larger" }}>
      <span>{renderTime()}</span>
    </span>
  );
};

export default StopWatch;
