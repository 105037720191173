import { Permission } from "pacts/permission";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useBreakpoint from "hooks/use-breakpoint";

export default function LocationActions() {
  const roleAndPermission = useRoleAndPermission();
  const breakPoint = useBreakpoint();

  const viewAs = (permission: Permission) => {
    return !roleAndPermission.isContractor() && roleAndPermission.canPerform(permission);
  };

  const actions = (locationId: string) => [
    {
      name: "Keys",
      linkTo: `/locations/${locationId}/keys`,
      isVisible: viewAs(Permission.LOCATION_VIEW),
    },
    {
      name: "Jobs",
      linkTo: `/locations/${locationId}/jobs`,
      isVisible: breakPoint.mobileOnly && roleAndPermission.isContractor(),
    },
    {
      name: "Gateways",
      linkTo: `/locations/${locationId}/gateways`,
      isVisible: roleAndPermission.canPerform(Permission.LOCATION_VIEW) && !roleAndPermission.isCustomer(),
    },
    {
      name: "Spare nodes",
      linkTo: `/locations/${locationId}/spare-nodes`,
      isVisible: roleAndPermission.isWS() || roleAndPermission.isContractor(),
    },
    {
      name: "Infrastructures",
      linkTo: `/locations/${locationId}/infrastructures`,
      isVisible: viewAs(Permission.INFRASTRUCTURE_VIEW_ALL),
    },
    {
      name: "Nodes",
      linkTo: `/locations/${locationId}/nodes`,
      isVisible:
        roleAndPermission.canPerform(Permission.NODES_LIST) &&
        !roleAndPermission.isCustomer() &&
        !roleAndPermission.isContractor(),
    },
    {
      name: "Configuration",
      linkTo: `/locations/${locationId}/configuration`,
      isVisible: roleAndPermission.canPerform(Permission.LOCATION_VIEW) && roleAndPermission.isCustomer(),
    },
    {
      name: "Energy Consumption",
      linkTo: `/locations/${locationId}/energy-consumption`,
      isVisible: roleAndPermission.isCustomer(),
    },
  ];

  return (locationId: string) => actions(locationId).filter((action) => action.isVisible);
}
