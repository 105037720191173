import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { MappedNodeDataType } from "pages/InfrastructureDetails/InfrastructureDetails.d";
import { NodeInputDataType } from "../NodeInput/NodeInput.d";

export const transformToEditingNode = (data: MappedNodeDataType): NodeInputDataType => {
  const { slotName, nodeType, settings } = data;

  return {
    nodeMacInput: data.nodeMacId,
    slotName,
    nodeType,
    emonSetting: settings,
  };
};

export const transformToUpdateNodeInput = (infraId: string, data: NodeInputDataType) => {
  const { slotName, emonSetting, mapInput, nodeMacInput } = data;

  const response = {
    infraId,
    nodeMacId: nodeMacInput!,
    slotName: slotName!,
  };

  if (data.nodeType === NodeType.Energy)
    return {
      ...response,
      emonSetting,
      mapInput,
    };

  return response;
};
