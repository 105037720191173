import React, { useState } from "react";
import { message, Modal } from "antd";
import useBreakpoint from "hooks/use-breakpoint";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import {
  NodeType,
  useGatewaysQuery,
  useUnmapInfraNodeMutation,
  useUpsertPositionGatewayMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import get from "lodash/get";
import assign from "lodash/assign";
import find from "lodash/find";
import errorHandler from "errorHandler";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import MappedNodesGrid from "./MappedNodesGrid";
import MappedNodesTable from "./MappedNodesTable";
import { MappedNodeDataType, PositionDataType } from "../InfrastructureDetails.d";
import { emonSettings, MappedNodesWrapperProps } from "./MappedNodes.d";
import EditNodeModal from "./EditNodeModal/EditNodeModal";
import showModal from "../../../components/Modal/show";
import SelectGatewayModal from "../../Key/KeyDetail/SelectGatewayModal";
import useSensorflowLocation from "../../../hooks/use-sensorflow-location";

const MappedNodes = ({ dataSource, infraId, reloadInfrastructure }: MappedNodesWrapperProps) => {
  const screen = useBreakpoint();
  const { locationId } = useSensorflowLocation();

  const [editNode, setEditNode] = useState<MappedNodeDataType>();
  const [unmappingNodeId, setUnmappingNodeId] = useState<string>();
  const [gateways, setGateways] = useState<any[]>([]);

  useGatewaysQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setGateways(data.gateways);
    },
  });

  const [editNodeModalRef, openEditNodeModal] = useModalWrapperTrigger();

  const [unmapInfraNode] = useUnmapInfraNodeMutation({
    onCompleted() {
      message.success(`${unmappingNodeId} Unmapped Success`);
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  const [upsertPositionGatewayMutation] = useUpsertPositionGatewayMutation({
    onCompleted: () => {
      message.success("Gateway has been updated.");
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  const handleUnmapNode = (nodeId: string) => {
    setUnmappingNodeId(nodeId);

    Modal.confirm({
      title: `Unmap ${nodeId}`,
      content: "Node will be unmapped",
      cancelText: "Dismiss",
      okText: "Confirm",
      onOk() {
        unmapInfraNode({
          variables: {
            infraId,
            nodeMacId: nodeId,
          },
        });
      },
    });
  };

  const transformToMappedNodesDataSource = (data: PositionDataType): MappedNodeDataType[] => {
    if (data) {
      return data.nodes.map((node) => {
        const nodeTypeLabel = getNodeTypeDisplayValue(node.nodeType);
        const settings =
          node.nodeType === NodeType.Energy && get(data, "positionConfiguration[0].desiredEmonSwitchPosition");

        const settingsLabel = get(find(emonSettings, { value: settings }), "label");
        return assign(node, { nodeTypeLabel, settings, settingsLabel });
      });
    }
    return [];
  };

  const mappedNodesDataSource = transformToMappedNodesDataSource(dataSource);

  const handleAdjustMappedNode = (node: MappedNodeDataType) => {
    setEditNode(node);
    openEditNodeModal();
  };

  const startChangeGateway = (positionId: string) => {
    const mappedNode = mappedNodesDataSource.find((data) => data.positionId === positionId);

    showModal({
      element: SelectGatewayModal,
      config: {
        gateways,
        defaultValue: mappedNode?.mappedGateway?.gatewayId,
        onOk: (selectedGateway: string) => {
          upsertPositionGatewayMutation({
            variables: {
              positionId,
              gatewayId: selectedGateway,
            },
          });
        },
      },
    });
  };

  const mappedNodesProps = {
    dataSource: mappedNodesDataSource,
    handleAdjustMappedNode,
    handleUnmapNode,
    startChangeGateway,
  };

  return (
    <>
      {screen.mobileOnly ? <MappedNodesGrid {...mappedNodesProps} /> : <MappedNodesTable {...mappedNodesProps} />}
      <ModalWrapper ref={editNodeModalRef} modal={EditNodeModal} props={{ node: editNode, reloadInfrastructure }} />
    </>
  );
};

export default MappedNodes;
