import { map } from "lodash";
import { FilterOption, InfrastructureListSubscription } from "pacts/app-webcore/hasura-webcore.graphql";

export enum InfraPhaseEnum {
  INFRA_1_PHASE = 1,
  INFRA_3_PHASES = 3,
}

export enum InfrastructureTypeEnum {
  COMPRESSOR = "COMPRESSOR",
  CHILLER = "CHILLER",
  PIPE = "PIPE",
  PUMP = "PUMP",
  COOLING_TOWER = "COOLING_TOWER",
  CHILLED_WATER = "CHILLED_WATER",
}

export enum HvacSystemTypeEnum {
  CHILLED_WATER = "CHILLED_WATER",
  SPLIT = "SPLIT",
  VRV = "VRV",
}

export const hvacSystemTypes = {
  [HvacSystemTypeEnum.CHILLED_WATER]: "Cooling System",
  [HvacSystemTypeEnum.SPLIT]: "Split",
  [HvacSystemTypeEnum.VRV]: "VRV",
};

export const infrastructureTypeInput = {
  [InfrastructureTypeEnum.COMPRESSOR]: {
    [InfraPhaseEnum.INFRA_1_PHASE]: "COMPRESSOR_1_PHASE",
    [InfraPhaseEnum.INFRA_3_PHASES]: "COMPRESSOR_3_PHASES",
  },
  [InfrastructureTypeEnum.CHILLER]: {
    [InfraPhaseEnum.INFRA_1_PHASE]: "CHILLER_1_PHASE",
    [InfraPhaseEnum.INFRA_3_PHASES]: "CHILLER_3_PHASES",
  },
  [InfrastructureTypeEnum.PUMP]: {
    [InfraPhaseEnum.INFRA_1_PHASE]: "PUMP_1_PHASE",
    [InfraPhaseEnum.INFRA_3_PHASES]: "PUMP_3_PHASES",
  },
  [InfrastructureTypeEnum.COOLING_TOWER]: {
    [InfraPhaseEnum.INFRA_1_PHASE]: "COOLING_TOWER_1_PHASE",
    [InfraPhaseEnum.INFRA_3_PHASES]: "COOLING_TOWER_3_PHASES",
  },
  [InfrastructureTypeEnum.CHILLED_WATER]: "CHILLED_WATER",
};

export const infrastructureTypeValue = {
  COMPRESSOR_1_PHASE: {
    label: "Compressor (1 Phase)",
    type: InfrastructureTypeEnum.COMPRESSOR,
    numbOfPhases: 1,
  },
  COMPRESSOR_3_PHASES: {
    label: "Compressor (3 Phases)",
    type: InfrastructureTypeEnum.COMPRESSOR,
    numbOfPhases: 3,
  },
  CHILLER_1_PHASE: {
    label: "Chiller (1 Phase)",
    type: InfrastructureTypeEnum.CHILLER,
    numbOfPhases: 1,
  },
  CHILLER_3_PHASES: {
    label: "Chiller (3 Phases)",
    type: InfrastructureTypeEnum.CHILLER,
    numbOfPhases: 3,
  },
  PUMP_1_PHASE: {
    label: "Pump (1 Phase)",
    type: InfrastructureTypeEnum.PUMP,
    numbOfPhases: 1,
  },
  PUMP_3_PHASES: {
    label: "Pump (3 Phases)",
    type: InfrastructureTypeEnum.PUMP,
    numbOfPhases: 3,
  },
  COOLING_TOWER_1_PHASE: {
    label: "Cooling Tower (1 Phase)",
    type: InfrastructureTypeEnum.COOLING_TOWER,
    numbOfPhases: 1,
  },
  COOLING_TOWER_3_PHASES: {
    label: "Cooling Tower (3 Phases)",
    type: InfrastructureTypeEnum.COOLING_TOWER,
    numbOfPhases: 3,
  },
  PIPE: {
    label: "Pipe",
    type: InfrastructureTypeEnum.PIPE,
  },
  CHILLED_WATER: {
    label: "Cooling System",
    type: InfrastructureTypeEnum.CHILLED_WATER,
  },
};

export const infrastructureTypes: any[] = [
  {
    label: "Compressor",
    value: InfrastructureTypeEnum.COMPRESSOR,
  },
  {
    label: "Chiller",
    value: InfrastructureTypeEnum.CHILLER,
  },
  {
    label: "Pipe",
    value: InfrastructureTypeEnum.PIPE,
  },
  {
    label: "Pump",
    value: InfrastructureTypeEnum.PUMP,
  },
  {
    label: "Cooling Tower",
    value: InfrastructureTypeEnum.COOLING_TOWER,
  },
  {
    label: "Cooling System",
    value: InfrastructureTypeEnum.CHILLED_WATER,
  },
];

export const filterOptions: FilterOption[] = [
  ...map(infrastructureTypeValue, (type, key) => ({
    field: "type",
    value: key,
    label: type.label,
  })),
  {
    field: "node",
    value: "offline",
    label: "Offline Node",
  },
];

export interface PaginationProps {
  limit: number;
  offset: number;
}

export type Infrastructure = InfrastructureListSubscription["infrastructures"][number];
