import React from "react";
import { UnlinkIcon } from "components/Icons";
import "./InvalidUrl.scss";

const InvalidUrl = () => {
  return (
    <div className="d-flex h-100 flex-column align-items-center">
      <div className="icon-bg d-flex justify-content-center align-items-center my-xl">
        <UnlinkIcon />
      </div>
      <div className="font-heading text-center fs-xl font-weight-semi-bold header">
        Sorry, this link is no longer valid
      </div>
      <div className="text-center subtext fs-m font-weight-normal mt-s">
        It may have expired or your email has been removed. If you find yourself needing additional cooling, please
        don&apos;t hesitate to contact the hotel staff directly. We are always ready to help guarantee your utmost
        comfort during your stay.
      </div>
    </div>
  );
};

export default InvalidUrl;
