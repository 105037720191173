import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  TemporaryInfrastructureQuery,
  useTemporaryInfrastructureQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { PositionFunction } from "../../Checklist/Checklist.d";
import Checklist from "../../Checklist/Checklist";
import { ActiveTab, InfrastructureDetailsDataType } from "../InfrastructureDetails.d";
import InfrastructureDetails from "../InfrastructureDetails";

export default function InfrastructureDetailsWrapper() {
  const { infrastructureId } = useParams<{ infrastructureId: string }>();
  const [infrastructureDetails, setInfrastructureDetails] = useState<InfrastructureDetailsDataType>();
  const infrastructureType = new InfrastructureType(infrastructureDetails?.type!);
  const { locationId, activeTab } = useParams<{ locationId: string; activeTab: string }>();
  const history = useHistory();
  const location = useLocation();

  const role = useRoleAndPermission();

  const handleTabChange = (activeKey: string) => {
    history.push(`/locations/${locationId}/infrastructures/${infrastructureId}/${activeKey}${location.search}`);
  };

  useTemporaryInfrastructureQuery({
    variables: {
      id: infrastructureId,
    },
    onCompleted: (data: TemporaryInfrastructureQuery) =>
      setInfrastructureDetails(data.infrastructures?.[0] as InfrastructureDetailsDataType),
  });

  return (
    <>
      <Link to={`/locations/${locationId}/infrastructures${location.search}`}>
        <Button type="link" className="pl-none">
          <ArrowLeftOutlined /> Back to Infrastructure
        </Button>
      </Link>

      <Tabs className="header-tab-bar" activeKey={activeTab} onChange={handleTabChange}>
        {!role.isContractor() && (
          <Tabs.TabPane tab="Compressor" key={ActiveTab.COMPRESSOR}>
            <InfrastructureDetails />
          </Tabs.TabPane>
        )}
        {!infrastructureType.isCoolingSystem() && (
          <Tabs.TabPane tab="Checklist" key={ActiveTab.CHECK_LIST}>
            <Checklist keyPositionId={infrastructureId} positionFunction={PositionFunction.COMPRESSOR} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
}
