import { Button, Col, Collapse, Modal, Row, Space, Typography, message } from "antd";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import {
  useUpdateCompressorInstallTaskToPendingMutation,
  useUpdateSlotTasksToPendingMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { TestRun, TestRunStatus } from "pages/Checklist/Checklist.d";
import { ReloadTestModal } from "pages/Checklist/ReloadTestModal";
import { InforIcon } from "components/Icons";
import { ModalWrapperProps } from "components/Modal/Modal";
import { statusIcon } from "./helpers";
import { TestList } from "../TestList";

export interface TaskTestCardProps {
  handleTestFunctionClicked: (
    keyPositionId: string,
    positionFunction: string,
    isMeterPosition?: boolean,
    resumePreviousSession?: boolean
  ) => Promise<void>;
  keyPositionId: string;
  positionFunction: string;
  isMeterPosition?: boolean;
  splitIntoRooms?: boolean;
  task: any;
  testRuns: TestRun[];
  isCompressor?: boolean;
  isCurrentKeyEntry?: boolean;
}

const TestFunction = ({
  modalWrapperProps,
  closeModal,
  onNewSession,
  onResumePreviousSession,
}: {
  onNewSession: () => Promise<void>;
  onResumePreviousSession: () => Promise<void>;
} & ModalWrapperProps) => (
  <Modal
    className="select-test-run-modal"
    title="Test function"
    centered
    footer={null}
    width={400}
    {...modalWrapperProps}
  >
    <Button
      type="primary"
      className="ant-btn ant-btn-primary modal-button"
      onClick={() => onNewSession().then(closeModal)}
    >
      Start new session
    </Button>
    <br />
    <Button
      type="default"
      className="modal-button button-no"
      onClick={() => onResumePreviousSession().then(closeModal)}
    >
      Resume previous session
    </Button>

    <Space className="pt-xl">
      <Typography.Text type="warning">Insert batteries now or Restart all the nodes</Typography.Text>
    </Space>
  </Modal>
);

export const TaskTestCard: React.FC<TaskTestCardProps> = ({
  task,
  testRuns,
  keyPositionId,
  isMeterPosition,
  splitIntoRooms,
  positionFunction,
  isCompressor,
  isCurrentKeyEntry,
  handleTestFunctionClicked,
}) => {
  const [resetModalRef, openResetModalRef] = useModalWrapperTrigger();
  const [testFunctionModalRef, openTestFunctionModalRef] = useModalWrapperTrigger();
  const [isHavePendingTest, setIsHavePendingTest] = useState<boolean>(true);

  useMemo(() => {
    const pendingTests = testRuns.filter((testRun) => testRun.status === TestRunStatus.PENDING);
    setIsHavePendingTest(pendingTests.length > 0);
  }, [testRuns, setIsHavePendingTest]);

  const [updateSlotTasksToPending, { loading }] = useUpdateSlotTasksToPendingMutation({
    onCompleted: () => message.success("Reopen installation tasks successfully!"),
    onError: errorHandler.handleError,
  });

  const [updateCompressorInstallTaskToPending, { loading: compressorLoading }] =
    useUpdateCompressorInstallTaskToPendingMutation({
      onCompleted: () => message.success("Reopen installation tasks successfully!"),
      onError: errorHandler.handleError,
    });

  const handleOnReOpenTask = () => {
    if (!keyPositionId) return;
    if (isCompressor) updateCompressorInstallTaskToPending({ variables: { compressorId: keyPositionId } });
    else updateSlotTasksToPending({ variables: { roomId: keyPositionId } });
  };

  return (
    <div className="task-card" key={task.taskId}>
      <Collapse
        expandIconPosition="end"
        bordered={splitIntoRooms}
        className={classNames({ "bg-white": true, disabled: !splitIntoRooms })}
        expandIcon={!splitIntoRooms ? () => null : undefined}
      >
        <Collapse.Panel
          key={task.taskId}
          collapsible={!splitIntoRooms ? "disabled" : undefined}
          header={
            <Row align="middle">
              <Col span={4} className="taskTypeIcon">
                <InforIcon className="InforIcon" />
              </Col>
              <Col span={20} className="title justify-content-between d-inline-flex">
                <span className="vertical-align-center">{task.title}</span>
                <div className="icon">{splitIntoRooms ? statusIcon(task.status) : <p>x1</p>}</div>
              </Col>
            </Row>
          }
        >
          <Row gutter={[16, 16]}>
            {keyPositionId && (
              <Col span={24}>
                <Button
                  className="w-100 mb-s"
                  type="primary"
                  onClick={() => openResetModalRef()}
                  loading={loading || compressorLoading}
                  disabled={!isCurrentKeyEntry}
                >
                  Reopen installation tasks
                </Button>
              </Col>
            )}
            {!isHavePendingTest && (!testRuns || testRuns.length === 0) && (
              <Col span={24}>
                <Button
                  className="w-100 text-primary test-function-button"
                  type="primary"
                  onClick={() => openTestFunctionModalRef()}
                  disabled={!isCurrentKeyEntry}
                >
                  Test function
                </Button>
              </Col>
            )}
            <Col span={24}>
              <TestList
                testRuns={testRuns}
                handleTestFunctionClicked={handleTestFunctionClicked}
                keyPositionId={keyPositionId}
                positionFunction={positionFunction}
                isMeterPosition={isMeterPosition}
                isCurrentKeyEntry={isCurrentKeyEntry}
              />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>

      <ModalWrapper
        ref={resetModalRef}
        modal={ReloadTestModal}
        props={{ onReset: handleOnReOpenTask, title: "Do you want to reopen all task?" }}
      />
      <ModalWrapper
        ref={testFunctionModalRef}
        modal={TestFunction}
        props={{
          onNewSession: () => handleTestFunctionClicked(keyPositionId, positionFunction, isMeterPosition, false),
          onResumePreviousSession: () =>
            handleTestFunctionClicked(keyPositionId, positionFunction, isMeterPosition, true),
        }}
      />
    </div>
  );
};
