import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import debounce from "lodash/debounce";
import React from "react";
import { SearchBarProps } from "./SearchBar.d";

const SearchBar = (props: SearchBarProps & InputProps) => {
  const { placeholder, defaultValue, onInputChange, ...rest } = props;

  const onChange = debounce((value: string) => {
    onInputChange(value);
  }, 500);

  return (
    <Input
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
      prefix={<SearchOutlined style={{ color: "#bfbfbf" }} />}
      {...rest}
    />
  );
};

export default SearchBar;
