import React from "react";
import { Modal, message } from "antd";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useUpdateLocationGatewayPromiscuousModeMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { ConfirmPromiscuousModeModalProps } from "./ConfirmPromiscuousModeModal.d";

const ConfirmPromiscuousModeModal: React.FC<ConfirmPromiscuousModeModalProps> = ({
  onError,
  onSuccess,
  closeModal,
  modalWrapperProps,
  promiscuousMode,
  locationId,
}) => {
  const [updateLocationGatewayPromiscuousMode, { loading }] = useUpdateLocationGatewayPromiscuousModeMutation({
    onCompleted: () => {
      message.success("Successfully updated the promiscuous mode!");
      if (isFunction(onSuccess)) onSuccess();
    },
    onError: (error) => {
      errorHandler.handleError(error);
      if (isFunction(onError)) onError();
    },
  });

  const handleOnConfirm = async () => {
    await updateLocationGatewayPromiscuousMode({ variables: { locationId, promiscuousModeEnabled: promiscuousMode } });
    closeModal();
  };

  return (
    <Modal
      title="Change promiscuous mode"
      centered
      onOk={handleOnConfirm}
      width={400}
      okText="Confirm"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      {...modalWrapperProps}
      onCancel={() => {
        if (isFunction(onError)) onError();
        closeModal();
      }}
    >
      Please confirm to update the promiscuous mode in the location
    </Modal>
  );
};
export default ConfirmPromiscuousModeModal;
