import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, DatePicker, Input, Modal, Row, message } from "antd";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  CheckInIcon,
  CheckOutIcon,
  CircleFilledIcon,
  EmailIcon,
  ReloadIcon,
  ReloadWithBackgroundIcon,
  TrashIcon,
  WarningTriangleIcon,
} from "components/Icons";
import { DATETIME_12H_FORMAT, TIME_24H_FORMAT, formatDateTime12h } from "utils/date";
import moment, { Moment } from "moment";
import {
  useComfortPlusResetDailyLimitMutation,
  useEditComfortPlusDetailMutation,
  useRemoveGuestFromComfortPlusActivationMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import classNames from "classnames";
import { Key } from "../KeyList.d";
import { DeactivateComfortPlusForm } from "../DeactivateComfortPlusForm/DeactivateComfortPlusForm";

type ComfortPlusKeyDetailProps = {
  keyName: string;
  reservation: Key["reservation"];
  setOpenComfortPlusKeyDetailModal: (value: boolean) => void;
  openComfortPlusKeyDetailModal: boolean;
};

type User = {
  userId: string;
  email: string;
};

export enum EReservationStatus {
  CONFIRMED = "confirmed",
  UNCONFIRMED = "unconfirmed",
  ARRIVED = "arrived",
  DEPARTED = "departed",
  CANCELLED = "cancelled",
  NOSHOW = "noshow",
  UNKNOWN = "unknown",
}

const ComfortPlusKeyDetailModal: React.FC<ComfortPlusKeyDetailProps> = ({
  keyName,
  reservation,
  setOpenComfortPlusKeyDetailModal,
  openComfortPlusKeyDetailModal,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userInputs, setUserInputs] = useState<User[]>([]);
  const [checkInAtInput, setCheckInAtInput] = useState<Moment>(moment(reservation[0].checkInAt));
  const [checkOutAtInput, setCheckOutAtInput] = useState<Moment>(moment(reservation[0].checkOutAt));
  const [isOpenDeactivateModal, setIsOpenDeactivateModal] = useState<boolean>(false);

  const closeComfortPlusModalDetail = () => {
    setIsEditing(false);
    setOpenComfortPlusKeyDetailModal(false);
  };

  useEffect(() => {
    setUsers(
      reservation[0].reservationGuests.map((guest) => {
        return guest.user;
      })
    );
  }, [reservation]);

  const statusTitle = useMemo(() => {
    if (reservation[0].status !== EReservationStatus.CANCELLED) {
      return (
        <>
          <CircleFilledIcon /> ComfortPlus is activated
        </>
      );
    }
  }, [reservation]);

  const [removeGuest] = useRemoveGuestFromComfortPlusActivationMutation({
    onCompleted: () => {
      message.success("Guest email deleted succesfully.");
    },
  });

  const [editComfortPlusDetail] = useEditComfortPlusDetailMutation({
    onCompleted: () => {
      message.success("ComfortPlus detail updated successfully.");
      setUserInputs([]);
      setIsEditing(false);
    },
  });

  const [resetDailyLimit] = useComfortPlusResetDailyLimitMutation({
    variables: {
      reservationId: reservation[0].id,
      dailyPrecoolLimitResetAt: moment().format(),
    },
    onCompleted: () => {
      message.success(`The guest daily limit to pre-cool ${keyName} have been reset successfully.`);
      closeComfortPlusModalDetail();
    },
  });

  const handleCancelEdit = () => {
    const content = (
      <div className="deleteGuestComfirmModalContent">
        <div className="title mb-s">
          <b>Are you sure to leave this page?</b>
        </div>
        <div className="content">Information entered will not be saved.</div>
      </div>
    );
    Modal.confirm({
      icon: (
        <div className="deleteGuestConfirmModalIcon mb-m">
          <WarningTriangleIcon />
        </div>
      ),
      content,
      className: "comfortPlusConfirmModal",
      okText: "Leave now",
      okButtonProps: {
        className: "deleteGuestRemoveButton",
      },
      onOk: () => {
        setUserInputs([]);
        setIsEditing(false);
      },
    });
  };

  const handleSubmitDetailChanges = () => {
    if (
      (checkInAtInput && checkInAtInput !== moment(reservation[0].checkInAt)) ||
      (checkOutAtInput && checkOutAtInput !== moment(reservation[0].checkOutAt)) ||
      userInputs.length > 0
    ) {
      const content = (
        <div className="deleteGuestComfirmModalContent">
          <div className="title mb-s">
            <b>Are you sure to save changes?</b>
          </div>
          <div className="content">
            Please check your changes carefully as it will affect the guest&apos;s access to the ComfortPlus link for{" "}
            <b>key {keyName}</b>
          </div>
        </div>
      );
      Modal.confirm({
        icon: (
          <div className="deleteGuestConfirmModalIcon mb-m">
            <WarningTriangleIcon />
          </div>
        ),
        content,
        className: "comfortPlusConfirmModal",
        okText: "Save changes",
        onOk: async () => {
          await editComfortPlusDetail({
            variables: {
              reservationId: reservation[0].id,
              checkInAt: checkInAtInput.format(),
              checkOutAt: checkOutAtInput.format(),
              addedEmails: userInputs.map((user) => user.email),
            },
          });
        },
      });
    } else {
      setUserInputs([]);
      setIsEditing(false);
    }
  };

  const handleResetDailyLimit = () => {
    const content = (
      <div className="deleteGuestComfirmModalContent">
        <div className="title mb-s">
          <b>Are you sure to reset daily limit?</b>
        </div>
        <div className="content">
          When you reset the guest daily limit, guest will again have&nbsp;
          <b>{reservation[0].comfortplusActivations[0].precoolDailyLimit}</b> times to pre-cool the room.
        </div>
      </div>
    );
    Modal.confirm({
      icon: (
        <div className="deleteGuestConfirmModalIcon mb-m">
          <ReloadWithBackgroundIcon />
        </div>
      ),
      content,
      className: "comfortPlusConfirmModal",
      okText: "Comfirm",
      onOk: resetDailyLimit,
    });
  };

  const handleRemoveGuest = (user: User) => {
    const content = (
      <div className="deleteGuestComfirmModalContent">
        <div className="title mb-s">
          <b>Are you sure to remove access for this email?</b>
        </div>
        <div className="content">
          <b>{user.email}</b> will no longer have access to the ComfortPlus link. You can add it again at anytime.
        </div>
      </div>
    );
    Modal.confirm({
      width: 520,
      className: "comfortPlusConfirmModal",
      icon: (
        <div className="deleteGuestConfirmModalIcon mb-m">
          <WarningTriangleIcon />
        </div>
      ),
      content,
      onOk: async () => {
        await removeGuest({
          variables: {
            reservationId: reservation[0].id,
            userId: user.userId,
          },
        });
      },
      okText: "Remove",
      okButtonProps: {
        className: "deleteGuestRemoveButton",
      },
    });
  };

  const onClickAddEmail = () => {
    userInputs.push({ userId: `${userInputs.length + 1}`, email: "" });
  };

  return (
    <Modal
      title={`Key ${keyName}`}
      width={500}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={closeComfortPlusModalDetail}
      open={openComfortPlusKeyDetailModal}
      destroyOnClose
      className="comfortPlusDetailModal"
    >
      <div className="headerModal mb-m">
        <span>
          <b>General Information</b>
        </span>
        <Button className="p-none" type="link" icon={<EditOutlined />} onClick={() => setIsEditing(true)}>
          &nbsp;<b>Edit</b>
        </Button>
      </div>
      <div className="bg-light-gray radius-xs p-s">
        <div className="headerModal">
          <span>
            <b>{statusTitle}</b>
          </span>
          <Button
            className={classNames("p-none", { "d-none": false })}
            type="link"
            onClick={handleResetDailyLimit}
            icon={<ReloadIcon />}
          >
            &nbsp;<b>Reset daily limit</b>
          </Button>
        </div>
        <hr className="my-xs" style={{ color: "gray" }} />
        <div className="detail mt-m">
          <Row className="my-xs" gutter={16}>
            <Col span={8}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <CheckInIcon />
                &nbsp;Check in date
              </span>
            </Col>
            <Col span={16}>
              <div style={{ textAlign: "right" }}>
                {isEditing ? (
                  <div className="mb-none">
                    <DatePicker
                      className="w-100"
                      placeholder="Check in date"
                      format={DATETIME_12H_FORMAT}
                      showTime={{ format: TIME_24H_FORMAT }}
                      defaultValue={moment(reservation[0].checkInAt)}
                      disabledDate={(d) => !d || d.isAfter(checkOutAtInput)}
                      onChange={(event) => {
                        if (event?.isSameOrBefore(checkOutAtInput)) setCheckInAtInput(event);
                      }}
                    />
                  </div>
                ) : (
                  <b>{formatDateTime12h(reservation[0].checkInAt)}</b>
                )}
              </div>
            </Col>
          </Row>
          <Row className="my-xs" gutter={16}>
            <Col span={8}>
              <span>
                <CheckOutIcon />
                &nbsp;Check out date
              </span>
            </Col>
            <Col span={16}>
              <div style={{ textAlign: "right" }}>
                {isEditing ? (
                  <div className="mb-none">
                    <DatePicker
                      className="w-100"
                      placeholder="check out date"
                      format={DATETIME_12H_FORMAT}
                      showTime={{ format: TIME_24H_FORMAT }}
                      defaultValue={moment(reservation[0].checkOutAt)}
                      disabledDate={(d) => !d || d.isBefore(checkInAtInput)}
                      onChange={(event) => {
                        if (event?.isSameOrAfter(checkInAtInput)) setCheckOutAtInput(event);
                      }}
                    />
                  </div>
                ) : (
                  <b>{formatDateTime12h(reservation[0].checkOutAt)}</b>
                )}
              </div>
            </Col>
          </Row>
          {users.map((user, index) => (
            <Row key={user.userId} className="my-xs" gutter={16}>
              <Col span={8}>
                <span>
                  <EmailIcon />
                  &nbsp;Email {index + 1}
                  {isEditing && (
                    <>
                      &nbsp;
                      <Button type="link" className="p-none" onClick={() => handleRemoveGuest(user)}>
                        <TrashIcon className="red-svg-icon" />
                      </Button>
                    </>
                  )}
                </span>
              </Col>
              <Col span={16}>
                <div style={{ textAlign: "right" }}>
                  {isEditing ? (
                    <div className="mb-none">
                      <Input className="w-100" value={user.email} disabled />
                    </div>
                  ) : (
                    <b>{user.email}</b>
                  )}
                </div>
              </Col>
            </Row>
          ))}
          {isEditing &&
            userInputs.map((user, index) => (
              <Row key={user.userId} className="my-xs" gutter={16}>
                <Col span={8}>
                  <span>
                    <EmailIcon />
                    &nbsp;Email {users.length + index + 1}
                    &nbsp;
                    <Button type="link" className="p-none" onClick={() => userInputs.splice(index, 1)}>
                      <TrashIcon className="red-svg-icon" />
                    </Button>
                  </span>
                </Col>
                <Col span={16}>
                  <div style={{ textAlign: "right" }}>
                    <div className="mb-none">
                      <Input
                        className="w-100"
                        type="email"
                        onChange={(event) => {
                          userInputs[index].email = event.target.value;
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            ))}
        </div>
        {isEditing && (
          <Row className="my-xs">
            <Button type="link" className="p-none" onClick={() => onClickAddEmail()}>
              <b>
                <PlusOutlined /> Add Email
              </b>
            </Button>
          </Row>
        )}
      </div>
      <Row className="mt-l" gutter={16}>
        <Col span={12}>
          <Button type="link" className="text-danger pl-none" onClick={() => setIsOpenDeactivateModal(true)}>
            <b>
              <CloseOutlined /> Deactivate ComfortPlus
            </b>
          </Button>
        </Col>
        {isEditing && (
          <Col span={12} style={{ textAlign: "right" }}>
            <Button type="ghost" className="mr-s" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmitDetailChanges}>
              Save
            </Button>
          </Col>
        )}
      </Row>
      <DeactivateComfortPlusForm
        open={isOpenDeactivateModal}
        onClose={() => setIsOpenDeactivateModal(false)}
        reservation={reservation}
      />
    </Modal>
  );
};

export default ComfortPlusKeyDetailModal;
