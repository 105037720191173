import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {
  formatTemp,
  getACFanSpeedText,
  getACModeText,
  getACText,
  getAutomationModeText,
  getDoorStatusText,
  getOccupancyStateText,
} from "pages/Key/key.helper";
import { EventStreamType } from "pages/Key/types";
import React from "react";
import { CsvHeaderConfig } from "types/types";
import { v4 as uuid } from "uuid";
import { GetAllActivityLogsEventStreamQuery, Maybe } from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import { correctTimezone, formatDate, formatTime24h, formatTimezone } from "../../../../utils/date";
import { ActionFilterInput, ActivityLog, ChangedByType, LogGroupTypes, LogsToCSV, LogTypes } from "./ActivityLog.d";

const getPlainTextMessage = (
  messageTemplate: string,
  currentValue: string | number,
  previousValue?: string | number | null
) => {
  if (previousValue) {
    return `${messageTemplate} ${currentValue} from ${previousValue}`;
  }
  return `${messageTemplate} ${currentValue}`;
};

const getBinaryLogMessage = (messageTemplate: string, changedValue: any) => {
  return (
    <span>
      {messageTemplate} <span className="font-weight-bold">{changedValue}</span>
    </span>
  );
};

const getNonBinaryLogMessage = (messageTemplate: string, toValue: any, fromValue?: any) => {
  if (!isEmpty(fromValue)) {
    return (
      <span>
        {messageTemplate} <span className="font-weight-bold">{toValue}</span> from{" "}
        <span className="font-weight-bold">{fromValue}</span>
      </span>
    );
  }
  return (
    <span>
      {messageTemplate} <span className="font-weight-bold">{toValue}</span>
    </span>
  );
};

const customUsername = (name: string | null | undefined) => {
  if (!name) return "";
  if (name === ChangedByType.AUTOMATION) return "Automation";
  if (name === ChangedByType.GUEST_INTERACTION) return "Guest";
  if (name === ChangedByType.NIGHTTIME_SETPOINT_INCREASE) return "Nighttime Setpoint Increase Automation";
  return name;
};

const parseAndRemoveDecimal = (input: Maybe<string> | undefined): number | undefined => {
  if (input === undefined) {
    return undefined;
  }

  const numberValue = parseFloat(input as string);
  const integerValue = Math.trunc(numberValue);
  return integerValue;
};

const createNonBinaryLog = (
  message: string,
  currentValue: string,
  previousValue: string,
  logGroupType: LogGroupTypes,
  logType: LogTypes,
  user: string,
  time: Date
): ActivityLog => ({
  id: uuid(),
  message: getNonBinaryLogMessage(message, currentValue, previousValue),
  plainTextMessage: getPlainTextMessage(message, currentValue, previousValue),
  logGroupType,
  logType,
  currentValue,
  previousValue,
  user,
  time,
});

const createBinaryLog = (
  message: string,
  currentValue: string,
  logGroupType: LogGroupTypes,
  logType: string,
  user: string,
  time: Date
): ActivityLog => ({
  id: uuid(),
  message: getBinaryLogMessage(message, currentValue),
  plainTextMessage: getPlainTextMessage(message, currentValue),
  logGroupType,
  logType,
  currentValue,
  user,
  time,
});

const createACLog = (
  message: string,
  newValue: string,
  logGroupType: LogGroupTypes,
  logType: LogTypes,
  user: string,
  time: Date
): ActivityLog => {
  const currentValue = getACText(parseAndRemoveDecimal(newValue));
  const plainTextOfCurrentValue = typeof currentValue !== "string" ? "Not Updated" : currentValue;
  return {
    id: uuid(),
    message: getBinaryLogMessage(message, currentValue),
    plainTextMessage: getPlainTextMessage(message, plainTextOfCurrentValue),
    logGroupType,
    logType,
    currentValue: plainTextOfCurrentValue,
    user,
    time,
  };
};

const formatActivityDescription = (config: string) => {
  return `${config} changed to`;
};

const titleCaseACStatus = (status: string) => {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

const createLogFromEvent = (config: any, timezone: string): ActivityLog | null => {
  const locationOffset = moment().tz(timezone).utcOffset();
  const correctedTime = correctTimezone(config.time, locationOffset);
  const time = moment(correctedTime).toDate() || moment().toDate();
  const user = customUsername(config.modified_by_user);

  switch (config.event_type) {
    case EventStreamType.Door:
      return createBinaryLog(
        "Door was",
        getDoorStatusText(parseAndRemoveDecimal(config.new_ac_settings_value)),
        LogGroupTypes.DOOR,
        config.new_ac_settings_value.split(".")[0],
        user,
        time
      );
    case EventStreamType.Occupancy:
      return createBinaryLog(
        "Room became",
        getOccupancyStateText(parseAndRemoveDecimal(config.new_ac_settings_value)),
        LogGroupTypes.OCCUPANCY,
        config.new_ac_settings_value.split(".")[0],
        user,
        time
      );
    case EventStreamType.Powered:
      return createACLog(
        "HVAC powered",
        config.new_ac_settings_value,
        LogGroupTypes.HVAC,
        LogTypes.AC_STATUS,
        user,
        time
      );
    case EventStreamType.TemperatureSet:
      if (config.new_ac_settings_value !== config.previous_ac_settings_value) {
        return createNonBinaryLog(
          formatActivityDescription("Setpoint"),
          formatTemp(config.new_ac_settings_value),
          formatTemp(config.previous_ac_settings_value),
          LogGroupTypes.HVAC,
          LogTypes.AC_SETPOINT,
          user,
          time
        );
      }
      break;
    case EventStreamType.FanSpeed:
      if (config.new_ac_settings_value !== config.previous_ac_settings_value) {
        return createNonBinaryLog(
          formatActivityDescription("Fan speed"),
          getACFanSpeedText(parseAndRemoveDecimal(config.new_ac_settings_value)),
          getACFanSpeedText(parseAndRemoveDecimal(config.previous_ac_settings_value)),
          LogGroupTypes.HVAC,
          LogTypes.AC_FANSPEED,
          user,
          time
        );
      }
      break;
    case EventStreamType.Mode:
      if (config.new_ac_settings_value !== config.previous_ac_settings_value) {
        return createNonBinaryLog(
          formatActivityDescription("HVAC Mode"),
          getACModeText(parseAndRemoveDecimal(config.new_ac_settings_value)),
          getACModeText(parseAndRemoveDecimal(config.previous_ac_settings_value)),
          LogGroupTypes.HVAC,
          LogTypes.AC_MODE,
          user,
          time
        );
      }
      break;
    case EventStreamType.AutomationModeEvent:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        getAutomationModeText(config.new_ac_settings_value),
        getAutomationModeText(config.previous_ac_settings_value),
        LogGroupTypes.AUTOMATION_MODE,
        config.new_ac_settings_value,
        user,
        time
      );
    case EventStreamType.LowerSetPoint:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.SETPOINT_LIMIT,
        user,
        time
      );
    case EventStreamType.UpperSetPoint:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.SETPOINT_LIMIT,
        user,
        time
      );
    case EventStreamType.OccupancyTimeOutNight:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.OCC_TIMEOUT_NIGHT_MINS,
        user,
        time
      );
    case EventStreamType.OccupancyTimeoOutDay:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.OCC_TIMEOUT_DAY_MINS,
        user,
        time
      );
    case EventStreamType.AutomationHours:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.AC_NIGHT,
        user,
        time
      );
    case EventStreamType.ActiveHours:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.OCC_NIGHT,
        user,
        time
      );
    case EventStreamType.SpecialInstallationMode:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.INSTALLATION_MODE,
        user,
        time
      );
    case EventStreamType.DoorSensorEnabled:
      return createBinaryLog(
        "Door sensor was",
        config.new_ac_settings_value === "true" ? "Enabled" : "Disabled",
        LogGroupTypes.CONFIGURATIONS,
        "",
        user,
        time
      );
    case EventStreamType.DoorSensorTimeOut:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.DOOR_SENSOR_TIMEOUT,
        user,
        time
      );
    case EventStreamType.UnoccupiedTemp:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.UNOCCUPIED_TEMP,
        user,
        time
      );
    case EventStreamType.ACModelId:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.AC_MODEL,
        user,
        time
      );
    case EventStreamType.Precool:
      return createBinaryLog("Precool Command: ", config.new_ac_settings_value, LogGroupTypes.HVAC, "", user, time);
    case EventStreamType.ACSetPoint:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.HVAC,
        LogTypes.AC_SETPOINT,
        user,
        time
      );
    case EventStreamType.ACStatusEvent:
      return createBinaryLog(
        "HVAC powered",
        titleCaseACStatus(config.new_ac_settings_value),
        LogGroupTypes.HVAC,
        LogTypes.AC_STATUS,
        user,
        time
      );
    case EventStreamType.NightTimeSetpointEnabled:
      return createBinaryLog(
        "Nighttime setpoint increase was",
        config.new_ac_settings_value === "true" ? "Enabled" : "Disabled",
        LogGroupTypes.CONFIGURATIONS,
        "",
        user,
        time
      );
    case EventStreamType.NightTimeSetpointHours:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.NIGHTTIME_SETPOINT_HOURS,
        user,
        time
      );
    case EventStreamType.NightTimeSetpointDelay:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.NIGHTTIME_SETPOINT_DELAY,
        user,
        time
      );
    case EventStreamType.NightTimeSetpointMaxSetpoint:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.NIGHTTIME_SETPOINT_LIMIT,
        user,
        time
      );
    case EventStreamType.NightTimeSetpointOffset:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        formatTemp(config.new_ac_settings_value),
        formatTemp(config.previous_ac_settings_value),
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.NIGHTTIME_SETPOINT_OFFSET,
        user,
        time
      );
    case EventStreamType.ForceOccupancyAutomation:
      return createBinaryLog(
        "Force occupancy automation was",
        config.new_ac_settings_value === "true" ? "Enabled" : "Disabled",
        LogGroupTypes.CONFIGURATIONS,
        "",
        user,
        time
      );
    case EventStreamType.OperationalMode:
      return createNonBinaryLog(
        formatActivityDescription(config.ac_settings_type_changed),
        config.new_ac_settings_value,
        config.previous_ac_settings_value,
        LogGroupTypes.CONFIGURATIONS,
        LogTypes.OPERATIONAL_MODE,
        user,
        time
      );
    default:
      return null;
  }
  return null;
};

export const getLogsFromEventsStream = (
  eventsStream: GetAllActivityLogsEventStreamQuery["sensorflow_f_get_all_event_stream"],
  timezone: string
): ActivityLog[] => {
  const logs: ActivityLog[] = [];

  eventsStream.forEach((config) => {
    const log = createLogFromEvent(config, timezone);
    if (log) logs.push(log);
  });

  return logs;
};

const isFiltered = (logType: string, filter?: Array<string>) => {
  if (!filter) return false;
  if (filter.length > 0 && filter.indexOf(logType) > -1) return true;
  return false;
};

const isEmptyFilter = (filter?: ActionFilterInput) => {
  if (!filter) return true;
  if (
    (filter.automationMode && filter.automationMode.length > 0) ||
    (filter.configurations && filter.configurations.length > 0) ||
    (filter.hvac && filter.hvac.length > 0) ||
    (filter.occupancy && filter.occupancy.length > 0) ||
    (filter.door && filter.door.length > 0)
  )
    return false;
  return true;
};

export const applyFilters = (logs: ActivityLog[], filter?: ActionFilterInput) => {
  if (isEmptyFilter(filter)) return logs;
  return logs.filter((log) => {
    switch (log.logGroupType) {
      case LogGroupTypes.AUTOMATION_MODE:
        return isFiltered(log.logType.toString(), filter?.automationMode);
      case LogGroupTypes.OCCUPANCY:
        return isFiltered(log.logType.toString(), filter?.occupancy);
      case LogGroupTypes.DOOR:
        return isFiltered(log.logType.toString(), filter?.door);
      case LogGroupTypes.HVAC:
        return isFiltered(log.logType.toString(), filter?.hvac);
      default:
        return isFiltered(log.logType.toString(), filter?.configurations);
    }
  });
};

export const getActivityLogCsvHeader = () => {
  return [
    {
      label: "Log Time",
      key: "time",
    },
    {
      label: "Message",
      key: "message",
    },
    {
      label: "Modified by",
      key: "user",
    },
  ];
};

export const transformLogs = (rawLogs: ActivityLog[], timezone?: string): LogsToCSV[] => {
  return rawLogs.map(
    (log) =>
      ({
        user: log.user,
        time: `${formatDate(log.time, timezone)} ${formatTime24h(log.time, timezone)} GMT${formatTimezone(
          log.time,
          timezone
        )}`,
        message: log.plainTextMessage,
      } as LogsToCSV)
  );
};

export const transformLogToCsvData = (
  logs: LogsToCSV[],
  {
    headers,
  }: {
    headers: CsvHeaderConfig<LogsToCSV>[];
  }
) => {
  return logs.map((log) => {
    return Object.fromEntries(
      headers.map((headerCfg) => [
        headerCfg.key,
        headerCfg.transform ? headerCfg.transform(log) : (log as any)[headerCfg.key],
      ])
    );
  });
};
