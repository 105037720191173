import React, { useContext } from "react";
import { ActiveTab } from "pages/Key/KeyDetailWrapper/KeyDetailWrapper";
import Table from "components/Table/Table";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button } from "antd";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { Permission } from "pacts/permission";
import useBreakpoint from "hooks/use-breakpoint";
import { InstallationModeButton } from "components/InstallationMode";
import { PositionFunction } from "types/InstallationMode.d";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import StopWatch from "components/InstallationMode/StopWatch";
import { Key } from "../KeyList/KeyList.d";
import { getDefaultColumnKeyListTable, KeyListTableProps } from "../key.helper";

const KeyListTable = (props: KeyListTableProps) => {
  const { locationId } = useParams() as { locationId: string };
  const { currentPositionId, currentKeyEntryOfInstaller } = useContext(InstallationModeContext);
  const location = useLocation();

  const screen = useBreakpoint();

  const roleAndPermission = useRoleAndPermission();

  const DEFAULT_COLUMN_CONFIG = getDefaultColumnKeyListTable(props, locationId);

  const COLUMN_CONFIG = [
    ...DEFAULT_COLUMN_CONFIG,
    {
      render: (key: Key) => {
        return (
          <Link to={`/locations/${locationId}/keys/${key.keyId}/check-list${location.search}`}>
            <Button type="link">Checklist</Button>
          </Link>
        );
      },
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      render: (key: Key) => {
        return (
          <Link to={`/locations/${locationId}/keys/${key.keyId}/node-list${location.search}`}>
            <Button type="link">Nodelist</Button>
          </Link>
        );
      },
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: "Installation mode",
      render: (record: Key) => (
        <InstallationModeButton
          keyPositionId={record.keyId}
          currentPositionFunction={PositionFunction.AUTOSET}
          redirectUrl={`/locations/${locationId}/keys/${record.keyId}/${ActiveTab.KEYDETAILS}${location.search}`}
          activeModal
          showStopWatch
          automaticTestRun
        />
      ),
      isVisible: !roleAndPermission.canPerform(Permission.LOCATION_CREATE),
      isShowOnMobile: false,
    },
    {
      render: (record: Key) => {
        return (
          <div className="d-flex align-content-start">
            <div className="w-25">
              <h2>{record.keyName}</h2>
              <div className="mt-m">
                <span className="d-block fs-sm font-weight-bold">CATEGORY</span>
                <span className="d-block fs-sm">{record.categoryName}</span>
              </div>
            </div>
            <div className="align-items-center d-flex mb-xs w-75">
              <div className="align-items-center blocks mr-s ml-auto">
                <div className="mb-s">
                  <InstallationModeButton
                    keyPositionId={record.keyId}
                    currentPositionFunction={PositionFunction.AUTOSET}
                    redirectUrl={`/locations/${locationId}/keys/${record.keyId}/${ActiveTab.KEYDETAILS}${location.search}`}
                    activeModal
                    automaticTestRun
                  />
                  <Link
                    to={`/locations/${locationId}/keys/${record.keyId}/check-list${location.search}`}
                    className="ml-s"
                  >
                    <Button> Checklist </Button>
                  </Link>
                </div>
                <div className="align-items-center d-flex">
                  {currentPositionId === record.keyId && currentKeyEntryOfInstaller?.startedAt && (
                    <StopWatch startTime={currentKeyEntryOfInstaller?.startedAt} />
                  )}
                  <Link
                    to={`/locations/${locationId}/keys/${record.keyId}/node-list${location.search}`}
                    className="align-items-center blocks mr-none ml-auto"
                  >
                    <Button> Node list </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      },
      isVisible: true,
      isShowOnMobile: true,
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  return (
    <Table {...props} columns={columns} mobileView={screen.mobileAndTabletOnly} dataItemName="keys" rowKey="keyId" />
  );
};

export default KeyListTable;
