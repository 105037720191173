import { Select } from "antd";
import errorHandler from "errorHandler";
import { useKeyCategoriesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useMemo, useState } from "react";

interface ICategoryFilterProps {
  locationId: string;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const CategoryFilter = ({ locationId, value, onChange }: ICategoryFilterProps) => {
  const [keyCategories, setKeyCategories] = useState<{ categoryName: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string | undefined>();
  const { loading: categoriesLoading } = useKeyCategoriesQuery({
    variables: {
      locationIdInput: {
        location: {
          locationId: {
            _eq: locationId,
          },
        },
        ...(searchKey
          ? {
              categoryName: {
                _ilike: `%${searchKey}%`,
              },
            }
          : {}),
      },
    },
    onCompleted: (data) => {
      setKeyCategories(data.keyCategories || []);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const options = useMemo(() => {
    return keyCategories
      .filter((category) => !searchKey || category.categoryName.includes(searchKey))
      .map((category) => ({
        label: category.categoryName,
        value: category.categoryName,
      }));
  }, [keyCategories, searchKey]);
  return (
    <>
      <Select
        showSearch
        value={value}
        loading={categoriesLoading}
        onSearch={setSearchKey}
        onChange={onChange}
        mode="multiple"
        placeholder="Type to search locations"
        notFoundContent="No category found"
        options={options}
        autoClearSearchValue={false}
        onBlur={() => setSearchKey("")}
        filterOption
      />
    </>
  );
};

export default CategoryFilter;
