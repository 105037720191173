export default [
  {
    gatewayId: "a8684e69-f01d-4649-9750-e33675785850",
    gatewayName: "Gateway 1",
  },
  {
    gatewayId: "53dbbae6-f3f9-450c-ab03-07a17c58d425",
    gatewayName: "Gateway 2",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0001-ac83f1d8f452",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [
      {
        measurementType: "wlan_level",
        time: "2021-04-13T10:14:17.941968+00:00",
        value: -10,
      },
    ],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0002-ac83f1d8f453",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0003-ac83f1d8f454",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0004-ac83f1d8f455",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0007-ac83f1d8f789",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0006-ac83f1d8f456",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0007-ac83f1d8f457",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0008-ac83f1d8f458",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0009-ac83f1d8f459",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0010-ac83f1d8f460",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
  {
    gatewayId: "1dfb0f9d-a754-4d80-0011-ac83f1d8f461",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    gatewayHealthData: [],
    __typename: "Gateway",
  },
];
