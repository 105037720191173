import React from "react";
import { Progress, Row } from "antd";

import "./KeysStats.css";

const KeysStats = ({ mappedCount, totalCount }: any) => {
  const percentage = totalCount === 0 ? 0 : (100 * mappedCount) / totalCount;

  return (
    <div className="keys-stats">
      <Row justify="space-between">
        <b>Keys</b>
        <p>
          {mappedCount}/{totalCount}
        </p>
      </Row>
      <Row justify="space-between">
        <Progress percent={percentage} size="small" status="normal" showInfo={false} />
      </Row>
    </div>
  );
};

export default KeysStats;
