import { useApiContext } from "contexts/api-context";
import { useUrlSearchParams } from "hooks/use-query-string";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EAuthType } from "utils/constants";

const comfortPlusStorageKey = "comfortplus-token";

export const useSetComfortPlusParams = () => {
  const searchParams = useUrlSearchParams();
  const history = useHistory();
  const { setAccessTokenAndAuthType, accessToken } = useApiContext();

  useEffect(() => {
    if (setAccessTokenAndAuthType) {
      let token = searchParams.get("access_token");
      if (token) {
        localStorage.setItem(comfortPlusStorageKey, token);
        setAccessTokenAndAuthType?.(token, EAuthType.ComfortPlus);
        searchParams.delete("access_token");
        history.replace({
          search: searchParams.toString(),
        });
      } else {
        token = localStorage.getItem(comfortPlusStorageKey);
        if (token) {
          setAccessTokenAndAuthType(token, EAuthType.ComfortPlus);
        }
      }
    }
  }, [searchParams, setAccessTokenAndAuthType, history]);

  return { accessToken };
};

export default useSetComfortPlusParams;
