import React, { useContext, useEffect, useMemo, useState } from "react";
import { Input, Radio, Select } from "antd";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import errorHandler from "errorHandler";
import { NodeType, useEmonMapInputInfoLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import InfrastructureDetailsContext from "pages/InfrastructureDetails/InfrastructureDetails.Context";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { isNil } from "lodash";
import classNames from "classnames";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { mapInputs as rawMapInputs, NodeInputDataType } from "./NodeInput.d";
import { emonSettings } from "../MappedNodes.d";
import "./NodeInput.scss";

export const formatNodeMacId = (nodeMacId: string) => {
  if (nodeMacId.length === 18) {
    return nodeMacId.substring(2);
  }
  return nodeMacId;
};

interface NodeInputProps {
  node: NodeInputDataType;
  infraId?: string;
  type?: InfrastructureType;
  isRemappingNode?: boolean;
  handleInputChange: (
    input: any,
    isInvalidData?: boolean,
    isInputChanged?: boolean,
    isNewMappingInfra?: boolean
  ) => void;
}

export default function NodeInput({ node, infraId, type, handleInputChange, isRemappingNode }: NodeInputProps) {
  const infraDetails = useContext(InfrastructureDetailsContext);
  const roleAndPermission = useRoleAndPermission();

  const [slotName, setSlotName] = useState<string | undefined>(node.slotName);
  const [emonSetting, setEmonSetting] = useState<number | undefined>(node.emonSetting);
  const [mapInput, setMapInput] = useState<number | null | undefined>();
  const [originMapInput, setOriginMapInput] = useState<number | null | undefined>();
  const [mapInputs, setMapInputs] = useState<Array<any>>([]);

  const [emonMapInputInfo] = useEmonMapInputInfoLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      infraId: infraId ?? infraDetails?.id!,
      nodeMacId: formatNodeMacId(node.nodeMacInput!),
    },
    onCompleted: (data) => {
      setMapInput(data.emonMapInputInfo.currentMapInput);
      setOriginMapInput(data.emonMapInputInfo.currentMapInput);

      const updatedInputs = rawMapInputs.map((input) => {
        if (data.emonMapInputInfo.unavailableMapInputs.includes(input.value))
          return {
            ...input,
            disabled: !type?.isCompressor() || data.emonMapInputInfo.unavailableMapInputs.length === 1,
          };
        return input;
      });

      setMapInputs(updatedInputs);
    },
    onError: errorHandler.handleError,
  });

  const infraType = type ?? new InfrastructureType(infraDetails?.type!);
  const isEnergyMonitor = node.nodeType === NodeType.Energy;

  useMemo(() => {
    if (node.nodeType !== NodeType.PipeTemp) emonMapInputInfo();
  }, [node.nodeType, emonMapInputInfo]);

  useEffect(() => {
    const isInvalidSlotName = isEmpty(slotName);
    const isInvalidEmonSetting = !isNumber(emonSetting);
    const isInvalidMapInput = !isNumber(mapInput);

    if (!isEnergyMonitor) {
      handleInputChange({ slotName }, isInvalidSlotName);
    } else if (infraType.is3Phases()) {
      handleInputChange({ slotName, emonSetting }, isInvalidSlotName || isInvalidEmonSetting);
    } else if (infraType.isCompressor()) {
      handleInputChange(
        { slotName, emonSetting, mapInput },
        isInvalidSlotName || isInvalidEmonSetting || isInvalidMapInput,
        !isNil(originMapInput) && mapInput !== originMapInput,
        !!isNil(originMapInput)
      );
    } else {
      handleInputChange(
        { slotName, emonSetting, mapInput },
        isInvalidSlotName || isInvalidEmonSetting || isInvalidMapInput,
        !isNil(originMapInput) && mapInput !== originMapInput
      );
    }
    // eslint-disable-next-line
  }, [isEnergyMonitor, emonSetting, mapInput, slotName]);

  const handleMapInputChange = (e: any) => setMapInput(e.target.value);
  const handleSlotNameChange = (e: any) => setSlotName(e.target.value);

  return (
    <div>
      {!isRemappingNode && (
        <>
          <div
            className={classNames({
              "data-section": true,
              "d-none": roleAndPermission.isContractor(),
            })}
          >
            <p className="title">SLOT NAME</p>
            <Input placeholder="Slot Name" value={slotName} onChange={handleSlotNameChange} />
          </div>
          <div className="data-section">
            <p className="title">NODE ID</p>
            <p>{node.nodeMacInput}</p>
          </div>
          <div className="data-section">
            <p className="title">NODE TYPE</p>
            <p>{getNodeTypeDisplayValue(node.nodeType!)}</p>
          </div>
        </>
      )}

      {isEnergyMonitor && (
        <>
          <div className="data-section">
            <p className="title">SETTINGS</p>
            <Select
              className="w-100"
              data-testid="emonSettings"
              defaultValue={emonSetting}
              onSelect={setEmonSetting}
              placeholder="Settings"
            >
              {emonSettings.map((emon) => (
                <Select.Option key={emon.value} value={emon.value}>
                  {emon.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="data-section">
            <p className="title">MAP INPUTS</p>
            <Radio.Group
              disabled={infraType.is3Phases()}
              value={mapInput}
              options={mapInputs}
              onChange={handleMapInputChange}
            />
          </div>
        </>
      )}
    </div>
  );
}
