import React from "react";
import { Button, Space, Typography } from "antd";
import ListFilter from "components/ListFilter/ListFilter";
import { PlusOutlined } from "@ant-design/icons";
import { GroupsHeaderProps } from "./GroupsHeader.d";

const GroupsHeader: React.FC<GroupsHeaderProps> = ({ onFilterChange, loading, onAddGroupClick }) => {
  return (
    <div className="groups-container d-flex justify-content-between align-items-center layout-contractor p-m radius-t-12">
      <div className="groups-block">
        <Typography.Title level={4} className="mb-none">
          Groups
        </Typography.Title>
      </div>
      <div className="groups-block">
        <Space className="m-b-xs">
          <ListFilter
            onChange={onFilterChange}
            searchField="groupName"
            searchPlaceholder="Search group name"
            hideAdvancedFilter
          />
          <Button type="primary" icon={<PlusOutlined />} loading={loading} onClick={onAddGroupClick}>
            Add Group
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default GroupsHeader;
