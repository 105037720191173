import React, { useCallback, useMemo, useState } from "react";
import { useCreatePositionMutation, useGroupsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { message } from "antd";
import errorHandler from "errorHandler";
import usePagination from "pages/Key/hooks/use-pagination";
import { GroupsProps } from "./Groups.d";
import { GroupsHeader } from "./Components/GroupsHeader";
import { GroupModal, GroupModalProps, FormValue, GroupType } from "./Components/GroupModal";
import { GroupList } from "./Components/GroupList";

const initalValue: Partial<FormValue> = {
  name: "",
  type: undefined,
};

const Groups: React.FC<GroupsProps> = ({ locationId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [formGroupValue] = useState(initalValue);
  const [errors, setErrors] = useState<any>(null);
  const { pagination, setPagination } = usePagination();
  const [filters, setFilters] = useState<Record<string, any>>({ groupName: "" });
  const handleOpenModal = useCallback((state: boolean) => () => setOpenModal(state), [setOpenModal]);

  const variablesGroup = useMemo(
    () => ({
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      where: {
        locationId: { _eq: locationId || "" },
        ...(filters.groupName ? { positionName: { _ilike: `%${filters.groupName}%` } } : null),
      },
    }),
    [locationId, pagination, filters]
  );

  const { data, refetch: getGroup } = useGroupsQuery({
    variables: variablesGroup,
    onCompleted: (result) => {
      setPagination((prev) => ({ ...prev, total: result?.groupsAggregate?.aggregate?.count || 0 }));
    },
  });

  const [createGroup, { loading }] = useCreatePositionMutation({
    onError: (error) => {
      const errorMsg = errorHandler.handleError(error);
      if (errorMsg.includes("duplicate key")) setErrors({ name: ["This group name is already in use"] });
    },
    onCompleted: () => {
      message.success("Create group successfully!");
      handleOpenModal(false)();
      getGroup(variablesGroup);
    },
  });

  const handleOnSubmit: GroupModalProps["onSubmit"] = useCallback(
    async (value, form) => {
      const result = await createGroup({
        variables: {
          input: {
            locationId,
            positionName: value.name,
            positionType: value.type,
            positionFunction: GroupType.GROUP,
            parentPositionId: locationId,
          },
        },
      });
      if (result) form.resetFields();
    },
    [createGroup, locationId]
  );

  const reload = () => {
    getGroup(variablesGroup);
  };

  const onFilterChange = (fitlerValue: Record<string, any>) => setFilters(fitlerValue);

  return (
    <>
      <GroupsHeader onAddGroupClick={handleOpenModal(true)} onFilterChange={onFilterChange} />
      <GroupList
        data={data?.sensorflow_v_group_with_stats || []}
        pagination={pagination}
        onPaginationChange={setPagination}
        reload={reload}
      />
      <GroupModal
        onSubmit={handleOnSubmit}
        formValue={formGroupValue}
        errors={errors}
        loading={loading}
        onCancel={handleOpenModal(false)}
        open={openModal}
      />
    </>
  );
};

export default Groups;
