import React from "react";
import "./OnDemandPrecoolRooms.scss";
import { Col, Row } from "antd";
import moment from "moment";
import { AutoModeIcon, CoolFilledIcon, DryIcon, FanFilledIcon, SunIcon } from "components/Icons";
import { PreCoolTimer } from "./PreCoolTimer";
import { OnDemandPrecoolRoomsProps, precoolModes } from "./OnDemandPrecoolRooms.d";

const OnDemandPrecoolRooms: React.FC<OnDemandPrecoolRoomsProps> = ({ precoolingRooms }) => {
  const expirationTime = (startedAt?: string, totalMinutes?: number | null) => {
    return moment(startedAt).add(totalMinutes, "minutes")?.format();
  };

  const precoolMode = (mode?: number | null) => {
    const { label } = precoolModes.find((p) => p.value === (mode ?? 1));
    return <span className="mode">{label}</span>;
  };

  const precoolIcon = (mode?: number | null) => {
    switch (mode) {
      case 0:
        return <AutoModeIcon />;
      case 2:
        return <DryIcon className="dry-icon-filled-color" />;
      case 3:
        return <FanFilledIcon />;
      case 4:
        return <SunIcon className="sun-icon-filled-color" />;
      case 1:
      default:
        return <CoolFilledIcon />;
    }
  };

  return (
    <div className="w-100 onDemandPrecoolRooms mt-m">
      <Row className="title mx-s mt-m mb-xs">
        <Col span={16}>Rooms</Col>
        <Col className="timeRemaining">Time remaining:</Col>
      </Row>
      {precoolingRooms.map((room) => (
        <Row className="room p-s" key={room.positionName}>
          <Col span={16} className="roomName">
            {room.positionName}&nbsp;{precoolMode(room.mode)}
          </Col>
          <Col className="timeRemaining">
            {precoolIcon(room.mode)}&nbsp;
            <PreCoolTimer expiryTime={expirationTime(room?.startedAt, room?.totalMinutes)} onlyReturnTimer />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default OnDemandPrecoolRooms;
