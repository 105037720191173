import { Button, Col, message, Modal, Row, Tag } from "antd";
import classNames from "classnames";
import GetHelpButton from "components/GetHelpButton";
import { InstallationModeButton } from "components/InstallationMode";
import StopWatch from "components/InstallationMode/StopWatch";
import ModalWrapper from "components/Modal/Wrapper";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import errorHandler from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { capitalize, isFunction, uniq } from "lodash";
import isNil from "lodash/isNil";
import { useChangeBatteryMutation as useChangeBattery } from "pacts/app-webcore/hasura-webcore.graphql";
import { PositionFunction } from "pages/Checklist/Checklist.d";
import ContractorMapNodeModal from "pages/InfrastructureDetails/ContractorMappedNodes/MapNodeModal/MapNodeModal";
import { ActiveTab } from "pages/InfrastructureDetails/InfrastructureDetails.d";
import { mapInputs } from "pages/InfrastructureDetails/MappedNodes/NodeInput/NodeInput.d";
import { displayNodeMacId } from "pages/Node/ContractorNodeList/ContractorNodeComponent";
import ChangeBatteryModal from "pages/NodeManagement/ChangeBatteryModal/ChangeBatteryModal";
import { FormData } from "pages/NodeManagement/CreateNodeLifeCycleEvent";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getInfraDetailLink } from "utils/link";
import { InfrastructureType } from "../InfrastructureDataType";
import { UnmapModal } from "../UnmapModal";
import { ContractorInfrastructureProps } from "./ContractorInfrastructure.d";
import "./ContractorInfrastructure.scss";

const getColorTag = (status?: string) => {
  const defaultColor = "#8c8c8c";
  const colorTags = {
    online: "#009D7F",
    offline: "#E10058",
  };

  return colorTags[status as keyof typeof colorTags] || defaultColor;
};

const ContractorInfrastructure = (props: ContractorInfrastructureProps) => {
  const history = useHistory();
  const location = useLocation();

  const {
    name,
    type,
    meterPosition,
    locationId,
    locationName,
    id,
    reloadInfrastructures,
    phaseStreamIndex,
    rooms,
    onUnmap,
    currentFilter,
    meterPositionId,
  } = props;

  const { mobileOnly, tabletUp } = useBreakpoint();
  const [openChangeBatteryModal, setOpenChangeBatteryModal] = useState(false);
  const [changeBatteryValue, setChangeBatteryValue] = useState<FormData>({});
  const [isUnmappedNodeModalOpen, setUnmappedNodeModalOpen] = useState(false);
  const [addMappingModalRef, openAddMappingModal] = useModalWrapperTrigger();
  const [isMapNode, setIsMapNode] = useState(false);

  const { currentPositionId, currentKeyEntryOfInstaller } = useContext(InstallationModeContext);

  const infraType = new InfrastructureType(type || "");
  const connectedNodes = useMemo(() => meterPosition?.nodes || [], [meterPosition]);

  const node = connectedNodes[0];

  const aggregatedKeys =
    rooms && rooms.length > 0 ? uniq(rooms.map((r) => r.position?.parentPosition?.positionName)).join(", ") : "None";

  useEffect(() => {
    setIsMapNode(!connectedNodes || connectedNodes.length === 0);
  }, [connectedNodes]);

  const onMapOrUnMap = () => {
    if (isMapNode) {
      openAddMappingModal();
    } else {
      setUnmappedNodeModalOpen(true);
    }
  };

  const closeModalUnmapNode = () => {
    setUnmappedNodeModalOpen(false);
  };

  const onOpenChangeBatteryModal = async (value: any) => {
    setOpenChangeBatteryModal(true);
    setChangeBatteryValue({ nodeMacId: value });
  };

  const [changeBattery] = useChangeBattery({
    onError: errorHandler.handleError,
    onCompleted: (data: any) => {
      message.success(`Record life cycle event battery change successfully for node ${data.changeBattery?.nodeMacId}`);
      setOpenChangeBatteryModal(false);
    },
  });

  const onChangeBattery = async (data: FormData) => {
    if (data.nodeMacId) {
      await changeBattery({
        variables: {
          nodeMacId: data.nodeMacId,
          comment: data.comment,
        },
      });
    }
  };

  const helpText = useMemo(() => {
    if (locationId && locationName && id) {
      return `${locationName}: ${name} ${getInfraDetailLink(locationId, id)}`;
    }
  }, [locationId, locationName, id, name]);

  const channelInput = useMemo(() => {
    return mapInputs.find((input: { label: string; value: number }) => input.value === phaseStreamIndex);
  }, [phaseStreamIndex]);

  return (
    <Row gutter={[16, 16]} className="contractor-infrastructure bg-white m-auto">
      <Col span={24}>
        <span className="d-inline-flex" style={{ width: "100%" }}>
          <h4 className="contractor-infrastructure-name p-0">{name}</h4>
          {currentPositionId === id && currentKeyEntryOfInstaller?.startedAt && (
            <div className="stopwatch pr-s position-absolute r-0">
              <StopWatch startTime={currentKeyEntryOfInstaller?.startedAt} />
            </div>
          )}
        </span>
      </Col>
      <Col span={10} md={{ span: 24 }}>
        <Row justify="space-between" align="middle">
          <Col span={24}>
            <div className="contractor-infrastructure-section">
              <p className="title">TYPE</p>
              <p className="contractor-infrastructure-type">{infraType.getLabel()}</p>
            </div>
          </Col>
          {!isNil(phaseStreamIndex) && (
            <Col span={24}>
              <div className="contractor-infrastructure-section">
                <p className="title">CHANNEL</p>
                <p className="contractor-infrastructure-type">{channelInput.label}</p>
              </div>
            </Col>
          )}
          <Col span={24}>
            <div className="contractor-infrastructure-section">
              {connectedNodes.length > 0 ? (
                connectedNodes.map((nodeConnected, index: number) => {
                  const status =
                    currentPositionId === id ? nodeConnected.nodeStatusInInstallationMode : nodeConnected.status;
                  const colorTag = getColorTag(status || "");
                  return (
                    <Row key={index}>
                      <Col span={24}>
                        <p className="title">NODE ID {status && <Tag color={colorTag}>{capitalize(status)}</Tag>}</p>
                      </Col>
                      <Col span={24}>
                        <p>{displayNodeMacId(nodeConnected.nodeMacId)}</p>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Row>
                  <Col span={24}>
                    <p className="title">NODE ID</p>
                  </Col>
                  <Col span={24}>
                    <p>(Map Node)</p>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={14} md={{ span: 24 }}>
        <Row justify="space-between" align={tabletUp ? "middle" : "top"} gutter={[8, 16]} wrap className="custom-row">
          {(infraType.isCompressor() || infraType.isCoolingDevice()) && (
            <Col span={12} md={12} className={classNames("custom-col", { "text-right": mobileOnly })}>
              <InstallationModeButton
                keyPositionId={id}
                currentPositionFunction={PositionFunction.COMPRESSOR}
                buttonName={{ enter: "Start work", exit: "End Work" }}
                classNameButton={classNames({ "contractor-infrastructure-button": tabletUp })}
                activeModal
                automaticTestRun
              />
            </Col>
          )}
          {(infraType.isCompressor() || infraType.isCoolingDevice()) && (
            <Col span={12} md={12} className={classNames("custom-col", { "text-right": mobileOnly })}>
              <Button
                className={classNames({ "w-100": tabletUp })}
                onClick={() =>
                  history.push(
                    `/locations/${locationId}/infrastructures/${id}/${ActiveTab.CHECK_LIST}${location.search}`
                  )
                }
              >
                Checklist
              </Button>
            </Col>
          )}
          <Col span={24} md={12} className={classNames({ "text-right": mobileOnly })}>
            <Button
              className={classNames({ "w-100": tabletUp })}
              onClick={() => {
                onOpenChangeBatteryModal(connectedNodes[0].nodeMacId);
              }}
              disabled={isMapNode}
            >
              Change battery
            </Button>
          </Col>

          {!infraType.isCoolingSystem() && (
            <Col span={24} md={12} className={classNames({ "text-right": mobileOnly })}>
              <Button className={classNames({ "w-100": tabletUp })} onClick={() => onMapOrUnMap()}>
                {isMapNode ? "Map Node" : "Unmap"}
              </Button>
            </Col>
          )}
          <Col span={24} md={12} className={classNames({ "text-right": mobileOnly })}>
            <GetHelpButton helpText={helpText} className={classNames({ "w-100": tabletUp })} />
          </Col>
        </Row>
      </Col>
      {openChangeBatteryModal && (
        <ChangeBatteryModal
          formData={changeBatteryValue}
          closeModal={() => setOpenChangeBatteryModal(false)}
          handleOnSubmit={onChangeBattery}
        />
      )}

      {node && (
        <Modal
          className="unmap-node-modal"
          title="Unmap Node"
          footer={null}
          visible={isUnmappedNodeModalOpen}
          onCancel={closeModalUnmapNode}
          destroyOnClose
        >
          <UnmapModal
            roomName={aggregatedKeys}
            currentNodeMacId={node.nodeMacId || ""}
            slotName={node.slotName}
            infraId={id}
            onUnmapSuccessfully={async () => {
              if (isFunction(onUnmap)) await onUnmap(currentFilter);
              closeModalUnmapNode();
            }}
            onCloseModal={() => closeModalUnmapNode()}
            infraName={name}
          />
        </Modal>
      )}
      <ModalWrapper
        ref={addMappingModalRef}
        modal={ContractorMapNodeModal}
        props={{
          infraId: id,
          infraType,
          meterPositionId,
          reloadInfrastructures,
          currentFilter,
        }}
      />
    </Row>
  );
};

export default ContractorInfrastructure;
