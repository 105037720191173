import React, { useEffect, useState } from "react";
import {
  NodeSubType,
  useDeleteSlotMutation,
  useSingleMapNodeMutation,
  useUnmapNodeMutation,
  useUpdatePositionConfigurationMutation,
  useUpdateSlotMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { assign, get, noop, omit, pick } from "lodash";
import NodeTypeDisplay, { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import { Button, Col, Collapse, Menu, message, Modal, Select } from "antd";
import "./SlotComponent.scss";
import confirm from "antd/lib/modal/confirm";
import { CaretRightOutlined, ExclamationCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { ApolloError } from "apollo-client";
import NodeMappingComponent, { getExternalErrorComponent } from "components/NodeMapping";
import ActionButton from "components/ActionButton";
import { Permission } from "pacts/permission";
import Can from "components/Can/Can";
import classnames from "classnames";
import { ROLE } from "rbac-rules";
import BaseACModelDropdown from "components/BaseACModelDropdown";
import NodeLifeCycleEvent from "pages/NodeManagement/NodeLifeCycleEvent/NodeLifeCycleEvent";
import RenameFormModal from "../../../components/RenameModal/RenameFormModal";
import errorHandler from "../../../errorHandler";
import CopyButton from "../../../components/CopyButton";
import useBreakpoint from "../../../hooks/use-breakpoint";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import MenuDropdown from "../../../components/MenuDropdown";
import { ExtendedSlot, Room, Slot } from "./KeyDetail.d";
import LiveValue from "../../../components/LiveValue";
import useLocationDateTime from "../../../hooks/use-location-datetime";
import { ActingMode } from "../types";
import { isShowBaseACModelDropdown } from "../key.helper";

const { Panel } = Collapse;

const WARNING_SIGNAL_STRENGTH = 20;

interface SlotComponentProps {
  room: Room;
  slot: Slot;
  extendedSlot: ExtendedSlot;
  updateMappedCount: any;
  updateStatus: any;
  slotChanged: any;
  subscriptionLoaded: boolean;
}

const HeaderContainer: React.FC<Slot> = ({ slotName, nodeSubType }): React.ReactElement => (
  <>
    <p className="slot-name">{slotName}</p>
    <p className="node-type">{get(NodeTypeDisplay[nodeSubType], "code")}</p>
  </>
);

const SlotComponent = (props: SlotComponentProps): React.ReactElement => {
  const {
    room,
    slot: slotProps,
    updateMappedCount,
    updateStatus,
    slotChanged,
    extendedSlot,
    subscriptionLoaded = true,
  } = props;

  const [slot, setSlot] = useState<Slot>(slotProps);
  const [isModalOpen, setModalOpen] = useState(false);
  const [mapNodeError, setMapNodeError] = useState<ApolloError | undefined>();
  const [nodeMacInput, setNodeMacInput] = useState<string | undefined>();
  const [isRenameSlotModalOpen, setIsRenameSlotModalOpen] = useState(false);
  const [baseAcModelId, setBaseAcModelId] = useState();
  const [baseAcModelIdInput, setBaseAcModelIdInput] = useState();
  const [actingModeInput, setActingModeInput] = useState();
  const [actingMode, setActingMode] = useState();

  const roleAndPermission = useRoleAndPermission();
  const { formatDateTime, formatUnixDate } = useLocationDateTime();

  const request = {
    variables: {
      roomId: room.roomId,
      slotName: slot.slotName,
      nodeType: slot.nodeType,
      nodeMacInput: slot.nodeTypeCodeIdentifier.slice(-2) + get(slot, "currentMappedNode.mappedNode.nodeMacId"),
      nodeSubType: slot.nodeSubType,
    },
  };

  const [deleteSlotMutation] = useDeleteSlotMutation({
    variables: {
      id: slot.id,
    },
    onCompleted: () => {
      message.info(`Slot ${slot.slotName} has been deleted.`);
      slotChanged();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [renameSlotMutation] = useUpdateSlotMutation({
    onCompleted: () => {
      message.info("Slot has been renamed.");
      slotChanged();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [unmapNodeMutation] = useUnmapNodeMutation({
    onCompleted: ({ unmapNode }) => {
      setSlot(omit(slot, "currentMappedNode.mappedNode"));
      updateStatus(get(unmapNode, "currentKey.keyStats.mappingStatus"));
      updateMappedCount((counter: number) => counter - 1);
    },
    onError: noop,
  });

  const [mapNodeMutation] = useSingleMapNodeMutation({
    onCompleted: async ({ mapNode }) => {
      const mappedRoom = mapNode.currentKey?.rooms.find((r) => r.roomId === room.roomId);
      const mappedSlot = mappedRoom?.slots.find((s) => s.currentMappedNode?.mappedNode.nodeMacId === nodeMacInput);
      setSlot(assign(slot, { currentMappedNode: { mappedNode: mappedSlot?.currentMappedNode?.mappedNode } }));
      setModalOpen(false);
      updateStatus(get(mapNode, "currentKey.keyStats.mappingStatus"));
      updateMappedCount((counter: number) => counter + 1);
    },
    onError: setMapNodeError,
  });

  const [updatePositionConfigurationMutation] = useUpdatePositionConfigurationMutation({
    onCompleted() {
      if (slot.nodeSubType === NodeSubType.Aircon_4pfc) {
        message.success("Acting Mode has been updated.");
        setActingMode(actingModeInput);
      } else {
        message.success("Base AC Model has been updated.");
        setBaseAcModelId(baseAcModelIdInput);
      }
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    setSlot(slotProps);
    setBaseAcModelId(room.positionConfiguration?.acModelId ? room.positionConfiguration?.acModelId : null);
    setActingMode(room.positionConfiguration?.actingMode ? room.positionConfiguration?.actingMode : ActingMode.Default);
  }, [slotProps, room]);

  const nodeMapSubmit = (input: string) => {
    request.variables.nodeMacInput = input;
    mapNodeMutation(request);
    setNodeMacInput(input.substring(2));
  };

  const customHeaderNodeMappingProps = () => {
    return <HeaderContainer {...slot} />;
  };

  const nodeMappingProps = {
    ...pick(slot, ["nodeTypeCodeIdentifier", "nodeType", "slotName", "nodeSubType"]),
    roomId: room.roomId,
    nodeMapSubmit,
    customHeader: customHeaderNodeMappingProps,
    externalError: getExternalErrorComponent(mapNodeError, nodeMacInput),
  };

  const closeModalMapNode = () => {
    setModalOpen(false);
    setMapNodeError(undefined);
    setNodeMacInput(undefined);
  };

  const onClickUnmap = () => {
    confirm({
      className: "confirm-unmap-popup",
      title: "Unmap this node?",
      icon: <QuestionCircleOutlined className="question-icon" />,
      // TODO change the content popup message
      content: "Are you sure that you want to unmap this node?",
      okText: "Unmap node",
      okButtonProps: { className: "btn-umnap-node" },
      onOk() {
        unmapNodeMutation(request);
      },
    });
  };

  const renderActionButton = (): React.ReactElement => {
    const nodeMacId = slot.currentMappedNode?.mappedNode?.nodeMacId;
    if (nodeMacId) {
      return (
        <section className="slot-render-action-btn">
          <button type="button" className="unmap" onClick={onClickUnmap}>
            Unmap
          </button>
          <NodeLifeCycleEvent nodeMacId={nodeMacId} btnGhost />
        </section>
      );
    }
    return (
      <Button className="map" onClick={() => setModalOpen(true)}>
        Map
      </Button>
    );
  };

  const deleteSlot = () => {
    confirm({
      className: "confirm-delete-key-popup",
      icon: <ExclamationCircleFilled className="text-warning" />,
      content: `Are you sure you wish to delete ${slot.slotName}?`,
      okText: `Delete ${slot.slotName}`,
      okButtonProps: { className: "btn-popup-primary" },
      onOk() {
        deleteSlotMutation();
      },
    });
  };

  const renameSlot = (id: string, value: string) => {
    renameSlotMutation({
      variables: {
        id,
        updateSlotInput: {
          slotName: value,
        },
      },
    });
  };

  const changeBaseACModel = (value: any) => {
    setBaseAcModelIdInput(value);
    updatePositionConfigurationMutation({
      variables: {
        positionConfiguration: {
          positionId: room.roomId,
          acModelId: value,
        },
      },
    });
  };

  const changeActingMode = (value: any) => {
    setActingModeInput(value);
    updatePositionConfigurationMutation({
      variables: {
        positionConfiguration: {
          positionId: room.roomId,
          actingMode: value === ActingMode.Default ? null : value,
        },
      },
    });
  };

  const changeBaseACModelWithConfirm = (value: any) => {
    if (roleAndPermission.isInstaller()) {
      Modal.confirm({
        title: "Confirm changing AC Model",
        onOk: () => {
          changeBaseACModel(value);
        },
        onCancel: () => {
          setBaseAcModelId(null);
        },
      });
    } else {
      changeBaseACModel(value);
    }
  };

  const changeActingModeWithConfirm = (value: any) => {
    if (roleAndPermission.isInstaller()) {
      Modal.confirm({
        title: "Confirm changing Acting Mode",
        onOk: () => {
          changeActingMode(value);
        },
        onCancel: () => {
          setActingModeInput(null);
        },
      });
    } else {
      changeActingMode(value);
    }
  };

  const getStatusClass = (status: any) => {
    if (!status || status === "not map") return "not-map-status";
    return `${status.toLowerCase()}-status`;
  };

  const screen = useBreakpoint();

  const expandIcon = (isActive: boolean | undefined) => {
    return <CaretRightOutlined rotate={isActive ? 90 : 0} />;
  };

  return (
    <Col md={24} lg={roleAndPermission.isInstaller() || roleAndPermission.isPC() ? 12 : 6} className="mb-l">
      <div
        className={classnames("d-flex flex-wrap mb-l", {
          "h-100": screen.desktopUp,
          "extended-mode": roleAndPermission.isInstaller() || roleAndPermission.isPC(),
        })}
      >
        <div
          className={classnames("slot", {
            "w-100": screen.mobileAndTabletOnly,
            "w-50": screen.desktopUp && (roleAndPermission.isInstaller() || roleAndPermission.isPC()),
          })}
        >
          <div className="slot-name">
            <span className={classnames("font-weight-bold fs-sm d-block")}>SLOT NAME</span>
            <div
              className={classnames("d-flex align-items-center", {
                "justify-content-between": screen.mobileAndTabletOnly,
              })}
            >
              <span>{slot.slotName}</span>&nbsp;
              <Can
                requiredPermission={Permission.KEY_EDIT}
                yes={
                  !roleAndPermission.isContractor() && (
                    <ActionButton
                      data-testid={`rename-slot-testId-${slot.id}`}
                      type="edit"
                      btnClassName={screen.classes.hiddenOnMobile}
                      className="fs-l"
                      onClick={() => setIsRenameSlotModalOpen(true)}
                    />
                  )
                }
              />
              <Can
                requiredPermission={Permission.KEY_EDIT}
                yes={
                  !roleAndPermission.isContractor() && (
                    <ActionButton
                      data-testid={`delete-slot-testId-${slot.id}`}
                      className="pl-sm fs-l"
                      type="delete"
                      btnClassName={screen.classes.hiddenOnMobile}
                      onClick={deleteSlot}
                    />
                  )
                }
              />
              <div className={screen.classes.hiddenOnDesktop}>
                <MenuDropdown
                  menu={
                    <Menu>
                      <Menu.Item onClick={() => setIsRenameSlotModalOpen(true)}>Rename Slot</Menu.Item>
                      <Menu.Item onClick={() => deleteSlot()}>Delete Slot</Menu.Item>
                      <Menu.Divider />
                      {slot.currentMappedNode?.mappedNode?.nodeMacId ? (
                        <Menu.Item onClick={onClickUnmap}>Unmap Slot</Menu.Item>
                      ) : (
                        <Menu.Item onClick={() => setModalOpen(true)}>Map slot</Menu.Item>
                      )}
                    </Menu>
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={classnames("d-flex justify-content-between", {
              "flex-column": screen.desktopUp,
              "align-items-end": screen.mobileAndTabletOnly,
            })}
          >
            {get(slot, "currentMappedNode.mappedNode.nodeMacId") && (
              <div className="d-flex align-items-start justify-content-start flex-column">
                <span className={classnames("font-weight-bold fs-sm d-block")}>NODE ID</span>
                <div>
                  <span>{get(slot, "currentMappedNode.mappedNode.nodeMacId", "-")}</span>
                  {(roleAndPermission.isInstaller() || roleAndPermission.isPC()) && (
                    <CopyButton input={get(slot, "currentMappedNode.mappedNode.nodeMacId", "-")} />
                  )}
                </div>
              </div>
            )}
            <p className="node-type">{getNodeSubTypeDisplayName(slot.nodeType, slot.nodeSubType?.toString() || "")}</p>
          </div>
          <div className="mb-m">
            <Can
              requiredRole={ROLE.PROJECT_COORDINATOR}
              requiredPermission={Permission.KEY_EDIT}
              yes={
                isShowBaseACModelDropdown(slot.nodeType, slot.nodeSubType, slot.currentMappedNode?.mappedNode) && (
                  <BaseACModelDropdown
                    baseAcModelId={baseAcModelId}
                    onChange={changeBaseACModelWithConfirm}
                    dataTestId="slot-ac-base-model-testId"
                  />
                )
              }
            />
          </div>
          <div className="mb-m">
            <Can
              requiredPermission={Permission.KEY_EDIT}
              yes={
                slot.nodeSubType === NodeSubType.Aircon_4pfc && (
                  <div>
                    <span className="d-block text-scorpion font-weight-bold fs-sm text-uppercase mb-xs mt-s">
                      Acting Mode
                    </span>
                    <Select
                      className="w-100"
                      data-testid="slot-acting-mode-testId"
                      value={actingMode}
                      onChange={changeActingModeWithConfirm}
                      defaultValue={ActingMode.Default}
                    >
                      <Select.Option value={ActingMode.Default}>Default</Select.Option>
                      <Select.Option value={ActingMode.TwoPFC}>2PFC</Select.Option>
                    </Select>
                  </div>
                )
              }
            />
          </div>
          {screen.desktopUp && (
            <Can
              requiredRole={ROLE.PROJECT_COORDINATOR}
              requiredPermission={Permission.SLOT_MAP}
              yes={renderActionButton()}
            />
          )}
        </div>
        {(roleAndPermission.isInstaller() || roleAndPermission.isPC()) && (
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => expandIcon(isActive)}
            className={classnames("bg-light-gray", {
              "w-100": screen.mobileAndTabletOnly,
              "w-50": screen.desktopUp,
            })}
          >
            <Panel
              showArrow={!screen.desktopUp}
              header={
                <div
                  aria-hidden="true"
                  className="d-flex fs-sm justify-content-between"
                  onClick={(event) => screen.desktopUp && event.stopPropagation()}
                >
                  <div className="d-flex align-items-center">
                    <span className={classnames("p-xs text-white node-status", getStatusClass(extendedSlot.status))}>
                      <LiveValue value={extendedSlot.status} hasData={subscriptionLoaded} />
                    </span>
                    <div className="d-flex flex-column pl-s">
                      <span className="font-weight-bold">LAST SEEN</span>
                      <span>
                        <LiveValue
                          value={extendedSlot.lastSeen ? formatDateTime(extendedSlot.lastSeen) : null}
                          hasData={subscriptionLoaded}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="font-weight-bold">SIGNAL STRENGTH</span>
                    <span
                      className={classnames({ "text-danger": extendedSlot.signalStrength < WARNING_SIGNAL_STRENGTH })}
                    >
                      <LiveValue value={extendedSlot.signalStrength} hasData={subscriptionLoaded} />
                    </span>
                  </div>
                </div>
              }
              key="1"
              className="bg-light-gray pl-none custom-collapsible"
            >
              <div
                className={classnames("d-flex flex-column", {
                  "ml-l": screen.mobileAndTabletOnly,
                })}
              >
                <div className="d-flex fs-sm justify-content-between mt-m">
                  <div className="d-flex flex-column w-50">
                    <span className="font-weight-bold">BOOT TIME</span>
                    <span>
                      <LiveValue value={formatUnixDate(extendedSlot.bootTime)} hasData={subscriptionLoaded} />
                    </span>
                  </div>
                  <div className="d-flex flex-column w-50">
                    <span className="font-weight-bold">48HR NODE JOIN</span>
                    <span>
                      <LiveValue value={extendedSlot.recentJoinCount} hasData={subscriptionLoaded} />
                    </span>
                  </div>
                </div>
                <div className="d-flex fs-sm justify-content-between mt-m">
                  <div className="d-flex flex-column w-50">
                    <span className="font-weight-bold">MAPPED ON</span>
                    <span>
                      <LiveValue value={formatDateTime(extendedSlot.mappedTime)} hasData={subscriptionLoaded} />
                    </span>
                  </div>
                  <div className="d-flex flex-column w-50">
                    <span className="font-weight-bold">FW VERSION</span>
                    <span>
                      <LiveValue value={extendedSlot.firmwareVersion} hasData={subscriptionLoaded} />
                    </span>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        )}
      </div>
      <Modal
        className="map-node-modal"
        title="Map Node"
        footer={null}
        visible={isModalOpen}
        onCancel={closeModalMapNode}
        destroyOnClose
      >
        <NodeMappingComponent {...nodeMappingProps} />
      </Modal>
      {isRenameSlotModalOpen && (
        <RenameFormModal
          popupTitle="Rename Slot"
          popupOkText="Rename Slot"
          onSubmit={renameSlot}
          objectName={slot.slotName}
          objectId={slot.id}
          close={() => setIsRenameSlotModalOpen(false)}
          onClose={noop}
          regexPattern=""
        />
      )}
    </Col>
  );
};

export default SlotComponent;
