import { Col, Drawer, Row } from "antd";
import useBreakpoint from "hooks/use-breakpoint";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import BookingStatusIcon from "../components/BookingStatusIcon";
import { getBookingListColumnConfig } from "./BookingListColumnConfig";
import { getBookingStatus } from "./helpers";
import { BookingRecord } from "./types";

interface IBookingDetailsCommonProps {
  booking?: BookingRecord;
}

interface IBookingDetailsDrawerProps extends IBookingDetailsCommonProps {
  location?: LocationWithPmsProperty;
  onClose: () => void;
}

const BookingDetailsDrawerTitle = ({ booking }: IBookingDetailsCommonProps) => {
  return (
    <div>
      <p className="font-weight-bold py-none fs-sm">Booking ID</p>
      <Row justify="start" gutter={[16, 0]} align="bottom">
        <Col>
          <p className="font-weight-bold fs-xl my-none">{booking?.id}</p>
        </Col>
        <Col className="fs-m">
          <BookingStatusIcon status={booking && getBookingStatus(booking)} screen={{ mobileOnly: true }} />
        </Col>
      </Row>
    </div>
  );
};

const BookingDetailsDrawer = ({ booking, location, onClose }: IBookingDetailsDrawerProps) => {
  const screen = useBreakpoint();
  const bookingInfoRenderers = React.useMemo(
    () =>
      getBookingListColumnConfig({ location, screen: { mobileOnly: true } }).filter((col) => col.visibleOnDetailView),
    [location]
  );
  return (
    <Drawer
      width={screen.tabletUp ? 450 : "100%"}
      title={<BookingDetailsDrawerTitle booking={booking} />}
      visible={!!booking}
      closable
      onClose={onClose}
      headerStyle={{ paddingTop: "9px", paddingLeft: "20px" }}
      bodyStyle={{ padding: "20px" }}
    >
      <h4 className="font-weight-bold">General information</h4>
      <ul className="pl-l py-m fs-m">
        {bookingInfoRenderers.map((renderer, index) => (
          <li key={index}>
            <Row gutter={[4, 8]}>
              <Col span={15}>{renderer.title}</Col>
              <Col span={9}>{booking && renderer.render(booking)}</Col>
            </Row>
          </li>
        ))}
      </ul>
    </Drawer>
  );
};

export default BookingDetailsDrawer;
