export default [
  {
    positionId: "123410e2-9fc9-4b15-9902-c6d937f61d03",
    positionName: "Bedroom 9",
    gatewayMapping: [
      {
        gateway: {
          gatewayId: "a8684e69-f01d-4649-9750-e33675785850",
          gatewayName: "Gateway 1",
        },
      },
    ],
    slotMappings: [
      {
        slotName: "Occupancy (Dining)",
        nodeType: "aircon",
        nodeMacId: "FFFF14B4570DF169",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: true,
        slot: {
          id: "48617ccb-ca7c-414b-8a89-95dc357fa317",
        },
        node: {
          node_mac_id: "FFFF14B4570DF169",
          nodeOnlineStatus: {
            nodeStatus: "OFFLINE",
            lastTransmission: null,
            nodeStatusInInstallationMode: "OFFLINE",
          },
          nodeJoinStrengthLive: {
            signalStrength: 0,
          },
          nodeMetaStatus: {
            recentJoinCount: 1,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "ACIR",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE2",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "ed40b435-9f63-4741-b464-5782f2d06cb2",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "FFFF14B4577AC3EE2",
          nodeOnlineStatus: {
            nodeStatus: "OFFLINE",
            lastTransmission: null,
            nodeStatusInInstallationMode: "OFFLINE",
          },
          nodeJoinStrengthLive: {
            signalStrength: 0,
          },
          nodeMetaStatus: {
            recentJoinCount: 1,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          nodeMetaData: [],
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "Door Main",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE3",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "c09ed05a-c3c2-4e49-bfb6-f82712cb3fa6",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "FFFF14B4577AC3EE3",
          nodeOnlineStatus: {
            nodeStatus: "OFFLINE",
            lastTransmission: null,
            nodeStatusInInstallationMode: "OFFLINE",
          },
          nodeJoinStrengthLive: {
            signalStrength: 0,
          },
          nodeMetaStatus: {
            recentJoinCount: 0,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "Door Balcony",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE4",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "33798e49-2f60-4053-8173-ec1625461438",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "FFFF14B4577AC3EE4",
          nodeOnlineStatus: {
            nodeStatus: "OFFLINE",
            lastTransmission: null,
            nodeStatusInInstallationMode: "OFFLINE",
          },
          nodeJoinStrengthLive: {
            signalStrength: 0,
          },
          nodeMetaStatus: {
            recentJoinCount: 0,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
    ],
    __typename: "sensorflow_positions",
  },
  {
    positionId: "123410e2-9fc9-4b15-9902-c6d937f61d02",
    positionName: "Bedroom 9",
    gatewayMapping: [
      {
        gateway: {
          gatewayId: "a8684e69-f01d-4649-9750-e33675785850",
          gatewayName: "Gateway 1",
        },
      },
    ],
    slotMappings: [
      {
        slotName: "Occupancy (Dining)",
        nodeType: "aircon",
        nodeMacId: "FFFF14B4570DF169",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: true,
        slot: {
          id: "48617ccb-ca7c-414b-8a89-95dc357fa317",
        },
        node: {
          nodeMetaStatus: {
            recentJoinCount: 1,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "ACIR",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE2",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "ed40b435-9f63-4741-b464-5782f2d06cb2",
          __typename: "sensorflow_node_slots",
        },
        node: {
          nodeMetaStatus: {
            recentJoinCount: 1,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          nodeMetaData: [],
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "Door Main",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE3",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "c09ed05a-c3c2-4e49-bfb6-f82712cb3fa6",
          __typename: "sensorflow_node_slots",
        },
        node: {
          nodeMetaStatus: {
            recentJoinCount: 0,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slotName: "Door Balcony",
        nodeType: "occupancy",
        nodeMacId: "FFFF14B4577AC3EE4",
        mappedTime: "2021-02-10T09:45:44+00:00",
        isPrimaryNode: null,
        slot: {
          id: "33798e49-2f60-4053-8173-ec1625461438",
          __typename: "sensorflow_node_slots",
        },
        node: {
          nodeMetaStatus: {
            recentJoinCount: 0,
            bootTime: 1606241456,
            firmwareVersion: "1.2.0",
            __typename: "sensorflow_node_join_status",
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
    ],
    __typename: "sensorflow_positions",
  },
];
