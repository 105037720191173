import React from "react";

import { Row, Col } from "antd";

import SearchBar from "components/SearchBar/SearchBar";
import { Order_By, useGetNodeLifeCycleHistoryQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import NodeLifeCycleEvent from "../NodeLifeCycleEvent/NodeLifeCycleEvent";

import { NodeManagementHeaderProps } from "./NodeManagementHeader.d";

const NodeManagementHeader = ({ handleSearchChange }: NodeManagementHeaderProps) => {
  const { refetch } = useGetNodeLifeCycleHistoryQuery({
    variables: { order_by: [{ changedAt: Order_By.Desc }] },
    onError: errorHandler.handleError,
  });
  return (
    <Row justify="space-between" style={{ marginBottom: "1em" }}>
      <Col span={12}>
        <h2>Node life cycle events</h2>
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <NodeLifeCycleEvent
          label="Record lifecycle event(s)"
          nodeMacInputOptions={{
            scan: { isVisible: true },
            input: { title: "Enter Node ID" },
          }}
          refetch={() => refetch()}
        />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }}>
        <SearchBar onInputChange={handleSearchChange} placeholder="Type node mac id" autoFocus />
      </Col>
    </Row>
  );
};

export default NodeManagementHeader;
