import get from "lodash/get";
import { NodeSubType, NodeType } from "../pacts/app-webcore/hasura-webcore.graphql";

export interface INodeTypeDisplay {
  code: string;
  name: string;
}

const NodeTypeDisplay: { [key: string]: INodeTypeDisplay } = {
  [NodeSubType.Aircon_4pfc]: {
    code: "DEV-000023",
    name: "Aircon (4PFC Thermostat)",
  },
  [NodeSubType.AirconAcir]: {
    code: "DEV-000007",
    name: "Aircon (ACIR)",
  },
  [NodeSubType.Aircon_2pfc]: {
    code: "DEV-000003",
    name: "Aircon (2PFC Thermostat)",
  },
  [NodeSubType.AirconDaikin]: {
    code: "DEV-000002",
    name: "Aircon (Daikin EFD Thermostat)",
  },
  [NodeSubType.Aircon_2pfcAicool]: {
    code: "DEV-000011",
    name: "Aircon (2PFC Thermostat, Aicool)",
  },
  [NodeSubType.Aircon_2pfcPlc]: {
    code: "DEV-000013",
    name: "Aircon (2PFC Thermostat, PLC)",
  },

  [NodeSubType.OccupancyCeiling]: {
    code: "DEV-000001",
    name: "Occupancy (Ceiling)",
  },
  [NodeSubType.OccupancyWall]: {
    code: "DEV-000009",
    name: "Occupancy (Wall)",
  },
  [NodeSubType.DoorLaser]: {
    code: "DEV-000005",
    name: "Door (Laser)",
  },
  [NodeSubType.DoorMagnetic]: {
    code: "DEV-000016",
    name: "Door (Magnetic)",
  },
  [NodeType.Door]: {
    code: "",
    name: "Door",
  },
  [NodeType.Energy]: {
    code: "DEV-000006",
    name: "Energy Monitor",
  },
  [NodeType.PipeTemp]: {
    code: "DEV-000004",
    name: "Pipe Temperature",
  },
  [NodeType.FlowMeter]: {
    code: "DEV-000010",
    name: "Flowmeter Interface",
  },
  [NodeType.Occupancy]: {
    code: "",
    name: "Occupancy",
  },
  [NodeType.Aircon]: {
    code: "",
    name: "Aircon",
  },
};

export const getNodeTypeDisplayValue = (nodeType: string) => {
  if (nodeType in NodeTypeDisplay) {
    return NodeTypeDisplay[nodeType].name;
  }
  return nodeType;
};

export const getNodeSubTypeDisplayName = (nodeType: string, nodeSubType: string) => {
  if (nodeSubType) {
    if (nodeSubType in NodeTypeDisplay) {
      return get(NodeTypeDisplay[nodeSubType], "name");
    }
    return nodeSubType;
  }
  // does not have nodeSubType then fall backs to node type
  return getNodeTypeDisplayValue(nodeType);
};

export default NodeTypeDisplay;
