import React from "react";

import { Button, Alert } from "antd";
import Countdown from "react-countdown";
import { createKeyZpl, createRoomZpl } from "services/zpl/helper";
import useRoomLabelPrinting from "hooks/use-room-print";
import useKeyLabelPrinting from "hooks/use-key-print";
import FullyMappedAlert from "./FullyMappedAlert";
import "./FullyMappedComponent.css";

const getLabelPreviewLink = (zpl: string) => {
  return `http://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/${zpl}`;
};

const FullyMappedComponent = ({
  isKeyFullyMapped,
  roomId,
  keyId,
  roomName,
  keyName,
  timer = 30000,
  continueScanning,
}: any) => {
  const {
    loading: roomDetailsLoading,
    error: roomDetailsError,
    printError: printRoomError,
    details: roomDetails,
  } = useRoomLabelPrinting(roomId);

  const {
    loading: keyDetailsLoading,
    error: keyDetailsError,
    printError: printKeyError,
    details: keyDetails,
  } = useKeyLabelPrinting(keyId, isKeyFullyMapped);

  const renderer = ({ seconds }: any) => (
    <div id="label-printing-wrapper">
      <FullyMappedAlert message={roomName} description="Fully mapped. Printing room label..." />
      {roomDetails && (
        <img
          width="70%"
          src={getLabelPreviewLink(createRoomZpl(roomDetails))}
          alt="room label"
          data-testid="room-label-testid"
        />
      )}
      {printRoomError && <Alert type="error" message={`${printRoomError?.message}`} />}
      {isKeyFullyMapped && (
        <>
          <FullyMappedAlert message={keyName} description="Fully mapped. Printing room label..." />
          {keyDetails && (
            <img
              width="70%"
              src={getLabelPreviewLink(createKeyZpl(keyDetails))}
              alt="key label"
              data-testid="key-label-testid"
            />
          )}
          {printKeyError && <Alert type="error" message={`${printKeyError?.message}`} />}
        </>
      )}

      <p id="countdown-text">Screen will auto advance in {seconds} seconds</p>
      <Button type="primary" id="continue-scanning" onClick={continueScanning}>
        Continue scanning
      </Button>
    </div>
  );

  if (roomDetailsLoading || keyDetailsLoading) return <p>Loading...</p>;
  if (roomDetailsError || keyDetailsError) {
    return <Alert type="error" message="Error getting label printing APIs" />;
  }

  return <Countdown date={Date.now() + timer} onComplete={continueScanning} renderer={renderer} />;
};

export default FullyMappedComponent;
