import React from "react";
import classnames from "classnames";
import { Tooltip } from "antd";
import styles from "./styles.module.scss";

export interface ObjectHealthProps {
  online: number;
  offline: number;
  booted: number;
  className?: string;
  loading?: boolean;
}

const ObjectHealth = ({ offline, online, booted, className, loading = false }: ObjectHealthProps) => {
  const onlineCount = loading ? "_" : online ?? 0;
  const offlineCount = loading ? "_" : offline ?? 0;
  const bootedCount = loading ? "_" : booted ?? 0;
  const total = loading ? "_" : (offline ?? 0) + (online ?? 0) + (booted ?? 0);
  return (
    <span className={classnames(styles.wrapper, className, "key-health")}>
      <Tooltip title="Online">
        <span className={classnames(styles.online, "cursor-pointer")}>{onlineCount}</span>
      </Tooltip>
      <span>/</span>
      <Tooltip title="Offline">
        <span className={classnames(styles.offline, "cursor-pointer")}>{offlineCount}</span>
      </Tooltip>
      <span>/</span>
      <Tooltip title="Booted">
        <span className={classnames(styles.booted, "cursor-pointer")}>{bootedCount}</span>
      </Tooltip>
      <span>/</span>
      <Tooltip title="Total">
        <span className={classnames(styles.total, "cursor-pointer")}>{total}</span>
      </Tooltip>
    </span>
  );
};

export default ObjectHealth;
