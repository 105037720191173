import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Menu, message, Row } from "antd";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  MailOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import {
  GatewayHealthStatus,
  useGatewayImageDownloadUrlLazyQuery,
  useTriggerToUploadGatewayImagesMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import "./GatewayAction.scss";
import { RoleContext } from "contexts/role-context";
import { Permission } from "pacts/permission";
import useBreakpoint from "hooks/use-breakpoint";
import MenuDropdown from "components/MenuDropdown";
import errorHandler from "errorHandler";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { canPerform } from "permissionHelpers";
import fileUtil from "utils/file";
import { ButtonRMA } from "components/ButtonRMA";
import GetHelpButton from "components/GetHelpButton";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { getGatewayDetailLink } from "utils/link";
import GetHelpMenuItem from "components/GetHelpMenuItem";
import { Gateway } from "../GatewayList/GatewayList.d";
import GatewayTroubleshoot from "../GatewayTroubleshoot/GatewayTroubleshoot";

type GatewayActionProps = {
  gateway: Gateway;
  showDeleteModal?: (gatewayId: string, gatewayName: string, healthStatus: GatewayHealthStatus) => void;
  showRenameModal?: (gatewayId: string, gatewayName: string) => void;
  reloadGateways?: () => void;
};

enum TroubleshootIssues {
  NOT_BOOTED,
  ERROR_INITIALISING_LORA,
  UNKNOWN_ERROR,
}

const TROUBLESHOOT_STEPS = {
  [TroubleshootIssues.NOT_BOOTED]: {
    headerText: "Troubleshooting Steps – Unable to boot",
    subText: "Wait 5 mins in between steps",
    troubleshootSteps: [
      {
        icon: <ThunderboltOutlined />,
        step: "1. Try with a different power supply",
        description: `Remove the Gateway from the power supply and plug it into a different one and check if the LEDs
        are blinking. If the LEDs don’t blink, try again with a known working power supply.`,
      },
      {
        icon: <WifiOutlined />,
        step: "2. Check that there is internet connectivity",
        description: "Check if the WiFi router is powered and working.",
      },
      {
        icon: <CloudDownloadOutlined />,
        step: "3. Reflash SD card",
        description: "Re-download the Gateway Image and flash the SD Card with the newly downloaded Gateway Image.",
      },
      {
        icon: <ToolOutlined />,
        step: "4. Replace hardware",
        description: "Replace the Raspberry Pi with Gateway. Return the faulty Raspberry Pi to SensorFlow.",
      },
    ],
  },
  [TroubleshootIssues.ERROR_INITIALISING_LORA]: {
    headerText: "Troubleshooting Steps – Unable to initialise LORA",
    subText: "",
    troubleshootSteps: [
      {
        icon: <ToolOutlined />,
        step: "Reseat the board (will supplement with GIF/Images)",
        description: `Unscrew the PCB boards from the Raspberry Pi and detach them. Re-assemble the Gateway, making
        sure that the IO pins are aligned before screwing them together on the stand-offs.`,
      },
    ],
  },
  [TroubleshootIssues.UNKNOWN_ERROR]: {
    headerText: "Troubleshooting Steps – Unknown error",
    subText: "",
    troubleshootSteps: [
      {
        icon: <MailOutlined />,
        step: "Contact SensorFlow",
        description: "Replace the Raspberry Pi with Gateway. Return the faulty Raspberry Pi to SensorFlow.",
      },
    ],
  },
};

const GatewayAction = ({ gateway, showDeleteModal, showRenameModal, reloadGateways }: GatewayActionProps) => {
  const role = useContext(RoleContext);
  const [isTroubleshootModalOpen, setIsTroubleshootModalOpen] = useState<boolean>(false);
  const { kittingStatus } = gateway;
  const screen = useBreakpoint();
  const roleAndPermission = useRoleAndPermission();

  const { locationName } = useSensorflowLocation();

  const [triggerToUploadGatewayImages] = useTriggerToUploadGatewayImagesMutation({
    onCompleted: () => {
      message.success("Re-try to upload gateway image is successful");
      if (reloadGateways) reloadGateways();
    },
    onError: errorHandler.handleError,
  });

  const [getGatewayImageDownloadUrl, { data: gatewayImageDownloadData, loading }] =
    useGatewayImageDownloadUrlLazyQuery();

  useEffect(() => {
    if (gatewayImageDownloadData && !loading) {
      fileUtil.triggerDownloadFile(
        gatewayImageDownloadData.gatewayImageDownloadUrl,
        `gatewayImage-${gateway.gatewayName}.img`
      );
    }
  }, [gatewayImageDownloadData, loading, gateway.gatewayName]);

  const downloadGatewayImage = () => {
    getGatewayImageDownloadUrl({
      variables: {
        gatewayId: gateway.gatewayId,
      },
    });
  };

  const handleClickUploadImage = () => {
    triggerToUploadGatewayImages({
      variables: {
        gatewayIds: [gateway.gatewayId],
      },
    });
  };

  const canDownloadImage = () => {
    return (
      canPerform(role, Permission.GATEWAY_DOWNLOAD_IMAGE) &&
      kittingStatus !== GatewayHealthStatus.ErrorProvisioningImage &&
      kittingStatus !== GatewayHealthStatus.PreparingImage
    );
  };

  const shouldShowRetryButton =
    kittingStatus === GatewayHealthStatus.ErrorProvisioningImage &&
    roleAndPermission.canPerform(Permission.GATEWAY_CREATE);

  const troubleshoot = useMemo(() => {
    let res;
    switch (kittingStatus) {
      case GatewayHealthStatus.Verifying:
      case GatewayHealthStatus.NotBooted:
        res = TROUBLESHOOT_STEPS[TroubleshootIssues.NOT_BOOTED];
        break;
      case GatewayHealthStatus.Error:
        res = TROUBLESHOOT_STEPS[TroubleshootIssues.UNKNOWN_ERROR];
        break;
      case GatewayHealthStatus.LoraError:
        res = TROUBLESHOOT_STEPS[TroubleshootIssues.ERROR_INITIALISING_LORA];
        break;
      default:
    }
    return res;
  }, [kittingStatus]);

  const helpText = useMemo(() => {
    return `Gateway ${gateway.gatewayName} - Location ${locationName}: ${getGatewayDetailLink(gateway.gatewayId)}`;
  }, [gateway, locationName]);

  if (screen.desktopUp) {
    if (kittingStatus === GatewayHealthStatus.PreparingImage) {
      return <span className="image-available">Image will be available for download once ready.</span>;
    }

    if (shouldShowRetryButton) {
      return (
        <Button type="primary" onClick={handleClickUploadImage}>
          Try again
        </Button>
      );
    }
  }

  if (screen.mobileAndTabletOnly) {
    return (
      <MenuDropdown
        mode="horizontal"
        menu={
          <Menu>
            {
              // cannot use <Can /> here because it would break the UI, Menu.Item needs to be direct child of Menu
              canPerform(role, Permission.GATEWAY_UPDATE) && (
                <Menu.Item key="rename" onClick={() => showRenameModal!(gateway.gatewayId, gateway.gatewayName)}>
                  Rename Gateway
                </Menu.Item>
              )
            }

            {kittingStatus !== GatewayHealthStatus.PreparingImage && canPerform(role, Permission.GATEWAY_DELETE) && (
              <>
                <Menu.Divider />
                <Menu.Item
                  key="delete"
                  onClick={() => showDeleteModal!(gateway.gatewayId, gateway.gatewayName, gateway.kittingStatus)}
                >
                  Delete Gateway
                </Menu.Item>
              </>
            )}

            {canDownloadImage() && (
              <>
                <Menu.Divider />
                <Menu.Item key="download_image" onClick={() => downloadGatewayImage()}>
                  Download Image
                </Menu.Item>
              </>
            )}
            {troubleshoot && (
              <>
                <Menu.Divider />
                <Menu.Item key="troubleshoot" onClick={() => setIsTroubleshootModalOpen(true)}>
                  Troubleshoot
                </Menu.Item>

                <GatewayTroubleshoot
                  visible={isTroubleshootModalOpen}
                  setVisible={setIsTroubleshootModalOpen}
                  headerText={troubleshoot?.headerText}
                  subText={troubleshoot?.subText}
                  troubleshootSteps={troubleshoot?.troubleshootSteps}
                />
              </>
            )}
            {shouldShowRetryButton && (
              <>
                <Menu.Divider />
                <Menu.Item key="try_again" onClick={() => handleClickUploadImage()}>
                  Try again
                </Menu.Item>
              </>
            )}

            {(roleAndPermission.isInstaller() || roleAndPermission.isPC()) && (
              <>
                <Menu.Divider />
                <Menu.Item key="RMA">
                  <ButtonRMA gatewayRecord={gateway} type="text" buttonClasses="p-none w-100 text-left" />
                </Menu.Item>
                <Menu.Divider />
                <GetHelpButton
                  helpText={helpText}
                  as={({ triggerHelp, helpText: text }) =>
                    GetHelpMenuItem({
                      onClick: triggerHelp,
                      disabled: !text,
                      key: "Get help",
                      className: "px-m py-xs",
                    })
                  }
                />
              </>
            )}
          </Menu>
        }
      />
    );
  }

  return (
    <Row>
      {canDownloadImage() && (
        <Col span={24}>
          <Button
            icon={<DownloadOutlined />}
            type="link"
            className="action-download-image"
            onClick={() => downloadGatewayImage()}
          >
            Download Image
          </Button>
        </Col>
      )}
      {troubleshoot && (
        <>
          <Col span={24}>
            <Button type="link" danger className="action-troubleshoot" onClick={() => setIsTroubleshootModalOpen(true)}>
              Troubleshoot
            </Button>
          </Col>
          <GatewayTroubleshoot
            visible={isTroubleshootModalOpen}
            setVisible={setIsTroubleshootModalOpen}
            headerText={troubleshoot?.headerText}
            subText={troubleshoot?.subText}
            troubleshootSteps={troubleshoot?.troubleshootSteps}
          />
        </>
      )}
      {(roleAndPermission.isInstaller() || roleAndPermission.isPC()) && (
        <>
          <Col span={24}>
            <ButtonRMA gatewayRecord={gateway} />
          </Col>
          <Col span={24}>
            <GetHelpButton className="mt-xs" helpText={helpText} />
          </Col>
        </>
      )}
    </Row>
  );
};

export default GatewayAction;
