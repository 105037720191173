import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import isNil from "lodash/isNil";
import { getACView, getDoorStatusIcon, getIsDeviatedFromDefaultIcon, getOccupancyStateIcon } from "./KeyList.helper";
import { Key } from "./KeyList.d";
import { AutomationMode } from "../types";
import { getAutomationModeText, NotAvailable } from "../key.helper";
import usePrecoolCountDown from "../hooks/use-precool-countdown";

const KeyMobileItem = (props: {
  locationId: string;
  record: Key;
  onCancel?: (e: React.MouseEvent<HTMLSpanElement>) => void | Promise<void>;
}) => {
  const { record, locationId, onCancel } = props;

  const { currentTime, getRemainingTime } = usePrecoolCountDown();

  let key: Key | null = null;
  if (record.type === "key" && record.rooms.length === 1) {
    key = record.rooms[0] as any as Key;
  }
  if (record.type === "room") {
    key = record;
  }
  return (
    <Link
      to={`/locations/${locationId}/keys/${record.type === "room" ? record.parentId : record.positionId}`}
      className="text-black"
    >
      <div className="d-flex flex-row">
        <div className="w-100 pl-s">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-none">{record.positionName}</h3>
            {key && (
              <div className="d-flex justify-content-between align-items-center">
                {key.hasOccupancy && getOccupancyStateIcon(key.occupancyState)}
                {key.hasDoor && getDoorStatusIcon(key.doorStatus)}
                {key.isDefaultConfig === true ? getIsDeviatedFromDefaultIcon(key.isDefaultConfig) : ""}
              </div>
            )}
          </div>
          {key && (
            <div className="d-flex justify-content-between flex-column mt-m">
              <div className="mb-m">
                <span className="d-block fs-sm mb-s">HVAC Settings</span>
                <span className="d-block">
                  {key.hasHVAC ? (
                    getACView(
                      key,
                      key.acPrecoolStatus,
                      getRemainingTime(key.acPrecoolExpiredTime, key.acPrecoolStatus, currentTime, null),
                      onCancel
                    )
                  ) : (
                    <NotAvailable />
                  )}
                </span>
              </div>
              <div>
                <span className="d-block fs-sm mb-s">Automation Mode</span>
                <span className={classnames({ "text-volcano": key.automationMode === AutomationMode.Disabled })}>
                  {!isNil(key.automationMode) ? getAutomationModeText(key.automationMode!) : "NA"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default KeyMobileItem;
