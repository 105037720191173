import React, { useState } from "react";
import errorHandler from "errorHandler";

import { Button, message } from "antd";
import {
  useRecordNewNodeLifeCycleEventMutation,
  useUpdateNodeLifeCycleEventMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";

import { NodeLifeCycleEventProps } from "./NodeLifeCycleEvent.d";
import { CreateNodeLifeCycleEvent, FormOperations, FormData } from "../CreateNodeLifeCycleEvent";

const initialValues = {
  nodeMacId: "",
  eventType: undefined,
  comment: "",
};

const NodeLifeCycleEvent = ({ nodeMacId, btnGhost, label, nodeMacInputOptions, refetch }: NodeLifeCycleEventProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [formOperation, setFormOperation] = useState<FormOperations>(FormOperations.CREATE);
  const [values, setValues] = useState<FormData>(initialValues);

  const [create] = useRecordNewNodeLifeCycleEventMutation({
    onError: errorHandler.handleError,
    onCompleted: (dataNodeLifeCycle) => {
      refetch?.();
      message.success(
        `Record life cycle event successfully for node ${dataNodeLifeCycle.createNodeLifeCycleEvent?.nodeMacId}`
      );
    },
  });

  const [update] = useUpdateNodeLifeCycleEventMutation({
    onError: errorHandler.handleError,
    onCompleted: () => {
      refetch?.();
      setOpenModal(false);
    },
  });

  const handleOnSubmit = async (value: FormData) => {
    const { id, nodeTypeCode, creationDate, ...input } = value;
    if (formOperation === FormOperations.UPDATE && id) {
      await update({ variables: { id, input } });
    } else {
      if (!input.nodeMacId || !input.eventType) {
        message.error(`Cannot record life cycle event without Node Mac ID or Event Type`);
        return;
      }
      await create({
        variables: {
          nodeMacId: input.nodeMacId,
          eventType: input.eventType,
          comment: input.comment,
          creationDate,
          nodeTypeCode,
        },
      });
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    refetch?.();
  };

  return (
    <>
      <Button
        type="primary"
        ghost={btnGhost}
        onClick={() => {
          setOpenModal(true);
          setValues({ nodeMacId: nodeMacId ?? "" });
          setFormOperation(FormOperations.CREATE);
        }}
      >
        {label ?? "Record lifecycle event"}
      </Button>
      {openModal && (
        <CreateNodeLifeCycleEvent
          formData={values}
          formOperation={formOperation}
          closeModal={closeModal}
          nodeMacInputOptions={{
            scan: { isVisible: false },
            input: {
              tabTitle: "Node ID",
              disabled: nodeMacId !== undefined,
            },
            ...(nodeMacInputOptions ?? {}),
          }}
          handleOnSubmit={handleOnSubmit}
        />
      )}
    </>
  );
};

export default React.memo(NodeLifeCycleEvent);
