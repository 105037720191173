import { ApolloProvider } from "@apollo/react-hooks";
import React, { useState, createContext, useMemo, useCallback } from "react";
import GraphAPI from "services/graphql/graphAPI";
import mockGraphQLClient from "services/graphql/mockGraphQLClient";
import { EAuthType } from "utils/constants";

export interface IApiContextState {
  accessToken?: string | null;
  setAccessTokenAndAuthType?: (token: string, type?: EAuthType | null) => void;
}

export interface IApiProviderProps {
  children: React.ReactNode;
}

const apiContext = createContext<IApiContextState>({});

const WEBCORE_URL = process.env.REACT_APP_API_WEBCORE || "localhost:8000";
const GQL_CLIENT = process.env.REACT_APP_DEVELOP_WITH_MOCK === "true" ? mockGraphQLClient : null;

export const ApiProvider = ({ children }: IApiProviderProps) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [authType, setAuthType] = useState<EAuthType | undefined>();

  const setAccessTokenAndAuthType = useCallback((token: string, type?: EAuthType | null) => {
    setAccessToken(token);
    setAuthType(type ?? undefined);
  }, []);

  const webcoreAPI = useMemo(
    () => new GraphAPI(accessToken, GQL_CLIENT, WEBCORE_URL, authType),
    [accessToken, authType]
  );

  return (
    <apiContext.Provider value={{ setAccessTokenAndAuthType, accessToken }}>
      <ApolloProvider client={webcoreAPI.apolloClient}>{children}</ApolloProvider>
    </apiContext.Provider>
  );
};

export const useApiContext = () => {
  return React.useContext(apiContext);
};
