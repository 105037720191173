import { GetSmartAllocReportQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { formatYearMonthDate } from "utils/date";
import { SmartAllocReportType } from "../constants";
import { ChartData, SmartAllocReport, ChartDataObj } from "./SmartAllocDashboard.d";

const generateDefaultChartData = (months: string[], weeks: string[]): ChartDataObj => {
  return {
    [SmartAllocReportType.MONTHLY]: months.reduce((results, month) => {
      return {
        ...results,
        [month]: null,
      };
    }, {}),
    [SmartAllocReportType.WEEKLY]: weeks.reduce((results, week) => {
      return {
        ...results,
        [week]: null,
      };
    }, {}),
  };
};

const transfomChartData = (chartData: ChartDataObj): ChartData => {
  return {
    [SmartAllocReportType.MONTHLY]: Object.values(chartData[SmartAllocReportType.MONTHLY]),
    [SmartAllocReportType.WEEKLY]: Object.values(chartData[SmartAllocReportType.WEEKLY]),
  };
};

export const transform = (
  data: GetSmartAllocReportQuery["sf_pms_smartalloc_report"],
  months: string[],
  weeks: string[]
): SmartAllocReport | null => {
  const numberOfMonths = data.filter((item) => item.type === SmartAllocReportType.MONTHLY).length;
  if (!numberOfMonths) {
    return null;
  }

  let totalAverageOccupancy = 0;
  let totalFollowedRecommendationRatio = 0;
  let totalNinetyAdherenceSavingKwh = 0;
  let totalNinetyAdherenceSavingSgd = 0;
  let totalNinetyAdherenceSavingLocalCurrency = 0;
  let totalSavingsKwh = 0;
  let totalSavingsSgd = 0;
  let totalSavingsLocalCurrency = 0;

  const usingSmartAlloc = generateDefaultChartData(months, weeks);
  const notUsingSmartAlloc = generateDefaultChartData(months, weeks);
  const occupancy = generateDefaultChartData(months, weeks);
  const maximumCompressors = generateDefaultChartData(months, weeks);
  const compressorsInUse = generateDefaultChartData(months, weeks);

  data.forEach((item) => {
    if (item.type === SmartAllocReportType.MONTHLY) {
      totalAverageOccupancy += item.averageOccupancy ?? 0;
      totalFollowedRecommendationRatio += item.followedRecommendationRatio ?? 0;
      totalNinetyAdherenceSavingKwh += item.ninetyAdherenceSavingsKwh ?? 0;
      totalNinetyAdherenceSavingSgd += item.ninetyAdherenceSavingsSgd ?? 0;
      totalNinetyAdherenceSavingLocalCurrency += item.ninetyAdherenceSavingsLocalCurrency ?? 0;
      totalSavingsKwh += item.savingsKwh ?? 0;
      totalSavingsSgd += item.savingsSgd ?? 0;
      totalSavingsLocalCurrency += item.savingsLocalCurrency ?? 0;
      usingSmartAlloc[SmartAllocReportType.MONTHLY][formatYearMonthDate(item.date)!] = item.totalConsumptionKwh ?? 0;
      notUsingSmartAlloc[SmartAllocReportType.MONTHLY][formatYearMonthDate(item.date)!] =
        item.simulatedNoSmartallocConsumptionKwh ?? 0;
      occupancy[SmartAllocReportType.MONTHLY][formatYearMonthDate(item.date)!] = (item.averageOccupancy ?? 0) * 100;
      maximumCompressors[SmartAllocReportType.MONTHLY][formatYearMonthDate(item.date)!] =
        item.simulatedNoSmartallocDailyCompressors ?? 0;
      compressorsInUse[SmartAllocReportType.MONTHLY][formatYearMonthDate(item.date)!] =
        item.averageDailyRunningCompressors ?? 0;
    } else if (item.type === SmartAllocReportType.WEEKLY) {
      usingSmartAlloc[SmartAllocReportType.WEEKLY][formatYearMonthDate(item.date)!] = item.totalConsumptionKwh ?? 0;
      notUsingSmartAlloc[SmartAllocReportType.WEEKLY][formatYearMonthDate(item.date)!] =
        item.simulatedNoSmartallocConsumptionKwh ?? 0;
      occupancy[SmartAllocReportType.WEEKLY][formatYearMonthDate(item.date)!] = (item.averageOccupancy ?? 0) * 100;
      maximumCompressors[SmartAllocReportType.WEEKLY][formatYearMonthDate(item.date)!] =
        item.simulatedNoSmartallocDailyCompressors ?? 0;
      compressorsInUse[SmartAllocReportType.WEEKLY][formatYearMonthDate(item.date)!] =
        item.averageDailyRunningCompressors ?? 0;
    }
  });

  return {
    averageOccupancy: (totalAverageOccupancy / numberOfMonths) * 100,
    followedRecommendationRatio: (totalFollowedRecommendationRatio / numberOfMonths) * 100,
    ninetyAdherenceSavingKwh: totalNinetyAdherenceSavingKwh,
    ninetyAdherenceSavingSgd: totalNinetyAdherenceSavingSgd,
    ninetyAdherenceSavingLocalCurrency: totalNinetyAdherenceSavingLocalCurrency,
    savingsKwh: totalSavingsKwh,
    savingsSgd: totalSavingsSgd,
    savingsLocalCurrency: totalSavingsLocalCurrency,
    usingSmartAlloc: transfomChartData(usingSmartAlloc),
    notUsingSmartAlloc: transfomChartData(notUsingSmartAlloc),
    occupancy: transfomChartData(occupancy),
    maximumCompressors: transfomChartData(maximumCompressors),
    compressorsInUse: transfomChartData(compressorsInUse),
  };
};

export const getChartData = (chartData?: ChartData, isMonthly: boolean = true) => {
  return chartData?.[isMonthly ? SmartAllocReportType.MONTHLY : SmartAllocReportType.WEEKLY] || [];
};
