import {
  GatewayHardwareType,
  GatewayHealthError,
  GatewayHealthStatus,
  Position,
  Sensorflow_Gateway_Health_Data,
} from "pacts/app-webcore/hasura-webcore.graphql";

export type Gateway = {
  error?: GatewayHealthError;
  gatewayId: string;
  gatewayMac: number;
  gatewayName: string;
  position: Position;
  healthStatus: GatewayHealthStatus;
  kittingStatus: GatewayHealthStatus;
  locationId: string;
  wifiMacAddress?: string;
  ethernetMacAddress?: string;
  gatewayHealthData: Pick<Sensorflow_Gateway_Health_Data, "value" | "time" | "measurementType">[];
  wlanLinkGatewayHealthData: Pick<Sensorflow_Gateway_Health_Data, "value" | "time" | "measurementType">[];
  wlan1LinkGatewayHealthData: Pick<Sensorflow_Gateway_Health_Data, "value" | "time" | "measurementType">[];
  networkConnectionStatus?: string;
  hardwareType?: GatewayHardwareType;
};

export const GATEWAY_STATUS_UI_CONFIG = {
  [GatewayHealthStatus.Healthy]: ["Healthy", "success", "1"],
  [GatewayHealthStatus.Error]: ["Error", "error", "2"],
  [GatewayHealthStatus.NotBooted]: ["Not Booted", "default", "3"],
  [GatewayHealthStatus.PreparingImage]: ["Preparing Image", "warning", "4"],
  [GatewayHealthStatus.Verifying]: ["Verifying", "warning", "5"],
  [GatewayHealthStatus.LoraError]: ["Lora Error", "error", "6"],
  [GatewayHealthStatus.ErrorProvisioningImage]: ["Error Provisioning Image", "error", "7"],
  [GatewayHealthStatus.Online]: ["Online", "success", "8"],
  [GatewayHealthStatus.Offline]: ["Offline", "error", "9"],
};

export const GATEWAY_ERROR_DISPLAY = {
  [GatewayHealthStatus.LoraError]: "Error initialising LORA",
  [GatewayHealthStatus.Error]: "Unknown error",
};
