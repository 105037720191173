/* eslint-disable import/no-cycle */
import React, { useContext, useState } from "react";
import useBreakpoint from "hooks/use-breakpoint";
import { Button, Col, message, Modal, Row } from "antd";
import classNames from "classnames";
import { Slot } from "pages/Key/KeyDetail/KeyDetail.d";
import {
  useSingleMapNodeMutation,
  Maybe,
  useChangeBatteryMutation as useChangeBattery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import "./ContractorNodeListTable.scss";
import NodeMappingComponent from "components/NodeMapping/NodeMapping";
import { assign, get, pick } from "lodash";
import NodeTypeDisplay from "utils/nodeTypeDisplay";
import { getExternalErrorComponent } from "components/NodeMapping";
import ChangeBatteryModal from "pages/NodeManagement/ChangeBatteryModal/ChangeBatteryModal";
import { ApolloError } from "apollo-client";
import errorHandler from "errorHandler";
import { FormData } from "pages/NodeManagement/CreateNodeLifeCycleEvent";
import CopyButton from "components/CopyButton";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { NodeHealth } from "./ContractorNodeList.d";
import UnmapedNodeComponent from "./UnmapedNode/UnmappedNode";

export const displayNodeMacId = (nodeMacId?: Maybe<string>) => {
  if (!nodeMacId) return null;

  const subNodeMacIds: string[] = [];
  for (let i = 0; i < nodeMacId.length; i += 4) {
    const subNodeMacId = nodeMacId.substring(i, i + 4);
    subNodeMacIds.push(subNodeMacId);
  }
  const boldStr: string = subNodeMacIds.pop() ?? "";

  let normalStr: string = "";
  subNodeMacIds.forEach((str) => {
    normalStr += `${str}-`;
  });
  return (
    <>
      {normalStr}
      <b className="fs-l">{boldStr}</b>
      <CopyButton input={nodeMacId} />
    </>
  );
};

type ContractorNodeComponentProps = {
  roomId: string;
  roomName: string;
  keyName: string;
  node: Slot & Record<string, any>;
  nodeHealth?: NodeHealth;
  reloadNodes: () => void;
};

const HeaderContainer: React.FC<Slot> = (slot?): React.ReactElement => (
  <>
    <p className="slot-name">{slot?.slotName}</p>
    <p className="node-type">{get(NodeTypeDisplay[slot?.nodeSubType!], "code")}</p>
  </>
);

const ContractorNodeComponent = (props: ContractorNodeComponentProps) => {
  const [isNodeMappingModalOpen, setNodeMappingModalOpen] = useState(false);
  const [isUnmappedNodeModalOpen, setUnmappedNodeModalOpen] = useState(false);
  const [nodeMacInput, setNodeMacInput] = useState<string | undefined>();
  const { roomId, roomName, keyName, node, nodeHealth, reloadNodes } = props;
  const [slotMapping, setSlotMapping] = useState<Slot>(node);
  const [mapNodeError, setMapNodeError] = useState<ApolloError | undefined>();
  const [openChangeBatteryModal, setOpenChangeBatteryModal] = useState(false);
  const [changeBatteryValue, setChangeBatteryValue] = useState<FormData>({});
  const { currentKeyEntryOfInstaller } = useContext(InstallationModeContext);
  const screen = useBreakpoint();

  const nodeMacId = nodeHealth?.nodeMacId;

  const closeModalMapNode = () => {
    setNodeMappingModalOpen(false);
    setMapNodeError(undefined);
    setNodeMacInput(undefined);
  };

  const closeModalUnmapNode = () => {
    setUnmappedNodeModalOpen(false);
    setMapNodeError(undefined);
    setNodeMacInput(undefined);
  };

  const mappingRequest = {
    variables: {
      roomId,
      slotName: node.slotName,
      nodeType: node.nodeType,
      nodeMacInput: node.nodeTypeCodeIdentifier.slice(-2) + get(node, "currentMappedNode.mappedNode.nodeMacId"),
      nodeSubType: node.nodeSubType,
    },
  };

  const displaySlotName = () => {
    const nodeType = node.nodeType[0].toLocaleUpperCase() + node.nodeType.slice(1);
    return `${node.slotName} (${nodeType})`;
  };

  const displayNodeStatus = () => {
    if (!nodeMacId) {
      return null;
    }
    let nodeStatus = nodeHealth?.nodeStatus;
    if (currentKeyEntryOfInstaller) {
      nodeStatus = nodeHealth?.nodeStatusInInstallationMode ?? "";
    }

    return (
      <div
        className={classNames(
          screen.desktopOny ? "node-status-desktop" : "node-status-mobile",
          "node-status",
          nodeStatus
        )}
      >
        <span>{nodeStatus}</span>
      </div>
    );
  };

  const [changeBattery] = useChangeBattery({
    onError: errorHandler.handleError,
    onCompleted: (data: any) => {
      message.success(`Record life cycle event battery change successfully for node ${data.changeBattery?.nodeMacId}`);
      setOpenChangeBatteryModal(false);
    },
  });

  const onOpenChangeBatteryModal = async (value: any) => {
    setOpenChangeBatteryModal(true);
    setChangeBatteryValue({ nodeMacId: value });
  };

  const onChangeBattery = async (data: FormData) => {
    if (data.nodeMacId) {
      await changeBattery({
        variables: {
          nodeMacId: data.nodeMacId,
          comment: data.comment,
        },
      });
    }
  };

  const [mapNodeMutation, { loading: isMapping }] = useSingleMapNodeMutation({
    onCompleted: () => {
      setSlotMapping(assign(slotMapping, { currentMappedNode: { mappedNode: { nodeMacId: nodeMacInput } } }));
      setNodeMappingModalOpen(false);
      reloadNodes();
    },
    onError: setMapNodeError,
  });

  const nodeMapSubmit = (input: string) => {
    mappingRequest.variables.nodeMacInput = input;
    mapNodeMutation(mappingRequest);
    setNodeMacInput(input.substring(2));
  };

  const onUnmapSuccessfully = () => {
    closeModalUnmapNode();
    reloadNodes();
  };

  const mapSlot = (slot: Slot) => {
    setSlotMapping(slot);
    setNodeMappingModalOpen(true);
  };

  const renderActionButton = (slot: Slot): React.ReactElement => {
    if (nodeMacId) {
      return (
        <Button type="primary" className="unmap" onClick={() => setUnmappedNodeModalOpen(true)}>
          Unmap
        </Button>
      );
    }
    return (
      <Button type="primary" className="map" onClick={() => mapSlot(slot)}>
        Map
      </Button>
    );
  };

  const nodeMappingProps = {
    ...pick(slotMapping, ["nodeTypeCodeIdentifier", "nodeType", "slotName"]),
    roomId,
    nodeMapSubmit,
    isMapping,
    customHeader: () => {
      return <HeaderContainer {...slotMapping} />;
    },
    externalError: getExternalErrorComponent(mapNodeError, nodeMacInput),
  };

  const nodeUnmappingProps = {
    ...pick(slotMapping, ["nodeTypeCodeIdentifier", "nodeType", "slotName"]),
    roomId,
    roomName,
    keyName,
    currentNodeMacId: nodeMacId,
    onUnmapSuccessfully,
    externalError: getExternalErrorComponent(mapNodeError, nodeMacInput),
  };

  return (
    <>
      {screen.desktopOny ? (
        <Row className="my-m">
          <Col className="text-center" span={6}>
            {displaySlotName()}
          </Col>
          <Col className="text-center" span={6}>
            {displayNodeMacId(nodeMacId)}
          </Col>
          <Col className="text-center" span={6}>
            {displayNodeStatus()}
          </Col>
          <Col className="px-auto">
            {renderActionButton(node)}
            {node?.currentMappedNode?.mappedNode?.nodeMacId && (
              <Button
                className="mx-s"
                onClick={async () => {
                  await onOpenChangeBatteryModal(nodeMacId);
                }}
              >
                Change battery
              </Button>
            )}
          </Col>
        </Row>
      ) : (
        <Row className="node-item">
          <Col span={14}>
            <Row className="">
              <Col flex="auto">
                <span className="title">SLOT NAME</span>
                <p>{displaySlotName()}</p>
              </Col>
            </Row>
            {nodeMacId && (
              <Row>
                <span className="title">NODE ID</span>
                {displayNodeStatus()}
                <p>{displayNodeMacId(nodeMacId)}</p>
              </Row>
            )}
          </Col>
          <Col span={10}>
            <Row className="action-button">{renderActionButton(node)}</Row>
            <Row className="action-button">
              {nodeMacId && (
                <Button
                  className="my-xs"
                  onClick={async () => {
                    await onOpenChangeBatteryModal(nodeMacId);
                  }}
                >
                  Change battery
                </Button>
              )}
            </Row>
          </Col>
        </Row>
      )}
      <Modal
        className="map-node-modal"
        title="Map Node"
        footer={null}
        visible={isNodeMappingModalOpen}
        onCancel={closeModalMapNode}
        destroyOnClose
      >
        <NodeMappingComponent {...nodeMappingProps} />
      </Modal>
      <Modal
        className="unmap-node-modal"
        title="Unmap Node"
        footer={null}
        visible={isUnmappedNodeModalOpen}
        onCancel={closeModalUnmapNode}
        destroyOnClose
      >
        <UnmapedNodeComponent {...nodeUnmappingProps} />
      </Modal>
      {openChangeBatteryModal && (
        <ChangeBatteryModal
          formData={changeBatteryValue}
          closeModal={() => setOpenChangeBatteryModal(false)}
          handleOnSubmit={onChangeBattery}
        />
      )}
    </>
  );
};
export default ContractorNodeComponent;
