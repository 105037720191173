import React, { useState } from "react";

import { GatewayHealthStatus } from "pacts/app-webcore/hasura-webcore.graphql";
import classNames from "classnames";
import moment from "moment";
import { Button } from "antd";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import useRoleAndPermission from "hooks/use-role-and-permission";
import GatewayAction from "../GatewayAction/GatewayAction";
import "./GatewayMobileItem.scss";
import { getConnectionStatusDisplayText } from "../GatewayList/GatewayList.util";
import { Gateway, GATEWAY_ERROR_DISPLAY, GATEWAY_STATUS_UI_CONFIG } from "../GatewayList/GatewayList.d";

interface GatewayMobileItemProps {
  gateway: Gateway;
  showDeleteModal?: (gatewayId: string, gatewayName: string, healthStatus: GatewayHealthStatus) => void;
  showRenameModal?: (gatewayId: string, gatewayName: string) => void;
  reloadGateways?: () => void;
}

const GatewayMobileItem = ({ gateway, showDeleteModal, showRenameModal, reloadGateways }: GatewayMobileItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const roleAndPermission = useRoleAndPermission();

  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        <div className="w-10">
          <Button
            type="text"
            icon={expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            onClick={() => setExpanded((currentExpanded) => !currentExpanded)}
          />
        </div>
        <div className="w-30">
          <div className="gateway-name-container">
            <p className="gateway-name">{gateway.gatewayName}</p>
          </div>
          {(gateway.healthStatus === GatewayHealthStatus.Error ||
            gateway.healthStatus === GatewayHealthStatus.LoraError) && (
            <span className="gateway-error">{GATEWAY_ERROR_DISPLAY[gateway.healthStatus]}</span>
          )}
        </div>
        <div className="w-35 lh-l" style={{ display: "flex", alignItems: "center" }}>
          <div
            className={classNames(
              "gateway-status-indicator",
              `bg-${GATEWAY_STATUS_UI_CONFIG[gateway.healthStatus][1]}`
            )}
          />
          {roleAndPermission.isInstaller() && gateway.gatewayHealthData.length > 0 ? (
            <div>{moment(gateway.gatewayHealthData[0].time).fromNow()}</div>
          ) : (
            "-"
          )}
        </div>
        <div className="w-10 lh-l">
          {roleAndPermission.isInstaller() && gateway.gatewayHealthData.length > 0
            ? getConnectionStatusDisplayText(gateway)
            : "-"}
        </div>
        <div className="w-15 more-button-container">
          <GatewayAction
            gateway={gateway}
            showRenameModal={showRenameModal}
            showDeleteModal={showDeleteModal}
            reloadGateways={reloadGateways}
          />
        </div>
      </div>
      {expanded && (
        <div className="d-flex flex-row ml-m">
          <div className="mt-m w-50">
            <span className="d-block fs-sm font-weight-bold">Wifi Mac Address</span>
            <span className="d-block fs-sm">{gateway.wifiMacAddress || "-"}</span>
          </div>
          <div className="mt-m w-50">
            <span className="d-block fs-sm font-weight-bold">Eth Mac Address</span>
            <span className="d-block fs-sm">{gateway.ethernetMacAddress || "-"}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default GatewayMobileItem;
