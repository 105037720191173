/* eslint-disable @typescript-eslint/dot-notation */
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Form, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect, useState } from "react";
import CategoryFilter from "./CategoryFilter";
import { FilterV2Props } from "./FilterV2.d";
import GroupFilter from "./GroupFilter";

const FilterV2: React.FC<FilterV2Props> = (props) => {
  const { currentFilter, onFilterChange, locationId } = props;
  const [form] = Form.useForm();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      categoryName: currentFilter.categoryName ?? [],
      groupIds: currentFilter.groupIds ?? [],
    });
  }, [form, currentFilter]);

  const clearFilter = useCallback(() => {
    form.resetFields();
    setOpen(false);
    onFilterChange?.({
      categoryName: undefined,
      groupIds: undefined,
    });
  }, [form, onFilterChange]);

  const cancel = useCallback(() => {
    setOpen(false);
  }, []);

  const apply = useCallback(() => {
    const categoryNames = form.getFieldValue("categoryName");
    const groupIds = form.getFieldValue("groupIds");
    onFilterChange?.({
      categoryName: !isEmpty(categoryNames) ? form.getFieldValue("categoryName") : undefined,
      groupIds: !isEmpty(groupIds) ? form.getFieldValue("groupIds") : undefined,
    });
    setOpen(false);
  }, [onFilterChange, form]);

  const dropdownRender = useCallback(() => {
    return (
      <Card className="w-100">
        <Form form={form} layout="vertical" onFinish={apply}>
          <Form.Item label={<h3 className="font-weight-bold">Category</h3>} name="categoryName">
            <CategoryFilter locationId={locationId || ""} />
          </Form.Item>
          <Form.Item label={<h3 className="font-weight-bold">Group</h3>} name="groupIds">
            <GroupFilter locationId={locationId || ""} />
          </Form.Item>
          <Row justify="space-between" className="pt-s pb-xs">
            <Col>
              <Button type="text" onClick={clearFilter}>
                <CloseOutlined />
                Clear filter
              </Button>
            </Col>
            <Col>
              <Button className="mr-s" onClick={cancel}>
                Cancel
              </Button>
              <Button type="primary" onClick={() => form.submit()}>
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }, [form, apply, cancel, clearFilter, locationId]);

  return (
    <>
      <Form form={form}>
        <Dropdown
          overlayStyle={{ width: "400px" }}
          dropdownRender={dropdownRender}
          placement="bottomRight"
          trigger={["click"]}
          open={open}
        >
          <Button className="ml-s" onClick={() => setOpen(true)}>
            <FilterOutlined />
          </Button>
        </Dropdown>
      </Form>
    </>
  );
};

export default FilterV2;
