import { Doughnut as DoughnutChartjs, ChartComponentProps } from "react-chartjs-2";
import React from "react";

const Doughnut = ({ data, options }: Partial<ChartComponentProps>) => {
  return (
    <DoughnutChartjs
      width={50}
      height={50}
      data={data}
      options={{
        cutoutPercentage: 50,
        legend: {
          display: false,
        },
        ...options,
      }}
    />
  );
};

export default Doughnut;
