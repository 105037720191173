import { Form, Select } from "antd";
import React, { useState } from "react";
import { FormInstance } from "antd/lib/form";
import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { nodeSubTypes, nodeTypes } from "./key.helper";

const NodeTypeFormItem = (props: { form: FormInstance; hasAircon: boolean }) => {
  const { form, hasAircon } = props;
  const [selectedNodeType, setSelectedNodeType] = useState();

  const getNodeSubTypes = () => {
    if (!selectedNodeType) return [];
    return nodeSubTypes.filter((n) => n.nodeType === selectedNodeType);
  };

  const onNodeTypeChanged = (val: any) => {
    setSelectedNodeType(val);
    form.setFieldsValue({
      nodeSubType: null,
    });
  };

  const filteredNodeTypes = nodeTypes.filter((n) => (hasAircon ? n.key !== NodeType.Aircon.toString() : n));

  return (
    <>
      <Form.Item name={["nodeType"]} label="Node Type" rules={[{ required: true }]}>
        <Select className="w-100" data-testid="node-type-testId" onSelect={onNodeTypeChanged}>
          {filteredNodeTypes.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={["nodeSubType"]} label="Node Sub Type" rules={[{ required: true }]}>
        <Select className="w-100" data-testid="node-sub-type-testId" disabled={!selectedNodeType}>
          {getNodeSubTypes().map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default NodeTypeFormItem;
