import classNames from "classnames";
import React from "react";
import "./ChartLegend.scss";

type Props = {
  datasets: {
    backgroundColor: string;
    label: string;
  }[];
  variant?: "horizontal" | "vertical";
};

const ChartLegend = (props: Props) => {
  const { datasets, variant = "horizontal" } = props;
  return (
    <div
      className={classNames("chart-legend d-flex flex-wrap", {
        "flex-row align-items-center": variant === "horizontal",
        "flex-column": variant === "vertical",
      })}
    >
      {!!datasets?.length &&
        datasets.map((dataset, index) => {
          return (
            <div
              className={classNames(
                "chart-legend__item fs-sm d-flex flex-row align-items-center",
                index < datasets.length - 1 && "mr-s"
              )}
              key={index}
            >
              <div
                className="chart-legend__dot d-inline-block mr-xs"
                style={{ backgroundColor: dataset.backgroundColor }}
              />
              {dataset.label}
            </div>
          );
        })}
    </div>
  );
};

export default ChartLegend;
