import React, { useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { KeyEntryStatus } from "types/InstallationMode.d";
import { CommentModalProps } from "./CommentModal.d";

const CommentModal: React.FC<CommentModalProps> = ({
  title,
  keyName,
  onConfirm,
  setOpenCommentModal,
  openCommentModal,
  isForExitEntry,
  customDescription,
  isMeterPosition,
  isLoading,
}) => {
  const [comment, setComment] = useState<string>();

  useEffect(() => {
    if (!openCommentModal) setComment("");
  }, [openCommentModal]);

  return (
    <Modal
      title={title || "Exit installation"}
      centered
      width={400}
      okText="Confirm"
      onCancel={() => setOpenCommentModal(false)}
      onOk={() => onConfirm(KeyEntryStatus.VISITED_REQUIRED, comment, isMeterPosition)}
      okButtonProps={{ disabled: !comment?.trim().length || isLoading }}
      open={openCommentModal}
      zIndex={1001}
      destroyOnClose
    >
      {!customDescription && isForExitEntry ? (
        <p className="mb-xs">
          Key <b>{keyName} has not completed yet.</b>
        </p>
      ) : null}

      {customDescription && <p className="mb-xs">{customDescription}</p>}

      <TextArea
        id="key-name-input"
        data-testid="key-name-input-testId"
        placeholder="Comments"
        onChange={(e) => setComment(e.target.value)}
      />
      {isForExitEntry && (
        <div className="w-100 d-flex">
          <Checkbox className="ant-checkbox-rtl mt-s ml-auto" disabled checked>
            Revisit required
          </Checkbox>
        </div>
      )}
    </Modal>
  );
};

export default CommentModal;
