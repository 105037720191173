import React, { useCallback, useState } from "react";
import { Checkbox, Col, Input, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CheckSelectProps } from "./CheckSelect.d";
import "./CheckSelect.scss";

const CheckSelect: React.FC<CheckSelectProps> = ({
  options,
  value,
  placeholder,
  onChange,
  placeholderSearch,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");

  const searchDropdown = useCallback(
    (menu: any) => {
      return (
        <Row gutter={[8, 8]}>
          <Col span={24} className="py-xs px-s">
            <Input
              placeholder={placeholderSearch}
              onChange={(e) => setSearchItem(e.target.value)}
              prefix={<SearchOutlined />}
              disabled={disabled}
            />
          </Col>
          <Col span={24}>{menu}</Col>
        </Row>
      );
    },
    [placeholderSearch, setSearchItem, disabled]
  );

  return (
    <Select
      mode="multiple"
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      placeholder={placeholder}
      onChange={onChange}
      allowClear
      filterOption
      optionFilterProp="children"
      className="check-select"
      value={value}
      dropdownRender={searchDropdown}
      disabled={disabled}
    >
      {options
        .filter((option) => {
          if (!searchItem) return true;
          return `${option.label}`.includes(searchItem);
        })
        .map((option) => (
          <Select.Option key={option.value} value={option.value}>
            <Checkbox checked={value?.includes(option.value)}>{option.label}</Checkbox>
          </Select.Option>
        ))}
    </Select>
  );
};

export default CheckSelect;
