import React, { useState } from "react";

import { Button, Form, InputNumber, Modal, Radio, RadioChangeEvent, Select, message } from "antd";
import ModalWrapper from "components/Modal/Wrapper";
import errorHandler from "errorHandler";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import _ from "lodash";
import moment from "moment";
import {
  GatewayHardwareType,
  LocationKeysAndRoomsQuery,
  useGetOnePositionConfigQuery,
  useGetSmartRemValidationHistoryQuery,
  useGetTicketClassesForDropdownQuery,
  useLocationKeysAndRoomsQuery,
  useLocationSettingsQuery,
  useUpsertActivePropertyAlertsMutation,
  useUpsertLocationMetadataMutation,
  useUpsertSmartRemValidationHistoryMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import getGatewayHardwareTypeDisplayText from "pages/Gateway/helpers";
import useResetConfigDefault from "pages/Key/hooks/use-reset-config-default";
import { ActingMode, OperationalMode, PositionConfigurationRecordType } from "pages/Key/types";
import { usePrevious } from "react-use";
import { formatDateTime24h, formatUTCTimestamp } from "utils/date";
import { AvailableOperationalModes, LocationSubscription } from "utils/locationMetadata";
import { ConfirmBeaconSlotManagementModal } from "../ConfirmBeaconSlotManagementModal";
import { ConfirmChangeHardwareModal } from "../ConfirmChangeHardwareModal";
import { ConfirmEnableNoKeyCardStateModal } from "../ConfirmEnableNoKeyCardStateModal";
import OverrideSmartREMValidationConfirmModal from "../ConfirmOverrideSmartREMConfigModal/ConfirmOverrideSmartREMConfigModal";
import ConfirmPromiscuousModeModal from "../ConfirmPromiscuousModeModal/ConfirmPromiscuousModeModal";
import { LocationSettingsModalProps } from "./LocationSettingsModal.d";
import { transformActivePropertyAlertInput } from "./LocationSettingsModal.helper";

enum ESmartREMValidationAutomationMode {
  SMART_SAVE = "SMARTSAVE",
  SUPER_SAVE = "SUPERSAVE",
}
const LocationSettingsModal = ({ locationId, closeModal }: LocationSettingsModalProps) => {
  const resetTitle = "Rooms will reset to default configurations";
  const resetContent =
    "After disabling SmartREM Validation, rooms will go back to their default configurations. Confirm?";

  const [confirmModalRef, openConfirmModalRef] = useModalWrapperTrigger();
  const [confirmOverrideSmartREM, openConfirmOverrideSmartREM] = useModalWrapperTrigger();

  const [currentActivePropertyAlerts, setCurrentActivePropertyAlerts] = useState<number[]>([]);
  const [gatewayHardwareType, setGatewayHardwareType] = useState<GatewayHardwareType>();
  const prevGatewayHardwareType = usePrevious(gatewayHardwareType);

  const [confirmPromiscuousModeModalRef, openConfirmPromiscuousModeModalRef] = useModalWrapperTrigger();
  const [confirmEnableNoKeyCardStateModalRef, openConfirmEnableNoKeyCardStateModalRef] = useModalWrapperTrigger();
  const [confirmBeaconSlotManagementModalRef, openBeaconSlotManagementModalRef] = useModalWrapperTrigger();
  const [promiscuousMode, setPromiscuousMode] = useState<boolean>(false);
  const [hasKeyCard, setHasKeyCard] = useState<boolean>(false);
  const [enableNoKeyCardState, setEnableNoKeyCardState] = useState<boolean>(false);
  const [beaconSlotManagementEnabled, setBeaconSlotManagementEnabled] = useState<boolean>(false);
  const prevPromiscuousMode = usePrevious(promiscuousMode);
  const prevEnableNoKeyCardState = usePrevious(enableNoKeyCardState);
  const prevBeaconSlotManagementEnabled = usePrevious(beaconSlotManagementEnabled);
  const [smartRemValidationEnabled, setSmartRemValidationEnabled] = useState<boolean>(false);
  const [resetDone, setResetDone] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [previousSmartREMToggle, setPreviousSmartREMToggle] = useState<boolean>(false);
  const [roomsData, setRoomsData] = useState<LocationKeysAndRoomsQuery>();

  const [form] = Form.useForm();

  const smartRemValidationMode = Form.useWatch<ESmartREMValidationAutomationMode>("smartRemValidationMode", form);

  const [upsertActivePropertyAlertsMutation] = useUpsertActivePropertyAlertsMutation({
    onCompleted: () => {
      message.success(`Save location settings successfully`);
      closeModal();
    },
    onError: errorHandler.handleError,
  });

  const [upsertLocationMetadataMutation, { loading: upsertLoading }] = useUpsertLocationMetadataMutation({
    onCompleted: () => {
      const activePropertyAlerts = form.getFieldValue("activePropertyAlerts");
      upsertActivePropertyAlertsMutation({
        variables: {
          objects: transformActivePropertyAlertInput(locationId, activePropertyAlerts, currentActivePropertyAlerts),
        },
      });
    },
    onError: errorHandler.handleError,
  });

  const [upsertLocationMetadataSmartREMValidation] = useUpsertLocationMetadataMutation({});

  const [upsertSmartRemValidationHistory] = useUpsertSmartRemValidationHistoryMutation();

  const { refetch: getPositionsForLocation } = useLocationKeysAndRoomsQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setRoomsData(data);
    },
  });

  const { refetch: getPositionsOfLocation } = useGetOnePositionConfigQuery({
    variables: {
      where: {
        recordType: { _eq: PositionConfigurationRecordType.CURRENT },
        actingMode: { _eq: ActingMode.TwoPFC },
        operationalMode: { _eq: OperationalMode.Heating },
        position: {
          locationId: {
            _eq: locationId,
          },
        },
      },
    },
  });

  const { loading } = useLocationSettingsQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      const activePropertyAlerts = data.locationOne?.activePropertyAlerts
        .filter((el) => el.active)
        .map((el) => el.ticketClassId);
      form.setFieldsValue({
        subscription: data.locationMetadata?.subscription || LocationSubscription.SMART_REM,
        showEnergyConsumption: !!data.locationMetadata?.showEnergyConsumption,
        showHeatingMode: !!data.locationMetadata?.showHeatingMode,
        activePropertyAlerts: data.locationOne?.activePropertyAlerts
          .filter((el) => el.active)
          .map((el) => el.ticketClassId),
        activeAutosetCount: data.locationMetadata?.activeAutosetCount || 0,
        allowDisableAutomationMode: data.locationMetadata?.allowDisableAutomationMode ?? false,
        smartRemValidation: !!data.locationMetadata?.smartRemValidation,
        availableOperationalModes: data.locationMetadata?.availableOperationalModes,
      });
      setGatewayHardwareType(data?.locationMetadata?.gatewayHardwareType as GatewayHardwareType);
      setCurrentActivePropertyAlerts(activePropertyAlerts ?? []);
      setPromiscuousMode(!!data?.locationMetadata?.gatewayPromiscuousModeEnabled);
      setHasKeyCard(data.locationMetadata?.hasKeyCard ?? false);
      setEnableNoKeyCardState(data.locationMetadata?.enableNoKeyCardState ?? false);
      setBeaconSlotManagementEnabled(data.locationMetadata?.beaconSlotManagement ?? false);
      setPreviousSmartREMToggle(data.locationMetadata?.smartRemValidation ?? false);
      setSmartRemValidationEnabled(data.locationMetadata?.smartRemValidation ?? false);
    },
    onError: errorHandler.handleError,
  });

  const { data: ticketClasses, refetch: refetchTicketClasses } = useGetTicketClassesForDropdownQuery({
    variables: {
      ticketClass: "%%",
    },
    onError: errorHandler.handleError,
  });

  const { data: lastSmartRemPeriod, loading: validationHistoryLoading } = useGetSmartRemValidationHistoryQuery({
    variables: {
      locationId,
    },
  });

  const { resetConfigToDefault } = useResetConfigDefault(
    async () => {
      const { smartRemValidation } = form.getFieldsValue();
      const now = formatUTCTimestamp(moment());

      // once confirmed, we must make sure to save disabling SmartREM validation
      // to prevent reverting config if ever the modal was closed
      await upsertLocationMetadataSmartREMValidation({
        variables: {
          input: {
            locationId,
            smartRemValidation,
          },
        },
      });

      const smartRemPeriod = lastSmartRemPeriod?.getSmartRemValidationHistory[0];
      if (!smartRemPeriod) return false;
      await upsertSmartRemValidationHistory({
        variables: {
          object: {
            id: smartRemPeriod.id,
            locationId,
            validationStartDate: smartRemPeriod.validationStartDate,
            validationEndDate: now,
          },
        },
      });

      setResetDone(true);
      setResetLoading(false);
    },
    resetTitle,
    resetContent,
    () => {
      setResetDone(false);
      setResetLoading(false);
    }
  );

  const trackSmartRemPeriod = async (isSmartRemEnabled: boolean, automationMode: string, setPoint: number) => {
    const now = formatUTCTimestamp(moment());
    if (isSmartRemEnabled) {
      // check if there is an existing record with no end date
      // and end that
      const smartRemPeriod = lastSmartRemPeriod?.getSmartRemValidationHistory?.[0];

      if (smartRemPeriod && !smartRemPeriod.validationEndDate) {
        await upsertSmartRemValidationHistory({
          variables: {
            object: {
              id: smartRemPeriod.id,
              locationId,
              validationStartDate: smartRemPeriod.validationStartDate,
              validationEndDate: now,
            },
          },
        });
      }
      await upsertSmartRemValidationHistory({
        variables: {
          object: {
            locationId,
            validationStartDate: now,
            automationMode,
            smartsaveSetpoint: automationMode === ESmartREMValidationAutomationMode.SMART_SAVE ? setPoint : null,
          },
        },
      });
    } else if (lastSmartRemPeriod) {
      const smartRemPeriod = lastSmartRemPeriod.getSmartRemValidationHistory[0];
      if (!smartRemPeriod) return false;
      await upsertSmartRemValidationHistory({
        variables: {
          object: {
            id: smartRemPeriod.id,
            locationId,
            validationStartDate: smartRemPeriod.validationStartDate,
            validationEndDate: now,
          },
        },
      });
    } else {
      return false;
    }
    return true;
  };

  const updateLocationMetadata = async () => {
    const {
      showEnergyConsumption,
      showHeatingMode,
      subscription,
      activeAutosetCount,
      allowDisableAutomationMode,
      smartRemValidation,
      availableOperationalModes,
      smartRemValidationSetpoint,
      smartRemValidationMode: innerSmartRemValidationMode,
    } = form.getFieldsValue();

    await upsertLocationMetadataMutation({
      variables: {
        input: {
          locationId,
          showEnergyConsumption,
          showHeatingMode,
          subscription,
          activeAutosetCount,
          allowDisableAutomationMode,
          availableOperationalModes,
          ...((await trackSmartRemPeriod(smartRemValidation, innerSmartRemValidationMode, smartRemValidationSetpoint))
            ? { smartRemValidation }
            : {}),
          hasKeyCard,
          enableNoKeyCardState,
          beaconSlotManagement: beaconSlotManagementEnabled,
        },
      },
    });
  };

  const handleSubmit = async () => {
    const {
      showHeatingMode,
      smartRemValidation,
      availableOperationalModes,
      smartRemValidationSetpoint,
      smartRemValidationMode: innerSmartRemValidationMode,
    } = form.getFieldsValue();

    if (!showHeatingMode) {
      const data = await getPositionsOfLocation();
      if (data?.data?.positionConfigurations?.length > 0) {
        message.error("Couldn't switch off heating for this location. Because some rooms are still in heating mode");
        return;
      }
    }
    if (availableOperationalModes !== AvailableOperationalModes.Both) {
      let operationalMode = OperationalMode.Cooling;
      if (availableOperationalModes === AvailableOperationalModes.Cooling) operationalMode = OperationalMode.Heating;
      const data = await getPositionsOfLocation({
        where: {
          recordType: { _eq: PositionConfigurationRecordType.CURRENT },
          actingMode: { _eq: ActingMode.TwoPFC },
          operationalMode: { _eq: operationalMode },
          position: {
            locationId: {
              _eq: locationId,
            },
          },
        },
      });
      if (data?.data?.positionConfigurations?.length > 0) {
        message.error(
          `Couldn't lock operational mode for this location. Because some rooms are still in ${operationalMode} mode`
        );
        return;
      }
    }

    // if validation is disabled and reset to default was cancelled, don't disable validation
    if (smartRemValidationEnabled !== previousSmartREMToggle && !smartRemValidationEnabled && !resetDone) {
      setSmartRemValidationEnabled(true);
      form.setFieldValue("smartRemValidation", true);
    }

    // verify if anything has changed
    let smartREMConfigChanged = false;
    const latestSmartRemPeriod = lastSmartRemPeriod?.getSmartRemValidationHistory?.[0];
    if (smartRemValidation && latestSmartRemPeriod && !latestSmartRemPeriod.validationEndDate) {
      smartREMConfigChanged =
        smartREMConfigChanged ||
        latestSmartRemPeriod.automationMode !== innerSmartRemValidationMode ||
        latestSmartRemPeriod.smartsaveSetpoint !== smartRemValidationSetpoint;
    }

    if (smartREMConfigChanged) openConfirmOverrideSmartREM();

    if (!smartREMConfigChanged) await updateLocationMetadata();
  };

  const handleSmartREMValidationOnChange = () => {
    const { smartRemValidation } = form.getFieldsValue();
    setSmartRemValidationEnabled(smartRemValidation);
    getPositionsForLocation();
    if (!smartRemValidation && smartRemValidation !== previousSmartREMToggle) {
      const positionIdsInRooms = _.flatMap(roomsData?.positions, (position) => _.map(position.rooms, "positionId"));
      if (positionIdsInRooms) {
        setResetLoading(true);
        resetConfigToDefault(positionIdsInRooms);
      } else {
        message.error(
          `Unable to reset position configurations of rooms to DEFAULT after disabling SmartREM Validation`
        );
      }
    }
  };

  const handleSearchTicketClasses = (search: string) => {
    refetchTicketClasses({
      ticketClass: `%${search}%`,
    });
  };

  const handleAddAllClasses = () => {
    form.setFieldsValue({
      activePropertyAlerts: ticketClasses?.ticketClasses.map((item) => item.id),
    });
  };

  const handleRemoveAllClasses = () => {
    form.setFieldsValue({
      activePropertyAlerts: [],
    });
  };

  const lastSmartRemValidationChanged = () => {
    if (lastSmartRemPeriod) {
      const smartRemPeriod = lastSmartRemPeriod.getSmartRemValidationHistory[0];
      if (smartRemPeriod && smartRemPeriod.validationEndDate) {
        return <p className="text-gray fs-sm">Last disabled: {formatDateTime24h(smartRemPeriod.validationEndDate)}</p>;
      }
      if (smartRemPeriod) {
        return <p className="text-gray fs-sm">Last enabled: {formatDateTime24h(smartRemPeriod.validationStartDate)}</p>;
      }
    }
  };

  const gatewayHardwareConfirm = (value: GatewayHardwareType) => {
    setGatewayHardwareType(value);
    openConfirmModalRef();
  };

  const handleOnPromiscuousModeChange = (e: RadioChangeEvent) => {
    setPromiscuousMode(e?.target?.value);
    openConfirmPromiscuousModeModalRef();
  };

  const handleOnHasKeyCardChange = (e: RadioChangeEvent) => {
    setHasKeyCard(e?.target?.value);
  };

  const handleOnEnableNoKeyCardStateChange = (e: RadioChangeEvent) => {
    setEnableNoKeyCardState(e?.target?.value);
    openConfirmEnableNoKeyCardStateModalRef();
  };

  const handleOnBeaconSlotManagementChange = (e: RadioChangeEvent) => {
    setBeaconSlotManagementEnabled(e?.target?.value);
    openBeaconSlotManagementModalRef();
  };

  return (
    <Modal
      title="Location settings"
      centered
      visible
      onCancel={closeModal}
      onOk={form.submit}
      width={400}
      okText="OK"
      okButtonProps={{ disabled: resetLoading }}
    >
      {!loading && !validationHistoryLoading && (
        <Form
          form={form}
          initialValues={{
            smartRemValidationSetpoint: lastSmartRemPeriod?.getSmartRemValidationHistory?.[0]?.smartsaveSetpoint ?? 25,
            smartRemValidationMode:
              lastSmartRemPeriod?.getSmartRemValidationHistory?.[0]?.automationMode ??
              ESmartREMValidationAutomationMode.SUPER_SAVE,
          }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item name="subscription" label="Subscription">
            <Select
              options={[
                {
                  label: "SmartREM",
                  value: LocationSubscription.SMART_REM,
                },
                {
                  label: "SmartAlloc",
                  value: LocationSubscription.SMART_ALLOC,
                },
                {
                  label: "SmartAlloc Pro",
                  value: LocationSubscription.SMART_ALLOC_PRO,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="showEnergyConsumption" label="Show energy consumption">
            <Radio.Group
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="showHeatingMode" label="Show heating mode">
            <Radio.Group
              defaultValue={false}
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="activePropertyAlerts" label="Active alert classes" className="mb-none">
            <Select
              options={ticketClasses?.ticketClasses.map((ticketClass) => ({
                value: ticketClass.id,
                label: ticketClass.ticketClass,
              }))}
              onSearch={handleSearchTicketClasses}
              showSearch
              mode="multiple"
              placeholder="Type to search ticket classes"
              maxTagTextLength={12}
              maxTagCount={6}
            />
          </Form.Item>
          <div className="d-flex justify-content-end">
            <Button type="link" onClick={handleAddAllClasses} className="p-xs">
              Add all
            </Button>
            <Button type="link" onClick={handleRemoveAllClasses} className="p-xs">
              Remove all
            </Button>
          </div>
          <Form.Item name="activeAutosetCount" label="Active Autoset count">
            <InputNumber min={0} max={10000} />
          </Form.Item>
          <Form.Item name="allowDisableAutomationMode" label="Kill switch mode">
            <Radio.Group
              defaultValue={false}
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="smartRemValidation" label="Smart REM Validation" className="mb-xs">
            <Radio.Group
              data-testid="smart-rem-toggle"
              defaultValue={false}
              onChange={handleSmartREMValidationOnChange}
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          {smartRemValidationEnabled && (
            <Form.Item name="smartRemValidationMode" label="Smart REM validation mode" className="mb-xs">
              <Select
                options={Object.values(ESmartREMValidationAutomationMode).map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </Form.Item>
          )}
          {smartRemValidationMode === ESmartREMValidationAutomationMode.SMART_SAVE && (
            <Form.Item
              name="smartRemValidationSetpoint"
              label="SMARTSAVE setpoint (Smart REM validation)"
              className="mb-xs"
            >
              <Select
                options={Array.from(Array(5).keys()).map((setpoint) => ({
                  label: (setpoint + 25).toString(),
                  value: setpoint + 25,
                }))}
              />
            </Form.Item>
          )}

          {lastSmartRemValidationChanged()}

          <Form.Item name="availableOperationalModes" label="Available Operational Modes">
            <Select
              defaultValue={AvailableOperationalModes.Both}
              options={[
                {
                  label: "Heating",
                  value: AvailableOperationalModes.Heating,
                },
                {
                  label: "Cooling",
                  value: AvailableOperationalModes.Cooling,
                },
                {
                  label: "Both",
                  value: AvailableOperationalModes.Both,
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Gateway hardware type"
            help={!gatewayHardwareType && "Please set the gateway hardware type"}
          >
            <Select value={gatewayHardwareType} onChange={gatewayHardwareConfirm}>
              {Object.values(GatewayHardwareType).map((type) => (
                <Select.Option value={type} key={type}>
                  {getGatewayHardwareTypeDisplayText(type)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Promiscuous mode">
            <Radio.Group
              value={promiscuousMode}
              onChange={handleOnPromiscuousModeChange}
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Beacon slot management">
            <Radio.Group
              value={beaconSlotManagementEnabled}
              onChange={handleOnBeaconSlotManagementChange}
              options={[
                {
                  label: "Enable",
                  value: true,
                },
                {
                  label: "Disable",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Keycards installed">
            <Radio.Group
              value={hasKeyCard}
              onChange={handleOnHasKeyCardChange}
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Enable no keycard state">
            <Radio.Group
              value={enableNoKeyCardState}
              onChange={handleOnEnableNoKeyCardStateChange}
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Form>
      )}
      <ModalWrapper
        ref={confirmModalRef}
        modal={ConfirmChangeHardwareModal}
        props={{
          locationId,
          gatewayHardwareType,
          onError: () => setGatewayHardwareType(prevGatewayHardwareType),
        }}
      />
      <ModalWrapper
        ref={confirmPromiscuousModeModalRef}
        modal={ConfirmPromiscuousModeModal}
        props={{
          locationId,
          promiscuousMode,
          onError: () => setPromiscuousMode(!!prevPromiscuousMode),
        }}
      />
      <ModalWrapper
        ref={confirmEnableNoKeyCardStateModalRef}
        modal={ConfirmEnableNoKeyCardStateModal}
        props={{
          locationId,
          enableNoKeyCardState,
          onError: () => setEnableNoKeyCardState(!!prevEnableNoKeyCardState),
        }}
      />
      <ModalWrapper
        ref={confirmOverrideSmartREM}
        modal={OverrideSmartREMValidationConfirmModal}
        props={{
          loading: upsertLoading,
          onConfirm: updateLocationMetadata,
        }}
      />
      <ModalWrapper
        ref={confirmBeaconSlotManagementModalRef}
        modal={ConfirmBeaconSlotManagementModal}
        props={{
          locationId,
          beaconSlotManagementEnabled,
          onError: () => setBeaconSlotManagementEnabled(!!prevBeaconSlotManagementEnabled),
        }}
      />
    </Modal>
  );
};

export default LocationSettingsModal;
