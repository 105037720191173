import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Col, Row } from "antd";
import { getAutomationHoursText, getIsDeviatedFromDefaultIcon } from "./KeyConfigurationList.helper";
import { Key } from "./KeyConfigurationList.d";
import { AutomationMode } from "../types";
import { formatTemp, getOperationalText } from "../key.helper";

const KeyConfigurationMobileItem = (props: { locationId: string; record: Key }) => {
  const { record, locationId } = props;
  return (
    <Link
      to={`/locations/${locationId}/keys/${record.type === "room" ? record.parentId : record.positionId}`}
      className="text-black"
    >
      <div className="d-flex flex-row">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center mb-m">
            <h3 className="mb-none">{record.positionName}</h3>
            <div className="d-flex justify-content-between">
              {getIsDeviatedFromDefaultIcon(record.isDeviatedFromDefaultConfig)}
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <Row>
              <Col span={12} className="mb-m">
                {record.type === "key" && (
                  <>
                    <span className="d-block fs-sm">Key Category</span>
                    <span className="d-block ">{record.keyCategoryPositionMapping?.categoryName}</span>
                  </>
                )}
              </Col>
              <Col span={12} className="mb-m">
                <span className="d-block fs-sm">Automation Mode</span>
                <span className="d-block ">
                  <span className={classnames({ "text-warning": record.automationMode === AutomationMode.Disabled })}>
                    {record.automationMode}
                  </span>
                </span>
              </Col>
              <Col span={12} className="mb-m">
                <span className="d-block fs-sm">Setpoint Limits</span>
                <span className="d-block ">
                  {record.minTemp && record.maxTemp
                    ? `${formatTemp(record.minTemp)} - ${formatTemp(record.maxTemp)}`
                    : "_"}
                </span>
              </Col>
              <Col span={12} className="mb-m">
                <span className="d-block fs-sm">Automation Hours</span>
                <span className="d-block ">{getAutomationHoursText(record)}</span>
              </Col>
              <Col span={12} className="mb-m">
                <span className="d-block fs-sm">Operational Mode</span>
                <span className="d-block ">{getOperationalText(record.operationalMode)}</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default KeyConfigurationMobileItem;
