import { Button } from "antd";
import { ChatQuestionIcon } from "components/Icons";
import React from "react";

interface ISupportButtonProps {
  triggerHelp: () => void;
}

const SupportButton = ({ triggerHelp }: ISupportButtonProps) => {
  return (
    <Button
      type="text"
      onClick={triggerHelp}
      className="ml-xs"
      style={{ backgroundColor: "orange", borderRadius: "20px" }}
    >
      <ChatQuestionIcon />
    </Button>
  );
};

export default SupportButton;
