import React from "react";

import "./CurrentRoomInfo.css";

const CurrentRoomInfo = (props: any) => {
  const { keyName, roomName, categoryName } = props;

  return (
    <div className="current-room-info">
      <p className="list-item-header">KEY</p>
      <p className="list-item-text">{keyName}</p>

      <p className="list-item-header">ROOM</p>
      <p className="list-item-text">{roomName}</p>

      <p className="list-item-header">CATEGORY</p>
      <p className="list-item-category-text">{categoryName}</p>
    </div>
  );
};

export default CurrentRoomInfo;
