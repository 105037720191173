import React from "react";
import Table from "components/Table/Table";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";
import { QuestionIcon } from "components/Icons";
import { Key } from "./KeyConfigurationList.d";
import useBreakpoint from "../../../hooks/use-breakpoint";
import {
  ConfigurationToolTip,
  getAutomationHoursText,
  getIsDeviatedFromDefaultIcon,
} from "./KeyConfigurationList.helper";
import KeyConfigurationMobileItem from "./KeyConfigurationMobileItem";
import { AutomationMode } from "../types";
import { formatTemp, getAutomationModeText, getOperationalText } from "../key.helper";

const KeyConfigurationListTable = (props: any) => {
  const { locationId } = useParams() as { locationId: string };

  const screen = useBreakpoint();

  const getPositionLink = (record: Key) => {
    if (record.type === "room") {
      return <Link to={`/locations/${locationId}/keys/${record.parentId}/configurations`}>{record.positionName}</Link>;
    }
    return <Link to={`/locations/${locationId}/keys/${record.positionId}/configurations`}>{record.positionName}</Link>;
  };

  const COLUMN_CONFIG = [
    {
      title: "Key",
      isVisible: true,
      render: (key: Key) => {
        return (
          <div className="d-flex justify-content-between">
            {getPositionLink(key)}
            <div className="d-flex justify-content-between">
              {getIsDeviatedFromDefaultIcon(key.isDeviatedFromDefaultConfig)}
            </div>
          </div>
        );
      },
      isShowOnMobile: false,
    },
    {
      title: "Category",
      render: (record: Key) => {
        return record.keyCategoryPositionMapping?.categoryName;
      },
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: (
        <>
          Automation Mode&nbsp;
          <QuestionIcon tooltip={ConfigurationToolTip.AutomationMode} />
        </>
      ),
      render: (record: Key) => (
        <span className={classnames({ "text-warning": record.automationMode === AutomationMode.Disabled })}>
          {getAutomationModeText(record.automationMode ?? "")}
        </span>
      ),
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: (
        <>
          Unoccupied Setpoint&nbsp;
          <QuestionIcon tooltip={ConfigurationToolTip.UnoccupiedSetpoint} />
        </>
      ),
      render: (record: Key) =>
        record.automationMode === AutomationMode.SmartSave ? formatTemp(record.unoccupiedTemp) : "",
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: (
        <>
          Setpoint Limits&nbsp;
          <QuestionIcon tooltip={ConfigurationToolTip.SetpointLimits} />
        </>
      ),
      render: (record: Key) =>
        record.minTemp && record.maxTemp ? `${formatTemp(record.minTemp)} - ${formatTemp(record.maxTemp)}` : "",
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: (
        <>
          Automation Hours&nbsp;
          <QuestionIcon tooltip={ConfigurationToolTip.AutomationHours} />
        </>
      ),
      // Automation Hours is reversion of AcNight Times
      render: (record: Key) => getAutomationHoursText(record),
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: <>Operational Mode</>,
      render: (record: Key) => getOperationalText(record.operationalMode),
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      render: (record: Key) => {
        return <KeyConfigurationMobileItem locationId={locationId} record={record} />;
      },
      isVisible: true,
      isShowOnMobile: true,
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  return (
    <Table
      {...props}
      columns={columns}
      classNames={{ "table-checkbox-with-expandable": true }}
      dataItemName="keys"
      rowKey="positionId"
    />
  );
};

export default KeyConfigurationListTable;
