import { Button, Tabs } from "antd";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Checklist from "pages/Checklist/Checklist";
import { PositionFunction } from "pages/Checklist/Checklist.d";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ContractorNodeList from "pages/Node/ContractorNodeList/ContractorNodeList";

export enum ActiveTab {
  NODE_LIST = "node-list",
  CHECK_LIST = "check-list",
}

const ContractorKeyDetails = () => {
  const { keyId, locationId, activeTab } = useParams<{ keyId: string; locationId: string; activeTab: string }>();
  const location = useLocation();

  const handleTabChange = (activeKey: string) => {
    window.history.pushState({}, "", `/locations/${locationId}/keys/${keyId}/${activeKey}${location.search}`);
  };

  const tabConfigs = [
    {
      name: "Nodes",
      key: ActiveTab.NODE_LIST,
      component: <ContractorNodeList keyId={keyId} active={activeTab === ActiveTab.NODE_LIST} showKeyButton />,
    },
    {
      name: "Checklist",
      key: ActiveTab.CHECK_LIST,
      component: <Checklist keyPositionId={keyId} positionFunction={PositionFunction.AUTOSET} />,
    },
  ];

  return (
    <div>
      <Link to={`/locations/${locationId}${location.search}`}>
        <Button type="link">
          <ArrowLeftOutlined /> Back to location
        </Button>
      </Link>

      <Tabs className="header-tab-bar" defaultActiveKey={activeTab} onChange={handleTabChange}>
        {tabConfigs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ContractorKeyDetails;
