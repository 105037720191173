import { GetPositionNameQuery } from "pacts/app-webcore/hasura-webcore.graphql";

export interface KeyEntryJobChecklistProps {
  [x: string]: any;
  locationId: string;
  keyId: string;
}

export enum TaskStatus {
  PENDING = "pending",
  IN_PROGRESS = "in-progress",
  DONE = "done",
}

export enum TaskParentEntityType {
  LOCATION = "location",
  GROUP = "group",
  KEY = "key",
  ROOM = "room",
  SLOT = "slot",
  GATEWAY = "gateway",
  COMPRESSOR = "compressor",
}

export enum Executor {
  CONTRACTOR = "Contractor",
}

export type RoomList = NonNullable<GetPositionNameQuery["positions"]>;
