import { Button, Checkbox, Col, DatePicker, Drawer, Row } from "antd";
import useBreakpoint from "hooks/use-breakpoint";
import { isEmpty, isUndefined } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { SHORT_DATE_FORMAT } from "utils/date";
import { BOOKING_LIST_STATUS_FILTER_OPTIONS } from "./constants";
import { IBookingListQueryFilter, PmsRoomCategory } from "./types";

interface IBookingListAdvancedFilterProps {
  isOpen: boolean;
  filter?: IBookingListQueryFilter;
  roomCategories?: PmsRoomCategory[];
  onClose: () => void; // open or close
  onFilterSet: (filter: IBookingListQueryFilter | undefined) => void;
}

interface IBookingListSelectionFilterSectionProps {
  filterKey: keyof IBookingListQueryFilter;
  filter?: IBookingListQueryFilter;
  title?: string;
  options: { label?: string; value: string }[];
  onFilterSet: (filter: IBookingListQueryFilter | undefined) => void;
}

const BookingListAdvancedFilterAction = ({
  applyFilter,
  clearFilter,
}: {
  applyFilter: () => void;
  clearFilter: () => void;
}) => {
  return (
    <Row justify="space-between">
      <Col>
        <Button type="text" className="text-error" onClick={clearFilter}>
          Clear all
        </Button>
      </Col>
      <Col>
        <Button type="primary" onClick={applyFilter}>
          Apply Filter
        </Button>
      </Col>
    </Row>
  );
};

const BookingListDateFilter = ({
  onFilterSet,
  filter,
}: Pick<IBookingListAdvancedFilterProps, "onFilterSet" | "filter">) => {
  return (
    <Row justify="space-between">
      <Col span={12}>
        <DatePicker
          data-testid="advanced-booking-filter-check-in-date"
          className="mr-m"
          placeholder="Check-in date"
          value={isUndefined(filter?.checkInDate) ? undefined : moment(filter?.checkInDate)}
          onChange={(value) => onFilterSet({ ...filter, checkInDate: value ? value.toDate() : undefined })}
          format={SHORT_DATE_FORMAT}
        />
      </Col>
      <Col span={12}>
        <DatePicker
          data-testid="advanced-booking-filter-check-out-date"
          className="mr-m"
          placeholder="Check-out date"
          value={isUndefined(filter?.checkOutDate) ? undefined : moment(filter?.checkOutDate)}
          onChange={(value) => onFilterSet({ ...filter, checkOutDate: value ? value.toDate() : undefined })}
          format={SHORT_DATE_FORMAT}
        />
      </Col>
    </Row>
  );
};

const BookingListSelectionFilterSection = ({
  filterKey,
  filter,
  options,
  title,
  onFilterSet,
}: IBookingListSelectionFilterSectionProps) => {
  const onChange = React.useCallback(
    (chosenValues: (string | number | boolean)[]) => {
      onFilterSet({
        ...filter,
        [filterKey]: chosenValues,
      });
    },
    [onFilterSet, filter, filterKey]
  );

  if (isEmpty(options)) {
    return null;
  }

  return (
    <div className="mt-xl">
      <h4 className="font-weight-bold">{title}</h4>
      <Checkbox.Group onChange={onChange} value={(filter?.[filterKey] || []) as string[]}>
        <Row>
          {options.map((o) => (
            <Col span={24} className="py-xs" key={o.value}>
              <Checkbox value={o.value}>{o.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

const BookingListAdvancedFilter = ({
  isOpen,
  filter,
  onClose,
  onFilterSet,
  roomCategories,
}: IBookingListAdvancedFilterProps) => {
  const screen = useBreakpoint();
  const [tempFilter, setTempFilter] = React.useState(filter);

  const categoryOpts = React.useMemo(
    () => roomCategories?.map((c) => ({ label: c.name || "", value: c.id || "" })) || [],
    [roomCategories]
  );

  const applyFilter = React.useCallback(() => {
    onFilterSet(tempFilter);
    onClose();
  }, [tempFilter, onFilterSet, onClose]);

  const clearFilter = React.useCallback(() => {
    setTempFilter(undefined);
    onFilterSet(undefined);
  }, [onFilterSet]);

  useEffect(() => {
    setTempFilter({ ...filter });
  }, [filter]);

  return (
    <Drawer
      title={<h3 className="font-weight-bold">Filter</h3>}
      placement="right"
      visible={isOpen}
      closable
      onClose={onClose}
      width={screen.tabletUp ? 400 : "100%"}
      footer={<BookingListAdvancedFilterAction applyFilter={applyFilter} clearFilter={clearFilter} />}
    >
      <BookingListDateFilter filter={tempFilter} onFilterSet={setTempFilter} />
      <BookingListSelectionFilterSection
        filterKey="statuses"
        filter={tempFilter}
        title="Status"
        options={BOOKING_LIST_STATUS_FILTER_OPTIONS}
        onFilterSet={setTempFilter}
      />
      <BookingListSelectionFilterSection
        filterKey="roomCategories"
        filter={tempFilter}
        title="Room Category"
        options={categoryOpts}
        onFilterSet={setTempFilter}
      />
    </Drawer>
  );
};

export default BookingListAdvancedFilter;
