import React, { useState } from "react";
import { AcModel, AcModelsQuery, useAcModelsQuery } from "../pacts/app-webcore/hasura-webcore.graphql";
import Loading from "../components/Loading";

export const MasterDataContext = React.createContext({ acModels: Array<AcModel>() });

export const MasterDataProvider = (props: { loadTypes: string[]; children: any }) => {
  const { loadTypes, children } = props;
  const [acModels, setAcModels] = useState();

  const { loading } = useAcModelsQuery({
    variables: {
      // There are less than 100 items in db now, so load all will be ok.
      pagination: {
        limit: 1000,
        offset: 0,
      },
    },
    onCompleted: (data: AcModelsQuery) => {
      setAcModels(
        data?.ACModels?.ACModels.sort((a, b) => +(a.identifier > b.identifier) || -(a.identifier < b.identifier))
      );
    },
    skip: !loadTypes.some((k) => k === "ACModels"),
  });

  if (loading) {
    return <Loading label="master data loading" />;
  }

  return <MasterDataContext.Provider value={{ acModels }}>{children}</MasterDataContext.Provider>;
};
