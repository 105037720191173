import React, { useState } from "react";

import classnames from "classnames";
import { Collapse } from "antd";
import { scroller } from "react-scroll";
import { DeleteOutlined } from "@ant-design/icons";
import { Category, Room, Session } from "./DataTypes";
import "./CategoryCollapse.scss";

const { Panel } = Collapse;

type CategoryCollapseProps = {
  session: Session;
  onSessionChangeCallback: () => void;
  updateCurrentCategory: (category: Category) => void;
};

const CategoryCollapse = ({ session, onSessionChangeCallback, updateCurrentCategory }: CategoryCollapseProps) => {
  const { categories } = session;
  const [activeRoom, setActiveRoom] = useState<string>();

  const handleDeleteRoom = (category: Category, room: Room) => {
    category.deleteRoom(room);
    onSessionChangeCallback();
  };

  const handleClickRoom = (category: Category, roomId: string) => {
    setActiveRoom(roomId);
    updateCurrentCategory(category);

    // Make sure that will scroll after update category
    setTimeout(() => {
      scroller.scrollTo(roomId, {
        smooth: true,
        containerId: "room-list",
      });
    }, 10);
  };

  const isActiveRoom = (roomIdMatch: any) => activeRoom === roomIdMatch;

  const renderHeaderPanel = (category: Category) => (
    <span role="none" onClick={() => updateCurrentCategory(category)}>
      {category.name}
    </span>
  );

  return (
    <>
      <Collapse collapsible="header" className="category-collapse">
        {categories.map((category: Category) => {
          return (
            <Panel className="category-collapse-panel" header={renderHeaderPanel(category)} key={category.id}>
              {category.rooms.map((room: Room) => (
                <div className={classnames("room-name", isActiveRoom(room.id) && "active")} key={room.id}>
                  <span role="none" className="text" onClick={() => handleClickRoom(category, room.id)}>
                    {room.name}
                  </span>
                  <DeleteOutlined className="detele-room" onClick={() => handleDeleteRoom(category, room)} />
                </div>
              ))}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default CategoryCollapse;
