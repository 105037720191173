import React, { useEffect } from "react";
import { useLocationEmbeddedAnalyticsInfoQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import Loading from "components/Loading";

type InsightsDasboardDetailProps = {
  embedId: string;
  locationId: string;
};

function InsightsDasboardDetail({ embedId, locationId }: InsightsDasboardDetailProps) {
  const { data, loading } = useLocationEmbeddedAnalyticsInfoQuery({
    variables: {
      locationId,
      input: {
        embedId,
      },
    },
  });

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.params.height) {
        iframeRef.current?.setAttribute("height", `${event.data.params.height}px`);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [embedId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <iframe
        id="iframe"
        title="embed analytics"
        ref={iframeRef}
        data-hj-allow-iframe
        srcDoc={`
        <html>
          <body>
            <form id="form" action="${data?.locationEmbeddedAnalyticsInfo.url}" method="post">
              <input type="hidden" name="embedToken" value="${data?.locationEmbeddedAnalyticsInfo.token}">
            </form>
            <script>
              document.getElementById("form").submit();
              document.addEventListener("DOMContentLoaded", function () {
                var height = document.body.scrollHeight;
                parent.postMessage({ iframeHeight: height }, "*");
              });
            </script>
          </body>
        </html>
      `}
        width="100%"
        height="500px" // default height
        style={{ border: 0 }}
      />
    </div>
  );
}

export default InsightsDasboardDetail;
