import { Button, Col, Row } from "antd";
import React from "react";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { Permission } from "pacts/permission";
import Can from "components/Can/Can";
import { useLocationDomoEmbedsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import InsightsDashboardList from "./InsightsDashboardList/InsightsDashboardList";
import ManageLocationDashboardsModal from "./ManageLocationDashboardsModal/ManageLocationDashboardsModal";
import { DomoEmbed } from "./Analytics.d";

type AnalyticsProps = {
  locationId: string;
};

const Analytics: React.FC<AnalyticsProps> = ({ locationId }) => {
  const { data, loading, refetch } = useLocationDomoEmbedsQuery({
    variables: { where: { location_id: { _eq: locationId } } },
  });

  const [manageLocationDashboardsModalRef, openManageLocationDashboardsModal] = useModalWrapperTrigger();

  if (loading) return <p>Loading...</p>;

  const locationDomoEmbeds = data?.locationDomoEmbeds.map(({ domo_embed }: { domo_embed: DomoEmbed }) => domo_embed);
  return (
    <>
      <Row justify="end">
        <Can
          requiredPermission={Permission.LOCATION_EDIT}
          yes={
            <Col>
              <Button type="primary" onClick={openManageLocationDashboardsModal}>
                Manage Location Dashboards
              </Button>
            </Col>
          }
        />
      </Row>
      <InsightsDashboardList locationId={locationId} items={locationDomoEmbeds || []} />
      <ModalWrapper
        onCancelled={refetch}
        ref={manageLocationDashboardsModalRef}
        modal={ManageLocationDashboardsModal}
        props={{ locationId, locationDomoEmbeds, refetch }}
      />
    </>
  );
};

export default Analytics;
