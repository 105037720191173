import { Checkbox, Col, Radio, Row, Select, Space, Switch } from "antd";
import errorHandler from "errorHandler";
import { groupBy } from "lodash";
import {
  useFirmwareReleasesForDropdownLazyQuery,
  useLocationsForAutocompleteLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useState } from "react";
import { OTA_NODE_TYPES } from "pages/Ota/OtaDetails/OtaDetails.constants";
import { getOtaNodeTypeDisplayValue } from "pages/Ota/OtaDetails/OtaDetails.helper";
import FilterDropdown from "./FilterDropdown/FilterDropdown";
import FwVersionSelector from "./FwVersionSelector/FwVersionSelector";
import { INITIAL_FILTER, NodeFilterInput, UpgradeStatus } from "../OtaNodeList.d";
import { OtaNodeFilterProps, UPGRADE_STATUS_OPTIONS } from "./OtaNodeFilter.d";
import ExcludeLockedSwitcher from "./ExcludeLockedSwitcher/ExcludeLockedSwitcher";

const OtaNodeFilter = (props: OtaNodeFilterProps) => {
  const { onFilterChange, initialFilter } = props;

  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([]);
  const [filters, setFilters] = useState<NodeFilterInput>(initialFilter);

  useEffect(() => {
    setFilters(initialFilter);
  }, [initialFilter]);

  const [getLocations, { data: locationsData, loading: locationsQueryLoading }] = useLocationsForAutocompleteLazyQuery({
    onError: errorHandler.handleError,
  });

  const [getFirmwareReleasesForDropdown, { data: firmwareReleasesData }] = useFirmwareReleasesForDropdownLazyQuery({
    onError: errorHandler.handleError,
  });

  const handleLocationSearch = (value: string) => {
    getLocations({ variables: { filter: { locationName: value } } });
  };

  const locations = locationsData?.locations.locations;

  useEffect(() => {
    setLocationOptions(
      locations?.map((location) => {
        return {
          value: location.id,
          label: location.locationName,
        };
      }) || []
    );
  }, [locations]);

  const groups = groupBy(firmwareReleasesData?.firmwareReleases, (p) => p.nodeSubType);

  const handleChangeExcludeLockedVersion = (checked: boolean) => {
    setFilters({
      ...filters,
      firmwareVersionExcludeLocked: checked,
    });
  };

  const filterDropdowns: { name: string; onClear: () => void; component: React.ReactElement }[] = [
    {
      name: "Location",
      onClear: () => {
        setFilters({
          ...filters,
          locations: INITIAL_FILTER.locations,
        });
      },
      component: (
        <div style={{ width: 300 }}>
          <Select
            data-testid="location-filter-select-testId"
            showSearch
            className="w-100"
            mode="multiple"
            value={filters.locations}
            loading={locationsQueryLoading}
            options={locationOptions}
            onSearch={handleLocationSearch}
            onDropdownVisibleChange={(open) => open && handleLocationSearch("")}
            placeholder="Select one or more locations"
            notFoundContent="No locations found"
            autoFocus
            onChange={(value) => {
              setFilters({
                ...filters,
                locations: value,
              });
            }}
            filterOption={() => true}
            autoClearSearchValue={false}
          />
        </div>
      ),
    },
    {
      name: "Target FW Version",
      onClear: () => {
        setFilters({
          ...filters,
          firmwareVersionExcludeLocked: INITIAL_FILTER.firmwareVersionExcludeLocked,
          targetFwVersionSpecificVersion: INITIAL_FILTER.targetFwVersionSpecificVersion,
          targetFwVersion: INITIAL_FILTER.targetFwVersion,
        });
      },
      component: (
        <div style={{ width: 400 }}>
          <ExcludeLockedSwitcher
            onChange={handleChangeExcludeLockedVersion}
            checked={filters.firmwareVersionExcludeLocked}
          />
          <Row wrap={false} gutter={10}>
            <Col>
              <Switch
                onChange={(checked) => {
                  if (checked) {
                    getFirmwareReleasesForDropdown();
                  }
                  setFilters({
                    ...filters,
                    targetFwVersionSpecificVersion: checked,
                  });
                }}
                checked={filters.targetFwVersionSpecificVersion}
              />
            </Col>
            <Col flex="auto">
              <div className="fs-m font-weight-bold">Specific Version</div>
              <FwVersionSelector
                disabled={!filters.targetFwVersionSpecificVersion}
                groups={groups}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    targetFwVersion: value,
                  });
                }}
                value={filters.targetFwVersion}
              />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      name: "Current FW Version",
      onClear: () => {
        setFilters({
          ...filters,
          firmwareVersionExcludeLocked: INITIAL_FILTER.firmwareVersionExcludeLocked,
          currentFwVersionSpecificVersion: INITIAL_FILTER.currentFwVersionSpecificVersion,
          currentFwVersion: INITIAL_FILTER.currentFwVersion,
        });
      },
      component: (
        <div style={{ width: 400 }}>
          <ExcludeLockedSwitcher
            onChange={handleChangeExcludeLockedVersion}
            checked={filters.firmwareVersionExcludeLocked}
          />
          <Row wrap={false} gutter={10}>
            <Col>
              <Switch
                onChange={(checked) => {
                  if (checked) {
                    getFirmwareReleasesForDropdown();
                  }
                  setFilters({
                    ...filters,
                    currentFwVersionSpecificVersion: checked,
                  });
                }}
                checked={filters.currentFwVersionSpecificVersion}
              />
            </Col>
            <Col flex="auto">
              <div className="fs-m font-weight-bold">Specific Version</div>
              <FwVersionSelector
                disabled={!filters.currentFwVersionSpecificVersion}
                groups={groups}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    currentFwVersion: value,
                  });
                }}
                value={filters.currentFwVersion}
              />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      name: "Upgrade Status",
      onClear: () => {
        setFilters({
          ...filters,
          upgradeStatuses: INITIAL_FILTER.upgradeStatuses,
        });
      },
      component: (
        <div>
          <Checkbox.Group
            className="d-flex flex-column"
            onChange={(value) => {
              setFilters({
                ...filters,
                upgradeStatuses: value as UpgradeStatus[],
              });
            }}
            value={filters.upgradeStatuses}
          >
            <Space direction="vertical">
              {UPGRADE_STATUS_OPTIONS.map((status) => {
                return (
                  <Checkbox key={status.value} value={status.value}>
                    {status.label}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </div>
      ),
    },
    {
      name: "Node Type",
      onClear: () => {
        setFilters({
          ...filters,
          nodeSubType: INITIAL_FILTER.nodeSubType,
        });
      },
      component: (
        <div>
          <Radio.Group
            onChange={(value) => {
              setFilters({
                ...filters,
                nodeSubType: value.target.value,
              });
            }}
            value={filters.nodeSubType}
          >
            <Space direction="vertical">
              {OTA_NODE_TYPES.map((nst) => {
                return (
                  <Radio key={nst} value={nst}>
                    {getOtaNodeTypeDisplayValue(nst)}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
      ),
    },
  ];

  const handleApplyFilter = () => {
    onFilterChange(filters);
  };

  return (
    <div className="d-flex gap-s flex-wrap">
      {filterDropdowns.map((filter, index) => {
        return (
          <FilterDropdown
            key={index}
            component={filter.component}
            name={filter.name}
            onApply={handleApplyFilter}
            onClear={filter.onClear}
          />
        );
      })}
    </div>
  );
};

export default OtaNodeFilter;
