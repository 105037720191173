import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TreeSelect, Modal, message } from "antd";
import { useUpsertCompressorPositionMappingsMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import {
  connectedRoomsToAutosetValues,
  transformAutosetToAutosetInput,
  transformAutosetsToTreeNodes,
} from "utils/DataTransformer";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import { ConnectedRoomDataType } from "pages/InfrastructureDetails/InfrastructureDetails.d";

export interface AdjustRoomConnectionModalProps {
  connectedRooms: ConnectedRoomDataType[];
  keysAndRooms: any[];
  reloadInfrastructure: () => void;
}

const AdjustRoomConnectionModal = ({
  connectedRooms,
  keysAndRooms,
  modalWrapperProps,
  closeModal,
  reloadInfrastructure,
}: AdjustRoomConnectionModalProps & ModalWrapperProps) => {
  const { infrastructureId } = useParams<{ infrastructureId: string }>();
  const [value, setValue] = useState<any[]>([]);

  const actionTitle = value.length === 0 ? "Add" : "Edit";

  const [upsertCompressorPositionMappings, { loading }] = useUpsertCompressorPositionMappingsMutation({
    onCompleted: () => {
      message.success("Update Room Connection successful");
      closeModal();
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    setValue(connectedRoomsToAutosetValues(connectedRooms));
  }, [connectedRooms]);

  const callUpsertCompressorApi = () => {
    const dataInput = transformAutosetToAutosetInput(value);
    const mappingsInput = dataInput.map((data) => ({ compressorId: infrastructureId, positionId: data.positionId }));

    upsertCompressorPositionMappings({
      variables: {
        compressorId: infrastructureId,
        mappingsInput,
      },
    });
  };

  const handleSubmit = () => {
    Modal.confirm({
      title: "Add Room Connection",
      content: `You are connecting ${value.length} Room(s). Do you wish to proceed?`,
      onOk: callUpsertCompressorApi,
    });
  };

  const autosetSelectorProps = {
    treeData: transformAutosetsToTreeNodes(keysAndRooms),
    value,
    onChange: (data: any) => setValue(data),
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: "Please Select Autoset",
    showSearch: true,
    className: "w-100",
  };

  return (
    <Modal
      title={`${actionTitle} Room Connection`}
      centered
      onOk={handleSubmit}
      width={450}
      okText="Confirm"
      confirmLoading={loading}
      {...modalWrapperProps}
    >
      <TreeSelect {...autosetSelectorProps} />
    </Modal>
  );
};

export default AdjustRoomConnectionModal;
