import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DownOutlined, FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Input, Menu, Select, TimePicker } from "antd";
import classnames from "classnames";
import uniq from "lodash/uniq";
import { FilterOption, FilterOptionType } from "pacts/app-webcore/hasura-webcore.graphql";
import useBreakpoint from "hooks/use-breakpoint";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE, FILTER_GROUP_NAME_DEFAULT, FilterDropdownProps } from "./FilterDropdown.d";
import styles from "./FilterDropdown.module.scss";
import { TIME_24H_FORMAT } from "../../utils/date";

const FilterDropdown = (props: FilterDropdownProps) => {
  const { filterOptions, onOptionChange, waitApplyAll, currentFilter, onFilterChange } = props;
  const screen = useBreakpoint();

  const [tempFilter, setTempFilter] = useState<any>({});

  const isApplyingCheckboxFilters = useMemo(() => {
    let isApplyingFilters = false;
    if (!currentFilter) return false;
    Object.keys(currentFilter).forEach((field) => {
      if (Array.isArray(currentFilter[field]) && currentFilter[field]?.length > 0) {
        isApplyingFilters = true;
      }
    });
    return isApplyingFilters;
  }, [currentFilter]);

  const handleTempFilterChange = useCallback(
    (option: FilterOption, value: any) => {
      if (option.type === FilterOptionType.Checkbox) {
        let currentOptions = tempFilter ? tempFilter[option.field] || [] : [];
        if (value) {
          currentOptions = currentOptions.concat(option.value);
        } else {
          currentOptions = currentOptions.filter((optionValue: any) => optionValue !== option.value);
        }
        setTempFilter((current: any) => ({
          ...current,
          [option.field]: currentOptions,
        }));
      }
    },
    [tempFilter, setTempFilter]
  );

  const handleOptionChange = useCallback(
    (option: FilterOption, value: any, key: string) => {
      if (waitApplyAll) {
        handleTempFilterChange(option, value, key);
      } else {
        onOptionChange(option, value, key);
      }
    },
    [onOptionChange, waitApplyAll, handleTempFilterChange]
  );

  const onClearOptions = useCallback(() => {
    if (onFilterChange) {
      onFilterChange({});
    }
  }, [onFilterChange]);

  const onApplyOptions = useCallback(() => {
    if (onFilterChange) {
      onFilterChange(tempFilter);
    }
  }, [onFilterChange, tempFilter]);

  const onVisibleChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        setTempFilter(currentFilter || {});
      }
    },
    [currentFilter, setTempFilter]
  );

  useEffect(() => {
    setTempFilter(currentFilter || {});
  }, [currentFilter]);

  filterOptions.forEach((option, index) => {
    if (!option.groupName) filterOptions[index].groupName = FILTER_GROUP_NAME_DEFAULT;
    if (!option.type) filterOptions[index].type = FilterOptionType.Checkbox;
  });
  const groupNames = uniq(filterOptions.map((option) => option.groupName!));

  const getDropdownLabelFilterIcon = () => {
    if (isApplyingCheckboxFilters) {
      return <FilterFilled className={styles.filterIcon} />;
    }
    return <FilterOutlined className={styles.filterIcon} />;
  };

  const filterButtonType = useMemo(() => {
    return isApplyingCheckboxFilters ? "primary" : "link";
  }, [isApplyingCheckboxFilters]);

  const getFilterDropdownButton = () => {
    if (!waitApplyAll) {
      return (
        <Button className={`${styles.filterToggleBtn} filter-toggle-btn`} type="link">
          Filter
          <DownOutlined />
        </Button>
      );
    }

    if (screen.mobileAndTabletOnly) {
      return (
        <Button type={filterButtonType} className={`${styles.filterToggleBtnMobile} ml-s filter-toggle-btn`}>
          {getDropdownLabelFilterIcon()}
        </Button>
      );
    }

    return (
      <Button type={filterButtonType} className={`${styles.filterToggleBtn} ml-s filter-toggle-btn`}>
        {getDropdownLabelFilterIcon()}
        Filter
        <DownOutlined />
      </Button>
    );
  };

  const menu = (
    <div className={classnames(styles.filterDropdownMenu, screen.desktopOny && styles.filterDropdownMenuDesktop)}>
      <div className={styles.filterMenuHeader}>
        <FilterOutlined />
        Filter
      </div>

      <div className={classnames("flex-row pb-xs", screen.desktopOny && "d-flex")} data-testid="filter-dropdown">
        {groupNames.map((groupName) => (
          <div key={groupName} className={styles.filterMenuGroup}>
            {groupName === FILTER_GROUP_NAME_DEFAULT || <div className={styles.filterMenuGroupName}>{groupName}</div>}
            <Menu className={classnames(screen.desktopOny && styles.filterGroupContent)} selectable={false}>
              {filterOptions
                .filter((option) => option.groupName === groupName)
                .map((option: FilterOption) => (
                  <React.Fragment key={option.value}>
                    {option.type === FilterOptionType.Checkbox && (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                      <div
                        onClick={(e: any) => {
                          if (waitApplyAll) {
                            e.stopPropagation();
                          }
                        }}
                        className={classnames(styles.filterMenuItem, "ant-dropdown-menu-item pl-s mr-s")}
                      >
                        <Checkbox
                          onChange={(e) => handleOptionChange(option, e.target.checked, "")}
                          checked={tempFilter[option.field]?.includes(option.value)}
                        >
                          <span>{option.label}</span>
                          {option.count !== FILTER_DROPDOWN_COUNT_HIDDEN_VALUE && (
                            <span className={styles.filterOptionLabelCount}>{option.count}</span>
                          )}
                        </Checkbox>
                      </div>
                    )}

                    {option.type === FilterOptionType.InputValue && (
                      <div
                        aria-hidden="true"
                        className="mt-xs px-s"
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="lh-24 mr-xs">{option.label}</span>
                        <Input
                          className={styles.filterInputValueInput}
                          placeholder="Value"
                          size="small"
                          onChange={(e: any) => handleOptionChange(option, e.target.value, "inputValue")}
                        />
                      </div>
                    )}

                    {option.type === FilterOptionType.FloorCeilingValue && (
                      <div
                        aria-hidden="true"
                        className="mt-xs px-s"
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="lh-24 mr-xs">{option.label}</span>
                        <Input
                          className={styles.filterInputValueInput}
                          placeholder="Value"
                          size="small"
                          onChange={(e: any) => handleOptionChange(option, e.target.value, "floorValue")}
                        />
                        <span>{">= or <= "}</span>
                        <Input
                          className={styles.filterInputValueInput}
                          placeholder="Value"
                          size="small"
                          onChange={(e: any) => handleOptionChange(option, e.target.value, "ceilingValue")}
                        />
                      </div>
                    )}

                    {option.type === FilterOptionType.Select && (
                      <div
                        aria-hidden="true"
                        className="mt-xs px-s"
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="lh-24 mr-xs">{option.label}</span>
                        <Select
                          allowClear
                          placeholder="Select"
                          className={styles.filterSelect}
                          onChange={(val) => handleOptionChange(option, val, "")}
                        >
                          {option.options?.map((opt) => (
                            <Select.Option key={opt} value={opt}>
                              {opt}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    )}

                    {option.type === FilterOptionType.TimePicker && (
                      <div
                        aria-hidden="true"
                        className="mt-xs px-s"
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="lh-24 mr-xs">{option.label}</span>
                        <TimePicker format={TIME_24H_FORMAT} onChange={(val) => handleOptionChange(option, val, "")} />
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </Menu>
          </div>
        ))}
      </div>
      {waitApplyAll && (
        <div className={styles.actionButtonContainer}>
          <Button className={styles.clearButton} onClick={onClearOptions}>
            Clear
          </Button>
          <Button className={styles.applyButton} onClick={onApplyOptions}>
            Apply
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]} onOpenChange={onVisibleChange}>
      {getFilterDropdownButton()}
    </Dropdown>
  );
};

export default FilterDropdown;
