import JSPM from "jsprintmanager";

const INIT_TOP = 60;
const LINE_HEIGHT = 30;
let IS_PRINTER_MANAGER_STARTED = false;

export const printLabel = async (zpl, printerName = process.env.PRINTER_NAME || "zpl") => {
  // eslint-disable-next-line
  if (window && window.Cypress) {
    // skip printing in Cypress e2e test
    return;
  }

  if (!IS_PRINTER_MANAGER_STARTED) {
    await JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.auto_reconnect = true;
    IS_PRINTER_MANAGER_STARTED = true;
  }

  const printers = await JSPM.JSPrintManager.getPrinters();
  if (!printers.includes(printerName)) return Error(`Printer named "${printerName}" not found`);

  const printer = new JSPM.ClientPrintJob();
  printer.clientPrinter = new JSPM.InstalledPrinter(printerName);
  printer.printerCommands = zpl;
  printer.sendToClient();
};

const createNode = (nodeInfo, top) => {
  const { slotName, nodeTypeCodeIdentifier, currentMappedNode } = nodeInfo;
  if (currentMappedNode) {
    return `^FO20,${top}^FD${slotName} - ${nodeTypeCodeIdentifier} - ${currentMappedNode.mappedNode.nodeMacId}^FS`;
  }
  return "";
};

const createRoomDetails = (roomInfo, offset) => {
  const slots = roomInfo.slots.filter(({ currentMappedNode }) => currentMappedNode);
  const roomZpl = slots.map((nodeInfo, index) => createNode(nodeInfo, offset + LINE_HEIGHT * (index + 1)));
  return roomZpl;
};

const createRoom = (roomInfo, offset) => {
  const header = `^FO20,${offset}^FD${roomInfo.roomName} ^FS`;
  const roomDetails = createRoomDetails(roomInfo, offset);
  return [header, ...roomDetails];
};

const createZpl = (zpl) => ["^XA", "^FX Top section with logo, name and address.", "^CF0,30", ...zpl, "^XZ"];

export const createKeyZpl = (keyInfo) => {
  if (!keyInfo) return "";

  const header = `^FO20,20^FDKey: ${keyInfo.keyName}^FS, '^CFA,20'`;
  let accumulateSlots = 0;
  const rooms = keyInfo.rooms.map((roomInfo, index) => {
    const offset = INIT_TOP + (accumulateSlots + index + 1) * LINE_HEIGHT;
    const room = createRoom(roomInfo, offset);
    accumulateSlots += roomInfo.slots.filter(({ currentMappedNode }) => currentMappedNode).length;
    return room;
  });
  return createZpl([header, ...rooms]).join("");
};

export const createRoomZpl = (roomInfo) => {
  if (!roomInfo) return "";

  const header = `^FO20,20^FD${roomInfo.roomName} ^FS, '^CFA,20'`;
  const roomDetails = createRoomDetails(roomInfo, LINE_HEIGHT);
  return createZpl([header, ...roomDetails]).join("");
};
