import { Col, Collapse, Empty, Row } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import GetHelpButton from "components/GetHelpButton";
import { InstallationModeButton } from "components/InstallationMode";
import StopWatch from "components/InstallationMode/StopWatch";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { useKeyQuery, useRoomNodeStatusSubscription } from "pacts/app-webcore/hasura-webcore.graphql";
import { Key } from "pages/Key/KeyDetail/KeyDetail.d";
import React, { useContext, useMemo, useState } from "react";
import { PositionFunction } from "types/InstallationMode.d";
import { getKeyDetailLink } from "utils/link";
// eslint-disable-next-line import/no-cycle
import { ContractorNodeListProps, NodeHealth } from "./ContractorNodeList.d";
import ContractorNodeListTable from "./ContractorNodeListTable";

const ContractorNodeList = (props: ContractorNodeListProps) => {
  const { keyId, active, keyDetail: keyDetailProps, showKeyButton: showKeyButtonProps } = props;

  const { currentPositionId, currentKeyEntryOfInstaller } = useContext(InstallationModeContext);

  const [keyDetail, setKeyDetail] = useState<Key>(keyDetailProps as unknown as Key);
  const [nodeHealthList, setNodeHealthList] = useState<NodeHealth[]>([]);
  const [showKeyButton, setShowKeyButton] = useState(showKeyButtonProps);
  const { locationName, locationId } = useSensorflowLocation();

  const { refetch: reloadKey } = useKeyQuery({
    skip: !keyId && !active,
    variables: { keyId },
    onCompleted: ({ key }) => setKeyDetail(key),
    onError: () => setShowKeyButton(false),
  });

  useRoomNodeStatusSubscription({
    skip: !keyId && !active,
    variables: {
      keyId,
      filterSlotMappings: {
        decomissionedTime: {
          _is_null: true,
        },
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const { data: { positions = [] } = {} } = subscriptionData;
      if (!positions.length) return;

      const mapNodeHealthList: NodeHealth[] = [];

      positions.forEach((position) => {
        const formatSlot = position.slotMappings.map((x) => ({
          slotId: x.slot?.id,
          nodeStatus: x.node.nodeOnlineStatus?.nodeStatus || "",
          nodeMacId: x.node.node_mac_id,
          nodeStatusInInstallationMode: x.node.nodeOnlineStatus?.nodeStatusInInstallationMode || "",
        }));
        mapNodeHealthList.push(...formatSlot);
      });

      setNodeHealthList(mapNodeHealthList);
    },
  });

  const reloadNodes = async () => {
    const { data: keyData } = await reloadKey({
      keyId,
    });
    setKeyDetail(keyData.key);
  };

  const nodeListHelpText = useMemo(() => {
    if (locationId && locationName && keyDetail) {
      return `${locationName}: ${keyDetail.keyName} ${getKeyDetailLink(locationId, keyDetail.keyId)}`;
    }
    return undefined;
  }, [keyDetail, locationName, locationId]);

  if (!keyDetail) return null;

  return (
    <>
      <Row className="mb-l" align="middle" gutter={[4, 20]}>
        <Col flex="auto">
          <Row justify="start" align="middle" wrap={false}>
            <Col flex="none">
              <h1>{keyDetail.keyName}</h1>
            </Col>
            <Col flex="none">
              {currentPositionId === keyId && currentKeyEntryOfInstaller && (
                <div>
                  <StopWatch startTime={currentKeyEntryOfInstaller.startedAt} />
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col flex="none">
          <Row>
            <Col flex="none" className="mr-s">
              {showKeyButton && (
                <>
                  <InstallationModeButton
                    keyPositionId={keyId}
                    currentPositionFunction={PositionFunction.AUTOSET}
                    buttonName={{ enter: "Start work", exit: "End Work" }}
                    activeModal
                    className="mb-l"
                    automaticTestRun
                  />
                </>
              )}
            </Col>
            <Col flex="none">
              <GetHelpButton helpText={nodeListHelpText} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse
            defaultActiveKey={keyDetail.rooms.map((room) => {
              return room.roomId;
            })}
          >
            {keyDetail.rooms.map((room) => (
              <CollapsePanel header={room.roomName} key={room.roomId} className="no-padding-collapsible-tab">
                {room.slots.length > 0 ? (
                  <ContractorNodeListTable
                    key={room.roomId}
                    roomId={room.roomId}
                    roomName={room.roomName}
                    keyName={keyDetail.keyName}
                    nodes={room.slots}
                    nodeHealthList={nodeHealthList}
                    reloadNodes={reloadNodes}
                  />
                ) : (
                  <Empty description="No Node Mapped" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </CollapsePanel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default ContractorNodeList;
