import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, DrawerProps, Form, Row, Select, Space, TimePicker, Typography } from "antd";
import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import React, { useEffect } from "react";
import { DATE_FORMAT } from "utils/date";
import { DaysOfTheWeek } from "./DaysOfTheWeek";

const { RangePicker } = DatePicker;

export enum ScheduleType {
  EVERY_DAY = "EVERY_DAY",
  DAYS_OF_THE_WEEK = "DAYS_OF_THE_WEEK",
  DATE_RANGE = "DATE_RANGE",
}

export interface FormValues {
  id?: number | null;
  scheduleType: ScheduleType;
  weekDay?: string[];
  time: any | null;
  dateRange?: Moment[];
}

export interface PrecoolScheduleModalProps {
  [x: string]: any;
  open?: boolean;
  checkInDate?: string;
  checkOutDate?: string;
  values?: FormValues;
  onClose?: DrawerProps["onClose"];
  onSubmit?: (value: FormValues) => Promise<void> | void;
}

const TIME_FORM = "hh:mm A";
const DATE_RESERVATION_FORMAT = "ddd, Do MMM YYYY";

export const PrecoolScheduleModal: React.FC<PrecoolScheduleModalProps> = ({
  checkInDate,
  checkOutDate,
  open,
  values,
  onSubmit,
  onClose,
}) => {
  const [form] = Form.useForm();
  const scheduleTypeValue = Form.useWatch(["scheduleType"], form);
  const weekDay = Form.useWatch(["weekDay"], form);

  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && (current < moment(checkInDate).startOf("day") || current > moment(checkOutDate).endOf("day"));
  };

  return (
    <Drawer
      destroyOnClose
      title={
        <Typography.Title level={3} className="mb-none">
          Set schedule
        </Typography.Title>
      }
      open={open}
      closable={false}
      onClose={onClose}
      placement="bottom"
      className="precool-schedule-modal"
      extra={
        <Space>
          <Button type="text" onClick={onClose}>
            <CloseOutlined />
          </Button>
        </Space>
      }
    >
      <Form form={form} initialValues={values} onFinish={onSubmit}>
        <Row>
          <Form.Item name="id" />

          <Col span={24} className="reservation">
            <Typography.Text>Reservation</Typography.Text>
            {checkInDate && checkOutDate && (
              <Typography.Text strong>
                {moment(checkInDate).format(DATE_RESERVATION_FORMAT)} -{" "}
                {moment(checkOutDate).format(DATE_RESERVATION_FORMAT)}
              </Typography.Text>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              name="time"
              label={<span className="required">What time do you want to precool your room?</span>}
              rules={[{ required: true, message: "Please select time!" }]}
            >
              <TimePicker className="w-100" showSecond={false} format={TIME_FORM} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Schedule type" name="scheduleType">
              <Select placeholder="Select schedule type" className="schedule-type-field">
                <Select.Option value={ScheduleType.EVERY_DAY}>Every Day during my stay</Select.Option>
                <Select.Option value={ScheduleType.DAYS_OF_THE_WEEK}>Days of the week</Select.Option>
                <Select.Option value={ScheduleType.DATE_RANGE}>Date Range</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {scheduleTypeValue === ScheduleType.DAYS_OF_THE_WEEK && (
            <Col span={24}>
              <Form.Item label="Days" name="weekDay">
                <DaysOfTheWeek
                  selected={weekDay || []}
                  onSelected={(day, newDoW) => form.setFieldValue("weekDay", newDoW)}
                />
              </Form.Item>
            </Col>
          )}
          {scheduleTypeValue === ScheduleType.DATE_RANGE && (
            <Col span={24}>
              <Form.Item
                label={<span className="required">Select date range</span>}
                name="dateRange"
                rules={[
                  {
                    type: "array" as const,
                    required: scheduleTypeValue === ScheduleType.DATE_RANGE,
                    message: "Please select date range!",
                  },
                ]}
              >
                <RangePicker className="w-100" format={DATE_FORMAT} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
          )}
        </Row>
        <div className="precool-schedule-modal-action">
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
