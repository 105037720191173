import { Form, Input, Modal } from "antd";
import React from "react";
import "../CreateNodeLifeCycleEvent/CreateNodeLifeCycleEvent.scss";
import { displayNodeMacId } from "pages/Node/ContractorNodeList/ContractorNodeComponent";
import { ChangeBatteryEventProps } from "../CreateNodeLifeCycleEvent/CreateNodeLifeCycleEvent.d";

const ChangeBatteryModal = (props: ChangeBatteryEventProps) => {
  const { formData, closeModal, handleOnSubmit } = props;

  const [form] = Form.useForm<ChangeBatteryEventProps["formData"]>();

  return (
    <Modal
      title="Add Node Event Changed Battery"
      centered
      visible
      onCancel={closeModal}
      onOk={form.submit}
      width={600}
      okText="Add Node Event"
    >
      <Form form={form} layout="vertical" initialValues={formData} onFinish={handleOnSubmit}>
        <p>Node Mac Id: {displayNodeMacId(formData.nodeMacId)}</p>
        <Form.Item name="nodeMacId" hidden>
          <Input hidden />
        </Form.Item>

        <Form.Item name="comment" label="Comment">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ChangeBatteryModal;
