import React, { useState } from "react";

import { Input, Row, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import { every, get, isEqual } from "lodash";

import "./CreateKeyCategoryModal.scss";

import {
  useCreateKeyCategoryMutation,
  Sensorflow_Key_Categories_Insert_Input,
} from "pacts/app-webcore/hasura-webcore.graphql";

interface CreateKeyCategoryModalProps {
  closeModal: () => void;
  visible: boolean;
  locationName: string;
  refetchCategories: () => void;
}

const CreateKeyCategoryModal = ({
  visible,
  closeModal,
  locationName,
  refetchCategories,
}: CreateKeyCategoryModalProps) => {
  const initState = {
    categoryName: "",
  };
  const [categoryName, setCategoryName] = useState<string>(initState.categoryName);

  const [createKeyCategoryMutation] = useCreateKeyCategoryMutation({
    onCompleted: () => {
      closeModal();
      message.success("Created a new category");
      refetchCategories();
    },
    onError: (error) => {
      message.error(`Create a new category failed: ${error}`);
    },
  });

  const handleClose = () => {
    setCategoryName(initState.categoryName);
    closeModal();
  };

  const validRequest = every({ categoryName }, (value: string, key: string) => !isEqual(value, get(initState, key)));

  const handleSubmit = () => {
    if (validRequest) {
      const categoryInput: Sensorflow_Key_Categories_Insert_Input = {
        categoryName,
        locationName,
      };

      createKeyCategoryMutation({
        variables: {
          input: categoryInput,
        },
      });
    }
  };

  return (
    <Modal
      title="Add Category"
      okText="Add Category"
      className="create-category-modal"
      visible={visible}
      onCancel={handleClose}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !validRequest,
      }}
      destroyOnClose
    >
      <Row className="input-row">
        <span>Category Name</span>
        <Input
          placeholder="Enter Category Name"
          onChange={(e: any) => setCategoryName(e.target.value)}
          value={categoryName}
        />
      </Row>
    </Modal>
  );
};

export default CreateKeyCategoryModal;
