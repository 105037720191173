const keyCategories = [
  {
    id: "5da2d0b9-f560-4791-9ee4-08fbe04d3e45",
    locationName: "Pigeon Hotel",
    categoryName: "Standard",
  },
  {
    id: "9a6165df-2287-4868-818b-9b9742c7cf6d",
    locationName: "Pigeon Hotel",
    categoryName: "CU3A",
  },
  {
    id: "b0137058-2aa9-421d-a416-f50a3a5f7fce",
    locationName: "Pigeon Hotel",
    categoryName: "Cu3b",
  },
  {
    id: "8ad2bef4-9041-44e2-97b6-1904117b1fee",
    locationName: "Pigeon Hotel",
    categoryName: "Singa 05-02",
  },
  {
    id: "cd803078-e73a-4306-bb2f-c98c9be9fa67",
    locationName: "Pigeon Hotel",
    categoryName: "Sunbird 05-01",
  },
  {
    id: "ec0fe60a-a056-4210-a76a-61af8997e18e",
    locationName: "Pigeon Hotel",
    categoryName: "Tembusu 04-02",
  },
  {
    id: "40084bfb-0d12-4d56-9deb-f75d45f7e851",
    locationName: "Pigeon Hotel",
    categoryName: "Yellow Flame 04-01",
  },
  {
    id: "32fda9d7-7a18-4a99-a5cf-b55cb0897cef",
    locationName: "Turkey Hotel",
    categoryName: "1 Bedroom Standard",
  },
  {
    id: "8cf16f5c-0888-468b-a0b2-cd3412f7295b",
    locationName: "Turkey Hotel",
    categoryName: "2 Bedroom 2105",
  },
  {
    id: "1117a9f9-ac98-4890-90e6-ee51eb19387e",
    locationName: "Turkey Hotel",
    categoryName: "Office Building 3",
  },
  {
    id: "b7fd8ec0-2e04-4d13-9f7b-3892ac7d613e",
    locationName: "Turkey Hotel",
    categoryName: "Offices Building 1",
  },
  {
    id: "98065375-3811-41df-89a3-7197253a1258",
    locationName: "Straits Residence Tiong Bahru",
    categoryName: "Sales Office Building 1",
  },
  {
    id: "8fe8cafb-bf59-46da-ba7b-02649bb7a8b8",
    locationName: "Straits Residence Tiong Bahru",
    categoryName: "Spa Building 2",
  },
  {
    id: "f2b1b2b0-edf1-40c8-8f37-3ec0a272ffd8",
    locationName: "Straits Residence Tiong Bahru",
    categoryName: "Studio 6102",
  },
  {
    id: "b4ec71c3-123c-40c4-8264-d04968ce8157",
    locationName: "Straits Residence Tiong Bahru",
    categoryName: "Studio Standard",
  },
  {
    id: "b1b6b527-e54b-4ead-a490-19dc525d3e06",
    locationName: "Bonobo Hotel",
    categoryName: "Daikin Rooms",
  },
  {
    id: "2de9ba0f-e239-4f63-a777-3bffcdee46bc",
    locationName: "Bonobo Hotel",
    categoryName: "Deluxe",
  },
  {
    id: "1013238e-22f6-4237-8be6-7cab069f5165",
    locationName: "Bonobo Hotel",
    categoryName: "Pool Villa",
  },
  {
    id: "9a53cd01-6a41-4aec-8e58-0b82b9e6c29d",
    locationName: "Bonobo Hotel",
    categoryName: "Superior",
  },
  {
    id: "09e12838-b7e4-4974-bef5-b63aeb5eebc0",
    locationName: "Bonobo Hotel",
    categoryName: "Terrace Tree Villa",
  },
  {
    id: "1b46faa3-60ab-4213-9b65-211066333f33",
    locationName: "Bonobo Hotel",
    categoryName: "Valley Villa",
  },
  {
    id: "fcf2362f-7be5-4954-a763-042e3d6f7672",
    locationName: "Bonobo Hotel",
    categoryName: "Villa 510",
  },
  {
    id: "80847260-ef20-4240-88c4-c40cd7ffd36a",
    locationName: "Bonobo Hotel",
    categoryName: "1 Bed Villa + 2PFC",
  },
  {
    id: "f3d0ef2c-4b1b-4ae1-835b-0911e3be9b58",
    locationName: "Bonobo Hotel",
    categoryName: "1 Bed Villa + DAIKIN",
  },
  {
    id: "2d16b53f-1ae9-43a1-bd98-ed0fbd45fbb6",
    locationName: "Bonobo Hotel",
    categoryName: "3 Bed Hillside Villa H15",
  },
  {
    id: "7effd088-122c-4222-ae71-c5e758ab926e",
    locationName: "Bonobo Hotel",
    categoryName: "3 Bed Villa Cliff Edge",
  },
  {
    id: "00259e91-473a-4ed9-9fa7-25db956ecb2b",
    locationName: "Bonobo Hotel",
    categoryName: "3 Bed Villa Hillside",
  },
  {
    id: "14f973b0-129d-4f51-abc0-db9ab2862ea5",
    locationName: "Bonobo Hotel",
    categoryName: "4 Bed Villa Hillside",
  },
  {
    id: "7e5e637a-225f-450b-9a23-637145473238",
    locationName: "Bonobo Hotel",
    categoryName: "H24 Villa",
  },
  {
    id: "bdebcd1a-a740-4950-8b5a-3c861e09bd6a",
    locationName: "Lemming Hotel",
    categoryName: "One Bed Villa ",
  },
  {
    id: "77229275-2546-4323-8ad6-2ed0f7bc36f3",
    locationName: "Lemming Hotel",
    categoryName: "Three Bed Villa",
  },
  {
    id: "bd78b4f8-ca86-41bb-a19c-70ab92f1fc77",
    locationName: "Lemming Hotel",
    categoryName: "One Bedroom",
  },
  {
    id: "a1b9904c-5095-4921-980b-82724a7440d0",
    locationName: "Lemming Hotel",
    categoryName: "Three Bedroom",
  },
  {
    id: "cb6c98f7-4acb-46e6-8abb-ad9dec762eb0",
    locationName: "Lemming Hotel",
    categoryName: "Three Bedroom Office",
  },
  {
    id: "a8579551-54ad-4193-9147-a6c9c9c062ab",
    locationName: "Lemming Hotel",
    categoryName: "Two Bedroom",
  },
  {
    id: "8a88f4c5-a7cd-4675-a05f-ba57bc5ce98e",
    locationName: "Lemming Hotel",
    categoryName: "5 aircon bedroom",
  },
  {
    id: "4a3d93a4-92ef-4992-b777-a7a47ab98940",
    locationName: "Boar Hotel",
    categoryName: "B1",
  },
  {
    id: "5aef63f2-9c62-4bd1-b073-e5d106bef6fa",
    locationName: "Boar Hotel",
    categoryName: "B2",
  },
  {
    id: "51e0fe36-70f4-4dcd-af4a-69d79f85ee17",
    locationName: "Boar Hotel",
    categoryName: "B3",
  },
  {
    id: "a046ee3d-1e8a-42ec-928e-ae6d128a8e07",
    locationName: "Boar Hotel",
    categoryName: "B4",
  },
  {
    id: "6dbb8a4d-6617-454f-bf0d-169906c22e1b",
    locationName: "Boar Hotel",
    categoryName: "Energy meter",
  },
  {
    id: "e559e507-758c-437a-b8cd-e83655a72b39",
    locationName: "Boar Hotel",
    categoryName: "Gym",
  },
  {
    id: "78dc2969-c013-465e-b4ae-33e3adac327d",
    locationName: "Boar Hotel",
    categoryName: "Restaurant",
  },
  {
    id: "a62089fc-b7b3-48b7-9aa8-e53fa537ad18",
    locationName: "Boar Hotel",
    categoryName: "S1",
  },
  {
    id: "f746af82-e07a-4322-8829-5ec0d6c26ddb",
    locationName: "Boar Hotel",
    categoryName: "S4",
  },
  {
    id: "f95dfa16-6680-446b-906b-368bbeb58048",
    locationName: "Wildfowl Hotel",
    categoryName: "1 bed room",
  },
  {
    id: "771775db-4e11-410a-af75-72f9c94317eb",
    locationName: "Wildfowl Hotel",
    categoryName: "Meeting Room",
  },
  {
    id: "90b63eca-e159-47e0-80c1-65114016407b",
    locationName: "Wildfowl Hotel",
    categoryName: "Standard",
  },
  {
    id: "3718ce02-403b-479e-a570-ef28c96527e2",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Honeymoon 2PFC",
  },
  {
    id: "7771361e-8012-4405-a768-cdb384eec495",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Honeymoon ACIR",
  },
  {
    id: "e990ded7-fa76-4e72-972c-10a4470fdcc0",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Jacuzzi Deluxe 2PFC",
  },
  {
    id: "57e831a8-9ced-46c7-85c4-880bd03e5cd0",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Jacuzzi Deluxe ACIR",
  },
  {
    id: "4065f53d-c546-4062-aabe-c7ea1d35d8a6",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Jacuzzi Suite 2PFC",
  },
  {
    id: "06c28306-bfb9-4b33-a961-9cb71bb07ae9",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Pool Penthouse 2PFC",
  },
  {
    id: "0a69838e-7ca6-4b2a-ab95-bdcdf114440a",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Studio 2PFC",
  },
  {
    id: "ce6d879e-770d-4155-ad8d-74f21c119a82",
    locationName: "Wildfowl Hotel",
    categoryName: "Sea View Studio ACIR",
  },
  {
    id: "04d9bc4d-eb4f-4a43-9659-c1b42f43533e",
    locationName: "Bat Hotel",
    categoryName: "1 bedroom",
  },
  {
    id: "9ee51a00-f313-4461-b9cf-ae9504f93f4b",
    locationName: "Bat Hotel",
    categoryName: "2 bed room",
  },
  {
    id: "792f45b0-93ca-4e95-9b24-ad0acb8e3c83",
    locationName: "Bat Hotel",
    categoryName: "Studio",
  },
  {
    id: "2fdff572-2b60-495d-8031-acd7651c34fb",
    locationName: "Bat Hotel",
    categoryName: "Water heater",
  },
  {
    id: "a4279b31-7be7-4bec-b601-6217f30662a5",
    locationName: "Chipmunk Hotel",
    categoryName: "Deluxe",
  },
  {
    id: "fcb6988c-8149-48c8-aa89-c57873d07462",
    locationName: "Chipmunk Hotel",
    categoryName: "Pool Villa",
  },
  {
    id: "d6b201bf-21e5-47df-855b-d914c6aa95d9",
    locationName: "Chipmunk Hotel",
    categoryName: "Residence 1 bedroom no door",
  },
  {
    id: "cfef737d-ce2a-4705-ae20-0bac5b0d09e1",
    locationName: "Chipmunk Hotel",
    categoryName: "Residence 2 bedroom",
  },
  {
    id: "71b04f5a-5c3d-42e0-8c72-8c1b8e9735f5",
    locationName: "Chipmunk Hotel",
    categoryName: "Residence 2 bedroom 3 door",
  },
  {
    id: "7dca4101-e6bd-4f07-aa97-8b11ec4d9627",
    locationName: "Chipmunk Hotel",
    categoryName: "Residence 2 bedroom no door",
  },
  {
    id: "3d76cce2-6e21-4efb-9bc2-95590f0f2f08",
    locationName: "Chipmunk Hotel",
    categoryName: "Resident 2 bedrm living 1 door bedrm 1 door",
  },
  {
    id: "c7b6e94e-e912-439d-9124-4110896f69e7",
    locationName: "Monkey Hotel",
    categoryName: "Double Unit 05/07",
  },
  {
    id: "396e8a78-fa50-4c56-a190-f3137d554626",
    locationName: "Monkey Hotel",
    categoryName: "Double Unit A",
  },
  {
    id: "cbd21a2a-9f02-4271-93c1-e3558d124b8b",
    locationName: "Monkey Hotel",
    categoryName: "Double Unit B",
  },
  {
    id: "7e851c26-3b4d-4fdd-86f9-70d2cf552a61",
    locationName: "Monkey Hotel",
    categoryName: "Single Unit",
  },
  {
    id: "2e32da76-397f-4348-86db-38cecbfa9cd6",
    locationName: "Monkey Hotel",
    categoryName: "Type D",
  },
  {
    id: "22bd2997-77d5-4077-b14c-1907118b8e85",
    locationName: "Monkey Hotel",
    categoryName: "Deluxe Room",
  },
  {
    id: "d5eb0ca4-0660-4624-81cc-5aa022a6a00a",
    locationName: "Monkey Hotel",
    categoryName: "Executive Garden Pool Suite",
  },
  {
    id: "fa4c8491-0177-4517-b4da-ee1770412a63",
    locationName: "Monkey Hotel",
    categoryName: "Grand Pool Deluxe Suite",
  },
  {
    id: "a6c90449-ab66-4e73-b963-758d47f0c0e2",
    locationName: "Monkey Hotel",
    categoryName: "Gym",
  },
  {
    id: "a40214e4-6d58-417c-ad02-4cc8224abab3",
    locationName: "Monkey Hotel",
    categoryName: "Pool/Sky Suite",
  },
  {
    id: "af1064a0-450d-4412-8fa9-5704c2912061",
    locationName: "Chimpanzee Hotel",
    categoryName: "Deluxe Room",
  },
  {
    id: "e537ffea-cd15-4211-95cb-d0607fb275ca",
    locationName: "Chimpanzee Hotel",
    categoryName: "Deluxe Room Ambient",
  },
  {
    id: "567fcb69-8ff9-4faa-9047-88d6a22adf3d",
    locationName: "Chimpanzee Hotel",
    categoryName: "Villa",
  },
  {
    id: "eaede641-0df6-4869-ad8d-c8eeaf9cf4ca",
    locationName: "Chimpanzee Hotel",
    categoryName: "Daikin Standard",
  },
  {
    id: "5ddc474d-b72a-4e18-bce3-dad2ab5bd295",
    locationName: "Chimpanzee Hotel",
    categoryName: "Daikin Studio",
  },
  {
    id: "e83dfd78-6b92-40a6-a2a2-2254834dbbfa",
    locationName: "Chimpanzee Hotel",
    categoryName: "Master",
  },
  {
    id: "77582be2-6a6a-4672-b143-bc277192f5fa",
    locationName: "Chimpanzee Hotel",
    categoryName: "Standard",
  },
  {
    id: "2981b805-72d3-4913-ac4e-7c44a7c5fd3a",
    locationName: "Chimpanzee Hotel",
    categoryName: "Standard + Living",
  },
  {
    id: "4ed752d9-7c34-4b6c-885d-ef3fce5750ea",
    locationName: "Chimpanzee Hotel",
    categoryName: "Studio",
  },
  {
    id: "967ea4cf-e23d-4ea3-8b67-8595b06e1e9d",
    locationName: "Possum Hotel",
    categoryName: "2 Bedroom Pool Villa NEW",
  },
  {
    id: "cbd4eee2-49ae-4881-8a75-38ac1ac4e85f",
    locationName: "Possum Hotel",
    categoryName: "2 Bedroom Pool Villa new r1",
  },
  {
    id: "f1354ce2-1a8d-4ab2-9dfa-25a1d8e75efa",
    locationName: "Possum Hotel",
    categoryName: "Chic Suite",
  },
  {
    id: "b9f7128d-2315-4280-b59a-a0a1d4289e3c",
    locationName: "Possum Hotel",
    categoryName: "Family Suite NEW",
  },
  {
    id: "d3ad78f2-2f80-4e8b-a0dd-1198cf580ba1",
    locationName: "Possum Hotel",
    categoryName: "Grand villa r1",
  },
  {
    id: "aa26ea8b-ce23-48a0-a22a-5ea2667acdbc",
    locationName: "Possum Hotel",
    categoryName: "Ocean Suite",
  },
  {
    id: "35e47707-7b9f-4915-9dd9-4d016d649f3c",
    locationName: "Possum Hotel",
    categoryName: "Pool Villa",
  },
];

export default keyCategories;
