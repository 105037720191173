import { Button, Col, Row } from "antd";
import LocationSwitcher from "components/LocationSwitcher";
import SearchBar from "components/SearchBar/SearchBar";
import useBreakpoint from "hooks/use-breakpoint";
import React from "react";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import BookingListAdvancedFilter from "./BookingListAdvancedFilter";
import { IBookingListQueryFilter, PmsRoomCategory } from "./types";
import BookingFilterValues from "./BookingFilterValues";
import { hasFilter } from "./helpers";

interface IBookingListHeaderAndFilterProps {
  filter?: IBookingListQueryFilter;
  roomCategories?: PmsRoomCategory[];
  onFilterSet: (filter: IBookingListQueryFilter | undefined) => void;
  onLocationIdChosen: (locationId: string) => void;
}

const BookingListHeader = ({
  roomCategories,
  filter,
  onFilterSet,
  onLocationIdChosen,
}: IBookingListHeaderAndFilterProps) => {
  const [isAdvancedFilterVisible, setAdvancedFilterVisibility] = React.useState<boolean>(false);
  const screen = useBreakpoint();

  return (
    <>
      <Row justify="space-between" align="middle" className="mb-m" gutter={[8, 8]}>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <Row justify={screen.desktopOny ? "start" : "space-between"} align="middle">
            <Col>
              <h1 className="mb-none mr-l">BOOKING</h1>
            </Col>
            <Col>
              <LocationSwitcher onChange={onLocationIdChosen} />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          <Row
            justify="end"
            gutter={[10, 10]}
            align={screen.desktopOny ? "middle" : "bottom"}
            wrap={false}
            className={screen.desktopOny ? "flex-row" : "flex-column-reverse"}
          >
            <Col flex="auto">
              <BookingFilterValues onFilterSet={onFilterSet} filter={filter} roomCategories={roomCategories} />
            </Col>
            <Col flex={screen.mobileOnly ? "auto" : "320px"}>
              <Row justify="end">
                <Col flex="46px">
                  <Button
                    type="default"
                    data-testId="booking-advanced-filter"
                    className="text-primary"
                    onClick={() => setAdvancedFilterVisibility(true)}
                  >
                    {hasFilter(filter) ? <FilterFilled /> : <FilterOutlined />}
                  </Button>
                </Col>
                <Col flex={screen.mobileOnly ? "180px" : "250px"} className="ml-s">
                  <SearchBar
                    placeholder="Search via booking ID"
                    onInputChange={(value) => onFilterSet({ ...filter, idSearchText: value })}
                    allowClear
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <BookingListAdvancedFilter
        isOpen={isAdvancedFilterVisible}
        onClose={() => setAdvancedFilterVisibility(false)}
        filter={filter}
        onFilterSet={onFilterSet}
        roomCategories={roomCategories}
      />
    </>
  );
};

export default BookingListHeader;
