import React, { useState } from "react";
import { Button, Row, Alert, message } from "antd";
import {
  UserQuery,
  useUserResetPasswordMutation,
  useUserResendWelcomeEmailMutation,
  useUserDisableMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { ExclamationCircleFilled } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { useHistory } from "react-router-dom";

type Props = {
  user: UserQuery["user"];
};

type OperationStatus = "DISABLE_SUCCESS" | "RESET_PASSWORD_SUCCESS" | "RESEND_WELCOME_EMAIL_SUCCESS" | null;

const UserAdditionalActions = ({ user }: Props) => {
  const [operationStatus, setOperationStatus] = useState<OperationStatus>(null);
  const history = useHistory();

  // mutation for delete (not in contract yet)
  const [userDisable, { loading: userDisableLoading, error: userDisableError }] = useUserDisableMutation({
    variables: { userId: user.userId },
    onCompleted() {
      message.success("User has been deleted");
      history.push("/users");
    },
    // TODO 2020-08-21: remove dummy onError callback once apollo doesn't throw error for mutations in Jest
    onError() {},
  });

  // mutation for reset password
  const [userResetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] =
    useUserResetPasswordMutation({
      variables: { email: user.email },
      onCompleted() {
        setOperationStatus("RESET_PASSWORD_SUCCESS");
      },
      // TODO 2020-08-21: remove dummy onError callback once apollo doesn't throw error for mutations in Jest
      onError() {},
    });

  // mutation for account creation
  const [userResendWelcomeEmail, { loading: resendWelcomeEmailLoading, error: resendWelcomeEmailError }] =
    useUserResendWelcomeEmailMutation({
      variables: { userId: user.userId },
      onCompleted() {
        setOperationStatus("RESEND_WELCOME_EMAIL_SUCCESS");
      },
      // TODO 2020-08-21: remove dummy onError callback once apollo doesn't throw error for mutations in Jest
      onError() {},
    });

  // click handlers
  const handleDelete = () => {
    confirm({
      icon: <ExclamationCircleFilled className="text-warning" />,
      title: "Are you sure you want to delete this user?",
      okText: "Confirm",
      okButtonProps: { className: "btn-popup-primary" },
      onOk() {
        userDisable();
      },
    });
  };

  const handleResetPassword = () => {
    userResetPassword();
  };

  const handleResendAccountCreation = () => {
    userResendWelcomeEmail();
  };

  return (
    <>
      <Row>
        {/* success messages */}
        {operationStatus === "RESET_PASSWORD_SUCCESS" && (
          <Alert type="success" message="Password reset email sent to user" style={{ marginBottom: "24px" }} />
        )}
        {operationStatus === "RESEND_WELCOME_EMAIL_SUCCESS" && (
          <Alert
            type="success"
            message="Account creation email has been resent to user"
            style={{ marginBottom: "24px" }}
          />
        )}
        {/* error messages */}
        {resetPasswordError && (
          <Alert type="error" message="Error sending password reset email to user" style={{ marginBottom: "24px" }} />
        )}
        {resendWelcomeEmailError && (
          <Alert type="error" message="Error sending account creation email to user" style={{ marginBottom: "24px" }} />
        )}
        {userDisableError && <Alert type="error" message="Error deleting user" style={{ marginBottom: "24px" }} />}
      </Row>
      <Row style={{ marginBottom: "16px", marginLeft: "-15px" }} align="middle">
        {user.status !== "DISABLED" && (
          <Button
            type="link"
            danger
            onClick={handleDelete}
            loading={userDisableLoading}
            disabled={operationStatus === "DISABLE_SUCCESS"}
          >
            Delete
          </Button>
        )}
        <span
          style={{
            display: "inline-block",
            backgroundColor: "#999",
            width: "1px",
            height: "20px",
          }}
        />
        {user.emailVerified === "VERIFIED" ? (
          <Button
            type="link"
            onClick={handleResetPassword}
            loading={resetPasswordLoading}
            disabled={operationStatus === "DISABLE_SUCCESS"}
          >
            Reset password
          </Button>
        ) : (
          <Button
            type="link"
            onClick={handleResendAccountCreation}
            loading={resendWelcomeEmailLoading}
            disabled={operationStatus === "DISABLE_SUCCESS"}
          >
            Resend account creation
          </Button>
        )}
      </Row>
    </>
  );
};

export default UserAdditionalActions;
