import { AutoComplete, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { currencyList } from "utils/CurrencyUtils";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { useGetCurrencyListQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";

interface ICurrencySelectorProps {
  onChange?: (value: string) => void;
  value?: string;
}

const initOptions = currencyList.map(({ label, key }) => {
  return {
    label,
    value: key,
  };
});

const CurrencySelector = ({ onChange, value: currentValue }: ICurrencySelectorProps) => {
  const [value, setValue] = useState(currentValue || "");
  const [options, setOptions] = useState<{ value: string; label: string }[]>(initOptions);

  useEffect(() => {
    if (currentValue) {
      setValue(currentValue);
    }
  }, [currentValue]);

  const { loading } = useGetCurrencyListQuery({
    onCompleted(data) {
      if (data && data.currencies) {
        setOptions(
          data.currencies.map((currency) => ({
            value: currency.shortCode,
            label: `${currency.name} (${currency.shortCode})`,
          }))
        );
      }
    },
    onError: errorHandler.handleError,
  });

  const handleSearch = useCallback((searchInput: string) => {
    const searchValue = searchInput.toUpperCase();
    const filtered = filter(initOptions, ({ value: name }) => name.toUpperCase().includes(searchValue));
    setOptions(filtered);
  }, []);

  const onBlur = useCallback(() => {
    if (isEmpty(value)) {
      onChange?.("");
      return;
    }
    if (!some(options, ({ value: name }) => name === value)) {
      onChange?.("");
    }
    onChange?.(value);
  }, [value, options, onChange]);

  return (
    <AutoComplete
      className="w-100"
      options={options}
      onSearch={handleSearch}
      onChange={setValue}
      onBlur={onBlur}
      onSelect={onChange}
      value={value}
      disabled={loading}
    >
      <Input.Search size="large" disabled={loading} placeholder="Select Currency" />
    </AutoComplete>
  );
};

export default CurrencySelector;
