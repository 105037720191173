import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Checkbox, Col, List, message, Modal, Row, Tooltip } from "antd";
import {
  FilterOption,
  GetKeysForDropdownQuery,
  KeyListWithPositionConfigurationQuery,
  PositionConfigurationWithThermostatStatus,
  Sensorflow_Positions_Bool_Exp,
  useChangeDisabledAutomationModeStatusMutation,
  useGetIsManagerQuery,
  useGetKeysForDropdownQuery,
  useKeyListWithPositionConfigurationQuery,
  useLocationMetadataQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import ListFilter from "components/ListFilter/ListFilter";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import classnames from "classnames";
import { useAuth0 } from "services/auth/authService";
import { useNewRelicBrowserAgent } from "hooks/use-nr-browser-agent";
import { ENewrelicCustomEvent } from "types/types";
import errorHandler from "errorHandler";
import { AvailableOperationalModes } from "utils/locationMetadata";
import { Key } from "./KeyConfigurationList.d";
import KeyConfigurationListTable from "./KeyConfigurationListTable";
import { ReloadIcon } from "../../../components/Icons";
import { fixedFilterOptions, transformKeys } from "./KeyConfigurationList.helper";
import useModalWrapperTrigger from "../../../hooks/use-modal-wrapper-trigger";
import ModalWrapper from "../../../components/Modal/Wrapper";
import BulkConfigurationEditModal from "./BulkConfigurationEditModal/BulkConfigurationEditModal";
import { KeysFilterInput, PositionWithRooms } from "../types";
import { expandAndCollapseButton, getPositionQueryFilter } from "../key.helper";
import useBreakpoint from "../../../hooks/use-breakpoint";
import usePagination from "../hooks/use-pagination";
import useKeyRoomSelection from "../hooks/use-key-room-selection";
import useLocationLoader from "../hooks/use-location-loader";
import useResetConfigDefault from "../hooks/use-reset-config-default";
import useKeyExpansion from "../hooks/use-key-expansion";

const KeyConfigurationList = () => {
  const newrelic = useNewRelicBrowserAgent();
  const { locationId } = useParams<{ locationId: string }>();
  const { user } = useAuth0();

  const [showHeatingMode, setShowHeatingMode] = useState<boolean>(false);

  const [keys, setKeys] = useState<Key[]>([]);
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);

  const { pagination, setPagination, resetPagination, handlePaginationChange, getPagingMessage } = usePagination();
  const {
    resetSelection,
    hasRoomSelected,
    onSourceBinding,
    selectedPositions,
    selectionStatsMessage,
    onSelectAll,
    getSelectedRooms,
    handlePositionSelected,
    onTotalCountChange,
  } = useKeyRoomSelection();
  const { location, getLocationStatusMessage } = useLocationLoader(locationId);

  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [filter, setFilter] = useState<KeysFilterInput>({ locationId });
  const [locationCategories, setLocationCategories] = useState<any[]>([]);
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);
  const [keysRoomsLoading, setKeysRoomsLoading] = useState<boolean>(false);
  const [allowDisableAutomationMode, setAllowDisableAutomationMode] = useState<boolean>();
  const [automationModeDisabled, setAutomationModeDisabled] = useState<boolean>();
  const [isManager, setIsManager] = useState<boolean>(false);
  const [availableOperationalModes, setAvailableOperationalModes] = useState<AvailableOperationalModes>();
  const [bulkConfigurationEditModalRef, openBulkConfigurationEditModal] = useModalWrapperTrigger();
  const [isSearchByKeyName, setIsSearchByKeyName] = useState<boolean>(true);
  const [isSearchByRoomName, setIsSearchByRoomName] = useState<boolean>(true);
  const [bulkEditErrorMessage, setBulkEditErrorMessage] = useState<React.ReactNode | undefined>();
  const [roomsWithThermostatOff, setRoomsWithThermostatOff] = useState<PositionConfigurationWithThermostatStatus[]>([]);
  const [allKeys, setAllKeys] = useState<GetKeysForDropdownQuery["positions"]>();
  const screen = useBreakpoint();

  const { onExpandAll, handleKeyExpanded, expandedKeys, onSourceBinding: onExpansionSourceBinding } = useKeyExpansion();

  const reloadBinding = (data?: KeyListWithPositionConfigurationQuery) => {
    if (data) {
      setLocationCategories(data.locationOne?.keyCategories ?? []);
      const keysData = transformKeys(data);
      setKeys(keysData);
      onSourceBinding(keysData);
      onExpansionSourceBinding(keysData);

      const totalCount = data?.locationOne?.positionsAggregate?.aggregate?.count || 0;
      setPagination({
        ...pagination,
        total: totalCount,
      });
      onTotalCountChange(totalCount);
    }
  };

  const {
    refetch: refetchKeys,
    loading: keysLoading,
    error: keysError,
  } = useKeyListWithPositionConfigurationQuery({
    variables: {
      keyName: isSearchByKeyName ? filter.positionName || "" : null,
      autosetName: isSearchByRoomName ? filter.positionName || "" : null,
      locationId,
      where: getPositionQueryFilter(locationId, filter, isSearchByKeyName, isSearchByRoomName),
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
    onCompleted: reloadBinding,
  });

  const refetchKeysHandler = async () => {
    setLoadingTable(true);
    const response = await refetchKeys();
    reloadBinding(response.data);
    setLoadingTable(false);
  };

  const { resetConfigToDefault, loading: isResetting } = useResetConfigDefault(() => {
    resetSelection();
    refetchKeysHandler();
  });

  useGetKeysForDropdownQuery({
    variables: {
      locationId,
    },
    onCompleted: ({ positions: data }) => {
      const sorted = data.sort((key1, key2) => {
        if (key1.positionName > key2.positionName) return 1;
        if (key1.positionName < key2.positionName) return -1;
        return 0;
      });
      setAllKeys(sorted);
    },
  });

  useEffect(() => {
    const categoryFilterOptions = locationCategories.map((c) => ({
      field: "categories",
      value: c.categoryName,
      label: c.categoryName,
      count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
      groupName: "Category",
    }));
    setFilterOptions([...categoryFilterOptions, ...fixedFilterOptions]);
  }, [locationCategories]);

  useGetIsManagerQuery({
    variables: {
      userId: user.sub,
    },
    onCompleted: (data) => {
      setIsManager(!!data.sensorflow_users_by_pk?.isManager);
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    setLoadingTable(keysLoading);
  }, [keysLoading]);

  const statusMessage = useMemo(() => {
    if (getLocationStatusMessage()) return getLocationStatusMessage();
    if (location) {
      const { locationName } = location;
      return getPagingMessage(locationName);
    }

    return "";
  }, [location, getLocationStatusMessage, getPagingMessage]);

  const handleFilterChange = (newFilter: KeysFilterInput) => {
    resetPagination();
    setFilter(newFilter);
    resetSelection();
  };

  const fetchSelectedRooms = useCallback(() => {
    let filterByRoomName: Sensorflow_Positions_Bool_Exp = {};
    if (isSearchByRoomName || isSearchByKeyName) {
      filterByRoomName = {
        _or: [
          {
            positionName: {
              _ilike: isSearchByRoomName ? `%${filter.positionName ? filter.positionName : ""}%` : ``,
            },
          },
          {
            parentPosition: {
              positionName: {
                _ilike: isSearchByKeyName ? `%${filter.positionName ? filter.positionName : ""}%` : ``,
              },
            },
          },
        ],
      };
    }

    return getSelectedRooms({
      where: getPositionQueryFilter(locationId, filter, isSearchByKeyName, isSearchByRoomName),
      filterByRoomName,
    });
  }, [isSearchByRoomName, isSearchByKeyName, filter, getSelectedRooms, locationId]);

  const resetConfig = async () => {
    const roomIds = await fetchSelectedRooms();
    resetConfigToDefault(roomIds);
  };

  const openEditModal = async () => {
    setKeysRoomsLoading(true);
    const roomIds = await fetchSelectedRooms();
    setKeysRoomsLoading(false);
    await setSelectedRoomIds(roomIds);
  };

  useEffect(() => {
    // This do the trick, set selectedRoomIds state need be completed
    // before open the modal otherwise ref.current will be null
    if (selectedRoomIds.length > 0) {
      openBulkConfigurationEditModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomIds]);

  useLocationMetadataQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setShowHeatingMode(data.locationMetadata?.showHeatingMode ?? false);
      setAllowDisableAutomationMode(data.locationMetadata?.allowDisableAutomationMode ?? false);
      setAutomationModeDisabled(data.locationMetadata?.isAutomationModeDisabled ?? false);
      setAvailableOperationalModes(
        (data.locationMetadata?.availableOperationalModes as AvailableOperationalModes) ??
          AvailableOperationalModes.Both
      );
    },
  });

  const reasonDisableBulkEditButton = () => {
    if (automationModeDisabled) {
      return "Automation has been disabled, please enable it to edit configurations.";
    }
    if (!hasRoomSelected()) {
      return "Please select at least 1 key to edit configurations.";
    }
  };

  const [changeDisabledAutomationModeStatus, { loading }] = useChangeDisabledAutomationModeStatusMutation({
    onCompleted: (data) => {
      if (data.changeDisabledAutomationModeStatus.isAutomationModeDisabled) {
        message.success("Disable Automation & Configuration successfully.");
      } else {
        message.success("Enable Automation & Configuration successfully.");
      }
      refetchKeysHandler();
    },
  });

  const disableAutomationModeBtn = () => {
    if (!loading) {
      if (automationModeDisabled) return "Enable Automation & Configuration";
      return "Disable Automation & Configuration";
    }
    if (automationModeDisabled) return "Enabling Automation & Configuration";
    return "Disabling Automation Mode & Configuration";
  };

  const onChangeDisabledAutomationModeStatus = async () => {
    const title = automationModeDisabled ? "Enable Automation & Configuration" : "Disable Automation & Configuration";

    const content = automationModeDisabled
      ? "Are you sure you want to enable, this will turn on all automation function and add the setpoint limits?"
      : "Are you sure you want to disable, this will turn off all automation function and remove the setpoint limits?";

    Modal.confirm({
      title,
      content,
      className: "confirm-modal",
      width: 500,
      okText: "OK",
      cancelText: "Cancel",
      onOk: async () => {
        const { data } = await changeDisabledAutomationModeStatus({
          variables: {
            locationId,
            isAutomationModeDisabled: !automationModeDisabled,
          },
        });
        if (data?.changeDisabledAutomationModeStatus.isAutomationModeDisabled) {
          newrelic?.addPageAction(ENewrelicCustomEvent.KILL_SWITCH_TURN_ON, {
            locationId,
            locationName: location?.locationName ?? "",
            userId: user.sub,
            userName: user.name,
            userEmail: user.email,
          });
        }
        setAutomationModeDisabled(data?.changeDisabledAutomationModeStatus.isAutomationModeDisabled ?? false);
      },
    });
  };

  useEffect(() => {
    resetSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchByKeyName, isSearchByRoomName]);

  const setRoomsErrorMessage = () => {
    if (!allKeys) return;
    const messages: React.ReactNode[] = [];
    let countRooms = 0;
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const keyIndex in allKeys) {
      const key = allKeys[keyIndex];
      const roomsInKey = roomsWithThermostatOff.filter((room) => room.parentPositionId === key.positionId);
      if (roomsInKey.length > 0) {
        const listItems: React.ReactNode[] = [];
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const roomIndex in roomsInKey) {
          const room = roomsInKey[roomIndex];
          listItems.push(
            <List.Item key={room.positionId} className="pl-l py-none border-none">
              {room.positionName}
            </List.Item>
          );
          countRooms += 1;
          if (countRooms === 50) {
            break;
          }
        }
        messages.push(
          <List>
            {key.positionName}
            {listItems}
          </List>
        );
        if (countRooms === 50) {
          break;
        }
      }
    }
    setBulkEditErrorMessage(
      <>
        New configurations saved. Please manually alter the operating mode in below rooms as the thermostats are
        currently offline:
        {message}
        {countRooms < roomsWithThermostatOff.length && (
          <span>And {roomsWithThermostatOff.length - countRooms} more rooms hae their thermostats offline.</span>
        )}
      </>
    );
  };

  useEffect(() => {
    if (roomsWithThermostatOff.length > 0) {
      setRoomsErrorMessage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsWithThermostatOff]);

  return (
    <>
      <Row justify="space-between" className="mb-m">
        <Col>
          <h2 className="d-inline font-weight-bold mr-xl">Configuration</h2>
          <p className="d-inline">{statusMessage}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between" className="mb-l">
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <ListFilter
                currentFilter={filter}
                onChange={handleFilterChange}
                searchField="positionName"
                searchPlaceholder="Search key name"
                filterOptions={filterOptions}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div
                className={classnames("d-flex", {
                  "justify-content-end mt-none": screen.desktopUp,
                  "mt-m": screen.mobileAndTabletOnly,
                })}
              >
                {allowDisableAutomationMode && isManager && (
                  <div className="mr-m">
                    <div className="text-center">
                      <Button
                        type="primary"
                        className="mr-xs"
                        data-testid="btn-bulk-config-testId"
                        onClick={onChangeDisabledAutomationModeStatus}
                        loading={keysRoomsLoading}
                        disabled={loading || !!keysError}
                      >
                        {disableAutomationModeBtn()}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="mr-m">
                  <div className="text-center">
                    <Tooltip title={reasonDisableBulkEditButton()}>
                      <Button
                        type="primary"
                        className="mr-xs"
                        data-testid="btn-bulk-config-testId"
                        onClick={openEditModal}
                        disabled={!hasRoomSelected() || automationModeDisabled}
                        loading={keysRoomsLoading}
                      >
                        Bulk Edit Configuration
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div className="text-center">
                    <Button
                      type="primary"
                      shape="circle"
                      className="mr-xs"
                      data-testid="btn-reset-default-testId"
                      disabled={!hasRoomSelected() || isResetting}
                      onClick={resetConfig}
                    >
                      <ReloadIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pb-m">
            <Col span={3}>
              <Checkbox defaultChecked={isSearchByKeyName} onChange={(e) => setIsSearchByKeyName(e.target.checked)}>
                Search by key name
              </Checkbox>
            </Col>
            <Col span={3}>
              <Checkbox defaultChecked={isSearchByRoomName} onChange={(e) => setIsSearchByRoomName(e.target.checked)}>
                Search by room name
              </Checkbox>
            </Col>
          </Row>
          {screen.desktopUp && (
            <div className="d-flex justify-content-between">
              {expandAndCollapseButton(keys as PositionWithRooms[], onExpandAll)}
            </div>
          )}
          {!keysLoading && (
            <div className="py-s">
              <span className="text-primary">{selectionStatsMessage}</span>
            </div>
          )}
          {bulkEditErrorMessage && (
            <Alert
              onClose={() => {
                setBulkEditErrorMessage(undefined);
                setRoomsWithThermostatOff([]);
              }}
              description={bulkEditErrorMessage}
              type="error"
              className="mb-l"
              showIcon
              closable
            />
          )}
          <KeyConfigurationListTable
            tableData={keys}
            pagination={{
              ...pagination,
              pageSizeOption: ["10", "20", "50", "100"],
              showSizeChanger: true,
            }}
            handleTableChange={handlePaginationChange}
            loading={isLoadingTable}
            error={keysError}
            expandable={{
              rowExpandable: () => true,
              childrenColumnName: "rooms",
            }}
            rowSelection={{
              selectedRowKeys: Array.from(selectedPositions),
              type: "checkbox",
              onSelect: (record: Key, selected: boolean) => {
                handlePositionSelected(keys as PositionWithRooms[], [record] as PositionWithRooms[], selected);
              },
              onSelectAll: (selected: boolean) => {
                onSelectAll(selected, keys as PositionWithRooms[]);
              },
              getCheckboxProps: (record: Key) => ({
                name: record.positionId,
              }),
              preserveSelectedRowKeys: true,
            }}
            onExpand={(expand: boolean, record: Key) => {
              handleKeyExpanded(keys as PositionWithRooms[], [record] as PositionWithRooms[], expand);
            }}
            expandedRowKeys={expandedKeys}
          />
        </Col>
      </Row>
      <ModalWrapper
        ref={bulkConfigurationEditModalRef}
        modal={BulkConfigurationEditModal}
        props={{
          showHeatingMode,
          availableOperationalModes,
          selectedPositionIds: selectedRoomIds,
          setRoomsWithThermostatOff,
          onSuccess: refetchKeysHandler,
        }}
      />
    </>
  );
};

export default KeyConfigurationList;
