import React, { useEffect } from "react";

import { usePreviousDistinct } from "react-use";
import { Button, notification } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import {
  useLabelDetailsForRoomLazyQuery,
  useLabelDetailsForKeyLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { createKeyZpl, createRoomZpl, printLabel } from "services/zpl/helper";
import "./KeyAndRooms.css";

const displaySuccessNotification = (name: string) => {
  notification.success({
    message: `Label reprinted for ${name}`,
    top: 90,
    duration: 5,
    style: { width: 450 },
  });
};

const displayPrintingError = (message: string) => {
  notification.error({
    message,
    top: 90,
    duration: 5,
    style: { width: 450 },
  });
};

const KeyAndRooms = ({ keyId, keyName, rooms }: any) => {
  const [getKeyDetails, { data: keyDetails, error: keyDetailsError }] = useLabelDetailsForKeyLazyQuery();

  const [getRoomDetails, { data: roomDetails, error: roomDetailsError }] = useLabelDetailsForRoomLazyQuery();

  const prevKeyDetails = usePreviousDistinct(keyDetails);
  const prevRoomDetails = usePreviousDistinct(roomDetails);

  useEffect(() => {
    const asyncFunc = async () => {
      if (keyDetails && keyDetails !== prevKeyDetails) {
        const printKeyError = await printLabel(createKeyZpl(keyDetails.key));
        if (printKeyError) displayPrintingError("Couldn't print. Please contact supporter.");
        else displaySuccessNotification(`Label reprinted for ${keyDetails.key.keyName}`);
      }
    };

    asyncFunc();
  }, [keyDetails, prevKeyDetails]);

  useEffect(() => {
    const asyncFunc = async () => {
      if (roomDetails && roomDetails !== prevRoomDetails) {
        const printRoomError = await printLabel(createRoomZpl(roomDetails.room));
        if (printRoomError) displayPrintingError("Couldn't print. Please contact supporter.");
        else displaySuccessNotification(`Label reprinted for ${roomDetails.room.roomName}`);
      }
    };

    asyncFunc();
  }, [roomDetails, prevRoomDetails]);

  useEffect(() => {
    if (keyDetailsError || roomDetailsError) displayPrintingError("Couldn't fetch key or room label");
  }, [keyDetailsError, roomDetailsError]);

  const handleReprintKeyLabel = () => {
    getKeyDetails({ variables: { keyId } });
  };

  const handleReprintRoomLabel = (roomId: string) => {
    getRoomDetails({ variables: { roomId } });
  };

  return (
    <div className="key-and-rooms-wrapper">
      <Button
        type="default"
        icon={<PrinterOutlined />}
        size="large"
        onClick={() => handleReprintKeyLabel()}
        block
        data-testid="reprint-key-testid"
      >
        {keyName}
      </Button>
      {rooms &&
        rooms.map((room: any, index: number) => (
          <Button
            key={index}
            icon={<PrinterOutlined />}
            size="large"
            data-testid="reprint-room-testid"
            onClick={() => handleReprintRoomLabel(room.roomId)}
            block
          >
            {room.roomName}
          </Button>
        ))}
    </div>
  );
};

export default KeyAndRooms;
