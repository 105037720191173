import React from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Space, Typography, message } from "antd";
import { KeyIcon } from "components/Icons";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useActivateComfortPlusMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import classNames from "classnames";
import "./ActivateComfortPlusModal.scss";
import moment from "moment-timezone";
import { ActivateComfortPlusForm, ActivateComfortPlusProps } from "./ActivateComfortPlus.d";

const INPUT_DATE_FORMAT = "DD/MM/YYYY, HH:mm";
const initialValues = () => {
  const is2PM = moment().isBefore(moment().hour(14).startOf("h").format());
  const checkIn = moment()
    .hour(14)
    .add(is2PM ? 0 : 1, "d")
    .startOf("h");

  const checkOut = moment()
    .hour(12)
    .add(is2PM ? 1 : 2, "d")
    .startOf("h");

  return {
    emails: [""],
    checkIn,
    checkOut,
  };
};

const ActivateComfortPlus: React.FC<ActivateComfortPlusProps> = ({ modalWrapperProps, selectedKey, closeModal }) => {
  const [form] = Form.useForm<ActivateComfortPlusForm>();
  const checkIn = Form.useWatch("checkIn", form);

  const [activateComfortPlusMutation] = useActivateComfortPlusMutation({
    onError: errorHandler.handleError,
    onCompleted: () => {
      message.success("ComfortPlus successfully activated.");
      closeModal();
    },
  });

  const handleOnSubmit = async (value: ActivateComfortPlusForm) => {
    await activateComfortPlusMutation({
      variables: {
        input: {
          checkInAt: moment(value.checkIn).format(),
          checkOutAt: moment(value.checkOut).format(),
          emails: value.emails,
          keyId: selectedKey?.positionId,
        },
      },
    });
  };

  return (
    <Modal
      title="Activate ComfortPlus"
      centered
      wrapClassName="vh-100-modal activate-comfortPlus"
      width={450}
      {...modalWrapperProps}
      okButtonProps={{ onClick: () => form.submit() }}
      okText="Confirm"
    >
      <Form
        form={form}
        className="d-block p-s"
        layout="vertical"
        initialValues={initialValues()}
        onFinish={handleOnSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Space>
              <div className="icon-key">
                <KeyIcon />
              </div>
              Activate key: {selectedKey?.positionName}
            </Space>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Detail</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label={<span className="required">Check-in</span>}
                  name="checkIn"
                  rules={[
                    {
                      required: true,
                      message: "Please select time Check-in",
                    },
                  ]}
                >
                  <DatePicker format={INPUT_DATE_FORMAT} showTime={{ format: "HH:mm" }} className="w-100" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="required">Check-out</span>}
                  name="checkOut"
                  rules={[
                    {
                      required: true,
                      message: "Please select time Check-out",
                    },
                    {
                      validator(_, value) {
                        if (value < checkIn)
                          return Promise.reject(new Error("Please do not choose a time smaller than check in time"));
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker format={INPUT_DATE_FORMAT} showTime={{ format: "HH:mm" }} className="w-100" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="email-list">
              <Form.List
                name="emails"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(new Error("At least 1 emails"));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...field}
                          className="w-100"
                          label={
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <span className={classNames({ required: index === 0 })}>Email {index + 1}</span>
                              {index > 0 && <DeleteOutlined color="error" onClick={() => remove(index)} />}
                            </div>
                          }
                          rules={[
                            {
                              required: index === 0,
                              whitespace: true,
                              message: "Please input E-mail",
                            },
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      ))}

                      <Button
                        type="text"
                        className="email-list-add-button"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                      >
                        Add Email
                      </Button>
                    </>
                  );
                }}
              </Form.List>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ActivateComfortPlus;
