import React, { useEffect, useRef, useState } from "react";

import { usePrevious } from "react-use";
import { Button, Modal } from "antd";

import "./UnmappedNode.css";
import NodeScanningComponent from "components/NodeMapping/NodeScanning";

const INVALID_NODE_ID_MSG = "Node ID should begin with valid node type identifier. Scan or type Node ID again to retry";
const NODE_MAC_ID_LENGTH = 16;
const FULL_NODE_MAC_ID_LENGTH = 18;

export const isValidNodeMacId = (macId: string) => {
  return !!macId.match(/FFFF[a-f0-9]{12}$/i);
};

const ScannedNode = ({
  roomId,
  roomName,
  keyName,
  currentNodeMacId,
  nodeTypeCodeIdentifier,
  nodeSubTypeCode,
  slotName,
  scannedMacIdSubmit,
  externalError,
}: any) => {
  const unmappingInput: any = useRef(null);
  const [internalError, setInternalError] = useState<any>();
  const [input, setInput] = useState<string>("");
  const prevRoomId = usePrevious(roomId);
  const prevIdentifier = usePrevious(nodeTypeCodeIdentifier);
  const prevNodeSubTypeCode = usePrevious(nodeSubTypeCode);
  const prevSlotName = usePrevious(slotName);

  useEffect(() => {
    if (
      roomId !== prevRoomId ||
      nodeSubTypeCode !== prevNodeSubTypeCode ||
      slotName !== prevSlotName ||
      nodeTypeCodeIdentifier !== prevIdentifier
    ) {
      setInput("");
      setInternalError(null);
    }
  }, [
    roomId,
    nodeSubTypeCode,
    slotName,
    nodeTypeCodeIdentifier,
    prevRoomId,
    prevNodeSubTypeCode,
    prevSlotName,
    prevIdentifier,
  ]);

  useEffect(() => {
    if (unmappingInput.current) {
      unmappingInput.current.focus();
    }
  }, [unmappingInput]);

  const submitNodeUnmapping = (value: any) => {
    let nodeMacInput = value;
    if (nodeMacInput.length === NODE_MAC_ID_LENGTH) {
      nodeMacInput = nodeTypeCodeIdentifier.slice(-2) + nodeMacInput;
    }

    if (nodeMacInput.length === FULL_NODE_MAC_ID_LENGTH) {
      if (!isValidNodeMacId(nodeMacInput.substring(2))) {
        setInternalError(INVALID_NODE_ID_MSG);
      } else if (currentNodeMacId === nodeMacInput.substring(2)) {
        setInternalError(null);
        scannedMacIdSubmit(nodeMacInput);
      } else {
        const closeModal = Modal.destroyAll();
        Modal.confirm({
          title: "Node ID doesn't match",
          content: `Are you in ${keyName} ${roomName}?`,
          className: "confirm-modal",
          okText: "No",
          cancelText: "Yes",
          onCancel: () => {
            scannedMacIdSubmit(false, nodeMacInput);
          },
          onOk: () => closeModal,
        });
      }
    } else {
      setInternalError(null);
    }
  };

  const ExternalErrorComponentClass = externalError?.component;

  const handleInputChange = (e: any) => {
    const userInput = (e.target.value || "").toUpperCase();
    submitNodeUnmapping(userInput);
    setInput(userInput);
  };

  const handleQRCodeScan = (value: any) => {
    if (value) {
      submitNodeUnmapping((value || "").toUpperCase());
    }
  };

  const handleScanQRCodeError = () => {
    setInternalError("Error during scan QR Code.");
  };

  const onTabChanged = () => {
    setInternalError(null);
    setInput("");
  };

  const handleCannotScan = () => {
    scannedMacIdSubmit();
  };

  return (
    <>
      <p className="current-room-text" data-testid="center-room-testId">
        Scan to confirm
      </p>
      <NodeScanningComponent
        internalError={internalError}
        handleInputChange={handleInputChange}
        input={input}
        mappingInput={unmappingInput}
        onTabChanged={onTabChanged}
        externalError={externalError}
        ExternalErrorComponentClass={ExternalErrorComponentClass}
        handleScanQRCodeError={handleScanQRCodeError}
        handleQRCodeScan={handleQRCodeScan}
      />
      <Button onClick={handleCannotScan}>Can&apos;t scan</Button>
    </>
  );
};

export default ScannedNode;
