import { get } from "lodash";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import {
  InfrastructureTypeEnum,
  infrastructureTypeInput,
  infrastructureTypeValue,
  InfraPhaseEnum,
  HvacSystemTypeEnum,
} from "./Infrastructures.d";

export const getInfraTypeValue = (type: InfrastructureTypeEnum, phase: InfraPhaseEnum) => {
  if (type === InfrastructureTypeEnum.PIPE) return "";
  return infrastructureTypeInput[type][phase];
};

export class InfrastructureData {
  locationId: string;

  name!: string;

  type!: InfrastructureTypeEnum;

  numberOfPhases!: InfraPhaseEnum;

  hvacSystemType?: HvacSystemTypeEnum;

  compressorId?: string;

  ctMultiplier?: number;

  constructor(
    locationId: string,
    name: string | undefined,
    type: InfrastructureTypeEnum | undefined,
    numberOfPhases: InfraPhaseEnum | undefined,
    hvacSystemType: HvacSystemTypeEnum | undefined,
    compressorId: string | undefined,
    ctMultiplier: number | undefined
  ) {
    this.locationId = locationId;
    if (name) this.name = name;
    if (type) this.type = type;
    if (numberOfPhases) this.numberOfPhases = numberOfPhases;
    if (hvacSystemType) this.hvacSystemType = hvacSystemType;
    if (compressorId) this.compressorId = compressorId;
    if (ctMultiplier) this.ctMultiplier = ctMultiplier;
  }

  isPipe(): boolean {
    return this.type === InfrastructureTypeEnum.PIPE;
  }

  isCoolingSystem(): boolean {
    return this.type === InfrastructureTypeEnum.CHILLED_WATER;
  }

  isEmpty(): boolean {
    if (this.isPipe() || this.isCoolingSystem()) return isEmpty(this.name);
    return some([this.name, this.type, InfraPhaseEnum[this.numberOfPhases]], (data) => isEmpty(data));
  }

  toInputWithoutPhase(type: string) {
    return {
      locationId: this.locationId,
      name: this.name,
      type,
    };
  }

  toInput() {
    return {
      locationId: this.locationId,
      name: this.name,
      type: getInfraTypeValue(this.type, this.numberOfPhases),
      numberOfPhases: this.numberOfPhases,
      hvacSystemType: this.hvacSystemType,
      compressorId: this.compressorId,
      ctMultiplier: this.ctMultiplier,
    };
  }
}

export class InfrastructureType {
  label!: string;

  type!: InfrastructureTypeEnum;

  numbOfPhases!: number;

  constructor(type?: string) {
    const infraType = get(infrastructureTypeValue, type!);
    if (infraType) {
      this.label = infraType.label;
      this.type = infraType.type;
      this.numbOfPhases = infraType.numbOfPhases;
    }
  }

  isCompressor(): boolean {
    return this.type === InfrastructureTypeEnum.COMPRESSOR;
  }

  isCoolingSystem(): boolean {
    return this.type === InfrastructureTypeEnum.CHILLED_WATER;
  }

  isCoolingDevice(): boolean {
    return (
      this.type === InfrastructureTypeEnum.CHILLER ||
      this.type === InfrastructureTypeEnum.COOLING_TOWER ||
      this.type === InfrastructureTypeEnum.PUMP
    );
  }

  is3Phases(): boolean {
    return this.numbOfPhases === 3;
  }

  getLabel(): string {
    return this.label;
  }
}
