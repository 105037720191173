import { Col, Row, Switch } from "antd";
import React from "react";

import { ExcludeLockedSwitcherProps } from "./ExcludeLockedSwitcher.d";

const ExcludeLockedSwitcher = (props: ExcludeLockedSwitcherProps) => {
  const { onChange, checked } = props;

  return (
    <Row wrap={false} align="middle" className="mb-s" gutter={10}>
      <Col>
        <Switch onChange={onChange} checked={checked} />
      </Col>
      <Col>
        <div className="fs-m font-weight-bold">Exclude Locked Version</div>
        <div className="fs-sm">Select to show nodes that are NOT Locked to their FW version</div>
      </Col>
    </Row>
  );
};

export default ExcludeLockedSwitcher;
