import moment, { Moment } from "moment";
import {
  CustomerKeyListWithNodeMeasurementsSubscription,
  TemporaryKeyListWithNodeMeasurementsQuery,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import { ACMode } from "../types";

export enum PreCoolStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
  SCHEDULED = "SCHEDULED",
  CANCEL = "CANCEL",
  ERROR = "ERROR",
}

export const PrecoolActiveStatuses = [
  PreCoolStatus.PENDING,
  PreCoolStatus.ACCEPTED,
  PreCoolStatus.SUCCESS,
  PreCoolStatus.SCHEDULED,
];

type PositionConfigs = {
  occupancyState?: number | null | undefined;
  doorStatus?: number | null | undefined;
  isDefaultConfig?: boolean;
  acMode?: number | null | undefined;
  acStatus?: number | null | undefined;
  acModeSummary?: {
    onCount: number;
    offCount: number;
  };
  acSetPoint?: number | null | undefined;
  fanSpeed?: number | null | undefined;
  acPrecoolExpiredTime?: moment | null | undefined;
  acPrecoolStatus?: PreCoolStatus | null | undefined;
  automationMode?: string | null | undefined;
  ambientTemperature?: string | null | undefined;
  ambientHumidity?: string;
  hasDoor?: boolean;
  hasOccupancy?: boolean;
  hasHVAC?: boolean;
  type: string;
  parentId?: string | null | undefined;
  roomCount: number;
};
export type Room = NonNullable<RawKey["rooms"]>[number] & PositionConfigs;

export type Key = Omit<RawKey, "rooms"> &
  PositionConfigs & {
    rooms: Room[];
  };

export type RawKeyFromSubscription = NonNullable<CustomerKeyListWithNodeMeasurementsSubscription["keyList"]>[number];

export type RawKeyFromQuery = NonNullable<
  NonNullable<NonNullable<TemporaryKeyListWithNodeMeasurementsQuery>["locationOne"]>["locationKeys"]
>[number];

export type RawKey = Omit<RawKeyFromQuery, "rooms" | "positionId"> &
  Omit<RawKeyFromSubscription, "rooms" | "positionId"> & {
    rooms: (NonNullable<RawKeyFromQuery["rooms"]>[number] & NonNullable<RawKeyFromSubscription["rooms"]>[number])[];
  } & {
    positionId: string;
  };

export type CustomerKeyListRawData = {
  queryData?: TemporaryKeyListWithNodeMeasurementsQuery;
  subscriptionData?: CustomerKeyListWithNodeMeasurementsSubscription;
};

export type PresetValues = {
  mins: number;
  mode: ACMode;
  temperature: number;
  startTime: Moment | undefined | null;
  endTime: Moment;
};

export type RoomsWithThermostatOffList = {
  keyName: string;
  rooms: string[];
};

export type PositionPrecoolStatus = {
  positionId: string;
  isActive: boolean;
};
