import { Badge, Button, Card, Col, Divider, Row, Typography } from "antd";
import { ClaimKeyButton } from "components/ClaimKey";
import {
  AirPumpIcon,
  CommentsIcon,
  HourglassIcon,
  IcFillEngineerIcon,
  KeyIcon,
  RouterWifiIcon,
} from "components/Icons";
import startCase from "lodash/startCase";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { KeyEntryStatus } from "types/InstallationMode.d";
import { PositionType } from "types/types";
import { Link } from "react-router-dom";
import useLocationDateTime from "hooks/use-location-datetime";
import classNames from "classnames";
import _ from "lodash";
import { ActiveTab } from "../../Key/KeyDetailWrapper/KeyDetailWrapper";
import { JobCardProps } from "./JobCard.d";
import "./JobCard.scss";

export const STATUS_CONFIG: Record<
  string,
  [string, "error" | "default" | "warning" | "success" | "processing" | undefined]
> = {
  [KeyEntryStatus.DONE]: ["Not Started", "default"],
  [KeyEntryStatus.DO_NOT_DISTURB]: ["Do not disturb", "error"],
  [KeyEntryStatus.IN_PROGRESS]: ["In Progress", "processing"],
  [KeyEntryStatus.PENDING]: ["Pending", "default"],
  [KeyEntryStatus.VISITED_REQUIRED]: ["Revisit required", "warning"],
  NOT_STARTED: ["Not Started", "default"],
};

export const getUiConfig = (status?: string) => {
  if (!status) return STATUS_CONFIG[KeyEntryStatus.IN_PROGRESS];
  return STATUS_CONFIG[status];
};

export const TagStatus = ({
  status,
  statusStartedAt,
  statusEndedAt,
}: Pick<JobCardProps, "status" | "statusEndedAt" | "statusStartedAt">) => {
  const [sinceTime, setSinceTime] = useState(0);

  const actualStatus = useMemo(() => {
    if (!status) {
      if (!statusStartedAt) return undefined;
      if (!statusEndedAt) return KeyEntryStatus.IN_PROGRESS;
    }
    return Object.values(KeyEntryStatus).includes(status as KeyEntryStatus) ? status : undefined;
  }, [status, statusEndedAt, statusStartedAt]);

  useEffect(() => {
    if (!statusStartedAt) return;

    const timer = setInterval(() => {
      const startTime = moment(statusStartedAt);
      const now = moment(moment.now());
      setSinceTime(now.diff(startTime, "m"));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [statusStartedAt, statusEndedAt]);

  const uiConfig = actualStatus ? getUiConfig(actualStatus) : STATUS_CONFIG.NOT_STARTED;

  return (
    <Row gutter={[6, 6]} justify="end">
      <Col>
        <Badge status={uiConfig?.[1]} text={uiConfig?.[0]} />
      </Col>
      {!!statusStartedAt && !statusEndedAt && (
        <Col>
          <Typography.Text type="warning">
            <span className="font-size-10">since</span> <span className="font-weight-bold">{sinceTime}m</span>
          </Typography.Text>
        </Col>
      )}
    </Row>
  );
};

const jobTypeIcon = (possitionType: string, categoryName?: string) => {
  switch (possitionType) {
    case PositionType.GATEWAY:
      return (
        <Col className="ml-xs">
          <RouterWifiIcon className="svg-icon-size" />
        </Col>
      );

    case PositionType.COMPRESSOR:
    case PositionType.METER_POSITION:
      return (
        <Col className="ml-xs">
          <AirPumpIcon className="svg-icon-size" />
        </Col>
      );
    default:
      return (
        (categoryName || possitionType) && (
          <>
            &nbsp;
            <KeyIcon className="svg-icon-size" />
            <Divider type="vertical" className="h-auto" />
            <Col style={{ maxWidth: 80 }}>
              <p className="text-truncate">{categoryName ?? possitionType}</p>
            </Col>
          </>
        )
      );
  }
};

const getActiveTabDetail = (possitionType: string) => {
  switch (possitionType) {
    case PositionType.COMPRESSOR:
      // Update infra tab key
      return ActiveTab.JOB_DETAILS;
    case PositionType.GATEWAY:
      return ActiveTab.GATEWAY_JOB_DETAILS;
    default:
      return ActiveTab.JOB_DETAILS;
  }
};

const JobCard: React.FC<JobCardProps> = ({
  keyName,
  categoryName,
  totalRooms,
  keyId,
  group,
  assigneeName,
  assigneeId,
  locationId,
  loading,
  totalKeys,
  isGroupView,
  status,
  statusEndedAt,
  statusStartedAt,
  possitionType,
  comments,
  currentFilter,
  remainKeyEntries,
  taskDueDate,
}) => {
  const { formatDate, isOverdueDate } = useLocationDateTime();

  const getFilterParams = useCallback((): string => {
    const params = new URLSearchParams();

    if (!_.isUndefined(currentFilter.groups)) {
      params.append("groups", currentFilter.groups.join(","));
    }

    if (currentFilter.emails && !_.isEmpty(currentFilter.emails)) {
      params.append("emails", currentFilter.emails.join(","));
    }

    if (currentFilter.viewAsGroups) {
      params.append("viewAsGroups", currentFilter.viewAsGroups.toString());
    }

    if (currentFilter.keyName) {
      params.append("keyName", currentFilter.keyName);
    }

    if (currentFilter.taskStatus) {
      params.append("taskStatus", currentFilter.taskStatus);
    }

    return params.toString();
  }, [currentFilter]);

  const jobCardTitle = useMemo(
    () => (
      <Row>
        <Col style={{ maxWidth: 100 }}>
          <p className="text-primary font-weight-bold text-truncate">{keyName}</p>
        </Col>
        {jobTypeIcon(possitionType, categoryName)}
        {comments && <CommentsIcon className="ml-xs" />}
      </Row>
    ),
    [keyName, categoryName, possitionType, comments]
  );

  const totalRemainingKeyEntries = useMemo(() => {
    return `#Open jobs ${remainKeyEntries || 0}`;
  }, [remainKeyEntries]);

  const groupInfo = useMemo(() => {
    if (!group) return;
    return `${startCase(group.groupType)} ${group.groupName}`;
  }, [group]);

  const actualStatus = useMemo(() => {
    if (!status) {
      if (!statusStartedAt) return undefined;
      if (!statusEndedAt) return KeyEntryStatus.IN_PROGRESS;
    }
    return Object.values(KeyEntryStatus).includes(status as KeyEntryStatus) ? status : undefined;
  }, [status, statusEndedAt, statusStartedAt]);

  return (
    <Card
      className="radius-xs border-semi-blue"
      title={jobCardTitle}
      extra={
        !isGroupView && <TagStatus status={status} statusEndedAt={statusEndedAt} statusStartedAt={statusStartedAt} />
      }
      headStyle={{ padding: "0 12px", background: "#f2f4f6" }}
      bodyStyle={{ paddingLeft: 12, paddingRight: 12 }}
      loading={loading}
    >
      <Row wrap={false} className="w-100">
        <Col flex="auto">
          {groupInfo && (
            <>
              <span className="mr-xs">{groupInfo}</span>
              <span className="mr-s">•</span>
            </>
          )}
          {totalKeys && totalKeys >= 0 && (
            <>
              <span>#Keys {totalKeys}</span> <Divider type="vertical" style={{ borderLeftWidth: "2px" }} />
            </>
          )}
          {totalRooms >= 0 && <span>#Rooms {totalRooms}</span>}
          {remainKeyEntries && remainKeyEntries >= 0 && (
            <>
              <Divider type="vertical" style={{ borderLeftWidth: "2px" }} />
              <span>{totalRemainingKeyEntries}</span>
            </>
          )}
        </Col>
        {assigneeName && (
          <Col flex="none" style={{ maxWidth: "100px" }} className="text-truncate">
            <span>
              <IcFillEngineerIcon className="mr-s" />
              {assigneeName}
            </span>
          </Col>
        )}
      </Row>
      {taskDueDate && (
        <Row wrap={false} className="w-100 mt-s">
          <Col flex="auto">
            <Typography.Text
              className={classNames({
                "text-dark-orange": isOverdueDate(taskDueDate),
              })}
            >
              <HourglassIcon className="mr-xs" fill={isOverdueDate(taskDueDate) ? "#e8472e" : "#a4a4a4"} />
              {formatDate(taskDueDate)}
            </Typography.Text>
          </Col>
        </Row>
      )}
      <Divider className="my-m" />
      <Row className="w-100" gutter={[8, 8]}>
        <Col span={isGroupView ? 24 : 12}>
          <Link
            to={{
              pathname: `/locations/${locationId}/keys/${keyId}/${getActiveTabDetail(possitionType)}`,
              search: `?${getFilterParams()}`,
              state: { taskDueDate },
            }}
          >
            <Button className="text-primary font-weight-bold claim-key-button w-100">Work info</Button>
          </Link>
        </Col>
        {!isGroupView && (
          <Col span={12}>
            <ClaimKeyButton
              status={actualStatus ? getUiConfig(actualStatus) : STATUS_CONFIG.NOT_STARTED}
              keyPositionId={keyId}
              possitionType={possitionType}
              assigneeId={assigneeId}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default JobCard;
