import moment from "moment";
import { formatYearMonthDate } from "utils/date";

const currentMonth = moment().date(1);

export default [
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(12, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(11, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(10, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(9, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(8, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(7, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(6, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(5, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(4, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(3, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(2, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth).subtract(1, "M")),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
  {
    averageDailyRunningCompressors: 2.19355,
    averageOccupancy: 0.246491,
    billingCurrency: "VND",
    followedRecommendationRatio: 0.8125,
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    ninetyAdherenceSavingsKwh: 776.918,
    ninetyAdherenceSavingsSgd: 125.998,
    ninetyAdherenceSavingsLocalCurrency: 2.135e6,
    numberOfFollowedRecommendation: 39,
    numberOfRecommendation: 48,
    numberOfReservations: 57,
    savingsKwh: 700.668,
    savingsLocalCurrency: 1.92546e6,
    savingsPercentage: 0.0998642,
    savingsSgd: 113.632,
    simulatedNoSmartallocConsumptionKwh: 7794.61,
    simulatedNoSmartallocDailyCompressors: 2.45965,
    totalConsumptionKwh: 7016.21,
    date: formatYearMonthDate(moment(currentMonth)),
    type: "MONTHLY",
    __typename: "sf_pms_smartalloc_report",
  },
];
