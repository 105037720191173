import React from "react";
import { ApolloError } from "apollo-client";
import { message, Tag } from "antd";
import Table from "components/Table/Table";
import moment from "moment";
import {
  GatewayHealthStatus,
  useDeleteGatewayMutation,
  useRenameGatewayMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import useRoleAndPermission from "hooks/use-role-and-permission";
import errorHandler from "errorHandler";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "components/Modal/ConfirmDeleteModal";
import showModal from "components/Modal/show";
import RenameFormModal, { RenameFormModalProps } from "components/RenameModal/RenameFormModal";
import useBreakpoint from "hooks/use-breakpoint";
import { Gateway, GATEWAY_STATUS_UI_CONFIG } from "../GatewayList/GatewayList.d";
import GatewayAction from "../GatewayAction/GatewayAction";
import GatewayName from "../GatewayName/GatewayName";
import "../GatewayName/GatewayName.scss";
import "./GatewayListTable.scss";
import GatewayMobileItem from "../GatewayMobileItem/GatewayMobileItem";
import { getConnectionStatusDisplayText } from "../GatewayList/GatewayList.util";

export type GatewayListTableProps = {
  reloadGateways: () => Promise<void>;
  tableData: Gateway[];
  handleTableChange: (value: any) => void;
  loading: boolean;
  error?: ApolloError;
  rowSelection: any;
};

const GatewayListTable = (props: GatewayListTableProps) => {
  const { reloadGateways } = props;
  const screen = useBreakpoint();

  const roleAndPermission = useRoleAndPermission();

  const [deleteGatewayMutation] = useDeleteGatewayMutation({
    onCompleted: () => {
      message.info(`Gateway has been deleted.`);
      reloadGateways();
    },
    onError: (error: any) => {
      errorHandler.handleError(error);
    },
  });

  const [renameGatewayMutation] = useRenameGatewayMutation({
    onCompleted: () => {
      message.info(`Gateway has been renamed.`);
      reloadGateways();
    },
    onError: (error: any) => {
      errorHandler.handleError(error);
    },
  });

  const renameGateway = (actionGatewayId: string, gatewayName: string) => {
    renameGatewayMutation({
      variables: {
        gatewayId: actionGatewayId,
        gatewayName,
      },
    });
  };

  const deleteGateway = (gatewayId: string) => {
    deleteGatewayMutation({
      variables: {
        gatewayId,
      },
    });
  };

  const showDeleteModal = (gatewayId: string, gatewayName: string, healthStatus: GatewayHealthStatus) => {
    if (healthStatus !== GatewayHealthStatus.Online) {
      showModal<ConfirmDeleteModalProps>({
        element: ConfirmDeleteModal,
        config: {
          popupTitle: "Delete Gateway",
          fieldPlaceholder: "Enter Gateway Name",
          popupOkText: "Confirm",
          onOk: () => deleteGateway(gatewayId),
          confirmText: gatewayName,
          message: `Are you sure you want to delete ${gatewayName}?`,
          fieldLabel: "Key in Gateway Name to confirm",
        },
      });
    } else {
      message.error("The gateway cannot be deleted in online status.");
    }
  };

  const showRenameModal = (gatewayId: string, gatewayName: string) => {
    showModal<RenameFormModalProps>({
      element: RenameFormModal,
      config: {
        popupTitle: "Rename Gateway",
        popupOkText: "Confirm",
        onSubmit: renameGateway,
        objectName: gatewayName,
        objectId: gatewayId,
        fieldLabel: "Update Gateway Name",
        placeholderText: "Enter New Gateway Name",
        message: "Gateway name only contains numbers, letters, dash (-), or underscore(_)!",
        regexPattern: "^[a-zA-Z0-9_-]+$",
      },
    });
  };

  const COLUMN_CONFIG = [
    {
      title: "Healthy Status",
      render: (record: Gateway) => {
        const uiConfig: string[] = GATEWAY_STATUS_UI_CONFIG[record.healthStatus];
        return (
          <Tag color={uiConfig[1]} data-testid="healthy-gateway-status-testId">
            {uiConfig[0]}
          </Tag>
        );
      },
      sorter: (a: Gateway, b: Gateway) =>
        GATEWAY_STATUS_UI_CONFIG[a.healthStatus][2].localeCompare(GATEWAY_STATUS_UI_CONFIG[b.healthStatus][2]),
      isShowOnMobile: false,
      isVisible: true,
      width: 100,
      fixed: "left",
    },
    {
      title: "Kitting Status",
      render: (record: Gateway) => {
        const uiConfig: string[] = GATEWAY_STATUS_UI_CONFIG[record.kittingStatus];
        return (
          <Tag color={uiConfig[1]} data-testid="kitting-gateway-status-testId">
            {uiConfig[0]}
          </Tag>
        );
      },
      sorter: (a: Gateway, b: Gateway) =>
        GATEWAY_STATUS_UI_CONFIG[a.kittingStatus][2].localeCompare(GATEWAY_STATUS_UI_CONFIG[b.kittingStatus][2]),
      isShowOnMobile: false,
      isVisible: true,
      fixed: "left",
    },
    {
      title: "Gateway",
      render: (record: Gateway) => (
        <GatewayName
          gatewayId={record.gatewayId}
          name={record.gatewayName}
          status={record.kittingStatus}
          allowDelete={
            record.kittingStatus !== GatewayHealthStatus.PreparingImage &&
            record.healthStatus !== GatewayHealthStatus.Online
          }
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      ),
      sorter: (a: Gateway, b: Gateway) => a.gatewayName.localeCompare(b.gatewayName),
      isShowOnMobile: false,
      isVisible: true,
      fixed: "left",
      width: 200,
    },
    {
      title: "Group",
      render: (record: Gateway) => {
        if (record.position && record.position.positionId !== record.locationId) return record.position.positionName;
        return "--";
      },
      sorter: (a: Gateway, b: Gateway) => a.gatewayName.localeCompare(b.gatewayName),
      isShowOnMobile: false,
      isVisible: true,
      fixed: "left",
      width: 200,
    },
    {
      title: "Last Seen",
      render: (record: Gateway) => {
        if (record.gatewayHealthData.length) {
          return <div>{moment(record.gatewayHealthData[0].time).fromNow()}</div>;
        }
        return null;
      },
      isShowOnMobile: false,
      isVisible: roleAndPermission.isInstaller(),
    },
    {
      title: "Wifi Mac Address",
      render: (record: Gateway) => {
        if (!record.wifiMacAddress) {
          return null;
        }
        const [internalMac, dongleMac] = record.wifiMacAddress.split(",");
        const dongleSection = dongleMac ? <p>Dongle - {dongleMac}</p> : null;
        return (
          <div>
            <p>Internal - {internalMac}</p>
            {dongleSection}
          </div>
        );
      },
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Eth Mac Address",
      render: (record: Gateway) => record.ethernetMacAddress,
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Wifi Signal Strength",
      render: (record: Gateway) => {
        if (record.gatewayHealthData.length) {
          return getConnectionStatusDisplayText(record);
        }
        return null;
      },
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Actions",
      render: (record: Gateway) => {
        return <GatewayAction gateway={record} reloadGateways={reloadGateways} />;
      },
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "",
      render: (record: Gateway) => {
        return (
          <GatewayMobileItem
            gateway={record}
            showDeleteModal={showDeleteModal}
            showRenameModal={showRenameModal}
            reloadGateways={reloadGateways}
          />
        );
      },
      isVisible: true,
      isShowOnMobile: true,
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  if (screen.mobileAndTabletOnly) {
    return (
      <>
        <div className="d-flex flex-row align-items-center w-100 font-weight-bold text-center">
          <div className="w-35">Gateway</div>
          {roleAndPermission.isInstaller() && (
            <>
              <div className="w-30">Last seen</div>
              <div className="w-30">Wifi Signal</div>
            </>
          )}
        </div>
        <Table
          {...props}
          columns={columns}
          mobileView={screen.mobileAndTabletOnly}
          rowKey="gatewayId"
          id="gateway-table"
          dataItemName="gateways"
          classNames={{ "table-gateway-mobile": screen.mobileAndTabletOnly }}
        />
      </>
    );
  }

  return (
    <Table
      {...props}
      scroll={{ x: screen.desktopUp ? 1200 : true }}
      columns={columns}
      mobileView={screen.mobileAndTabletOnly}
      rowKey="gatewayId"
      id="gateway-table"
      dataItemName="gateways"
      pagination={{
        pageSizeOption: ["10", "20", "50", "100"],
        showSizeChanger: true,
      }}
      classNames={{ "table-gateway-mobile": screen.mobileAndTabletOnly }}
    />
  );
};

export default GatewayListTable;
