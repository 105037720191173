import { Alert, Space } from "antd";
import React from "react";

export interface WarningUseMobileOnlyProps {}

export const WarningUseMobileOnly: React.FC<WarningUseMobileOnlyProps> = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Alert message="Warning" description="Please use this on your mobile phone instead." type="warning" showIcon />
    </Space>
  );
};
