import React, { useState } from "react";
import classNames from "classnames";

import useBreakpoint from "hooks/use-breakpoint";
import { formatNumber } from "utils/number";
import { formatMonth, formatShortMonth, formatWeek, formatYear } from "utils/date";
import UnitSelect, { Units } from "./UnitSelect";
import ChartLegend from "./ChartLegend";
import { InformationIcon } from "../../../components/Icons";
import { Bar } from "../../../components/Chart";
import styles from "../../../styles/export.module.scss";
import "./EnergyConsumptionChart.scss";
import { SmartAllocReport } from "../Dashboard/SmartAllocDashboard.d";
import EmptyChart from "./EmptyChart";
import { getChartData } from "../Dashboard/SmartAllocDashboard.helper";

const defaultDatasetConfigs = {
  barPercentage: 1.0,
  categoryPercentage: 0.4,
};

type Props = {
  months: string[];
  weeks: string[];
  smartAllocReport?: SmartAllocReport | null;
};

const EnergyConsumptionChart = (props: Props) => {
  const { months, weeks, smartAllocReport } = props;
  const isEmpty = !smartAllocReport;

  const [unit, setUnit] = useState<Units>(Units.kWh);
  const [period, setPeriod] = useState<Units>(Units.monthly);
  const screen = useBreakpoint();

  const isMonthly = period === Units.monthly;
  const notUsingSmartAlloc = getChartData(smartAllocReport?.notUsingSmartAlloc, isMonthly)?.map((value) => {
    return unit === Units.kWh ? value : value * 1000;
  });
  const usingSmartAlloc = getChartData(smartAllocReport?.usingSmartAlloc, isMonthly)?.map((value) => {
    return unit === Units.kWh ? value : value * 1000;
  });

  const data = {
    labels: isMonthly ? months : weeks,
    grouped: true,
    datasets: [
      {
        label: "Not using SmartAlloc",
        data: notUsingSmartAlloc,
        backgroundColor: styles.darkBlue,
        ...defaultDatasetConfigs,
      },
      {
        label: "Using SmartAlloc",
        data: usingSmartAlloc,
        backgroundColor: styles.lightOrange,
        ...defaultDatasetConfigs,
      },
    ],
  };

  return (
    <div>
      <div
        className={classNames(
          "d-flex justify-content-between flex-wrap mb-xl",
          screen.desktopOny ? "flex-row align-items-center" : "flex-column"
        )}
      >
        <h2 className="fs-h4 fs-xl font-weight-bold mb-m">
          Energy Consumption
          <InformationIcon className="ml-s mb-xs d-inline-block" />
        </h2>
        {!isEmpty && (
          <>
            <div className={classNames("d-flex mb-m", { "ml-xl": screen.desktopOny })}>
              <div>
                <UnitSelect
                  label="Unit"
                  options={[
                    {
                      value: Units.kWh,
                      label: "kWh",
                    },
                    {
                      value: Units.wh,
                      label: "Wh",
                    },
                  ]}
                  value={unit}
                  onChange={setUnit}
                />
              </div>
              <div className="ml-m">
                <UnitSelect
                  label="Filter by"
                  options={[
                    {
                      value: Units.monthly,
                      label: "Monthly",
                    },
                    {
                      value: Units.weekly,
                      label: "Weekly",
                    },
                  ]}
                  value={period}
                  onChange={setPeriod}
                />
              </div>
            </div>
            <div className={classNames({ "w-100 d-flex justify-content-end pr-xl": screen.desktopOny })}>
              <ChartLegend datasets={data.datasets} />
            </div>
          </>
        )}
      </div>
      {isEmpty && <EmptyChart />}
      {!isEmpty && (
        <div
          className={classNames({
            "h-20vw": screen.desktopOny,
            "h-75vw": screen.mobileAndTabletOnly,
          })}
        >
          <Bar
            data={data}
            options={{
              aspectRatio: 4,
            }}
            renderTooltip={(tooltipData) => {
              return (
                tooltipData && (
                  <div className="energy-comsumption-chart-tooltip fs-sm">
                    <div>
                      <strong>{formatMonth(tooltipData.title)}</strong>
                    </div>
                    {tooltipData.bodyLines.map((bodyItem, index) => {
                      return (
                        <div className="d-flex align-items-center" key={index}>
                          <div
                            style={{
                              backgroundColor: bodyItem.backgroundColor,
                            }}
                            className="mr-xs energy-comsumption-chart-tooltip__dot"
                          />
                          <span>
                            <strong>{formatNumber(bodyItem.value)}</strong>&nbsp;
                            {unit === Units.kWh ? "KWh" : "Wh"}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )
              );
            }}
            xAxesTicksCallback={(value: string) => {
              if (isMonthly) {
                return [formatShortMonth(value) ?? "", formatYear(value) ?? ""];
              }
              return [formatWeek(value) ?? "", formatYear(value) ?? ""];
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EnergyConsumptionChart;
