import { InformationIcon } from "components/Icons";
import React, { useState } from "react";
import classNames from "classnames";

import useBreakpoint from "hooks/use-breakpoint";
import { formatNumber } from "utils/number";
import { formatShortMonth, formatWeek, formatYear } from "utils/date";
import ChartLegend from "./ChartLegend";
import { Line } from "../../../components/Chart";
import styles from "../../../styles/export.module.scss";
import "./CompressorChart.scss";
import { SmartAllocReport } from "../Dashboard/SmartAllocDashboard.d";
import EmptyChart from "./EmptyChart";
import UnitSelect, { Units } from "./UnitSelect";
import { getChartData } from "../Dashboard/SmartAllocDashboard.helper";

const datasets = [
  {
    label: "Opcupancy",
    backgroundColor: styles.darkOrange,
  },
  {
    label: "Maximum number of compressors",
    backgroundColor: styles.lightBlue,
  },
  {
    label: "No. of compressors in use",
    backgroundColor: styles.lightOrange,
  },
];

const defaultDatasetConfigs = {
  borderWidth: 2,
  pointHitRadius: 6,
  pointHoverRadius: 6,
  lineTension: 0,
};

type Props = {
  months: string[];
  weeks: string[];
  smartAllocReport?: SmartAllocReport | null;
};

const CompressorChart = (props: Props) => {
  const { months, weeks, smartAllocReport } = props;
  const isEmpty = !smartAllocReport;

  const [period, setPeriod] = useState(Units.monthly);

  const screen = useBreakpoint();
  const isMonthly = period === Units.monthly;
  const occupancy = getChartData(smartAllocReport?.occupancy, isMonthly);
  const maximumCompressors = getChartData(smartAllocReport?.maximumCompressors, isMonthly);
  const compressorsInUse = getChartData(smartAllocReport?.compressorsInUse, isMonthly);

  const data = (canvas: any) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, `${styles.lightOrange}59`);
    gradient.addColorStop(1, styles.transparent);

    return {
      labels: isMonthly ? months : weeks,
      datasets: [
        {
          label: "Opcupancy",
          data: [...occupancy],
          borderColor: styles.darkOrange,
          backgroundColor: styles.transparent,
          pointBorderWidth: 2,
          pointBorderColor: styles.darkBlue10,
          pointBackgroundColor: styles.darkOrange,
          pointRadius: 6,
          pointHoverBorderWidth: 0,
          pointHoverBorderColor: styles.transparent,
          ...defaultDatasetConfigs,
        },
        {
          label: "Maximum number of compressors",
          data: [...maximumCompressors],
          borderColor: styles.lightBlue,
          backgroundColor: styles.transparent,
          pointBackgroundColor: styles.lightBlue,
          borderDash: [2, 4],
          pointRadius: 0,
          ...defaultDatasetConfigs,
        },
        {
          label: "No. of compressors in use",
          data: [...compressorsInUse],
          borderColor: styles.lightOrange,
          backgroundColor: gradient,
          pointBackgroundColor: styles.lightOrange,
          pointRadius: 0,
          ...defaultDatasetConfigs,
        },
      ],
    };
  };

  return (
    <div>
      <div
        className={classNames(
          "d-flex justify-content-between flex-wrap mb-xl",
          screen.desktopOny ? "flex-row align-items-center" : "flex-column"
        )}
      >
        <h2 className="fs-h4 fs-xl font-weight-bold mb-m">
          Number of {screen.mobileAndTabletOnly && <br />}compressors in use
          <InformationIcon className="ml-s mb-xs d-inline-block" />
        </h2>
        {!isEmpty && (
          <>
            <div
              className={classNames("mb-m", {
                "ml-xl": screen.desktopOny,
              })}
            >
              <UnitSelect
                label="Filter by"
                options={[
                  {
                    value: Units.monthly,
                    label: "Monthly",
                  },
                  {
                    value: Units.weekly,
                    label: "Weekly",
                  },
                ]}
                value={period}
                onChange={setPeriod}
              />
            </div>
            <div className={classNames({ "w-100 d-flex justify-content-end pr-xl": screen.desktopOny })}>
              <ChartLegend datasets={datasets} variant={screen.desktopOny ? "horizontal" : "vertical"} />
            </div>
          </>
        )}
      </div>
      {isEmpty && <EmptyChart />}
      {!isEmpty && (
        <div
          className={classNames({
            "h-20vw": screen.desktopOny,
            "h-75vw": screen.mobileAndTabletOnly,
          })}
        >
          <Line
            data={data}
            renderTooltip={(tooltipData) => {
              return (
                tooltipData && (
                  <div className="fs-sm compressor-chart-tooltip">
                    {tooltipData.body.datasetIndex === 0 ? (
                      <>
                        <div>
                          <strong>Opcupancy</strong>
                        </div>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              backgroundColor: tooltipData.body.backgroundColor,
                            }}
                            className="mr-xs compressor-chart-tooltip__dot"
                          />
                          <strong>{formatNumber(tooltipData.body.value)}</strong>
                          <small>%</small>
                        </div>
                      </>
                    ) : (
                      <>
                        <strong>{formatNumber(tooltipData.body.value)}</strong>&nbsp;compressors
                      </>
                    )}
                  </div>
                )
              );
            }}
            xAxesTicksCallback={(value: string) => {
              if (isMonthly) {
                return [formatShortMonth(value) ?? "", formatYear(value) ?? ""];
              }
              return [formatWeek(value) ?? "", formatYear(value) ?? ""];
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CompressorChart;
