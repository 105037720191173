import React from "react";

import ExternalError from "./ExternalError";

const NodeAlreadyMappedError = ({ nodeId, locationName, keyName, roomName }: any) => {
  const details = [
    `This node ${nodeId} is already mapped to:`,
    `${locationName}`,
    `${keyName}`,
    `${roomName}`,
    "",
    "Set this node aside and raise this to SensorFlow. You can continue mapping with a new node.",
  ];

  return <ExternalError message="Unable to map node – Node already mapped" details={details} />;
};

export default NodeAlreadyMappedError;
