import { Menu } from "antd";
import { useUserLocationMetadataStats } from "hooks/use-location-metadata";
import useRoleAndPermission from "hooks/use-role-and-permission";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LocationSubscription } from "utils/locationMetadata";
import useBreakpoint from "../../hooks/use-breakpoint";
import { Permission } from "../../pacts/permission";

const { Item } = Menu;

const LeftMenu = (props: { theme: any; onItemClick?: any }) => {
  const { theme, onItemClick } = props;
  const screen = useBreakpoint();
  const { pathname } = useLocation();
  const { isPC, canPerform, isCustomer, isWS } = useRoleAndPermission();
  const metadataStats = useUserLocationMetadataStats({
    subscriptions: [LocationSubscription.SMART_ALLOC, LocationSubscription.SMART_ALLOC_PRO],
  });
  return (
    <Menu
      mode={screen.desktopUp ? "horizontal" : "inline"}
      theme={theme}
      onClick={onItemClick}
      selectedKeys={[pathname.split("/")[1]]}
      style={{ minWidth: 0, flex: "auto" }}
    >
      {canPerform(Permission.LOCATION_VIEW_ALL) && (
        <Item key="locations">
          <Link to="/locations">Locations</Link>
        </Item>
      )}
      {canPerform(Permission.USER_VIEW) && (
        <Item key="users">
          <Link to="/users">Users</Link>
        </Item>
      )}
      {isCustomer() && metadataStats?.stats.data?.count ? (
        <Item key="smart-alloc">
          <Link to="/smart-alloc">Smart Alloc</Link>
        </Item>
      ) : null}
      {isPC() && (
        <Item key="ota">
          <Link to="/ota">OTA</Link>
        </Item>
      )}
      {(isWS() || isPC()) && (
        <Item key="node-management">
          <Link to="/node-management">Node management</Link>
        </Item>
      )}
    </Menu>
  );
};

export default LeftMenu;
