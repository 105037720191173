import React from "react";

import { Button } from "antd";

import Table from "components/Table/Table";
import { KeyCategory } from "../KeyCategoryList.d";

const KeyListTable = (props: any) => {
  const { onEditClick } = props;

  const COLUMN_CONFIG = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
    },
    {
      title: "Actions",
      width: 125,
      render: (record: KeyCategory) => (
        <Button type="link" title="Edit Category" onClick={() => onEditClick(record)}>
          Edit Category
        </Button>
      ),
      align: "center",
    },
  ];

  return (
    <Table {...props} columns={COLUMN_CONFIG} dataItemName="key categories" rowKey="categoryName" hidePagination />
  );
};

export default KeyListTable;
