import {
  FilterOption as FilterOptionPact,
  FilterOptionType as FilterOptionTypePact,
  LocationQuery,
  Scalars,
  Maybe,
} from "pacts/app-webcore/hasura-webcore.graphql";

export type Location = LocationQuery["location"];

export type LocationWithPmsProperty = Exclude<Location, undefined> & {
  pms?: {
    pmsId: number;
    propertyId: string;
  };
};

export type CsvHeaderConfig<T = any> = {
  label: string;
  key: string;
  transform?: (entity: T) => string;
};

export interface CsvExportInput {
  headers: Omit<CsvHeaderConfig, "transformer">[];
  data: Record<string, unknown>[];
  fileName?: string;
}

export interface INewrelicBrowserAgent {
  addPageAction(name: string, attribues: Record<string, string | number | boolean>): void;
  noticeError(error: Error, customAttributes?: Record<string, any>): void;
}

export enum ENewrelicCustomEvent {
  KILL_SWITCH_TURN_ON = "Custom:KillSwitch:AutomationModeDisabled",
}

export enum FilterOptionType {
  MultipleSelect = "MultipleSelect",
  Switch = "Switch",
}
export type FilterOption = Omit<FilterOptionPact, "type" | "options"> & {
  type?: Maybe<FilterOptionTypePact | FilterOptionType>;
  options?: Maybe<Array<{ value: any; label: string }>> | Maybe<Array<Scalars["String"]>>;
  placeholder?: Maybe<string>;
  label: React.ReactNode;
  placeholderSearch?: string;
  disabled?: boolean;
};

export enum PositionType {
  KEY = "key",
  COMPRESSOR = "compressor",
  GATEWAY = "gateway",
  LOCATION = "location",
  GROUP = "group",
  ROOM = "room",
  SLOT = "slot",
  METER_POSITION = "meter_position",
  FLOOR = "floor",
  ZONE = "zone",
}
