/* eslint-disable no-param-reassign */
import { Affix, Button, Col, message, Row, Typography } from "antd";
import { CalendarIcon, StartButtonIcon } from "components/Icons";
import { WarningUseMobileOnly } from "components/WarningUseMobileOnly";
import useBreakpoint from "hooks/use-breakpoint";
import moment from "moment";
import {
  Sensorflow_V_Comfortplus_Precool_Status,
  useUpcomingPrecoolSchedulesSubscription,
  useComfortplusKeyDetailsQuery,
  useComfortplusKeyMeasurementsSubscription,
  useComfortPlusPrecoolStatusSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { PreCoolStatus } from "pages/Key/CustomerKeyList/KeyList.d";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { usePublicRouteContext } from "App/PublicRoutes";
import { PrecoolSchedule } from "pages/PrecoolSchedule";
import { useIntercom } from "react-use-intercom";

import NoteCard from "../../components/NoteCard/NoteCard";
import "./ComfortPlus.scss";

import InvalidUrl from "./InvalidUrl";
import { Onboarding } from "./Onboarding";
import { useSetComfortPlusParams } from "./use-comfortplus-params";
import { UpcomingPreCool, UpcomingPreCoolProps } from "./components/UpcomingPreCool";
import ChooseRoomToPrecoolModal, {
  ListRoomWithAmbientTemp,
} from "./components/ChooseRoomToPrecoolModal/ChooseRoomToPrecoolModal";
import OnDemandPrecoolRooms from "./components/OnDemandPrecoolRooms";
import { PrecoolStatuses } from "./components/OnDemandPrecoolRooms.d";
import ConfirmPreCoolModal from "./components/ConfirmPreCoolModal/ConfirmPreCoolModal";
import { PrecoolKeyStatus, PrecoolOverallStatus, PrecoolRoomStatus } from "./components/PrecoolOverallStatus";

const NOTECARD_HEADER = "Reducing AC usage supports";
const NOTECARD_POINTS = [
  "Global efforts to combat climate change",
  "Ensuring a sustainable and habitable planet for future generations",
];

const ComfortPlus = () => {
  const history = useHistory();
  const { path: currentPath } = useRouteMatch();
  const { accessToken } = useSetComfortPlusParams();
  const breakpoint = useBreakpoint();
  const [overallStatus, setOverallStatus] = useState<PrecoolKeyStatus>();
  const [precoolExpirationAt, setPrecoolExpirationAt] = useState<string>("");
  const [upcomingScheduledPrecools, setUpcomingScheduledPrecools] = useState<
    UpcomingPreCoolProps["upcomingScheduledPrecools"]
  >([]);
  const [listRoomWithAmbientTemp, setListRoomWithAmbientTemp] = useState<ListRoomWithAmbientTemp>([]);
  const [openChooseRoomToPrecoolModal, setOpenChooseRoomToPrecoolModal] = useState<boolean>(false);
  const [openConfirmPrecoolModal, setOpenConfirmPrecoolModal] = useState<boolean>(false);
  const [nextScheduleInMinutes, setNextScheduleInMinutes] = useState<number>();
  const [timezone, setTimezone] = useState<string>();

  const [precoolingRooms, setPrecoolingRooms] = useState<PrecoolStatuses>([]);
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot();
    return () => {
      shutdown();
    };
  }, [boot, shutdown]);

  const handleReOnboarding = () => {
    history.push(`${currentPath}/onboarding`);
  };

  const goToSchedules = () => {
    history.push(`${currentPath}/precool-schedule`);
  };

  const { data, error: keyDetailsError } = useComfortplusKeyDetailsQuery({
    skip: !accessToken,
    onCompleted: (result) => {
      if (!result) return;
      const isOnboarded = result?.data[0]?.onboarded;
      setTimezone(result?.data[0].timezone ?? "");
      if (!isOnboarded) handleReOnboarding();
    },
  });

  const { isOnboarding } = usePublicRouteContext();

  const shouldShowWarningOnDesktop = useMemo<boolean>(() => {
    return !breakpoint.mobileAndTabletOnly;
  }, [breakpoint.mobileAndTabletOnly]);

  const coolingStatus = (room: any) => {
    if (room.precoolPendingCount > 0) {
      return "cooling";
    }
    if (room.lastPrecoolSuccessTime && moment(room.lastPrecoolSuccessTime).add(5, "minutes").isAfter(moment())) {
      return "cooled";
    }
    return "";
  };

  useComfortplusKeyMeasurementsSubscription({
    skip: !accessToken,
    onSubscriptionData: ({ subscriptionData }) => {
      setListRoomWithAmbientTemp(subscriptionData?.data?.data ?? []);

      if (subscriptionData?.data?.data && subscriptionData?.data?.data.length > 0) {
        let sumTemp = 0;
        const rooms: PrecoolRoomStatus[] = [];
        subscriptionData?.data?.data.forEach((room) => {
          rooms.push({
            name: room.roomName ?? "",
            status: coolingStatus(room),
            acModeCoolOn: room.acMode === 1,
            fanOn: room.fanSpeed > 0,
            scheduleOn: room.scheduledCount > 0,
            temp: room.ambientTemperature,
          });
          sumTemp += room.ambientTemperature;
        });

        setOverallStatus({
          keyName: data?.data[0].positionName ?? "",
          averageTemp: sumTemp / subscriptionData.data.data.length,
          rooms,
        });
      }
    },
  });

  const { loading: precoolStatusLoading } = useComfortPlusPrecoolStatusSubscription({
    skip: !accessToken,
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data?.sensorflow_v_comfortplus_precool_status) {
        return;
      }

      setPrecoolingRooms(
        subscriptionData.data?.sensorflow_v_comfortplus_precool_status.filter((room) =>
          [PreCoolStatus.ACTIVE, PreCoolStatus.ACCEPTED].includes(room.status as PreCoolStatus)
        )
      );

      const { finalStatus, precoolCommand } = subscriptionData.data.sensorflow_v_comfortplus_precool_status.reduce<{
        precoolCommand: Sensorflow_V_Comfortplus_Precool_Status | null;
        finalStatus: PreCoolStatus | null;
        // eslint-disable-next-line
      }>(
        (prev, precoolCmd) => {
          if (prev.finalStatus === PreCoolStatus.ACTIVE) {
            return prev;
          }
          // if there are still running precools => showing the timer
          if ([PreCoolStatus.ACTIVE, PreCoolStatus.ACCEPTED].includes(precoolCmd.status as PreCoolStatus)) {
            prev.precoolCommand = precoolCmd;
            prev.finalStatus = PreCoolStatus.ACTIVE;
            return prev;
          }

          // if failed, then the overall status is failed => show the error message
          if (
            [PreCoolStatus.FAILED, PreCoolStatus.SUCCESS, PreCoolStatus.CANCEL, PreCoolStatus.ERROR].includes(
              precoolCmd.status as PreCoolStatus
            )
          ) {
            prev.precoolCommand = precoolCmd;
            prev.finalStatus = precoolCmd.status as PreCoolStatus;
            return prev;
          }

          return prev;
        },
        {
          precoolCommand: null,
          finalStatus: null,
        }
      );

      const currentDate = moment();

      switch (finalStatus) {
        case PreCoolStatus.ACTIVE: {
          const expirationTime = moment(precoolCommand?.startedAt).add(precoolCommand?.totalMinutes, "minutes");
          setPrecoolExpirationAt(expirationTime?.format() ?? "");
          break;
        }
        case PreCoolStatus.ERROR:
        case PreCoolStatus.FAILED: {
          if (moment(precoolCommand?.startedAt).add(5, "minutes").isAfter(currentDate)) {
            message.error("Sorry, we are unable to pre-cool your room at the moment. Please try again later.");
          }
          setPrecoolExpirationAt("");
          break;
        }
        case PreCoolStatus.SUCCESS: {
          if (moment(precoolCommand?.startedAt).add(5, "minutes").isAfter(currentDate)) {
            message.success(
              <div>
                <p className="font-weight-bold mb-s">Precool completed</p>
                <p>Your room was cooled successfully</p>
              </div>
            );
          }
          setPrecoolExpirationAt("");
          break;
        }
        default:
      }
    },
  });

  useUpcomingPrecoolSchedulesSubscription({
    skip: !accessToken,
    onSubscriptionData({ subscriptionData }) {
      if (subscriptionData?.data?.sensorflow_precool_commands) {
        setUpcomingScheduledPrecools(subscriptionData?.data?.sensorflow_precool_commands || []);
        if (subscriptionData?.data?.sensorflow_precool_commands.length > 0) {
          setNextScheduleInMinutes(
            moment(moment.now()).diff(subscriptionData?.data?.sensorflow_precool_commands[0].startedAt, "m") * -1
          );
        }
      }
    },
  });

  const handleOnDemandPrecool = useCallback(() => {
    if (nextScheduleInMinutes && nextScheduleInMinutes <= 15) {
      setOpenConfirmPrecoolModal(true);
    } else setOpenChooseRoomToPrecoolModal(true);
  }, [setOpenConfirmPrecoolModal, setOpenChooseRoomToPrecoolModal, nextScheduleInMinutes]);

  const precoolNowConfirmed = useCallback(
    (confirmed: boolean) => {
      setOpenConfirmPrecoolModal(false);
      if (confirmed) {
        setOpenChooseRoomToPrecoolModal(true);
      }
    },
    [setOpenConfirmPrecoolModal, setOpenChooseRoomToPrecoolModal]
  );

  if (!accessToken || keyDetailsError) return <InvalidUrl />;

  return shouldShowWarningOnDesktop ? (
    <WarningUseMobileOnly />
  ) : (
    <Switch>
      <Route path={`${currentPath}/`} exact>
        <div className="precool-comfortPlus-content d-flex flex-column justify-content-between">
          <Row>
            <Col span={24}>
              <Row gutter={[20, 12]}>
                <Col span={24}>
                  <Button
                    type="text"
                    onClick={handleReOnboarding}
                    className="px-none text-primary font-weight-semi-bold"
                  >
                    Back to Onboarding
                  </Button>
                </Col>
                <Col span={24}>
                  {overallStatus?.rooms && overallStatus?.rooms?.length > 0 && (
                    <PrecoolOverallStatus precoolKeyStatus={overallStatus} />
                  )}
                </Col>
              </Row>
              <div className="info-text fs-m text-center word-wrap-break mt-m">
                {precoolExpirationAt && (
                  <Typography.Title level={4} className="text-center mb-s">
                    Thank you for using ComfortPlus to pre-cool your room!
                  </Typography.Title>
                )}
                {!!upcomingScheduledPrecools?.length && (
                  <UpcomingPreCool upcomingScheduledPrecools={upcomingScheduledPrecools} timezone={timezone ?? ""} />
                )}
                You can pre-cool your room before you arrive. This allows you to enjoy a comfortable room when you need
                it most, while also contributing to our shared goal of sustainability.
              </div>
              <div className="d-flex flex-column">
                {precoolExpirationAt && <OnDemandPrecoolRooms precoolingRooms={precoolingRooms} />}
                <NoteCard className="mt-32" header={NOTECARD_HEADER} items={NOTECARD_POINTS} />
              </div>
            </Col>
          </Row>
          {!isOnboarding && (
            <Affix className="w-100" style={{ position: "sticky", bottom: 0 }}>
              <div className="w-100 h-100 py-m mt-m bg-white flex-column">
                <Button
                  type="primary"
                  className="w-100 h-100 px-xs py-s radius-8 mb-s"
                  icon={<StartButtonIcon className="mr-xs" />}
                  disabled={precoolStatusLoading || !!precoolExpirationAt}
                  onClick={handleOnDemandPrecool}
                >
                  Pre-cool my room
                </Button>
                <Button
                  type="default"
                  className="w-100 h-100 px-xs py-s radius-8"
                  icon={<CalendarIcon className="mr-xs" />}
                  onClick={goToSchedules}
                >
                  Set schedule
                </Button>
              </div>
            </Affix>
          )}
        </div>
        <ChooseRoomToPrecoolModal
          isChooseRoomToPrecoolModalOpen={openChooseRoomToPrecoolModal}
          setIsChooseRoomToPrecoolModalOpen={setOpenChooseRoomToPrecoolModal}
          listRoomWithAmbientTemp={listRoomWithAmbientTemp}
        />
        <ConfirmPreCoolModal
          nextScheduleInMinutes={nextScheduleInMinutes ?? 0}
          isOpen={openConfirmPrecoolModal}
          onConfirmed={precoolNowConfirmed}
        />
      </Route>

      <Route path={`${currentPath}/onboarding`} exact>
        <Col span={24}>
          <Onboarding comfortplusKeyDetail={data?.data[0]} />
        </Col>
      </Route>

      <Route path={`${currentPath}/precool-schedule`} exact>
        <PrecoolSchedule />
      </Route>
    </Switch>
  );
};

export default ComfortPlus;
