/* eslint-disable @typescript-eslint/no-use-before-define */
import { Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  FilterOption,
  LocationFilterInput,
  LocationsForCustomerQuery,
  LocationSortInput,
  LocationsQuery,
  SortOptions,
  useLocationsForCustomerQuery,
  useLocationsWithNodeHealthAndTasksLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { AntdTablePagination } from "components/Table/Table.d";
import CreateLocationModal from "components/Location/CreateLocationModal";
import { Permission } from "pacts/permission";
import { useHistory } from "react-router-dom";
import { Location } from "./LocationList.d";
import LocationListTable from "./LocationListTable/LocationListTable";
import "./LocationList.scss";
import useMenuNavigation from "../../../hooks/use-menu-navigation";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import { getPaginationMessage } from "../Location.helper";
import LocationSettingsModal from "./LocationSettingsModal/LocationSettingsModal";
import { LocationHeader } from "./LocationHeader";

const LocationList = () => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [tableData, setTableData] = useState<Location[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [sort, setSort] = useState<LocationSortInput>({ locationName: SortOptions.Asc });
  const [filter, setFilter] = useState<LocationFilterInput>({});
  const [editLocation, setEditLocation] = useState<Location | undefined>();
  const [editShowEnergyConsumptionLocation, setEditShowEnergyConsumptionLocation] = useState<Location | undefined>();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const menuNavigation = useMenuNavigation();
  const history = useHistory();

  const roleAndPermission = useRoleAndPermission();

  const canCreateLocation = roleAndPermission.canPerform(Permission.LOCATION_CREATE);

  const queryVariables = {
    filter,
    sort,
    pagination: {
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
  };

  const handleCompletedDataFetching = async (
    data: LocationsQuery | LocationsForCustomerQuery,
    shouldFetchFullLocations = false
  ) => {
    setTableData(data.locationsAssignedToUser?.locations || []);
    setFilterOptions(data.locationsAssignedToUser?.filterOptions || []);
    // we're only interested in the total from the server, the current and pageSize are managed in local state
    setPagination({
      ...pagination,
      total: data.locationsAssignedToUser?.pagination?.count || 0,
    });
    menuNavigation.setupLocationMenu(data.locationsAssignedToUser?.locations);

    if (shouldFetchFullLocations) {
      if (typeof refetchLocationsWithNodeHealth === "function") {
        const locationsWithNodeHealth = await refetchLocationsWithNodeHealth();
        handleCompletedDataFetching(locationsWithNodeHealth.data);
      } else {
        fetchLocationsWithNodeHealth();
      }
    }
  };

  const { loading, error, refetch } = useLocationsForCustomerQuery({
    variables: queryVariables,
    onCompleted: (data) => handleCompletedDataFetching(data, true),
  });

  const [fetchLocationsWithNodeHealth, { refetch: refetchLocationsWithNodeHealth }] =
    useLocationsWithNodeHealthAndTasksLazyQuery({
      variables: queryVariables,
      onCompleted: handleCompletedDataFetching,
    });

  const handleTableChange = (
    desiredPagination: AntdTablePagination,
    _filters: any,
    _sorter: any,
    extra: { action: string }
  ) => {
    if (extra.action === "paginate") {
      setPagination(desiredPagination);
    }
  };

  const statMessage = useMemo(() => {
    if (tableData.length === 0) return "";

    return getPaginationMessage(pagination);
  }, [tableData, pagination]);

  const handleSortChange = (columnHeader: keyof LocationSortInput) => {
    setSort({ [columnHeader]: sort[columnHeader] === SortOptions.Asc ? SortOptions.Desc : SortOptions.Asc });
  };

  const handleFilterChange = (newFilter: LocationFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  const handleEditClick = (location: Location) => {
    setEditLocation(location);
  };

  const handleEditShowEnergyConsumptionClick = (location: Location) => {
    setEditShowEnergyConsumptionLocation(location);
  };

  const refetchLocations = async () => {
    const data = await refetch(queryVariables);
    handleCompletedDataFetching(data.data, true);
  };

  useEffect(() => {
    if (tableData) {
      menuNavigation.setupLocationMenu(tableData);
    }

    if (roleAndPermission.isContractor() && tableData.length === 1 && !filter?.locationName) {
      const { id } = tableData[0];
      history.push(`/locations/${id}/jobs`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <>
      <LocationHeader
        statMessage={statMessage}
        filter={filter}
        filterOptions={filterOptions}
        onClickAddLocation={() => setOpenCreateModal(true)}
        onFilterChange={handleFilterChange}
      />

      <Row>
        <LocationListTable
          tableData={tableData}
          onEditClick={handleEditClick}
          onEditShowEnergyConsumptionClick={handleEditShowEnergyConsumptionClick}
          pagination={{
            ...pagination,
            pageSizeOption: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
          handleTableChange={handleTableChange}
          loading={loading}
          error={error}
          sortBy={handleSortChange}
          sort={sort}
        />
      </Row>
      {editShowEnergyConsumptionLocation && (
        <LocationSettingsModal
          closeModal={() => setEditShowEnergyConsumptionLocation(undefined)}
          locationId={editShowEnergyConsumptionLocation.id}
        />
      )}
      <CreateLocationModal
        visible={!!editLocation}
        closeModal={() => setEditLocation(undefined)}
        locationId={editLocation?.id}
        refetchLocations={refetchLocations}
      />
      {canCreateLocation && (
        <CreateLocationModal visible={openCreateModal} closeModal={() => setOpenCreateModal(false)} />
      )}
    </>
  );
};

export default LocationList;
