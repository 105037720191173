import { TreeSelect } from "antd";
import { TreeNode } from "antd/lib/tree-select";
import { getOtaNodeTypeDisplayValue } from "pages/Ota/OtaDetails/OtaDetails.helper";
import React from "react";
import { FwVersionSelectorProps } from "./FwVersionSelector.d";

const FwVersionSelector = (props: FwVersionSelectorProps) => {
  const { disabled, value, onChange, groups, valueKey = "versionNumber" } = props;

  return (
    <TreeSelect
      disabled={disabled}
      showSearch
      className="w-100"
      value={value}
      placeholder="Please select"
      allowClear
      treeDefaultExpandAll
      onChange={onChange}
    >
      <TreeNode value="types" title="Node Types" selectable={false}>
        {Object.keys(groups).map((g) => {
          return (
            <TreeNode value={g} title={getOtaNodeTypeDisplayValue(g)} selectable={false} key={g}>
              {groups[g]
                .sort((a, b) =>
                  a.versionName.localeCompare(b.versionName, undefined, { numeric: true, sensitivity: "base" })
                )
                .map((l) => (
                  <TreeNode
                    key={`${g}-${l[valueKey]}`}
                    value={l[valueKey]}
                    title={`${l.versionName}${l.isDeprecated ? " (Outdated Version)" : ""}`}
                  />
                ))}
            </TreeNode>
          );
        })}
      </TreeNode>
    </TreeSelect>
  );
};

export default FwVersionSelector;
