import { Doughnut } from "components/Chart";
import { isUndefined } from "lodash";
import React from "react";
import { formatNumber } from "utils/number";
import styles from "../../../styles/export.module.scss";

type Props = {
  value?: number;
};

const OccupancyChart = (props: Props) => {
  const { value } = props;
  const isEmpty = isUndefined(value);

  const data = {
    labels: ["Occupied", "Unoccupied"],
    datasets: [
      {
        label: "My First Dataset",
        data: [formatNumber(value), formatNumber(100 - (value || 0))],
        backgroundColor: [styles.darkOrange, styles.white],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        tooltips: {
          enabled: !isEmpty,
        },
      }}
    />
  );
};

export default OccupancyChart;
