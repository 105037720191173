import { OtaNodeTypes } from "../../OtaDetails/OtaDetails.d";
import { FirmwareRelease } from "../FirmwareList/FirmwareList.d";

export enum CreateFirmwareFormFieldKey {
  DOWNLOAD_LINK = "downloadLink",
  VERSION_NUMBER = "versionNumber",
  VERSION_NAME = "versionName",
  COMMENT = "comment",
}

export interface CreateFirmwareModalProps {
  closeModal: () => void;
  visible: boolean;
  nodeSubType: OtaNodeTypes;
  firmware?: FirmwareRelease | null;
  reloadFirmwareList: () => void;
}

export interface FirmwareInput {
  [CreateFirmwareFormFieldKey.COMMENT]: string | null;
  [CreateFirmwareFormFieldKey.VERSION_NAME]: string;
  [CreateFirmwareFormFieldKey.DOWNLOAD_LINK]: string;
  [CreateFirmwareFormFieldKey.VERSION_NUMBER]: number;
}

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}
