import React, { useEffect, useState } from "react";
import { Input, Tabs } from "antd";
import { isValidNodeMacId } from "components/NodeMapping/NodeMapping";
import ScannedNodeMacId from "pages/InfrastructureDetails/MappedNodes/MapNodeModal/ScannedNodeMacId";
import classNames from "classnames";

const FULL_NODE_MAC_ID_LENGTH = 18;
const NODE_MAC_ID_LENGTH = 16;

export const InternalError = (props: { errorMessage: string; className?: string }) => {
  const { errorMessage, className } = props;
  return <p className={classNames("internal-error", className)}>{errorMessage}</p>;
};
interface NodeMacInputProps {
  setNodeMacInput: (nodeMac: string) => void;
  defaultActiveKey?: string;
  disabledScan?: boolean;
  disabledInput?: boolean;
  value?: string;
  autoFocus?: boolean;
  needTrimFirstTwoDigit?: boolean;
  setInvalidInputData?: any;
  isValidNodeData?: boolean;
}
export default function NodeMacInput({
  setNodeMacInput,
  defaultActiveKey = "1",
  disabledScan,
  disabledInput,
  value,
  autoFocus,
  needTrimFirstTwoDigit,
  setInvalidInputData,
  isValidNodeData,
}: NodeMacInputProps) {
  const [nodeMacId, setNodeMacId] = useState(value);
  const [internalError, setInternalError] = useState<any>();

  useEffect(() => {
    setNodeMacId(value);
  }, [value]);

  const handleNodeMacId = (data: any) => {
    if (data) {
      let nodeMacInput = (data || "").toUpperCase();
      if (needTrimFirstTwoDigit && data.length === FULL_NODE_MAC_ID_LENGTH) {
        nodeMacInput = data.substring(2);
      }
      setNodeMacInput(nodeMacInput);
      setNodeMacId(nodeMacInput);
      if (setInvalidInputData) {
        if (nodeMacInput.length !== NODE_MAC_ID_LENGTH || !isValidNodeMacId(nodeMacInput)) {
          setInvalidInputData(true);
          setInternalError("Invalid Node Mac ID");
        } else {
          setInvalidInputData(false);
          setInternalError(null);
        }
      }
    } else {
      setNodeMacInput(data);
      setNodeMacId(data);
    }
  };

  const handleNodeMacInputChange = (e: any) => {
    handleNodeMacId(e.target.value);
  };

  const handleQRCodeScan = (data: any) => {
    if (!data) return;
    handleNodeMacId(data);
  };

  return (
    <div className="scan-node-wrapper">
      <Tabs defaultActiveKey={defaultActiveKey}>
        <Tabs.TabPane tab="Scan with camera" key="1" data-testid="scan-with-camera" disabled={disabledScan}>
          {nodeMacId && <p>Node Mac Id: {nodeMacId}</p>}
          {(!nodeMacId || !isValidNodeData) && <ScannedNodeMacId handleQRCodeScan={handleQRCodeScan} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Enter Node ID" key="2" disabled={disabledInput}>
          <h4>Type Node ID:</h4>
          <Input
            placeholder={needTrimFirstTwoDigit ? "FFFF14B4570DF953" : "01FFFF14B4570DF953"}
            size="large"
            id="mapping-input"
            onChange={handleNodeMacInputChange}
            value={nodeMacId}
            autoFocus={autoFocus}
          />
        </Tabs.TabPane>
      </Tabs>
      {internalError && <InternalError errorMessage={internalError} />}
    </div>
  );
}
