import { EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Menu, message, Row, Typography } from "antd";
import confirm from "antd/lib/modal/confirm";
import Link from "antd/lib/typography/Link";
import classnames from "classnames";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { assign, get, isNil, set } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ActionButton from "../../../components/ActionButton";
import Can from "../../../components/Can/Can";
import CopyButton from "../../../components/CopyButton";
import LiveValue from "../../../components/LiveValue";
import MenuDropdown from "../../../components/MenuDropdown";
import showModal from "../../../components/Modal/show";
import errorHandler from "../../../errorHandler";
import useBreakpoint from "../../../hooks/use-breakpoint";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import {
  MappingStatus,
  NodeType,
  useDeletePositionMutation,
  useGatewaysQuery,
  useRoomNodeStatusSubscription,
  useTemporaryRoomNodeMappingsQuery,
  useUpdatePrimaryNodeMutation,
  useUpsertPositionGatewayMutation,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "../../../pacts/permission";
import { roomDashboardLink } from "../key.helper";
import CreateSlotFormModal from "./CreateSlotFormModal";
import {
  ExtendedRoom,
  ExtendedSlot,
  Key,
  Room,
  RoomMetaData,
  RoomSubscription,
  SlotMapping,
  SlotMappingMetaData,
} from "./KeyDetail.d";
import RenameRoomFormModal from "./RenameRoomFormModal";
import SelectGatewayModal from "./SelectGatewayModal";
import SelectPrimaryNodeModal from "./SelectPrimaryNodeModal";
import SlotComponent from "./SlotComponent";

type AirconMapping = {
  [key: string]: boolean;
};

const RoomWithSlots = (props: {
  locationId: string;
  keyDetail: Key;
  reloadKey: any;
  reloadKeyBinding: any;
  locationName: string | undefined;
}) => {
  const { keyDetail, reloadKey, reloadKeyBinding, locationId, locationName } = props;
  const { Title } = Typography;

  const [selectedRoom, setSelectedRoom] = useState<Room>();
  const [isRenameRoomModalOpen, setIsRenameRoomModalOpen] = useState<boolean>(false);
  const [isCreateSlotModalOpen, setIsCreateSlotModalOpen] = useState<boolean>(false);
  const [hasSubscriptionData, setHasSubscriptionData] = useState<boolean>(false);
  const [subscriptionData, setSubscriptionData] = useState<RoomSubscription[]>([]);
  const [metaData, setMetaData] = useState<RoomMetaData[]>([]);
  const [extendedRooms, setExtendedRooms] = useState<ExtendedRoom[]>([]);
  const [gateways, setGateways] = useState<any[]>([]);
  const [hasAircon, setHasAircon] = useState<AirconMapping>({});
  const [forceRetrigger, setForceRetrigger] = useState<boolean>(false);
  const { currentKeyEntryOfInstaller } = useContext(InstallationModeContext);
  const roleAndPermission = useRoleAndPermission();

  const retriggerSubscription = () => {
    setForceRetrigger((prev) => !prev);
  };

  const setRoomStatus = (room: string, status: boolean) => {
    setHasAircon((prevStatus) => ({
      ...prevStatus,
      [room]: status,
    }));
  };

  const resetRoomStatus = () => {
    const resetStatus = Object.keys(hasAircon).reduce((acc, room) => {
      acc[room] = false;
      return acc;
    }, {} as AirconMapping);

    setHasAircon(resetStatus);
  };

  const rebuildRoomList = () => {
    const subscriptionDataDict = new Map<string, RoomSubscription>();
    resetRoomStatus();
    (subscriptionData ?? []).forEach((sd) => {
      subscriptionDataDict.set(sd.positionId, sd);
    });

    const metaDataDict = new Map<string, RoomMetaData>();
    (metaData ?? []).forEach((sd) => {
      metaDataDict.set(sd.positionId, sd);
    });

    setExtendedRooms(
      keyDetail.rooms.map((r) => {
        const roomSubData = subscriptionDataDict.has(r.roomId) ? subscriptionDataDict.get(r.roomId) : null;
        const roomMetaData = metaDataDict.has(r.roomId) ? metaDataDict.get(r.roomId) : null;
        const primaryNodes = roomMetaData
          ? roomMetaData.slotMappings.filter((sm: SlotMappingMetaData) => sm.isPrimaryNode)
          : [];
        const slotMappingsDict = new Map<string, SlotMapping>();
        r.slots.forEach((slot) => {
          if (slot?.nodeType === NodeType.Aircon.toString()) {
            setRoomStatus(r.roomId, true);
          }
        });
        if (roomSubData) {
          roomSubData.slotMappings.forEach((s: SlotMapping) => {
            if (s.node) {
              slotMappingsDict.set(s.slot!.id, s);
            }
          });
        }
        const roomMetaDataDict = new Map<string, SlotMappingMetaData>();
        if (roomMetaData) {
          roomMetaData.slotMappings.forEach((s: SlotMappingMetaData) => {
            if (s.node) {
              roomMetaDataDict.set(s.slot!.id, s);
            }
          });
        }
        return {
          room: r,
          extras: roomMetaData,
          subscriptionData: roomSubData,
          primaryNode: primaryNodes.length > 0 ? primaryNodes[0] : null,
          gateway:
            roomMetaData && roomMetaData.gatewayMapping.length > 0 ? roomMetaData.gatewayMapping[0].gateway : null,
          slots: r.slots.map((s) => {
            const slotSubData = slotMappingsDict.has(s.id) ? slotMappingsDict.get(s.id) : null;
            const slotMetaData = roomMetaDataDict.has(s.id) ? roomMetaDataDict.get(s.id) : null;
            let signalStrength = slotSubData ? get(slotSubData, "node.nodeJoinStrengthLive.signalStrength") : null;
            if (!isNil(signalStrength)) {
              signalStrength = parseFloat(signalStrength).toFixed(2);
            }
            let status;
            if (slotSubData) {
              status = currentKeyEntryOfInstaller
                ? get(slotSubData, "node.nodeOnlineStatus.nodeStatusInInstallationMode")
                : get(slotSubData, "node.nodeOnlineStatus.nodeStatus");
            }
            return {
              slot: s,
              mappedTime: slotMetaData ? slotMetaData.mappedTime : null,
              status,
              lastSeen: slotSubData ? get(slotSubData, "node.nodeOnlineStatus.lastTransmission") : null,
              recentJoinCount: slotMetaData ? get(slotMetaData, "node.nodeMetaStatus.recentJoinCount") : null,
              bootTime: slotMetaData ? get(slotMetaData, "node.nodeMetaStatus.bootTime") : null,
              firmwareVersion: slotMetaData ? get(slotMetaData, "node.nodeMetaStatus.firmwareVersion") : null,
              signalStrength,
            };
          }),
        };
      })
    );
  };

  const { refetch } = useTemporaryRoomNodeMappingsQuery({
    skip: !(roleAndPermission.isInstaller() || roleAndPermission.isPC()),
    variables: {
      keyId: keyDetail.keyId,
    },
    onCompleted: (data) => {
      if (data) {
        setMetaData(data.positions);
      }
    },
    onError: errorHandler.handleError,
  });

  const reloadMetaData = async () => {
    const data = await refetch();
    setMetaData(data.data.positions);
  };

  useEffect(() => {
    rebuildRoomList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData, keyDetail, metaData, currentKeyEntryOfInstaller]);

  useEffect(() => {
    if (forceRetrigger) {
      setForceRetrigger(false);
    }
  }, [forceRetrigger]);

  const [deleteRoomMutation] = useDeletePositionMutation({
    onCompleted: (data: any) => {
      const deletedRoom = keyDetail.rooms.find((r) => r.roomId === data.deletePosition.positionId);
      if (deletedRoom) {
        message.info(`Room ${deletedRoom.roomName} has been deleted.`);
        reloadKey();
      }
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const deleteRoom = (room: Room) => {
    confirm({
      icon: <ExclamationCircleFilled className="text-warning" />,
      content: `Are you sure you wish to delete ${room?.roomName}?`,
      okText: `Delete ${room?.roomName}`,
      okButtonProps: { className: "btn-popup-primary" },
      onOk: () => {
        deleteRoomMutation({
          variables: {
            positionId: room.roomId,
          },
        });
      },
    });
  };

  const [upsertPositionGatewayMutation] = useUpsertPositionGatewayMutation({
    onCompleted: async () => {
      message.success("Gateway has been updated.");
      await reloadMetaData();
    },
    onError: (error: any) => {
      errorHandler.handleError(error);
    },
  });

  useRoomNodeStatusSubscription({
    skip: !(roleAndPermission.isInstaller() || roleAndPermission.isPC()) || forceRetrigger,
    variables: {
      keyId: keyDetail.keyId,
      filterSlotMappings: { decomissionedTime: { _is_null: true } },
    },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data) {
        setHasSubscriptionData(true);
        setSubscriptionData(data.subscriptionData.data.positions);
      }
    },
  });

  const updateStatus = (mappingStatus: MappingStatus) => {
    if (mappingStatus) {
      reloadKeyBinding(assign(keyDetail, { keyStats: { mappingStatus } }));
    }
  };

  const mappedCountUpdated = (value: any) => {
    set(keyDetail, "keyStats.nodesStats.mappedCount", value);
    reloadKeyBinding(keyDetail);
  };

  const renameRoom = (room: Room) => {
    setSelectedRoom(room);
    setIsRenameRoomModalOpen(true);
  };

  const startCreateSlot = (room: Room) => {
    setSelectedRoom(room);
    setIsCreateSlotModalOpen(true);
  };

  useGatewaysQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setGateways(data.gateways);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const startChangeGateway = (room: Room) => {
    const extendedRoom = extendedRooms.find((er) => er.room.roomId === room.roomId);
    if (!extendedRoom) return;
    showModal({
      element: SelectGatewayModal,
      config: {
        gateways,
        defaultValue: extendedRoom?.gateway?.gatewayId,
        onOk: (gatewayId: any) => {
          upsertPositionGatewayMutation({
            variables: {
              positionId: room.roomId,
              gatewayId,
            },
          });
        },
      },
    });
  };

  const [updatePrimaryNodeMutation] = useUpdatePrimaryNodeMutation({
    onCompleted: async () => {
      message.success("Primary node has been updated.");
      await reloadMetaData();
    },
    onError: (error: any) => {
      errorHandler.handleError(error);
    },
  });

  const startEditPrimaryNode = (room: any) => {
    if (!room) return;
    const extendedRoom = extendedRooms.find((er) => er.room.roomId === room.roomId);
    if (!extendedRoom) return;
    showModal({
      element: SelectPrimaryNodeModal,
      config: {
        nodes: extendedRoom?.extras?.slotMappings,
        defaultValue: extendedRoom?.primaryNode?.nodeMacId,
        onOk: (nodeMacId: any) => {
          updatePrimaryNodeMutation({
            variables: {
              positionId: room.roomId,
              nodeMacId,
            },
          });
        },
      },
    });
  };

  const screen = useBreakpoint();

  const roomName = (name: string) => {
    if (!locationName) {
      return name;
    }
    const link = roomDashboardLink(locationId, locationName, keyDetail.keyName, name);
    return (
      <Link href={link} target="_blank">
        {name}
      </Link>
    );
  };

  return (
    <div className="key-details-container my-xl">
      {extendedRooms.map((er: ExtendedRoom, roomIndex: number): React.ReactElement => {
        return (
          <Fragment key={roomIndex}>
            <div className="key-details-room bg-white p-m mt-l" id={`room-${er.room.roomId}`}>
              <div className="room-actions">
                <div className="d-flex flex-wrap w-100">
                  <Title
                    className={classnames("room-name mb-xl", {
                      "w-50 fs-xxl": screen.mobileAndTabletOnly,
                    })}
                    level={2}
                  >
                    {roomName(er.room.roomName)}
                  </Title>
                  <Can
                    requiredPermission={Permission.KEY_EDIT}
                    yes={
                      !roleAndPermission.isContractor() && (
                        <ActionButton
                          data-testid={`rename-room-testId-${er.room.roomId}`}
                          type="edit"
                          btnClassName={screen.classes.hiddenOnMobile}
                          className="fs-xl pl-s mr-xl mb-xl"
                          onClick={() => renameRoom(er.room)}
                        />
                      )
                    }
                  />
                  {(roleAndPermission.isInstaller() || roleAndPermission.isPC()) && (
                    <div
                      className={classnames("d-flex align-items-center mb-xl", {
                        "w-50 fs-xs pr-xl pl-xl justify-content-end": screen.mobileAndTabletOnly,
                      })}
                    >
                      <span className="readonly-text" data-testid={`room-id-${er.room.roomId}`}>
                        {er.room.roomId}
                      </span>
                      <CopyButton btnClassName="p-none" className="fs-l" input={er.room.roomId} />
                    </div>
                  )}
                  {((roleAndPermission.isInstaller() && !roleAndPermission.isContractor()) ||
                    roleAndPermission.isPC()) && (
                    <div
                      className={classnames("pr-xs d-flex align-items-center justify-content-between mb-xl pr-xl", {
                        "w-50 fs-xs": screen.mobileAndTabletOnly,
                      })}
                    >
                      <div>
                        <h4 className="mb-none font-weight-bold">ASSOCIATED GATEWAY</h4>
                        <span className="">
                          <LiveValue value={er.gateway ? er.gateway.gatewayName : null} hasData emptyValue="Not set" />
                        </span>
                      </div>
                      <ActionButton
                        btnClassName="p-none"
                        customIcon={<EditOutlined className="fs-l pl-s" />}
                        onClick={() => startChangeGateway(er.room)}
                        data-testid={`change-gateway-testId-${er.room.roomId}`}
                      />
                    </div>
                  )}
                  {((roleAndPermission.isInstaller() && !roleAndPermission.isContractor()) ||
                    roleAndPermission.isPC()) && (
                    <div
                      className={classnames("pr-xs d-flex align-items-center justify-content-between mb-xl pr-xl", {
                        "w-50 fs-xs": screen.mobileAndTabletOnly,
                      })}
                    >
                      <div>
                        <h4 className="mb-none font-weight-bold">PRIMARY NODE</h4>
                        <span data-testid={`primary-node-value-testId-${er.room.roomId}`}>
                          <LiveValue
                            value={er.primaryNode ? er.primaryNode.slotName : null}
                            hasData
                            emptyValue="Not set"
                          />
                        </span>
                      </div>
                      <ActionButton
                        btnClassName="p-none"
                        customIcon={<EditOutlined className="fs-l pl-s" />}
                        onClick={() => startEditPrimaryNode(er.room)}
                        data-testid={`change-primary-node-testId-${er.room.roomId}`}
                      />
                    </div>
                  )}
                </div>
                <div className={classnames("mb-xl actions-right-mobile")}>
                  <Can
                    requiredPermission={Permission.KEY_EDIT}
                    yes={
                      !roleAndPermission.isContractor() && (
                        <ActionButton
                          data-testid={`delete-room-testId-${er.room.roomId}`}
                          type="delete"
                          btnClassName={screen.classes.hiddenOnMobile}
                          onClick={() => deleteRoom(er.room)}
                          className="pr-m fs-xl"
                        />
                      )
                    }
                  />
                  <Can
                    requiredPermission={Permission.KEY_EDIT}
                    yes={
                      !roleAndPermission.isContractor() && (
                        <Button
                          className={classnames("ant-btn ant-btn-primary", screen.classes.hiddenOnMobile)}
                          onClick={() => startCreateSlot(er.room)}
                        >
                          Add New Slot
                        </Button>
                      )
                    }
                  />
                  <Can
                    requiredPermission={Permission.KEY_EDIT}
                    yes={
                      !roleAndPermission.isContractor() && (
                        <span className={screen.classes.hiddenOnDesktop}>
                          <MenuDropdown
                            menu={
                              <Menu>
                                <Menu.Item onClick={() => renameRoom(er.room)}>Rename Room</Menu.Item>
                                <Menu.Item onClick={() => deleteRoom(er.room)}>Delete Room</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item onClick={() => startCreateSlot(er.room)}>Add New Slot</Menu.Item>
                              </Menu>
                            }
                          />
                        </span>
                      )
                    }
                  />
                </div>
              </div>

              <Row gutter={[20, 0]} className="slots">
                {er.slots.map(
                  (slot: ExtendedSlot, slotIndex: number): React.ReactElement => (
                    <SlotComponent
                      key={slotIndex}
                      room={er.room}
                      slot={slot.slot}
                      extendedSlot={slot}
                      subscriptionLoaded={hasSubscriptionData}
                      updateMappedCount={mappedCountUpdated}
                      updateStatus={updateStatus}
                      slotChanged={() => {
                        reloadKey();
                        retriggerSubscription();
                      }}
                    />
                  )
                )}
              </Row>
            </div>
          </Fragment>
        );
      })}
      {isRenameRoomModalOpen && selectedRoom && (
        <RenameRoomFormModal
          roomId={selectedRoom.roomId}
          roomName={selectedRoom.roomName}
          reloadKey={reloadKey}
          closeModal={() => setIsRenameRoomModalOpen(false)}
        />
      )}
      {isCreateSlotModalOpen && selectedRoom && (
        <CreateSlotFormModal
          roomId={selectedRoom.roomId}
          hasAircon={hasAircon[selectedRoom.roomId]}
          onCompleted={reloadKey}
          closeModal={() => setIsCreateSlotModalOpen(false)}
        />
      )}
    </div>
  );
};

export default RoomWithSlots;
