export interface ClaimKeyButtonProps {
  [x: string]: any;
  keyPositionId: string;
  className?: string;
  text?: string;
  possitionType: string;
  assigneeId?: string;
  status?: [string, "error" | "default" | "warning" | "success" | "processing" | undefined];
}

export enum JobStatus {
  DEFAULT = "default",
  PROCESSING = "processing",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}
