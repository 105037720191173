import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AntdTablePagination } from "components/Table/Table.d";
import {
  KeyTaskFilterInput,
  JobEntryQuery,
  TaskStatus,
  useGetUsesForLocationQuery,
  useJobEntryQuery,
  FilterOptionType as FilterOptionTypePact,
  useGetGroupsForDropdownQuery,
  useGroupTasksQuery,
  useTaskHistoryStreamSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { useLocation, useParams } from "react-router-dom";
import { Checkbox, Col, Form, Row, Space, Switch, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAuth0 } from "services/auth/authService";
import errorHandler from "errorHandler";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { FilterOption, FilterOptionType } from "types/types";
import { GroupType, PositionFunction } from "pages/Groups/Components/GroupModal";
import moment from "moment";
import { isNil } from "lodash";
import { DueDateValue, JobsProps, KeyAndTask } from "./Jobs.d";
import { JobsTable } from "./JobsTable";
import { JobFilter } from "./JobFilter";

const defaultPagination = { current: 1, pageSize: 10, total: 0 };

export type KeyTaskFilter = {
  groups?: string[];
  viewAsGroups?: boolean;
  dueDate?: DueDateValue | number;
} & KeyTaskFilterInput;

const getPreviousFilter = (location: any, locationId: string): KeyTaskFilter => {
  const groups = new URLSearchParams(location.search).get("groups")?.split(",");
  const emails = new URLSearchParams(location.search).get("emails")?.split(",");
  const viewAsGroups = new URLSearchParams(location.search).get("viewAsGroups") === "true";
  const taskStatus = new URLSearchParams(location.search).get("taskStatus") as TaskStatus;
  const keyName = new URLSearchParams(location.search).get("keyName");
  return { groups, emails: emails ?? [], viewAsGroups: viewAsGroups ?? undefined, taskStatus, keyName, locationId };
};

const convertToDateFilterFromOption = (value?: DueDateValue | number) => {
  if (value === DueDateValue.THIS_WEEK)
    return [moment().startOf("isoWeek").toISOString(), moment().endOf("isoWeek").toISOString()];

  if (value === DueDateValue.NEXT_WEEK)
    return [
      moment().add(1, "weeks").startOf("isoWeek").toISOString(),
      moment().add(1, "weeks").endOf("isoWeek").toISOString(),
    ];

  if (value === DueDateValue.OVERDUE) return [null, moment(moment.now()).toISOString()];
  if (value === DueDateValue.TOMORROW)
    return [moment().add(1, "days").startOf("day").toISOString(), moment().add(1, "days").endOf("day").toISOString()];

  return [moment().startOf("day").toISOString(), moment().add(value, "days").endOf("day").toISOString()];
};

const Jobs: React.FC<JobsProps> = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = useLocation();
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [assigneeDropdown, setAssigneeDropdown] = useState<FilterOption["options"]>([]);
  const [groupsDropdown, setGroupsDropdown] = useState<FilterOption["options"]>([]);
  const previousFilter = getPreviousFilter(location, locationId);
  const [filter, setFilter] = useState<KeyTaskFilter>({ ...previousFilter });
  const { user } = useAuth0();
  const [keysAndTask, setKeysAndTask] = useState<KeyAndTask[]>([]);
  const { currentKeyEntryOfPositions = [], claimKeys = [] } = useContext(InstallationModeContext);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);

  const { error: errorFilter } = useGetUsesForLocationQuery({
    variables: { locationId },
    onError: errorHandler.handleError,
    onCompleted: (data) => {
      const assigneeOptions = data?.sensorflow_location_to_user_mapping.map((item) => ({
        label: item.user.name,
        value: item.user.email,
      }));
      setAssigneeDropdown(assigneeOptions);
    },
  });

  useTaskHistoryStreamSubscription({
    skip: !loadingComplete,
    variables: {
      locationId,
      startTrackingTime: moment().utc().format(),
    },
    onSubscriptionData: (data) => {
      const newArr = [...keysAndTask];
      data?.subscriptionData?.data?.sensorflow_v_task_with_key_status_history_stream.forEach((newKeyTask) => {
        if (!keysAndTask.find((currentKeyTask) => currentKeyTask.keyId === newKeyTask.keyId)) {
          newArr.unshift({
            categoryName: newKeyTask.categoryName || "",
            keyId: newKeyTask.keyId || "",
            keyName: newKeyTask.keyName || "",
            totalRooms: newKeyTask.roomsCount || 0,
            possitionType: newKeyTask.keyPositionType || "",
            isNew: true,
          });
        }
        setKeysAndTask(newArr);
      });
    },
  });

  const { error: errorGroupFilter } = useGetGroupsForDropdownQuery({
    skip: !locationId,
    variables: {
      where: {
        locationId: { _eq: locationId },
        positionType: {
          _in: [GroupType.FLOOR, GroupType.GROUP, GroupType.ZONE],
        },
        positionFunction: { _eq: PositionFunction.GROUP },
        deletedAt: { _is_null: true },
      },
    },
    onCompleted: (data) => {
      const groupsOptions = data?.positions.map((position) => {
        return {
          label: position.positionName,
          value: position.positionId,
        };
      });
      setGroupsDropdown(groupsOptions);
    },
  });

  const filterOptions = useMemo(() => {
    return [
      {
        field: "emails",
        count: 0,
        type: FilterOptionType.MultipleSelect,
        options: assigneeDropdown,
        label: "Assignee",
        value: "",
        placeholder: "Choose Assignee",
        placeholderSearch: "Search Assignee",
        disabled: filter.viewAsGroups,
      },
      {
        field: "taskStatus",
        type: FilterOptionTypePact.Select,
        placeholder: "Choose Status",
        options: [
          {
            label: "Done",
            value: TaskStatus.Done,
          },
          {
            label: "In Progress",
            value: TaskStatus.InProgress,
          },
          {
            label: "Pending",
            value: TaskStatus.Pending,
          },
        ],
        count: 0,
        label: "Status",
        value: "",
        disabled: filter.viewAsGroups,
      },
      {
        field: "dueDate",
        type: FilterOptionTypePact.Select,
        placeholder: "Choose Due date",
        options: [
          {
            label: "Overdue",
            value: DueDateValue.OVERDUE,
          },
          {
            label: "Today",
            value: 0,
          },
          {
            label: "Tomorrow",
            value: DueDateValue.TOMORROW,
          },
          {
            label: "This week",
            value: DueDateValue.THIS_WEEK,
          },
          {
            label: "Next week",
            value: DueDateValue.NEXT_WEEK,
          },
          {
            label: "Within 14 days",
            value: 14,
          },
        ],
        count: 0,
        label: "Due date",
        value: "",
        disabled: filter.viewAsGroups,
      },
      {
        field: "groups",
        count: 0,
        type: FilterOptionType.MultipleSelect,
        options: groupsDropdown,
        label: (
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Groups</span>
            <Space>
              View as groups
              <Form.Item name="viewAsGroups" noStyle>
                <Switch
                  defaultChecked={previousFilter.viewAsGroups}
                  onChange={() => setFilter((prev) => ({ ...prev, viewAsGroups: !filter.viewAsGroups }))}
                />
              </Form.Item>
            </Space>
          </div>
        ),
        value: "",
        placeholder: "Choose Groups",
        placeholderSearch: "Search Groups",
      },
    ];
  }, [assigneeDropdown, groupsDropdown, filter, previousFilter]);

  const reloadBinding = (data: JobEntryQuery) => {
    const keyEntriesInProgress = currentKeyEntryOfPositions?.filter((item) => item.startedAt);
    const keysTasks = data?.keysTasks?.keys || [];
    let keysTasksByStatus = keysTasks;
    switch (filter.taskStatus) {
      case TaskStatus.InProgress:
        keysTasksByStatus = keysTasks.filter((task) => {
          return keyEntriesInProgress?.findIndex((item) => item.keyPositionId === task.keyId) >= 0;
        });
        break;
      case TaskStatus.Pending:
        keysTasksByStatus = keysTasks.filter((task) => {
          return claimKeys?.findIndex((item) => item.keyPositionId === task.keyId) >= 0;
        });
        break;
      case TaskStatus.Done:
        keysTasksByStatus = keysTasks.filter((task) => {
          return currentKeyEntryOfPositions?.findIndex((item) => item.keyPositionId === task.keyId) === -1;
        });
        break;
      default:
        break;
    }

    setKeysAndTask(
      keysTasksByStatus.map((item) => {
        return {
          categoryName: item.categoryName,
          keyId: item.keyId,
          keyName: item.keyName,
          totalRooms: item.keyStats.roomsStats.totalCount,
          group: item.group as any,
          possitionType: item.possitionType,
          taskDueDate: item.taskDueDate,
        };
      })
    );
    setPagination({
      ...pagination,
      total: data?.keysTasks?.pagination?.count || 0,
    });
    setLoadingComplete(true);
  };

  const filterDueDate = useMemo(() => {
    let dueDate: (string | null)[] = [];
    if (!isNil(filter?.dueDate)) {
      dueDate = convertToDateFilterFromOption(filter.dueDate);
    }

    return {
      dueDateStartTime: dueDate[0],
      dueDateEndTime: dueDate[1],
    };
    // eslint-disable-next-line
  }, [filter?.dueDate]);

  const { loading, error, refetch } = useJobEntryQuery({
    skip: filter.viewAsGroups,
    variables: {
      filter: {
        locationId,
        emails: filter.emails,
        keyName: filter.keyName,
        groupIDs: filter.groups?.length ? filter.groups : undefined,
        ...filterDueDate,
      },
      pagination: {
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      },
    },
    onCompleted: reloadBinding,
    onError: errorHandler.handleError,
  });

  const { loading: groupLoading, error: errorGroupTasks } = useGroupTasksQuery({
    skip: !filter.viewAsGroups,
    variables: {
      where: {
        locationId: { _eq: locationId },
        positionId: { _in: filter.groups?.length ? filter.groups : undefined },
      },
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
    },
    onCompleted: (data) => {
      setKeysAndTask(
        data?.sensorflow_v_group_tasks?.map((item: any) => {
          return {
            keyId: item.positionId,
            keyName: item.positionName,
            totalRooms: item.roomsCount,
            totalKeys: item.keysCount,
            possitionType: item.possitionType,
            remainKeyEntries: item.openJobEntryCount,
          };
        }) || []
      );

      setPagination({
        ...pagination,
        total: data?.groupsAggregate?.aggregate?.count || 0,
      });
    },
  });

  const onChangeCheckBox = useCallback(
    (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      setFilter((prev) => ({
        ...prev,
        emails: checked ? [user.email] : filter.emails?.filter((x) => x !== user.email),
      }));
    },
    [user, filter.emails, setFilter]
  );

  const handleTableChange = useCallback(
    (desiredPagination: AntdTablePagination) => {
      setPagination(desiredPagination);
    },
    [setPagination]
  );

  const handleFilterChange = (newFilter: KeyTaskFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  useEffect(() => {
    if (filter.taskStatus) {
      (async () => {
        const res = await refetch();
        reloadBinding(res.data);
      })();
    }
    // eslint-disable-next-line
  }, [filter.taskStatus]);

  return (
    <Row>
      <Col span={24}>
        <Row align="middle" className="mb-m">
          <Col span={12}>
            <Typography.Title level={2} className="mb-none">
              Jobs
            </Typography.Title>
          </Col>
          <Col span={12} className="text-right">
            <Checkbox
              onChange={onChangeCheckBox}
              checked={filter.emails?.includes(user.email)}
              disabled={filter.viewAsGroups || !!filter.groups?.length}
            >
              Assigned to me
            </Checkbox>
          </Col>
        </Row>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 18 }}>
        <JobFilter
          currentFilter={filter}
          searchField="keyName"
          searchPlaceholder="Search key name"
          containerClassName="mb-xl"
          isSaveKeySearch
          filterOptions={filterOptions}
          onChange={handleFilterChange}
          defaultKeySearch={previousFilter.keyName as string}
        />
        <JobsTable
          currentFilter={filter}
          data={keysAndTask}
          handleTableChange={handleTableChange}
          loading={loading || groupLoading}
          error={error || errorFilter || errorGroupFilter || errorGroupTasks}
          pagination={{
            ...pagination,
            pageSizeOption: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
          locationId={locationId}
          isGroupView={filter.viewAsGroups || !!filter.groups?.length}
        />
      </Col>
    </Row>
  );
};

export default Jobs;
