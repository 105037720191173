import React, { useCallback, useState } from "react";
import { Input, Tabs } from "antd";
import { isValidNodeMacId } from "components/NodeMapping/NodeMapping";
import ScannedNodeMacId from "./ScannedNodeMacId";

const FULL_NODE_MAC_ID_LENGTH = 18;
const NODE_MAC_ID_LENGTH = 16;

const InternalError = (props: { message: string }) => {
  const { message } = props;
  return <p className="internal-error">{message}</p>;
};
interface NodeMacInputProps {
  setNodeMacInput: (nodeMac: string) => void;
  defaultActiveKey?: string;
  disabledScan?: boolean;
  disabledInput?: boolean;
  value?: string;
  autoFocus?: boolean;
  needTrimFirstTwoDigit?: boolean;
  handleSubmitWhenValidData?: any;
  options?: {
    scan?: {
      tabTitle?: string;
      title?: string;
      isVisible?: boolean;
    };
    input?: {
      tabTitle?: string;
      title?: string;
      isVisible?: boolean;
      disabled?: boolean;
    };
  };
  clearForm?: boolean;
  validateNodeMacWithFullLen?: boolean;
  setInvalidInputData?: (isInValid: boolean) => void;
  setNodeTypeCode?: (nodeTypeCode: any) => void;
  setNodeMacInputActiveKey?: (activeKey: string) => void;
  forceSubmit?: boolean;
}

export default function NodeMacInput({
  setNodeMacInput,
  defaultActiveKey = "1",
  disabledScan,
  disabledInput,
  value,
  autoFocus,
  needTrimFirstTwoDigit,
  handleSubmitWhenValidData,
  options: optionsProps = {},
  clearForm = true,
  validateNodeMacWithFullLen,
  setInvalidInputData,
  setNodeTypeCode,
  setNodeMacInputActiveKey,
  forceSubmit = true,
}: NodeMacInputProps) {
  const [nodeMacId, setNodeMacId] = useState(value);
  const [internalError, setInternalError] = useState<any>();
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);

  const handleTabChange = (eventKey: string) => {
    setActiveKey(eventKey);
    if (setNodeMacInputActiveKey) {
      setNodeMacInputActiveKey(eventKey);
    }
  };

  const options = {
    scan: {
      tabTitle: "Scan with camera",
      title: "Scan QR Code with camera",
      isVisible: true,
      ...(optionsProps.scan ?? {}),
    },
    input: {
      tabTitle: "Enter Node ID",
      title: "Type Node ID",
      isVisible: true,
      disabled: false,
      ...(optionsProps.input ?? {}),
    },
  };

  const handleSubmit = async (nodeMacInput: any) => {
    if (nodeMacInput.length !== NODE_MAC_ID_LENGTH || !isValidNodeMacId(nodeMacInput)) {
      setInternalError(`Invalid Node Mac ID ${nodeMacInput}`);
    } else {
      try {
        await handleSubmitWhenValidData();
        setInternalError(null);
        if (clearForm) setNodeMacId("");
      } catch (error) {
        if (typeof error === "string") setInternalError(error);
      }
    }
  };

  const formatNodeMacInputFullLength = useCallback(
    (nodeMacInput: string) => {
      if (nodeMacInput.length === FULL_NODE_MAC_ID_LENGTH && validateNodeMacWithFullLen && setInvalidInputData) {
        if (!isValidNodeMacId(nodeMacInput.substring(2))) {
          setInternalError(`Invalid Node Mac ID ${nodeMacInput}`);
          setInvalidInputData(true);
        } else {
          setInvalidInputData(false);
          setInternalError(null);
          if (setNodeTypeCode) {
            setNodeTypeCode(nodeMacInput.substring(0, 2));
          }
          if (activeKey === "2") {
            return nodeMacInput;
          }
          return nodeMacInput.substring(2);
        }
      }
      return nodeMacInput;
    },
    [activeKey, validateNodeMacWithFullLen, setInvalidInputData, setNodeTypeCode]
  );

  const formatNodemacInput = useCallback(
    (rawNodeMacInput: string) => {
      const nodeMacInput =
        rawNodeMacInput.length > NODE_MAC_ID_LENGTH
          ? rawNodeMacInput.padStart(FULL_NODE_MAC_ID_LENGTH, "0")
          : rawNodeMacInput;
      if (needTrimFirstTwoDigit && nodeMacInput.length === FULL_NODE_MAC_ID_LENGTH) {
        if (setNodeTypeCode) {
          setNodeTypeCode(nodeMacInput.substring(0, 2));
        }
        return nodeMacInput.substring(2);
      }

      return formatNodeMacInputFullLength(nodeMacInput);
    },
    [formatNodeMacInputFullLength, needTrimFirstTwoDigit, setNodeTypeCode]
  );

  const handleNodeMacId = async (data: any) => {
    if (data) {
      const nodeMacInput = (formatNodemacInput(data) || "").toUpperCase();
      setNodeMacInput(nodeMacInput);
      setNodeMacId(nodeMacInput);
      if (handleSubmitWhenValidData && forceSubmit) {
        await handleSubmit(nodeMacInput);
      }
    } else {
      setNodeMacInput(data);
      setNodeMacId(data);
    }
  };

  const handleNodeMacInputChange = (e: any) => {
    handleNodeMacId(e.target.value);
  };

  const handleQRCodeScan = (data: any) => {
    if (data) {
      handleNodeMacId(data);
    }
  };

  return (
    <div className="scan-node-wrapper">
      <Tabs defaultActiveKey={defaultActiveKey} onChange={handleTabChange} activeKey={activeKey}>
        {options.scan.isVisible && (
          <Tabs.TabPane tab={options.scan.tabTitle} key="1" data-testid="scan-with-camera" disabled={disabledScan}>
            {nodeMacId && !internalError && <p>Node Mac Id: {nodeMacId}</p>}
            {(!nodeMacId || internalError) && <ScannedNodeMacId handleQRCodeScan={handleQRCodeScan} />}
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab={options.input.tabTitle} key="2" disabled={disabledInput}>
          <h4>{options.input.title}:</h4>
          <Input
            placeholder={needTrimFirstTwoDigit ? "FFFF14B4570DF953" : "01FFFF14B4570DF953"}
            size="large"
            id="mapping-input"
            onChange={handleNodeMacInputChange}
            value={nodeMacId}
            autoFocus={autoFocus}
            disabled={options.input.disabled}
          />
        </Tabs.TabPane>
      </Tabs>
      {internalError && <InternalError message={internalError} />}
    </div>
  );
}
