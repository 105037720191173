import React from "react";
import { Progress, Row } from "antd";

import "./ProgressComponent.scss";

const ProgressComponent = ({ name, doneCount, totalCount }: any) => {
  const percentage = totalCount === 0 ? 0 : (100 * doneCount) / totalCount;

  return (
    <div className="progress-component">
      <Row justify="space-between">
        <b>{name}</b>
        <p>
          {doneCount}/{totalCount}
        </p>
      </Row>
      <Row justify="space-between">
        <Progress percent={percentage} size="small" status="normal" showInfo={false} />
      </Row>
    </div>
  );
};

export default ProgressComponent;
