import {
  CheckCircleFilled,
  CloseCircleFilled,
  CommentOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, message, Tooltip } from "antd";
import { CircleOutlinedIcon } from "../../components/Icons";
import { formatUTCTimestamp } from "../../utils/date";
import { useUpdateTestRunStatusMutation } from "../../pacts/app-webcore/hasura-webcore.graphql";
import useModalWrapperTrigger from "../../hooks/use-modal-wrapper-trigger";
import useLocationDateTime from "../../hooks/use-location-datetime";
import { TestGroup, TestRun, TestRunStatus } from "./Checklist.d";
import ModalWrapper from "../../components/Modal/Wrapper";
import TestNoteModal from "./TestNoteModal";
import { ReloadTestModal } from "./ReloadTestModal";

const CheckResult = (props: { testRun: TestRun; isRunning: boolean }): React.ReactElement => {
  const { testRun, isRunning } = props;
  const [testNoteEditRef, openTestNoteEditModal] = useModalWrapperTrigger();
  const [resetModalRef, openResetModalRef] = useModalWrapperTrigger();
  const [status, setStatus] = useState<string>(testRun.status);
  const { formatDateTimeWithLocalTz } = useLocationDateTime();

  const testRunStatusIcon = () => {
    switch (status) {
      case TestRunStatus.PASSED:
        if (testRun.isIgnored) return <CheckCircleFilled className="fs-xl text-blue mx-s" />;
        return <CheckCircleFilled className="fs-xl text-green mx-s" />;
      case TestRunStatus.FAILED:
        return <CloseCircleFilled className="fs-xl text-danger mx-s" />;
      default:
        if (testRun.isManual) return <CircleOutlinedIcon className="text-gray mx-s" />;
        return <CloseCircleFilled className="fs-xl text-danger mx-s" />;
    }
  };

  const formatDetail = (details: String, testStatus: string) => {
    const items = details.split(",");
    return (
      <div>
        {" "}
        {testStatus === TestRunStatus.PASSED ? "Details" : "Error on"}:
        {items.map((item) => (
          <div style={{ marginLeft: "2em" }} key={item}>
            {item}
          </div>
        ))}
      </div>
    );
  };

  const lastSuccessOn = useMemo(() => {
    if (testRun.status === TestRunStatus.PENDING) {
      if (testRun.isManual) return "Not completed yet";
      return "Not started yet";
    }
    if (testRun.status === TestRunStatus.PASSED && testRun.lastPassedAt) {
      return (
        <>
          <span>Last success at: {formatDateTimeWithLocalTz(testRun.lastPassedAt)}</span>
          {testRun?.details && formatDetail(testRun.details, testRun.status)}
        </>
      );
    }
    return testRun.status !== TestRunStatus.PENDING && testRun?.details
      ? formatDetail(testRun.details, testRun.status)
      : "Not succeeded yet";
  }, [testRun, formatDateTimeWithLocalTz]);

  const [updateTestRunStatus] = useUpdateTestRunStatusMutation({
    variables: {
      testRunId: testRun.testRunId,
      status: testRun.status === TestRunStatus.PENDING ? TestRunStatus.PASSED : TestRunStatus.PENDING,
      lastPassedAt: testRun.status === TestRunStatus.PENDING ? formatUTCTimestamp(moment()) : null,
      lastTestedAt: testRun.status === TestRunStatus.PENDING ? formatUTCTimestamp(moment()) : null,
      startedAt: testRun.startedAt || formatUTCTimestamp(moment()),
    },
    onCompleted: async () => {
      await message.success("Updated status.");
    },
  });

  const handleUpdateStatus = async () => {
    setStatus(testRun.status === TestRunStatus.PENDING ? TestRunStatus.PASSED : TestRunStatus.PENDING);
    await updateTestRunStatus();
  };

  const handleOnResetStatus = useCallback(async () => {
    setStatus(TestRunStatus.PENDING);
    await updateTestRunStatus({
      variables: {
        status: TestRunStatus.PENDING,
        testRunId: testRun.testRunId,
        startedAt: formatUTCTimestamp(moment()),
      },
    });
  }, [testRun, updateTestRunStatus]);

  useEffect(() => {
    setStatus(testRun.status);
  }, [testRun.status, setStatus]);

  return (
    <>
      <div className="align-items-center d-flex mb-xs">
        <span className="d-flex align-content-start w-80">
          <div>
            {testRun.isManual && isRunning ? (
              <Button className="checked-test-run-button" onClick={() => handleUpdateStatus()}>
                {testRunStatusIcon()}
              </Button>
            ) : (
              testRunStatusIcon()
            )}
          </div>
          <div>
            <div className="font-weight-semi-bold">
              <Tooltip title={testRun.description}>{testRun.name}</Tooltip>
              {testRun.note && <CommentOutlined className="ml-xs text-scorpion" />}
            </div>
            <div className="font-weight-light fs-sm text-gray">{lastSuccessOn}</div>
          </div>
        </span>
        <div className="align-items-center blocks mr-s ml-auto">
          <a href="#0" onClick={() => openResetModalRef()}>
            <ReloadOutlined className="text-primary mr-xs fs-xl" />
          </a>
          <a href="#0" onClick={() => openTestNoteEditModal()}>
            <FormOutlined className="text-primary mr-xs fs-xl" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={testRun.troubleshootingLink}>
            <ExclamationCircleOutlined className="fs-xl text-primary" />
          </a>
        </div>
      </div>
      <ModalWrapper ref={testNoteEditRef} modal={TestNoteModal} props={{ testRun }} />
      <ModalWrapper ref={resetModalRef} modal={ReloadTestModal} props={{ onReset: handleOnResetStatus }} />
    </>
  );
};

const ChecklistDetail = (props: { group: TestGroup; isRunning: boolean }) => {
  const { group, isRunning } = props;

  const testRuns = group.testRuns.sort((a, b) => a.testId - b.testId);

  return (
    <div className="checklist-detail m-m">
      <div className="font-weight-bold my-s">Automation Tests</div>
      {testRuns
        .filter((testRun) => !testRun.isManual)
        .map((testRun) => {
          return <CheckResult key={testRun.testRunId} testRun={testRun} isRunning={isRunning} />;
        })}
      <div className="font-weight-bold my-s mt-xl">Manual Tests</div>
      {testRuns
        .filter((testRun) => testRun.isManual)
        .map((testRun) => {
          return <CheckResult key={testRun.testRunId} testRun={testRun} isRunning={isRunning} />;
        })}
    </div>
  );
};
export default ChecklistDetail;
