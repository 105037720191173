/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, message, Modal, Row, Tooltip } from "antd";
import {
  FilterOption,
  KeyFilterInput,
  KeySortInput,
  KeysQuery,
  SimpleKeyListQuery,
  SortOptions,
  useDeletePositionsMutation,
  useEditGroupOfKeysMutation,
  useKeysWithNodeHealthQuery,
  useLabelPrintingKeyRoomsLazyQuery,
  useLocationWithKeysQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import Can from "components/Can/Can";
import { AntdTablePagination } from "components/Table/Table.d";
import ListFilter from "components/ListFilter/ListFilter";
import { AppstoreAddOutlined, EditFilled, ExclamationCircleFilled } from "@ant-design/icons";
import classnames from "classnames";
import confirm from "antd/lib/modal/confirm";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { Key, Location } from "./KeyList.d";
import KeyListTable from "./KeyListTable";
import KeyAndRooms from "./KeyAndRooms";
import OverallStatusSidebar from "./OverallStatusSidebar/OverallStatusSidebar";
import errorHandler from "../../../errorHandler";
import ActionButton from "../../../components/ActionButton";
import AddSlotToKeysFormModal from "./AddSlotToKeysFormModal";
import useBreakpoint from "../../../hooks/use-breakpoint";
import CreateKeyFormModal from "./CreateKeyModal";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import OverallNodeHealthSidebar from "./OverallNodeHealthSidebar/OverallNodeHealthSidebar";
import { InputClickUpIdModal } from "./InputClickUpIdModal";
import EditGroupOfObjectsFormModal from "./EditKeysModal";

const KeyListPage = () => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const { locationId } = useParams<{ locationId: string }>();
  const [keys, setKeys] = useState<Key[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [location, setLocation] = useState<Location>();
  const [sort, setSort] = useState<KeySortInput>({ keyName: SortOptions.Asc });
  const [filter, setFilter] = useState<KeyFilterInput>({ locationId });
  const [reprintKeyId, setReprintKeyId] = useState<string | undefined>();
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [rowSelectionSetting, setRowSelectionSetting] = useState<any>();
  const [isAddSlotKeysPopupOpen, setIsAddSlotKeysPopupOpen] = useState<boolean>(false);
  const [isEditKeysPopupOpen, setIsEditKeysPopupOpen] = useState<boolean>(false);
  const [isCreateKeyModalOpen, setIsCreateKeyModalOpen] = useState<boolean>(false);
  const [clickUpExportRef, openClickUpExportModal] = useModalWrapperTrigger();
  const { location: locationContext } = useSensorflowLocation();

  const screen = useBreakpoint();

  const roleAndPermission = useRoleAndPermission();

  const reloadBinding = (data: KeysQuery | SimpleKeyListQuery) => {
    if (data) {
      const newKeys = data?.keys?.keys || [];
      setKeys(
        newKeys.map((newKey) => {
          const oldKey = keys.find((k) => k.keyId === newKey.keyId);
          return {
            ...oldKey,
            ...newKey,
          };
        })
      );
      setFilterOptions(data?.keys?.filterOptions || []);
      setPagination({
        ...pagination,
        total: data?.keys?.pagination?.count || 0,
      });
    }
    setSelectedKeys([]);
  };

  const {
    loading: keysLoading,
    error: keysError,
    fetchMore: keysFetchMore,
    refetch,
  } = useKeysWithNodeHealthQuery({
    variables: {
      filter,
      sort,
      pagination: {
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      },
    },
    onCompleted: (data) => {
      reloadBinding(data);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const reloadKeys = async () => {
    const { data } = await refetch({
      filter,
      sort,
      pagination: {
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      },
    });
    reloadBinding(data);
  };

  const { loading: locationLoading, error: locationError } = useLocationWithKeysQuery({
    variables: {
      locationId,
      sort: {
        keyName: SortOptions.Asc,
      },
      pagination: {
        limit: 10,
      },
    },
    onCompleted: (data) => {
      if (data?.keysInLocation) {
        setLocation(data.keysInLocation);
      }
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [
    getLabelPrintingKeyRooms,
    { data: labelPrintingKeyRoomsData, loading: labelPrintingKeyRoomsLoading, error: labelPrintingKeyRoomsError },
  ] = useLabelPrintingKeyRoomsLazyQuery();

  const [deleteKeysMutation] = useDeletePositionsMutation({
    onCompleted: async () => {
      message.info("Keys have been deleted.");
      await reloadKeys();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  useEffect(() => {
    if (reprintKeyId) getLabelPrintingKeyRooms({ variables: { keyId: reprintKeyId } });
  }, [reprintKeyId, getLabelPrintingKeyRooms]);

  const handleTableChange = (desiredPagination: AntdTablePagination) => {
    window.scrollTo(0, 0);
    keysFetchMore({
      variables: {
        filter,
        sort,
        pagination: {
          offset: (desiredPagination.current - 1) * pagination.pageSize,
          limit: desiredPagination.pageSize,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const { limit: pageSize, offset, count: total } = fetchMoreResult.keys!.pagination;
        setPagination({
          current: offset && pageSize ? offset / pageSize + 1 : 1,
          pageSize,
          total,
        } as AntdTablePagination);
        return fetchMoreResult;
      },
    });
    setPagination(desiredPagination);
  };

  useEffect(() => {
    if (roleAndPermission.canPerform(Permission.KEY_EDIT) && !roleAndPermission.isInstaller()) {
      setRowSelectionSetting({
        selectedRowKeys: selectedKeys.map((p) => p.keyId),
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[], selectedRows: Key[]) => {
          setSelectedKeys(selectedRows);
        },
        getCheckboxProps: (record: Key) => ({
          name: record.keyId,
        }),
      });
    }
    // Include roleAndPermission will result infinity re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  const statMessage = useMemo(() => {
    if (locationLoading) return "Loading...";
    if (locationError) return "Error on loading location";

    if (location) {
      const { current, pageSize, total } = pagination;
      const { locationName } = location;
      const start = (current - 1) * pageSize + 1;
      const end = Math.min(total, start + pageSize - 1);

      return `Showing ${start}-${end} of ${total} keys in ${locationName}`;
    }

    return "";
  }, [locationError, locationLoading, location, pagination]);

  const handleReprintClick = (keyId: string) => {
    setReprintKeyId(keyId);
  };

  const handleSortChange = (columnHeader: keyof KeySortInput) => {
    setSort({ [columnHeader]: sort[columnHeader] === SortOptions.Asc ? SortOptions.Desc : SortOptions.Asc });
  };

  const handleFilterChange = (newFilter: KeyFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  const deleteKeys = () => {
    if (selectedKeys.length === 0) {
      return message.warn("Please select at least one key.");
    }

    confirm({
      icon: <ExclamationCircleFilled className="text-warning" />,
      content: `Are you sure you wish to delete the Keys?`,
      okText: `Delete Keys`,
      okButtonProps: { className: "btn-popup-primary" },
      onOk: () => {
        deleteKeysMutation({
          variables: {
            positionIds: selectedKeys.map((k) => k.keyId),
          },
        });
      },
    });
  };

  const addSlotToKeys = () => {
    if (selectedKeys.length === 0) {
      return message.warn("Please select at least one key.");
    }

    setIsAddSlotKeysPopupOpen(true);
  };

  const [editKeys, { loading: keysEditing }] = useEditGroupOfKeysMutation({
    onCompleted: async () => {
      message.info("Update keys' groups completed.");
      await reloadKeys();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const editGroupOfKeys = async (keyIds: string[], groupId: string) => {
    await editKeys({
      variables: {
        groupId,
        keyIds,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "1em" }}>
        <Col>
          <h2 style={{ display: "inline", marginRight: "20px" }}>Keys</h2>
          <p style={{ display: "inline" }}>{statMessage}</p>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Can
              requiredPermission={Permission.KEY_EDIT}
              yes={
                !roleAndPermission.isInstaller() && (
                  <ActionButton
                    onClick={deleteKeys}
                    type="delete"
                    className="fs-xl mr-m"
                    data-testid="delete-keys-testId"
                  />
                )
              }
            />
            <Can
              requiredPermission={Permission.KEY_EDIT}
              yes={
                !roleAndPermission.isInstaller() && (
                  <ActionButton
                    onClick={addSlotToKeys}
                    customIcon={
                      <Tooltip title="Multiple Edit">
                        <AppstoreAddOutlined className="text-primary fs-xl mr-m" />
                      </Tooltip>
                    }
                    data-testid="add-slot-keys-testId"
                  />
                )
              }
            />
            <Button
              disabled={selectedKeys.length === 0}
              onClick={() => {
                setIsEditKeysPopupOpen(true);
              }}
              type="primary"
              className="mr-m"
            >
              <EditFilled /> Update keys&apos; groups
            </Button>

            <Can
              requiredPermission={Permission.KEY_CREATE}
              yes={
                !roleAndPermission.isInstaller() && (
                  <Link
                    to={{
                      pathname: `/locations/${locationId}/categories-and-keys-creation`,
                      state: {
                        locationName: location?.locationName,
                      },
                    }}
                  >
                    <Button type="primary" disabled={!location?.locationName}>
                      Creation Mode
                    </Button>
                  </Link>
                )
              }
            />
            {((roleAndPermission.isInstaller() && !roleAndPermission.isContractor()) || roleAndPermission.isPC()) && (
              <Button type="primary" className="ml-m" onClick={() => setIsCreateKeyModalOpen(true)}>
                Create Key
              </Button>
            )}

            {roleAndPermission.isPC() && (
              <Button
                disabled={locationContext?.clickupExportDisabled || !!locationContext?.clickupListId}
                type="primary"
                className="ml-m"
                onClick={() => openClickUpExportModal()}
              >
                Export to Clickup
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <ListFilter
            searchField="keyName"
            searchPlaceholder="Search key name"
            filterOptions={filterOptions}
            onChange={handleFilterChange}
            currentFilter={filter}
            filterType="FilterV3"
            locationId={locationId}
          />
          <KeyListTable
            onReprintClick={handleReprintClick}
            tableData={keys}
            sortBy={handleSortChange}
            sort={sort}
            pagination={{
              ...pagination,
              pageSizeOption: ["10", "20", "50", "100"],
              showSizeChanger: true,
            }}
            handleTableChange={handleTableChange}
            loading={keysLoading}
            error={keysError}
            rowSelection={screen.desktopUp ? rowSelectionSetting : null}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 6 }}
          className={classnames({
            "d-none": screen.mobileAndTabletOnly,
          })}
        >
          {roleAndPermission.isInstaller() ? (
            <OverallNodeHealthSidebar locationId={locationId} />
          ) : (
            <>
              {roleAndPermission.isPC() && <OverallNodeHealthSidebar locationId={locationId} />}
              <OverallStatusSidebar locationId={locationId} />
            </>
          )}
        </Col>
      </Row>
      <Modal
        title="Reprint Labels"
        centered
        visible={!!reprintKeyId}
        footer={null}
        onCancel={() => setReprintKeyId(undefined)}
        width={400}
      >
        <KeyAndRooms
          keyId={reprintKeyId}
          keyName={labelPrintingKeyRoomsData?.key.keyName}
          rooms={labelPrintingKeyRoomsData?.key.rooms}
          loading={labelPrintingKeyRoomsLoading}
          error={labelPrintingKeyRoomsError}
        />
      </Modal>
      {isAddSlotKeysPopupOpen && (
        <AddSlotToKeysFormModal
          locationId={locationId}
          onCompleted={reloadKeys}
          keys={selectedKeys}
          closeModal={() => setIsAddSlotKeysPopupOpen(false)}
        />
      )}
      {isEditKeysPopupOpen && (
        <EditGroupOfObjectsFormModal
          locationId={locationId}
          onCompleted={reloadKeys}
          objects={selectedKeys.map((key) => ({ id: key.keyId, name: key.keyName }))}
          objectType="key"
          closeModal={() => setIsEditKeysPopupOpen(false)}
          editing={keysEditing}
          editObjects={editGroupOfKeys}
        />
      )}
      {isCreateKeyModalOpen && location && (
        <CreateKeyFormModal
          locationId={locationId}
          locationName={location.locationName}
          onCompleted={reloadKeys}
          onClose={() => setIsCreateKeyModalOpen(false)}
        />
      )}

      <ModalWrapper ref={clickUpExportRef} modal={InputClickUpIdModal} />
    </>
  );
};

export default KeyListPage;
