import { Button, Col, Empty, Row } from "antd";
import Can from "components/Can/Can";
import ListFilter from "components/ListFilter/ListFilter";
import Loading from "components/Loading";
import ModalWrapper from "components/Modal/Wrapper";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import useRoleAndPermission from "hooks/use-role-and-permission";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  useInfrastructureListSubscription,
  useInfrastructuresQuery,
  useLocationKeysAndRoomsQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { getObjBySearch } from "utils/url";
import AddCompressorModal from "./AddCompressorModal/AddCompressorModal";
import AddInfrastructureModal from "./AddInfrastructureModal/AddInfrastructureModal";
import { ContractorInfrastructure } from "./ContractorInfrastructure";
import InfrastructureBlock from "./InfrastructureBlock/InfrastructureBlock";
import { InfrastructureType } from "./InfrastructureDataType";
import { filterOptions, Infrastructure, PaginationProps } from "./Infrastructures.d";
import "./Infrastructures.scss";
import { transformToInfrastructuresQueryVariables } from "./Infrastructures.transform";

type InfrastructuresProps = {
  locationName: string;
};

const Infrastructures = ({ locationName }: InfrastructuresProps) => {
  const defaultPagination = useMemo(() => ({ offset: 0, limit: 10 }), []);
  const location = useLocation();
  const roleAndPermission = useRoleAndPermission();
  const { locationId } = useParams<{ locationId: string }>();
  const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([]);
  const [roomAutosets, setRoomAutosets] = useState<any[]>([]);
  const [currentFilter, setCurrentFilter] = useState(
    getObjBySearch<{ infraName: string | undefined }>(location.search)
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationProps>(defaultPagination);
  const [hasMoreInfrastructure, setHasMoreInfrastructure] = useState<boolean>(false);

  const [addInfrastructureModalRef, openAddInfrastructureModal] = useModalWrapperTrigger();
  const [addCompressorModalRef, openAddCompressorModal] = useModalWrapperTrigger();
  const [isEmptyInfrastructures, setIsEmptyInfrastructures] = useState<boolean>(false);

  const { currentPositionId, currentKeyEntryOfInstaller } = useContext(InstallationModeContext);

  const { refetch, loading: isLoadingList } = useInfrastructuresQuery({
    variables: {
      where: transformToInfrastructuresQueryVariables(roleAndPermission.isContractor(), locationId, currentFilter),
      ...pagination,
    },
    onCompleted: (infrastructureData) => {
      if (infrastructureData) {
        setIsEmptyInfrastructures(infrastructureData.infrastructures.length === 0);
        setHasMoreInfrastructure(infrastructureData.infrastructures.length === pagination.limit);
        if (isEqual(defaultPagination, pagination)) {
          setInfrastructures(infrastructureData.infrastructures);
        } else {
          setInfrastructures((prev) => prev.concat(infrastructureData.infrastructures));
        }
      } else {
        setIsEmptyInfrastructures(true);
      }
      setLoading(false);
    },
  });

  const infraIds = useMemo(() => infrastructures.map((infra) => infra.id), [infrastructures]);
  useInfrastructureListSubscription({
    skip: isLoading || infraIds.length === 0,
    variables: { where: { id: { _in: infraIds } }, offset: 0, limit: infraIds.length },
    onSubscriptionData: (data) => {
      const infrastructureData = data.subscriptionData.data;
      if (infrastructureData) {
        setInfrastructures(infrastructureData.infrastructures);
      } else {
        setIsEmptyInfrastructures(true);
      }
    },
  });

  useLocationKeysAndRoomsQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => setRoomAutosets(data.positions),
  });

  const reloadInfrastructures = useCallback(
    async (filterValue: any) => {
      setCurrentFilter(filterValue);
      setPagination(defaultPagination);
      setLoading(true);
      const data = await refetch({
        where: transformToInfrastructuresQueryVariables(roleAndPermission.isContractor(), locationId, filterValue),
        ...defaultPagination,
      });
      setInfrastructures(data.data.infrastructures);
      setLoading(false);
    },
    [refetch, roleAndPermission, locationId, defaultPagination]
  );

  useEffect(() => {
    setLoading(isLoadingList);
  }, [isLoadingList]);

  const nextPage = () => {
    setPagination({
      offset: pagination.offset + pagination.limit,
      limit: pagination.limit,
    });
  };

  const loadingOrEmpty = () => {
    if (isLoading) return <Loading />;
    if (isEmptyInfrastructures)
      return (
        <div className="m-auto">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
  };

  return (
    <div>
      <Row>
        <Col flex="auto">
          <ListFilter
            onChange={reloadInfrastructures}
            currentFilter={currentFilter}
            searchField="infraName"
            searchPlaceholder="Type to search"
            filterOptions={filterOptions}
            hideAdvancedFilter={roleAndPermission.isContractor()}
            isSaveKeySearch={roleAndPermission.isContractor()}
            defaultKeySearch={roleAndPermission.isContractor() ? currentFilter?.infraName : undefined}
          />
        </Col>

        <Col span={24} md={8}>
          <Can
            requiredPermission={Permission.INFRASTRUCTURE_CREATE}
            yes={
              <Button type="primary" className="add-button" onClick={openAddInfrastructureModal}>
                Add Infrastructure
              </Button>
            }
          />
          {!roleAndPermission.isContractor() && (
            <Can
              requiredPermission={Permission.INFRASTRUCTURE_CREATE}
              yes={
                <Button type="primary" className="add-button add-compressor" onClick={openAddCompressorModal}>
                  Add 1:1 Compressor
                </Button>
              }
            />
          )}
        </Col>
      </Row>
      <InfiniteScroll
        dataLength={infrastructures.length}
        next={nextPage}
        hasMore={hasMoreInfrastructure}
        loader={<Loading />}
      >
        <Row className="infra-list mt-l" gutter={[16, 16]}>
          {isEmpty(infrastructures)
            ? loadingOrEmpty()
            : infrastructures.map((infra: any) => {
                if (roleAndPermission.isContractor()) {
                  const infraType = new InfrastructureType(infra.type || "");

                  if (!infraType.isCompressor() && !infraType.isCoolingDevice()) return null;

                  return (
                    <Col key={infra.id} lg={8} md={12} xs={24}>
                      <ContractorInfrastructure
                        {...infra}
                        meterPositionId={infra.meterPositionId}
                        locationId={locationId}
                        locationName={locationName}
                        currentFilter={currentFilter}
                        stopWatchStartTime={
                          infra.id === currentPositionId && currentKeyEntryOfInstaller
                            ? currentKeyEntryOfInstaller.startedAt
                            : ""
                        }
                      />
                    </Col>
                  );
                }
                return (
                  <Col key={infra.id} lg={8} md={12} xs={24}>
                    <InfrastructureBlock
                      {...infra}
                      handleDeleteBlock={reloadInfrastructures}
                      locationName={locationName}
                    />
                  </Col>
                );
              })}
        </Row>
      </InfiniteScroll>

      <ModalWrapper
        ref={addInfrastructureModalRef}
        modal={AddInfrastructureModal}
        props={{ onSuccess: reloadInfrastructures }}
      />
      <ModalWrapper
        ref={addCompressorModalRef}
        modal={AddCompressorModal}
        props={{ roomAutosets, onSuccess: reloadInfrastructures }}
      />
    </div>
  );
};

export default Infrastructures;
