import { Col, Row } from "antd";
import classNames from "classnames";
import { capitalize } from "lodash";
import React from "react";

export const DoWOptions = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

const sortDays = (days: string[]) => {
  const sortedDays = ["", "", "", "", "", "", ""];
  const daysWeek = [...DoWOptions];
  days.forEach((day) => {
    for (let i = 0; i < 7; i++) if (day === daysWeek[i]) sortedDays[i] = daysWeek[i];
  });
  return sortedDays.filter((str) => str !== "");
};

export interface DaysOfTheWeekProps {
  [x: string]: any;
  selected: string[];
  onSelected: (day: string, newDays: string[]) => Promise<void> | void;
}

export const DaysOfTheWeek: React.FC<DaysOfTheWeekProps> = ({ selected, onSelected }) => {
  const handleOnChange = (day: string) => {
    let newDaysSelected = [...selected];
    if (selected.includes(day)) newDaysSelected = newDaysSelected.filter((d) => d !== day);
    else newDaysSelected.push(day);
    onSelected(day, sortDays(newDaysSelected));
  };

  return (
    <Row gutter={[8, 8]} className="days-of-the-week" justify="space-between" data-testId="days-of-the-week">
      {DoWOptions.map((option) => {
        return (
          <Col
            key={option}
            span={Math.floor(24 / DoWOptions.length)}
            className={classNames("days-of-the-week-item", {
              "days-of-the-week-item--active": selected.includes(option),
            })}
            onClick={() => handleOnChange(option)}
            data-testId="days-of-the-week-item"
          >
            {capitalize(option)}
          </Col>
        );
      })}
    </Row>
  );
};
