import React, { useState } from "react";

import classNames from "classnames";
import { Button } from "antd";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { Link, useParams } from "react-router-dom";
import CopyButton from "components/CopyButton";
import { getNodeListLookerDashboardLink } from "utils/link";
import isNil from "lodash/isNil";
import useLocationDateTime from "hooks/use-location-datetime";
import { ButtonRMA } from "components/ButtonRMA";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { Node, NODE_STATUS_UI_CONFIG, NodeStatus } from "../NodeList/NodeList.d";

interface NodeMobileItemProps {
  node: Node;
}

const NodeMobileItem = ({ node }: NodeMobileItemProps) => {
  const [expanded, setExpanded] = useState(false);
  const { locationId } = useParams<{ locationId: string }>();
  const { formatDateTime, formatFromNow } = useLocationDateTime();
  const roleAndPermission = useRoleAndPermission();

  return (
    <div>
      <div className="d-flex flex-row align-items-center">
        <div className="w-10">
          <Button
            type="text"
            icon={expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            onClick={() => setExpanded((currentExpanded) => !currentExpanded)}
          />
        </div>
        <div className="w-45 fs-sm">
          <div className="d-flex flex-row">
            <a target="_blank" rel="noopener noreferrer" href={getNodeListLookerDashboardLink(node.nodeMacId)}>
              <span className="node-name">{node.nodeMacId}</span>
            </a>
            <CopyButton btnClassName="p-none" className="fs-m" input={node.nodeMacId} />
          </div>
        </div>
        <div className="w-35 lh-l fs-sm" style={{ display: "flex", alignItems: "center" }}>
          <div
            className={classNames(
              "status-indicator",
              `bg-${NODE_STATUS_UI_CONFIG[node.status || NodeStatus.Offline]?.indicatorColor}`
            )}
          />
          <div>{node.lastSeen ? formatFromNow(node.lastSeen) : "Never Seen"}</div>
        </div>
        <div className="w-20 lh-l fs-sm">{getNodeTypeDisplayValue(node.nodeType)}</div>
      </div>
      {expanded && (
        <div>
          <div className="d-flex flex-row ml-m">
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">Key</span>
              <span className="d-block fs-sm">
                <Link to={`/locations/${locationId}/keys/${node.key.positionId}`}>{node.key.positionName}</Link>
              </span>
            </div>
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">Room</span>
              <span className="d-block fs-sm">
                <a href={`/locations/${locationId}/keys/${node.key.positionId}#room-${node.room.positionId}`}>
                  {node.room.positionName}
                </a>
              </span>
            </div>
          </div>
          <div className="d-flex flex-row ml-m">
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">Associated Gateway</span>
              <span className="d-block fs-sm">{node?.associatedGateway?.gatewayName || "-"}</span>
            </div>
            <div className="mt-m w-25">
              <span className="d-block fs-sm font-weight-bold">Signal Strength</span>
              <span className="d-block fs-sm">
                {!isNil(node.signalStrength) ? node.signalStrength.toFixed(2) : "-"}
              </span>
            </div>
            <div className="mt-m w-25">
              <span className="d-block fs-sm font-weight-bold">Node Join</span>
              <span className="d-block fs-sm">{node.nodeJoin || "-"}</span>
            </div>
          </div>
          <div className="d-flex flex-row ml-m">
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">Boot Time</span>
              <span className="d-block fs-sm">{node.bootTime ? formatDateTime(node.bootTime) : "-"}</span>
            </div>
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">Mapped On</span>
              <span className="d-block fs-sm">{formatDateTime(node.mappedTime)}</span>
            </div>
          </div>
          <div className="d-flex flex-row ml-m">
            <div className="mt-m w-50">
              <span className="d-block fs-sm font-weight-bold">FW version</span>
              <span className="d-block fs-sm">{node.firmwareVersion || "-"}</span>
            </div>
            {roleAndPermission.isInstaller() && (
              <div className="mt-m w-50">
                <span className="d-block fs-sm">
                  <ButtonRMA nodeMacRecord={node} />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeMobileItem;
