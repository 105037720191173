import { ApolloClient } from "apollo-client";
import { defaultDataIdFromObject, InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";
import { split, ApolloLink } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { v4 as uuid } from "uuid";
import { EAuthType, SF_AUTH_TYP_HEADER } from "utils/constants";
import logger from "../logger/logger";

export default class GraphAPI {
  private authToken: string | null;

  private authType?: EAuthType;

  private baseUrl: string;

  public apolloClient: any;

  private isSecure: boolean;

  constructor(authToken: string | null, apolloClient: any, baseUrl: string, authType?: EAuthType) {
    this.authToken = authToken;
    this.baseUrl = baseUrl;
    this.isSecure = window.location.protocol === "https:";
    this.authType = authType;

    this.apolloClient =
      apolloClient ||
      new ApolloClient({
        link: ApolloLink.from([this.errorLink, this.splitLinkBasedOnQueryType()]),
        //  TODO: 2020-11-06: Remove this explicit config for the cache key id for User after we have updated the GraphQL schema to use 'ID' for userId (instead of 'String)
        cache: new InMemoryCache({
          dataIdFromObject: (object: any) => {
            // eslint-disable-next-line no-underscore-dangle
            switch (object.__typename) {
              case "User":
                return object.userId;
              default:
                return defaultDataIdFromObject(object);
            }
          },
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "network-only",
          },
        },
      });
  }

  // Create an http link:
  private httpLink = () => {
    const protocol = process.env.REACT_APP_API_WEBCORE_PROTOCOL ?? `http${this.isSecure ? "s" : ""}`;
    return new HttpLink({
      uri: `${protocol}://${this.baseUrl}`,
    });
  };

  private buildAuthorizationHeaders() {
    const token = this.authToken;

    let authHeaderVal: string | null = "";
    switch (true) {
      case this.authType === EAuthType.ComfortPlus && !!token: {
        authHeaderVal = token;
        break;
      }
      case !!token: {
        authHeaderVal = `Bearer ${token}`;
        break;
      }
      default:
    }
    return {
      authorization: authHeaderVal,
      ...(this.authType ? { [SF_AUTH_TYP_HEADER]: this.authType } : {}),
    };
  }

  // add auth0 to headers for http link
  private authHttpLink = setContext((_, { headers }) => {
    const hasuraHeaders = {
      ...headers,
      ...this.buildAuthorizationHeaders(),
      requestId: uuid(),
    };

    return {
      headers: hasuraHeaders,
    };
  });

  // Create a WebSocket link:
  private wsLink = () =>
    new WebSocketLink({
      uri: `ws${this.isSecure ? "s" : ""}://${this.baseUrl}`,
      options: {
        // lazy mode - connects only when subscription created, no need to initialise socket until then
        // https://github.com/apollographql/subscriptions-transport-ws
        lazy: true,
        reconnect: true,
        connectionParams: () => {
          const hasuraHeaders = {
            ...this.buildAuthorizationHeaders(),
            requestId: uuid(),
          };
          return {
            // WebCore expects this to look like http request shape
            headers: hasuraHeaders,
          };
        },
      },
    });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  private splitLinkBasedOnQueryType = () =>
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      this.wsLink(),
      this.authHttpLink.concat(this.httpLink())
    );

  private errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) =>
        logger.warn(
          `[GraphQL error]: Message: ${message}, Code: ${extensions?.code} Location: ${JSON.stringify(
            locations
          )}, Path (Query/Mutation/Subscription Name): ${path}`
        )
      );
    if (networkError) {
      logger.warn(`[Network error]: ${networkError}`);
      // using "in" to access property only when there is response (only some networkErrors will have response)
      // https://github.com/apollographql/apollo-link/issues/300#issuecomment-518445337
      if ("response" in networkError) {
        logger.info(networkError.response); // will have headers and status of the HTTP response
      }
    }
  });

  initApolloClientWithNewToken(authToken: string) {
    this.authToken = authToken;
    this.apolloClient = new ApolloClient({
      // use from() to marge 2 links, the second one uses split() to decide between websockets and http based on whether it is a subscription query
      link: ApolloLink.from([this.errorLink, this.splitLinkBasedOnQueryType()]),
      cache: new InMemoryCache(),
    });
  }
}
