/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from "react";

import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import {
  NodeSubType,
  NodeType,
  SlotCreateRoomInput,
  useCreateRoomMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { range } from "lodash";
import { ACTUATOR } from "../../CategoriesAndKeysCreation/DataTypes";
import { MasterDataContext } from "../../../contexts/master-data-context";
import errorHandler from "../../../errorHandler";
import acModelFormatter from "../../../utils/BaseACModelFormatter";

type CreateRoomFormModalProps = {
  locationId: string;
  keyId: string;
  onCompleted: () => void;
  closeModal: () => void;
};

const actuators = [
  {
    key: ACTUATOR.ACIR.toString(),
    value: "ACIR",
  },
  {
    key: ACTUATOR.TWOPFC.toString(),
    value: "2PFC",
  },
  {
    key: ACTUATOR.DAIKIN.toString(),
    value: "Daikin",
  },
  {
    key: ACTUATOR.FOURPFC.toString(),
    value: "4PFC",
  },
];

const validateMessages = {
  required: "This field is required.",
};

const CreateRoomFormModal = ({ locationId, keyId, closeModal, onCompleted }: CreateRoomFormModalProps) => {
  const [form] = Form.useForm();
  const [actuator, setActuator] = useState<string>("");

  const masterDataContext = useContext(MasterDataContext);

  const initialValues = {
    numberOfCeilingOccupancy: 0,
    numberOfWallOccupancy: 0,
    numberOfDoorLaser: 0,
    numberOfDoorMagnetic: 0,
  };

  const [createRoomMutation] = useCreateRoomMutation({
    onCompleted: () => {
      onCompleted();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleSubmitCreateRoom = (values: any) => {
    const slots: SlotCreateRoomInput[] = [
      ...[
        {
          nodeSubType: values.actuator,
          nodeType: NodeType.Aircon,
          slotName: actuators.find((a) => a.key === values.actuator)!.value,
        },
      ],
      ...range(values.numberOfCeilingOccupancy).map((_, i) => ({
        nodeSubType: NodeSubType.OccupancyCeiling,
        slotName: `Occupancy Ceiling ${i + 1}`,
        nodeType: NodeType.Occupancy,
      })),
      ...range(values.numberOfWallOccupancy).map((_, i) => ({
        nodeSubType: NodeSubType.OccupancyWall,
        slotName: `Occupancy Wall ${i + 1}`,
        nodeType: NodeType.Occupancy,
      })),
      ...range(values.numberOfDoorLaser).map((_, i) => ({
        nodeSubType: NodeSubType.DoorLaser,
        slotName: `Door Laser ${i + 1}`,
        nodeType: NodeType.Door,
      })),
      ...range(values.numberOfDoorMagnetic).map((_, i) => ({
        nodeSubType: NodeSubType.DoorMagnetic,
        slotName: `Door Magnetic ${i + 1}`,
        nodeType: NodeType.Door,
      })),
    ];

    const acModelId = values.baseACModel;

    createRoomMutation({
      variables: {
        positionName: values.roomName,
        locationId,
        parentPositionId: keyId,
        slots,
        acModelId,
        isDeviatedFromDefaultConfig: false,
      },
    });
    closeModal();
  };

  return (
    <Modal title="Add New Room" centered visible onCancel={closeModal} onOk={form.submit} width={600} okText="Confirm">
      <Form
        name="create-slot-form"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        validateMessages={validateMessages}
        requiredMark={false}
        onFinish={handleSubmitCreateRoom}
      >
        <Form.Item name={["roomName"]} label="Room Name" rules={[{ required: true }]}>
          <Input data-testid="room-name-input-testId" placeholder="1002" />
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item name={["actuator"]} label="Select AC" rules={[{ required: true }]}>
              <Select className="w-100" data-testid="ac-select-testId" onSelect={(v: string) => setActuator(v)}>
                {actuators.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {actuator === ACTUATOR.ACIR.toString() && (
            <Col span={11} offset={2}>
              <Form.Item
                name={["baseACModel"]}
                label="Select Base AC Model"
                rules={[{ required: actuator === ACTUATOR.ACIR }]}
              >
                <Select
                  className="w-100"
                  data-testid="ac-base-model-testId"
                  showSearch
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {masterDataContext.acModels?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {acModelFormatter.getDisplayName(option)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item name={["numberOfCeilingOccupancy"]} label="Add Ceiling Occupancy">
              <InputNumber className="w-50" min={0} max={9} data-testid="numberOfCeilingOccupancy-testId" />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item name={["numberOfWallOccupancy"]} label="Add Wall Occupancy">
              <InputNumber className="w-50" min={0} max={9} data-testid="numberOfWallOccupancy-testId" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item name={["numberOfDoorLaser"]} label="Add Door Laser">
              <InputNumber className="w-50" min={0} max={9} data-testid="numberOfDoorLaser-testId" />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item name={["numberOfDoorMagnetic"]} label="Add Door Magnetic">
              <InputNumber className="w-50" min={0} max={9} data-testid="numberOfDoorMagnetic-testId" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateRoomFormModal;
