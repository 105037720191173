import { useState, useMemo } from "react";
import { LocationWithPmsProperty } from "types/types";
import { formatLongDate } from "utils/date";
import { EodSurveyRecord } from "../EodSurveys/types";

export const useEodSurveysFilter = (surveys: EodSurveyRecord[], location?: LocationWithPmsProperty) => {
  const [surveyDate, setSurveyDate] = useState<Date>();

  const displayedSurveys = useMemo(() => {
    if (surveyDate) {
      return surveys.filter(
        (s) => formatLongDate(s.createdAt, location?.timezone) === formatLongDate(surveyDate, location?.timezone)
      );
    }
    return surveys;
  }, [surveys, surveyDate, location]);

  return {
    displayedSurveys,
    surveyDate,
    setSurveyDate,
  };
};

export default useEodSurveysFilter;
