import { Button, Form, message, Modal } from "antd";
import classnames from "classnames";
import React, { useEffect, useState, useCallback } from "react";
import { AutomationMode, FormPositionConfiguration } from "pages/Key/types";
import {
  useCancelTimerAndResetToDefaultConfigMutation,
  useResetPositionConfigurationsMutation,
  useUpdateDefaultPositionConfigurationMutation,
  useUpdatePositionConfigurationMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import { validationMessages } from "utils/validation";
import useLocationDateTime from "hooks/use-location-datetime";
import { useAuth0 } from "services/auth/authService";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { AvailableOperationalModes } from "utils/locationMetadata";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { EFeatureFlags } from "utils/constants";
import { RoomContainer } from "./KeyConfiguration.d";
import { convertToPositionConfiguration, getCurrentConfig, isDifferentWithDefault } from "./Room.helper";
import KeyConfigurationRoomForm from "./KeyConfigurationRoomForm";
import { TimerResetConfigurationModal } from "../ResetConfigurationModal";

const RoomComponent = (props: {
  room: RoomContainer;
  reloadRooms: any;
  showHeatingMode: boolean;
  isRunningInstallationMode: boolean;
  isAutomationModeDisabled: boolean;
  availableOperationalModes: AvailableOperationalModes;
}) => {
  const {
    room,
    reloadRooms,
    showHeatingMode,
    isRunningInstallationMode,
    isAutomationModeDisabled,
    availableOperationalModes,
  } = props;
  const [currentConfig, setCurrentConfig] = useState<FormPositionConfiguration>();
  const { formatDateTime } = useLocationDateTime();
  const { user } = useAuth0();
  const roleAndPermission = useRoleAndPermission();
  const [isFailedSaving, setIsFailedSaving] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [isResetDefaultDisabled, setIsResetDefaultDisabled] = useState<boolean>(false);
  const [roomAutomationDisabled, setRoomAutomationDisabled] = useState<boolean>(false);

  const [form] = Form.useForm();
  const automationMode = Form.useWatch("automationMode", form);
  const screen = useBreakpoint();
  const { location } = useSensorflowLocation();

  const [updatePositionConfigurationMutation] = useUpdatePositionConfigurationMutation({
    onCompleted: ({ updatePositionConfiguration: data }) => {
      if (data.thermostatOfflineStatus) {
        message.error(
          "Automation Configuration Saved. Please manually alter the operating mode in this room as the thermostat is currently offline."
        );
      } else {
        message.success("Automation Configuration Saved");
      }
      reloadRooms();
    },
    onError: errorHandler.handleError,
  });

  const [updateDefaultPositionConfigurationMutation] = useUpdateDefaultPositionConfigurationMutation({
    onCompleted: ({ updateDefaultPositionConfiguration: data }) => {
      if (data.thermostatOfflineStatus) {
        message.error(
          "Automation Configuration Saved. Please manually alter the operating mode in this room as the thermostat is currently offline."
        );
      } else {
        message.success("New Default Saved");
      }
      reloadRooms();
    },
    onError: errorHandler.handleError,
  });

  const canSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (error) {
      setIsFailedSaving(true);
      return (error as any)?.errorFields?.length === 0;
    }
    return !form.getFieldsError().some((item) => item.errors.length > 0);
  }, [form]);

  const saveConfig = useCallback(
    async (formValue: any) => {
      await updatePositionConfigurationMutation({
        variables: {
          positionConfiguration: convertToPositionConfiguration(room.config.positionId, formValue),
        },
      });
      setIsFailedSaving(false);
    },
    [updatePositionConfigurationMutation, room.config.positionId]
  );

  const saveAsNewDefault = useCallback(
    async (formValue: any) => {
      await updateDefaultPositionConfigurationMutation({
        variables: {
          positionConfiguration: convertToPositionConfiguration(room.config.positionId, formValue),
        },
      });
    },
    [updateDefaultPositionConfigurationMutation, room.config.positionId]
  );

  const handleOnSaveAsNewDefault = async () => {
    if (!(await canSubmit())) return;

    Modal.confirm({
      title: "Do you want to save new default?",
      content:
        "This will save the displayed settings as the new default setting for this room but the current settings will not be affected.",
      className: "confirm-modal",
      okText: "Confirm",
      onOk: () => saveAsNewDefault(form.getFieldsValue()),
    });
  };

  const handleOnSave = async () => {
    const formValue = form.getFieldsValue();

    if (!(await canSubmit())) return;
    if (
      !roleAndPermission.isInstaller() &&
      isDifferentWithDefault(convertToPositionConfiguration(room.config.positionId, formValue), room.defaultConfig)
    ) {
      Modal.confirm({
        width: 450,
        title: "Do you want to save the new configuration?",
        cancelButtonProps: { hidden: true },
        okButtonProps: { hidden: true },
        content: (
          <TimerResetConfigurationModal
            onClose={Modal.destroyAll}
            onSaveDefault={async () => {
              await saveAsNewDefault(formValue);
              Modal.destroyAll();
            }}
            onSave={async (expiredAt) => {
              await saveConfig({ ...formValue, expiredAt });
              Modal.destroyAll();
            }}
            disableSaveDefault={roomAutomationDisabled}
            disableSaveAsDefaultMaxResetTimeoutFlagEnabled={(location?.enabledFeatures ?? []).includes(
              EFeatureFlags.DisableSaveAsDefaultMaxResetTimeout
            )}
          />
        ),
      });
    } else {
      Modal.confirm({
        title: "Do you want to save the new configuration?",
        content: "This will update the current settings for this room and override its default settings.",
        className: "confirm-modal",
        okText: "Confirm",
        onOk: () => saveConfig(formValue),
      });
    }
  };

  const [resetPositionConfigurationsMutation] = useResetPositionConfigurationsMutation({
    onCompleted: () => {
      message.success("Default Reset Success");
      reloadRooms();
    },
    onError: errorHandler.handleError,
  });

  const resetToDefault = async () => {
    if (!(await canSubmit())) return;

    Modal.confirm({
      title: "Do you want to reset back to default?",
      content: "This will reset the room back to its default settings.",
      className: "confirm-modal",
      okText: "Confirm",
      onOk: () => {
        resetPositionConfigurationsMutation({
          variables: {
            positionIds: [room.config.positionId],
          },
        });
      },
    });
  };

  const compareWithCurrentConfig = () => {
    const formValue = form.getFieldsValue();
    setIsSaveDisabled(
      !isDifferentWithDefault(convertToPositionConfiguration(room.config.positionId, formValue), room.config)
    );
  };

  const compareWithDefautConfig = () => {
    const formValue = form.getFieldsValue();
    setIsResetDefaultDisabled(
      !isDifferentWithDefault(convertToPositionConfiguration(room.config.positionId, formValue), room.defaultConfig)
    );
  };

  const onFormChange = () => {
    compareWithDefautConfig();
    compareWithCurrentConfig();
  };

  const cancelChanges = () => {
    form.setFieldsValue(currentConfig);
    setCurrentConfig({ ...currentConfig } as FormPositionConfiguration);
    onFormChange();
  };

  useEffect(() => {
    const config = getCurrentConfig(room.config);
    setCurrentConfig(config);
    form.setFieldsValue(config);
    onFormChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, form]);

  useEffect(() => {
    setRoomAutomationDisabled(automationMode === AutomationMode.Disabled);
  }, [automationMode]);

  const [cancelTimer] = useCancelTimerAndResetToDefaultConfigMutation({
    variables: {
      roomIds: [room?.config.positionId],
      userId: user.sub,
    },
    onCompleted: async () => {
      message.success("Cancel timer and reset to default configuration successfully.");
      await reloadRooms();
    },
    onError: errorHandler.handleError,
  });

  const onCancelButtonClick = async () => {
    Modal.confirm({
      title: "Do you want to cancel the timer and reset back to default?",
      content: "This will cancel the timer and reset the room back to its default settings.",
      className: "confirm-modal",
      okText: "Confirm",
      onOk: cancelTimer,
    });
  };

  return (
    <>
      <Form name="basic" form={form} validateMessages={validationMessages} onValuesChange={onFormChange}>
        <KeyConfigurationRoomForm
          form={form}
          currentConfig={currentConfig}
          room={room}
          showHeatingMode={showHeatingMode}
          isAutomationModeDisabled={isAutomationModeDisabled}
          onCancelButtonClick={onCancelButtonClick}
          isFailedSaving={isFailedSaving}
          setIsFailedSaving={setIsFailedSaving}
          availableOperationalModes={availableOperationalModes}
        />
        <div
          className={classnames("p-l d-flex justify-content-between", {
            "flex-column align-items-center": screen.mobileAndTabletOnly,
          })}
        >
          <div className="d-flex align-items-center">
            <span className={classnames("d-block fs-xs text-gray", { "text-center mb-l": screen.mobileAndTabletOnly })}>
              Last modified by&nbsp;
              {[room.config.modifiedByUser?.name ?? "NA", formatDateTime(room.config.modifiedAt)]
                .filter((p) => p)
                .join(", ")}
            </span>
          </div>
          <div
            className={classnames("d-flex", {
              "flex-column justify-content-center": screen.mobileAndTabletOnly,
            })}
          >
            <Button
              type="default"
              className={classnames({ "mr-s": screen.desktopUp }, { "mb-s": screen.mobileAndTabletOnly })}
              onClick={cancelChanges}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={classnames({ "mr-s": screen.desktopUp }, { "mb-s": screen.mobileAndTabletOnly })}
              disabled={isAutomationModeDisabled || isSaveDisabled}
              onClick={handleOnSave}
            >
              Save
            </Button>
            {roleAndPermission.isInstaller() && (
              <Button
                type="primary"
                htmlType="submit"
                className={classnames({ "mr-s": screen.desktopUp }, { "mb-s": screen.mobileAndTabletOnly })}
                onClick={handleOnSaveAsNewDefault}
                disabled={isRunningInstallationMode || isAutomationModeDisabled}
              >
                Save as New Default
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              onClick={resetToDefault}
              disabled={isAutomationModeDisabled || isResetDefaultDisabled}
            >
              Reset to Default
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default RoomComponent;
