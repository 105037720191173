import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

const UserListHeader = () => {
  const history = useHistory();

  return (
    <Row justify="space-between" style={{ marginBottom: "1em" }}>
      <h2>Users</h2>
      <Button type="primary" onClick={() => history.push("/users/new")}>
        New User <UserAddOutlined />
      </Button>
    </Row>
  );
};

export default UserListHeader;
