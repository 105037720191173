import React from "react";
import { Link } from "react-router-dom";

import get from "lodash/get";
import classnames from "classnames";
import { NodeType, Order_By } from "pacts/app-webcore/hasura-webcore.graphql";
import Table from "components/Table/Table";
import { LockFilled } from "@ant-design/icons";
import isNil from "lodash/isNil";
import { getNodeListLookerDashboardLink } from "utils/link";
import { Node } from "../OtaNodeList/OtaNodeList.d";
import "./OtaNodeListTable.scss";
import { getNodeStatusIndicatorIcon, getUpgradeStatusIcon } from "../OtaNodeList/OtaNodeList.helper";
import { getOtaNodeTypeDisplayValue } from "../../OtaDetails/OtaDetails.helper";

const sortOrder = (value: Order_By) => get({ [Order_By.Asc]: "ascend", [Order_By.Desc]: "descend" }, value, "");

const OtaNodeListTable = (props: any) => {
  const getSortConfig = (sortKey: string) => {
    const { sortBy, sort } = props;
    return {
      onHeaderCell: () => ({
        onClick: () => {
          sortBy(sortKey);
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort[sortKey]),
    };
  };

  const COLUMN_CONFIG = [
    {
      title: "Node ID",
      render: ({ nodeMacId, status }: Node) => {
        return (
          <div className={classnames("d-flex align-items-center gap")}>
            {getNodeStatusIndicatorIcon(status)}
            <a target="_blank" rel="noopener noreferrer" href={getNodeListLookerDashboardLink(nodeMacId)}>
              <span className="pl-xs">{nodeMacId}</span>
            </a>
          </div>
        );
      },
      isVisible: true,
      width: 200,
      fixed: "left",
    },
    {
      title: "Location",
      render: ({ location }: Node) => {
        return location && <Link to={`/locations/${location.locationId}`}>{location.locationName}</Link>;
      },
      ...getSortConfig("position.location.locationName"),
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Key",
      render: ({ key, nodeType, location }: Node) => {
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return (
          location &&
          key && <Link to={`/locations/${location.locationId}/keys/${key.positionId}`}>{key.positionName}</Link>
        );
      },
      ...getSortConfig("position.parentPosition.positionName"),
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Room",
      render: ({ room, key, nodeType, location }: Node) => {
        // must use <a> because <Link> does not support hash fragment #room-...
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return (
          location &&
          key && (
            <a href={`/locations/${location.locationId}/keys/${key.positionId}#room-${room.positionId}`}>
              {room.positionName}
            </a>
          )
        );
      },
      ...getSortConfig("position.positionName"),
      isVisible: true,
    },
    {
      title: "Node Type",
      render: (record: Node) => getOtaNodeTypeDisplayValue(record.nodeSubType),
      isVisible: true,
      width: 200,
    },
    {
      title: "Target FW Version",
      render: ({ targetFwVersion, firmwareVersionLocked }: Node) => {
        return (
          <span>
            {targetFwVersion || "-"} {targetFwVersion && firmwareVersionLocked && <LockFilled className="text-green" />}
          </span>
        );
      },
      ...getSortConfig("node.targetFirmwareRelease.versionName"),
      isVisible: true,
      width: 180,
    },
    {
      title: "Current FW Version",
      render: ({ currentFwVersion, firmwareVersionLocked }: Node) => {
        return (
          <span>
            {currentFwVersion || "-"}{" "}
            {currentFwVersion && firmwareVersionLocked && <LockFilled className="text-green" />}
          </span>
        );
      },
      ...getSortConfig("node.currentFirmwareRelease.versionName"),
      isVisible: true,
      width: 180,
    },
    {
      title: "Failed Count",
      render: ({ failedCount }: Node) => {
        return !isNil(failedCount) ? failedCount : "-";
      },
      ...getSortConfig("node.otaFailedUpgradeAttempts"),
      isVisible: true,
      width: 180,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      ...getSortConfig("node.otaComment"),
      isVisible: true,
      ellipsis: true,
    },
    {
      title: "Upgrade status",
      render: ({ upgradeStatus }: Node) => getUpgradeStatusIcon(upgradeStatus),
      ...getSortConfig("node.nodeFirmware.upgradeStatus"),
      isVisible: true,
      align: "center",
      width: 150,
    },
  ];

  const columns = COLUMN_CONFIG.filter(({ isVisible }) => isVisible);

  return (
    <Table
      {...props}
      scroll={{ x: 2000 }}
      columns={columns}
      rowKey="nodeMacId"
      id="node-list-table"
      dataItemName="nodes"
      onRow={() => ({ "data-testid": "node-list-row" })}
    />
  );
};

export default OtaNodeListTable;
