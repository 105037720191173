import React, { useMemo, useState } from "react";

import { Form, Modal, Select } from "antd";
import { GetGroupsForDropdownQuery, useGetGroupsForDropdownQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { GroupType, PositionFunction } from "pages/Groups/Components/GroupModal";
import { LoadingOutlined } from "@ant-design/icons";

type Object = {
  id: string;
  name: string;
};

type EditGroupOfObjectsFormModalProps = {
  locationId: string;
  objects: Object[];
  closeModal: () => void;
  editObjects: (objectIds: string[], groupId: string) => Promise<void>;
  objectType: string;
  editing: any;
};

const EditGroupOfObjectsFormModal = ({
  locationId,
  closeModal,
  objects,
  editObjects,
  objectType,
  editing,
}: EditGroupOfObjectsFormModalProps) => {
  const [form] = Form.useForm();
  const [groupNames, setGroupNames] = useState<GetGroupsForDropdownQuery["positions"]>([]);
  const title = `Update ${objectType}s' groups`;
  const okText = useMemo(() => {
    return (
      <>
        {editing && <LoadingOutlined />} &nbsp;{title}
      </>
    );
  }, [editing, title]);

  const { loading } = useGetGroupsForDropdownQuery({
    variables: {
      where: {
        locationId: { _eq: locationId },
        positionType: {
          _in: [GroupType.FLOOR, GroupType.GROUP, GroupType.ZONE],
        },
        positionFunction: { _eq: PositionFunction.GROUP },
        deletedAt: { _is_null: true },
      },
    },
    onCompleted: ({ positions: groups }) => {
      setGroupNames(groups);
    },
  });

  const handleSubmitEditObjects = async (values: any) => {
    const inputs = objects.map((obj) => obj.id);
    await editObjects(inputs, values.group);
    closeModal();
  };

  return (
    <Modal
      title={title}
      onCancel={closeModal}
      onOk={form.submit}
      width={600}
      okText={okText}
      closable={!(editing || loading)}
      okButtonProps={{
        disabled: editing || loading,
      }}
      cancelButtonProps={{
        disabled: editing || loading,
      }}
      centered
      visible
    >
      <Form
        name="edit-keys-form"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmitEditObjects}
        disabled={loading || editing}
      >
        <span className="d-block mb-m">
          Add to following {objectType}s: {objects.map((obj) => obj.name).join(", ")}{" "}
        </span>
        <Form.Item name={["group"]} label="Group">
          <Select
            className="w-100"
            data-testid="room-names-testId"
            disabled={loading || editing}
            placeholder="Choose group"
            showSearch
            filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Select.Option value={undefined}>NO GROUP</Select.Option>
            {groupNames?.map((option) => (
              <Select.Option key={option.positionName} value={option.positionId}>
                {option.positionName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditGroupOfObjectsFormModal;
