import { flatMap } from "lodash";

export enum Currency {
  Eur = "EUR",
  Hkd = "HKD",
  Idr = "IDR",
  Khr = "KHR",
  Mvr = "MVR",
  Myr = "MYR",
  Sgd = "SGD",
  Thb = "THB",
  Vnd = "VND",
  Php = "PHP",
}

const mappedCurrency = {
  [Currency.Thb]: "Thai Baht",
  [Currency.Hkd]: "Hong Kong Dollar",
  [Currency.Idr]: "Indonesian Rupiah",
  [Currency.Vnd]: "Vietnamese Dong",
  [Currency.Myr]: "Malaysian Ringgit",
  [Currency.Sgd]: "Singapore Dollar ",
  [Currency.Khr]: "Cambodian Riel ",
  [Currency.Mvr]: "Maldivian Rufiyaa",
  [Currency.Eur]: "Euro",
  [Currency.Php]: "Philippine Peso",
};

export const currencyList = flatMap(mappedCurrency, (value, key) => ({ label: `${value} (${key})`, key }));
