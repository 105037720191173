import errorHandler from "errorHandler";
import { useSfLocationWithPmsProperty } from "hooks/use-sensorflow-location";
import { useTableSortByColumn } from "hooks/use-table-sort-by-cols";
import { useGetPmsReservationsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import usePagination from "pages/Key/hooks/use-pagination";
import React from "react";
import { useFetchPropertyRoomCategories } from "../hooks/use-fetch-room-categories";
import BookingDetailsDrawer from "./BookingDetailsDrawer";
import BookingListHeader from "./BookingListHeader";
import BookingListTable from "./BookingListTable";
import { convertBookingListFilterToQueryVariables } from "./helpers";
import { BookingRecord, IBookingListQueryFilter } from "./types";

const BookingList = () => {
  const { location, onLocationIdSelected } = useSfLocationWithPmsProperty();
  const [filter, setFilter] = React.useState<IBookingListQueryFilter>();
  const { pagination, setPagination } = usePagination();
  const [bookingRecords, setBookingRecords] = React.useState<BookingRecord[]>([]);
  const [focusedBooking, setFocusedBooking] = React.useState<BookingRecord>();
  const sortUtil = useTableSortByColumn();

  const handleFilterSet = React.useCallback(
    (newFilter: IBookingListQueryFilter | undefined) => {
      setFilter(newFilter);
      // reset pagination
      setPagination((cur) => ({ ...cur, current: 1 }));
    },
    [setFilter, setPagination]
  );

  const { loading: isPmsCategoryFetching, categories } = useFetchPropertyRoomCategories(location);

  const { loading, error } = useGetPmsReservationsQuery({
    variables: convertBookingListFilterToQueryVariables(location?.pms, filter, sortUtil.orderBy, pagination),
    onCompleted: (data) => {
      if (data?.reservations) {
        setBookingRecords([...data.reservations]);
        setPagination((cur) => ({ ...cur, total: data.aggregation.aggregate?.count || 0 }));
      }
    },
    onError: errorHandler.handleError,
  });

  const onTableChange = (_pagination: any, filters: any) => {
    const newFilter: IBookingListQueryFilter = { ...filter };
    newFilter.statuses = filters.status;
    setFilter(newFilter);
  };

  // header (title, location dropdown, filter)
  // table with pagination
  return (
    <>
      <BookingListHeader
        filter={filter}
        roomCategories={categories}
        onFilterSet={handleFilterSet}
        onLocationIdChosen={onLocationIdSelected}
      />
      <BookingListTable
        onChange={onTableChange}
        onBookingChosen={setFocusedBooking}
        sortUtil={sortUtil}
        onPaginationChanged={setPagination}
        data={bookingRecords}
        location={location}
        pagination={pagination}
        filter={filter}
        loading={loading || isPmsCategoryFetching}
        error={error}
      />
      <BookingDetailsDrawer booking={focusedBooking} onClose={() => setFocusedBooking(undefined)} />
    </>
  );
};

export default BookingList;
