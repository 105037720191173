import React, { useMemo } from "react";
import "./PrecoolOverallStatus.scss";
import { ClockIcon, CoolIcon, FanFilledIcon, ThermostatIcon } from "components/Icons";
import { Button } from "antd";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import classNames from "classnames";
import { PrecoolOverallStatusProps } from "./PrecoolOverallStatus.d";
import PrecoolOverallStatusDetail from "../PrecoolOverallStatusDetail/PrecoolOverallStatusDetail";

const PrecoolOverallStatus: React.FC<PrecoolOverallStatusProps> = ({ precoolKeyStatus }) => {
  const [precoolDetailModalRef, openPrecoolDetailModalRef] = useModalWrapperTrigger();

  const isMutipleRooms = useMemo(() => precoolKeyStatus.rooms.length > 1, [precoolKeyStatus]);

  const acModeStatus = useMemo(() => {
    const totalOn = precoolKeyStatus.rooms.filter(({ acModeCoolOn }) => acModeCoolOn);

    const label =
      !totalOn.length && !isMutipleRooms ? (
        "Off"
      ) : (
        <>{isMutipleRooms && `${totalOn.length}/${precoolKeyStatus.rooms.length}`} Cool</>
      );

    return {
      label,
      totalOn: totalOn.length,
      active: !!totalOn.length,
    };
  }, [isMutipleRooms, precoolKeyStatus]);

  const fanStatus = useMemo(() => {
    const totalOn = precoolKeyStatus.rooms.filter(({ fanOn }) => fanOn);

    const label =
      !totalOn.length && !isMutipleRooms ? (
        "Off"
      ) : (
        <>{isMutipleRooms && `${totalOn.length}/${precoolKeyStatus.rooms.length}`} On</>
      );

    return {
      label,
      totalOn: totalOn.length,
      active: !!totalOn.length,
    };
  }, [isMutipleRooms, precoolKeyStatus]);

  const scheduleStatus = useMemo(() => {
    const totalOn = precoolKeyStatus.rooms.filter(({ scheduleOn }) => scheduleOn);

    const label =
      !totalOn.length && !isMutipleRooms ? (
        "Off"
      ) : (
        <>{isMutipleRooms && `${totalOn.length}/${precoolKeyStatus.rooms.length}`} Schedule</>
      );

    return {
      label,
      totalOn: totalOn.length,
      active: !!totalOn.length,
    };
  }, [isMutipleRooms, precoolKeyStatus]);

  return (
    <>
      <div className="precool-overall-status">
        <div className="precool-overall-status-header">
          <div className="precool-overall-status-header-title">{precoolKeyStatus.keyName}</div>
          <div className="precool-overall-status-header-chip">{precoolKeyStatus?.rooms?.length} rooms</div>
          {isMutipleRooms && (
            <div className="precool-overall-status-header-see-all">
              <Button type="text" onClick={openPrecoolDetailModalRef}>
                See all rooms
              </Button>
            </div>
          )}
        </div>
        <div className="precool-overall-status-temp">
          <p className="precool-overall-status-temp-title">Average Temp.</p>
          <p className="precool-overall-status-temp-content">
            <ThermostatIcon /> {precoolKeyStatus.averageTemp}°C
          </p>
        </div>
        <div className="precool-overall-status-list">
          <div className="precool-overall-status-list-item ac-mode">
            <div className="precool-overall-status-list-item-title">AC mode</div>
            <div className={classNames("precool-overall-status-list-item-content", { active: acModeStatus.active })}>
              <CoolIcon className="precool-overall-icon ac-mode-icon" /> {acModeStatus.label}
            </div>
          </div>
          <div className="precool-overall-status-list-item fan">
            <div className="precool-overall-status-list-item-title">Fan</div>
            <div className={classNames("precool-overall-status-list-item-content", { active: fanStatus.active })}>
              <FanFilledIcon className="precool-overall-icon fan-icon" /> {fanStatus.label}
            </div>
          </div>
          <div className="precool-overall-status-list-item schedule">
            <div className="precool-overall-status-list-item-title">Schedule</div>
            <div className={classNames("precool-overall-status-list-item-content", { active: scheduleStatus.active })}>
              <ClockIcon className="precool-overall-icon schedule-icon" /> {scheduleStatus.label}
            </div>
          </div>
        </div>
      </div>
      <ModalWrapper ref={precoolDetailModalRef} modal={PrecoolOverallStatusDetail} props={{ precoolKeyStatus }} />
    </>
  );
};

export default PrecoolOverallStatus;
