import { Button, Modal } from "antd";
import React from "react";
import { TrashIcon } from "components/Icons";
import "./DeleteGroupButton.scss";

type DeleteGroupButtonProps = {
  groupId: string;
  handleDeleteGroup: (groupId: string) => Promise<void>;
};

const DeleteGroupButton = ({ groupId, handleDeleteGroup }: DeleteGroupButtonProps) => {
  const handleDeleteButtonClicked = () => {
    Modal.confirm({
      title: (
        <center>
          <b>Do you want to delete this group?</b>
        </center>
      ),
      content: <center>When you delete a group, all internal keys and portals will not belong to any group</center>,
      okText: "Confirm",
      onOk: () => handleDeleteGroup(groupId),
      icon: (
        <div className="confirm-delete-modal-icon">
          <TrashIcon className="white-svg-icon" />
        </div>
      ),
      className: "delete-group-modal",
    });
  };

  return (
    <>
      <Button
        className="px-s action-column"
        onClick={() => {
          handleDeleteButtonClicked();
        }}
      >
        <TrashIcon />
      </Button>
    </>
  );
};

export default DeleteGroupButton;
