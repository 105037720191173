import { useState } from "react";
import { PositionWithRooms } from "../types";

const useKeyExpansion = () => {
  const [expandedKeySet, setExpandedKeySet] = useState<Set<string>>(new Set());
  const [isExpandedAll, setIsExpandedAll] = useState<boolean>(true);
  const [collapsedKeySet, setCollapsedKeySet] = useState<Set<string>>(new Set());

  const rebindSets = () => {
    // Rebind all collections
    setCollapsedKeySet(new Set(collapsedKeySet));
    setExpandedKeySet(new Set(expandedKeySet));
  };

  const resetExpansion = () => {
    setExpandedKeySet(new Set());
    setCollapsedKeySet(new Set());
    setIsExpandedAll(true);
    rebindSets();
  };

  const expandKey = (key: string) => {
    expandedKeySet.add(key);
    collapsedKeySet.delete(key);
  };

  const collapseKey = (key: string) => {
    expandedKeySet.delete(key);
    collapsedKeySet.add(key);
  };

  const handleKeyExpanded = (keys: PositionWithRooms[], records: PositionWithRooms[], expanded: boolean) => {
    records.forEach((record) => {
      if (expanded) {
        expandKey(record.positionId);
      } else {
        collapseKey(record.positionId);
      }
    });
    rebindSets();
  };

  const onSourceBinding = (keys: PositionWithRooms[]) => {
    if (!isExpandedAll) return;
    keys.forEach((k) => {
      if (collapsedKeySet.has(k.positionId)) return;
      expandKey(k.positionId);
    });
    rebindSets();
  };

  const onExpandAll = (expanded: boolean, keys: PositionWithRooms[]) => {
    setIsExpandedAll(expanded);
    handleKeyExpanded(keys, keys, expanded);
    resetExpansion();
    rebindSets();
  };

  return {
    resetExpansion,
    onSourceBinding,
    onExpandAll,
    handleKeyExpanded,
    expandedKeys: Array.from(expandedKeySet),
  };
};

export default useKeyExpansion;
