import React, { useCallback, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { validationMessages } from "utils/validation";
import {
  useInitClickupExportProgressMutation,
  useValidateClickupListIdLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { useParams } from "react-router-dom";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { clickupListLinkRegex } from "components/Location/CreateLocationFormConfig";
import { InputClickUpIdModalProps } from "./InputClickUpIdModal.d";

const InputClickUpIdModal: React.FC<InputClickUpIdModalProps> = ({ modalWrapperProps, closeModal }) => {
  const [form] = Form.useForm<{ clickupListId: string }>();
  const { locationId } = useParams<{ locationId: string }>();
  const { reloadLocation } = useSensorflowLocation();
  const [isValidLink, setIsValidLink] = useState<boolean>(false);

  const [validateClickupListId, { loading: validatingClickupListId }] = useValidateClickupListIdLazyQuery({
    onCompleted: () => {
      setIsValidLink(true);
    },
    onError: () => {
      setIsValidLink(false);
      message.error("ClickUp list provided is not valid. Please create the list using the location root template.");
    },
  });

  const [exportClickUp, { loading }] = useInitClickupExportProgressMutation({
    onCompleted: () => {
      message.success(
        "Export to Clickup started. it can take up to 30 minutes for an existing large property to be exported"
      );
      reloadLocation();
      closeModal();
    },
    onError: errorHandler.handleError,
  });

  const handleOnExport = useCallback(async () => {
    await form.validateFields();
    const clickupListLink = form.getFieldValue("clickupListLink") as string;
    const splitLink = clickupListLink.split("/");
    const clickupListId = splitLink[splitLink.length - 1];
    if (isValidLink) {
      await exportClickUp({ variables: { clickupListId, locationId } });
    }
  }, [form, exportClickUp, locationId, isValidLink]);

  const handleClickupListLinkFieldChange = useCallback(
    (link: string) => {
      const splitLink = link.split("/");
      const clickupListId = splitLink[splitLink.length - 1];
      validateClickupListId({
        variables: {
          clickupListId,
        },
      });
    },
    [validateClickupListId]
  );

  return (
    <Modal
      title="Export location to Clickup"
      centered
      wrapClassName="vh-100-modal"
      width={450}
      footer={[
        <Button key="clear" onClick={closeModal} disabled={loading}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          className="mb-s"
          onClick={handleOnExport}
          loading={loading || validatingClickupListId}
          disabled={loading || !isValidLink}
        >
          Export
        </Button>,
      ]}
      {...modalWrapperProps}
    >
      <Form form={form} validateMessages={validationMessages} autoComplete="off" layout="vertical">
        <Form.Item
          label="Clickup List link"
          name="clickupListLink"
          rules={[
            { required: true, message: "Please enter the ClickUp List Link" },
            { pattern: clickupListLinkRegex, message: "Please enter the correct ClickUp List Link" },
          ]}
        >
          <Input onChange={(e: any) => handleClickupListLinkFieldChange(e.target.value)} type="link" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InputClickUpIdModal;
