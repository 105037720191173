import React, { useEffect, useRef, useState } from "react";

import { Input, Modal, Row, Select } from "antd";
import { Closable } from "components/Modal/show";
import { GetCoolingSystemQuery, useGetCoolingSystemQuery } from "pacts/app-webcore/hasura-webcore.graphql";

export type RenameFormModalProps = {
  objectId: string;
  locationId: string;
  objectName: string;
  objectCtMultiplier?: number;
  objectCoolingSystemId?: string;
  hasCtMultiplier: boolean;
  hasCoolingSystem: boolean;
  onSubmit: (id: string, name: string, ctMultiplier?: number, coolingSystemId?: string) => void;
};

const ChangeInfraDetailModal = ({
  objectId,
  locationId,
  objectName,
  objectCtMultiplier,
  objectCoolingSystemId,
  hasCtMultiplier,
  hasCoolingSystem,
  onSubmit,
  close,
}: RenameFormModalProps & Closable) => {
  const [nameInput, setNameInput] = useState<string>(objectName);
  const [ctMultiplierInput, setCtMultiplier] = useState<number | undefined>(objectCtMultiplier);
  const [coolingSystemId, setCoolingSystemId] = useState<string | undefined>(objectCoolingSystemId);
  const [coolingSystems, setCoolingSystems] = useState<GetCoolingSystemQuery["infrastructures"]>();
  const objectNameInputRef: any = useRef();
  const objectCTMultiplierInputRef: any = useRef();

  useEffect(() => {
    objectNameInputRef.current!.focus();
  }, [objectNameInputRef]);

  useGetCoolingSystemQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setCoolingSystems(data.infrastructures);
    },
  });

  const handleSubmitRenameObject = () => {
    const inputChanged =
      (nameInput && nameInput !== objectName) ||
      ctMultiplierInput !== objectCtMultiplier ||
      coolingSystemId !== objectCoolingSystemId;
    if (inputChanged) {
      onSubmit(objectId, nameInput, ctMultiplierInput, coolingSystemId);
      close();
    }
  };

  const handleNameInputChange = (e: any) => {
    setNameInput(e.target.value);
  };

  const handleCtMultiplierInputChange = (e: any) => {
    setCtMultiplier(parseFloat(e.target.value));
  };

  return (
    <Modal
      title="Change Infrastructure Detail"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitRenameObject}
      width={400}
      okText="Change"
    >
      <Row>
        <p className="mb-xs">Infrastructure Name</p>
        <Input
          id="object-name-input"
          data-testid="object-name-input-testId"
          onChange={handleNameInputChange}
          ref={objectNameInputRef}
          value={nameInput}
          placeholder="Name"
        />
      </Row>
      {hasCtMultiplier && (
        <Row className="mt-xl">
          <p className="mb-xs">CT Multiplier</p>
          <Input
            id="object-multiplier-input"
            type="number"
            data-testid="object-name-input-testId"
            onChange={handleCtMultiplierInputChange}
            ref={objectCTMultiplierInputRef}
            value={ctMultiplierInput}
            placeholder="CT Multiplier"
          />
        </Row>
      )}
      {hasCoolingSystem && (
        <Row className="mt-xl">
          <p className="mb-xs">Cooling System</p>
          <Select className="infrastructure-type-selector" defaultValue={coolingSystemId} onSelect={setCoolingSystemId}>
            {coolingSystems?.map((system) => {
              return <Select.Option value={system.id}>{system.name}</Select.Option>;
            })}
          </Select>
        </Row>
      )}
    </Modal>
  );
};

export default ChangeInfraDetailModal;
