import { set } from "lodash";
import { Sensorflow_Positions } from "pacts/app-webcore/hasura-webcore.graphql";

const transformToAutosetValue = (positionName: string, roomName: string, positionId: string) =>
  JSON.stringify({
    positionName,
    roomName,
    positionId,
  });

export const connectedRoomsToAutosetValues = (connectedRooms: any[]) =>
  connectedRooms.map((connectedRoom) =>
    transformToAutosetValue(
      connectedRoom.position.parentPosition.positionName,
      connectedRoom.position.positionName,
      connectedRoom.position.positionId
    )
  );

export const transformAutosetsToTreeNodes = (autosets: any[]) =>
  autosets
    .filter((parentPosition: Sensorflow_Positions) => parentPosition.rooms.length > 0)
    .map((parentPosition: Sensorflow_Positions) => ({
      title: parentPosition.positionName,
      value: parentPosition.positionId,
      children: parentPosition.rooms.map((position: Sensorflow_Positions) => ({
        title: position.positionName,
        value: transformToAutosetValue(parentPosition.positionName, position.positionName, position.positionId),
        children: position.rooms
          ? position.rooms.map((childPosition: Sensorflow_Positions) => ({
              title: childPosition.positionName,
              value: transformToAutosetValue(
                position.positionName,
                childPosition.positionName,
                childPosition.positionId
              ),
            }))
          : [],
      })),
    }))
    .sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: "base" }));

export const transformAutosetToAutosetInput = (autosets: string[]) => autosets.map((autoset) => JSON.parse(autoset));

export const getOrderByFromSort = (sort: Record<string, any>) => {
  const obj = {};
  const sortKey = Object.keys(sort)[0];
  if (sortKey) {
    set(obj, sortKey, sort[sortKey]);
  }
  return obj;
};
