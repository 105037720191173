import React from "react";

import cx from "classnames";
import { CheckOutlined } from "@ant-design/icons";
import styles from "./BreadcrumbStep.module.scss";

type BreadcrumbStepProps = {
  badge: React.ReactNode | string | undefined;
  text: React.ReactNode | string;
  badgeStyleClass: string;
  textStyleClass: string;
};

type ConcreteBreadcrumbStepProps = {
  badge?: React.ReactNode | string | undefined;
  text: string;
};

const BreadcrumbStep = ({ badge, badgeStyleClass, text, textStyleClass }: BreadcrumbStepProps) => {
  return (
    <div className={styles.breadcrumbStep}>
      <span className={cx(styles.badge, badgeStyleClass)}>{badge}</span>
      <span className={cx(styles.badgeText, textStyleClass)}>{text}</span>
    </div>
  );
};

export const CurrentBreadcrumbStep = ({ badge, text }: ConcreteBreadcrumbStepProps) => {
  return (
    <BreadcrumbStep
      badge={badge}
      text={text}
      badgeStyleClass={styles.badgeCurrent}
      textStyleClass={`${styles.badgeTextCurrent} badge-text-current `}
    />
  );
};

export const CompletedBreadcrumbStep = ({ badge = <CheckOutlined />, text }: ConcreteBreadcrumbStepProps) => {
  return (
    <BreadcrumbStep
      badge={badge}
      text={text}
      badgeStyleClass={styles.badgeCompleted}
      textStyleClass={styles.badgeTextCompleted}
    />
  );
};

export const IncompleteBreadcrumbStep = ({ badge, text }: ConcreteBreadcrumbStepProps) => {
  return (
    <BreadcrumbStep
      badge={badge}
      text={text}
      badgeStyleClass={styles.badgeIncomplete}
      textStyleClass={styles.badgeTextIncomplete}
    />
  );
};
