import {
  GetCurrentKeyEntryOfInstallerQuery,
  useGetCurrentKeyEntryOfInstallerLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import getRules, { UserWithAuthToken } from "rbac-rules";
import { useCallback, useEffect, useMemo, useState } from "react";
import useBreakpoint from "./use-breakpoint";

const useRedirectToCurrentInstallation = ({ userWithAuthToken }: { userWithAuthToken: UserWithAuthToken }) => {
  const [currentKeyEntry, setCurrentKeyEntry] = useState<
    GetCurrentKeyEntryOfInstallerQuery["keyEntries"] | undefined
  >();
  const screens = useBreakpoint();

  const roleValue = useMemo(() => {
    return getRules(userWithAuthToken);
  }, [userWithAuthToken]);

  const [getCurrentKeyEntry] = useGetCurrentKeyEntryOfInstallerLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ keyEntries: data }) => {
      setCurrentKeyEntry(data);
    },
  });

  const installationPageUrl = useMemo(() => {
    if (currentKeyEntry?.[0] && screens.mobileAndTabletOnly) {
      const keyEntry = currentKeyEntry[0];
      return `/locations/${keyEntry.position?.locationId}/keys/${keyEntry.keyPositionId}/job-details`;
    }
    return undefined;
  }, [currentKeyEntry, screens]);

  useEffect(() => {
    if (userWithAuthToken && roleValue?.isContractor) {
      getCurrentKeyEntry({
        variables: {
          userId: userWithAuthToken.userId,
        },
      });
      return;
    }
    setCurrentKeyEntry(undefined);
  }, [userWithAuthToken, getCurrentKeyEntry, roleValue]);

  const redirectToInstallationPage = useCallback(() => {
    const currentUrl = window.location.href;
    if (!installationPageUrl || currentUrl.indexOf(installationPageUrl) >= 0) return;
    window.location.href = installationPageUrl;
  }, [installationPageUrl]);

  return {
    redirectToInstallationPage,
  };
};

export default useRedirectToCurrentInstallation;
