import React, { useState } from "react";

import { Modal, Select } from "antd";
import { Closable } from "../../../../components/Modal/show";

export type SelectGatewayModalProps = {
  onOk: (gateway: any) => void;
  gateways: any[];
  defaultValue: any;
};

const SelectGatewayModal = ({ gateways, onOk, close, defaultValue }: SelectGatewayModalProps & Closable) => {
  const [selectedGateway, setSelectedGateway] = useState<any>();

  const handleSubmitAction = () => {
    if (selectedGateway) {
      onOk(selectedGateway);
      close();
    }
  };

  const handleOnChange = (value: any) => {
    setSelectedGateway(value);
  };

  return (
    <Modal
      title="Change Gateway"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !selectedGateway,
      }}
    >
      <p className="mb-xs">Select Gateway</p>
      <Select defaultValue={defaultValue} className="w-100" data-testid="gateway-testId" onChange={handleOnChange}>
        {gateways?.map((option) => (
          <Select.Option key={option.gatewayId} value={option.gatewayId}>
            {option.gatewayName}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default SelectGatewayModal;
