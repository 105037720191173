import classNames from "classnames";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import { formatShortDateAndTime } from "utils/date";

interface IBookingTimeDisplayProps {
  dateStr?: string;
  screen?: { mobileOnly?: boolean };
  location?: LocationWithPmsProperty;
}

const BookingTimeDisplay = ({ dateStr, screen, location }: IBookingTimeDisplayProps) => {
  const data = formatShortDateAndTime(dateStr, location?.timezone);
  return (
    <span>
      {!data ? (
        "-"
      ) : (
        <>
          <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>{data.day} -</span>
          <span className="text-gray">{` ${data.time}`}</span>
        </>
      )}
    </span>
  );
};

export default BookingTimeDisplay;
