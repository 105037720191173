/* eslint-disable no-case-declarations */
import React, { useState, useContext, createContext } from "react";
import { v4 as uuid } from "uuid";
import noop from "lodash/noop";
import { GetMockAuthServiceProps, AuthContext, AuthProviderProps, MockLoginScreenProps } from "./authService.d";

enum MockAuthServiceType {
  MOCK_FOR_DEV = "MOCK_FOR_DEV",
  E2E_STAGING = "E2E_STAGING",
}

const MockLoginScreen = ({ login, loginButtonText, loginMessage }: MockLoginScreenProps) => (
  <>
    <h1>Login</h1>
    <p>{loginMessage}</p>
    <button type="button" onClick={login}>
      {loginButtonText}
    </button>
  </>
);

let MockAuth: any;

const getMockAuthService = ({
  persistedAuthStatusKey,
  defaultAuthState,
  loginMessage,
  loginButtonText,
}: GetMockAuthServiceProps) => {
  if (!MockAuth) {
    MockAuth = createContext({ ...defaultAuthState });
  }

  const Provider = ({
    children,
    overrideLoginStatusTo = undefined,
    authStateOverrides,
  }: AuthProviderProps): JSX.Element => {
    const persistedAuthStatus = localStorage.getItem(persistedAuthStatusKey);

    let initialLoginStatus = false;
    if (overrideLoginStatusTo !== undefined) {
      initialLoginStatus = overrideLoginStatusTo;
    } else if (persistedAuthStatus === "true") {
      initialLoginStatus = true;
    }

    const [userProfile] = useState(defaultAuthState.userProfile);
    const [idToken] = useState(defaultAuthState.idToken);
    const [isLoggedIn, setLoggedIn] = useState(initialLoginStatus);
    const [showLoginScreen, setShowLoginScreen] = useState(false);

    const toggleLoggedIn = (desiredState: boolean) => setLoggedIn(desiredState);

    const login = () => {
      setLoggedIn(true);
      setShowLoginScreen(false);
      localStorage.setItem(persistedAuthStatusKey, "true");
    };

    const loginWithRedirect = () => setShowLoginScreen(true);

    const authState = {
      userProfile,
      idToken,
      accessToken: defaultAuthState.accessToken,
      isLoggedIn,
      toggleLoggedIn,
      loginWithRedirect,
      ...authStateOverrides,
    };

    return (
      <MockAuth.Provider value={authState}>
        {showLoginScreen ? (
          <MockLoginScreen login={login} loginMessage={loginMessage} loginButtonText={loginButtonText} />
        ) : (
          children
        )}
      </MockAuth.Provider>
    );
  };

  const Hook = () => {
    const { accessToken, isLoggedIn, userProfile, toggleLoggedIn, loginWithRedirect } = useContext(MockAuth);

    return {
      isAuthenticated: isLoggedIn,
      isLoading: false,
      user: userProfile,
      getAccessTokenSilently: () => Promise.resolve(accessToken),
      loginWithRedirect,
      logout: () => {
        toggleLoggedIn(false);
        localStorage.setItem(persistedAuthStatusKey, "false");
        window.location.href = "/";
      },
    };
  };

  return { provider: Provider, hook: Hook };
};

const getMockAuthServiceByType = (type: MockAuthServiceType) => {
  switch (type) {
    case MockAuthServiceType.MOCK_FOR_DEV:
      const defaultAuthStateMockDev: AuthContext = {
        userProfile: {
          name: "mock.auth.user@sensorflow.org",
          nickname: "Mock Mockingbird",
          sub: uuid(),
        },
        idToken:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
        accessToken:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
        isLoggedIn: true, // true by default to facilitate testing when no provider
        toggleLoggedIn: noop,
        loginWithRedirect: noop,
      };

      return getMockAuthService({
        persistedAuthStatusKey: "mockAuthStatus",
        defaultAuthState: defaultAuthStateMockDev,
        loginMessage: "In our actual app this would be hosted on auth0",
        loginButtonText: "Log in",
      });

    case MockAuthServiceType.E2E_STAGING:
      const cypressAuthState = JSON.parse(localStorage.getItem("cypressAuthState")!);

      const defaultAuthStateE2EStaging: AuthContext = {
        ...cypressAuthState,
        toggleLoggedIn: noop,
        loginWithRedirect: noop,
      };

      return getMockAuthService({
        persistedAuthStatusKey: "cypressStagingMockAuthStatus",
        defaultAuthState: defaultAuthStateE2EStaging,
        loginMessage: "In normal login flow this would be hosted on Auth0",
        loginButtonText: "Log in by Auth0 API",
      });
    default:
  }
};

const getDevMockAuthService = () => getMockAuthServiceByType(MockAuthServiceType.MOCK_FOR_DEV);

const getE2EStagingMockAuthService = () => getMockAuthServiceByType(MockAuthServiceType.E2E_STAGING);

export { getDevMockAuthService, getE2EStagingMockAuthService };
