import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import {
  Gateway,
  Position,
  Sensorflow_Node_To_Slot_Mapping_Bool_Exp,
  TemporaryNodeListQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";

export enum NodeStatus {
  Online = "online",
  Offline = "offline",
}

export const NODE_STATUS_UI_CONFIG = {
  [NodeStatus.Online]: {
    indicatorColor: "success",
  },
  [NodeStatus.Offline]: {
    indicatorColor: "error",
  },
};

export type RawNodeToSlotMapping = TemporaryNodeListQuery["sensorflow_node_to_slot_mapping"][number];

export type Node = {
  nodeMacId: string;
  nodeType: string;
  status: NodeStatus;
  lastSeen: string;
  key: Pick<Position, "positionId" | "positionName">;
  room: Pick<Position, "positionId" | "positionName">;
  associatedGateway: Pick<Gateway, "gatewayName">;
  signalStrength: number;
  nodeJoin: number;
  bootTime: string;
  mappedTime: string;
  firmwareVersion: number;
  nodeSubType: string;
};

type FloorCeilingValues = {
  floorValue: number;
  ceilingValue: number;
};

export type NodeFilterInput = {
  nodeTypes?: Array<string>;
  statuses?: Array<string>;
  nodeMacId: "";
  firmwareVersion?: {
    inputValue: string;
  };
  signalStrength?: FloorCeilingValues;
  nodeJoin?: FloorCeilingValues;
};

export const getNodeListWhereFromFilter = (
  locationId: string,
  filter: NodeFilterInput
): Sensorflow_Node_To_Slot_Mapping_Bool_Exp => {
  const nodeTypes = filter.nodeTypes?.length ? filter.nodeTypes : null;

  const statuses = filter.statuses?.length ? filter.statuses : null;

  const firmwareVersion = filter.firmwareVersion?.inputValue;

  const where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp = {
    position: {
      locationId: {
        _eq: locationId,
      },
      deletedAt: {
        _is_null: true,
      },
    },
    node: {
      nodeJoinStatus: {},
    },
    decomissionedTime: { _is_null: true },
  };

  if (filter.nodeMacId) {
    const ilikeQuery = `%${filter.nodeMacId}%`;
    where._or = [
      {
        nodeMacId: {
          _ilike: ilikeQuery,
        },
      },
      {
        position: {
          positionName: {
            _ilike: ilikeQuery,
          },
        },
      },
      {
        position: {
          parentPosition: {
            positionName: {
              _ilike: ilikeQuery,
            },
          },
        },
      },
      {
        node: {
          gateway: {
            gatewayName: {
              _ilike: ilikeQuery,
            },
          },
        },
      },
    ];
  }

  if (nodeTypes) {
    where.nodeType = {
      _in: nodeTypes,
    };
  }

  if (statuses) {
    where.node!.nodeOnlineStatus = {
      nodeStatus: {
        _in: statuses.map((status) => status.toUpperCase()),
      },
    };
  }

  if (firmwareVersion) {
    where.node!.nodeJoinStatus!.firmwareVersion = {
      _ilike: `%${firmwareVersion}%`,
    };
  }

  if (filter.signalStrength?.floorValue) {
    where.node!.nodeJoinStatus!.signalStrength = {
      _gte: filter.signalStrength.floorValue,
    };
  }

  if (filter.signalStrength?.ceilingValue) {
    where.node!.nodeJoinStatus!.signalStrength = {
      _lte: filter.signalStrength.ceilingValue,
    };
  }

  if (filter.nodeJoin?.floorValue) {
    where.node!.nodeJoinStatus!.recentJoinCount = {
      _gte: filter.nodeJoin.floorValue,
    };
  }

  if (filter.nodeJoin?.ceilingValue) {
    where.node!.nodeJoinStatus!.recentJoinCount = {
      _lte: filter.nodeJoin.ceilingValue,
    };
  }

  if (where.node && isEmpty(where.node.nodeJoinStatus)) {
    delete where.node.nodeJoinStatus;

    if (isEmpty(where.node)) {
      delete where.node;
    }
  }

  return where;
};

export const getOrderByFromSort = (sort: any) => {
  const obj = {};
  const sortKey = Object.keys(sort)[0];
  if (sortKey) {
    set(obj, sortKey, sort[sortKey]);
  }
  return obj;
};
