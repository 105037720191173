import React, { useState } from "react";

import { Modal, Select } from "antd";
import { Closable } from "../../../../components/Modal/show";

export type SelectPrimaryNodeModalProps = {
  onOk: (node: any) => void;
  nodes: any[];
  defaultValue: any;
};

const SelectPrimaryNodeModal = ({ nodes, onOk, close, defaultValue }: SelectPrimaryNodeModalProps & Closable) => {
  const [selectedNode, setSelectedNode] = useState<any>();

  const handleSubmitAction = () => {
    if (selectedNode) {
      onOk(selectedNode);
      close();
    }
  };

  const handleOnChange = (value: any) => {
    setSelectedNode(value);
  };

  return (
    <Modal
      title="Change Primary Node"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !selectedNode,
      }}
    >
      <p className="mb-xs">Select Node</p>
      <Select defaultValue={defaultValue} className="w-100" data-testid="node-testId" onChange={handleOnChange}>
        {nodes?.map((option) => (
          <Select.Option key={option.nodeMacId} value={option.nodeMacId}>
            {option.slotName}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default SelectPrimaryNodeModal;
