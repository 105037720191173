import { Badge, DatePicker, List } from "antd";
import classNames from "classnames";
import { SurveyIcon } from "components/Icons";
import moment from "moment";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import { formatLongDate, LONG_DATE_FORMAT } from "utils/date";
import { useEodSurveysFilter } from "../hooks/use-eod-survey-filter";
import { EodSurveyRecord, EodSurveyStatus } from "./types";

interface IEodSurveySideBarProps {
  surveys: EodSurveyRecord[];
  location?: LocationWithPmsProperty;
  onSurveyFocused: (record: EodSurveyRecord) => void;
  focusedSurvey?: EodSurveyRecord;
}

const getEodSurveyKey = (r: EodSurveyRecord) => `${r.pmsId}-${r.propertyId}-${r.surveyMonkeyId}`;

const EodSurveySideBar = ({ surveys, location, onSurveyFocused, focusedSurvey }: IEodSurveySideBarProps) => {
  const { surveyDate, setSurveyDate, displayedSurveys } = useEodSurveysFilter(surveys, location);

  return (
    <div className="bg-light-gray h-36r">
      <div className="p-l">
        <div className="p-xs">
          <DatePicker
            data-testid="eod-survey-date-filter"
            className="mr-m"
            placeholder="Filter by date"
            value={!surveyDate ? undefined : moment(surveyDate)}
            onChange={(value) => setSurveyDate(value?.toDate())}
            format={LONG_DATE_FORMAT}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div>
        <List itemLayout="horizontal">
          {displayedSurveys.map((survey) => (
            <List.Item
              key={getEodSurveyKey(survey)}
              className={classNames("font-weight-bold cursor-pointer px-l", {
                "bg-white": focusedSurvey && getEodSurveyKey(survey) === getEodSurveyKey(focusedSurvey),
                "hover:bg-white": !focusedSurvey || getEodSurveyKey(survey) !== getEodSurveyKey(focusedSurvey),
              })}
              onClick={() => onSurveyFocused(survey)}
            >
              <div className="p-s">
                <span>
                  <SurveyIcon />
                  <span className="px-s">{formatLongDate(survey.createdAt, location?.timezone)}</span>
                </span>
              </div>
              <div>
                <Badge dot color={survey.status === EodSurveyStatus.PENDING ? "gold" : "green"} />
              </div>
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

export default EodSurveySideBar;
