/* eslint-disable no-console */
import { forEach } from "lodash";

const WRAPPER_KEY = "sensorFlow.";

const setItem = (key: string, data: any) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (error) {
    console.warn(error);
  }
};

const getItem = <T = any>(key: string) => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data) as T;
    }
  } catch (error) {
    console.warn(error);
  }
};

const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.warn(error);
  }
};

export const StoreHelper = {
  setItem,
  getItem,
  removeItem,
};

export const WrapperStorageHelper = {
  setItem: (key: string, data: any) => setItem(WRAPPER_KEY + key, data),
  getItem: <T>(key: string) => getItem<T>(WRAPPER_KEY + key),
  removeItem: (key: string) => removeItem(WRAPPER_KEY + key),
  clearAll: (onlyWrapper = true) => {
    if (!onlyWrapper) localStorage.clear();
    forEach(localStorage, () => {
      const key = localStorage.key(localStorage.length - 1);
      if (key && new RegExp(`^(${WRAPPER_KEY})`).test(key || "")) removeItem(key);
    });
  },
};
