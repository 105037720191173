import { FilterOption } from "types/types";

export interface FilterDropdownProps {
  filterOptions: Array<FilterOption>;
  onOptionChange: (option: FilterOption, value: any, key: string) => void;
  waitApplyAll?: boolean;
  currentFilter?: any;
  onFilterChange?: (filter: any) => void;
}

export interface OptionPendingChange {
  option: FilterOption;
  value: any;
  key: string;
}

export const FILTER_GROUP_NAME_DEFAULT = "Default";

export const FILTER_DROPDOWN_COUNT_HIDDEN_VALUE = -1;
