import { useContext } from "react";

import { RoleContext } from "contexts/role-context";
import { canPerform } from "../../permissionHelpers";
import { Permission } from "../../pacts/permission";

type CanProps = {
  requiredPermission?: Permission;
  requiredRole?: string | string[];
  additionalData?: any;
  yes?: any;
  no?: any;
};

const Can = ({ requiredPermission, requiredRole, additionalData, yes = null, no = null }: CanProps) => {
  const role = useContext(RoleContext);
  const { name } = role || {};
  if (!role) return null;
  // return yes;
  if (requiredRole) {
    if (typeof requiredRole === "string") return requiredRole === name ? yes : no;
    return requiredRole?.some((roleName) => roleName === name) ? yes : no;
  }
  if (requiredPermission) {
    return canPerform(role, requiredPermission, additionalData) ? yes : no;
  }
  return no;
};

export default Can;
