/* eslint-disable react/no-danger */
import { RightOutlined } from "@ant-design/icons";
import { Affix, Button, Col, Row, Typography } from "antd";
import React from "react";
import { isValidUrl } from "utils/url";

export interface OnboardStepProps {
  steps: number;
  currentStep: number;
  image: string;
  title: string;
  desc: string;
  isLoading?: boolean;
  onNext: () => Promise<void> | void;
  onSkip: () => Promise<void> | void;
}

const OnboardStep: React.FC<OnboardStepProps> = ({
  currentStep,
  steps,
  title,
  desc,
  image,
  isLoading,
  onNext,
  onSkip,
}) => {
  return (
    <Row gutter={[16, 40]}>
      <Col span={24} className="d-flex justify-content-center align-items-center mt-32">
        <div className="logo-brand d-flex justify-content-center align-items-center">
          <div className="logo-brand-blur" />
          <div className="logo-brand-live d-flex justify-content-center align-items-center">
            <div className="logo-brand-content d-flex justify-content-center align-items-center">
              {isValidUrl(image) ? <img src={image} alt={`step-${currentStep}`} /> : image}
            </div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Typography.Title level={4}>{title}</Typography.Title>
        {desc && (
          <Typography.Text>
            <span dangerouslySetInnerHTML={{ __html: desc }} />
          </Typography.Text>
        )}
      </Col>
      <Affix offsetBottom={0} className="onboarding-action">
        <Row gutter={[8, 8]}>
          {currentStep === steps ? (
            <Col span={24}>
              <Button type="primary" onClick={onSkip} loading={isLoading}>
                Get started
              </Button>
            </Col>
          ) : (
            <>
              <Col span={12}>
                <Button type="default" onClick={onSkip} loading={isLoading}>
                  Skip
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" onClick={onNext} disabled={isLoading}>
                  Next <RightOutlined />
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Affix>
    </Row>
  );
};

export default OnboardStep;
