import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { tz as timezone } from "moment-timezone";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { useGetTimeZoneListQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { TimeZoneAutoCompleteProps } from "./TimeZoneAutoComplete.d";

const displayValue = (timeZone: string) => `(GMT ${timezone(timeZone).format("Z")}) ${timeZone}`;

const TimeZoneAutoComplete = ({ handleChangedValue, value: currentValue }: TimeZoneAutoCompleteProps) => {
  const [value, setValue] = useState(currentValue || "");
  const [initOptions, setInitOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [options, setOptions] = useState<Array<{ value: string }>>(initOptions);

  const { loading } = useGetTimeZoneListQuery({
    onCompleted: (data) => {
      if (!data?.sensorflow_timezones) return;

      const formatOptions = data.sensorflow_timezones.map(({ timezone: name }) => ({
        label: displayValue(name),
        value: name,
      }));

      setInitOptions(formatOptions);
      setOptions(formatOptions);
    },
  });

  useEffect(() => {
    setValue(currentValue || "");
  }, [currentValue]);

  const handleSearch = (searchInput: string) => {
    const searchValue = searchInput.toUpperCase();
    const filtered = filter(initOptions, ({ value: name }) => name.toUpperCase().includes(searchValue));
    setOptions(filtered);
  };

  const onBlur = () => {
    if (isEmpty(value)) handleChangedValue?.("");
    else if (!some(initOptions, ({ value: name }) => name === value)) handleChangedValue?.("");
    else handleChangedValue?.(value);
  };

  return (
    <AutoComplete
      className="w-100"
      options={options}
      onSearch={handleSearch}
      onChange={setValue}
      onBlur={onBlur}
      onSelect={handleChangedValue}
      value={value}
    >
      <Input.Search size="large" placeholder="Select Timezone" loading={loading} />
    </AutoComplete>
  );
};

export default TimeZoneAutoComplete;
