import React from "react";

import isNil from "lodash/isNil";

const GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_SUCCESS = -65;
const GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_WARNING = -70;
const GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_PERCENTAGE_SUCCESS = 67;
const GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_PERCENTAGE_WARNING = 33;

type GetConnectionStatusProps = {
  networkConnectionStatus?: string;
  gatewayHealthData: {
    measurementType: string;
    time: string;
    value: number;
  }[];
  wlanLinkGatewayHealthData: {
    measurementType: string;
    time: string;
    value: number;
  }[];
  wlan1LinkGatewayHealthData: {
    measurementType: string;
    time: string;
    value: number;
  }[];
};

export const getConnectionStatusDisplayText = (gateway: GetConnectionStatusProps) => {
  let textClassName = "";
  const wlanLevel = gateway.gatewayHealthData.find((healthData) => healthData.measurementType === "wlan_level")?.value;
  const wlanLink = gateway.wlanLinkGatewayHealthData?.find((healthData) => healthData.measurementType === "wlan_link");
  const wlan1Link = gateway.wlan1LinkGatewayHealthData?.find(
    (healthData) => healthData.measurementType === "wlan1_link"
  );
  if (wlanLink && wlan1Link) {
    const getClassName = (link: { value: number }) => {
      if (link.value > GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_PERCENTAGE_SUCCESS) {
        textClassName = "text-success";
      } else if (link.value > GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_PERCENTAGE_WARNING) {
        textClassName = "text-warning";
      } else {
        textClassName = "text-error";
      }
      return textClassName;
    };
    const wlanTextClassName = getClassName(wlanLink);
    const wlan1TextClassName = getClassName(wlan1Link);

    const isInternal = gateway.networkConnectionStatus === "Wifi-Internal";
    const isDongle = gateway.networkConnectionStatus === "Wifi-Dongle";

    return (
      <div>
        <div className={wlanTextClassName}>{`${isInternal ? "(*)" : ""} Internal - ${wlanLink.value}%`}</div>
        <div className={wlan1TextClassName}>{`${isDongle ? " (*)" : ""} Dongle - ${wlan1Link.value}%`}</div>
      </div>
    );
  }

  if (gateway.networkConnectionStatus !== "up" && !isNil(wlanLevel)) {
    if (wlanLevel > GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_SUCCESS) {
      textClassName = "text-success";
    } else if (wlanLevel > GATEWAY_WIFI_SIGNAL_STRENGTH_THRESHOLD_WARNING) {
      textClassName = "text-warning";
    } else {
      textClassName = "text-error";
    }
  }
  return <span className={textClassName}>{gateway.networkConnectionStatus === "up" ? "Ethernet" : wlanLevel}</span>;
};

export default getConnectionStatusDisplayText;
