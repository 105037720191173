import { Button, Col, Modal, Row, Typography, message } from "antd";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useUpdateKeyEntryCommentMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback } from "react";
import { KeyEntryCommentStatus } from "types/InstallationMode.d";

export interface CommentModalProps {
  comment?: string;
  onResolved?: () => Promise<void> | void;
  open?: boolean;
  onClose?: () => void;
  keyEntryId?: number;
}

export const CommentModal: React.FC<CommentModalProps> = ({ comment = "", onClose, onResolved, open, keyEntryId }) => {
  const [updateComment, { loading }] = useUpdateKeyEntryCommentMutation({
    onError: errorHandler.handleError,
    onCompleted: () => {
      message.success("Successfully solved");
      if (isFunction(onResolved)) onResolved();
    },
  });

  const handleUpdateComment = useCallback(async () => {
    if (!keyEntryId) return;
    await updateComment({ variables: { comment, commentStatus: KeyEntryCommentStatus.RESOLVED, keyEntryId } });
  }, [updateComment, comment, keyEntryId]);

  return (
    <Modal title="Comments" centered footer={null} onCancel={onClose} width={400} open={open}>
      <Typography.Text>{comment}</Typography.Text>

      <Row gutter={[16, 16]} className="mt-xl">
        <Col span={12}>
          <Button type="default" className="text-primary w-100" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            className="ant-btn ant-btn-primary w-100"
            onClick={handleUpdateComment}
            loading={loading}
          >
            Resolve
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
