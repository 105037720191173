import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Input, message, Modal, Select, TreeSelect } from "antd";
import isEmpty from "lodash/isEmpty";
import ModalWrapper from "components/Modal/Wrapper";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { transformAutosetToAutosetInput, transformAutosetsToTreeNodes } from "utils/DataTransformer";
import { HvacSystemTypeEnum, InfraPhaseEnum } from "../Infrastructures.d";
import ConfirmToAddCompressorModal from "./ConfirmToAddCompressorModal/ConfirmToAddCompressorModal";
import useAddCompressorsAction from "./AddCompressorAction";

interface AddCompressorModalProps {
  roomAutosets: any[];
  onSuccess: () => void;
}

const AddCompressorModal = ({
  roomAutosets,
  modalWrapperProps,
  onSuccess,
  closeModal,
}: AddCompressorModalProps & ModalWrapperProps) => {
  const { locationId } = useParams<{ locationId: string }>();

  const [autosets, setAutosets] = useState<string[]>([]);
  const [compressorType, setCompressorType] = useState<InfraPhaseEnum>();
  const [hvacSystemType, setHvacSystemType] = useState<string>();
  const [ctMultiplier, setCtMultiplier] = useState<number>(1);

  const [confirmModalRef, openConfirmModal] = useModalWrapperTrigger();

  const isInvalidInput = isEmpty(autosets) || isEmpty(InfraPhaseEnum[compressorType!]);

  const onSuccessCallback = () => {
    closeModal();
    onSuccess();
  };

  const { addCompressors, isLoading } = useAddCompressorsAction({ onSuccessCallback });

  const onConfirmedCallback = () => {
    if (!compressorType) {
      message.error("Missing required field Compressor Energy Setting");
      return;
    }
    if (!hvacSystemType) {
      message.error("Missing required field HVAC System Type");
      return;
    }
    if (!ctMultiplier) {
      message.error("Missing required field CT Multiplier");
      return;
    }

    addCompressors(locationId, compressorType, hvacSystemType, ctMultiplier, transformAutosetToAutosetInput(autosets));
  };

  const handleCompressorTypeChange = (value: any) => setCompressorType(value);
  const handleHvacSystemTypeChange = (value: any) => setHvacSystemType(value);
  const handleCtMultiplierChange = (e: any) => setCtMultiplier(parseFloat(e.target.value));

  const autosetSelectorProps = {
    treeData: transformAutosetsToTreeNodes(roomAutosets),
    value: autosets,
    onChange: (data: string[]) => setAutosets(data),
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: "Please Select Autoset",
    showSearch: true,
    className: "w-100",
  };

  return (
    <Modal
      title="Add 1:1 Compressor"
      centered
      onOk={openConfirmModal}
      width={450}
      okText="Confirm"
      confirmLoading={isLoading}
      okButtonProps={{
        disabled: isInvalidInput,
      }}
      {...modalWrapperProps}
    >
      <TreeSelect {...autosetSelectorProps} />

      <div className="mt-xl">
        <p className="mb-xs">Compressor Energy Setting</p>
        <Select className="w-100" onSelect={handleCompressorTypeChange} value={compressorType}>
          <Select.Option value={InfraPhaseEnum.INFRA_1_PHASE}>1 Phase</Select.Option>
          <Select.Option value={InfraPhaseEnum.INFRA_3_PHASES}>3 Phases</Select.Option>
        </Select>
      </div>

      <div className="mt-xl">
        <p className="mb-xs">HVAC System Type</p>
        <Select className="w-100" onSelect={handleHvacSystemTypeChange} value={hvacSystemType}>
          <Select.Option value={HvacSystemTypeEnum.VRV}>VRV</Select.Option>
          <Select.Option value={HvacSystemTypeEnum.SPLIT}>Split</Select.Option>
        </Select>
      </div>

      <div className="mt-xl d-none">
        <p className="mb-xs">CT Multiplier</p>
        <Input
          type="number"
          min={0}
          value={ctMultiplier}
          placeholder="Enter CT Multiplier"
          onChange={handleCtMultiplierChange}
        />
      </div>

      <ModalWrapper
        ref={confirmModalRef}
        modal={ConfirmToAddCompressorModal}
        props={{
          onConfirmedCallback,
          amount: autosets.length,
        }}
      />
    </Modal>
  );
};

export default AddCompressorModal;
