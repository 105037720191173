import React, { useContext } from "react";
import { Table } from "antd";
import moment from "moment-timezone";
import classNames from "classnames";
import { CopyFilled, DeleteFilled, EditFilled, EditOutlined } from "@ant-design/icons";
import CopyButton from "components/CopyButton";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { getNodeListLookerDashboardLink } from "utils/link";
import isNil from "lodash/isNil";
import "./MappedNodes.scss";
import ActionButton from "components/ActionButton";
import useLocationDateTime from "../../../hooks/use-location-datetime";
import { MappedNodesProps } from "./MappedNodes.d";
import InfrastructureDetailsContext from "../InfrastructureDetails.Context";
import { MappedNodeDataType } from "../InfrastructureDetails.d";

const MappedNodesTable = ({
  dataSource,
  handleAdjustMappedNode,
  handleUnmapNode,
  startChangeGateway,
}: MappedNodesProps) => {
  const infraDetails = useContext(InfrastructureDetailsContext);
  const infraType = new InfrastructureType(infraDetails?.type!);
  const { formatDateTime, formatFromNow } = useLocationDateTime();

  const columns = [
    {
      title: <span className="header-title">slot name</span>,
      dataIndex: "slotName",
    },
    {
      title: <span className="header-title">node id</span>,
      render: (data: MappedNodeDataType) => (
        <div className="node-id-block">
          <a target="_blank" rel="noopener noreferrer" href={getNodeListLookerDashboardLink(data.nodeMacId)}>
            <span className="node-id">{data.nodeMacId}</span>
          </a>
          <CopyButton input={data.nodeMacId} icon={<CopyFilled />} />
        </div>
      ),
    },
    {
      title: <span className="header-title">node type</span>,
      dataIndex: "nodeTypeLabel",
    },
    {
      title: <span className="header-title">last seen</span>,
      render: (data: any) => (
        <div className="last-seen">
          <svg className={classNames("status", data.status)} />
          <span>{data.lastSeen ? formatFromNow(data.lastSeen) : "Never seen"}</span>
        </div>
      ),
      sorter: (element1: any, element2: any) => {
        if (moment(element1.lastSeen).isAfter(element2.lastSeen)) return 1;
        if (moment(element1.lastSeen).isBefore(element2.lastSeen)) return -1;
        return 0;
      },
    },
    {
      title: <span className="header-title">associated gateway</span>,
      render: (data: MappedNodeDataType) => {
        return (
          <div className="d-flex align-items-center">
            {data?.mappedGateway?.gatewayName}
            <ActionButton
              btnClassName="p-none"
              customIcon={<EditOutlined className="ml-s" />}
              onClick={() => startChangeGateway(data.positionId)}
            />
          </div>
        );
      },
    },
    {
      title: <span className="header-title">signal strength</span>,
      render: (data: MappedNodeDataType) => (!isNil(data.signalStrength) ? data.signalStrength.toFixed(2) : "-"),
      sorter: (element1: any, element2: any) =>
        parseFloat(element1.signalStrength) - parseFloat(element2.signalStrength),
    },
    {
      title: <span className="header-title">mapped on</span>,
      render: (data: MappedNodeDataType) => data.mappedTime && formatDateTime(data.mappedTime),
      sorter: (element1: any, element2: any) => {
        if (moment(element1.mappedTime).isAfter(element2.mappedTime)) return 1;
        if (moment(element1.mappedTime).isBefore(element2.mappedTime)) return -1;
        return 0;
      },
    },
    {
      title: <span className="header-title">settings</span>,
      dataIndex: "settingsLabel",
      hide: !infraType.isCompressor(),
    },
    {
      title: "",
      render: (data: MappedNodeDataType) => (
        <EditFilled
          className="cursor-pointer"
          onClick={() => handleAdjustMappedNode(data)}
          data-testid="btn-edit-mapped-node-testId"
        />
      ),
    },
    {
      title: "",
      render: (data: MappedNodeDataType) => (
        <DeleteFilled className="cursor-pointer" onClick={() => handleUnmapNode(data.nodeMacId)} />
      ),
    },
  ];

  const filteredColumns = columns.filter((column) => !column.hide);

  return <Table rowKey="nodeMacId" columns={filteredColumns} dataSource={dataSource} pagination={false} />;
};

export default MappedNodesTable;
