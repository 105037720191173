import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";

interface HideSetpointToggleProps {
  toggleHideSetpoint?: (e: boolean) => void;
  hideSetpoint?: boolean;
}

export const HideSetpointToggle = ({ toggleHideSetpoint, hideSetpoint }: HideSetpointToggleProps) => {
  const toggleHandle = () => {
    toggleHideSetpoint?.(!hideSetpoint);
  };

  return (
    <Button
      type="link"
      className="ml-xs"
      data-testid="hideSetpointBtn"
      icon={
        hideSetpoint ? (
          <Tooltip title="Guests will not see any setpoint limits on the thermostat">
            <EyeInvisibleOutlined className="fs-xl vertical-align-center" />
          </Tooltip>
        ) : (
          <Tooltip title="Guests will see the setpoint is limited on the thermostat">
            <EyeOutlined className="fs-xl vertical-align-center" />
          </Tooltip>
        )
      }
      onClick={toggleHandle}
    />
  );
};

export default HideSetpointToggle;
