import React from "react";
import { ReactComponent as Logo } from "../Icons/assets/logo-white.svg";
import "./PublicHeader.scss";

const PublicHeader = () => {
  return (
    <div className="w-100 d-flex py-s justify-content-center logo-container">
      <Logo className="logo" />
    </div>
  );
};

export default PublicHeader;
