import React, { useState } from "react";

import classnames from "classnames";
import { Collapse } from "antd";
import { scroller } from "react-scroll";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Gateway } from "pacts/app-webcore/hasura-webcore.graphql";
import "./GatewayCollapse.scss";
import { GatewaySetting } from "../NameGatewaysPageDetail/NameGatewaysPageDetail.d";
// eslint-disable-next-line import/no-cycle
import { getGatewayNameFromGatewayAndPrefix } from "../NameGatewaysPage/NameGatewaysPage";

type GatewayCollapseProps = {
  gatewaySetting: GatewaySetting;
  handleClickAddGateway: () => void;
  handleClickRemoveGateway: (index: number) => void;
};

const GatewayCollapse = ({ gatewaySetting, handleClickAddGateway, handleClickRemoveGateway }: GatewayCollapseProps) => {
  const [activeGatewayId, setActiveGatewayId] = useState<string>("1");

  const handleClickGateway = (gateway: Partial<Gateway>) => {
    setActiveGatewayId(gateway.gatewayId || "");

    setTimeout(() => {
      scroller.scrollTo(`gateway-${gateway.gatewayId || ""}`, {
        smooth: true,
        containerId: "gateway-list",
      });
    }, 10);
  };

  const isActiveGateway = (gatewayId: string) => activeGatewayId === gatewayId;

  return (
    <>
      <Collapse collapsible="header" className="gateway-collapse">
        {gatewaySetting.gateways.map((gateway, index) => (
          <div
            className={classnames({
              "collapse-gateway-name": true,
              active: isActiveGateway(gateway.gatewayId || ""),
            })}
            key={index}
          >
            <span role="none" className="text" onClick={() => handleClickGateway(gateway)}>
              {getGatewayNameFromGatewayAndPrefix(gateway.gatewayName || "", gatewaySetting.prefix || "", index)}
            </span>
            <DeleteOutlined className="detele-gateway" onClick={() => handleClickRemoveGateway(index)} />
          </div>
        ))}
        <div className="button-add-gateway">
          <PlusOutlined className="icon-add-gateway" />
          <span role="none" className="text" onClick={handleClickAddGateway}>
            Add Gateway
          </span>
        </div>
      </Collapse>
    </>
  );
};

export default GatewayCollapse;
