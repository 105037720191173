import React, { useEffect, useCallback } from "react";

import { Form, Input } from "antd";
import { Element } from "react-scroll";
import { Gateway } from "pacts/app-webcore/hasura-webcore.graphql";
import "./NameGatewaysPageDetail.scss";
import { getGatewayHardwareTypeDisplayText } from "pages/Gateway/helpers";
import { gatewayNamePattern, GatewaySetting } from "./NameGatewaysPageDetail.d";

type GatewayDetailProps = {
  onGatewaySettingChange: (credentials: GatewaySetting) => void;
  gatewaySetting: GatewaySetting;
  disableSubmit: (value: boolean) => void;
};

const NameGatewaysPageDetail = ({ onGatewaySettingChange, gatewaySetting, disableSubmit }: GatewayDetailProps) => {
  const [form] = Form.useForm();

  const renderGatewayNamePreview = (gateway: Partial<Gateway>, index: number) => {
    return (
      <div>
        <span>Preview: </span>
        <span className="font-weight-bold">
          {gatewaySetting.prefix || gatewaySetting.defaultPrefix}-{gateway.gatewayName || `Gateway-${index + 1}`}
        </span>
        <span>/{getGatewayHardwareTypeDisplayText(gateway.hardwareType)}</span>
      </div>
    );
  };

  const checkFormValidity = useCallback(async () => {
    try {
      await form.validateFields();
      disableSubmit(false);
    } catch (e) {
      disableSubmit(true);
    }
  }, [form, disableSubmit]);

  useEffect(() => {
    form.setFieldsValue(gatewaySetting);
    checkFormValidity();
  }, [gatewaySetting, form, checkFormValidity]);

  return (
    <div>
      <Form form={form} className="name-gateways-form" onValuesChange={onGatewaySettingChange} layout="vertical">
        <Form.Item
          name="prefix"
          label="Gateway Name Prefix"
          extra="This appears in front of all Gateway Names."
          rules={[
            {
              required: form.getFieldValue("prefix")!!,
              message: "Gateway name only contains numbers, letters, dash (-), or underscore(_)!",
              pattern: new RegExp(gatewayNamePattern),
            },
          ]}
        >
          <Input placeholder={gatewaySetting.defaultPrefix} />
        </Form.Item>

        {gatewaySetting.gateways.map((gateway, index) => (
          <Element name={`gateway-${gateway.gatewayId}`} key={index + 1}>
            <Form.Item
              name={["gateways", index, "gatewayName"]}
              label={`Gateway ${index + 1} Name`}
              extra={renderGatewayNamePreview(gateway, index)}
              rules={[
                {
                  required: true,
                  message: "Gateway name only contains numbers, letters, dash (-), or underscore(_)!",
                  pattern: new RegExp(gatewayNamePattern),
                },
              ]}
            >
              <Input placeholder={`Gateway-${index + 1}`} />
            </Form.Item>
          </Element>
        ))}
      </Form>
    </div>
  );
};

export default NameGatewaysPageDetail;
