import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";

enum LOGTYPE {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

enum NodeENVConfig {
  DEVELOPMENT = "development",
  TEST = "test",
  PRODUCTION = "production",
}

export class Logger {
  private nodeEnv: string;

  private enableErrorReporting: boolean;

  constructor(nodeEnv: string, enableErrorReporting: boolean) {
    this.nodeEnv = nodeEnv;
    this.enableErrorReporting = enableErrorReporting;

    if (this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID!, {
        release: process.env.REACT_APP_VERSION,
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });
    }

    if (this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_VERSION,
      });
      // passing a callback to LogRocket.getSessionURL to ensure sessionURL is present
      // this takes a short while so if an error is thrown too early in an app there might not be a session URL if LogRocket hasn't init fully yet
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra("logRocketSessionURL", sessionURL);
        });
      });
    }
  }

  private log(logType: LOGTYPE, args: any) {
    if (this.nodeEnv === NodeENVConfig.DEVELOPMENT) {
      // eslint-disable-next-line no-console
      console[logType](...args);
    }

    if (this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) {
      LogRocket[logType](...args);
    }

    if ((this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) && logType === LOGTYPE.ERROR) {
      // sentry only takes 2 args, the error as the first, and an object containing key-value attributes for the second
      Sentry.captureException(args[0]);
    }
  }

  public info(...args: any) {
    this.log(LOGTYPE.INFO, args);
  }

  public warn(...args: any) {
    this.log(LOGTYPE.WARN, args);
  }

  public error(...args: any) {
    this.log(LOGTYPE.ERROR, args);
  }

  // eslint-disable-next-line class-methods-use-this
  public reactError(error: any, componentStack: string) {
    LogRocket.captureException(error, {
      extra: {
        componentStack,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public identify(userId = "", name = "", email = "") {
    if (this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) {
      LogRocket.identify(`${userId}`, {
        name,
        email,
      });
    }
    if (this.nodeEnv === NodeENVConfig.PRODUCTION || this.enableErrorReporting) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: userId, username: name, email });
      });
    }
  }
}

const { NODE_ENV, REACT_APP_DEV_ENABLE_ERROR_REPORTING } = process.env;

const logger = new Logger(NODE_ENV, REACT_APP_DEV_ENABLE_ERROR_REPORTING === "true");

export default logger;
