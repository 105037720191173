import React from "react";
import clsx from "classnames";
import { InputNumber, Divider, Row, Col, Button, Form, message as messageApi, Switch } from "antd";
import { useUpdateOtaSettingsMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import LocationOtaSettingTimeWindowInput from "./LocationOtaSettingTimeWindowInput";
import { getOtaSettingDefaultValue, LocationOtaSettingFormField } from "./helpers";
import { LocationOtaSetting, LocationOtaSettingFormValues } from "./LocationOtaSettings.d";
import { TIME_24H_FORMAT } from "../../../utils/date";

export interface LocationOtaSettingFormProps {
  currentSetting: LocationOtaSetting;
  onSettingChange?: (newSetting?: Partial<LocationOtaSetting> | null) => void;
}

const LocationOtaSettingForm = ({ currentSetting, onSettingChange }: LocationOtaSettingFormProps) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm<LocationOtaSettingFormValues>();
  const [updateLocationOtaSetting] = useUpdateOtaSettingsMutation({
    onCompleted: () => {
      messageApi.success(`Settings updated`);
    },
  });

  const handleSubmit = React.useCallback(
    async (values: LocationOtaSettingFormValues) => {
      try {
        const { otaMaxRetries, otaTimeWindow, otaEnabled } = values;
        const input = {
          otaIsEnabled: otaEnabled,
          otaMaxRetries,
          otaWindowStartTime: otaTimeWindow ? otaTimeWindow[0].format(TIME_24H_FORMAT) : "",
          otaWindowEndTime: otaTimeWindow ? otaTimeWindow[1].format(TIME_24H_FORMAT) : "",
        };
        await updateLocationOtaSetting({
          variables: {
            primaryKey: {
              locationId: currentSetting.locationId,
            },
            updateLocationInput: input,
          },
        });
        onSettingChange?.({
          locationId: currentSetting.locationId,
          ...input,
        });
      } catch (error) {
        errorHandler.handleError(error);
      }
    },
    [updateLocationOtaSetting, currentSetting, onSettingChange]
  );
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={getOtaSettingDefaultValue(currentSetting)}
      onFinish={handleSubmit}
    >
      <h3 className="header-title font-weight-bold mb-xl">OTA Windows</h3>
      <Form.Item name={LocationOtaSettingFormField.OTA_ENABLED} label="OTA Enabled" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label={<h4 className="font-weight-bold">Select Start/Stop hours</h4>}>
        <LocationOtaSettingTimeWindowInput
          className={clsx({
            "w-90": screens.mobileOnly,
            "w-50": screens.tabletOnly,
            "w-30": screens.desktopUp,
          })}
          data-testid="start-stop-picker-testId"
          showSecond={false}
          format={TIME_24H_FORMAT}
          order={false}
        />
      </Form.Item>
      <Form.Item
        name={LocationOtaSettingFormField.OTA_MAX_RETRIES}
        label={<h4 className="font-weight-bold">OTA retries per Roll out</h4>}
        trigger="onChange"
      >
        <InputNumber min={1} max={10} placeholder="1-10" />
      </Form.Item>
      <Divider />
      <Row justify="end" gutter={10} className="px-xl">
        <Col>
          <Button onClick={() => form.resetFields()}>Clear</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => form.submit()}>
            Apply
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default LocationOtaSettingForm;
