import React, { useEffect } from "react";

import { Form, Input } from "antd";
import { Sensorflow_Location_Metadata } from "pacts/app-webcore/hasura-webcore.graphql";
import "./AddWifiCredentialsPageDetail.scss";

type GatewayDetailProps = {
  onWifiCredentialsChange: (credentials: Partial<Sensorflow_Location_Metadata>) => void;
  wifiCredentials: Partial<Sensorflow_Location_Metadata> | undefined;
};

const AddWifiCredentialsPageDetail = ({ onWifiCredentialsChange, wifiCredentials }: GatewayDetailProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (wifiCredentials) {
      form.setFieldsValue(wifiCredentials);
    }
  }, [form, wifiCredentials]);

  return (
    <div>
      <Form
        form={form}
        className="add-wifi-credentials-form"
        onValuesChange={onWifiCredentialsChange}
        layout="vertical"
      >
        <Form.Item name="wifiSsid" label="Location WiFi SSID" extra="Leave blank if using Ethernet.">
          <Input placeholder="Somerset Sukhumvit Thonglor" />
        </Form.Item>

        <Form.Item name="wifiPassword" label="Location WiFi Password" extra="Leave blank if using Ethernet.">
          <Input placeholder="WelcomeSukhumvit2020" />
        </Form.Item>

        <Form.Item name="secondaryWifiSsid" label="Secondary WiFi SSID">
          <Input placeholder="SensorFlow-DHL" />
        </Form.Item>

        <Form.Item name="secondaryWifiPassword" label="Secondary WiFi Password">
          <Input placeholder="sensorflow999" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddWifiCredentialsPageDetail;
