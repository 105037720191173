/* eslint-disable no-underscore-dangle */
import { Line as LineChartjs, LinearComponentProps } from "react-chartjs-2";
import React, { ReactNode, useState } from "react";

import BaseLinear from "../BaseLinear";

const ARROW_POSITION_X = 20;
const ARROW_POSITION_Y = 5;

type TooltipData = {
  title: string;
  body: { value: string; backgroundColor: string; datasetIndex: number };
};

type Props = {
  renderTooltip: (data: TooltipData | null) => ReactNode;
  xAxesTicksCallback: (value: string) => string | string[];
};

const tooltipLine = {
  beforeDraw: (chart: any) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const { ctx } = chart;
      ctx.save();
      const activePoint = chart.tooltip._active[0];

      ctx.beginPath();
      ctx.setLineDash([2, 2]);
      ctx.moveTo(activePoint._model.x, activePoint._model.y);
      ctx.lineTo(activePoint._model.x, chart.chartArea.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = activePoint._model.backgroundColor;
      ctx.stroke();
      ctx.restore();
    }
  },
};

const defaultOptions = {
  tooltips: {
    position: "nearest",
  },
};

const Line = (props: Props & Partial<LinearComponentProps>) => {
  const { data, options, renderTooltip, xAxesTicksCallback, ...restProps } = props;

  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

  const setTooltipContent = (tooltipModel: any) => {
    const dataPoint = tooltipModel.dataPoints[0];
    setTooltipData({
      title: tooltipModel.title?.[0] || "",
      body: {
        value: dataPoint.value,
        backgroundColor: tooltipModel.labelColors[0].backgroundColor,
        datasetIndex: dataPoint.datasetIndex,
      },
    });
  };

  return (
    <BaseLinear
      chart={LineChartjs}
      data={data}
      options={{ ...defaultOptions, ...options }}
      tooltipContent={renderTooltip(tooltipData)}
      setTooltipContent={setTooltipContent}
      tootipOffset={{
        x: ARROW_POSITION_X,
        y: ARROW_POSITION_Y,
      }}
      plugins={[tooltipLine]}
      xAxesTicksCallback={xAxesTicksCallback}
      {...restProps}
    />
  );
};

export default Line;
