import { Breadcrumb, Tabs } from "antd";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ExclamationCircleOutlined, LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { get } from "lodash";
import KeyDetail from "./KeyDetail/KeyDetail";
import {
  useKeyBeforeAndAfterLazyQuery,
  useKeyQuery,
  useLocationQuery,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../errorHandler";
import ActivityLog from "./KeyActivityLog/ActivityLog";
import KeyConfiguration from "./KeyConfiguration/KeyConfiguration";

export enum ActiveTab {
  KEY_DETAIL = "key-details",
  ACTIVITIES = "activity-log",
  CONFIGURATIONS = "configurations",
}

const KeyDetails = () => {
  const { keyId, locationId, activeTab } = useParams<{ keyId: string; locationId: string; activeTab: string }>();
  const [locationName, setLocationName] = useState<string>();
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(activeTab);
  const [keyDetail, setKeyDetail] = useState<any>();
  const [prevKey, setPrevKey] = useState<any>();
  const [nextKey, setNextKey] = useState<any>();
  const [warningFlag, setWarningFlag] = useState(false);

  const handleTabChange = (activeKey: string) => {
    setCurrentActiveTab(activeKey);
    window.history.pushState({}, "", `/locations/${locationId}/keys/${keyId}/${activeKey}`);
  };

  useLocationQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setLocationName(data.location?.locationName);
    },
    onError: errorHandler.handleError,
  });

  const [keyBeforeAndAfterLazyQuery] = useKeyBeforeAndAfterLazyQuery({
    onCompleted: (data) => {
      setPrevKey(get(data, "key.location.keyBefore[0]"));
      setNextKey(get(data, "key.location.keyAfter[0]"));
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  useKeyQuery({
    variables: {
      keyId,
    },
    onCompleted: ({ key }) => {
      setKeyDetail(key);
      keyBeforeAndAfterLazyQuery({
        variables: {
          keyId: key.keyId,
          keyName: key.keyName,
        },
      });
    },
    onError: errorHandler.handleError,
  });

  const tabConfigs = [
    {
      name: (
        <span>
          {keyDetail?.keyName ?? "Key"} {warningFlag && <ExclamationCircleOutlined className="text-warning" />}
        </span>
      ),
      key: ActiveTab.KEY_DETAIL,
      component: <KeyDetail markKeyAsWarning={setWarningFlag} />,
    },
    {
      name: "Activity Log",
      key: ActiveTab.ACTIVITIES,
      component: <ActivityLog keyName={keyDetail?.keyName} />,
    },
    {
      name: "Configuration",
      key: ActiveTab.CONFIGURATIONS,
      component: <KeyConfiguration />,
    },
  ];

  return (
    <div>
      <Breadcrumb className="header-bread-crumb">
        <Breadcrumb.Item>
          <Link to="/locations">Locations</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/locations/${locationId}`}>{locationName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{keyDetail?.keyName}</Breadcrumb.Item>
      </Breadcrumb>

      <Tabs className="header-tab-bar" defaultActiveKey={activeTab} onChange={handleTabChange}>
        {tabConfigs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            <div className="d-flex justify-content-between mb-l">
              <div>
                {prevKey && (
                  <Link
                    to={`/locations/${locationId}/keys/${prevKey.positionId}/${currentActiveTab}`}
                    className="d-flex flex-row align-items-center"
                  >
                    <LeftCircleFilled className="fs-xl mr-xs" /> {prevKey.positionName}
                  </Link>
                )}
              </div>
              <div>
                {nextKey && (
                  <Link
                    to={`/locations/${locationId}/keys/${nextKey.positionId}/${currentActiveTab}`}
                    className="d-flex flex-row align-items-center"
                  >
                    {nextKey.positionName} <RightCircleFilled className="fs-xl ml-xs" />
                  </Link>
                )}
              </div>
            </div>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default KeyDetails;
