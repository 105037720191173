/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Form, Input, Button, Select, Alert, Radio, Col, Row } from "antd";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";

import {
  UserQuery,
  useRolesQuery,
  useOrganisationsForAutocompleteLazyQuery,
  OrganisationsForAutocompleteQuery,
  useLocationsForAutocompleteLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";

import { cloneDeep } from "apollo-utilities";
import uniqBy from "lodash/uniqBy";
import { FormOperations, RoleName } from "../UserCreateEdit.d";
import errorHandler from "../../../../errorHandler";
import "./UserCreateEditForm.scss";

type User = UserQuery["user"];

type Role = {
  id: string;
  name: string;
};

type Organisation = NonNullable<OrganisationsForAutocompleteQuery["organisations"]>["organisations"][number];

type Props = {
  user: User | null;
  handleSubmit: (formData: any) => void;
  loading: boolean;
  formOperation: FormOperations;
};

const { Option } = Select;

// custom hook for previous props
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const UserCreateEditForm = ({ user, handleSubmit, loading, formOperation }: Props) => {
  const [defaultOrg, setDefaultOrg] = useState();
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [locationOptions, setLocationOptions] = useState<any[]>([]);
  const [isCustomer, setIsCustomer] = useState<boolean>(false);
  const [isInstaller, setIsInstaller] = useState<boolean>(false);
  const [form] = Form.useForm();
  const prevUser = usePrevious(user);

  // query for organisations, roles, locations

  const { data: rolesData, loading: rolesQueryLoading, error: rolesQueryError } = useRolesQuery();
  const roles = rolesData?.roles;

  const [
    getOrganisations,
    { data: organisationsData, loading: organisationsQueryLoading, error: organisationsQueryError },
  ] = useOrganisationsForAutocompleteLazyQuery();
  const organisations = organisationsData?.organisations?.organisations;

  const [getDefaultOrg] = useOrganisationsForAutocompleteLazyQuery({
    onCompleted: (data) => {
      if (data.organisations.organisations.length > 0) {
        const org = data.organisations.organisations[0];
        setDefaultOrg(org);
        form.setFieldsValue({
          organisationId: org.id,
        });
      }
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    if (user && user !== prevUser) {
      // transform/flatten nested objects into flat representation for form
      const { role, locations: userLocations, organisation, isManager, isContractor, ...rest } = user;
      const formValues = {
        ...rest,
        roleId: role.id,
        organisationId: organisation.id,
        locationIds: userLocations.map((location) => location.id),
        isManager: !!isManager,
        isContractor: !!isContractor,
      };
      setSelectedLocations(formValues.locationIds);
      setIsCustomer(role.name === RoleName.HotelViewer);
      setIsInstaller(role.name === RoleName.Installer);
      // set props into form
      form.setFieldsValue(formValues);
    }
  }, [user, form, formOperation, prevUser]);

  useEffect(() => {
    if (formOperation === "CREATE") {
      getDefaultOrg({
        variables: { filter: { name: "Sensorflow Pte Ltd" } },
      });
    }
  }, [formOperation, getDefaultOrg]);

  const [getLocations, { data: locationsData, loading: locationsQueryLoading, error: locationsQueryError }] =
    useLocationsForAutocompleteLazyQuery();
  const locations = locationsData?.locations?.locations;

  const handleOrganisationSearch = (value: string) => {
    getOrganisations({ variables: { filter: { name: value } } });
  };

  const handleLocationSearch = (value: string) => {
    getLocations({ variables: { filter: { locationName: value } } });
  };

  useEffect(() => {
    getOrganisations({ variables: { filter: { name: "" } } });
    getLocations({ variables: { filter: { locationName: "" } } });
  }, [getOrganisations, getLocations]);

  const getOrganizations = () => {
    const orgs = cloneDeep(organisations) ?? [];
    // Check if user organisation in selection list otherwise insert it at the end.
    if (user?.organisation && !orgs?.some((organisation: Organisation) => organisation.id === user?.organisation.id)) {
      orgs?.push(user?.organisation);
    }
    // Check if user default value in selection list otherwise insert it at the end.
    if (defaultOrg && !orgs?.some((organisation: Organisation) => organisation.id === defaultOrg.id)) {
      orgs?.push(defaultOrg);
    }
    return orgs;
  };

  const handleOrganisationChange = (value: any) => {
    // If user select other value than default, default value will be removed from selection list.
    if (defaultOrg && value !== defaultOrg.id) {
      setDefaultOrg(undefined);
    }
  };

  const renderEmailVerifiedStatus = () => {
    if (formOperation === "CREATE" || !user?.emailVerified) return null;

    if (user?.emailVerified === "VERIFIED")
      return (
        <CheckCircleFilled
          style={{
            color: "#52c41a",
            marginLeft: "8px",
            marginRight: "8px",
          }}
          title="Email verified"
        />
      );
    return (
      <WarningFilled
        style={{
          color: "#faad14",
          marginLeft: "8px",
          marginRight: "8px",
        }}
        title="Email not verified"
      />
    );
  };

  const allLocation = useCallback(() => {
    type OptionType = {
      label: string;
      value: string;
    };

    const locationOptionList: OptionType[] = (locations || []).map((location) => ({
      value: location.id,
      label: location.locationName,
    }));
    const userOptions: OptionType[] = (user?.locations || []).map((location) => ({
      value: location.id,
      label: location.locationName,
    }));

    return uniqBy(locationOptionList.concat(userOptions), "value") ?? [];
  }, [user, locations]);

  const handleUpdateSelectedLocations = (values: any[]) => {
    setSelectedLocations(values);
  };

  useEffect(() => {
    setTimeout(() =>
      setLocationOptions(allLocation().filter((location: any) => !selectedLocations.includes(location.value)))
    );
  }, [selectedLocations, locations, allLocation]);

  useEffect(() => {
    setLocationOptions(allLocation());
  }, [locations, allLocation]);

  const onChangeRole = (roleId: string) => {
    const role = roles?.find((r) => r.id === roleId);
    setIsCustomer(role?.name === RoleName.HotelViewer);
    setIsInstaller(role?.name === RoleName.Installer);
    form.setFieldsValue({
      ...form,
      isManager: false,
      isContractor: false,
    });
  };

  return (
    <Form form={form} initialValues={user || undefined} onFinish={(values) => handleSubmit(values)} layout="vertical">
      {user?.userId && (
        <Form.Item name="userId" label="User ID" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item name="name" label="Full Name" rules={[{ required: true, message: "Name is required" }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Email" htmlFor="email" required>
        {/* nested */}
        <Form.Item
          name="email"
          noStyle
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please input a valid email" },
          ]}
        >
          <Input
            id="email"
            style={{
              width: user?.emailVerified ? "calc(100% - 30px)" : "100%",
            }}
            type="email"
            disabled={formOperation === "UPDATE"}
          />
        </Form.Item>
        {renderEmailVerifiedStatus()}
      </Form.Item>

      <Form.Item name="roleId" label="Access Role" rules={[{ required: true, message: "Role is required" }]}>
        <Select
          loading={rolesQueryLoading}
          showSearch
          filterOption
          optionFilterProp="children"
          placeholder="Select a role"
          disabled={roles?.length === 0 || !!rolesQueryError}
          onChange={(value: string) => onChangeRole(value)}
        >
          {roles &&
            roles.map((role: Role) => (
              <Option value={role.id} key={role.id}>
                {role.name}
              </Option>
            ))}
          {!roles?.some((role: Role) => role.id === user?.role.id) && user?.role && (
            <Option value={user.role.id} key={user.role.id}>
              {user.role.name}
            </Option>
          )}
        </Select>
      </Form.Item>

      <Form.Item name="isManager" label="Is Hotel Viewer Manager?" hidden={!isCustomer}>
        <Radio.Group
          defaultValue={false}
          options={[
            {
              label: "True",
              value: true,
            },
            {
              label: "False",
              value: false,
            },
          ]}
        />
      </Form.Item>

      <Form.Item name="isContractor" label="Is Contractor?" hidden={!isInstaller}>
        <Radio.Group
          defaultValue={false}
          options={[
            {
              label: "True",
              value: true,
            },
            {
              label: "False",
              value: false,
            },
          ]}
        />
      </Form.Item>

      {roles && roles.length === 0 && (
        <Alert
          type="warning"
          message={`No data returned from API for roles. You will not be able to ${
            formOperation === "CREATE" ? "create new users." : "change user's role."
          }`}
          style={{ marginBottom: "24px" }}
        />
      )}

      {rolesQueryError && (
        <Alert
          type="error"
          message={`Error getting roles from API. You will not be able to ${
            formOperation === "CREATE" ? "create new users." : "change user's role."
          }`}
          style={{ marginBottom: "24px" }}
        />
      )}

      <Form.Item name="organisationId" label="Organisation">
        <Select
          showSearch
          loading={organisationsQueryLoading}
          onSearch={handleOrganisationSearch}
          filterOption={false}
          placeholder="Type to search organisations"
          notFoundContent="No organisations found"
          onChange={handleOrganisationChange}
        >
          {getOrganizations().map((organisation: Organisation) => (
            <Option value={organisation.id} key={organisation.id}>
              {organisation.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {organisationsQueryError && (
        <Alert
          type="error"
          message="Error getting organisations from API. You may not be able to assign an organisation to the user"
          description={`Error details: ${organisationsQueryError.message}`}
          style={{ marginBottom: "24px" }}
        />
      )}

      <Form.Item className="user-form-location" label="Location(s)" htmlFor="locationIds">
        <Row className="user-form-location-button-container">
          <Col>
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                const allValue = allLocation().map((x) => x.value);
                form.setFieldsValue({ locationIds: allValue });
                setSelectedLocations(allValue);
              }}
            >
              Select all
            </Button>
          </Col>
          <Col>
            <Button
              type="link"
              danger
              onClick={(e) => {
                e.preventDefault();
                form.setFieldsValue({ locationIds: [] });
                setSelectedLocations([]);
              }}
            >
              Remove all
            </Button>
          </Col>
        </Row>
        <Form.Item name="locationIds">
          <Select
            id="locationIds"
            showSearch
            value={selectedLocations}
            loading={locationsQueryLoading}
            onSearch={handleLocationSearch}
            onChange={handleUpdateSelectedLocations}
            mode="multiple"
            placeholder="Type to search locations"
            notFoundContent="No locations found"
            options={locationOptions}
            autoClearSearchValue={false}
            onBlur={() => handleLocationSearch("")}
            filterOption={() => true}
          />
        </Form.Item>
      </Form.Item>

      {locationsQueryError && (
        <Alert
          type="error"
          message="Error getting locations from API. You may not be able to assign locations to the user"
          description={`Error details: ${locationsQueryError.message}`}
          style={{ marginBottom: "24px" }}
        />
      )}

      <Form.Item wrapperCol={{ span: 24 }}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={roles?.length === 0 || !!rolesQueryError}
        >
          {formOperation === "CREATE" ? "Add user" : "Save changes"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserCreateEditForm;
