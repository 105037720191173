import keys from "./keys";

const keysInLocations = [
  {
    id: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
    locationName: "Pigeon Hotel",
    locationMode: "ACTIVE",
    timezone: "Asia/Singapore",
    currency: "VND",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "COMPLETED",
      keysStats: {
        totalCount: 51,
        mappedCount: 51,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 50,
        mappedCount: 50,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "FLOW_METER",
          mappingStats: {
            totalCount: 447,
            mappedCount: 447,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Pigeon Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
    enabledFeatures: ["SmartSaveUnoccupiedLimit", "DisableSaveAsDefaultMaxResetTimeout", "NighttimeSetpointIncrease"],
  },
  {
    id: "27407651-106f-4bb3-98f6-efd87478b5d6",
    locationName: "Turkey Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "COMPLETED",
      keysStats: {
        totalCount: 60,
        mappedCount: 60,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 60,
        mappedCount: 50,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "FLOW_METER",
          mappingStats: {
            totalCount: 491,
            mappedCount: 491,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Turkey Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "cfbc10e2-9fc9-4b15-9902-c6d937f61d98",
    locationName: "Bonobo Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "COMPLETED",
      keysStats: {
        totalCount: 34,
        mappedCount: 34,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 50,
        mappedCount: 50,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "FLOW_METER",
          mappingStats: {
            totalCount: 550,
            mappedCount: 550,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Bonobo Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "c11de66b-50ab-4099-a3db-16eecbb4dc42",
    locationName: "Lemming Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "COMPLETED",
      keysStats: {
        totalCount: 82,
        mappedCount: 82,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 50,
        mappedCount: 50,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "TWOPFC",
          mappingStats: {
            totalCount: 965,
            mappedCount: 965,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Lemming Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "a889ba38-bf88-4d4c-9453-5b26489c45b6",
    locationName: "Boar Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "COMPLETED",
      keysStats: {
        totalCount: 21,
        mappedCount: 21,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 60,
        mappedCount: 60,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "TWOPFC",
          mappingStats: {
            totalCount: 683,
            mappedCount: 683,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Boar Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "82de8e51-81ff-4ed3-9a33-7f52fc345cd1",
    locationName: "Wildfowl Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "IN_PROGRESS",
      keysStats: {
        totalCount: 41,
        mappedCount: 38,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 20,
        mappedCount: 20,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "OCCUPANCY",
          mappingStats: {
            totalCount: 819,
            mappedCount: 433,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Wildfowl Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "2d4146e3-6aee-4a59-a897-df89e473d736",
    locationName: "Bat Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "IN_PROGRESS",
      keysStats: {
        totalCount: 80,
        mappedCount: 40,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 30,
        mappedCount: 30,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "FLOW_METER",
          mappingStats: {
            totalCount: 273,
            mappedCount: 99,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Bat Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "927179ea-0705-4ad1-816b-4739c11a4f41",
    locationName: "Chipmunk Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "IN_PROGRESS",
      keysStats: {
        totalCount: 95,
        mappedCount: 64,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 60,
        mappedCount: 60,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "OCCUPANCY",
          mappingStats: {
            totalCount: 416,
            mappedCount: 67,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Chipmunk Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "3b42a4bf-2fc4-4679-acf3-362a5ae1f4b6",
    locationName: "Monkey Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "NOT_STARTED",
      keysStats: {
        totalCount: 59,
        mappedCount: 0,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 0,
        mappedCount: 0,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "CIR",
          mappingStats: {
            totalCount: 857,
            mappedCount: 0,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Monkey Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "80929908-8700-4874-b119-efaa5b1612a3",
    locationName: "Chimpanzee Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "NOT_STARTED",
      keysStats: {
        totalCount: 29,
        mappedCount: 0,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 20,
        mappedCount: 20,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "DOOR",
          mappingStats: {
            totalCount: 789,
            mappedCount: 0,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Chimpanzee Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
  {
    id: "6b1433dd-7055-4a5e-b5fb-b500b5624bdc",
    locationName: "Possum Hotel",
    locationMode: "ACTIVE",
    currency: "VND",
    timezone: "Asia/Singapore",
    organisation: {
      id: "0c8bbb5b-3444-43f3-b3af-6ab159e94887",
      name: "The Marriott",
    },
    locationStats: {
      mappingStatus: "NOT_STARTED",
      keysStats: {
        totalCount: 0,
        mappedCount: 0,
        __typename: "MappingStatsCount",
      },
      gatewaysStats: {
        totalCount: 20,
        mappedCount: 0,
        __typename: "MappingStatsCount",
      },
      nodeOverallStats: [
        {
          nodeType: "OCCUPANCY",
          mappingStats: {
            totalCount: 624,
            mappedCount: 0,
            __typename: "MappingStatsCount",
          },
          __typename: "NodeTypeStats",
        },
      ],
      nodeHealthStats: {
        totalCount: 18,
        onlineCount: 1,
        offlineCount: 15,
        bootedCount: 2,
        __typename: "NodeHealthStats",
      },
      nodeHealthOverallStats: [
        {
          nodeType: "aircon",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 1,
            offlineCount: 7,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
        {
          nodeType: "occupancy",
          nodeHealthStats: {
            totalCount: 9,
            onlineCount: 0,
            offlineCount: 8,
            bootedCount: 1,
            __typename: "NodeHealthStats",
          },
          __typename: "NodeHealthOverallStats",
        },
      ],
      __typename: "LocationStats",
    },
    keysResponse: {
      keys: keys("Possum Hotel"),
    },
    clickupExportDisabled: true,
    beingExportedToClickup: false,
    clickupListId: null,
    locationMetadata: {
      showEnergyConsumption: true,
      subscription: "SMART_REM",
    },
  },
];

export default keysInLocations;
