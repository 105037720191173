import { LazyQueryHookOptions, QueryTuple } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import { useCallback, useState } from "react";
// additional layer to query lazy hook to compute the trigger function (first-time or refetch)
// and the loading status of the lazy query when trigger
export const useLazyQueryTrigger = <TData = any, TVariables = any>(
  useCustomLazyQuery: (o: LazyQueryHookOptions<TData, TVariables>) => QueryTuple<TData, TVariables>,
  { onCompleted, onError, ...options }: LazyQueryHookOptions<TData, TVariables>
) => {
  // we don't use the default loading status of the query,
  // since for re-trigger, the loading status will not be changed
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  // define a custom callback to switch on/off the loading status
  const onCompleteCallback = useCallback(
    (data: TData) => {
      setLoadingStatus((curStatus) => {
        if (curStatus && data) {
          onCompleted?.(data);
        }
        return false;
      });
    },
    [onCompleted]
  );

  const onErrorCallback = useCallback(
    (error: ApolloError) => {
      setLoadingStatus((curStatus) => {
        if (curStatus && error) {
          onError?.(error);
        }
        return false;
      });
    },
    [onError]
  );

  const [firstTrigger, { refetch }] = useCustomLazyQuery({
    ...options,
    onCompleted: onCompleteCallback,
    onError: onErrorCallback,
  });

  const refetchFn = useCallback(
    async (opt?: LazyQueryHookOptions<TData, TVariables>) => {
      if (typeof refetch !== "function") return;
      try {
        setLoadingStatus(true);
        const res = await refetch(opt?.variables);
        onCompleteCallback(res.data);
      } catch (error) {
        onErrorCallback(error as ApolloError);
      }
    },
    [refetch, onCompleteCallback, onErrorCallback]
  );

  const firstTriggerFn = useCallback(
    (opt?: LazyQueryHookOptions<TData, TVariables>) => {
      setLoadingStatus(true);
      firstTrigger(opt);
    },
    [firstTrigger]
  );

  return {
    triggerFn: typeof refetch === "function" ? refetchFn : firstTriggerFn,
    loading: loadingStatus,
  };
};

export default useLazyQueryTrigger;
