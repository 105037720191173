import qs from "qs";

export const stringifyQueryString = (obj: Record<string, any>) => {
  return qs.stringify(obj, { addQueryPrefix: true });
};

export const getObjBySearch = <T = Record<string, any>>(search: string = window.location.search) => {
  return qs.parse(search, { plainObjects: true, ignoreQueryPrefix: true }) as unknown as T;
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
