import { useState } from "react";

import { useLabelDetailsForRoomQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import useZPLPrintLabel from "services/zpl/use-print";
import { createRoomZpl } from "services/zpl/helper";
import { RoomDetailsForLabelPrinting } from "./use-room-print.d";

const useRoomLabelPrinting = (roomId: string) => {
  const [roomDetails, setRoomDetails] = useState<RoomDetailsForLabelPrinting>();
  const { loading, error } = useLabelDetailsForRoomQuery({
    variables: {
      roomId,
    },
    onCompleted: (data) => {
      if (data) setRoomDetails(data.room);
    },
  });

  const { error: printRoomError } = useZPLPrintLabel(createRoomZpl(roomDetails));

  return { loading, error, printError: printRoomError, details: roomDetails };
};

export default useRoomLabelPrinting;
