import { Button, Col, DatePicker, Form, Row, Space, TimePicker, message } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import useBreakpoint from "hooks/use-breakpoint";
import moment, { now } from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { TIME_24H_FORMAT, DATE_FORMAT, YEAR_MONTH_DATE_FORMAT } from "utils/date";
import { validationMessages } from "utils/validation";
import classnames from "classnames";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { TimerResetConfigurationFormType, TimerResetConfigurationModalProps } from "./TimerResetConfigurationModal.d";

const initialTime = moment("12:00 AM", ["HH:mm"]);

const TimerTimerResetConfigurationModal = ({
  onClose,
  onSave,
  onSaveDefault,
  disableSaveDefault,
  disableSaveAsDefaultMaxResetTimeoutFlagEnabled,
}: TimerResetConfigurationModalProps) => {
  const [form] = Form.useForm<TimerResetConfigurationFormType>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const screen = useBreakpoint();
  const { timezone } = useSensorflowLocation();

  const canSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return (error as any)?.errorFields?.length === 0;
    }
    return !form.getFieldsError().some((item) => item.errors.length > 0);
  }, [form]);

  const handleOnSave = useCallback(async () => {
    if (!(await canSubmit())) return;
    const { date: dateValue, time: timeValue } = form.getFieldsValue();
    const date = moment(dateValue).format(YEAR_MONTH_DATE_FORMAT);
    const time = moment(timeValue).format(TIME_24H_FORMAT);
    const dateTime = moment(`${date} ${time}`);
    if (!dateTime.isAfter(now())) {
      return form.setFields([{ name: "date", errors: ["Cannot choose a time in the past"] }]);
    }
    setIsDisabled(true);
    message.info("Current configurations are saving...");
    onSave(dateTime.isAfter(now()) ? dateTime.format() : dateTime.add("1", "d").format());
  }, [form, canSubmit, onSave]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const today = moment.tz(`${timezone}`).startOf("day");
    const maxDate = moment.tz(`${timezone}`).add(14, "days").endOf("day");
    return disableSaveAsDefaultMaxResetTimeoutFlagEnabled
      ? current && (current < today || current > maxDate)
      : current.diff(moment.now(), "day") < 0;
  };

  const initialValues = useMemo(
    () => ({
      date: moment(initialTime).diff(now()) < 0 ? moment().add(1, "d") : moment(),
      time: initialTime,
    }),
    []
  );

  const handleOnSaveDefault = useCallback(async () => {
    setIsDisabled(true);
    onSaveDefault();
  }, [onSaveDefault]);

  return (
    <Form
      disabled={isDisabled}
      name="basic"
      form={form}
      validateMessages={validationMessages}
      initialValues={initialValues}
    >
      <div className="mb-l content">
        <p className="mb-l">The new configuration will automatically reset to default on the date and time below</p>
        <p>If you don&apos;t want this configuration to reset, please click &quot;Save as default&quot;.</p>
      </div>
      <Row className="mb-l date-time-picker" gutter={8}>
        <Col span={14} xs={24} md={14}>
          <Form.Item name="date" rules={[{ required: true }]}>
            <DatePicker
              data-testid="date-picker"
              disabledDate={disabledDate}
              format={DATE_FORMAT}
              className="w-100"
              suffixIcon={null}
            />
          </Form.Item>
        </Col>
        <Col span={10} xs={24} md={10}>
          <Form.Item name="time" rules={[{ required: true }]}>
            <TimePicker minuteStep={5} format={TIME_24H_FORMAT} className="w-100" />
          </Form.Item>
        </Col>
      </Row>
      <Space
        wrap
        direction={screen.mobileOnly ? "vertical" : "horizontal"}
        className={classnames({ "d-flex": screen.mobileOnly })}
      >
        <Button type="default" onClick={onClose} className={classnames({ "w-100": screen.mobileOnly })}>
          Cancel
        </Button>
        <Button
          type="default"
          onClick={handleOnSaveDefault}
          disabled={disableSaveAsDefaultMaxResetTimeoutFlagEnabled ? disableSaveDefault : false}
          className={classnames({ "w-100": screen.mobileOnly })}
        >
          Save as default
        </Button>
        <Button type="primary" onClick={handleOnSave} className={classnames({ "w-100": screen.mobileOnly })}>
          Confirm
        </Button>
      </Space>
    </Form>
  );
};

export default TimerTimerResetConfigurationModal;
