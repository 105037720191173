import errorHandler from "errorHandler";
import { isUndefined } from "lodash";
import { useGetPmsRoomCategoriesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { LocationWithPmsProperty } from "types/types";

export const useFetchPropertyRoomCategories = (location?: LocationWithPmsProperty) => {
  const { data, loading } = useGetPmsRoomCategoriesQuery({
    variables: {
      where: {
        pms_id: { _eq: isUndefined(location?.pms?.pmsId) ? -1 : location?.pms?.pmsId },
        property_id: { _eq: location?.pms?.propertyId || "" },
      },
    },
    onError: errorHandler.handleError,
  });

  return {
    categories: data?.categories || [],
    loading,
  };
};

export default useFetchPropertyRoomCategories;
