import React, { useState } from "react";

import { Form, Input, message, Modal, Select } from "antd";
import { useCommonRoomNamesQuery, useCreateSlotsMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { Key } from "./KeyList.d";
import errorHandler from "../../../errorHandler";
import NodeTypeFormItem from "../NodeTypeFormItem";

type CreateRoomFormModalProps = {
  locationId: string;
  keys: Key[];
  onCompleted: () => Promise<void>;
  closeModal: () => void;
};

const validateMessages = {
  required: "This field is required.",
};

const AddSlotToKeysFormModal = ({ locationId, keys, closeModal, onCompleted }: CreateRoomFormModalProps) => {
  const [form] = Form.useForm();

  const [roomNames, setRoomNames] = useState<any[]>([]);
  const [slotPreviewName, setSlotPreviewName] = useState<any>([]);

  const { loading } = useCommonRoomNamesQuery({
    variables: {
      keyIds: keys.map((k) => k.keyId),
    },
    onCompleted(data: any) {
      setRoomNames(data.commonRoomNames);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [createSlotsMutation] = useCreateSlotsMutation({
    onCompleted: async () => {
      message.info("Nodes have been added.");
      await onCompleted();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleSubmitAddSlot = async (values: any) => {
    const inputs = keys.map((k) => ({
      slotName: `${values.roomName} ${values.slotName}`,
      nodeType: values.nodeType,
      position: {
        locationId,
        parentPositionId: k.keyId,
        positionName: values.roomName,
      },
    }));
    await createSlotsMutation({
      variables: {
        slotsInput: inputs,
      },
    });
    closeModal();
  };

  const onSlotNameChange = () => {
    setSlotPreviewName([form.getFieldValue("roomName"), form.getFieldValue("slotName")].join("  "));
  };

  return (
    <Modal title="Add Node" centered visible onCancel={closeModal} onOk={form.submit} width={600} okText="Add Node">
      <Form
        name="add-slot-to-keys-form"
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        requiredMark={false}
        onFinish={handleSubmitAddSlot}
      >
        <span className="d-block mb-m">Add to following keys: {keys.map((k) => k.keyName).join(", ")} </span>
        <Form.Item label="Slot Name">
          <Form.Item name={["slotName"]} noStyle rules={[{ required: true }]}>
            <Input data-testid="slot-name-input-testId" placeholder="Door" onChange={onSlotNameChange} />
          </Form.Item>
          <span className="text-gray">
            Preview: <b>{slotPreviewName}</b>
          </span>
        </Form.Item>
        <NodeTypeFormItem form={form} />
        <Form.Item name={["roomName"]} label="Room to Add to" rules={[{ required: true }]}>
          <Select className="w-100" data-testid="room-names-testId" disabled={loading} onChange={onSlotNameChange}>
            {roomNames?.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSlotToKeysFormModal;
