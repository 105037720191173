export enum LocationSubscription {
  SMART_REM = "SMART_REM",
  SMART_ALLOC = "SMART_ALLOC",
  SMART_ALLOC_PRO = "SMART_ALLOC_PRO",
}

export const hasSmartREM = (subscription?: string | null): boolean => {
  return (
    !subscription ||
    [LocationSubscription.SMART_REM, LocationSubscription.SMART_ALLOC_PRO].includes(
      subscription as LocationSubscription
    )
  );
};

export const hasSmartAlloc = (subscription?: string | null): boolean => {
  return (
    !!subscription &&
    [LocationSubscription.SMART_ALLOC, LocationSubscription.SMART_ALLOC_PRO].includes(
      subscription as LocationSubscription
    )
  );
};

export const hasSmartAllocPro = (subscription?: string | null): boolean => {
  return subscription === LocationSubscription.SMART_ALLOC_PRO;
};

export enum AvailableOperationalModes {
  Heating = "heating",
  Cooling = "cooling",
  Both = "both",
}
