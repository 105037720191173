import React, { useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { debounce, memoize } from "lodash";

import { useLocationsAssignedToUserForAutocompleteLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { LOCATION_QUERY_PARAM_KEY } from "utils/constants";
import { DownArrowIcon } from "components/Icons";
import useBreakpoint from "hooks/use-breakpoint";
import { Location, LocationSwitcherProps } from "./index.d";
import { stringifyQueryString } from "../../utils/url";
import useQueryString from "../../hooks/use-query-string";
import "./styles.scss";

const LocationSwitcher = (props: LocationSwitcherProps) => {
  const { onChange } = props;

  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const history = useHistory();
  const params = useQueryString();
  const screen = useBreakpoint();

  const [getLocations, { loading }] = useLocationsAssignedToUserForAutocompleteLazyQuery({
    onCompleted: (data) => {
      setLocations(data?.locationsAssignedToUser?.locations || []);
    },
    onError: errorHandler.handleError,
  });

  const searchLocations = useMemo(
    () =>
      memoize(
        debounce((search: string = "") => {
          getLocations({
            variables: {
              filter: {
                locationName: search,
              },
            },
          });
        }, 500)
      ),
    [getLocations]
  );

  useEffect(() => {
    if (locations?.length) {
      if (params[LOCATION_QUERY_PARAM_KEY]) {
        if (params[LOCATION_QUERY_PARAM_KEY] !== selectedLocation) {
          setSelectedLocation(params[LOCATION_QUERY_PARAM_KEY] as string);
        }
        return;
      }

      if (!selectedLocation) {
        setSelectedLocation(locations[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, params[LOCATION_QUERY_PARAM_KEY]]);

  useEffect(() => {
    searchLocations();
  }, [searchLocations]);

  useEffect(() => {
    if (selectedLocation) {
      if (params[LOCATION_QUERY_PARAM_KEY] !== selectedLocation) {
        history.push({
          search: stringifyQueryString({
            ...params,
            [LOCATION_QUERY_PARAM_KEY]: selectedLocation,
          }),
        });
      }
      if (onChange) {
        onChange(selectedLocation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const handleDropdownVisibleChange = (visible: boolean) => {
    if (visible) {
      searchLocations();
    }
  };

  const locationOptions = locations?.map((location) => ({
    value: location.id,
    label: location.locationName,
  }));

  return (
    <Select
      data-testid="location-switcher-testId"
      className="location-switcher text-primary"
      dropdownClassName="location-switcher-dropdown"
      onDropdownVisibleChange={handleDropdownVisibleChange}
      options={locationOptions}
      onSearch={searchLocations}
      showSearch
      loading={loading}
      filterOption={() => true}
      value={selectedLocation}
      onChange={setSelectedLocation}
      suffixIcon={<DownArrowIcon />}
      dropdownMatchSelectWidth={screen.mobileAndTabletOnly}
    />
  );
};

export default LocationSwitcher;
