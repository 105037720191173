import React from "react";
import { Menu } from "antd";

interface IGetHelpGetHelpMenuItemProps {
  disabled?: boolean;
  onClick: () => void;
  key?: string;
  className?: string;
}

export const GetHelpMenuItem = ({ disabled, onClick, key, className }: IGetHelpGetHelpMenuItemProps) => {
  return (
    <Menu.Item disabled={disabled} onClick={onClick} className={className} key={key}>
      Get Help
    </Menu.Item>
  );
};

export default GetHelpMenuItem;
