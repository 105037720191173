import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ModalRefProps } from "../Modal.d";

const Wrapper = (props: any) => {
  const { afterClose, Component } = props;
  const [visible, setVisible] = useState<boolean>(true);

  const modalWrapperProps = {
    visible,
    onCancel: () => setVisible(false),
    afterClose,
  };

  const closeModal = () => setVisible(false);

  const componentProps = {
    ...props,
    modalWrapperProps,
    closeModal,
  };

  return <Component {...componentProps} />;
};

function ModalWrapper({ modal: Component, props }: any, ref: React.Ref<ModalRefProps>) {
  const [renderModal, setRenderModal] = useState<boolean>();

  useImperativeHandle(ref, () => ({
    open: () => setRenderModal(true),
  }));

  const afterClose = () => setRenderModal(false);

  const wrapperProps = {
    Component,
    afterClose,
  };

  return renderModal ? <Wrapper {...wrapperProps} {...props} /> : <></>;
}

export default forwardRef(ModalWrapper);
