import React from "react";
import { Table } from "antd";
import { ConnectedRoomDataType } from "../InfrastructureDetails.d";

interface ConnectedRoomsTableProps {
  dataSource: ConnectedRoomDataType[];
}

const ConnectedRoomsTable = ({ dataSource }: ConnectedRoomsTableProps) => {
  const columns = [
    {
      title: <span className="header-title">key</span>,
      dataIndex: ["position", "parentPosition", "positionName"],
    },
    {
      title: <span className="header-title">room</span>,
      dataIndex: ["position", "positionName"],
    },
  ];

  return (
    <Table
      className="room-connected-table"
      rowKey={(data: ConnectedRoomDataType) => data.position.positionId}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  );
};

export default ConnectedRoomsTable;
