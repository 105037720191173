import { get } from "lodash";
import { Order_By } from "pacts/app-webcore/hasura-webcore.graphql";
import { useCallback, useState } from "react";
import { getOrderByFromSort } from "utils/DataTransformer";

const sortOrder = (value: Order_By) => get({ [Order_By.Asc]: "ascend", [Order_By.Desc]: "descend" }, value, "");

export const useTableSortByColumn = () => {
  const [sort, setSort] = useState<any>({});
  const handleSortChange = useCallback((columnHeader: string) => {
    setSort((cur: any) => {
      return { [columnHeader]: cur[columnHeader] === Order_By.Asc.toString() ? Order_By.Desc : Order_By.Asc };
    });
  }, []);
  const getSortConfig = useCallback(
    (sortKey: string) => {
      return {
        onHeaderCell: () => ({
          onClick: () => {
            handleSortChange(sortKey);
          },
        }),
        sorter: true,
        defaultSortOrder: sortOrder(sort[sortKey]),
      };
    },
    [handleSortChange, sort]
  );

  return {
    orderBy: [getOrderByFromSort(sort)],
    handleSortChange,
    getSortConfig,
  };
};

export default useTableSortByColumn;
