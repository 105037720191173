import { useCallback, useContext } from "react";
import get from "lodash/get";
import { RoleContext } from "../contexts/role-context";
import { canPerform } from "../permissionHelpers";
import { Permission } from "../pacts/permission";
import { ROLE } from "../rbac-rules";

const useRoleAndPermission = () => {
  const role = useContext(RoleContext);

  const can = (permission: Permission) => {
    return canPerform(role, permission);
  };

  const isInstaller = useCallback(() => {
    if (!role) return false;
    return role.roleName === ROLE.INSTALLER;
  }, [role]);

  const isCustomer = () => {
    if (!role) return false;
    return role.roleName === ROLE.CUSTOMER;
  };

  const isPC = () => {
    return role && role.roleName === ROLE.PROJECT_COORDINATOR;
  };

  const isWS = () => {
    return role && role.roleName === ROLE.WAREHOUSE_STAFF;
  };

  const isContractor = useCallback(() => {
    return isInstaller() && role.isContractor;
  }, [role, isInstaller]);

  const isManager = () => {
    return isCustomer() && role.isManager;
  };

  const getRoleName = useCallback(() => {
    if (isContractor()) {
      return "Contractor";
    }

    return get(role, "roleName");
  }, [role, isContractor]);

  return {
    canPerform: can,
    isInstaller,
    isCustomer,
    isPC,
    isWS,
    isContractor,
    isManager,
    getRoleName,
  };
};

export default useRoleAndPermission;
