import { CheckCircleFilled, ClockCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import React from "react";
import { SlotRapidMappingStatus } from "../../../pacts/app-webcore/hasura-webcore.graphql";

const MappingStatusIcon = (props: any) => {
  const { status, isCurrentSlot } = props;

  if (status === SlotRapidMappingStatus.Mapped) return <CheckCircleFilled style={{ color: "#00c400" }} />;
  if (isCurrentSlot) return <ClockCircleFilled style={{ color: "#f59b42" }} />;
  return <PlusCircleOutlined />;
};

export default MappingStatusIcon;
