import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import errorHandler from "errorHandler";
import { useGetNodeLifeCycleEventTypesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect } from "react";
import NodeMacInput from "pages/InfrastructureDetails/MappedNodes/MapNodeModal/NodeMacInput";
import moment from "moment";
import { DATETIME_24H_FORMAT, TIME_24H_FORMAT } from "utils/date";
import { CreateNodeLifeCycleEventProps, FormOperations } from "./CreateNodeLifeCycleEvent.d";
import "./CreateNodeLifeCycleEvent.scss";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";

const CreateNodeLifeCycleEvent = (props: CreateNodeLifeCycleEventProps) => {
  const { formData, formOperation, closeModal, handleOnSubmit, nodeMacInputOptions } = props;
  const {
    data: { sensorflow_node_life_cycle_event_types: eventTypes = [] } = {},
    loading,
    error,
  } = useGetNodeLifeCycleEventTypesQuery({
    onError: errorHandler.handleError,
  });

  const [form] = Form.useForm<CreateNodeLifeCycleEventProps["formData"]>();

  const { isPC } = useRoleAndPermission();

  useEffect(() => {
    if (eventTypes.length) {
      const defaultEventType = eventTypes.find((x) => x.isDefault);
      form.setFieldsValue({ eventType: defaultEventType?.id });
    }
  }, [eventTypes, form]);

  const handleSubmitWhenValidData = async () => {
    handleOnSubmit(form.getFieldsValue());
    if (isPC()) closeModal();
  };

  return (
    <Modal
      title="Add node event(s)"
      centered
      visible
      width={600}
      onCancel={closeModal}
      className="modal-node-life-cycle"
      footer={null}
    >
      <Form form={form} layout="vertical" initialValues={formData} onFinish={handleOnSubmit}>
        <Form.Item
          name="nodeMacId"
          rules={[{ required: true, message: "Node Mac Id is required" }]}
          className="input-node-mac-id"
        >
          {/* <Input /> */}
          <NodeMacInput
            setNodeMacInput={(nodeMacId) => form.setFieldsValue({ nodeMacId })}
            setNodeTypeCode={(nodeTypeCode) => {
              form.setFieldsValue({ nodeTypeCode });
            }}
            disabledInput={formOperation === FormOperations.UPDATE}
            disabledScan={formOperation === FormOperations.UPDATE}
            defaultActiveKey="2"
            autoFocus
            needTrimFirstTwoDigit
            handleSubmitWhenValidData={handleSubmitWhenValidData}
            options={nodeMacInputOptions ?? {}}
            forceSubmit={!isPC()}
          />
        </Form.Item>

        <Form.Item name="eventType" label="Event Type" rules={[{ required: true, message: "Event Type is required" }]}>
          <Select
            loading={loading}
            showSearch
            filterOption
            optionFilterProp="children"
            placeholder="Select a event"
            disabled={eventTypes?.length === 0 || !!error}
          >
            {eventTypes?.length &&
              eventTypes.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="comment" label="Comment">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item name="creationDate" label="Date time">
          <DatePicker
            className="mr-m w-100"
            placeholder="Start time"
            format={DATETIME_24H_FORMAT}
            showTime={{ format: TIME_24H_FORMAT }}
            defaultValue={moment()}
          />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="nodeTypeCode" hidden>
          <Input hidden />
        </Form.Item>
      </Form>
      <div className="modal-button">
        <Button type="default" className="ant-btn ant-btn-primary" onClick={closeModal}>
          Close
        </Button>
        {isPC() && (
          <Button type="primary" className="ml-m ant-btn ant-btn-primary" onClick={handleSubmitWhenValidData}>
            Submit
          </Button>
        )}
      </div>
    </Modal>
  );
};
export default CreateNodeLifeCycleEvent;
