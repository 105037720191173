import React, { Component } from "react";
import logger from "services/logger/logger";
import { INewrelicBrowserAgent } from "types/types";

type Props = {
  render: RenderPropsFunction;
  // error boundary needs to be used around children to be meaningful
  children: React.ReactNode;

  newrelicAgent?: INewrelicBrowserAgent;
};

type RenderPropsFunction = (error: any, errorInfo: any, onReset: () => void) => React.ReactNode;

type State = {
  error: any;
  errorInfo: any;
};

const DEFAULT_RENDER: RenderPropsFunction = (error, errorInfo, onReset) => {
  const { componentStack } = errorInfo;
  // eslint-disable-next-line react/destructuring-assignment
  const errorString = error.toString();

  return (
    <div>
      <h4>Something went wrong.</h4>
      <details>
        <summary>Details</summary>
        <p>{errorString}</p>
        <pre>
          Error details <br />
          {componentStack}
        </pre>
      </details>
      <button type="button" onClick={onReset}>
        Try Again
      </button>
    </div>
  );
};

class ErrorBoundary extends Component<Props, State> {
  state = { error: null, errorInfo: null };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    render: DEFAULT_RENDER,
  };

  componentDidCatch(error: Error, errorInfo: any) {
    const { newrelicAgent } = this.props;
    this.setState({ error, errorInfo });
    logger.reactError(error, errorInfo.componentStack);
    newrelicAgent?.noticeError(error);
  }

  reset = () => {
    this.setState({ error: null, errorInfo: null });
  };

  render() {
    const { render, children } = this.props;
    const { error, errorInfo } = this.state;

    if (error) {
      return render(error, errorInfo, this.reset);
    }
    return children;
  }
}

export default ErrorBoundary;
