import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";

export interface NodeInputDataType {
  slotName?: string;
  nodeType?: string;
  nodeMacInput?: string;
  emonSetting?: number;
  mapInput?: number;
}

export const mapInputs: any[] = [
  { label: "INPUT 1", value: 0 },
  { label: "INPUT 2", value: 1 },
  { label: "INPUT 3", value: 2 },
];

export type NodeTypeOption = {
  label: string;
  value: NodeType;
};

export const nodeTypes: NodeTypeOption[] = [
  {
    label: getNodeTypeDisplayValue(NodeType.Energy),
    value: NodeType.Energy,
  },
  {
    label: getNodeTypeDisplayValue(NodeType.PipeTemp),
    value: NodeType.PipeTemp,
  },
  {
    label: getNodeTypeDisplayValue(NodeType.FlowMeter),
    value: NodeType.FlowMeter,
  },
];
