import { getNodeTypeDisplayValue } from "../../../utils/nodeTypeDisplay";
import { EnergyNodeSubTypes } from "./OtaDetails.constants";

const NodeTypeDisplay: { [key: string]: string } = {
  [EnergyNodeSubTypes.EnergySwitched]: "Energy Switched",
  [EnergyNodeSubTypes.EnergySwitchless]: "Energy Switchless",
};

export const getOtaNodeTypeDisplayValue = (nodeType: string) => {
  if (nodeType in NodeTypeDisplay) {
    return NodeTypeDisplay[nodeType];
  }
  return getNodeTypeDisplayValue(nodeType);
};

export default getOtaNodeTypeDisplayValue;
