import { Col, Divider, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import ActionButton from "components/ActionButton";
import useBreakpoint from "hooks/use-breakpoint";
import React from "react";
import { formatDateTime24h } from "utils/date";
import { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import { SpareNodesTableProps, DataType } from "./SpareNodesTable.d";

const SpareNodesTable = (props: SpareNodesTableProps) => {
  const { data, onDelete = () => {} } = props;

  const screen = useBreakpoint();
  const columnConfig: ColumnsType<DataType> = [
    {
      title: "Node Mac ID",
      dataIndex: "nodeMacId",
      responsive: ["sm"],
    },
    {
      title: "Node type",
      render: (record: DataType) => {
        return getNodeSubTypeDisplayName(record?.node?.node_type, record?.node?.nodeSubType);
      },
      responsive: ["sm"],
    },
    {
      title: "Mapped at",
      dataIndex: "mappedAt",
      render: (text: string) => formatDateTime24h(text),
      responsive: ["sm"],
    },
    {
      title: "Action",
      render: (record: DataType) => (
        <ActionButton
          type="delete"
          onClick={() => onDelete(record.nodeMacId)}
          className="fs-xl mr-m"
          data-testid="delete-spare-node-testId"
        />
      ),
      responsive: ["sm"],
    },

    {
      title: "",
      render: (record: DataType) => {
        return (
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col span={24}>
              <Row>
                <Col span={22}>
                  <b>Node Mac ID</b>: {record.nodeMacId}
                </Col>
                <Col span={2}>
                  <ActionButton
                    type="delete"
                    onClick={() => onDelete(record.nodeMacId)}
                    data-testid="delete-spare-node-testId"
                  />
                </Col>
              </Row>
            </Col>
            <Divider className="my-m" />
            <Col span={24}>
              <b>Location name</b>: {record.locationName}
            </Col>
            <Col span={24}>
              <b>Mapped at</b>: {formatDateTime24h(record.mappedAt)}
            </Col>
          </Row>
        );
      },
      responsive: ["xs"],
    },
  ];

  return (
    <Table
      columns={columnConfig}
      dataSource={data}
      rowKey="id"
      scroll={{ x: true }}
      className={classNames({ "m-table": screen.mobileAndTabletOnly })}
    />
  );
};

export default SpareNodesTable;
