import moment from "moment";

const getNumberDays = (dateFrom: string, dateTo: string) => {
  return moment(dateTo).diff(moment(dateFrom), "days");
};

export default [
  {
    roomId: "e0f7d982-e527-11e9-ad3b-a45e60d743d3",
    roomName: "Bedroom 1",
    energyConsumption: (root: any, args: any) => {
      const { fromDate, toDate } = args;
      return getNumberDays(fromDate, toDate) * 100;
    },
  },
  {
    roomId: "e10a0832-e527-11e9-ad3b-a45e60d743d3",
    roomName: "Bedroom 2",
    energyConsumption: (root: any, args: any) => {
      const { fromDate, toDate } = args;
      return getNumberDays(fromDate, toDate) * 120;
    },
  },
];
