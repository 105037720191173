import isNil from "lodash/isNil";
import { CsvHeaderConfig } from "types/types";
import { EnergyConsumptionToCSV, Key } from "./EnergyConsumption.d";

export const getEnergyConsumptionFormat = (energyConsumption: number | null | undefined) => {
  if (isNil(energyConsumption)) return "NA";
  return `${energyConsumption!.toFixed(1)} kWh`;
};

export const getEnergyConsumptionCsvHeader = () => {
  return [
    {
      label: "Room",
      key: "room",
    },
    {
      label: "Key",
      key: "key",
    },
    {
      label: "Energy Consumed",
      key: "energyConsumed",
    },
  ];
};

export const transformData = (rawData: Key[]): EnergyConsumptionToCSV[] => {
  const data: EnergyConsumptionToCSV[] = [];
  rawData.forEach((key) => {
    key.rooms.forEach((room) => {
      data.push({
        key: key.keyName,
        room: room.roomName,
        energyConsumed: getEnergyConsumptionFormat(room.energyConsumption),
      });
    });
  });
  return data;
};

export const transformEnergyConsumptionDataToCsvData = (
  data: EnergyConsumptionToCSV[],
  {
    headers,
  }: {
    headers: CsvHeaderConfig<EnergyConsumptionToCSV>[];
  }
) => {
  return data.map((room) => {
    return Object.fromEntries(
      headers.map((headerCfg) => [
        headerCfg.key,
        headerCfg.transform ? headerCfg.transform(room) : (room as any)[headerCfg.key],
      ])
    );
  });
};
