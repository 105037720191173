import React, { useEffect, useState } from "react";
import { Col, Collapse, Empty, message, Row } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import _, { isEqual } from "lodash";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { useTemporaryNodeListQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import Loading from "components/Loading";
import usePagination from "pages/Key/hooks/use-pagination";

import { NodesByKey } from "./ContractorNodeListKey.d";
// eslint-disable-next-line import/no-cycle
import ContractorNodeList from "../ContractorNodeList/ContractorNodeList";
import { getNodeListWhereFromFilter, Node } from "../NodeList/NodeList.d";
import { transformNodes } from "../NodeList/NodeList.helpers";

const ContractorNodeListKey = () => {
  const [nodesByKey, setNodeByKey] = useState<NodesByKey[]>([]);
  const { pagination, setPagination } = usePagination();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [collapseActive, setCollapseActive] = useState<any[]>([]);
  const { locationId } = useSensorflowLocation();
  const [nodes, setNodes] = useState<Node[]>([]);

  const where = getNodeListWhereFromFilter(locationId || "", { nodeMacId: "" });

  const { error } = useTemporaryNodeListQuery({
    variables: {
      where,
      limit: pagination.pageSize,
      offset: pagination.total,
    },
    onCompleted(data) {
      const newNodes = transformNodes(data.sensorflow_node_to_slot_mapping);
      setHasMore(newNodes.length === pagination.pageSize);
      if (isEqual(newNodes, nodes)) setNodes(newNodes);
      else setNodes((prev) => prev.concat(newNodes));
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    const newNode = _.chain(nodes)
      .groupBy((node) => node.key.positionName)
      .map((items, value) => {
        const rooms = _.chain(items)
          .groupBy((x) => x.room.positionId)
          .map((x) => {
            const room = x[0] || {};
            return {
              roomId: room.room.positionId,
              roomName: room.room.positionName,
              slots: x,
            };
          })
          .value();
        return {
          keyName: value,
          keyId: items[0].key.positionId,
          rooms,
        };
      })
      .value();
    setNodeByKey(newNode);
  }, [nodes]);

  useEffect(() => {
    if (error) message.error(error.message);
  }, [error]);

  const onChange = (key: string | string[]) => {
    const formatKey = typeof key === "string" ? [key] : key;
    setCollapseActive(formatKey);
  };

  const nextPage = () => {
    setPagination({
      pageSize: pagination.pageSize,
      total: pagination.total + pagination.pageSize,
      current: pagination.current + 1,
    });
  };

  return (
    <div className="mb-l">
      <Row>
        <Col span={24}>
          <InfiniteScroll dataLength={nodes.length} next={nextPage} hasMore={hasMore} loader={<Loading />}>
            {nodesByKey?.length > 0 ? (
              <Collapse onChange={onChange} defaultActiveKey={nodesByKey[0]?.keyId}>
                {nodesByKey.map((key) => (
                  <Collapse.Panel header={key.keyName} key={key.keyId}>
                    {key.rooms.length > 0 ? (
                      <ContractorNodeList
                        keyId={key.keyId}
                        active={collapseActive.includes(key.keyId)}
                        keyDetail={key}
                        showKeyButton
                      />
                    ) : (
                      <Empty description="No Node Mapped" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </Collapse.Panel>
                ))}
              </Collapse>
            ) : (
              <Empty description="No Node Mapped" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </InfiniteScroll>
        </Col>
      </Row>
    </div>
  );
};

export default ContractorNodeListKey;
