import useQueryString from "hooks/use-query-string";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useErrorHandler } from "../errorHandler";
import { useLocationQuery } from "../pacts/app-webcore/hasura-webcore.graphql";
import { Location } from "../types/types";

export const LocationContext = React.createContext<{
  location?: Location;
  reloadLocation: () => Promise<void> | void;
}>({ location: undefined, reloadLocation: () => {} });

export const LocationProvider = (props: { children: any }) => {
  const { children } = props;
  const errorHandler = useErrorHandler();
  const [location, setLocation] = useState<Location>();

  const { locationId: locationIdParams } = useParams<{ locationId?: string }>();
  const queryStrings = useQueryString();

  const locationId = useMemo(() => {
    if (!locationIdParams) {
      return queryStrings.location as string;
    }
    return locationIdParams;
  }, [locationIdParams, queryStrings]);

  const { loading, refetch } = useLocationQuery({
    skip: !locationId,
    variables: { locationId: locationId || "" },
    onCompleted: (data) => {
      if (data?.location) setLocation(data.location);
    },
    onError: errorHandler.handleErrorWithStatusCode,
  });

  const reloadLocation = useCallback(async () => {
    const { data } = await refetch({ locationId });
    setLocation(data?.location);
  }, [refetch, setLocation, locationId]);

  const locationContextProps = useMemo(
    () => ({
      location,
      reloadLocation,
    }),
    [location, reloadLocation]
  );

  if (loading) {
    return <Loading label="location loading" />;
  }

  return <LocationContext.Provider value={locationContextProps}>{children}</LocationContext.Provider>;
};
