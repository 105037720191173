import { Button, Modal } from "antd";
import React from "react";
import "./SelectTestRunModeModal.scss";

export interface SelectTestRunModeModel {
  isSelectTestRunModeModalOpen: boolean;
  setSelectTestRunModeModalOpen?: any;
  installationModeButtonRef?: any;
  onStartSession?: () => void;
}

const SelectTestRunModeModal = ({
  isSelectTestRunModeModalOpen,
  setSelectTestRunModeModalOpen,
  installationModeButtonRef,
  onStartSession,
}: SelectTestRunModeModel) => {
  return (
    <Modal
      className="select-test-run-modal"
      title="Select test run mode"
      centered
      visible={isSelectTestRunModeModalOpen}
      footer={null}
      onCancel={() => setSelectTestRunModeModalOpen(false)}
      width={400}
    >
      <Button
        type="primary"
        className="ant-btn ant-btn-primary modal-button"
        onClick={() => {
          setSelectTestRunModeModalOpen(false);
          installationModeButtonRef?.current?.handleEnterKey(false);
          if (onStartSession) onStartSession();
        }}
      >
        Start new session
      </Button>
      <br />
      <Button
        type="default"
        className="modal-button button-no"
        onClick={() => {
          setSelectTestRunModeModalOpen(false);
          installationModeButtonRef?.current?.handleEnterKey(true);
        }}
      >
        Resume previous session
      </Button>
    </Modal>
  );
};

export default SelectTestRunModeModal;
