import React, { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Col, Row, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { ApolloError } from "apollo-client";
import {
  useKeyCategoriesWithTemplatesQuery,
  useUpsertKeyCategoryTemplatesMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import get from "lodash/get";
import LocationBreadcrumb from "components/LocationBreadcrumb/LocationBreadcrumb";
import { Category, Session } from "./DataTypes";
import CategoryCollapse from "./CategoryCollapse";
import CategoryDetail from "./CategoryDetail";
import "./CategoriesAndKeysCreation.scss";
import CategoriesAndKeysCreationHeader from "./CategoriesAndKeysCreationHeader";
import KeysAssign from "./KeysAssign/KeysAssign";
import { ActiveStep } from "./CategoriesAndKeysCreation.d";

const CategoriesAndKeysCreation = () => {
  const history = useHistory();
  const { locationId } = useParams<{ locationId: string }>();
  const locationName = get(useLocation(), "state.locationName", "");

  const [session, setSession] = useState<Session>(new Session(locationId, locationName));
  const [shouldUpdateSession, setShouldUpdateSession] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.KEY_CREATION);
  const [disableAssignKey, setDisableAssignKey] = useState<boolean>(false);

  useMemo(() => {
    if (!locationName) history.push(`/locations/${locationId}/keys`);
  }, [locationId, locationName, history]);

  const { loading } = useKeyCategoriesWithTemplatesQuery({
    variables: {
      locationName,
    },
    onCompleted: (data: any) => setSession(Session.deserialize(locationId, locationName, data)),
  });

  const [upsertKeyCategoryTemplates] = useUpsertKeyCategoryTemplatesMutation({
    onCompleted: () => {
      setDisableAssignKey(false);
      setActiveStep(ActiveStep.KEY_ASSIGN);
    },
    onError: (error: ApolloError) => {
      setDisableAssignKey(false);
      message.error(error.message);
    },
  });

  const rerender = () => {
    setShouldUpdateSession(!shouldUpdateSession);
  };

  const updateCurrentCategory = (category: Category) => {
    setCurrentCategory(category);
  };

  const handleClickAssignKey = () => {
    if (session.isValidToCreateRoom()) {
      setDisableAssignKey(true);
      upsertKeyCategoryTemplates({
        variables: {
          locationName,
          templatesInput: session.transformToKeyCategoryTemplatesInsertInput(),
        },
      });
    }
  };

  const keyCreationContent = (
    <>
      <Row className="create-category-section">
        <Col span={8} className="left-section" data-testid="create-category-templates-menu">
          <CategoryCollapse
            session={session}
            onSessionChangeCallback={rerender}
            updateCurrentCategory={updateCurrentCategory}
          />
        </Col>
        <Col id="room-list" span={16} className="right-section" data-testid="create-category-templates-content">
          <CategoryDetail onSessionChangeCallback={rerender} category={currentCategory} />
        </Col>
      </Row>
      <Row className="footer-section">
        <Button className="assign-keys" disabled={disableAssignKey} onClick={handleClickAssignKey}>
          Assign Keys <RightOutlined />
        </Button>
      </Row>
    </>
  );

  const renderKeyCreationStep = () => {
    if (loading) return <h3>Loading data...</h3>;
    if (session.isEmpty()) return <h3>Category list is empty. You have to create category first!</h3>;
    return keyCreationContent;
  };

  const renderActiveStep = () => {
    switch (activeStep) {
      case ActiveStep.KEY_CREATION:
        return renderKeyCreationStep();
      case ActiveStep.KEY_ASSIGN:
        return <KeysAssign session={session} goBackKeyCreationStep={() => setActiveStep(ActiveStep.KEY_CREATION)} />;
      default:
        throw new Error("Invalid step in key creation mode!");
    }
  };

  return (
    <div className="categories-and-keys-creation">
      <LocationBreadcrumb locationName={locationName} />
      <CategoriesAndKeysCreationHeader step={activeStep} />
      {renderActiveStep()}
    </div>
  );
};

export default CategoriesAndKeysCreation;
