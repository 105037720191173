import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import SearchBar from "components/SearchBar/SearchBar";
import FilterDrawer from "components/FilterV2/FilterDrawer";

import { JobFilterProps } from "./JobFilter.d";

const JobFilter: React.FC<JobFilterProps> = ({
  onChange,
  currentFilter,
  isSaveKeySearch,
  searchField,
  containerClassName,
  hideSearchBarAndFilter,
  hideSearchBar,
  searchPlaceholder,
  defaultKeySearch,
  filterOptions,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...currentFilter,
        [field]: value,
      });
    }
    if (isSaveKeySearch) {
      const params = new URLSearchParams({ [field]: value });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  };

  const handleSearchChange = (value: string) => {
    handleChange(searchField, value);
  };

  return (
    <Row className={containerClassName} gutter={[16, 16]}>
      {!hideSearchBarAndFilter && !hideSearchBar && (
        <Col flex={1}>
          <SearchBar
            onInputChange={handleSearchChange}
            placeholder={searchPlaceholder}
            defaultValue={defaultKeySearch}
          />
        </Col>
      )}
      <FilterDrawer filterOptions={filterOptions || []} currentFilter={currentFilter} onFilterChange={onChange} />
    </Row>
  );
};

export default JobFilter;
