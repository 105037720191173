import React, { useEffect, useRef, useState } from "react";

import { Input, Modal } from "antd";
import { Closable } from "../show";

export type ConfirmDeleteModalProps = {
  confirmText: string;
  onOk: () => void;
  popupTitle?: string;
  message?: string;
  messageAfter?: string;
  popupOkText?: string;
  fieldLabel?: string;
  fieldPlaceholder?: string;
};

const ConfirmDeleteModal = ({
  confirmText,
  onOk,
  popupTitle,
  popupOkText,
  fieldLabel,
  fieldPlaceholder,
  message,
  messageAfter,
  close,
}: ConfirmDeleteModalProps & Closable) => {
  const [input, setInput] = useState<string>();
  const confirmTextInputRef: any = useRef();

  useEffect(() => {
    confirmTextInputRef.current!.focus();
  }, [confirmTextInputRef]);

  const handleSubmitAction = () => {
    const inputChanged = input && input === confirmText;
    if (inputChanged) {
      onOk();
      close();
    }
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Modal
      title={popupTitle}
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText={popupOkText ?? "Confirm"}
      okButtonProps={{
        disabled: input !== confirmText,
      }}
    >
      <p className="pb-l">{message}</p>
      <p className="mb-xs">{fieldLabel}</p>
      <Input
        id="confirm-text-input"
        data-testid="confirm-text-input-testId"
        placeholder={fieldPlaceholder}
        onChange={handleInputChange}
        onPressEnter={handleSubmitAction}
        ref={confirmTextInputRef}
        value={input}
      />
      <p className="pb-l">{messageAfter}</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
