import { ComputerIcon, EmptyStateIcon } from "components/Icons";
import React from "react";

const EmptyChart = () => {
  return (
    <div className="d-flex align-items-center flex-column py-l px-m">
      <div className="d-flex align-items-center justify-content-center mb-xl">
        <EmptyStateIcon />
        <ComputerIcon className="position-absolute mb-xl" />
      </div>
      <p className="fs-body-18 font-weight-semi-bold mb-s text-center">Start Building Your Dashboard.</p>
      <p className="fs-m mb-s text-center">Before we can create any charts, we will need some data here.</p>
    </div>
  );
};

export default EmptyChart;
