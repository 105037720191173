/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import classNames from "classnames";
import { Tooltip } from "antd";
import { ReactComponent as UserIconSvg } from "./assets/user.svg";
import { ReactComponent as DoorOpenSvg } from "./assets/door-opened.svg";
import { ReactComponent as DoorCloseSvg } from "./assets/door-closed.svg";
import { ReactComponent as CoolSvg } from "./assets/cool.svg";
import { ReactComponent as FanSvg } from "./assets/fan.svg";
import { ReactComponent as SettingSvg } from "./assets/setting.svg";
import { ReactComponent as SettingMultipleSvg } from "./assets/setting-multiple.svg";
import { ReactComponent as TemperatureSvg } from "./assets/temp.svg";
import { ReactComponent as PreCoolSvg } from "./assets/precool.svg";
import { ReactComponent as ReloadSvg } from "./assets/reload.svg";
import { ReactComponent as AutomationSvg } from "./assets/automation.svg";
import { ReactComponent as ForbiddenSvg } from "./assets/forbidden.svg";
import { ReactComponent as UnoccupiedSvg } from "./assets/unoccupied.svg";
import { ReactComponent as WarningSvg } from "./assets/warning.svg";
import { ReactComponent as DryModeSvg } from "./assets/dry-mode.svg";
import { ReactComponent as AutoModeSvg } from "./assets/auto-mode.svg";
import { ReactComponent as RhombusQuestionSvg } from "./assets/rhombus-question.svg";
import { ReactComponent as PowerOnSvg } from "./assets/power-on.svg";
import { ReactComponent as PowerOffSvg } from "./assets/power-off.svg";
import { ReactComponent as ReloadTimeSvg } from "./assets/reload-time.svg";
import { ReactComponent as ExpandSvg } from "./assets/expand.svg";
import { ReactComponent as CollapseSvg } from "./assets/collapse.svg";
import { ReactComponent as SunSvg } from "./assets/sun.svg";
import { ReactComponent as DownloadSvg } from "./assets/download.svg";
import { ReactComponent as DownArrowSvg } from "./assets/down-arrow.svg";
import { ReactComponent as ShareSvg } from "./assets/share.svg";
import { ReactComponent as InformationSvg } from "./assets/information.svg";
import { ReactComponent as FilterSvg } from "./assets/filter-icon.svg";
import { ReactComponent as FilterV2Svg } from "./assets/filter.svg";
import { ReactComponent as SurveyIconSvg } from "./assets/survey.svg";
import { ReactComponent as ComputerSvg } from "./assets/computer.svg";
import { ReactComponent as EmptyStateSvg } from "./assets/empty-state.svg";
import { ReactComponent as CircleOutlinedSvg } from "./assets/circle-outlined.svg";
import { ReactComponent as Key } from "./assets/key.svg";
import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { ReactComponent as IcFilledEngineer } from "./assets/ic-fill-eng.svg";
import { ReactComponent as Contractor } from "./assets/contractor.svg";
import { ReactComponent as TodoList } from "./assets/todolist.svg";
import { ReactComponent as Sensor } from "./assets/sensor.svg";
import { ReactComponent as Thermostat } from "./assets/thermostat.svg";
import { ReactComponent as VoltMeter } from "./assets/voltmeter.svg";
import { ReactComponent as AirPump } from "./assets/air-pump.svg";
import { ReactComponent as InProgress } from "./assets/in-progress.svg";
import { ReactComponent as DoorExit } from "./assets/door-exit.svg";
import { ReactComponent as ChatQuestion } from "./assets/chat-question.svg";
import { ReactComponent as ThreeDotsVertical } from "./assets/three-dots-vertical.svg";
import { ReactComponent as LoadingTestRun } from "./assets/loading-test-run.svg";
import { ReactComponent as EditSquare } from "./assets/edit-square.svg";
import { ReactComponent as Refresh } from "./assets/refresh.svg";
import { ReactComponent as Infor } from "./assets/infor.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as RouterWifi } from "./assets/router-wifi.svg";
import { ReactComponent as Hourglass } from "./assets/hourglass.svg";
import { ReactComponent as Comments } from "./assets/comments.svg";
import { ReactComponent as Ecology } from "./assets/ecology.svg";
import { ReactComponent as StartButton } from "./assets/start-button.svg";
import { ReactComponent as Unlink } from "./assets/unlink.svg";
import { ReactComponent as CircleFilled } from "./assets/circle-filled.svg";
import { ReactComponent as CheckIn } from "./assets/check-in.svg";
import { ReactComponent as CheckOut } from "./assets/check-out.svg";
import { ReactComponent as Email } from "./assets/email.svg";
import { ReactComponent as WarningTriangle } from "./assets/warning-triangle.svg";
import { ReactComponent as CloseCircle } from "./assets/close-circle.svg";
import { ReactComponent as CalendarEmpty } from "./assets/calendar-empty.svg";
import { ReactComponent as Clock } from "./assets/clock.svg";
import { ReactComponent as Stop } from "./assets/stop.svg";
import { ReactComponent as ReloadWithBackground } from "./assets/reload-with-background.svg";
import { ReactComponent as PoweredBySensor } from "./assets/powered-by-sensor-flow.svg";
import { ReactComponent as OrangeKey } from "./assets/key-orange.svg";
import { ReactComponent as BlueTemp } from "./assets/blue-temp.svg";
import { ReactComponent as Cold } from "./assets/cold.svg";
import { ReactComponent as FanFilled } from "./assets/fan-icon-filled.svg";

type IconProps = {
  tooltip?: React.ReactNode;
  className?: string;
  fill?: string;
  [k: string]: any;
};

export const Icon = (props: {
  svgComponent: any;
  className?: string;
  tooltip?: React.ReactNode;
  onClick?: (e?: any) => void | Promise<void>;
  [x: string]: any;
}) => {
  const { svgComponent, className, tooltip = "", onClick, ...iconProps } = props;
  return (
    <Tooltip title={tooltip}>
      <span
        role="button"
        tabIndex={0}
        onKeyUp={() => {}}
        onClick={onClick}
        className={classNames(className, "svg-icon")}
      >
        {React.createElement(svgComponent, iconProps)}
      </span>
    </Tooltip>
  );
};

export const UserIcon = (props: IconProps) => {
  return <Icon svgComponent={UserIconSvg} {...props} />;
};

export const DoorOpenIcon = (props: IconProps) => {
  return <Icon svgComponent={DoorOpenSvg} {...props} />;
};

export const DoorCloseIcon = (props: IconProps) => {
  return <Icon svgComponent={DoorCloseSvg} {...props} />;
};

export const CoolIcon = (props: IconProps) => {
  return <Icon svgComponent={CoolSvg} {...props} />;
};

export const FanIcon = (props: IconProps) => {
  return <Icon svgComponent={FanSvg} {...props} />;
};

export const SettingIcon = (props: IconProps) => {
  return <Icon svgComponent={SettingSvg} {...props} />;
};
export const SettingMultipleIcon = (props: IconProps) => {
  return <Icon svgComponent={SettingMultipleSvg} {...props} />;
};

export const TemperatureIcon = (props: IconProps) => {
  return <Icon svgComponent={TemperatureSvg} {...props} />;
};

export const PreCoolIcon = (props: IconProps) => {
  return <Icon svgComponent={PreCoolSvg} {...props} />;
};

export const AutomationIcon = (props: IconProps) => {
  return <Icon svgComponent={AutomationSvg} {...props} />;
};

export const ForbiddenIcon = (props: IconProps) => {
  return <Icon svgComponent={ForbiddenSvg} {...props} />;
};

export const ReloadIcon = (props: IconProps) => {
  return <Icon svgComponent={ReloadSvg} {...props} />;
};

export const UnoccupiedIcon = (props: IconProps) => {
  return <Icon svgComponent={UnoccupiedSvg} {...props} />;
};

export const WarningIcon = (props: IconProps) => {
  return <Icon svgComponent={WarningSvg} {...props} />;
};

export const DryIcon = (props: IconProps) => {
  return <Icon svgComponent={DryModeSvg} {...props} />;
};

export const AutoModeIcon = (props: IconProps) => {
  return <Icon svgComponent={AutoModeSvg} {...props} />;
};

export const QuestionIcon = (props: IconProps) => {
  return <Icon svgComponent={RhombusQuestionSvg} {...props} />;
};

export const PowerOnIcon = (props: IconProps) => {
  return <Icon svgComponent={PowerOnSvg} {...props} />;
};

export const PowerOffIcon = (props: IconProps) => {
  return <Icon svgComponent={PowerOffSvg} {...props} />;
};

export const ReloadTimeIcon = (props: IconProps) => {
  return <Icon svgComponent={ReloadTimeSvg} {...props} />;
};

export const ExpandIcon = (props: IconProps) => {
  return <Icon svgComponent={ExpandSvg} {...props} />;
};

export const CollapseIcon = (props: IconProps) => {
  return <Icon svgComponent={CollapseSvg} {...props} />;
};

export const SunIcon = (props: IconProps) => {
  return <Icon svgComponent={SunSvg} {...props} />;
};

export const DownloadIcon = (props: IconProps) => {
  return <Icon svgComponent={DownloadSvg} {...props} />;
};

export const DownArrowIcon = (props: IconProps) => {
  return <Icon svgComponent={DownArrowSvg} {...props} />;
};

export const ShareIcon = (props: IconProps) => {
  return <Icon svgComponent={ShareSvg} {...props} />;
};

export const InformationIcon = (props: IconProps) => {
  return <Icon svgComponent={InformationSvg} {...props} />;
};

export const FilterIcon = (props: IconProps) => {
  return <Icon svgComponent={FilterSvg} {...props} />;
};

export const FilterV2Icon = (props: IconProps) => {
  return <Icon svgComponent={FilterV2Svg} {...props} />;
};

export const SurveyIcon = (props: IconProps) => {
  return <Icon svgComponent={SurveyIconSvg} {...props} />;
};

export const ComputerIcon = (props: IconProps) => {
  return <Icon svgComponent={ComputerSvg} {...props} />;
};

export const EmptyStateIcon = (props: IconProps) => {
  return <Icon svgComponent={EmptyStateSvg} {...props} />;
};

export const CircleOutlinedIcon = (props: IconProps) => {
  return <Icon svgComponent={CircleOutlinedSvg} {...props} />;
};
export const KeyIcon = (props: IconProps) => {
  return <Icon svgComponent={Key} {...props} />;
};
export const CalendarIcon = (props: IconProps) => {
  return <Icon svgComponent={Calendar} {...props} />;
};

export const IcFillEngineerIcon = (props: IconProps) => {
  return <Icon svgComponent={IcFilledEngineer} {...props} />;
};
export const ContractorIcon = (props: IconProps) => {
  return <Icon svgComponent={Contractor} {...props} />;
};
export const TodoListIcon = (props: IconProps) => {
  return <Icon svgComponent={TodoList} {...props} />;
};
export const SensorIcon = (props: IconProps) => {
  return <Icon svgComponent={Sensor} {...props} />;
};
export const ThermostatIcon = (props: IconProps) => {
  return <Icon svgComponent={Thermostat} {...props} />;
};
export const VoltMeterIcon = (props: IconProps) => {
  return <Icon svgComponent={VoltMeter} {...props} />;
};
export const AirPumpIcon = (props: IconProps) => {
  return <Icon svgComponent={AirPump} {...props} />;
};
export const InProgressIcon = (props: IconProps) => {
  return <Icon svgComponent={InProgress} {...props} />;
};

export const DoorExitIcon = (props: IconProps) => {
  return <Icon svgComponent={DoorExit} {...props} />;
};

export const ChatQuestionIcon = (props: IconProps) => {
  return <Icon svgComponent={ChatQuestion} {...props} />;
};

export const LoadingTestRunIcon = (props: IconProps) => {
  return <Icon svgComponent={LoadingTestRun} {...props} />;
};

export const ThreeDotsVerticalIcon = (props: IconProps) => {
  return <Icon svgComponent={ThreeDotsVertical} {...props} />;
};

export const EditSquareIcon = (props: IconProps) => {
  return <Icon svgComponent={EditSquare} {...props} />;
};

export const RefreshIcon = (props: IconProps) => {
  return <Icon svgComponent={Refresh} {...props} />;
};

export const InforIcon = (props: IconProps) => {
  return <Icon svgComponent={Infor} {...props} />;
};

export const EditIcon = (props: IconProps) => {
  return <Icon svgComponent={Edit} {...props} />;
};

export const TrashIcon = (props: IconProps) => {
  return <Icon svgComponent={Trash} {...props} />;
};

export const RouterWifiIcon = (props: IconProps) => {
  return <Icon svgComponent={RouterWifi} {...props} />;
};

export const HourglassIcon = (props: IconProps) => {
  return <Icon svgComponent={Hourglass} {...props} fill={props?.fill || "#a4a4a4"} />;
};

export const CommentsIcon = (props: IconProps) => {
  return <Icon svgComponent={Comments} {...props} />;
};

export const EcologyIcon = (props: IconProps) => {
  return <Icon svgComponent={Ecology} {...props} />;
};

export const CircleFilledIcon = (props: IconProps) => {
  return <Icon svgComponent={CircleFilled} {...props} />;
};

export const CheckInIcon = (props: IconProps) => {
  return <Icon svgComponent={CheckIn} {...props} />;
};

export const CheckOutIcon = (props: IconProps) => {
  return <Icon svgComponent={CheckOut} {...props} />;
};

export const UnlinkIcon = (props: IconProps) => {
  return <Icon svgComponent={Unlink} {...props} />;
};

export const StartButtonIcon = (props: IconProps) => {
  return <Icon svgComponent={StartButton} {...props} />;
};

export const WarningTriangleIcon = (props: IconProps) => {
  return <Icon svgComponent={WarningTriangle} {...props} />;
};

export const EmailIcon = (props: IconProps) => {
  return <Icon svgComponent={Email} {...props} />;
};

export const CloseCircleIcon = (props: IconProps) => {
  return <Icon svgComponent={CloseCircle} {...props} />;
};
export const CalendarEmptyIcon = (props: IconProps) => {
  return <Icon svgComponent={CalendarEmpty} {...props} />;
};
export const ClockIcon = (props: IconProps) => {
  return <Icon svgComponent={Clock} {...props} />;
};

export const StopIcon = (props: IconProps) => {
  return <Icon svgComponent={Stop} {...props} />;
};

export const PoweredBySensorLogo = (props: IconProps) => {
  return <Icon svgComponent={PoweredBySensor} {...props} />;
};

export const ReloadWithBackgroundIcon = (props: IconProps) => {
  return <Icon svgComponent={ReloadWithBackground} {...props} />;
};

export const OrangeKeyIcon = (props: IconProps) => {
  return <Icon svgComponent={OrangeKey} {...props} />;
};

export const BlueTempIcon = (props: IconProps) => {
  return <Icon svgComponent={BlueTemp} {...props} />;
};

export const CoolFilledIcon = (props: IconProps) => {
  return <Icon svgComponent={Cold} {...props} />;
};

export const FanFilledIcon = (props: IconProps) => {
  return <Icon svgComponent={FanFilled} {...props} />;
};
