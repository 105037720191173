import React, { useCallback, useMemo } from "react";
import TableV3 from "components/Table/TableV3";
import { message } from "antd";
import { useDeleteGroupMutation, useUpdateGroupMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { GroupListProps, ItemType } from "./GroupList.d";
import EditGroupButton from "./EditGroupButton";
import DeleteGroupButton from "./DeleteGroupButton";

const GroupList: React.FC<GroupListProps> = ({ data, pagination, onPaginationChange, reload }) => {
  const [editGroup] = useUpdateGroupMutation({
    onCompleted: () => {
      message.success("Edit group successfully!");
      reload();
    },
    onError: errorHandler.handleError,
  });

  const [deleteGroup] = useDeleteGroupMutation({
    onCompleted: () => {
      message.success("Delete group successfully!");
      reload();
    },
    onError: errorHandler.handleError,
  });

  const handleEditGroup = useCallback(
    async (groupId: string, groupName: string, groupType: string) => {
      await editGroup({
        variables: {
          groupId,
          groupName,
          groupType,
        },
      });
    },
    [editGroup]
  );

  const handleDeleteGroup = useCallback(
    async (groupId: string) => {
      await deleteGroup({
        variables: { groupId },
      });
    },
    [deleteGroup]
  );

  const getGroupColumList = () => {
    return [
      {
        title: "Group name",
        render: (record: ItemType) => <span>{record?.positionName}</span>,
        visibleOnDetailView: false,
      },
      {
        title: "Group type",
        render: (record: ItemType) => <span>{record?.positionType}</span>,
        visibleOnDetailView: false,
      },
      {
        title: "#Keys",
        render: (record: ItemType) => <span>{record?.keysCount}</span>,
        visibleOnDetailView: false,
      },
      {
        title: "#Gateways",
        render: (record: ItemType) => <span>{record?.gatewaysCount}</span>,
        visibleOnDetailView: false,
      },
      {
        title: "#Compressors",
        render: (record: ItemType) => <span>{record?.compressorsCount}</span>,
        visibleOnDetailView: false,
      },
      {
        render: (record: ItemType) => {
          return (
            <>
              <EditGroupButton record={record} handleEditGroup={handleEditGroup} />
              <DeleteGroupButton groupId={record.positionId} handleDeleteGroup={handleDeleteGroup} />
            </>
          );
        },
        visibleOnDetailView: false,
      },
    ];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => getGroupColumList(), []);
  const genRowClassNames = React.useCallback(
    () => ({
      "cursor-pointer": true,
    }),
    []
  );
  return (
    <>
      <TableV3
        columns={columns}
        rowKey="id"
        tableData={data}
        rowClassName={genRowClassNames}
        pagination={pagination}
        onPaginationChanged={onPaginationChange}
      />
    </>
  );
};

export default GroupList;
