import moment from "moment";
import { LocationOtaSetting, LocationOtaSettingFormValues } from "./LocationOtaSettings.d";
import { TIME_24H_FORMAT } from "../../../utils/date";

export enum LocationOtaSettingFormField {
  OTA_ENABLED = "otaEnabled",
  OTA_MAX_RETRIES = "otaMaxRetries",
  OTA_TIME_WINDOW = "otaTimeWindow",
}

export const getOtaTimeSettingDefaultValue = ({
  curStart,
  curEnd,
}: {
  curStart?: string | null;
  curEnd?: string | null;
}) => {
  if (!curStart && !curEnd) return undefined;
  let momentCurStart = moment(curStart, TIME_24H_FORMAT);
  let momentCurEnd = moment(curEnd, TIME_24H_FORMAT);
  if (!momentCurStart?.isValid() && !momentCurEnd?.isValid()) {
    return undefined;
  }
  if (!momentCurStart?.isValid()) {
    momentCurStart = momentCurEnd;
  }
  if (!momentCurEnd?.isValid()) {
    momentCurEnd = momentCurStart;
  }
  return [momentCurStart, momentCurEnd] as [moment.Moment, moment.Moment];
};

export const getOtaSettingDefaultValue = (curSetting: LocationOtaSetting): LocationOtaSettingFormValues => {
  return {
    otaEnabled: curSetting.otaIsEnabled || false,
    otaMaxRetries: curSetting.otaMaxRetries || undefined,
    otaTimeWindow: getOtaTimeSettingDefaultValue({
      curStart: curSetting.otaWindowStartTime,
      curEnd: curSetting.otaWindowEndTime,
    }),
  };
};
