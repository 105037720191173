import React, { useCallback, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { isEmpty, isString, startCase } from "lodash";
import { GroupModalProps, FormValue, GroupType } from "./GroupModal.d";

const GroupModal: React.FC<GroupModalProps> = ({ formValue, isUpdate, onSubmit, errors, loading, onCancel, open }) => {
  const [form] = Form.useForm<FormValue>();

  useEffect(() => {
    if (isUpdate) form.setFieldsValue(formValue);
  }, [isUpdate, formValue, form]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      const fields = Object.keys(errors).map((field) => {
        const messages = errors[field as keyof FormValue];
        return { name: field, errors: isString(messages) ? [messages] : messages };
      });
      form.setFields(fields);
    }
  }, [errors, form]);

  const handleConfirm = useCallback(async () => {
    await form.validateFields();
    form.submit();
  }, [form]);

  return (
    <Modal
      title={isUpdate ? "Edit Group" : "Add Group"}
      centered
      width={450}
      onOk={handleConfirm}
      okText={isUpdate ? "Edit group" : "Add group"}
      okButtonProps={{ loading }}
      cancelText="Cancel"
      onCancel={onCancel}
      open={open}
    >
      <Form layout="vertical" form={form} initialValues={formValue} onFinish={(value) => onSubmit(value, form)}>
        <Form.Item
          label="Group name"
          name="name"
          rules={[
            {
              required: true,
              message: "Group name is required",
            },
          ]}
        >
          <Input placeholder="Enter Group name" autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Group type"
          name="type"
          rules={[
            {
              required: true,
              message: "Group type is required",
            },
          ]}
        >
          <Select placeholder="Choose Group type">
            {Object.values(GroupType).map((type) => {
              return (
                <Select.Option key={type} value={type}>
                  {startCase(type)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GroupModal;
