import errorHandler from "errorHandler";
import { isEmpty, isUndefined } from "lodash";
import { usePmsPropertyEodSurveysSubscription } from "pacts/app-webcore/hasura-webcore.graphql";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import { MAX_POSSIBLE_ROW_CNT } from "utils/constants";
import { EodSurveyRecord } from "../EodSurveys/types";

export const useFetchAllEodSurveys = (
  location?: LocationWithPmsProperty
): {
  surveys: EodSurveyRecord[];
  loading?: boolean;
} => {
  const { data, loading, error } = usePmsPropertyEodSurveysSubscription({
    variables: {
      pmsId: isUndefined(location?.pms?.pmsId) ? -1 : location?.pms?.pmsId || 0,
      propertyId: location?.pms?.propertyId || "",
      offset: 0,
      limit: MAX_POSSIBLE_ROW_CNT,
    },
  });

  React.useEffect(() => {
    if (error) {
      errorHandler.handleError(error);
    }
  }, [error]);
  return {
    surveys: (isEmpty(data?.surveys) ? [] : data?.surveys || []) as EodSurveyRecord[],
    loading,
  };
};

export default useFetchAllEodSurveys;
