import { Col, Divider, Empty, Row } from "antd";
import LocationSwitcher from "components/LocationSwitcher";
import { useSfLocationWithPmsProperty } from "hooks/use-sensorflow-location";
import { isEmpty } from "lodash";
import React from "react";
import useBreakpoint from "hooks/use-breakpoint";
import { useFetchAllEodSurveys } from "../hooks/use-fetch-all-eod-surveys";
import EodSurveyIframe from "./EodSurveyIframe";
import EodSurveySideBar from "./EodSurveySideBar";
import EodSurveyMobile from "./EodSurveyMobile";
import { EodSurveyRecord } from "./types";

const EodSurvey = () => {
  const { location, onLocationIdSelected } = useSfLocationWithPmsProperty();
  const { surveys, loading } = useFetchAllEodSurveys(location);
  const [focusedSurvey, setFocusedSurvey] = React.useState<EodSurveyRecord>();
  const screen = useBreakpoint();

  React.useEffect(() => {
    if (!isEmpty(surveys)) {
      setFocusedSurvey(surveys[0]);
    }
  }, [surveys]);

  if (screen.mobileAndTabletOnly) {
    return (
      <EodSurveyMobile
        location={location}
        onLocationIdChosen={onLocationIdSelected}
        surveys={surveys}
        focusedSurvey={focusedSurvey}
        onSurveyFocused={setFocusedSurvey}
        loading={loading}
      />
    );
  }

  return (
    <>
      <Row justify="start" align="middle" className="pb-s">
        <Col>
          <h1 className="font-weight-bold mr-l">EOD SURVEY</h1>
        </Col>
        <Col>
          <LocationSwitcher onChange={onLocationIdSelected} />
        </Col>
      </Row>
      <Divider className="mt-s mb-l" />
      {loading || isEmpty(surveys) ? (
        <Empty />
      ) : (
        <Row>
          <Col>
            <EodSurveySideBar
              surveys={surveys}
              location={location}
              onSurveyFocused={setFocusedSurvey}
              focusedSurvey={focusedSurvey}
            />
          </Col>
          <Col flex={1}>
            <EodSurveyIframe survey={focusedSurvey} />
          </Col>
        </Row>
      )}
      <div className="h-2.5r" />
    </>
  );
};

export default EodSurvey;
