import React, { useState } from "react";

export const MenuContext = React.createContext<any>(null);
export const MenuConsumer = MenuContext.Consumer;

export const MenuProvider = (props: { children: any }) => {
  const { children } = props;
  const [dynamicMenu, setDynamicMenu] = useState<React.ReactNode>(<></>);

  return <MenuContext.Provider value={{ dynamicMenu, setDynamicMenu }}>{children}</MenuContext.Provider>;
};
