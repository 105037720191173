import { Col, Collapse, Row } from "antd";
import { isEmpty, keyBy } from "lodash";
import SearchBar from "components/SearchBar/SearchBar";
import React from "react";
import { Order_By, useLocationWithOtaSettingsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LocationOtaSetting } from "./LocationOtaSettings.d";
import LocationOtaSettingForm from "./LocationOtaSettingForm";

const LocationOtaSettingList = () => {
  const [locationOtaSettings, setLocationOtaSettings] = React.useState<Record<string, LocationOtaSetting>>({});
  const [locationNameSearch, setLocationNameSearch] = React.useState<string>("");
  useLocationWithOtaSettingsQuery({
    variables: {
      where: locationNameSearch
        ? {
            locationName: { _like: `%${locationNameSearch}%` },
          }
        : undefined,
      order_by: [
        {
          locationName: Order_By.Asc,
        },
      ],
    },
    onError: errorHandler.handleError,
    onCompleted: (data) => {
      setLocationOtaSettings(keyBy(data.locations, "locationId"));
    },
  });

  const updateLocationOtaSetting = React.useCallback(
    (newSetting?: Partial<LocationOtaSetting> | null) => {
      if (isEmpty(newSetting) || newSetting?.locationId) return;
      setLocationOtaSettings((currentMap) => {
        return {
          ...currentMap,
          [newSetting?.locationId]: {
            ...currentMap[newSetting?.locationId],
            ...newSetting,
          },
        };
      });
    },
    [setLocationOtaSettings]
  );

  return (
    <>
      <Row className="mb-l">
        <Col>
          <Link to="/ota">
            <ArrowLeftOutlined /> Back to OTA
          </Link>
          <h2 className="header-title mt-l">Location OTA Settings</h2>
        </Col>
      </Row>
      <Row className="mb-l">
        <Col flex={1}>
          <SearchBar placeholder="Type to search Location" onInputChange={setLocationNameSearch} />
        </Col>
      </Row>
      <Row className="mb-l">
        <Col flex={1}>
          <Collapse>
            {Object.entries(locationOtaSettings).map(([locId, setting]) => (
              <Collapse.Panel className="w-100" key={locId} header={setting.locationName}>
                <LocationOtaSettingForm
                  key={locId}
                  currentSetting={setting}
                  onSettingChange={updateLocationOtaSetting}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default LocationOtaSettingList;
