import React, { useCallback } from "react";
import { Button } from "antd";
import { useIntercom } from "react-use-intercom";

export interface IGetHelpButtonCustomElementProps {
  triggerHelp: () => void;
  helpText?: string;
}
interface IGetHelpButtonProps {
  helpText?: string;
  className?: string;
  as?: (props: IGetHelpButtonCustomElementProps) => JSX.Element;
}

const GetHelpButton = ({ helpText, className, as: CustomEl }: IGetHelpButtonProps) => {
  const { showNewMessages } = useIntercom();

  const onClick = useCallback(() => {
    if (helpText) {
      showNewMessages(helpText);
    }
  }, [showNewMessages, helpText]);

  if (CustomEl) {
    return <CustomEl triggerHelp={onClick} />;
  }

  return (
    <Button type="ghost" onClick={onClick} disabled={!helpText} className={className}>
      Get help
    </Button>
  );
};

export default GetHelpButton;
