import { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { DATETIME_24H_FORMAT } from "utils/date";
import { PreCoolStatus } from "../CustomerKeyList/KeyList.d";

const usePrecoolCountDown = () => {
  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const ticker = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => {
      clearInterval(ticker);
    };
  }, []);

  const getRemainingTime = useCallback(
    (
      expiredTime: Moment | null | undefined,
      status: PreCoolStatus | null | undefined,
      now: Moment,
      startedAt: Moment | null | undefined,
      emptyValue = ""
    ): string => {
      if (status && status === PreCoolStatus.SCHEDULED) {
        if (startedAt && startedAt > moment() && emptyValue !== "") {
          return `Scheduled for ${startedAt.format(DATETIME_24H_FORMAT)}`;
        }
        return emptyValue;
      }
      if (!expiredTime || expiredTime.isSameOrBefore(now)) return emptyValue;
      const duration = moment.duration(expiredTime.diff(now));
      const message = emptyValue === "" ? emptyValue : "Pre-set ends in ";
      return `${message}${duration.hours().toString().padStart(2, "0")}:${duration
        .minutes()
        .toString()
        .padStart(2, "0")}`;
    },
    []
  );

  return {
    currentTime,
    getRemainingTime,
  };
};

export default usePrecoolCountDown;
