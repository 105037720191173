import React from "react";

const StatusText = (props: { children: any; isNotAvailable?: boolean; isNotUpdated?: boolean }): React.ReactElement => {
  const { children, isNotAvailable, isNotUpdated } = props;

  if (isNotAvailable) {
    return <span className="text-warning font-weight-bold">Not Available</span>;
  }

  if (isNotUpdated) {
    return <span className="text-warning font-weight-bold">{children}</span>;
  }

  return <span className="font-weight-bold">{children}</span>;
};

export default StatusText;
