import { NodeSubType, NodeType } from "../../../pacts/app-webcore/hasura-webcore.graphql";

export enum EnergyNodeSubTypes {
  EnergySwitched = "energy_switched",
  EnergySwitchless = "energy_switchless",
}

export const OTA_NODE_TYPES = [
  NodeSubType.Aircon_4pfc,
  NodeSubType.Aircon_2pfc,
  NodeSubType.AirconAcir,
  NodeSubType.AirconDaikin,
  NodeType.Door,
  NodeSubType.DoorMagnetic,
  NodeSubType.DoorLaser,
  NodeType.Occupancy,
  NodeType.Energy,
  EnergyNodeSubTypes.EnergySwitched,
  EnergyNodeSubTypes.EnergySwitchless,
  NodeType.FlowMeter,
  NodeType.PipeTemp,
];
