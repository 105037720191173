import { useState } from "react";
import { message } from "antd";
import { useCreateInfraMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { ApolloError } from "apollo-client";
import { ErrorMessage, GraphQLErrorCode } from "utils/constants";
import errorHandler from "errorHandler";
import { InfrastructureTypeEnum } from "../Infrastructures.d";
import { InfrastructureData } from "../InfrastructureDataType";

interface AddInfrastructureActionResp {
  createInfrastructure: (data: InfrastructureData) => void;
  isLoading: boolean;
}

export default function AddInfrastructureAction({ onSuccessCallback }: any): AddInfrastructureActionResp {
  const [infraName, setInfraName] = useState<string>();

  const createInfrastructureCallback = {
    onCompleted: (data: any) => {
      message.success(`${infraName} created`);
      onSuccessCallback(data.createInfra.id);
    },
    onError: (error: ApolloError) => {
      errorHandler.handleError(error, {
        [GraphQLErrorCode.CONSTRAINT_VIOLATION]: ErrorMessage.INFRASTRUCTURE_NAME_ALREADY_EXISTS,
      });
    },
  };

  const [createInfra, { loading: createInfraLoading }] = useCreateInfraMutation(createInfrastructureCallback);

  const createInfrastructure = (data: InfrastructureData) => {
    setInfraName(data.name);

    createInfra({
      variables:
        data.type === InfrastructureTypeEnum.PIPE || data.type === InfrastructureTypeEnum.CHILLED_WATER
          ? data.toInputWithoutPhase(data.type)
          : data.toInput(),
    });
  };

  return { createInfrastructure, isLoading: createInfraLoading };
}
