import { useState } from "react";
import { message } from "antd";
import { useCreateRoomCompressorsMutation, RoomCompressorCreateInput } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { InfraPhaseEnum } from "../Infrastructures.d";

interface AddCompressorActionProps {
  onSuccessCallback: () => void;
}

const compressorNameGenerator = (positionName: string, roomName: string) => `${positionName} - ${roomName}`;

const roomCompressorInputBuilder = (
  locationId: string,
  roomName: string,
  numberOfPhases: InfraPhaseEnum,
  positionId: string,
  hvacSystemType: string,
  ctMultiplier: number
): RoomCompressorCreateInput => ({
  compressorName: roomName,
  compressorNumPhases: numberOfPhases,
  positionMappingId: positionId,
  position: {
    positionName: roomName,
    parentPositionId: locationId,
    locationId,
  },
  hvacSystemType,
  ctMultiplier,
});

export default function AddCompressorAction({ onSuccessCallback }: AddCompressorActionProps) {
  const [compressorCounter, setCompressorCounter] = useState<number>();

  const [createRoomCompressors, { loading: isLoading }] = useCreateRoomCompressorsMutation({
    onCompleted: () => {
      message.success(`${compressorCounter} Compressors Created`);
      onSuccessCallback();
    },
    onError: errorHandler.handleError,
  });

  const addCompressors = (
    locationId: string,
    numbOfPhases: InfraPhaseEnum,
    hvacSystemType: string,
    ctMultiplier: number,
    compressors: any[]
  ) => {
    const compressorsInput = compressors.map((compressor) =>
      roomCompressorInputBuilder(
        locationId,
        compressorNameGenerator(compressor.positionName, compressor.roomName),
        numbOfPhases,
        compressor.positionId,
        hvacSystemType,
        ctMultiplier
      )
    );

    setCompressorCounter(compressors.length);

    createRoomCompressors({
      variables: {
        compressorsInput,
      },
    });
  };

  return { addCompressors, isLoading };
}
