import { usePublicRouteContext } from "App/PublicRoutes";
import { Drawer } from "antd";
import { PoweredBySensorLogo } from "components/Icons";
import errorHandler from "errorHandler";
import {
  ComfortplusKeyDetailsQuery,
  useSetComfortPlusUserOnboardedMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Onboarding.scss";
import OnboardStep from "./components/OnboardStep";

interface OnboardingProps {
  [x: string]: any;
  comfortplusKeyDetail?: ComfortplusKeyDetailsQuery["data"][number];
}

const Onboarding: React.FC<OnboardingProps> = ({ comfortplusKeyDetail }) => {
  const [step, setStep] = useState<number>(1);
  const history = useHistory();

  const { setPublicHeaderShown, setLayoutClassNames, setIsOnboarding } = usePublicRouteContext();

  useEffect(() => {
    setPublicHeaderShown?.(false);
    setIsOnboarding?.(true);
    setLayoutClassNames?.({ "layout-onloading": true });
  }, [setPublicHeaderShown, setLayoutClassNames, setIsOnboarding]);

  const [handOnboarding, { loading }] = useSetComfortPlusUserOnboardedMutation({
    onCompleted: () => {
      setLayoutClassNames?.({ "layout-onloading": false });
      setPublicHeaderShown?.(true);
      setIsOnboarding?.(false);
      history.push(`/public/comfortplus`);
    },
    onError: errorHandler.handleError,
  });

  const steps: Record<number, { image: string; title: string; desc: string }> = useMemo(
    () => ({
      1: {
        title: "Welcome to ComfortPlus!",
        desc: "We're delighted to have you onboard. Our app is designed to enhance your hotel stay experience by giving you control over the room's temperature, even when you're not there.",
        image: (comfortplusKeyDetail?.logoUrl || comfortplusKeyDetail?.locationName) as string,
      },
      2: {
        title: "Pre cool your room from anywhere",
        desc: "With a simple tap, you can pre-cool your room  for <b>15 minutes</b> before you return, ensuring you always walk into a comfortable environment.",
        image: `${window.location.origin}/images/onboarding/step-2.png`,
      },
      3: {
        title: "Enjoy your stay with ComfortPlus!",
        desc: "Not only does this feature provide you with immediate comfort, but it also contributes to energy conservation, making your stay more sustainable. Together, we can make your stay pleasant and our planet greener.",
        image: `${window.location.origin}/images/onboarding/step-3.png`,
      },
    }),
    [comfortplusKeyDetail]
  );

  const dataStep = useMemo(() => {
    return steps[step];
  }, [steps, step]);

  const handleOnSkip = async () => {
    await handOnboarding();
  };

  const handleOnNext = () => {
    const newStep = step + 1;
    if (steps[newStep]) setStep(newStep);
    else handleOnSkip();
  };

  return (
    <div className="onboarding">
      <div className="onboarding-container">
        <Drawer
          title={<PoweredBySensorLogo />}
          placement="right"
          closable={false}
          open
          getContainer={false}
          className={`step-${step}`}
        >
          <div className="onboarding-content">
            <OnboardStep
              {...dataStep}
              currentStep={step}
              steps={Object.keys(steps).length}
              onNext={handleOnNext}
              onSkip={handleOnSkip}
              isLoading={loading}
            />
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Onboarding;
