import { Affix, Col, Pagination, Row } from "antd";
import React from "react";
import { getDefaultMobileHelpText } from "./helpers";
import { ITablePaginationProps } from "./Table.d";

const TablePaginationMobile = ({ onPaginationChanged, pagination, getMobileDisplayText }: ITablePaginationProps) => {
  return (
    <Affix offsetBottom={0} className="w-100" style={{ position: "fixed", left: 0 }}>
      <Row className="py-m px-s bg-white" justify="space-between" align="middle" wrap={false}>
        <Col>
          <span>{getMobileDisplayText?.(pagination) || getDefaultMobileHelpText(pagination)}</span>
        </Col>
        <Col>
          <Pagination
            responsive
            total={pagination.total}
            onChange={(page) => {
              window.scrollTo(0, 0);
              onPaginationChanged({ ...pagination, current: page });
            }}
            showSizeChanger={false}
            current={pagination.current}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </Affix>
  );
};

export default TablePaginationMobile;
