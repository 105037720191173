import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Table from "components/Table/Table";
import useLocationDateTime from "hooks/use-location-datetime";
import { get } from "lodash";
import { Order_By as OrderBy } from "pacts/app-webcore/hasura-webcore.graphql";
import React from "react";

import { FirmwareRelease } from "../FirmwareList/FirmwareList.d";

const sortOrder = (value: OrderBy) => get({ [OrderBy.Asc]: "ascend", [OrderBy.Desc]: "descend" }, value, "");

const FirmwareTable = (props: any) => {
  const { sort, sortBy, onDeleteFirmware, onDeprecated, onUpdateFirmware } = props;
  const { formatDate } = useLocationDateTime();

  const getSortConfig = (sortKey: string) => {
    return {
      onHeaderCell: () => ({
        onClick: () => {
          sortBy(sortKey);
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort[sortKey]),
    };
  };

  const columns = [
    {
      title: "FW Version",
      render: (record: FirmwareRelease) => record.versionName,
      ...getSortConfig("versionName"),
    },
    {
      title: "FW Version Number",
      render: (record: FirmwareRelease) => record.versionNumber,
      ...getSortConfig("versionNumber"),
    },
    {
      title: "Upload Date",
      render: (record: FirmwareRelease) => formatDate(record.uploadedDate),
      ...getSortConfig("uploadedDate"),
    },
    {
      title: "Outdated",
      render: (record: FirmwareRelease) => (
        <Checkbox checked={!!record.isDeprecated} onChange={() => onDeprecated(record.id, !record.isDeprecated)} />
      ),
      align: "center",
      ...getSortConfig("isDeprecated"),
    },
    {
      title: "Comments",
      render: (record: FirmwareRelease) => record.comment,
    },
    {
      title: "",
      render: (record: FirmwareRelease) => (
        <>
          <Button
            type="link"
            icon={<EditFilled />}
            onClick={() => {
              onUpdateFirmware(record);
            }}
          />
          <Button
            type="link"
            icon={<DeleteFilled />}
            onClick={() => {
              onDeleteFirmware(record.id);
            }}
          />
        </>
      ),
      align: "right",
    },
  ];

  return <Table {...props} columns={columns} rowKey="id" scroll={{ x: true }} />;
};

export default FirmwareTable;
