import React, { useCallback, useState } from "react";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import {
  GatewayHealthStatus,
  GetWorkInfoQuery,
  TemporaryGatewayKittingProcessMetadataQuery,
  useGetWorkInfoQuery,
  useTemporaryGatewayKittingProcessMetadataQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { JobDetailHeader, bindingWorkInfoData } from "components/JobDetailHeader";
import { JobChecklist } from "./JobChecklist";
import { GatewayJobChecklistProps, Gateways, WorkInfo } from "./GatewayJobChecklist.d";

const extractGateways = (data: TemporaryGatewayKittingProcessMetadataQuery): Gateways[] => {
  const gateways = data.gatewayKittingProcessMetadata
    .filter((metadata) => metadata.gateway)
    .map((gateway) => {
      const healthStatus = gateway?.gateway?.gatewayHealthData?.length
        ? GatewayHealthStatus.Online
        : GatewayHealthStatus.Offline;

      const kittingStatus = [GatewayHealthStatus.Online, GatewayHealthStatus.Offline].includes(
        gateway?.status as GatewayHealthStatus
      )
        ? GatewayHealthStatus.Healthy
        : (gateway?.status as GatewayHealthStatus);

      return {
        gatewayId: gateway.balenaId,
        kittingStatus,
        healthStatus,
        gatewayName: gateway?.gateway?.gatewayName ?? "",
        networkConnectionStatus: gateway?.gateway?.networkConnectionStatus ?? "",
        gatewayHealthData: gateway?.gateway?.gatewayHealthData ?? [],
        wlanLinkGatewayHealthData: gateway?.gateway?.wlanLinkGatewayHealthData ?? [],
        wlan1LinkGatewayHealthData: gateway?.gateway?.wlan1LinkGatewayHealthData ?? [],
      };
    });

  return gateways;
};

const GatewayJobChecklist: React.FC<GatewayJobChecklistProps> = ({ keyId }) => {
  const { locationId } = useSensorflowLocation();
  const [jobsToBeDone, setJobsToBeDone] = useState<number>(0);
  const [tasksWithRoom, setTasksWithRoom] = useState<WorkInfo[]>();
  const [keyName, setKeyName] = useState<string>("");

  const reloadBinding = useCallback(
    (data: GetWorkInfoQuery["tasksWithKeys"], gateways: Gateways[]) => {
      const { countJobsNotDone, workInfo } = bindingWorkInfoData(data, gateways);
      setJobsToBeDone(countJobsNotDone);
      setTasksWithRoom(workInfo);
    },
    [setJobsToBeDone, setTasksWithRoom]
  );

  const { refetch: getTemporaryGateWay } = useTemporaryGatewayKittingProcessMetadataQuery({
    variables: { locationId },
    skip: true,
  });

  const { refetch } = useGetWorkInfoQuery({
    variables: { where: { keyId: { _in: [keyId] } } },
    onCompleted: async (data) => {
      const gatewayRes = await getTemporaryGateWay();
      const gateways = extractGateways(gatewayRes.data);
      const gateName = gateways.find((gateway) => gateway.gatewayId === keyId);
      setKeyName(gateName?.gatewayName ?? "");
      reloadBinding(data?.tasksWithKeys || [], gateways || []);
    },
    onError: errorHandler.handleError,
  });

  const reloadKey = useCallback(async () => {
    const { data } = await refetch();
    const gatewayRes = await getTemporaryGateWay();
    const gateways = extractGateways(gatewayRes.data);
    reloadBinding(data?.tasksWithKeys || [], gateways || []);
  }, [reloadBinding, refetch, getTemporaryGateWay]);

  return (
    <div>
      <JobDetailHeader jobsToBeDone={jobsToBeDone} keyId={keyId} keyName={keyName} />

      <div className="mx-m">
        <JobChecklist isInInstallation reloadKey={reloadKey} data={tasksWithRoom || []} />
      </div>
    </div>
  );
};
export default GatewayJobChecklist;
