export default [
  {
    id: "8da1e6b0-3a61-44e5-8e96-416df241cfc9",
    locationId: "27407651-106f-4bb3-98f6-efd87478b5d6",
    validationStartDate: "2022-09-14 11:20:18.000 +0700",
    validationEndDate: "2022-09-14 11:20:22.000 +0700",
  },
  {
    id: "8da1e6b0-3a61-44e5-8e96-416df241cfa1",
    locationId: "c11de66b-50ab-4099-a3db-16eecbb4dc42",
    validationStartDate: "2022-09-14 11:20:18.000 +0700",
    validationEndDate: null,
    automationMode: "SUPERSAVE",
  },
];
