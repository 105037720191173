import React from "react";

import Modal from "antd/lib/modal/Modal";
import "./GatewayTroubleshoot.scss";

type GatewayTroubleshootProps = {
  setVisible: (isVisible: boolean) => any;
  visible: boolean;
  headerText?: string;
  subText?: string;
  troubleshootSteps?: {
    icon: any;
    step: string;
    description: string;
  }[];
};

const GatewayTroubleshoot = ({
  visible,
  setVisible,
  headerText,
  subText,
  troubleshootSteps,
}: GatewayTroubleshootProps) => {
  return (
    <Modal
      className="troubleshoot-modal"
      title={headerText}
      centered
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      width={464}
    >
      {subText && <p className="troubleshoot-subtext">{subText}</p>}
      {troubleshootSteps &&
        troubleshootSteps.map(({ icon, step, description }, index: number) => (
          <React.Fragment key={index}>
            {icon}
            <p className="troubleshoot-step">{step}</p>
            <p className="troubleshoot-description">{description}</p>
          </React.Fragment>
        ))}
    </Modal>
  );
};

export default GatewayTroubleshoot;
