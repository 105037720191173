import { useState } from "react";
import { AntdTablePagination } from "components/Table/Table.d";

const usePagination = () => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);

  const handlePaginationChange = (page: AntdTablePagination) => {
    setPagination({
      current: page.current,
      pageSize: page.pageSize,
      total: pagination.total,
    });
  };

  const resetPagination = () => {
    setPagination(defaultPagination);
  };

  const getPagingMessage = (locationName: string) => {
    const { current, pageSize, total } = pagination;

    if (total === 0) {
      return `There are no keys in ${locationName}`;
    }

    const start = (current - 1) * pageSize + 1;
    const end = Math.min(total, start + pageSize - 1);

    return `Showing ${start}-${end} of ${total} keys in ${locationName}`;
  };

  return {
    defaultPagination,
    pagination,
    setPagination,
    resetPagination,
    handlePaginationChange,
    getPagingMessage,
  };
};

export default usePagination;
