import React, { useState } from "react";

import { Form, Input, message, Modal, Select } from "antd";
import { useCreateKeysMutation, useKeyCategoriesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../../errorHandler";

type CreateKeyModalProps = {
  locationId: string;
  locationName: string;
  onCompleted: () => Promise<void>;
  onClose: () => void;
};

const validateMessages = {
  required: "This field is required.",
};

const CreateKeyFormModal = ({ locationId, locationName, onClose, onCompleted }: CreateKeyModalProps) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<any[]>();

  useKeyCategoriesQuery({
    variables: {
      locationIdInput: {
        location: {
          locationId: {
            _eq: locationId,
          },
        },
      },
    },
    onCompleted: ({ keyCategories }) => {
      setCategories(keyCategories);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [createKeyMutation] = useCreateKeysMutation({
    onCompleted: async () => {
      message.success("Key has been created.");
      await onCompleted();
      onClose();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleSubmitCreateKey = (values: any) => {
    createKeyMutation({
      variables: {
        keysInput: [
          {
            locationName,
            categoryName: values.category,
            position: {
              data: {
                positionName: values.keyName,
                positionType: "key",
                positionFunction: "identifier",
                locationId,
                parentPositionId: locationId,
              },
            },
          },
        ],
      },
    });
  };

  return (
    <Modal title="Create Key" centered visible onCancel={onClose} onOk={form.submit} width={400} okText="Confirm">
      <Form
        name="create-key-form"
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        requiredMark={false}
        onFinish={handleSubmitCreateKey}
      >
        <Form.Item name={["keyName"]} label="Key Name" rules={[{ required: true }]}>
          <Input placeholder="Enter name of key" />
        </Form.Item>
        <Form.Item name={["category"]} label="Key Category" rules={[{ required: true }]}>
          <Select className="w-100" data-testid="key-category-testId">
            {categories?.map((option) => (
              <Select.Option key={option.categoryName} value={option.categoryName}>
                {option.categoryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateKeyFormModal;
