import React from "react";
import { message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classnames from "classnames";
import ActionButton from "../ActionButton";

export interface CopyButtonProps {
  input: string;
  icon?: React.ReactElement;
  className?: string;
  btnClassName?: string;
}

const CopyButton = ({ input, icon, className, btnClassName }: CopyButtonProps) => {
  const handleCopy = () => {
    message.success("Text copied.");
  };
  return (
    <CopyToClipboard text={input} onCopy={handleCopy}>
      <ActionButton
        data-testid={`copy-button-testId-${input}`}
        customIcon={icon || <CopyOutlined className={className} />}
        className={classnames("text-gray", btnClassName)}
        onClick={null}
      />
    </CopyToClipboard>
  );
};

export default CopyButton;
