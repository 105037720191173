import { Col, message, Row } from "antd";
import errorHandler from "errorHandler";
import {
  useGetNodeSpareSetsQuery,
  useCreateNodeSpareSetMutation,
  useRemoveNodeSpareSetMutation,
  Order_By,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback, useEffect, useState } from "react";
import { CreateSpareNode } from "./CreateSpareNode";
import { nodeTypeCodeIdentifierMapping } from "./CreateSpareNode/CreateSpareNode";
import { SpareNodesProps, FormData } from "./SpareNodes.d";
import { SpareNodesHeader } from "./SpareNodesHeader";
import SpareNodesTable from "./SpareNodesTable/SpareNodesTable";

const initialValues = {
  nodeMacId: "",
  locationId: "",
  nodeType: undefined,
};

const getByValue = (map: any, searchValue: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
};

const SpareNodes = (props: SpareNodesProps) => {
  const { locationId, locationName } = props;
  const [formValue, setFormValue] = useState<FormData>({ ...initialValues, locationId });
  const [openModal, setOpenModal] = useState(false);
  const [dataTable, setDataTable] = useState<any[]>([]);

  const { data, refetch } = useGetNodeSpareSetsQuery({
    skip: !locationId,
    variables: {
      where: { locationId: { _eq: locationId }, consumedAt: { _is_null: true } },
      order_by: [{ mappedAt: Order_By.Desc }],
    },
    onError: errorHandler.handleError,
  });

  const [createNodeSpareSet] = useCreateNodeSpareSetMutation({
    onError: errorHandler.handleError,
    onCompleted: (result) => {
      refetch();
      setOpenModal(false);
      message.success(`Create spare node successfully for ${result.createNodeSpareSet.nodeMacId}`);
    },
  });

  const [removeNodeSpareSet] = useRemoveNodeSpareSetMutation({
    onError: errorHandler.handleError,
    onCompleted: () => {
      refetch();
      message.success(`Remove spare node successfully`);
    },
  });

  const onRemove = useCallback(
    async (nodeMacId: string) => {
      await removeNodeSpareSet({ variables: { nodeMacId } });
    },
    [removeNodeSpareSet]
  );

  const handleOnSubmit = useCallback(
    async (input: FormData) => {
      const { nodeMacId, nodeType, nodeSubType } = input;
      let nodeMacIdToSubmit = nodeMacId;
      if (nodeMacIdToSubmit.length === 16) {
        nodeMacIdToSubmit = getByValue(nodeTypeCodeIdentifierMapping, nodeType) + nodeMacIdToSubmit;
      }

      if (!nodeMacId || !nodeType) return;
      await createNodeSpareSet({ variables: { nodeMacId: nodeMacIdToSubmit, nodeType, locationId, nodeSubType } });
    },
    [createNodeSpareSet, locationId]
  );

  const handleOnSearch = useCallback(
    (search: string) => {
      if (!data?.sensorflow_node_spare_set.length) return;

      const mapTableData = data.sensorflow_node_spare_set
        .filter((x) => x.locationId === locationId)
        .map((x) => ({ ...x, locationName }));

      if (search) setDataTable(mapTableData.filter((x) => x.nodeMacId.includes(search)));
      else setDataTable(mapTableData);
    },
    [data, locationId, locationName]
  );

  useEffect(() => {
    if (!data?.sensorflow_node_spare_set?.length) {
      setDataTable([]);
    } else {
      const mapTableData = data.sensorflow_node_spare_set
        .filter((x) => x.locationId === locationId)
        .map((x) => ({ ...x, locationName }));
      setDataTable(mapTableData);
    }
  }, [data, locationId, locationName]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <SpareNodesHeader
          handleSearchChange={handleOnSearch}
          onOpen={() => {
            setFormValue({ ...initialValues, locationId });
            setOpenModal(true);
          }}
        />
      </Col>

      <Col span={24}>
        <SpareNodesTable data={dataTable} onDelete={onRemove} />
      </Col>

      {openModal && (
        <CreateSpareNode
          formData={formValue}
          closeModal={() => {
            setOpenModal(false);
          }}
          handleOnSubmit={handleOnSubmit}
        />
      )}
    </Row>
  );
};

export default SpareNodes;
