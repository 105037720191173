import React from "react";
import { Button, Progress, Tag } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import get from "lodash/get";

import { KeySortInput, MappingStatus } from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import Table from "components/Table/Table";
import { Link, useParams } from "react-router-dom";
import useBreakpoint from "hooks/use-breakpoint";
import useRoleAndPermission from "hooks/use-role-and-permission";
import ObjectHealth from "components/ObjectHealth";
import { InstallationModeButton } from "components/InstallationMode";
import { PositionFunction } from "types/InstallationMode.d";
import { ActiveTab } from "pages/Key/KeyDetailWrapper/KeyDetailWrapper";
import { AntdTablePagination } from "components/Table/Table.d";
import { ApolloError } from "apollo-client";
import { getDefaultColumnKeyListTable } from "../key.helper";
import { tableRenderLoadingRow } from "./KeyList.util";
import { Key, KeysWithNodeHealth } from "./KeyList.d";

const MAPPING_STATUS_UI_CONFIG = {
  [MappingStatus.NotStarted]: ["Not Mapped", "default"],
  [MappingStatus.InProgress]: ["In Progress", "warning"],
  [MappingStatus.Completed]: ["Completed", "success"],
};

const sortOrder = (value: any) => get({ ASC: "ascend", DESC: "descend" }, value, "");

export type KeyListTableProps = {
  onReprintClick: (keyId: string) => void;
  tableData: Key[];
  sortBy: (columnHeader: keyof KeySortInput) => void;
  sort: KeySortInput;
  pagination: {
    pageSizeOption: string[];
    showSizeChanger: boolean;
    current: number;
    pageSize: number;
    total: number;
  };
  handleTableChange: (desiredPagination: AntdTablePagination) => void;
  loading: boolean;
  error?: ApolloError;
  rowSelection: any;
};

const KeyListTable = (props: KeyListTableProps) => {
  const { sortBy } = props;
  const { sort, onReprintClick } = props;
  const { locationId } = useParams() as { locationId: string };
  const screen = useBreakpoint();
  const roleAndPermission = useRoleAndPermission();

  const DEFAULT_COLUMN_CONFIG = getDefaultColumnKeyListTable(props, locationId);

  const COLUMN_CONFIG = [
    {
      title: <span data-testid="key-list-status-column-title">Status</span>,
      render: (record: Key, _: any, index: number) => {
        const keyWithNodeHealth = record;
        if (!keyWithNodeHealth?.keyStats) return tableRenderLoadingRow(index, 10);
        const uiConfig: string[] =
          MAPPING_STATUS_UI_CONFIG[keyWithNodeHealth?.keyStats?.mappingStatus] ??
          MAPPING_STATUS_UI_CONFIG[MappingStatus.NotStarted];
        return <Tag color={uiConfig[1]}>{uiConfig[0]}</Tag>;
      },
      onHeaderCell: () => ({
        onClick: () => {
          sortBy("mappingStatus");
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort.mappingStatus),
      isVisible: true,
      isShowOnMobile: false,
    },
    ...DEFAULT_COLUMN_CONFIG,
    {
      title: "Room",
      render: (record: Key, _: any, index: number) => {
        const keyWithNodeHealth = record;
        if (!keyWithNodeHealth?.keyStats) return tableRenderLoadingRow(index, 10);
        const mappedCount = keyWithNodeHealth?.keyStats?.roomsStats?.mappedCount || 0;
        const totalCount = keyWithNodeHealth?.keyStats?.roomsStats?.totalCount || 0;
        return `${mappedCount}/${totalCount}`;
      },
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: "Group",
      render: (record: any) => {
        return record.group?.groupName ?? "--";
      },
      onHeaderCell: () => ({
        onClick: () => {
          sortBy("groupName");
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort.groupName),
      isVisible: roleAndPermission.isPC(),
    },
    {
      title: "Nodes",
      render: (record: Key, _: any, index: number) => {
        const keyWithNodeHealth = record as KeysWithNodeHealth;
        if (!keyWithNodeHealth?.keyStats) return tableRenderLoadingRow(index, 10);
        const mappedCount = keyWithNodeHealth?.keyStats?.nodesStats?.mappedCount || 0;
        const totalCount = keyWithNodeHealth?.keyStats?.nodesStats?.totalCount || 0;
        const percentage = totalCount === 0 ? 0 : (100 * mappedCount) / totalCount;

        return (
          <Progress
            percent={percentage}
            size="small"
            style={{ width: "80%" }}
            format={() => `${mappedCount}/${totalCount}`}
            status="normal"
          />
        );
      },
      isVisible: true,
      isShowOnMobile: false,
    },
    {
      title: "Actions",
      width: 125,
      render: (record: Key) => <Link to={`/locations/${locationId}/keys/${record.keyId}`}>Edit Mappings</Link>,
      isVisible: !roleAndPermission.canPerform(Permission.LOCATION_CREATE) || roleAndPermission.isPC(),
      isShowOnMobile: false,
    },
    {
      title: "Installation mode",
      render: (record: Key) => (
        <InstallationModeButton
          keyPositionId={record.keyId}
          currentPositionFunction={PositionFunction.AUTOSET}
          redirectUrl={`/locations/${locationId}/keys/${record.keyId}/${ActiveTab.KEYDETAILS}`}
          activeModal
          showStopWatch
          automaticTestRun
        />
      ),
      isVisible: !roleAndPermission.canPerform(Permission.LOCATION_CREATE) || roleAndPermission.isPC(),
      isShowOnMobile: false,
    },
    {
      title: "Key Health",
      width: 125,
      render: (record: KeysWithNodeHealth, _: any, index: number) => {
        if (!record?.keyStats) return tableRenderLoadingRow(index, 10);
        const nodeHealthStats = record?.keyStats?.nodeHealthStats;

        return (
          <ObjectHealth
            online={nodeHealthStats?.onlineCount ?? 0}
            offline={nodeHealthStats?.offlineCount ?? 0}
            booted={nodeHealthStats?.bootedCount ?? 0}
          />
        );
      },
      isVisible: roleAndPermission.isInstaller() || roleAndPermission.isPC(),
      isShowOnMobile: false,
    },
    {
      render: (record: Key, _: any, index: number) => {
        const keyWithNodeHealth = record as KeysWithNodeHealth;
        if (!keyWithNodeHealth?.keyStats) return tableRenderLoadingRow(index, 10);
        return (
          keyWithNodeHealth?.keyStats?.mappingStatus === MappingStatus.Completed && (
            <Button type="link" icon={<PrinterOutlined />} onClick={() => onReprintClick(record.keyId)}>
              Reprint
            </Button>
          )
        );
      },
      isVisible: !roleAndPermission.canPerform(Permission.LOCATION_CREATE),
      isShowOnMobile: false,
    },
    {
      render: (record: KeysWithNodeHealth) => {
        const nodeHealthStats = record?.keyStats?.nodeHealthStats;
        return (
          <>
            <Link to={`/locations/${locationId}/keys/${record.keyId}`} className="text-black">
              <div className="d-flex flex-row flex-wrap">
                <div className="w-25">
                  <h3>{record.keyName}</h3>
                </div>
                <div className="w-75">
                  <div className="mb-m">
                    <div className="category">
                      <span className="d-block fs-sm font-weight-bold">CATEGORY</span>
                      <span className="d-block fs-sm">{record.categoryName}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-m">
                    <div>
                      <span className="d-block fs-sm font-weight-bold">ROOMS</span>
                      <span className="d-block fs-sm">{`${record?.keyStats?.roomsStats?.mappedCount || 0}/${
                        record?.keyStats?.roomsStats?.totalCount || 0
                      }`}</span>
                    </div>
                    <div>
                      <span className="d-block fs-sm font-weight-bold">NODES</span>
                      <span className="d-block fs-sm">{`${record?.keyStats?.nodesStats?.mappedCount || 0}/${
                        record?.keyStats?.nodesStats?.totalCount || 0
                      }`}</span>
                    </div>
                    <div>
                      <span className="d-block fs-sm font-weight-bold">KEY HEALTH</span>
                      <ObjectHealth
                        online={nodeHealthStats?.onlineCount ?? 0}
                        offline={nodeHealthStats?.offlineCount ?? 0}
                        booted={nodeHealthStats?.bootedCount ?? 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div className="w-75 ml-auto installation-mode d-flex justify-content-between align-items-center">
              <span className="d-block fs-sm font-weight-bold">INSTALLATION MODE</span>
              <span className="d-block fs-sm">
                <InstallationModeButton
                  keyPositionId={record.keyId}
                  currentPositionFunction={PositionFunction.AUTOSET}
                  redirectUrl={`/locations/${locationId}/keys/${record.keyId}/${ActiveTab.KEYDETAILS}`}
                  activeModal
                  showStopWatch
                  automaticTestRun
                />
              </span>
            </div>
          </>
        );
      },
      isVisible: true,
      isShowOnMobile: true,
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  return (
    <Table {...props} columns={columns} mobileView={screen.mobileAndTabletOnly} dataItemName="keys" rowKey="keyId" />
  );
};

export default KeyListTable;
