import { AntdTablePagination } from "components/Table/Table.d";

export const getPaginationMessage = (pagination: AntdTablePagination, position = "locations") => {
  const { current, pageSize, total } = pagination;
  const start = (current - 1) * pageSize + 1;
  const end = Math.min(total, start + pageSize - 1);

  return `Showing ${start}-${end} of ${total} ${position}`;
};

export default getPaginationMessage;
