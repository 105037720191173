import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button, Col, Modal, Row } from "antd";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import "./GatewayCreationHeader.scss";
import { CloseOutlined } from "@ant-design/icons";
import useBreakpoint from "hooks/use-breakpoint";
import classnames from "classnames";

type GatewayCreationHeaderProps = {
  step: number;
};

const GatewayCreationHeader = ({ step = 1 }: GatewayCreationHeaderProps) => {
  const history = useHistory();
  const { locationId } = useParams<{ locationId: string }>();

  const screen = useBreakpoint();

  const exitCreationMode = () => {
    Modal.confirm({
      title: "You have unsaved changes.",
      content: "Exiting the Creation Wizard now will delete all changes you have made. Are you sure you wish to exit?",
      className: "confirm-modal",
      okText: "Continue Editing",
      cancelText: "Exit Without Saving",
      onCancel: () => {
        history.push(`/locations/${locationId}/gateways`);
      },
      okButtonProps: {
        className: classnames({
          "button-mobile": screen.mobileAndTabletOnly,
        }),
      },
      cancelButtonProps: {
        className: classnames({
          "button-mobile": screen.mobileAndTabletOnly,
        }),
      },
    });
  };

  const steps = ["Add WiFi Credentials", "Name Gateways"];
  const currentStep = steps[step - 1];

  return (
    <>
      <h1>Gateway Creation</h1>
      <Row
        className={classnames({
          "breadscrumb-row-wrapper": screen.desktopUp,
          "mt-m": screen.mobileAndTabletOnly,
          "mb-m": screen.mobileAndTabletOnly,
        })}
      >
        <Col span={16}>
          <Breadcrumb steps={steps} currentStep={currentStep} />
        </Col>
        <Col offset={4} span={4} className="exit-creation-mode">
          {screen.desktopUp ? (
            <Button onClick={exitCreationMode}>Exit Creation Mode</Button>
          ) : (
            <Button type="text" icon={<CloseOutlined />} onClick={exitCreationMode} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default GatewayCreationHeader;
