import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

const KEY_ENTRY_PRE_NOTIFY_TIME_MINUTES = process.env.REACT_APP_REACT_KEY_ENTRY_PRE_NOTIFY_TIME_MINUTES || "5";

const PreAutoExitModal = ({
  expiredAt,
  keyName,
  onExit = () => {},
  onExtendEntry,
  isLoading,
}: {
  keyName: string;
  expiredAt: string;
  onExit?: () => void;
  onExtendEntry?: () => void;
  isLoading?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: new Date(expiredAt),
    autoStart: true,
    onExpire: () => setIsOpen(false),
  });

  useEffect(() => {
    if (expiredAt) {
      restart(new Date(expiredAt), true);
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [expiredAt]);

  return (
    <Modal
      className="select-test-run-modal"
      title="Installation Mode Expired Warning"
      centered
      width={450}
      okText="Confirm"
      visible={!!seconds && minutes >= 0 && minutes < parseInt(KEY_ENTRY_PRE_NOTIFY_TIME_MINUTES) && isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <Row justify="center">
        <Col span={24}>
          <p>
            Key <b>{keyName}</b> will be expired in next {KEY_ENTRY_PRE_NOTIFY_TIME_MINUTES} minutes
          </p>
          <br />
        </Col>
        <Col span={24}>
          <Button
            disabled={isLoading}
            type="primary"
            className="ant-btn ant-btn-primary modal-button"
            onClick={onExtendEntry}
          >
            Extend entry
          </Button>
        </Col>
        <Col span={24}>
          <Button disabled={isLoading} type="default" className="modal-button button-no" onClick={() => onExit()}>
            Exit key
          </Button>
        </Col>
        <Col span={24}>
          <Button
            disabled={isLoading}
            type="default"
            className="modal-button button-no"
            onClick={() => setIsOpen(false)}
          >
            Ignore
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default PreAutoExitModal;
