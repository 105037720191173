import React from "react";
import { Modal } from "antd";
import { ConfirmOverrideSmartREMConfigModalProps } from "./ConfirmOverrideSmartREMConfigModal.d";

const OverrideSmartREMValidationConfirmModal: React.FC<ConfirmOverrideSmartREMConfigModalProps> = ({
  loading,
  onConfirm,
  closeModal,
  modalWrapperProps,
}) => {
  return (
    <Modal
      title="Override ongoing SmartREM Validation Configuration"
      centered
      onOk={async () => {
        await onConfirm();
        closeModal();
      }}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      width={400}
      okText="Confirm"
      {...modalWrapperProps}
      onCancel={() => {
        closeModal();
      }}
    >
      Please confirm to override ongoing SmartREM validation configuration. This will end the current period and start a
      new one.
    </Modal>
  );
};

export default OverrideSmartREMValidationConfirmModal;
