import React from "react";

import { useLocationNodeHealthQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import OverallStatusSidebarSection from "../OverallStatusSidebarSection/OverallStatusSidebarSection";
import { SidebarStatusItemData } from "../SidebarStatusItem/SidebarStatusItem.d";
import { getNodeTypeDisplayValue } from "../../../../utils/nodeTypeDisplay";

const OverallNodeHealthSidebar = ({ locationId }: { locationId: string }) => {
  const { data, loading, error } = useLocationNodeHealthQuery({
    variables: {
      locationId,
    },
  });

  const nodeHealthStats = data?.location?.locationStats.nodeHealthStats;

  let keyStatusItems: SidebarStatusItemData[] = [];

  if (nodeHealthStats) {
    keyStatusItems = [
      {
        name: "Nodes",
        total: nodeHealthStats?.totalCount || 0,
        current: nodeHealthStats?.onlineCount || 0,
        missing: nodeHealthStats?.offlineCount || 0,
        booted: nodeHealthStats?.bootedCount || 0,
      },
    ];
  }

  const nodeOverallStats = data?.location?.locationStats.nodeHealthOverallStats || [];

  const nodeOverallStatusItems: SidebarStatusItemData[] = nodeOverallStats.map(
    (i) =>
      ({
        name: getNodeTypeDisplayValue(i.nodeType),
        total: i.nodeHealthStats?.totalCount,
        current: i.nodeHealthStats?.onlineCount,
        missing: i.nodeHealthStats?.offlineCount,
        booted: i.nodeHealthStats?.bootedCount,
      } as SidebarStatusItemData)
  );

  return (
    <>
      <OverallStatusSidebarSection
        title="Location Health"
        sidebarStatusItems={keyStatusItems}
        showAsHealthStatus
        loading={loading}
        error={error}
      />
      <OverallStatusSidebarSection
        title="Node Health"
        sidebarStatusItems={nodeOverallStatusItems}
        showAsHealthStatus
        loading={loading}
        error={error}
      />
    </>
  );
};

export default OverallNodeHealthSidebar;
