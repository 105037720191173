import { useEffect, useState } from "react";
import { NodeIdsQueryVariables, useNodeIdsQuery } from "../../../../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../../../errorHandler";
import { Node } from "../OtaNodeList.d";

const useNodeSelection = () => {
  const [selectedNodeSet, setSelectedNodeSet] = useState<Set<string>>(new Set());
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectionStatsMessage, setSelectionStatsMessage] = useState<string>("");
  const [unselectedNodeSet, setUnselectedNodeSet] = useState<Set<string>>(new Set());
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    const count = isSelectAll ? totalCount - unselectedNodeSet.size : selectedNodeSet.size;
    setSelectionStatsMessage(`Selected ${count} nodes`);
  }, [selectedNodeSet.size, unselectedNodeSet.size, isSelectAll, totalCount]);

  const { refetch: nodeIdsQuery } = useNodeIdsQuery({
    onError: errorHandler.handleError,
    skip: true,
  });

  const resetSelection = () => {
    setSelectedNodeSet(new Set());
    setIsSelectAll(false);
  };

  const selectNode = (node: string) => {
    selectedNodeSet.add(node);
    unselectedNodeSet.delete(node);
  };

  const deselectNode = (node: string) => {
    selectedNodeSet.delete(node);
    unselectedNodeSet.add(node);
  };

  const handleNodeSelected = (nodes: Node[], records: Node[], selected: boolean) => {
    records.forEach((record) => {
      if (selected) {
        selectNode(record.nodeMacId);
      } else {
        deselectNode(record.nodeMacId);
      }
    });

    // Rebind all collections
    setUnselectedNodeSet(new Set(unselectedNodeSet));

    setSelectedNodeSet(new Set(selectedNodeSet));
  };

  const onSourceBinding = (nodes: Node[]) => {
    if (!isSelectAll) return;
    nodes.forEach((k) => {
      if (unselectedNodeSet.has(k.nodeMacId)) return;
      selectNode(k.nodeMacId);
    });
  };

  const onSelectAll = (selected: boolean, nodes: Node[]) => {
    setIsSelectAll(selected);
    setUnselectedNodeSet(new Set());

    if (selected) {
      handleNodeSelected(nodes, nodes, selected);
    } else {
      resetSelection();
    }
  };

  const onTotalCountChange = (count: number) => {
    setTotalCount(count);
  };

  const getSelectedNodeIds = async (variables: NodeIdsQueryVariables) => {
    if (!isSelectAll) return Array.from(selectedNodeSet);

    const { data } = await nodeIdsQuery(variables);
    if (data) {
      return data.nodeIds.map((p) => p.nodeMacId);
    }
    return [];
  };

  return {
    resetSelection,
    selectionStatsMessage,
    onSourceBinding,
    onSelectAll,
    handleNodeSelected,
    onTotalCountChange,
    selectedNodeSet,
    getSelectedNodeIds,
    isSelectAll,
  };
};

export default useNodeSelection;
