/* eslint-disable no-param-reassign */
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Breadcrumb, Col, Collapse, Empty, Row, Tooltip } from "antd";
import clsx from "classnames";
import GetHelpButton from "components/GetHelpButton";
import { InstallationModeButton } from "components/InstallationMode";
import StopWatch from "components/InstallationMode/StopWatch";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import errorHandler from "errorHandler";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import isNil from "lodash/isNil";
import {
  KeyQuery,
  useInfrastructureEmonPowerStatusSubscription,
  useGetPositionNameLazyQuery,
  useInfrastructuresLazyQuery,
  useKeyEntryDetailSubscription,
  useKeyLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PositionFunction, PositionName, PowerMeasurements, TestGroup, TestRun, TestRunStatus } from "./Checklist.d";
import ChecklistDetail from "./ChecklistDetail";

const StatusIcon = (props: { status: string }): React.ReactElement => {
  const { status } = props;
  if (status === TestRunStatus.PASSED) {
    return (
      <Tooltip title="Passed">
        <CheckCircleFilled className="fs-xl text-green" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Failed">
      <CloseCircleFilled className="fs-xl text-danger" />
    </Tooltip>
  );
};

const powerMeasurementsInitial = {
  1: {
    lastPowerReading: null,
  },
  2: {
    lastPowerReading: null,
  },
  3: {
    lastPowerReading: null,
  },
};

const Checklist = (props: { keyPositionId: string; positionFunction: PositionFunction } & Record<string, any>) => {
  const { keyPositionId, positionFunction } = props;
  const { Panel } = Collapse;
  const [groups, setGroups] = useState<TestGroup[]>([]);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [positions, setPositions] = useState<PositionName[]>();
  const [positionName, setPositionName] = useState<string>();
  const [powerMeasurements, setPowerMeasurements] =
    useState<Record<number, PowerMeasurements>>(powerMeasurementsInitial);
  const roleAndPermission = useRoleAndPermission();
  const { currentPositionId, currentKeyEntryOfInstaller } = useContext(InstallationModeContext);

  const { locationId, locationName } = useSensorflowLocation();

  const [infrastructuresQuery] = useInfrastructuresLazyQuery({
    variables: {
      where: {
        id: { _eq: keyPositionId },
      },
      offset: 0,
      limit: 1,
    },
    onCompleted: (data) => {
      const infra = data.infrastructures[0];
      if (infra && infra.name) setPositionName(infra.name);
    },
  });

  const [keyQuery] = useKeyLazyQuery({
    variables: {
      keyId: keyPositionId,
    },
    onCompleted: ({ key }: KeyQuery) => {
      setPositionName(key.keyName);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const [getPositions] = useGetPositionNameLazyQuery({
    variables: {
      keyId: keyPositionId,
    },
    onCompleted: (data) => {
      setPositions(data.positions);
    },
  });

  useEffect(() => {
    if (positionFunction === PositionFunction.COMPRESSOR) {
      infrastructuresQuery();
    } else {
      keyQuery();
      getPositions();
    }
  }, [keyQuery, infrastructuresQuery, getPositions, keyPositionId, positionFunction]);

  const bindingData = (testRuns: TestRun[]) => {
    const groupData: TestGroup[] = [];

    testRuns.forEach((testRun) => {
      let isNewGroup = true;

      groupData.forEach((group) => {
        if (group.groupId === testRun.positionId) {
          isNewGroup = false;
          group.testRuns.push(testRun);
          if (testRun.status === TestRunStatus.FAILED && group.status !== TestRunStatus.PENDING) {
            group.status = TestRunStatus.FAILED;
          } else if (testRun.status === TestRunStatus.PENDING) {
            group.status = TestRunStatus.PENDING;
          }
        }
      });
      if (isNewGroup) {
        const newGroup: TestGroup = {
          groupId: testRun.positionId,
          status: testRun.status,
          testRuns: [testRun],
        };
        groupData.push(newGroup);
      }
    });
    setGroups(groupData);
  };

  const groupStatus = (group: TestGroup) => {
    let groupName: string = "";
    if (positionFunction === PositionFunction.AUTOSET) {
      const position = positions?.filter((pos) => pos.positionId === group.groupId)[0];
      groupName = position ? position.positionName : "";
    } else if (positionName) {
      groupName = positionName;
    }

    return (
      <div className="align-items-center d-flex">
        <span className="font-weight-semi-bold d-inline-block align-content-start">
          {groupName}
          {isRunning ? " (running)" : ""}
        </span>
        <div className="align-items-center blocks mr-s ml-auto">
          <StatusIcon status={group.status} />
        </div>
      </div>
    );
  };

  useKeyEntryDetailSubscription({
    variables: {
      where: {
        keyPositionId: {
          _eq: keyPositionId,
        },
        positionFunction: {
          _eq: positionFunction,
        },
        startedAt: {
          _is_null: false,
        },
      },
    },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data) {
        const keyEntry = data.subscriptionData.data.keyEntries[0];
        if (keyEntry) {
          setIsRunning(!keyEntry.endedAt);

          const testRuns = keyEntry.testRuns.map((testRun) => {
            const test: TestRun = {
              name: testRun.test.name,
              description: testRun.test?.description,
              troubleshootingLink: testRun.test?.troubleshootingLink,
              testId: testRun.test.testId,
              isManual: testRun.test.isManual,
              status: testRun.status,
              lastPassedAt: testRun?.lastPassedAt,
              lastTestedAt: testRun?.lastTestedAt,
              testRunId: testRun.testRunId,
              positionId: testRun.autosetPositionId,
              note: testRun.note,
              details: testRun.details,
              isIgnored: testRun.isIgnored,
            };
            return test;
          });

          bindingData(testRuns);
        }
      }
    },
  });

  useInfrastructureEmonPowerStatusSubscription({
    variables: {
      id: keyPositionId,
    },
    onSubscriptionData(options) {
      const { infrastructureEmonPowerStatus = [] } = options.subscriptionData.data || {};

      setPowerMeasurements((measurements) => {
        const data = { ...measurements };
        infrastructureEmonPowerStatus.forEach((status) => {
          if (!isNil(status.lastPowerReading) && status.channel) {
            data[status.channel] = status;
          }
        });
        return data;
      });
    },
  });

  const checkListHelpText = useMemo(() => {
    if (locationName && positionName) {
      return `${locationName}: ${positionName} ${window.location.href}`;
    }
    return undefined;
  }, [locationName, positionName]);

  return (
    <>
      {roleAndPermission.isContractor() ? null : (
        <Breadcrumb className="header-bread-crumb">
          <Breadcrumb.Item>
            <Link to="/locations">Location</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/locations/${locationId}`}>{locationName}</Link>
          </Breadcrumb.Item>
          {positionFunction === PositionFunction.COMPRESSOR && (
            <Breadcrumb.Item>
              <Link to={`/locations/${locationId}/infrastructures`}>infrastructure</Link>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item>{positionName}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <>
        <Row className="mb-l" align="middle" gutter={[4, 20]}>
          <Col flex="auto">
            <Row justify="start" wrap={false} align="middle">
              <Col flex="none">
                <h1 className="lh-s">{positionName}</h1>
              </Col>
              <Col>
                {currentPositionId === keyPositionId && currentKeyEntryOfInstaller && (
                  <div>
                    <StopWatch startTime={currentKeyEntryOfInstaller.startedAt} />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col flex="none">
            <Row>
              <Col flex="none" className="mr-s">
                <InstallationModeButton
                  keyPositionId={keyPositionId}
                  currentPositionFunction={positionFunction}
                  buttonName={{ enter: "Start work", exit: "End Work" }}
                  activeModal
                  automaticTestRun
                />
              </Col>
              <Col flex="none">
                <GetHelpButton helpText={checkListHelpText} />
              </Col>
            </Row>
          </Col>
        </Row>
        {positionFunction !== PositionFunction.AUTOSET && (
          <div className="power-measurements mb-l">
            <h4 className="power-measurements-title">POWER MEASUREMENTS</h4>
            <div className="power-measurements-content">
              <Row>
                {Object.entries(powerMeasurements).map(([channel, powerData]) => (
                  <Col
                    key={channel}
                    span={8}
                    className={clsx({
                      "text-error": powerData.isMyChannel,
                    })}
                  >
                    <p>
                      <strong>Channel {channel}</strong>
                    </p>
                    <span>
                      {isNil(powerData.lastPowerReading) ? "-" : (powerData.lastPowerReading / 230).toFixed(2)}
                    </span>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
      </>

      <div className="mb-l">
        <Row>
          <Col span={24}>
            {groups.length > 0 ? (
              <Collapse>
                {groups.map((group) => (
                  <Panel header={groupStatus(group)} key={group.groupId} className="no-padding-collapsible-tab">
                    <ChecklistDetail group={group} isRunning={isRunning} />
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <Empty description="No Test Run" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Checklist;
