import React from "react";
import styles from "./styles.module.scss";

const Loading = (props: { label?: string; className?: string }) => {
  const { label, className } = props;
  return (
    <div
      data-testid="loading-testId"
      aria-label={label}
      className={`${styles.loadingContainer} d-flex justify-content-center align-items-center w-100 ${className}`}
    >
      <div className={styles.ldsDefault}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loading;
