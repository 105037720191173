import useLocationDateTime from "hooks/use-location-datetime";
import { get, isNil } from "lodash";
import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { CsvHeaderConfig } from "types/types";
import { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import { RawNodeToSlotMapping, Node } from "./NodeList.d";

export const transformNodes = (rawNodeData: RawNodeToSlotMapping[]): Node[] => {
  return rawNodeData.map(
    (nodeMapping) =>
      ({
        nodeMacId: nodeMapping.nodeMacId,
        nodeType: nodeMapping.nodeType,
        status: get(nodeMapping, "node.nodeOnlineStatus.nodeStatus", "").toLowerCase(),
        lastSeen: get(nodeMapping, "node.nodeJoinStatus.lastSeen"),
        key: get(nodeMapping, "position.parentPosition"),
        room: nodeMapping.position,
        associatedGateway: get(nodeMapping, "node.gateway"),
        signalStrength: get(nodeMapping, "node.nodeJoinStatus.signalStrength"),
        nodeJoin: get(nodeMapping, "node.nodeJoinStatus.recentJoinCount"),
        bootTime: get(nodeMapping, "node.nodeJoinStatus.bootTime"),
        mappedTime: nodeMapping.mappedTime,
        firmwareVersion: get(nodeMapping, "node.nodeJoinStatus.firmwareVersion"),
        nodeSubType: get(nodeMapping, "node.nodeSubType"),
      } as Node)
  );
};

export const transformNodeToCsvData = (
  nodes: Node[],
  {
    headers,
  }: {
    headers: CsvHeaderConfig<Node>[];
  }
) => {
  return nodes.map((n) => {
    return Object.fromEntries(
      headers.map((headerCfg) => [
        headerCfg.key,
        headerCfg.transform ? headerCfg.transform(n) : (n as any)[headerCfg.key],
      ])
    );
  });
};

type GetNodeListCsvHeaderConfigParam = ReturnType<typeof useLocationDateTime>;

export const getNodeListCsvHeader = ({ formatDateTime }: GetNodeListCsvHeaderConfigParam): CsvHeaderConfig<Node>[] => {
  return [
    {
      label: "Node ID",
      key: "nodeMacId",
    },
    {
      label: "Node Type",
      key: "nodeType",
      transform: (n: Node) => getNodeSubTypeDisplayName(n.nodeType, n.nodeSubType),
    },
    {
      label: "Last Seen",
      key: "lastSeen",
      transform: (n: Node) => (n.lastSeen ? formatDateTime(n.lastSeen) || "Never seen" : "Never Seen"),
    },
    {
      label: "Key",
      key: "key",
      transform: ({ nodeType, key }: Node) => {
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return key?.positionName || "";
      },
    },
    {
      label: "Room",
      key: "room",
      transform: ({ nodeType, room }: Node) => {
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return room?.positionName || "";
      },
    },
    {
      label: "Associated Gateway",
      key: "gateway",
      transform: (n: Node) => n.associatedGateway?.gatewayName || "-",
    },
    {
      label: "Signal strength",
      key: "signalStrength",
      transform: ({ signalStrength }: Node) => (!isNil(signalStrength) ? signalStrength.toFixed(2) : "-"),
    },
    {
      label: "Node Join",
      key: "nodeJoin",
    },
    {
      label: "Boot Time",
      key: "bootTime",
      transform: ({ bootTime }: Node) => formatDateTime(bootTime || "") || "-",
    },
    {
      label: "Mapped On",
      key: "mappedTime",
      transform: (n: Node) => formatDateTime(n.mappedTime) || "-",
    },
    {
      label: "FW Version",
      key: "fwVersion",
      transform: (n: Node) => n.firmwareVersion?.toString() || "-",
    },
  ];
};
