import React from "react";

import { GatewayHealthStatus } from "pacts/app-webcore/hasura-webcore.graphql";
import "./GatewayName.scss";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Permission } from "pacts/permission";
import Can from "components/Can/Can";
import { GATEWAY_ERROR_DISPLAY } from "../GatewayList/GatewayList.d";

interface GatewayNameProps {
  name: string;
  status: GatewayHealthStatus;
  gatewayId: string;
  allowDelete?: boolean;
  showDeleteModal: (gatewayId: string, gatewayName: string, healthStatus: GatewayHealthStatus) => void;
  showRenameModal: (gatewayId: string, gatewayName: string) => void;
}

const GatewayName = ({ name, status, gatewayId, allowDelete, showRenameModal, showDeleteModal }: GatewayNameProps) => {
  return (
    <>
      <div className="gateway-name-container">
        <p className="gateway-name">{name}</p>
        <Can
          requiredPermission={Permission.GATEWAY_UPDATE}
          yes={<Button type="link" icon={<EditOutlined />} onClick={() => showRenameModal(gatewayId, name)} />}
        />

        {allowDelete && (
          <Can
            requiredPermission={Permission.GATEWAY_DELETE}
            yes={
              <Button type="link" icon={<DeleteOutlined />} onClick={() => showDeleteModal(gatewayId, name, status)} />
            }
          />
        )}
      </div>
      {(status === GatewayHealthStatus.Error || status === GatewayHealthStatus.LoraError) && (
        <span className="gateway-error">{GATEWAY_ERROR_DISPLAY[status]}</span>
      )}
    </>
  );
};

export default GatewayName;
