import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { usePrevious } from "react-use";
import { Button } from "antd";
import useScanCamera from "components/ScanCamera/ScanCamera";
import { NodeMappingError } from "components/NodeMapping/NodeMappingError.d";
import "./NodeMapping.css";
import { useValidateNodeBeforeMappingToSlotLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import NodeScanningComponent from "./NodeScanning";

const INVALID_NODE_ID_MSG = "Node ID should begin with valid node type identifier. Scan or type Node ID again to retry";
const IS_INSTALLED_NODE_MSG = "Node is already mapped! Please choose different node.";
const NODE_MAC_ID_LENGTH = 16;
const FULL_NODE_MAC_ID_LENGTH = 18;

const errorMessage = {
  [NodeMappingError.NodeAlreadyMappedError]: IS_INSTALLED_NODE_MSG,
  [NodeMappingError.ServerError]: INVALID_NODE_ID_MSG,
};

export const isValidNodeMacId = (macId: string) => {
  const regexp = /FFFF[a-f0-9]{12}$/i;
  return !!regexp.exec(macId);
};

const messageNodeMappedError = (
  {
    keyId,
    keyName,
    locationId,
    locationName,
    roomName,
  }: {
    keyId: string;
    keyName: string;
    locationId: string;
    locationName: string;
    roomName: string;
    slotName: string;
  },
  currentLocation?: string
) => {
  return (
    <>
      Node is already mapped in room: {roomName}, in key:{" "}
      <Link to={`/locations/${locationId}/keys/${keyId}`}>{keyName}</Link>
      {currentLocation !== locationId && `, in location: ${locationName}`}! Please choose different node. different
      node.
    </>
  );
};

export interface NodeMappingComponentProps {
  roomId: any;
  isMapping?: boolean;
  nodeTypeCodeIdentifier: any;
  nodeSubTypeCode?: any;
  slotName: any;
  nodeMapSubmit: (currentNodeMacId: string) => Promise<void> | void;
  skipNode?: () => void;
  externalError: any;
  customHeader?: () => any;
  nodeType?: any;
  nodeSubType?: any;
}

const NodeMappingComponent: React.FC<NodeMappingComponentProps> = (props) => {
  const {
    roomId,
    isMapping,
    nodeTypeCodeIdentifier,
    nodeSubTypeCode,
    slotName,
    nodeMapSubmit,
    skipNode,
    externalError,
    customHeader,
    nodeType,
    nodeSubType,
  } = props;
  const { locationId } = useParams<{ locationId: string }>();

  const mappingInput: any = useRef(null);
  const [internalError, setInternalError] = useState<any>();
  const [input, setInput] = useState<string>("");
  const prevRoomId = usePrevious(roomId);
  const prevIdentifier = usePrevious(nodeTypeCodeIdentifier);
  const prevNodeSubTypeCode = usePrevious(nodeSubTypeCode);
  const prevSlotName = usePrevious(slotName);
  const [isNewNode, setIsNewNode] = useState<boolean>(false);
  const [isInstalledNode, setIsInstalledNode] = useState<boolean>(true);
  const { videoDeviceId, videoDevices, onCameraDeviceChange } = useScanCamera();
  const [currentNodeMacId, setCurrentNodeMacId] = useState<string>();

  useEffect(() => {
    if (
      roomId !== prevRoomId ||
      nodeSubTypeCode !== prevNodeSubTypeCode ||
      slotName !== prevSlotName ||
      nodeTypeCodeIdentifier !== prevIdentifier
    ) {
      setInput("");
      setInternalError(null);
    }
  }, [
    roomId,
    nodeSubTypeCode,
    slotName,
    nodeTypeCodeIdentifier,
    prevRoomId,
    prevNodeSubTypeCode,
    prevSlotName,
    prevIdentifier,
  ]);

  useEffect(() => {
    if (mappingInput.current) {
      mappingInput.current.focus();
    }
  }, [mappingInput]);

  const [validation] = useValidateNodeBeforeMappingToSlotLazyQuery({
    onCompleted: () => {
      setInternalError(null);
      setIsInstalledNode(false);
      setIsNewNode(true);
    },
    onError: (error) => {
      const { graphQLErrors } = error;
      if (!graphQLErrors[0]?.extensions) return;
      if (graphQLErrors[0].extensions.code === NodeMappingError.NodeAlreadyMappedError) {
        setIsInstalledNode(true);
        setInternalError(messageNodeMappedError(graphQLErrors[0]?.extensions?.mappedNodeInSlot || {}, locationId));
      } else if (graphQLErrors[0].extensions.code === NodeMappingError.NodeNotJigTestedError) {
        setInternalError(graphQLErrors[0]?.message);
      } else
        setInternalError(
          errorMessage[graphQLErrors[0].extensions.code as keyof typeof errorMessage] ||
            errorMessage.INTERNAL_SERVER_ERROR
        );
    },
  });

  const submitNodeMapping = (value: any) => {
    if (isMapping) return;

    let nodeMacInput = value;
    if (nodeMacInput.length === NODE_MAC_ID_LENGTH) nodeMacInput = nodeTypeCodeIdentifier.slice(-2) + nodeMacInput;
    if (nodeMacInput.length > NODE_MAC_ID_LENGTH) nodeMacInput = nodeMacInput.padStart(FULL_NODE_MAC_ID_LENGTH, "0");
    if (nodeMacInput.length < FULL_NODE_MAC_ID_LENGTH) setInternalError(null);
    else {
      setCurrentNodeMacId(nodeMacInput);
      validation({
        variables: {
          nodeMacInput,
          nodeType,
          roomId,
          slotName,
          nodeSubType,
        },
      });
    }
  };

  useEffect(() => {
    if (isNewNode && !isInstalledNode) nodeMapSubmit(currentNodeMacId || "".toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNodeMacId, isNewNode, isInstalledNode]);

  const ExternalErrorComponentClass = externalError?.component;

  const defaultHeader = () => (
    <>
      <p className="current-room-text" data-testid="center-room-testId">
        {nodeSubTypeCode}
      </p>
      <p className="current-slot-name" data-testid="center-slotname-testId">
        {slotName}
      </p>
    </>
  );

  const handleInputChange = (e: any) => {
    const userInput = (e.target.value || "").toUpperCase();
    submitNodeMapping(userInput);
    setInput(userInput);
  };

  const handleQRCodeScan = (value: any) => {
    submitNodeMapping((value ?? "").toUpperCase());
  };

  const handleScanQRCodeError = () => {
    setInternalError("Error during scan QR Code.");
  };

  const onTabChanged = () => {
    setInternalError(null);
    setInput("");
  };

  return (
    <>
      {customHeader ? customHeader() : defaultHeader()}
      <div>
        <NodeScanningComponent
          internalError={internalError}
          handleInputChange={handleInputChange}
          input={input}
          mappingInput={mappingInput}
          onTabChanged={onTabChanged}
          externalError={externalError}
          ExternalErrorComponentClass={ExternalErrorComponentClass}
          videoDeviceId={videoDeviceId}
          handleScanQRCodeError={handleScanQRCodeError}
          handleQRCodeScan={handleQRCodeScan}
          videoDevices={videoDevices}
          onCameraDeviceChange={onCameraDeviceChange}
        />
        <div className="scan-action">
          <Button type="primary" id="previous-button">
            &lt; Back to previous Node
          </Button>
          {skipNode && (
            <Button onClick={() => skipNode()} id="skip-button" type="primary" danger>
              Skip Node
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default NodeMappingComponent;
