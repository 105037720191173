/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { MoreOutlined } from "@ant-design/icons";
import React from "react";
import { Dropdown } from "antd";

const MenuDropdown = (props: { menu: any; mode?: "vertical" | "horizontal" }) => {
  const { menu, mode } = props;
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined rotate={mode === "horizontal" ? 90 : 0} className="fs-xl" />
      </span>
    </Dropdown>
  );
};

export default MenuDropdown;
