import { Button } from "antd";
import React, { useState, useCallback } from "react";
import { EditIcon } from "components/Icons";
import { FormValue, GroupModalProps, GroupModal } from "../GroupModal";
import { ItemType } from "./GroupList.d";

type EditGroupButtonProps = {
  record: ItemType;
  handleEditGroup: (groupId: string, groupName: string, groupType: string) => Promise<void>;
};

const EditGroupButton = ({ record, handleEditGroup }: EditGroupButtonProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { positionId, positionName, positionType } = record;
  const formGroupValue: Partial<FormValue> = {
    name: positionName,
    type: positionType,
  };

  const handleOnSubmit: GroupModalProps["onSubmit"] = useCallback(
    async (value) => {
      await handleEditGroup(positionId, value.name, value.type);
      setOpenModal(false);
    },
    [handleEditGroup, positionId]
  );

  return (
    <>
      <Button
        className="px-s mr-s action-column"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <EditIcon />
      </Button>
      <GroupModal
        isUpdate
        onSubmit={handleOnSubmit}
        formValue={formGroupValue}
        onCancel={() => setOpenModal(false)}
        open={openModal}
      />
    </>
  );
};

export default EditGroupButton;
