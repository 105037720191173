import useSensorflowLocation from "./use-sensorflow-location";
import {
  formatDate,
  formatDateTime,
  formatDateTime24h,
  formatFromNow,
  formatTime12h,
  formatTime24h,
  formatTimezone,
  formatUnixDate,
  isOverdueDate,
} from "../utils/date";

const useLocationDateTime = () => {
  const { timezone } = useSensorflowLocation();

  return {
    formatDateTimeWithLocalTz: (date: any) => formatDateTime(date),
    formatDateTime: (date: any) => formatDateTime(date, timezone),
    formatUnixDate: (date: any) => formatUnixDate(date, timezone),
    formatFromNow: (date: any) => formatFromNow(date, timezone),
    formatDateTime24h: (date: any) => formatDateTime24h(date, timezone),
    formatTime24h: (date: any) => formatTime24h(date, timezone),
    formatTime12h: (date: any) => formatTime12h(date, timezone),
    formatDate: (date: any) => formatDate(date, timezone),
    formatTimezone: (date: any) => formatTimezone(date, timezone),
    isOverdueDate: (date: any) => isOverdueDate(date, timezone),
  };
};

export default useLocationDateTime;
