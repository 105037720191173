import { useState } from "react";
import { Location } from "../types";
import { useLocationWithKeysQuery } from "../../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../errorHandler";

const useLocationLoader = (locationId: string) => {
  const [location, setLocation] = useState<Location>();

  const { loading: locationLoading, error: locationError } = useLocationWithKeysQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data?.keysInLocation) {
        setLocation(data.keysInLocation);
      }
    },
    onError: errorHandler.handleError,
  });

  const getLocationStatusMessage = () => {
    if (locationLoading) return "Loading...";
    if (locationError) return "Error on loading location";
    return null;
  };

  return {
    location,
    getLocationStatusMessage,
  };
};

export default useLocationLoader;
