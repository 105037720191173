import React from "react";
import { get } from "lodash";
import nodeTypeDisplay from "utils/nodeTypeDisplay";
import MappingStatusIcon from "../MappingStatusIcon/MappingStatusIcon";
import "./CurrentRoomNodeSlotList.css";

const CurrentRoomInfo = ({ slots }: any) => {
  const renderNodes = () => {
    if (slots) {
      return slots.map(({ nodeSubType, mappingStatus, isCurrentRapidMappingSlot, slotName }: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <p className="node-code-text" data-testid="nodeCode-testid">
              {get(nodeTypeDisplay[nodeSubType], "code")}
              <MappingStatusIcon status={mappingStatus} isCurrentSlot={isCurrentRapidMappingSlot} />
            </p>
            <p className="slot-name-text" data-testid="slotName-testid">
              {slotName}
            </p>
          </React.Fragment>
        );
      });
    }
    return null;
  };

  return <div className="current-room-node-slots-list">{renderNodes()}</div>;
};

export default CurrentRoomInfo;
