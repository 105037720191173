import React from "react";
import { Tabs } from "antd";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { useHistory, useParams } from "react-router-dom";
import { PositionFunction } from "pages/Checklist/Checklist.d";
import useBreakpoint from "hooks/use-breakpoint";
import { KeyEntryJobChecklist } from "pages/KeyEntryJobChecklist";
import { GatewayJobChecklist } from "pages/GatewayJobChecklist";
import CustomerKeyDetails from "../CustomerKeyDetail/KeyDetails";
import KeyDetails from "../KeyDetail/KeyDetail";
import Checklist from "../../Checklist/Checklist";
import ContractorKeyDetails from "../ContractorKeyDetail/KeyDetails";

export enum ActiveTab {
  KEYDETAILS = "key-details",
  HOTEL_STAFF_VIEW = "hotel-staff-view",
  CHECK_LIST = "check-list",
  JOB_DETAILS = "job-details",
  GATEWAY_JOB_DETAILS = "gateway-job-details",
}

export default function KeyDetailWrapper() {
  const roleAndPermission = useRoleAndPermission();
  const history = useHistory();
  const { keyId } = useParams<{ keyId: string }>();
  const { locationId, activeTab } = useParams<{ locationId: string; activeTab: string }>();
  const screen = useBreakpoint();

  const handleTabChange = (activeKey: string) => {
    history.push(`/locations/${locationId}/keys/${keyId}/${activeKey}`);
  };

  if (roleAndPermission.isContractor()) {
    if (activeTab === ActiveTab.JOB_DETAILS && screen.mobileAndTabletOnly) {
      return <KeyEntryJobChecklist locationId={locationId} keyId={keyId} />;
    }
    if (activeTab === ActiveTab.GATEWAY_JOB_DETAILS && screen.mobileAndTabletOnly) {
      return <GatewayJobChecklist keyId={keyId} />;
    }
    return <ContractorKeyDetails />;
  }

  if (roleAndPermission.isInstaller() || roleAndPermission.isPC()) {
    return (
      <Tabs className="header-tab-bar" activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane tab="Key Details" key={ActiveTab.KEYDETAILS}>
          <KeyDetails />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Hotel Staff View" key={ActiveTab.HOTEL_STAFF_VIEW}>
          <CustomerKeyDetails />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Checklist" key={ActiveTab.CHECK_LIST}>
          <Checklist keyPositionId={keyId} positionFunction={PositionFunction.AUTOSET} />
        </Tabs.TabPane>
      </Tabs>
    );
  }

  if (roleAndPermission.isCustomer()) {
    return <CustomerKeyDetails />;
  }

  return <KeyDetails />;
}
