import { InfrastructuresQuery, CompressorEmonPowerStatusSubscription } from "pacts/app-webcore/hasura-webcore.graphql";

export enum TestRunStatus {
  PASSED = "PASSED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export type TestGroup = {
  groupId: string;
  status: string;
  testRuns: TestRun[];
};

export type TestRun = {
  name?: string;
  description?: string;
  troubleshootingLink?: string;
  testId: number;
  isManual: boolean;
  isIgnored: boolean;
  status: string;
  lastPassedAt?: string;
  lastTestedAt?: string;
  note: string | null | undefined;
  testRunId: number;
  positionId: string;
  details: string | null | undefined;
  startedAt: string;
};

export type FormTestNote = {
  note: string | null | undefined;
};

export enum PositionFunction {
  AUTOSET = "AUTOSET",
  COMPRESSOR = "COMPRESSOR",
  CHILLER = "CHILLER",
  PUMP = "PUMP",
  COOLING_TOWER = "COOLING_TOWER",
}

export type PositionName = {
  positionName: string;
  positionId: string;
};

export type Infrastructure = InfrastructuresQuery["infrastructures"][number];

export type PowerMeasurements = CompressorEmonPowerStatusSubscription["compressorEmonPowerStatus"][number];
