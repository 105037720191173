import React, { useState } from "react";

import { Modal } from "antd";
import { Closable } from "components/Modal/show";
import TextArea from "antd/lib/input/TextArea";

export type UpdateCommentModalProps = {
  onOk: (comment: string) => void;
};

const UpdateCommentModal = ({ onOk, close }: UpdateCommentModalProps & Closable) => {
  const [comment, setComment] = useState<string>();

  const handleSubmitAction = () => {
    if (comment) {
      onOk(comment);
      close();
    }
  };

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setComment(event.target.value);
  };

  return (
    <Modal
      title="Update comment"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="OK"
      okButtonProps={{
        disabled: !comment,
      }}
    >
      <p className="mb-m">Enter comments to proceed.</p>
      <TextArea onChange={handleOnChange} rows={4} />
    </Modal>
  );
};

export default UpdateCommentModal;
