import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, message, Modal } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import moment from "moment";
import { TimerResetConfigurationModal } from "pages/Key/CustomerKeyDetail/ResetConfigurationModal";
import {
  useBulkUpdateDefaultPositionConfigurationsMutation,
  useBulkUpdatePositionConfigurationsMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import showModal from "components/Modal/show";
import { validationMessages } from "utils/validation";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "components/Modal/ConfirmDeleteModal";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { EFeatureFlags, POSITION_CONFIGURATION_DEFAULT } from "utils/constants";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { AutomationMode, FormPositionConfiguration, OperationalMode } from "../../types";
import KeyConfigurationRoomForm from "../../CustomerKeyDetail/KeyConfiguration/KeyConfigurationRoomForm";
import { FormCheckedStatePositionConfiguration } from "../KeyConfigurationList.d";
import { getValueForSubmit } from "../KeyConfigurationList.helper";

export interface BulkConfigurationEditModalProps {
  showHeatingMode: boolean;
  selectedPositionIds: string[];
  onSuccess: () => void;
  choosesAutomationMode?: boolean;
  setRoomsWithThermostatOff?: (value) => void;
  disableSaveDefault?: boolean;
}

const BulkConfigurationEditModal = ({
  showHeatingMode,
  selectedPositionIds,
  modalWrapperProps,
  closeModal,
  onSuccess,
  setRoomsWithThermostatOff,
  choosesAutomationMode,
}: BulkConfigurationEditModalProps & ModalWrapperProps) => {
  const [hasKeySelected, setHasKeySelected] = useState<boolean>(!!choosesAutomationMode);
  const [form] = Form.useForm<FormPositionConfiguration & FormCheckedStatePositionConfiguration>();
  const [defaultConfigs] = useState<FormPositionConfiguration>(() => {
    return {
      automationModeChecked: !!choosesAutomationMode,
      automationMode: choosesAutomationMode ? AutomationMode.SmartSave : AutomationMode.SuperSave,
      hideSetpoint: POSITION_CONFIGURATION_DEFAULT.hideSetpoint,
      heatingHideSetpoint: POSITION_CONFIGURATION_DEFAULT.heatingHideSetpoint,
      minTemp: POSITION_CONFIGURATION_DEFAULT.minTemp,
      maxTemp: POSITION_CONFIGURATION_DEFAULT.maxTemp,
      heatingMinTemp: POSITION_CONFIGURATION_DEFAULT.heatingMinTemp,
      heatingMaxTemp: POSITION_CONFIGURATION_DEFAULT.heatingMaxTemp,
      automationHours: [
        moment(POSITION_CONFIGURATION_DEFAULT.acNightEnd, "HH:mm"),
        moment(POSITION_CONFIGURATION_DEFAULT.acNightStart, "HH:mm"),
      ],
      activeHours: [
        moment(POSITION_CONFIGURATION_DEFAULT.occNightEnd, "HH:mm"),
        moment(POSITION_CONFIGURATION_DEFAULT.occNightStart, "HH:mm"),
      ],
      occTimeoutDayMins: POSITION_CONFIGURATION_DEFAULT.occTimeoutDayMins,
      occTimeoutNightMins: POSITION_CONFIGURATION_DEFAULT.occTimeoutNightMins,
      doorSensorTimeoutMins: POSITION_CONFIGURATION_DEFAULT.doorSensorTimeoutMins,
      unoccupiedTemp: POSITION_CONFIGURATION_DEFAULT.unoccupiedTemp,
      unoccupiedTempChecked: !!choosesAutomationMode,
      heatingUnoccupiedTemp: POSITION_CONFIGURATION_DEFAULT.heatingUnoccupiedTemp,
      heatingUnoccupiedTempChecked: !!choosesAutomationMode,
      freezeProtectionTemp: POSITION_CONFIGURATION_DEFAULT.freezeProtectionTemp,
      actuationTimeoutSeconds: POSITION_CONFIGURATION_DEFAULT.actuationTimeoutSeconds,
      actingMode: POSITION_CONFIGURATION_DEFAULT.actingMode,
      operationalMode: OperationalMode.Cooling,
      expiredAt: null,
      enableDoorSensor: POSITION_CONFIGURATION_DEFAULT.enableDoorSensor,
      nightTimeSetpointEnabled: POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointEnabled,
      nightTimeSetpointMaxSetpoint: POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointMaxSetpoint,
      nightTimeSetpointOffset: POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointOffset,
      nightTimeSetpointTimeStartEnd: [
        moment(POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointStart, "HH:mm"),
        moment(POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointEnd, "HH:mm"),
      ],
    };
  });
  const [roomsAutomationDisabled, setRoomsAutomationDisabled] = useState<boolean>(false);
  const automationMode = Form.useWatch("automationMode", form);
  const automationModeChecked = Form.useWatch("automationModeChecked", form);

  const roleAndPermission = useRoleAndPermission();
  const { location } = useSensorflowLocation();

  const [bulkUpdatePositionConfigurationsMutation, { loading: updateLoading }] =
    useBulkUpdatePositionConfigurationsMutation({
      onCompleted: ({ bulkUpdatePositionConfigurations: data }) => {
        if (data) {
          const roomIds = data.filter((room) => room.thermostatOfflineStatus);
          setRoomsWithThermostatOff?.(roomIds);
        }
        message.success("Current Configuration Saved");
        closeModal();
        onSuccess();
      },
      onError: errorHandler.handleError,
    });

  const [bulkUpdateDefaultPositionConfigurationsMutation, { loading: updateDefaultLoading }] =
    useBulkUpdateDefaultPositionConfigurationsMutation({
      onCompleted: ({ bulkUpdateDefaultPositionConfigurations: data }) => {
        if (data) {
          const roomIds = data.filter((room) => room.thermostatOfflineStatus);
          setRoomsWithThermostatOff?.(roomIds);
        }
        message.success("New Default Configuration Saved");
        closeModal();
        onSuccess();
      },
      onError: errorHandler.handleError,
    });

  const clearInputs = () => {
    form.setFieldsValue(defaultConfigs);
  };

  const saveAsCurrentConfig = useCallback(
    async (formValue: FormPositionConfiguration & FormCheckedStatePositionConfiguration) => {
      await bulkUpdatePositionConfigurationsMutation({
        variables: {
          positionConfigurations: selectedPositionIds.map((p) => getValueForSubmit(p, formValue)),
        },
      });
    },
    [bulkUpdatePositionConfigurationsMutation, selectedPositionIds]
  );

  const saveAsNewDefaultConfig = useCallback(
    async (formValue: FormPositionConfiguration & FormCheckedStatePositionConfiguration) => {
      await bulkUpdateDefaultPositionConfigurationsMutation({
        variables: {
          positionConfigurations: selectedPositionIds.map((p) => getValueForSubmit(p, formValue)),
        },
      });
    },
    [bulkUpdateDefaultPositionConfigurationsMutation, selectedPositionIds]
  );

  const handleOnSaveNewDefaultConfig = () => {
    if (form.getFieldsError().some((item) => item.errors.length > 0)) return;
    const formValue = form.getFieldsValue();
    showModal<ConfirmDeleteModalProps>({
      element: ConfirmDeleteModal,
      config: {
        popupTitle: "Do you want to save new default for these rooms?",
        message: "This action will save the current settings in these rooms as their new defaults.",
        messageAfter: "At the bottom of the Bulk Configuration panel",
        fieldLabel: "Input the number of affected rooms to confirm",
        fieldPlaceholder: "Enter Number",
        confirmText: selectedPositionIds.length.toString(),
        onOk: async () => {
          message.info("Default configurations are saving...");
          await saveAsNewDefaultConfig(formValue);
          Modal.destroyAll();
        },
      },
    });
  };

  const handleSaveAsCurrentConfig = () => {
    if (form.getFieldsError().some((item) => item.errors.length > 0)) return;
    const formValue = form.getFieldsValue();
    if (!roleAndPermission.isInstaller()) {
      Modal.confirm({
        width: 450,
        title: "Do you want to save the new configuration?",
        cancelButtonProps: { hidden: true },
        okButtonProps: { hidden: true },
        content: (
          <TimerResetConfigurationModal
            onClose={Modal.destroyAll}
            onSaveDefault={handleOnSaveNewDefaultConfig}
            onSave={async (expiredAt) => {
              await saveAsCurrentConfig({ ...formValue, expiredAt });
              Modal.destroyAll();
            }}
            disableSaveDefault={roomsAutomationDisabled}
            disableSaveAsDefaultMaxResetTimeoutFlagEnabled={(location?.enabledFeatures ?? []).includes(
              EFeatureFlags.DisableSaveAsDefaultMaxResetTimeout
            )}
          />
        ),
      });
    } else {
      Modal.confirm({
        title: "Do you want to save as Current Configuration for these rooms?",
        content: "This action will save these configuration in these rooms as their Current Configuration.",
        className: "confirm-modal",
        okText: "Confirm",
        onOk: () => saveAsCurrentConfig(formValue),
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue(defaultConfigs);
  }, [defaultConfigs, form]);

  useEffect(() => {
    setRoomsAutomationDisabled(!!automationModeChecked && automationMode === AutomationMode.Disabled);
  }, [automationMode, automationModeChecked]);

  const isLoading = updateLoading || updateDefaultLoading;

  return (
    <Modal
      title="Bulk Edit Configuration"
      centered
      wrapClassName="vh-100-modal no-padding-modal footer-left-modal"
      width={450}
      footer={[
        <div key="keys" className="mb-m">{`${selectedPositionIds.length} Room(s) Selected`}</div>,
        <Button key="clear" onClick={clearInputs}>
          Clear
        </Button>,
        <Button
          key="save"
          type="primary"
          className="mb-s"
          disabled={!hasKeySelected || isLoading}
          onClick={handleSaveAsCurrentConfig}
        >
          Save
        </Button>,
      ]}
      {...modalWrapperProps}
    >
      <Form form={form} validateMessages={validationMessages}>
        <KeyConfigurationRoomForm
          mobileMode
          showCheckBox
          form={form}
          onSelect={setHasKeySelected}
          currentConfig={defaultConfigs}
          showHeatingMode={showHeatingMode}
          isBulkConfiguration
        />
      </Form>
    </Modal>
  );
};

export default BulkConfigurationEditModal;
