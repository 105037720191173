import { Popover } from "antd";
import { PopoverProps } from "antd/lib/popover";
import React, { ReactNode, useMemo, useState } from "react";
import ChartComponent, { LinearComponentProps } from "react-chartjs-2";
import { getChartOptions } from "../helpers";
import "../styles.scss";

type Props = {
  chart: typeof ChartComponent;
  tooltipContent: ReactNode;
  setTooltipContent: (tooltipModel: Record<string, any>) => void;
  tootipOffset?: {
    x?: number;
    y?: number;
  };
  xAxesTicksCallback: (value: string) => string | string[];
};

const BaseLinear = (props: Props & Partial<LinearComponentProps>) => {
  const {
    data,
    options,
    tooltipContent,
    setTooltipContent,
    chart: Chart,
    tootipOffset = {},
    xAxesTicksCallback,
    ...restProps
  } = props;
  const { x: tootipOffsetX = 0, y: tootipOffsetY = 0 } = tootipOffset;

  const [tooltipOptions, setTooltipOptions] = useState<Partial<PopoverProps>>({
    visible: false,
    align: {
      offset: [0, 0],
    },
  });

  const hideTooltip = () => {
    setTooltipOptions((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const chartOptions = getChartOptions(options, {
    xAxesTicksCallback,
    onZoom: hideTooltip,
    onPan: hideTooltip,
    tooltipsCustom: (tooltipModel: any) => {
      if (tooltipModel.opacity === 0) {
        hideTooltip();
        return;
      }

      setTooltipOptions({
        visible: true,
        align: {
          offset: [tooltipModel.caretX - tootipOffsetX, tooltipModel.caretY - tootipOffsetY],
        },
      });

      setTooltipContent(tooltipModel);
    },
  });

  return (
    <Popover
      placement="topLeft"
      overlayClassName="chartjs-tooltip"
      transitionName="fade"
      content={tooltipContent}
      autoAdjustOverflow={false}
      {...tooltipOptions}
    >
      <div className="h-100 w-100">
        {useMemo(
          () => (
            <Chart data={data} options={chartOptions} {...restProps} />
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [data]
        )}
      </div>
    </Popover>
  );
};

export default BaseLinear;
