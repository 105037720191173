import { BorderOuterOutlined, ClearOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import {
  GetGroupsForDropdownQuery,
  GetNonEmptyKeysRoomsByGroupQuery,
  useGetGroupsForDropdownQuery,
  useGetNonEmptyKeysRoomsByGroupLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { GroupType } from "pages/Groups/Components/GroupModal";
import React, { useState } from "react";
import { mapTypes } from "./types";

const MapperHeader = (props: {
  mapMode: boolean;
  ongoingMapping: boolean;
  setEntityToMap: (e: any) => void;
  mapTypeChangeHandler: (e: any) => void;
  onClickHandler: () => void;
  onResetHandler: () => void;
  onDoneHandler: () => Promise<void>;
  onMappingDeleteHandler: (id: string, type: string) => Promise<void>;
  onAutoMapNodeSlotHandler: (nodeSlots: any) => void;
}) => {
  const {
    onClickHandler,
    mapMode,
    setEntityToMap,
    mapTypeChangeHandler,
    ongoingMapping,
    onResetHandler,
    onDoneHandler,
    onMappingDeleteHandler,
    onAutoMapNodeSlotHandler,
  } = props;

  const { locationId } = useSensorflowLocation();

  const [groupNames, setGroupNames] = useState<GetGroupsForDropdownQuery["positions"]>([]);
  const [groupData, setGroupData] = useState<GetNonEmptyKeysRoomsByGroupQuery["positions"]>();
  const [gatewayData, setGatewayData] = useState<GetNonEmptyKeysRoomsByGroupQuery["gateways"]>();
  const [selectedNodeSlots, setSelectedNodeSlots] =
    useState<GetNonEmptyKeysRoomsByGroupQuery["positions"][0]["rooms"][0]["slotMappings"]>();
  const [selectedKey, setSelectedKey] = useState<GetNonEmptyKeysRoomsByGroupQuery["positions"][0]>();
  const [entityBeingMapped, setEntityBeingMapped] = useState<"key" | "room" | "gateway" | null>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [mappingGateways, setMappingGateways] = useState<boolean>(false);
  const [selectedKeyEntity, setSelectedKeyEntity] = useState<{
    id: string;
    name: String;
    type: string;
    isMapped: boolean;
  } | null>();
  const [selectedRoomEntity, setSelectedRoomEntity] = useState<{
    id: string;
    name: String;
    type: string;
    isMapped: boolean;
    nodeSlotsMapped: boolean;
  } | null>();
  const [selectedGatewayEntity, setSelectedGatewayEntity] = useState<{
    id: string;
    name: String;
    type: string;
    isMapped: boolean;
  } | null>();

  const { loading } = useGetGroupsForDropdownQuery({
    variables: {
      where: {
        locationId: { _eq: locationId },
        positionType: {
          _in: [GroupType.FLOOR, GroupType.GROUP, GroupType.ZONE],
        },
        deletedAt: { _is_null: true },
      },
    },
    onCompleted: ({ positions: groups }) => {
      setGroupNames(groups);
    },
  });

  const [handleOnGroupSelect] = useGetNonEmptyKeysRoomsByGroupLazyQuery({
    variables: { locationId, groupId: selectedGroup },
    onCompleted(data) {
      setGroupData(data.positions);
      setGatewayData(data.gateways);
    },
  });

  const onKeySelectChangeHandler = (g: any) => {
    const key = groupData?.find((k: any) => k.positionId === g);
    const mapped = (key?.mappingCoordinates?.length ?? 0) > 0;
    if (mapped) setShowDelete(true);
    else setShowDelete(false);
    const coordinates = mapped ? key?.mappingCoordinates[0].coordinates : [];
    onResetHandler();
    setEntityToMap({ positionId: key?.positionId, positionName: key?.positionName, coordinates });
    setSelectedKeyEntity({
      id: key?.positionId,
      name: key?.positionName ?? "-",
      type: "position",
      isMapped: mapped,
    });
    setSelectedKey(key);
    setEntityBeingMapped("key");
    setSelectedRoomEntity(null);
  };

  const onGatewaySelectChangeHandler = (gw: any) => {
    const gateway = gatewayData?.find((g) => g.gatewayId === gw);
    const mapped = (gateway?.mappingCoordinates?.coordinates.length ?? 0) > 0;
    if (mapped) setShowDelete(true);
    else setShowDelete(false);
    const coordinates = mapped ? gateway?.mappingCoordinates?.coordinates[0] : null;
    setEntityToMap({
      gatewayId: gateway?.gatewayId,
      positionName: gateway?.gatewayName,
      coordinates,
    });
    setSelectedGatewayEntity({
      id: gateway?.gatewayId ?? "",
      name: gateway?.gatewayName ?? "-",
      type: "gateway",
      isMapped: mapped,
    });
    mapTypeChangeHandler("point");
    setEntityBeingMapped("gateway");
  };

  const onRoomSelectChangeHandler = (r: any) => {
    const key = groupData?.find((k: any) => k.positionId === selectedKeyEntity?.id);
    if (!key || !key?.rooms || key?.rooms.length < 0) return;
    const room = key?.rooms.find((innerRoom: any) => innerRoom.positionId === r);
    const mapped = (room?.mappingCoordinates?.length ?? 0) > 0;

    const nodeSlotsMapped =
      room?.slotMappings &&
      room?.slotMappings.length > 0 &&
      room?.slotMappings.every((ns) => (ns.slot?.mappingCoordinates?.coordinates?.length ?? 0) > 0);

    if (mapped) setShowDelete(true);
    else setShowDelete(false);
    const coordinates = mapped ? room?.mappingCoordinates[0].coordinates : [];
    onResetHandler();
    setEntityToMap({
      positionId: room?.positionId,
      positionName: room?.positionName,
      coordinates,
      nodeSlots: room?.slotMappings,
    });
    setSelectedRoomEntity({
      id: room?.positionId,
      name: room?.positionName ?? "-",
      type: "position",
      isMapped: mapped,
      nodeSlotsMapped: nodeSlotsMapped ?? false,
    });
    setEntityBeingMapped("room");
    setSelectedNodeSlots(room?.slotMappings ?? []);
  };

  const onDeleteHandler = async () => {
    switch (entityBeingMapped) {
      case "key":
        if (selectedKeyEntity) {
          await onMappingDeleteHandler(selectedKeyEntity?.id, selectedKeyEntity.type);
        }
        break;
      case "room":
        if (selectedRoomEntity?.nodeSlotsMapped && selectedNodeSlots) {
          selectedNodeSlots.map(async (ns) => {
            await onMappingDeleteHandler(ns.slot?.id, "slot");
          });
        }
        if (selectedRoomEntity) {
          await onMappingDeleteHandler(selectedRoomEntity?.id, selectedRoomEntity.type);
        }
        break;
      case "gateway":
        if (selectedGatewayEntity) {
          await onMappingDeleteHandler(selectedGatewayEntity?.id, selectedGatewayEntity.type);
        }
        break;
      default:
    }
    handleOnGroupSelect();
    setSelectedKeyEntity(null);
    setSelectedRoomEntity(null);
    setSelectedNodeSlots([]);
    setSelectedGatewayEntity(null);
    setShowDelete(false);
  };

  const onDoneEntityHandler = async () => {
    await onDoneHandler();
    onResetHandler();
    handleOnGroupSelect();
    switch (entityBeingMapped) {
      case "key":
        if (selectedKeyEntity) {
          setSelectedKeyEntity(null);
          setSelectedRoomEntity(null);
        }
        break;
      case "room":
        if (selectedRoomEntity) {
          setSelectedRoomEntity(null);
        }
        break;
      case "gateway":
        if (selectedGatewayEntity) {
          setSelectedGatewayEntity(null);
        }
        break;
      default:
    }
  };
  return (
    <>
      <div className="d-flex">
        <Button type="primary" onClick={onClickHandler}>
          {mapMode ? "Exit Mapping" : "Map Assets"}
        </Button>
        {mapMode && (
          <>
            <Button
              type="primary"
              className="ml-m"
              onClick={() => {
                setMappingGateways((prev) => !prev);
                setShowDelete(false);
              }}
            >
              {mappingGateways ? "Map Keys and Rooms" : "Map Gateways"}
            </Button>
            <div className="d-flex flex-column mb-m w-100">
              <div className="d-flex mb-m">
                <Select
                  className="w-20 ml-m"
                  data-testid="group-names-testId"
                  disabled={loading}
                  placeholder="Group"
                  showSearch
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(g) => {
                    setSelectedGroup(g);
                    handleOnGroupSelect();
                  }}
                >
                  <Select.Option value={undefined}>-</Select.Option>
                  {groupNames?.map((option) => (
                    <Select.Option key={option.positionName} value={option.positionId}>
                      {option.positionName}
                    </Select.Option>
                  ))}
                </Select>
                {groupData && !mappingGateways && (
                  <>
                    {/* Keys to be mapped */}
                    <Select
                      className="w-25 ml-m"
                      data-testid="key-names-testId"
                      disabled={loading}
                      placeholder="Keys"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedKeyEntity?.name}
                      onSelect={onKeySelectChangeHandler}
                    >
                      <Select.Option value={undefined}>-</Select.Option>
                      {groupData?.map((option) => (
                        <Select.Option key={option.positionName} value={option.positionId}>
                          {option.positionName}
                        </Select.Option>
                      ))}
                    </Select>
                    {/* Rooms to be mapped */}
                    <Select
                      className="w-25 ml-m"
                      data-testid="room-names-testId"
                      disabled={loading}
                      placeholder="Rooms"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedRoomEntity?.name}
                      onSelect={onRoomSelectChangeHandler}
                    >
                      <Select.Option value={undefined}>-</Select.Option>
                      {selectedKey?.rooms.map((option) => (
                        <Select.Option key={option.positionName} value={option.positionId}>
                          {option.positionName}
                        </Select.Option>
                      ))}
                    </Select>
                    {selectedRoomEntity?.isMapped && (
                      <Button
                        className="ml-m"
                        type="primary"
                        icon={
                          <Tooltip title="Automap Node Slots">
                            <BorderOuterOutlined data-testid="auto-mapping-icon" />
                          </Tooltip>
                        }
                        disabled={selectedRoomEntity.nodeSlotsMapped}
                        onClick={() => {
                          onAutoMapNodeSlotHandler(selectedNodeSlots);
                          setShowDelete(false);
                        }}
                      />
                    )}
                  </>
                )}
                {mappingGateways && (
                  // Section for Mapping Gateways
                  <div className="w-50">
                    <Select
                      className="w-50 ml-m"
                      data-testid="gateways-testId"
                      disabled={loading}
                      placeholder="Gateways"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={selectedGatewayEntity?.name}
                      onSelect={onGatewaySelectChangeHandler}
                    >
                      <Select.Option value={undefined}>-</Select.Option>
                      {gatewayData?.map((option) => (
                        <Select.Option key={option.gatewayName} value={option.gatewayId}>
                          {option.gatewayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-evenly w-100">
                {groupData && (
                  <div className="d-flex justify-content-evenly w-100">
                    {!mappingGateways && (
                      <Select
                        className="w-20 ml-m"
                        data-testid="room-names-testId"
                        disabled={loading}
                        placeholder="Map Type"
                        showSearch
                        filterOption={(input, option) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={(t) => {
                          mapTypeChangeHandler(t);
                        }}
                      >
                        <Select.Option value={undefined}>-</Select.Option>
                        {mapTypes?.map((option) => (
                          <Select.Option key={option.display} value={option.value}>
                            {option.display}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                    {showDelete && (
                      <Button
                        type="primary"
                        icon={
                          <Tooltip title="Delete mapping">
                            <DeleteOutlined data-testid="delete-mapping-icon" />
                          </Tooltip>
                        }
                        className="ml-m"
                        onClick={onDeleteHandler}
                      />
                    )}
                    {ongoingMapping && (
                      <>
                        <Button
                          type="primary"
                          icon={
                            showDelete ? (
                              <Tooltip title="Update Mapping">
                                <EditOutlined data-testid="update-mapping-icon" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Save Mapping">
                                <SaveOutlined data-testid="save-mapping-icon" />
                              </Tooltip>
                            )
                          }
                          className="ml-m"
                          onClick={onDoneEntityHandler}
                        />
                        <Button
                          type="primary"
                          icon={
                            <Tooltip title="Clear Mapping">
                              <ClearOutlined data-testid="clear-mapping-icon" />
                            </Tooltip>
                          }
                          className="ml-m"
                          onClick={onResetHandler}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MapperHeader;
