import React from "react";
import "./UpcomingPreCool.scss";
import { Modal, Typography } from "antd";
import { CalendarIcon, ClockIcon } from "components/Icons";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import ModalWrapper from "components/Modal/Wrapper";
import { ModalWrapperProps } from "components/Modal/Modal";
import { UpcomingPrecoolSchedulesSubscription } from "pacts/app-webcore/hasura-webcore.graphql";
import { formatDate, formatTime12h } from "utils/date";

export interface UpcomingPreCoolProps {
  upcomingScheduledPrecools: UpcomingPrecoolSchedulesSubscription["sensorflow_precool_commands"];
  timezone: string;
}

const UpcomingPreCoolSchedule: React.FC<
  { upcomingScheduledPrecools: UpcomingPreCoolProps["upcomingScheduledPrecools"]; timezone: string } & ModalWrapperProps
> = ({ modalWrapperProps, upcomingScheduledPrecools, timezone }) => {
  return (
    <Modal
      title="Pre-cool schedule"
      centered
      width={320}
      className="upcoming-pre-cool-modal"
      footer={null}
      {...modalWrapperProps}
    >
      {upcomingScheduledPrecools.map(({ startedAt }) => (
        <div key={startedAt} className="upcoming-pre-cool-content">
          <Typography.Text>
            <CalendarIcon /> {formatDate(startedAt, timezone)}
          </Typography.Text>
          <span>•</span>
          <Typography.Text>
            <ClockIcon /> {formatTime12h(startedAt, timezone)}
          </Typography.Text>
        </div>
      ))}
    </Modal>
  );
};

export const UpcomingPreCool: React.FC<UpcomingPreCoolProps> = ({ upcomingScheduledPrecools, timezone }) => {
  const [preCoolScheduleModalRef, openPreCoolScheduleModal] = useModalWrapperTrigger();
  const fistPreCoolSchedule = upcomingScheduledPrecools[0];

  return (
    <>
      <div className="upcoming-pre-cool" aria-hidden="true" onClick={() => openPreCoolScheduleModal?.()}>
        <div className="upcoming-pre-cool-title">
          <Typography.Title level={5}>Upcoming Pre-cool schedule</Typography.Title>
        </div>
        <div className="upcoming-pre-cool-content">
          <Typography.Text>
            <CalendarIcon /> {formatDate(fistPreCoolSchedule.startedAt, timezone)}
          </Typography.Text>
          <span>•</span>
          <Typography.Text>
            <ClockIcon /> {formatTime12h(fistPreCoolSchedule.startedAt, timezone)}
          </Typography.Text>
        </div>
      </div>
      <ModalWrapper
        ref={preCoolScheduleModalRef}
        modal={UpcomingPreCoolSchedule}
        props={{ upcomingScheduledPrecools, timezone }}
      />
    </>
  );
};
