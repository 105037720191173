import { Button, Modal } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal";
import React, { useCallback } from "react";

export interface ReloadTestModalProps {
  [x: string]: any;
  onReset: () => void | Promise<void>;
  title?: string;
}

export const ReloadTestModal = ({
  modalWrapperProps,
  closeModal,
  onReset,
  title,
}: ReloadTestModalProps & ModalWrapperProps) => {
  const handleOnOke = useCallback(async () => {
    await onReset();
    closeModal();
  }, [closeModal, onReset]);
  return (
    <Modal
      className="select-test-run-modal"
      title={title || "Do you want to reset this test?"}
      centered
      wrapClassName="vh-100-modal"
      width={450}
      {...modalWrapperProps}
      footer={null}
    >
      <Button type="primary" className="ant-btn ant-btn-primary modal-button" onClick={handleOnOke}>
        Yes
      </Button>
      <br />
      <Button type="default" className="modal-button button-no" onClick={closeModal}>
        No
      </Button>
    </Modal>
  );
};
