import React from "react";

import { Divider } from "antd";
import { get } from "lodash";
import NodeTypeDisplay from "utils/nodeTypeDisplay";
import "./NextRoomInfo.css";

const NextRoomInfo = ({ hasNextKey, nextKeyName, nextRoomName, nextRoomSlots }: any) => {
  const renderNodes = () => {
    if (nextRoomSlots) {
      return nextRoomSlots.map((slot: any, index: number) => (
        <p key={index} className="node-slot-text">
          {get(NodeTypeDisplay[slot.nodeSubType], "code")} - {slot.slotName}
        </p>
      ));
    }

    return null;
  };

  return (
    <div className="next-room-info">
      {hasNextKey && (
        <>
          <p className="list-item-header">NEXT KEY</p>
          <p className="list-item-text">{nextKeyName}</p>
        </>
      )}

      <p className="list-item-header">NEXT ROOM</p>
      <p className="list-item-text">{nextRoomName}</p>

      <Divider type="horizontal" className="divider" />

      <p className="list-item-header node-list-header">NODES</p>
      <div className="list-item-text">{renderNodes()}</div>
    </div>
  );
};

export default NextRoomInfo;
