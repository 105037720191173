import { useEffect, useState } from "react";
import {
  Sensorflow_Location_Metadata,
  useGetUserLocationMetadataStatsQuery,
  useLocationMetadataLazyQuery,
  useUserWithAuthTokenQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import {
  hasSmartAlloc,
  hasSmartAllocPro,
  hasSmartREM as hasSmartRem,
  LocationSubscription,
} from "utils/locationMetadata";

const useLocationMetadata = ({ locationId }: { locationId?: string }) => {
  const [locationMetadata, setLocationMetadata] = useState<Partial<Sensorflow_Location_Metadata>>({});

  const [getLocationMetadata] = useLocationMetadataLazyQuery({
    onCompleted: (data) => {
      setLocationMetadata(data.locationMetadata || {});
    },
    onError: errorHandler.handleError,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (locationId) {
      getLocationMetadata({
        variables: {
          locationId,
        },
      });
    }
  }, [locationId, getLocationMetadata]);

  return {
    locationMetadata,
    hasSmartRem: hasSmartRem(locationMetadata?.subscription),
    hasSmartAlloc: hasSmartAlloc(locationMetadata?.subscription),
    hasSmartAllocPro: hasSmartAllocPro(locationMetadata?.subscription),
  };
};

export interface IUseUserLocationMetadataStatsFilter {
  subscriptions: LocationSubscription[];
}

export default useLocationMetadata;

export const useUserLocationMetadataStats = (filter: IUseUserLocationMetadataStatsFilter) => {
  const { data: userData } = useUserWithAuthTokenQuery();
  const { data } = useGetUserLocationMetadataStatsQuery({
    variables: {
      where: {
        location: {
          location_to_user_mappings: {
            user_id: {
              _eq: userData?.userWithAuthToken?.userId || "",
            },
          },
        },
        subscription: {
          _in: filter.subscriptions,
        },
      },
    },
    skip: !userData?.userWithAuthToken?.userId,
  });

  return data;
};
