import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import errorHandler from "errorHandler";
import {
  useEndKeyEntryMutation,
  useCurrentKeyEntriesSubscription,
  useInfrastructuresLazyQuery,
  usePositionConfigurationsByKeyLazyQuery,
  useStartKeyEntryMutation,
  useUpdateKeyEntryExpiredAtMutation,
  useGetWorkInfoQuery,
  useKeyEntryDetailSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { InstallationModeContextState, KeyEntry, OptionsEnterKey, PositionFunction } from "types/InstallationMode.d";
import { message } from "antd";
import { RoleContext } from "contexts/role-context";
import moment from "moment";
import AutoExitModal from "components/InstallationMode/AutoExitModal";
import { WrapperStorageHelper } from "utils/storage";
import PreAutoExitModal from "components/InstallationMode/PreAutoExitModal";
import { Executor, TaskStatus } from "pages/KeyEntryJobChecklist";
import { TestRun, TestRunStatus } from "pages/Checklist/Checklist.d";
import { bindingWorkInfoData } from "components/JobDetailHeader";
import { CommentModal } from "components/CommentModal";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { isFunction } from "lodash";
import { toTestRuns } from "pages/KeyEntryJobChecklist/JobChecklist/helpers";

const InstallationModeContext = React.createContext<InstallationModeContextState>({} as InstallationModeContextState);
const storageKeyKeyEntry = "currentKeyEntry";
const localCurrentKeyKeyEntry = WrapperStorageHelper.getItem<KeyEntry>(storageKeyKeyEntry);

const KEY_ENTRY_NOTIFY_TIME_OUT = process.env.REACT_APP_REACT_KEY_ENTRY_NOTIFY_TIME_OUT_MINUTES || "15";
const KEY_ENTRY_EXTEND_EXPIRED_TIME_MINUTES = process.env.REACT_APP_REACT_KEY_ENTRY_EXTEND_EXPIRED_TIME_MINUTES || "15";

const InstallationModeProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const history = useHistory();
  const [currentKeyEntryOfInstaller, setCurrentKeyEntryOfInstaller] = useState<KeyEntry>();
  const [currentKeyEntryOfPositions, setCurrentKeyEntryOfPositions] = useState<KeyEntry[]>();
  const [claimKeys, setClaimKeys] = useState<KeyEntry[]>([]);
  const [currentPositionId, setCurrentPositionId] = useState<string>("");
  const [keyPositionIds, setKeyPositionIds] = useState<string[]>([]);
  const [keyName, setKeyName] = useState<string>("");
  const [testRuns, setTestRuns] = useState<TestRun[]>([]);
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [autoExitModal, setAutoExitModal] = useState<boolean>(false);
  const [callbackExit, setCallbackExit] = useState<() => Promise<void> | void>();
  const role = useContext(RoleContext);
  const { isContractor } = useRoleAndPermission();

  const userId = useMemo(() => role?.user?.userId, [role]);

  const setCurrentKeyEntry = useCallback(
    (keyEntry: KeyEntry) => {
      const currentKeyEntry = {
        keyEntryId: keyEntry.keyEntryId,
        startedAt: keyEntry.startedAt,
        endedAt: keyEntry.endedAt,
        userId: keyEntry.userId,
        positionFunction: keyEntry.positionFunction,
        keyPositionId: keyEntry.keyPositionId,
        isResumedEntry: keyEntry.isResumedEntry,
        expiredAt: keyEntry.expiredAt,
        status: keyEntry.status,
        automaticTestRun: keyEntry.automaticTestRun,
        isMeterPosition: keyEntry.isMeterPosition,
      };

      setCurrentPositionId(currentKeyEntry.keyPositionId || "");
      setCurrentKeyEntryOfInstaller(currentKeyEntry);
      WrapperStorageHelper.setItem(storageKeyKeyEntry, currentKeyEntry);
    },
    [setCurrentPositionId]
  );

  const onExitKeyEntry = useCallback((isAuto?: boolean) => {
    setCurrentPositionId("");
    setCurrentKeyEntryOfInstaller(undefined);
    if (isAuto) return;
    WrapperStorageHelper.removeItem(storageKeyKeyEntry);
  }, []);

  const onCloseModalAutoExit = useCallback(() => {
    setAutoExitModal(false);
    onExitKeyEntry();
  }, [setAutoExitModal, onExitKeyEntry]);

  const checkAutoExitKeyEntry = useCallback(
    (currentKeyEntry?: KeyEntry) => {
      if (currentKeyEntry) {
        const now = moment(moment.now());
        const keyEntryMaxAge = moment(currentKeyEntry.expiredAt);
        const diff = now.diff(keyEntryMaxAge, "m");
        const diffNotifyTimeOut = now.diff(keyEntryMaxAge.add(KEY_ENTRY_NOTIFY_TIME_OUT, "m"), "m");

        if (diff >= 0 && diffNotifyTimeOut < 0) {
          return setAutoExitModal(true);
        }
      }
      setAutoExitModal(false);
    },
    [setAutoExitModal]
  );

  const [startKeyEntry, statusOfTheEnteredProcess] = useStartKeyEntryMutation({
    onCompleted: (data) => {
      message.success("Entered to installation mode successfully.");
      const insertKeyEntry = data.startInstallationMode as KeyEntry;
      if (insertKeyEntry) setCurrentKeyEntry(insertKeyEntry);
    },
    onError: errorHandler.handleError,
  });
  const [endKeyEntry, statusOfTheExitedProcess] = useEndKeyEntryMutation();

  const [updateKeyEntryExpiredAtMutation, statusOfTheUpdateExpiredAt] = useUpdateKeyEntryExpiredAtMutation();

  useCurrentKeyEntriesSubscription({
    skip: keyPositionIds.length === 0 && !userId,
    variables: {
      where: {
        _or: [{ keyPositionId: { _in: keyPositionIds } }],
        endedAt: { _is_null: true },
      },
    },
    onSubscriptionData: (data) => {
      const keyEntriesData = data?.subscriptionData.data?.keyEntries as KeyEntry[];
      const keyEntryOfInstaller = keyEntriesData.find((x) => x.userId === userId && x.startedAt);
      const keyEntryOfClaim = keyEntriesData.filter((x) => x.userId === userId && !x.startedAt);
      setCurrentKeyEntryOfPositions(keyEntriesData);
      setClaimKeys(keyEntryOfClaim);
      if (keyEntryOfInstaller) setCurrentKeyEntry(keyEntryOfInstaller);
      else {
        checkAutoExitKeyEntry(currentKeyEntryOfInstaller);
        onExitKeyEntry(true);
      }
    },
  });

  useKeyEntryDetailSubscription({
    skip: !currentKeyEntryOfInstaller?.keyPositionId,
    variables: {
      where: {
        keyPositionId: {
          _eq: currentKeyEntryOfInstaller?.keyPositionId || "",
        },
        positionFunction: {
          _eq: currentKeyEntryOfInstaller?.positionFunction || "",
        },
      },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        const newTestRuns = toTestRuns(subscriptionData.data);
        if (newTestRuns) {
          setTestRuns(newTestRuns);
        }
      }
    },
  });

  const [queryKeyName] = usePositionConfigurationsByKeyLazyQuery({
    variables: { keyId: currentKeyEntryOfInstaller?.keyPositionId || localCurrentKeyKeyEntry?.keyPositionId },
    onCompleted: (data) => {
      const { position } = data;
      if (position) setKeyName(position.positionName);
    },
    onError: errorHandler.handleError,
  });

  const [queryInfrastructure] = useInfrastructuresLazyQuery({
    variables: {
      where: { id: { _eq: currentKeyEntryOfInstaller?.keyPositionId || localCurrentKeyKeyEntry?.keyPositionId } },
      offset: 0,
      limit: 1,
    },
    onCompleted: (data) => {
      const infrastructure = data.infrastructures[0];
      if (infrastructure?.name) setKeyName(infrastructure.name);
    },
    onError: errorHandler.handleError,
  });

  const { refetch } = useGetWorkInfoQuery({ skip: true });

  const handleEnterKey = useCallback(
    async (options: OptionsEnterKey) => {
      const { positionFunction, isResumedEntry, redirectUrl, keyPositionId, automaticTestRun, isMeterPosition } =
        options;
      const result = await startKeyEntry({
        variables: {
          keyPositionId,
          userId,
          positionFunction,
          isResumedEntry,
          automaticTestRun,
          isMeterPosition,
        },
      });
      if (!result) return;
      setCurrentPositionId(keyPositionId);
      if (redirectUrl) history.push(redirectUrl);
    },
    [userId, history, startKeyEntry]
  );

  const checkCanExitKeyEntry = useCallback(async () => {
    if (!isContractor()) return true;
    const { data: { tasksWithKeys = [] } = {} } = await refetch({
      where: {
        keyId: { _in: [currentKeyEntryOfInstaller?.keyPositionId || ""] },
        executor: { _eq: Executor.CONTRACTOR },
        _or: [
          { status: { _eq: TaskStatus.PENDING } },
          {
            _and: [
              { status: { _eq: TaskStatus.DONE } },
              { keyEntryId: { _eq: currentKeyEntryOfInstaller?.keyEntryId } },
            ],
          },
        ],
      },
    });
    const { countJobsNotDone } = bindingWorkInfoData(tasksWithKeys);
    const doneTestRun = testRuns?.length
      ? !testRuns?.filter((testRun) => testRun.status !== TestRunStatus.PASSED).length
      : true;

    return countJobsNotDone <= 0 && doneTestRun;
  }, [isContractor, refetch, currentKeyEntryOfInstaller, testRuns]);

  const handleExitKey = useCallback(
    async (onExit?: () => void) => {
      if (!currentKeyEntryOfInstaller) return;
      const isCanExit = await checkCanExitKeyEntry();
      if (isCanExit) {
        const { data: endKeyEntryReturning } = await endKeyEntry({
          variables: { keyEntryId: currentKeyEntryOfInstaller.keyEntryId },
        });
        if (endKeyEntryReturning?.exitInstallationMode) {
          message.success("Exited installation mode successfully.");
          onExitKeyEntry();
          setOpenCommentModal(false);
          if (isFunction(onExit)) onExit();
        } else message.error("You've already exited installation.");
      } else {
        setCallbackExit(() => onExit);
        setOpenCommentModal(true);
      }
    },
    [
      currentKeyEntryOfInstaller,
      endKeyEntry,
      checkCanExitKeyEntry,
      setOpenCommentModal,
      setCallbackExit,
      onExitKeyEntry,
    ]
  );

  const onConfirmComment = useCallback(
    async (status?: string, comment?: string) => {
      if (!currentKeyEntryOfInstaller) return;
      const { data: endKeyEntryReturning } = await endKeyEntry({
        variables: { keyEntryId: currentKeyEntryOfInstaller.keyEntryId, status, comment },
      });
      if (endKeyEntryReturning?.exitInstallationMode) {
        message.success("Exited installation mode successfully.");
        onExitKeyEntry();
        setOpenCommentModal(false);
        if (isFunction(callbackExit)) callbackExit();
      } else message.error("You've already exited installation.");
    },
    [currentKeyEntryOfInstaller, endKeyEntry, setOpenCommentModal, callbackExit, onExitKeyEntry]
  );

  const handleUpdateExpiredAt = useCallback(async () => {
    if (!currentKeyEntryOfInstaller) return;

    const { data: updateExpiredAt } = await updateKeyEntryExpiredAtMutation({
      variables: {
        keyEntryId: currentKeyEntryOfInstaller.keyEntryId,
        expiredAt: moment(currentKeyEntryOfInstaller.expiredAt)
          .add(KEY_ENTRY_EXTEND_EXPIRED_TIME_MINUTES, "m")
          .format(),
      },
    });

    if (updateExpiredAt?.update_keyEntries_by_pk) {
      message.success("Successfully extended the installation entry.");
    }
  }, [currentKeyEntryOfInstaller, updateKeyEntryExpiredAtMutation]);

  useEffect(() => {
    if (currentKeyEntryOfInstaller) {
      if (currentKeyEntryOfInstaller.positionFunction === PositionFunction.COMPRESSOR)
        queryInfrastructure({
          variables: {
            where: { id: { _eq: currentKeyEntryOfInstaller.keyPositionId } },
            offset: 0,
            limit: 1,
          },
        });
      else queryKeyName({ variables: { keyId: currentKeyEntryOfInstaller.keyPositionId } });
    }
  }, [queryKeyName, queryInfrastructure, currentKeyEntryOfInstaller]);
  const isLoading =
    statusOfTheExitedProcess.loading || statusOfTheEnteredProcess.loading || statusOfTheUpdateExpiredAt.loading;

  const contextValue = useMemo(() => {
    return {
      userId,
      keyName,
      testRuns,
      isLoading,
      claimKeys,
      currentPositionId,
      currentKeyEntryOfPositions,
      currentKeyEntryOfInstaller,
      handleExitKey,
      handleEnterKey,
      setKeyPositionIds,
      setCurrentPositionId,
      setCurrentKeyEntryOfInstaller,
    };
  }, [
    userId,
    keyName,
    testRuns,
    isLoading,
    claimKeys,
    currentPositionId,
    currentKeyEntryOfPositions,
    currentKeyEntryOfInstaller,
    handleExitKey,
    handleEnterKey,
    setKeyPositionIds,
    setCurrentPositionId,
    setCurrentKeyEntryOfInstaller,
  ]);

  return (
    <InstallationModeContext.Provider value={contextValue}>
      {children}
      <AutoExitModal
        keyName={keyName}
        open={autoExitModal}
        onOk={() => {
          const currentKeyEntry =
            currentKeyEntryOfInstaller || WrapperStorageHelper.getItem<KeyEntry>(storageKeyKeyEntry);

          if (currentKeyEntry) {
            WrapperStorageHelper.removeItem(storageKeyKeyEntry);
            handleEnterKey({
              isResumedEntry: true,
              keyPositionId: currentKeyEntry?.keyPositionId,
              positionFunction: currentKeyEntry?.positionFunction as PositionFunction,
              automaticTestRun: true,
              isMeterPosition: currentKeyEntry?.isMeterPosition,
            });
            setAutoExitModal(false);
          }
        }}
        onClose={onCloseModalAutoExit}
      />
      {currentKeyEntryOfInstaller && (
        <PreAutoExitModal
          keyName={keyName}
          expiredAt={currentKeyEntryOfInstaller?.expiredAt}
          onExit={handleExitKey}
          onExtendEntry={handleUpdateExpiredAt}
          isLoading={isLoading}
        />
      )}

      <CommentModal
        keyName={keyName}
        onConfirm={onConfirmComment}
        openCommentModal={openCommentModal}
        setOpenCommentModal={setOpenCommentModal}
        isForExitEntry
        isLoading={isLoading}
      />
    </InstallationModeContext.Provider>
  );
};

export { InstallationModeContext, InstallationModeProvider };
