import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";

import {
  UserFilterInput,
  useUsersQuery,
  UsersQuery,
  FilterOption,
  useRolesWithStatsQuery,
  SortOptions,
} from "pacts/app-webcore/hasura-webcore.graphql";

import ListFilter from "components/ListFilter/ListFilter";
import _ from "lodash";
import logger from "../../../../services/logger/logger";

// get the user type from the query response
type User = NonNullable<UsersQuery["users"]>["users"][number];

// should get this interface from antd
interface AntdTablePagination {
  current: number;
  pageSize: number;
  total: number;
}

const UserListTable = () => {
  // component state
  const [pagination, setPagination] = useState<AntdTablePagination>({
    // match with antd's api
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [activeFilters, setActiveFilters] = useState<UserFilterInput>({
    search: "",
  });

  const [roleFilterOptions, setRoleFilterOptions] = useState<FilterOption[]>([]);

  const [tableData, setTableData] = useState<User[]>([]);

  // graphQL
  const { error: queryRoleErr } = useRolesWithStatsQuery({
    onCompleted: (data) => {
      setRoleFilterOptions(
        data.roles
          ?.filter((r) => (r.userCount || 0) > 0)
          .map((r) => ({
            field: "roleIds",
            value: r.id,
            label: r.name,
            count: r.userCount || 0,
          })) || []
      );
    },
  });

  const { loading, error, fetchMore } = useUsersQuery({
    variables: {
      filter: {
        search: activeFilters?.search,
      },
      pagination: {
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      },
      sort: {
        name: SortOptions.Asc,
      },
    },
    onCompleted: (data) => {
      if (data?.users?.users) {
        setTableData(data.users.users);
      }
      // we're only interested in the total from the server, the current and pageSize are managed in local state
      setPagination({
        ...pagination,
        total: data.users?.pagination?.count || 0,
      });
    },
  });

  const handleFetch = (desiredPagination: AntdTablePagination) => {
    fetchMore({
      variables: {
        pagination: {
          offset: (desiredPagination.current - 1) * pagination.pageSize,
          limit: desiredPagination.pageSize,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const { limit: pageSize, offset, count: total } = fetchMoreResult.users!.pagination;
        setPagination({
          current: offset && pageSize ? offset / pageSize + 1 : 1,
          pageSize,
          total,
        } as AntdTablePagination);
        return fetchMoreResult;
      },
    });
    setPagination(desiredPagination);
  };

  const setTableDataAndPaginationWithFetchMoreResult = (prev: UsersQuery, fetchMoreResult: UsersQuery | undefined) => {
    if (!fetchMoreResult) return prev;
    const { limit: pageSize, offset, count: total } = fetchMoreResult.users!.pagination;
    setPagination({
      current: offset && pageSize ? offset / pageSize + 1 : 1,
      pageSize,
      total,
    } as AntdTablePagination);
    if (fetchMoreResult.users?.users) {
      setTableData(fetchMoreResult.users.users);
    }
    return fetchMoreResult;
  };

  const handleSearch = (currentFilters: UserFilterInput, desiredPagination?: any) => {
    const filtersToQuery: UserFilterInput = {};
    if (currentFilters?.search) {
      filtersToQuery.search = currentFilters.search;
    }

    let gqlPaginationVars = {
      offset: 0,
      limit: pagination.pageSize,
    };
    if (desiredPagination) {
      gqlPaginationVars = {
        offset: (desiredPagination.current - 1) * pagination.pageSize,
        limit: desiredPagination.pageSize,
      };
      setPagination(desiredPagination);
    }

    if (!_.isEmpty(currentFilters?.roleIds)) filtersToQuery.roleIds = currentFilters.roleIds;
    const gqlQueryVars = {
      filter: filtersToQuery,
      pagination: gqlPaginationVars,
    };
    fetchMore({
      variables: gqlQueryVars,
      updateQuery: (prev, { fetchMoreResult }) => setTableDataAndPaginationWithFetchMoreResult(prev, fetchMoreResult),
    });
  };

  const handleSearchWithNewFilter = (f: UserFilterInput) => {
    setActiveFilters(f);
    setPagination({
      ...pagination,
      current: 1,
    });
    handleSearch(f);
  };

  // handle pagination
  const handleTableChange = (desiredPagination: any) => {
    handleFetch(desiredPagination);
  };

  const COLUMN_CONFIG = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: User) => <Link to={`/users/${record.userId}`}>{text}</Link>,
    },
    {
      title: "Role",
      dataIndex: ["role", "name"],
    },
    {
      title: "User Id",
      dataIndex: "userId",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text: string, record: User) => (
        <>
          {text}&nbsp;
          {record.emailVerified === "VERIFIED" ? (
            <CheckCircleFilled style={{ color: "#52c41a" }} title="Email verified" />
          ) : (
            <WarningFilled style={{ color: "#faad14" }} title="Email not verified" />
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  const paginationConfig = { ...pagination, showSizeChanger: false };
  useEffect(() => {
    [error, queryRoleErr].filter((err) => !!err).forEach((err) => logger.error(err));
  }, [error, queryRoleErr]);

  const userList = () => {
    return error || queryRoleErr ? (
      <p>
        Error!
        <br />
        {error?.message || ""}
        <br />
        {queryRoleErr?.message || ""}
      </p>
    ) : (
      <Table
        columns={COLUMN_CONFIG}
        dataSource={tableData}
        rowKey="userId"
        pagination={paginationConfig}
        onChange={handleTableChange}
        scroll={{ x: true }}
      />
    );
  };
  return (
    <>
      <ListFilter
        currentFilter={activeFilters}
        onChange={handleSearchWithNewFilter}
        searchField="search"
        searchPlaceholder="Search user"
        containerClassName="mb-l"
        filterOptions={roleFilterOptions}
      />
      {loading ? <p>Loading...</p> : userList()}
    </>
  );
};

export default UserListTable;
