import { CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import React from "react";
import "./ContentMessage.scss";

const ContentMessage = ({ content }: { content: string }) => (
  <div className="d-flex align-items-center justify-content-between text-left">
    <span>{content}</span>
    <span>
      <CloseOutlined onClick={() => message.destroy()} />
    </span>
  </div>
);

export default ContentMessage;
