import React from "react";

import { CheckCircleTwoTone } from "@ant-design/icons";
import "./FullyMappedAlert.css";

const FullyMappedAlert = ({ message, description }: any) => {
  return (
    <div className="fullly-mapped-alert">
      <CheckCircleTwoTone twoToneColor="#52c41a" className="check-icon" />
      <div className="alert-text">
        <p className="alert-message" data-testid="alert-message-testid">
          {message}
        </p>
        <p className="alert-description">{description}</p>
      </div>
    </div>
  );
};

export default FullyMappedAlert;
