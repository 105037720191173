import React, { useEffect, useRef, useState } from "react";

import { Input, message, Modal } from "antd";
import { useUpdatePositionMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../errorHandler";

type RenameKeyFormModalProps = {
  keyId: string;
  keyName: string;
  reloadKeys: () => void;
  closeModal: () => void;
};

const RenameKeyFormModal = ({ keyId, keyName, reloadKeys, closeModal }: RenameKeyFormModalProps) => {
  const [input, setInput] = useState<string>(keyName);
  const keyNameInputRef: any = useRef();

  const [renameKey] = useUpdatePositionMutation({
    onCompleted: () => {
      message.info("Key name has been updated.");
      reloadKeys();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  useEffect(() => {
    keyNameInputRef.current!.focus();
  }, [keyNameInputRef]);

  const handleSubmitRenameKey = () => {
    const inputChanged = input && input !== keyName;
    if (inputChanged) {
      renameKey({
        variables: {
          positionId: keyId,
          updatePositionInput: {
            positionName: input,
          },
        },
      });
      closeModal();
    }
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Modal
      title="Rename Key"
      centered
      visible
      onCancel={closeModal}
      onOk={handleSubmitRenameKey}
      width={400}
      okText="Rename Key"
    >
      <p className="mb-xs">Key Name</p>
      <Input
        id="key-name-input"
        data-testid="key-name-input-testId"
        placeholder="101"
        onChange={handleInputChange}
        onPressEnter={handleSubmitRenameKey}
        ref={keyNameInputRef}
        value={input}
      />
    </Modal>
  );
};

export default RenameKeyFormModal;
