import { Tabs } from "antd";
import { useLocationActionWithQueryString } from "hooks/use-query-string";
import React from "react";
import { useParams } from "react-router-dom";
import BookingList from "../BookingList/BookingList";
import SmartAllocDashboard from "../Dashboard/SmartAllocDashboard";
import EodSurvey from "../EodSurveys/EodSurvey";

enum ActiveTab {
  DASHBOARD = "dashboard",
  EOD_SURVEY = "survey",
  BOOKING = "booking",
}

const SmartAllocDetails = () => {
  const { pushPathWithIntactSearch } = useLocationActionWithQueryString();

  const { activeTab } = useParams<{ activeTab: string }>();

  const handleTabChange = (activeKey: string) => {
    pushPathWithIntactSearch(`/smart-alloc/${activeKey}`);
  };

  const tabConfigs = [
    {
      name: "Booking",
      key: ActiveTab.BOOKING,
      component: <BookingList />,
      isVisible: true,
    },
    {
      name: "EOD Survey",
      key: ActiveTab.EOD_SURVEY,
      component: <EodSurvey />,
      isVisible: true,
    },
    {
      name: "Dashboard",
      key: ActiveTab.DASHBOARD,
      component: <SmartAllocDashboard />,
      isVisible: true,
    },
  ];

  const tabs = tabConfigs.filter(({ isVisible }) => isVisible);

  return (
    <div>
      <Tabs className="header-tab-bar" activeKey={activeTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SmartAllocDetails;
