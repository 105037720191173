import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button, Col, Modal, Row } from "antd";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import "./CategoriesAndKeysCreationHeader.scss";
import { ActiveStep } from "./CategoriesAndKeysCreation.d";

type CategoriesAndKeysCreationHeaderProps = {
  step: ActiveStep;
};

const CategoriesAndKeysCreationHeader = ({ step = ActiveStep.KEY_CREATION }: CategoriesAndKeysCreationHeaderProps) => {
  const history = useHistory();
  const { locationId } = useParams<{ locationId: string }>();

  const exitCreationMode = () => {
    Modal.confirm({
      title: "You have unsaved changes.",
      content: "Exiting the Creation Wizard now will delete all changes you have made. Are you sure you wish to exit?",
      className: "confirm-modal",
      okText: "Continue Editing",
      cancelText: "Exit Without Saving",
      onCancel: () => {
        history.push(`/locations/${locationId}/keys`);
      },
    });
  };

  const steps = ["Create Category Templates", "Assign Keys"];
  const currentStep = steps[step - 1];

  return (
    <>
      <h1>Key Creation</h1>
      <Row className="breadcrumb-row-wrapper">
        <Col span={16}>
          <Breadcrumb steps={steps} currentStep={currentStep} />
        </Col>
        <Col offset={4} span={4} className="exit-creation-mode">
          <Button onClick={exitCreationMode}>Exit Creation Mode</Button>
        </Col>
      </Row>
    </>
  );
};

export default CategoriesAndKeysCreationHeader;
