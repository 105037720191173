import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Tabs } from "antd";
import OtaNodeList from "../OtaNode/OtaNodeList/OtaNodeList";
import FwManagement from "../FwManagement/FwManagement";

enum ActiveTab {
  FIRMWARES = "firmwares",
  NODES = "nodes",
}

const OtaDetails = () => {
  const history = useHistory();

  const { activeTab } = useParams<{ activeTab: string }>();

  const handleTabChange = (activeKey: string) => {
    history.push(`/ota/${activeKey}`);
  };

  const tabConfigs = [
    {
      name: "OTA",
      key: ActiveTab.NODES,
      component: <OtaNodeList />,
    },
    {
      name: "FW Management",
      key: ActiveTab.FIRMWARES,
      component: <FwManagement />,
    },
  ];

  return (
    <div>
      <Tabs className="header-tab-bar" activeKey={activeTab} onChange={handleTabChange}>
        {tabConfigs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default OtaDetails;
