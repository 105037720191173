import { NodeSubType, Position, Sensorflow_Locations } from "pacts/app-webcore/hasura-webcore.graphql";

export enum NodeStatus {
  Online = "online",
  Offline = "offline",
}

export const NODE_STATUS_UI_CONFIG = {
  [NodeStatus.Online]: {
    indicatorColor: "success",
  },
  [NodeStatus.Offline]: {
    indicatorColor: "error",
  },
};

export enum UpgradeStatus {
  OK = "OK",
  PENDING = "PENDING",
  FAILED = "FAILED",
  DEPRECATED = "DEPRECATED",
  UNKNOWN = "UNKNOWN",
}

export type Node = {
  nodeMacId: string;
  nodeType: string;
  status: NodeStatus;
  key: Pick<Position, "positionId" | "positionName">;
  room: Pick<Position, "positionId" | "positionName">;
  location: Pick<Sensorflow_Locations, "locationName" | "locationId"> | undefined | null;
  nodeSubType: string;
  targetFwVersion: string;
  currentFwVersion: string;
  failedCount: number;
  firmwareVersionLocked: boolean;
  comment: string;
  upgradeStatus: UpgradeStatus;
  lockable: boolean;
};

export type FloorCeilingValues = {
  floorValue: number;
  ceilingValue: number;
};

export type NodeFilterInput = {
  nodeTypes?: Array<string>;
  statuses?: Array<string>;
  nodeMacId?: "";
  locations?: Array<string>;
  nodeSubType?: string;
  upgradeStatuses?: Array<UpgradeStatus>;
  firmwareVersionExcludeLocked?: boolean;
  targetFwVersionSpecificVersion?: boolean;
  targetFwVersion?: number;
  currentFwVersionSpecificVersion?: boolean;
  currentFwVersion?: number;
};

export const INITIAL_FILTER: NodeFilterInput = {
  nodeMacId: "",
  locations: [],
  firmwareVersionExcludeLocked: false,
  targetFwVersionSpecificVersion: false,
  targetFwVersion: undefined,
  currentFwVersionSpecificVersion: false,
  currentFwVersion: undefined,
  upgradeStatuses: [],
  nodeSubType: NodeSubType.AirconAcir,
};
