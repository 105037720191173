import { useEffect, useState } from "react";

import { printLabel } from "./helper";

const useZPLPrintLabel = (zpl: string) => {
  const [printError, setPrintError] = useState<Error | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (zpl) {
      const printFunc = async () => {
        const error = await printLabel(zpl);
        if (error) setPrintError(error);
        else setIsSuccess(true);
      };

      printFunc();
    }
  }, [zpl]);

  return { success: isSuccess, error: printError };
};

export default useZPLPrintLabel;
