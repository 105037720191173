import { checkEnvVarAsBoolean } from "utils/env";

export const useFeatureFlag = () => {
  return {
    precoolingWithSchduled: checkEnvVarAsBoolean("REACT_APP_PRECOOLING_WITH_SCHDULED_FEATURE_ENABLED", true),
    updateCompressorLocationEnabled: checkEnvVarAsBoolean("REACT_APP_UPDATE_COMPRESSOR_LOCATION_ENABLED", true),
  };
};

export default useFeatureFlag;
