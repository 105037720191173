import antdUseBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const useBreakpoint = () => {
  const screens = antdUseBreakpoint();

  const breakpoints = {
    mobileOnly: screens.xs,
    mobileUp: true,
    tabletOnly: (screens.sm || screens.md) && !screens.lg,
    tabletUp: !screens.xs,
    desktopOny: screens.lg,
    desktopUp: screens.lg,
    mobileAndTabletOnly: !screens.lg,
  };

  const classes = {
    hiddenOnMobile: !breakpoints.desktopUp ? "d-none" : "",
    hiddenOnDesktop: breakpoints.desktopUp ? "d-none" : "",
  };

  return {
    ...breakpoints,
    classes,
  };
};

export default useBreakpoint;
