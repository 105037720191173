import React, { useCallback } from "react";

import "./JobChecklist.scss";
import { CheckCircleFilled } from "@ant-design/icons";
import { InProgressIcon, RouterWifiIcon, TodoListIcon } from "components/Icons";
import { TaskStatus } from "pages/KeyEntryJobChecklist";
import { Button, Col, Divider, Row, Tag, message } from "antd";
import classNames from "classnames";
import { GatewayHealthStatus, useUpdateTaskMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { PresetStatusColorType } from "antd/es/_util/colors";
import startCase from "lodash/startCase";
import capitalize from "lodash/capitalize";
import errorHandler from "errorHandler";
import { getConnectionStatusDisplayText } from "pages/Gateway/GatewayList/GatewayList.util";
import { JobCheckListProps } from "./JobChecklist.d";

export const unitStatusTag = (status: GatewayHealthStatus): { color: PresetStatusColorType; text: string } => {
  switch (status) {
    case GatewayHealthStatus.Online:
    case GatewayHealthStatus.Healthy:
      return {
        color: "success",
        text: capitalize(startCase(status)),
      };

    case GatewayHealthStatus.Offline:
    case GatewayHealthStatus.ErrorProvisioningImage:
    case GatewayHealthStatus.Error:
    case GatewayHealthStatus.LoraError:
      return {
        color: "error",
        text: capitalize(startCase(status)),
      };

    case GatewayHealthStatus.PreparingImage:
    case GatewayHealthStatus.Verifying:
      return {
        color: "warning",
        text: capitalize(startCase(status)),
      };

    default:
      return {
        color: "default",
        text: capitalize(startCase(GatewayHealthStatus.NotBooted)),
      };
  }
};

const JobChecklist: React.FC<JobCheckListProps> = ({ isInInstallation, data, reloadKey }: JobCheckListProps) => {
  const statusIcon = useCallback((status?: string | null) => {
    switch (status) {
      case TaskStatus.DONE:
        return <CheckCircleFilled className="text-green task-status" />;
      case TaskStatus.IN_PROGRESS:
        return <InProgressIcon className="task-status" />;
      default:
        return <TodoListIcon className="task-status" />;
    }
  }, []);

  const [updateStatus] = useUpdateTaskMutation({
    onCompleted: () => message.success("Update task status completed."),
    onError: errorHandler.handleError,
  });

  const handleUpdateTaskStatus = useCallback(
    async (taskId: string, currentStatus?: string | null) => {
      await updateStatus({
        variables: {
          taskId,
          update: {
            status: currentStatus === TaskStatus.DONE ? TaskStatus.PENDING : TaskStatus.DONE,
          },
        },
      });
      await reloadKey();
    },
    [updateStatus, reloadKey]
  );

  const tasks = useCallback(
    (taskList: JobCheckListProps["data"]) => {
      const groupTask = taskList.map((task) => ({ task, count: 1 }));
      return groupTask.map(({ count, task }) => {
        const healthStatus = unitStatusTag(task.healthStatus || GatewayHealthStatus.Offline);
        const kittingStatus = unitStatusTag(task.kittingStatus || GatewayHealthStatus.NotBooted);

        return (
          <div
            key={task.keyId}
            className={classNames("room-content", {
              "in-installation-mode": isInInstallation,
              "p-s": isInInstallation,
              border: isInInstallation,
              "radius-xs": isInInstallation,
            })}
          >
            <div className="task-card">
              <Row align="middle" gutter={[16, 16]}>
                <Col span={4} className="task-type-icon">
                  <RouterWifiIcon className="icon-router-wifi" />
                </Col>
                <Col span={20} className="title">
                  <Row justify="space-between" align={isInInstallation ? "top" : "middle"}>
                    <Col span={20}>
                      <span className="vertical-align-center">{task.title}</span>
                      {isInInstallation && (
                        <>
                          <div className="d-d-inline-flex justify-content-between mt-xs">
                            <Tag color={healthStatus.color} className="radius-xs p-xs">
                              {healthStatus.text}
                            </Tag>
                            <Tag color={kittingStatus.color} className="radius-xs p-xs">
                              {kittingStatus.text}
                            </Tag>
                          </div>
                          {task.gatewayHealthData?.length && (
                            <div className="mt-xs">
                              <span className="d-d-inline-flex">
                                Wifi health strength:{" "}
                                {getConnectionStatusDisplayText({
                                  gatewayHealthData: task.gatewayHealthData,
                                  networkConnectionStatus: task.networkConnectionStatus,
                                })}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                    <Col span={4} className="text-right icon">
                      {isInInstallation ? (
                        <Button
                          className="gateway-checked-button"
                          onClick={() => handleUpdateTaskStatus(task.taskId, task.status)}
                        >
                          {statusIcon(task.status)}
                        </Button>
                      ) : (
                        <p>x{count}</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        );
      });
    },
    [isInInstallation, statusIcon, handleUpdateTaskStatus]
  );

  return (
    <>
      {isInInstallation ? <Divider className="mt-xs" /> : <h2 className="font-weight-bold text-primary">Work info</h2>}
      {tasks(data)}
    </>
  );
};

export default JobChecklist;
