import { SmartAllocReportType } from "../constants";

export type ChartData = {
  [SmartAllocReportType.MONTHLY]: number[];
  [SmartAllocReportType.WEEKLY]: number[];
};

export type ChartDataObj = {
  [SmartAllocReportType.MONTHLY]: Record<string, number>;
  [SmartAllocReportType.WEEKLY]: Record<string, number>;
};

export type SmartAllocReport = {
  averageOccupancy: number;
  followedRecommendationRatio: number;
  ninetyAdherenceSavingKwh: number;
  ninetyAdherenceSavingSgd: number;
  ninetyAdherenceSavingLocalCurrency: number;
  savingsKwh: number;
  savingsSgd: number;
  savingsLocalCurrency: number;
  usingSmartAlloc: ChartData;
  notUsingSmartAlloc: ChartData;
  occupancy: ChartData;
  maximumCompressors: ChartData;
  compressorsInUse: ChartData;
};

export const SGD_UNIT = "SGD";
