import React from "react";
import { Link } from "react-router-dom";

import Table from "components/Table/Table";
import { SettingFilled } from "@ant-design/icons";
import { hasSmartREM } from "utils/locationMetadata";
import { Location } from "./LocationList.d";

const LocationListTable = (props: any) => {
  const COLUMN_CONFIG = [
    {
      title: "Location Name",
      render: (record: Location) => {
        return (
          <div className="d-flex justify-content-between">
            {hasSmartREM(record.locationMetadata?.subscription) ? (
              <>
                <Link to={`locations/${record.id}/keys`}>{record.locationName}</Link>
                <Link to={`locations/${record.id}/configuration`}>
                  <SettingFilled />
                </Link>
              </>
            ) : (
              <span>{record.locationName}</span>
            )}
          </div>
        );
      },
    },
  ];

  return <Table {...props} columns={COLUMN_CONFIG} dataItemName="locations" rowKey="id" />;
};

export default LocationListTable;
