import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Upload, message, Select } from "antd";
import { isFunction } from "lodash";
import {
  CreateRmaRequestInput,
  useCreateRmaRequestMutation,
  useGenerateRmaAssetUploadLinkQuery,
  useGetRmaRootCausesQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useState } from "react";
import errorHandler from "errorHandler";
import { validations } from "utils/validation";
import fileHelper from "utils/file";
import classNames from "classnames";
import { ButtonRMAProps } from "./ButtonRMA.d";

const initialValues = {
  nodeId: "",
  positionId: "",
  comment: "",
  firmwareVersion: "",
  gatewayId: "",
  assets: [],
};

const ButtonRMA = (props: ButtonRMAProps) => {
  const { onClose, nodeMacRecord, gatewayRecord, type = "primary", buttonClasses } = props;
  const [form] = Form.useForm<CreateRmaRequestInput>();

  const [openModal, setOpenModal] = useState(false);

  const [createRMA] = useCreateRmaRequestMutation({
    onError: errorHandler.handleError,
  });
  const { refetch: refetchGenerateRma } = useGenerateRmaAssetUploadLinkQuery({ skip: true });
  const { data: { sf_support_root_causes: rootCauses = [] } = {} } = useGetRmaRootCausesQuery({
    onError: errorHandler.handleError,
  });

  const closeModal = () => {
    setOpenModal(false);
    form.resetFields();
    if (isFunction(onClose)) onClose();
  };

  const handleOnSubmit = async (values: CreateRmaRequestInput) => {
    const result = await createRMA({ variables: { input: values } });
    if (result?.data) {
      closeModal();
      message.success("RMA linked successfully for this device");
    }
  };

  useEffect(() => {
    if (openModal) {
      switch (true) {
        case !!nodeMacRecord:
          form.setFieldsValue({
            nodeId: nodeMacRecord?.nodeMacId,
            positionId: nodeMacRecord?.key.positionId,
          });
          break;
        case !!gatewayRecord:
          form.setFieldsValue({
            gatewayId: gatewayRecord?.gatewayId,
            positionId: gatewayRecord?.locationId,
          });
          break;
        default:
          break;
      }
    }
  }, [nodeMacRecord, gatewayRecord, openModal, form]);

  return (
    <>
      <Button type={type} onClick={() => setOpenModal(true)} className={classNames(buttonClasses)}>
        RMA
      </Button>

      {openModal && (
        <Modal title="RMA Form" centered visible onCancel={closeModal} onOk={form.submit} width={600} okText="submit">
          <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleOnSubmit}>
            {nodeMacRecord?.nodeMacId && (
              <Form.Item name="nodeId" label="Node Mac Id" rules={[validations.required("Node Mac Id is required")]}>
                <Input disabled />
              </Form.Item>
            )}

            {gatewayRecord?.gatewayId && (
              <Form.Item name="gatewayId" label="Gateway ID">
                <Input disabled />
              </Form.Item>
            )}

            <Form.Item name="positionId" label="Position Id" rules={[validations.required("Position Id is required")]}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="rootCauseId"
              label="Root Cause"
              rules={[{ required: true, message: "Root Cause Id is required" }]}
            >
              {/* <InputNumber min={0} style={{ width: "100%" }} /> */}
              <Select>
                {rootCauses.map((value) => (
                  <Select.Option key={value.id} value={value.id}>
                    {value.root_cause}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="firmwareVersion" label="Firmware Version">
              <Input />
            </Form.Item>

            <Form.Item name="comment" label="Comment">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Assets">
              <Form.Item valuePropName="fileList" noStyle>
                <Upload
                  beforeUpload={(_, fileList) => {
                    const assets: CreateRmaRequestInput["assets"] = [];
                    fileList.forEach(async (file) => {
                      const fileNameSplit = file.name.split(".");
                      const extension = fileNameSplit[fileNameSplit.length - 1];
                      const result = await refetchGenerateRma({ extension, type: file.type });
                      const { url, assetUri } = result.data.generateRMAAssetUploadLink;
                      fileHelper.uploadToS3(url, file);
                      assets.push({ assetUri, type: file.type });
                    });
                    form.setFieldsValue({ assets });

                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Form.Item>

            <Form.Item name="assets" hidden />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ButtonRMA;
