import { Button, Empty, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { take } from "lodash";
import {
  GetAllActivityLogsEventStreamQuery,
  useGetAllActivityLogsEventStreamLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import useLocationDateTime from "hooks/use-location-datetime";
import Loading from "components/Loading";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { applyFilters, getLogsFromEventsStream } from "./ActivityLog.helper";
import { ActionFilterInput, ActivityLog, Room } from "./ActivityLog.d";

const PAGE_SIZE = 10;

const RoomComponent = (props: {
  room: Room;
  filter: any;
  actionFilter?: ActionFilterInput;
  onLogsChange(positionId: string, logsData: ActivityLog[]): void;
  onLoadingComplete(positionId: string): void;
}) => {
  const { room, filter, actionFilter, onLogsChange, onLoadingComplete } = props;
  const [roomPageSize, setRoomPageSize] = useState<number>(PAGE_SIZE);
  const [eventStreams, setEventStreams] = useState<
    GetAllActivityLogsEventStreamQuery["sensorflow_f_get_all_event_stream"]
  >([]);
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const { formatTime24h, formatTimezone, formatDate } = useLocationDateTime();
  const { timezone } = useSensorflowLocation();

  const [temporaryActivityLogsEventStreamQuery, { loading: temporaryActivityLogsLoading }] =
    useGetAllActivityLogsEventStreamLazyQuery({
      variables: {
        positionId: room.positionId,
        fromDate: filter.fromDate,
        toDate: filter.toDate,
      },
      onCompleted: (data) => {
        setEventStreams(data.sensorflow_f_get_all_event_stream);
        onLoadingComplete(room.positionId);
      },
    });

  useEffect(() => {
    temporaryActivityLogsEventStreamQuery();
    setLogs(applyFilters(getLogsFromEventsStream(eventStreams, timezone || ""), actionFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, eventStreams, actionFilter, temporaryActivityLogsEventStreamQuery]);

  useEffect(() => {
    onLogsChange(room.positionId, logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  const getTimelineItem = (log: ActivityLog) => {
    return (
      <Timeline.Item
        key={log.id}
        label={
          <span className="text-gray">
            {formatDate(log.time)} <strong>{formatTime24h(log.time)}</strong>{" "}
            <span className="font-italic">{`GMT${formatTimezone(log.time)}`}</span>
          </span>
        }
      >
        <p>{log.message}</p>
        {log.user && <span className="text-gray">{log.user}</span>}
      </Timeline.Item>
    );
  };

  const loadMoreEvents = () => {
    setRoomPageSize(roomPageSize + PAGE_SIZE);
  };

  const getRoomLogs = () => {
    return take(logs, roomPageSize);
  };

  const hasMoreLogs = () => {
    return roomPageSize < logs.length;
  };

  return (
    <div className="p-xl text-center">
      {temporaryActivityLogsLoading ? (
        <Loading />
      ) : (
        <>
          {logs.length > 0 ? (
            <Timeline mode="left" pending pendingDot={<></>}>
              {getRoomLogs().map((log) => getTimelineItem(log))}
            </Timeline>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {hasMoreLogs() && (
            <Button type="primary" onClick={() => loadMoreEvents()}>
              Load Older Events
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default RoomComponent;
