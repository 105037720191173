import { Button, Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CloseCircleIcon } from "components/Icons";
import { useDeactivateComfortPlusMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback, useState } from "react";
import { Key } from "../KeyList.d";

export interface DeactivateComfortPlusFormProps {
  reservation: Key["reservation"];
  onClose: (state: boolean) => void;
  open?: boolean;
}

export const DeactivateComfortPlusForm: React.FC<DeactivateComfortPlusFormProps> = ({ open, reservation, onClose }) => {
  const [comment, setComment] = useState<string>("");

  const [deactivateComfortPlus] = useDeactivateComfortPlusMutation({
    onCompleted: () => {
      message.success("ComfortPlus deactivated successfully.");
      onClose(true);
    },
  });

  const handleDeactivateComfortPlus = useCallback(async () => {
    if (comment) {
      await deactivateComfortPlus({
        variables: {
          reservationId: reservation[0].id,
          comment,
        },
      });
    } else {
      message.error("Please leave a reason before deactivating ComfortPlus.");
    }
    setComment("");
    onClose(true);
  }, [comment, deactivateComfortPlus, onClose, reservation]);

  return (
    <Modal
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      open={open}
      className="comfortPlusConfirmModal"
      closable={false}
    >
      <div className="deleteGuestConfirmModalIcon mb-m">
        <CloseCircleIcon />
      </div>
      <div className="deleteGuestComfirmModalContent">
        <div className="title mb-s">
          <b>Are you sure to deactivate ComfortPlus?</b>
        </div>
        <div className="content">
          The ComfortPlus link for this key will be revoked. Please give the reason why you want to deactivate.
        </div>
        <div className="mt-m">
          <TextArea
            data-testid="text-box-deactivate-comfortPlus"
            rows={4}
            className="w-100 border-light-gray radius-xs"
            onChange={(event) => setComment(event.target.value)}
          />
        </div>
        <div className="mt-m content">
          <Button type="ghost" onClick={() => onClose(true)}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="ml-m deleteGuestRemoveButton"
            disabled={comment?.length <= 0}
            onClick={handleDeactivateComfortPlus}
          >
            Deactivate
          </Button>
        </div>
      </div>
    </Modal>
  );
};
