import { TimePicker, Form } from "antd";
import React from "react";
import { LocationOtaSettingFormField } from "./helpers";

const LocationOtaSettingTimeWindowInput = ({
  onChange,
  value,
  ...rest
}: Parameters<typeof TimePicker.RangePicker>[0]) => {
  const [isNextDay, setIsNextDay] = React.useState<boolean>(value?.[0]?.isSameOrAfter(value?.[1]) || false);
  const onTimeWindowChange = React.useCallback<NonNullable<typeof onChange>>(
    (values, formatString) => {
      if (values?.[0]?.isSameOrAfter(values?.[1])) {
        setIsNextDay(true);
      } else {
        setIsNextDay(false);
      }
      onChange?.(values, formatString);
    },
    [onChange]
  );
  return (
    <>
      <Form.Item name={LocationOtaSettingFormField.OTA_TIME_WINDOW} noStyle>
        <TimePicker.RangePicker {...rest} onChange={onTimeWindowChange} />
      </Form.Item>
      {isNextDay ? <span className="mx-s text-warning">Next day</span> : null}
    </>
  );
};

export default LocationOtaSettingTimeWindowInput;
