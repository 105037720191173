import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, message, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import merge from "lodash/merge";
import { useGatewaysCreateMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import classnames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import "./NameGatewaysPage.scss";
// eslint-disable-next-line import/no-cycle
import GatewayCollapse from "../GatewayCollapse/GatewayCollapse";
import NameGatewaysPageDetail from "../NameGatewaysPageDetail/NameGatewaysPageDetail";
import { GatewaySetting } from "../NameGatewaysPageDetail/NameGatewaysPageDetail.d";
import errorHandler from "../../../errorHandler";

export const getGatewayNameFromGatewayAndPrefix = (gatewayName: string, prefix: string, index: number) => {
  let parsedGatewayName = gatewayName;

  if (!parsedGatewayName) {
    parsedGatewayName = `Gateway-${index + 1}`;
  }
  if (!prefix) return parsedGatewayName;
  return `${prefix}-${parsedGatewayName}`;
};

type NameGatewaysPageProps = {
  goBackToPreviousStep: () => void;
};

const NameGatewaysPage = ({ goBackToPreviousStep }: NameGatewaysPageProps) => {
  const history = useHistory();
  const { locationId } = useParams<{ locationId: string }>();
  const [gatewaySetting, setGatewaySetting] = useState<GatewaySetting>({
    defaultPrefix: "ASST",
    quantity: 1,
    gateways: [
      {
        gatewayId: "1",
        locationId,
      },
    ],
  });
  const [isInvalidData, setInvalidData] = useState<boolean>(true);

  const screen = useBreakpoint();

  const [createGateways] = useGatewaysCreateMutation({
    onCompleted: () => {
      message.success("Created gateways.");
      history.push(`/locations/${locationId}/gateways`);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleClickCreateGateways = () => {
    if (gatewaySetting.gateways.length === 0) {
      message.error("Please specify at least 1 gateway!");
      return;
    }

    if (gatewaySetting.gateways.filter((gateway) => !gateway.gatewayName).length > 0) {
      message.error("Please specify names for all gateways!");
      return;
    }

    const inputGateways = gatewaySetting.gateways.map((gateway) => ({
      gatewayName: getGatewayNameFromGatewayAndPrefix(gateway.gatewayName || "", gatewaySetting.prefix || "", 0),
      locationId,
    }));

    createGateways({
      variables: {
        gateways: inputGateways,
      },
    });
  };

  const handleClickRemoveGateway = (index: number) => {
    setGatewaySetting((current) => ({
      ...current,
      gateways: current.gateways.filter((gateway, gatewayIndex) => gatewayIndex !== index),
    }));
  };

  const onGatewaySettingChange = (newGatewaySetting: GatewaySetting) => {
    setGatewaySetting((current) => ({ ...merge(current, newGatewaySetting) }));
  };

  const handleClickAddGateway = () => {
    setGatewaySetting((current) => {
      const newQuantity = (current.quantity || 0) + 1;

      return {
        ...current,
        quantity: newQuantity,
        gateways: [
          ...current.gateways,
          {
            gatewayId: String(newQuantity),
            locationId,
          },
        ],
      };
    });
  };

  return (
    <div>
      <Row className="create-gateway-section">
        {screen.desktopUp && (
          <Col span={8} className="left-section" data-testid="gateway-collapse-menu">
            <GatewayCollapse
              gatewaySetting={gatewaySetting}
              handleClickAddGateway={handleClickAddGateway}
              handleClickRemoveGateway={handleClickRemoveGateway}
            />
          </Col>
        )}
        <Col
          id="gateway-list"
          xs={{ span: 24 }}
          lg={{ span: 16 }}
          className="right-section"
          data-testid="name-gateways-content"
        >
          <NameGatewaysPageDetail
            gatewaySetting={gatewaySetting}
            onGatewaySettingChange={onGatewaySettingChange}
            disableSubmit={setInvalidData}
          />
        </Col>
      </Row>
      <Row className="footer-section">
        <Button
          className={classnames("navigation-button-edit-wifi-credentials", {
            "button-mobile": screen.mobileAndTabletOnly,
          })}
          onClick={goBackToPreviousStep}
        >
          <LeftOutlined /> Edit WiFi Credentials
        </Button>
        <Button
          type="primary"
          className={classnames("navigation-button-create-gateways", {
            "button-mobile": screen.mobileAndTabletOnly,
          })}
          onClick={handleClickCreateGateways}
          disabled={isInvalidData}
        >
          Create Gateways <RightOutlined />
        </Button>
      </Row>
    </div>
  );
};

export default NameGatewaysPage;
