/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
export enum NodeMappingError {
  NodeAlreadyMappedError = "NODE_ALREADY_MAPPED_ERROR",
  NodeMappingError = "NODE_MAPPING_ERROR",
  NodeNotMappedError = "NODE_NOT_MAPPED_ERROR",
  NodeNotMappedToThisSlotError = "NODE_NOT_MAPPED_TO_THIS_SLOT_ERROR",
  NodeNotJigTestedError = "NODE_NOT_JIG_TESTED_ERROR",
  NodeUnmappingError = "NODE_UNMAPPING_ERROR",
  SlotAlreadyMappedError = "SLOT_ALREADY_MAPPED_ERROR",
  SlotNotMappedError = "SLOT_NOT_MAPPED_ERROR",
  ServerError = "INTERNAL_SERVER_ERROR",
}
