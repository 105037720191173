import React from "react";
import { Slot } from "pages/Key/KeyDetail/KeyDetail.d";
import "./ContractorNodeListTable.scss";
// eslint-disable-next-line import/no-cycle
import ContractorNodeComponent from "./ContractorNodeComponent";
import { NodeHealth } from "./ContractorNodeList.d";

type ContractorNodeListProps = {
  roomId: string;
  roomName: string;
  keyName: string;
  nodes: Slot[];
  nodeHealthList: NodeHealth[];
  reloadNodes: () => void;
};

const ContractorNodeListTable = (props: ContractorNodeListProps) => {
  const { roomId, roomName, nodes, nodeHealthList, keyName, reloadNodes } = props;

  const getNodeHealth = (slotId?: string): NodeHealth => {
    return nodeHealthList.find((node) => node.slotId === slotId) as NodeHealth;
  };

  return (
    <>
      {nodes &&
        nodes?.map((node, index) => (
          <ContractorNodeComponent
            key={index}
            roomId={roomId}
            roomName={roomName}
            keyName={keyName}
            nodeHealth={getNodeHealth(node.id)}
            node={node}
            reloadNodes={reloadNodes}
          />
        ))}
    </>
  );
};

export default ContractorNodeListTable;
