import React from "react";
import { EcologyIcon } from "components/Icons";
import { NoteCardProps } from "./NoteCard.d";
import "./NoteCard.scss";

const NoteCard: React.FC<NoteCardProps> = ({ className, header, items }) => {
  return (
    <div className={`note w-100 word-wrap-break ${className}`}>
      <div className="overlap-container d-flex">
        <div className="floating-icon">
          <div className="earth-bg bg-white d-flex">
            <EcologyIcon />
          </div>
        </div>
      </div>
      <div className="card-title font-weight-semi-bold fs-l">{header}</div>
      <ul>
        {items.map((i) => (
          <li key={i} className="font-weight-normal fs-m word-wrap-break">
            {i}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NoteCard;
