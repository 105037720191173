import React from "react";

import { Alert, Divider, Spin } from "antd";
import { ApolloError } from "apollo-client";

import logger from "services/logger/logger";
import SidebarStatusItem from "../SidebarStatusItem/SidebarStatusItem";
import { SidebarStatusItemData } from "../SidebarStatusItem/SidebarStatusItem.d";

import "./OverallStatusSidebarSection.scss";

interface OverallStatusSidebarSectionProps {
  title: string;
  sidebarStatusItems: SidebarStatusItemData[];
  loading: boolean;
  showAsHealthStatus?: boolean;
  error: ApolloError | undefined;
}

const OverallStatusSidebarSection = ({
  title,
  sidebarStatusItems,
  loading,
  error,
  showAsHealthStatus = false,
}: OverallStatusSidebarSectionProps) => {
  if (error) {
    logger.error(error);
  }

  const renderBody = () => {
    if (error) {
      return <Alert type="error" message="Error getting data" description={error.message} />;
    }

    if (loading) {
      return <Spin className="overall-status-section-loading" data-testid="overall-status-section-loading" />;
    }

    if (sidebarStatusItems.length === 0) {
      return <span className="overall-status-section-no-data-text">No data</span>;
    }

    return (
      <>
        {sidebarStatusItems.map((item, index: number) => (
          <SidebarStatusItem {...item} key={index} showAsHealthStatus={showAsHealthStatus} />
        ))}
      </>
    );
  };

  return (
    <div className="overall-status-sidebar-container">
      <div className="overall-status-sidebar-header">
        <span className="sidebar-block-title">{title}</span>
      </div>
      <Divider className="overall-status-header-divider" />
      {renderBody()}
    </div>
  );
};

export default OverallStatusSidebarSection;
