import { ClockCircleOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React from "react";
import { BookingStatus, BOOKING_STATUS_LABEL } from "../constants";

interface IBookingStatusIconProps {
  status?: string;
  screen?: { mobileOnly?: boolean };
}

const BookingStatusIcon = ({ status, screen }: IBookingStatusIconProps) => {
  switch (status) {
    case BookingStatus.CHECKED_IN:
      return (
        <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>
          <DownloadOutlined className="mr-xs" style={{ color: "#5BC359" }} />
          {BOOKING_STATUS_LABEL[status]}
        </span>
      );
    case BookingStatus.CHECKED_OUT:
      return (
        <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>
          <UploadOutlined className="mr-xs" style={{ color: "#E8472E" }} />
          {BOOKING_STATUS_LABEL[status]}
        </span>
      );
    case BookingStatus.AWAIT_CHECKING_IN:
      return (
        <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>
          <ClockCircleOutlined className="mr-xs" style={{ color: "#FF8200" }} />
          {BOOKING_STATUS_LABEL[status]}
        </span>
      );
    default:
      return <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>-</span>;
  }
};

export default BookingStatusIcon;
