import { Spin } from "antd";
import React from "react";

export const tableRenderLoadingRow = (index: number, pageSize: number) => {
  if (index % pageSize === 0) {
    return {
      children: <Spin style={{ width: "100%" }} />,
      props: {
        rowSpan: pageSize,
      },
    };
  }
  return {
    props: {
      rowSpan: 0,
    },
  };
};

export default tableRenderLoadingRow;
