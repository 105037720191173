import React, { useMemo } from "react";

import {
  useExportClickupProressSubscriptionSubscription,
  useLocationKeyStatsQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import useRoleAndPermission from "hooks/use-role-and-permission";
import OverallStatusSidebarSection from "../OverallStatusSidebarSection/OverallStatusSidebarSection";
import { SidebarStatusItemData } from "../SidebarStatusItem/SidebarStatusItem.d";

const OverallStatusSidebar = ({ locationId }: { locationId: string }) => {
  const roleAndPermission = useRoleAndPermission();

  const { data, loading: loadingKeys, error: errorKeys } = useLocationKeyStatsQuery({ variables: { locationId } });
  const {
    data: dataExport,
    loading: loadingExport,
    error: errorExport,
  } = useExportClickupProressSubscriptionSubscription({ variables: { locationId } });

  const loading = useMemo(() => loadingKeys || loadingExport, [loadingKeys, loadingExport]);
  const error = useMemo(() => errorKeys || errorExport, [errorKeys, errorExport]);

  const keysStats = data?.location?.locationStats.keysStats;
  const exportToClickUp = dataExport?.locationOne;

  const keyStatusItems = useMemo(() => {
    const items: SidebarStatusItemData[] = [];
    if (keysStats) {
      items.push({
        name: "Keys",
        total: keysStats?.totalCount || 0,
        current: keysStats?.mappedCount || 0,
        missing: 0,
      });
    }

    if (exportToClickUp?.clickupListId && roleAndPermission.isPC()) {
      items.push({
        name: "Export to Clickup",
        total: 100,
        current: parseFloat(Number((exportToClickUp?.clickupExportProgress || 0) * 100).toFixed(1)),
        missing: 0,
        showWithPercentSign: true,
      });
    }

    return items;
  }, [keysStats, exportToClickUp, roleAndPermission]);

  const nodeOverallStats = data?.location?.locationStats.nodeOverallStats || [];

  const nodeOverallStatusItems: SidebarStatusItemData[] = nodeOverallStats.map(
    (i) =>
      ({
        name: getNodeTypeDisplayValue(i.nodeType),
        total: i.mappingStats?.totalCount,
        current: i.mappingStats?.mappedCount,
        missing: 0,
      } as SidebarStatusItemData)
  );

  return (
    <>
      <OverallStatusSidebarSection
        title="Overall Status"
        sidebarStatusItems={keyStatusItems}
        loading={loading}
        error={error}
      />
      <OverallStatusSidebarSection
        title="Node Status"
        sidebarStatusItems={nodeOverallStatusItems}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default OverallStatusSidebar;
