import { sortKey } from "../../../testUtils";
import categories from "./keyCategories";

const keys = (locationName?: string) => {
  const categoryNames: string[] = [];
  categories.forEach((category) => {
    if (category.locationName === locationName) {
      categoryNames.push(category.categoryName);
    }
  });
  const categoryCount = categoryNames.length;

  const sampleKeys = [
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d98",
      keyName: "Key 1",
      categoryName: categoryNames[0 % categoryCount],
      keyStats: {
        mappingStatus: "COMPLETED",
        nodesStats: {
          mappedCount: 100,
          totalCount: 100,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 7,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d01",
      keyName: "Ascott 1105",
      categoryName: categoryNames[1 % categoryCount],
      keyStats: {
        mappingStatus: "COMPLETED",
        nodesStats: {
          mappedCount: 60,
          totalCount: 60,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 5,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d02",
      keyName: "Ascott 1108",
      categoryName: categoryNames[2 % categoryCount],
      keyStats: {
        mappingStatus: "COMPLETED",
        nodesStats: {
          mappedCount: 43,
          totalCount: 43,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 6,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d03",
      keyName: "1000",
      categoryName: categoryNames[3 % categoryCount],
      keyStats: {
        mappingStatus: "COMPLETED",
        nodesStats: {
          mappedCount: 113,
          totalCount: 113,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 10,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 113,
          onlineCount: 110,
          offlineCount: 1,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d04",
      keyName: "1001",
      categoryName: categoryNames[4 % categoryCount],
      keyStats: {
        mappingStatus: "COMPLETED",
        nodesStats: {
          mappedCount: 128,
          totalCount: 128,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 9,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d05",
      keyName: "D29",
      categoryName: categoryNames[5 % categoryCount],
      keyStats: {
        mappingStatus: "IN_PROGRESS",
        nodesStats: {
          mappedCount: 18,
          totalCount: 27,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 7,
          totalCount: 16,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d06",
      keyName: "B117",
      categoryName: categoryNames[6 % categoryCount],
      keyStats: {
        mappingStatus: "IN_PROGRESS",
        nodesStats: {
          mappedCount: 18,
          totalCount: 27,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 6,
          totalCount: 15,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d07",
      keyName: "A1602",
      categoryName: categoryNames[7 % categoryCount],
      keyStats: {
        mappingStatus: "IN_PROGRESS",
        nodesStats: {
          mappedCount: 11,
          totalCount: 24,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 5,
          totalCount: 18,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d08",
      keyName: "207",
      categoryName: categoryNames[8 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 12,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d09",
      keyName: "307",
      categoryName: categoryNames[9 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 11,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d10",
      keyName: "606",
      categoryName: categoryNames[10 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 17,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d11",
      keyName: "206",
      categoryName: categoryNames[11 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d12",
      keyName: "504",
      categoryName: categoryNames[12 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 19,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d13",
      keyName: "A508",
      categoryName: categoryNames[13 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 11,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d14",
      keyName: "1406",
      categoryName: categoryNames[14 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 12,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d15",
      keyName: "707",
      categoryName: categoryNames[15 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d16",
      keyName: "2826",
      categoryName: categoryNames[16 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 19,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d17",
      keyName: "A1112",
      categoryName: categoryNames[17 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 14,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d18",
      keyName: "A1008",
      categoryName: categoryNames[18 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 16,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d19",
      keyName: "2207",
      categoryName: categoryNames[19 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 12,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d20",
      keyName: "703",
      categoryName: categoryNames[20 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 14,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d21",
      keyName: "Test Room",
      categoryName: categoryNames[21 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 14,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d22",
      keyName: "106",
      categoryName: categoryNames[22 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 18,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d23",
      keyName: "505",
      categoryName: categoryNames[23 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 16,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d24",
      keyName: "Iso 9",
      categoryName: categoryNames[24 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 15,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d25",
      keyName: "A301",
      categoryName: categoryNames[25 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d26",
      keyName: "Iso 8",
      categoryName: categoryNames[26 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d27",
      keyName: "503",
      categoryName: categoryNames[27 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 15,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d28",
      keyName: "Iso 3",
      categoryName: categoryNames[28 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 13,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d29",
      keyName: "Iso 4",
      categoryName: categoryNames[29 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 19,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d30",
      keyName: "Iso 1",
      categoryName: categoryNames[30 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 18,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d31",
      keyName: "Iso 2",
      categoryName: categoryNames[31 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d32",
      keyName: "402",
      categoryName: categoryNames[32 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 13,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d33",
      keyName: "5202",
      categoryName: categoryNames[33 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 14,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d34",
      keyName: "4512",
      categoryName: categoryNames[34 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 11,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d35",
      keyName: "509",
      categoryName: categoryNames[35 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d36",
      keyName: "309",
      categoryName: categoryNames[36 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 13,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d37",
      keyName: "303",
      categoryName: categoryNames[37 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 13,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d38",
      keyName: "713",
      categoryName: categoryNames[38 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 14,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d39",
      keyName: "1106",
      categoryName: categoryNames[39 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 15,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d40",
      keyName: "5304",
      categoryName: categoryNames[40 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d41",
      keyName: "807",
      categoryName: categoryNames[41 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d42",
      keyName: "2101",
      categoryName: categoryNames[42 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 11,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d43",
      keyName: "1105",
      categoryName: categoryNames[43 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 7,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d44",
      keyName: "A1510",
      categoryName: categoryNames[44 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 10,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d45",
      keyName: "422",
      categoryName: categoryNames[45 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d46",
      keyName: "1503",
      categoryName: categoryNames[46 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 17,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 5,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d47",
      keyName: "1022",
      categoryName: categoryNames[47 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 20,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 6,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d48",
      keyName: "1606",
      categoryName: categoryNames[48 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 17,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 9,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
    {
      keyId: "333010e2-9fc9-4b15-9902-c6d937f61d49",
      keyName: "A708",
      categoryName: categoryNames[49 % categoryCount],
      keyStats: {
        mappingStatus: "NOT_STARTED",
        nodesStats: {
          mappedCount: 0,
          totalCount: 13,
          __typename: "MappingStats",
        },
        roomsStats: {
          mappedCount: 0,
          totalCount: 8,
          __typename: "MappingStats",
        },
        nodeHealthStats: {
          totalCount: 18,
          onlineCount: 1,
          offlineCount: 15,
          bootedCount: 2,
          __typename: "NodeHealthStats",
        },
        __typename: "KeyMappingStats",
      },
      __typename: "Key",
    },
  ];

  return sortKey(sampleKeys, { keyName: "ASC" }) as typeof sampleKeys;
};

export default keys;
