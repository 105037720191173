import React from "react";

import classnames from "classnames";
import { Input } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import "./KeyListInput.scss";

const { TextArea } = Input;

type KeyListInputProps = {
  categoryName: string;
  hidden: boolean;
};

const KeyListInput = React.forwardRef<TextAreaRef, KeyListInputProps>(
  ({ categoryName, hidden }: KeyListInputProps, ref) => {
    return (
      <div className={classnames("keylist-input", { hidden })}>
        <p className="text">
          Assign Keys to <span>{categoryName}</span>
        </p>
        <p className="sub-text">Type each Key’s Name on a new line.</p>
        <TextArea rows={20} placeholder={"201\n202\n301\n302"} ref={ref} />
      </div>
    );
  }
);

export default KeyListInput;
