import React from "react";
import { COMMAS_FORMAT, formatNumber } from "utils/number";
import { Units } from "./UnitSelect";

type Props = {
  unit: Units;
  value: {
    dollar?: number;
    localCurrency?: number;
    kWh?: number;
  };
  localCurrency: string;
};

const EnergySavingValue = (props: Props) => {
  const { unit, value, localCurrency } = props;
  switch (unit) {
    case Units.kWh:
      return (
        <>
          <span className="fs-h1 font-weight-bold font-heading">{formatNumber(value.kWh)}</span>
          <span className="fs-xl">kWh</span>
        </>
      );
    case Units.dollar:
      return (
        <>
          <span className="fs-h3 font-weight-bold font-heading">$</span>
          <span className="fs-h1 font-weight-bold font-heading">{formatNumber(value.dollar, COMMAS_FORMAT)}</span>
        </>
      );
    case Units.localCurrency:
      return (
        <>
          <span className="fs-xl">{localCurrency}&nbsp;</span>
          <span className="fs-h1 font-weight-bold font-heading">
            {formatNumber(value.localCurrency, COMMAS_FORMAT)}
          </span>
        </>
      );
    default:
      return <></>;
  }
};

export default EnergySavingValue;
