import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { MenuContext } from "contexts/menu-context";
import useLocationActions from "pages/Location/LocationActions/LocationActions";

const { SubMenu } = Menu;

const useMenuNavigation = () => {
  const menuContext = useContext(MenuContext);
  const actionsForLocation = useLocationActions();

  const buildLocationMenu = (locations: any[]) => {
    return (
      <Menu mode="inline">
        {locations.map((location: any) => {
          const actions = actionsForLocation(location.id);

          return (
            <SubMenu key={location.id} title={location.locationName}>
              {actions.map((action) => (
                <Menu.Item key={action.name}>
                  <Link to={action.linkTo}>{action.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        })}
      </Menu>
    );
  };

  const setupLocationMenu = (locations: any[]) => {
    if (menuContext) {
      const { setDynamicMenu } = menuContext;
      if (setDynamicMenu) {
        setDynamicMenu(buildLocationMenu(locations));
      }
    }
  };

  return {
    setupLocationMenu,
  };
};

export default useMenuNavigation;
