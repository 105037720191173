/* eslint-disable import/prefer-default-export */
import { GetWorkInfoQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { Gateways, WorkInfo } from "pages/GatewayJobChecklist";
import { TaskStatus } from "pages/KeyEntryJobChecklist";
import { PositionType } from "types/types";

export const bindingWorkInfoData = (data: GetWorkInfoQuery["tasksWithKeys"], gateways?: Gateways[]) => {
  let countJobsNotDone = 0;
  const workInfo: WorkInfo[] = data.map((task) => {
    const gateway = gateways?.find(({ gatewayId }) => gatewayId === task.keyId);
    if (task.status !== TaskStatus.DONE && task.parentEntityType !== PositionType.KEY) {
      countJobsNotDone += 1;
    }
    return { ...task, ...gateway };
  });
  return { countJobsNotDone, workInfo };
};
