import { useState } from "react";

import { useLabelDetailsForKeyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import useZPLPrintLabel from "services/zpl/use-print";
import { createKeyZpl } from "services/zpl/helper";
import { KeyDetailsForLabelPrinting } from "./use-key-print.d";

const useKeyLabelPrinting = (keyId: string, isKeyFullyMapped: boolean) => {
  const [keyDetails, setKeyDetails] = useState<KeyDetailsForLabelPrinting>();
  const { loading, error } = useLabelDetailsForKeyQuery({
    variables: {
      keyId,
    },
    skip: !isKeyFullyMapped,
    onCompleted: (data) => {
      if (data) setKeyDetails(data.key);
    },
  });

  const { error: printKeyError } = useZPLPrintLabel(createKeyZpl(keyDetails));

  return { loading, error, printError: printKeyError, details: keyDetails };
};

export default useKeyLabelPrinting;
