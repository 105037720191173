import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface ICsvExportBtnProps {
  onExportTrigger: () => void;
  classNames?: string;
  disabled?: boolean;
}

export const CsvExportBtn = ({ onExportTrigger, classNames, disabled }: ICsvExportBtnProps) => {
  return (
    <Button type="primary" className={classNames} disabled={disabled} onClick={onExportTrigger}>
      <DownloadOutlined className="mr-s" />
      Export
    </Button>
  );
};

export default CsvExportBtn;
