import { EditFilled } from "@ant-design/icons";
import { Breadcrumb, Button, Col, message, Row } from "antd";
import classNames from "classnames";
import ModalWrapper from "components/Modal/Wrapper";
import errorHandler from "errorHandler";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { round } from "lodash";
import get from "lodash/get";
import noop from "lodash/noop";
import {
  TemporaryInfrastructureQuery,
  useInfrastructureWithMeterPositionSubscription,
  useLocationKeysAndRoomsQuery,
  useUpdateChillerMutation,
  useUpdateCompressorMutation,
  useUpdateCoolingTowerMutation,
  useUpdatePipeMutation,
  useUpdatePumpMutation,
  useTemporaryInfrastructureQuery,
  useChangeInfraTypeMutation,
  useInfrastructureEmonPowerStatusSubscription,
  useLocationGroupsAndKeysQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { PositionFunction } from "pages/Checklist/Checklist.d";
import { InstallationModeButton } from "components/InstallationMode";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { HvacSystemTypeEnum, hvacSystemTypes, InfrastructureTypeEnum } from "pages/Infrastructures/Infrastructures.d";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import GetHelpButton from "components/GetHelpButton";
import { getInfraDetailLink } from "utils/link";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { useFeatureFlag } from "hooks/use-feature-flag";
import UpdateCompressorLocationModal from "pages/Infrastructures/UpdateCompressorLocation/UpdateCompressorLocation";
import ChangeInfraDetailModal from "./ChangeInfraDetailModal/ChangeInfraDetailModal";
import ChangeInfraTypeModal from "./ChangeInfraTypeModel/ChangeInfraTypelModal";
import AdjustRoomConnectionModal from "./ConnectedRooms/AdjustRoomConnectionModal/AdjustRoomConnectionModal";
import ConnectedRoomTable from "./ConnectedRooms/ConnectedRoomsTable";
import { PowerData } from "./InfrastructureDetail.d";
import InfrastructureDetailsContext from "./InfrastructureDetails.Context";
import {
  CoolingSystemDataType,
  InfrastructureDetailsDataType,
  InfrastructureDetailType,
  ActiveTab,
} from "./InfrastructureDetails.d";
import "./InfrastructureDetails.scss";
import AddMappingModal from "./MappedNodes/MapNodeModal/MapNodeModal";
import MappedNodes from "./MappedNodes/MappedNodes";

const InfrastructureDetails: React.FC<any> = () => {
  const history = useHistory();
  const { updateCompressorLocationEnabled } = useFeatureFlag();
  const { locationId, infrastructureId } = useParams<{ locationId: string; infrastructureId: string }>();

  const [infrastructureDetails, setInfrastructureDetails] = useState<InfrastructureDetailsDataType>();
  const [keysAndRooms, setKeysAndRooms] = useState<any[]>([]);
  const [isOpenChangeInfraDetailModal, setIsOpenChangeInfraDetailModal] = useState<boolean>(false);
  const [isOpenChangeInfraTypeModal, setIsOpenChangeInfraTypeModal] = useState<boolean>(false);
  const [updateCompressorLocationModalRef, openUpdateCompressorLocationModalRef] = useModalWrapperTrigger();

  const [adjustRoomConnectionModalRef, openAdjustRoomConnectionModal] = useModalWrapperTrigger();
  const [addMappingModalRef, openAddMappingModal] = useModalWrapperTrigger();
  const [coolingSystem, setCoolingSystem] = useState<CoolingSystemDataType>();
  const [hvacSystemType, setHvacSystemType] = useState<HvacSystemTypeEnum>();
  const [ctMultiplier, setCtMultiplier] = useState<number>();
  const locationName = get(useLocation(), "state.locationName", "");
  const [groupsAndKeys, setGroupsAndKeys] = useState<any[]>([]);

  const roleAndPermission = useRoleAndPermission();

  const infrastructureType = new InfrastructureType(infrastructureDetails?.type!);
  const isAddingConnection = infrastructureDetails?.rooms.length === 0;

  const [powerData, setPowerData] = useState<PowerData[]>();

  const { error: loadInfraError, refetch } = useTemporaryInfrastructureQuery({
    variables: {
      id: infrastructureId,
    },
    onCompleted: (data: TemporaryInfrastructureQuery) => {
      setInfrastructureDetails(data.infrastructures?.[0] as InfrastructureDetailsDataType);
    },
  });

  useEffect(() => {
    if (infrastructureDetails?.coolingSystem) setCoolingSystem(infrastructureDetails.coolingSystem);
    if (infrastructureDetails?.hvacSystemType)
      setHvacSystemType(infrastructureDetails.hvacSystemType as HvacSystemTypeEnum);
    if (infrastructureDetails?.ctMultiplier) setCtMultiplier(infrastructureDetails.ctMultiplier);
  }, [infrastructureDetails]);

  const reloadInfrastructure = async () => {
    const data = await refetch();
    setInfrastructureDetails(data.data.infrastructures?.[0] as InfrastructureDetailsDataType);
  };

  useLocationKeysAndRoomsQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => setKeysAndRooms(data.positions),
  });

  const updateInfrastructureCallback = {
    onCompleted: async () => {
      message.success("Change infrastructure detail successful");
      await reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  };

  useInfrastructureEmonPowerStatusSubscription({
    variables: {
      id: infrastructureId,
    },
    onSubscriptionData: (data) => {
      const latestPowerData = data.subscriptionData.data?.infrastructureEmonPowerStatus;
      if (latestPowerData) {
        const latestPowerDataOfAllChannels: PowerData[] = latestPowerData.map((latestPowerDataOfEachChannel) => ({
          channel: latestPowerDataOfEachChannel.channel,
          lastPowerReading: round(latestPowerDataOfEachChannel.lastPowerReading / 230, 3) ?? "NA",
        }));

        if (!infrastructureType.is3Phases()) {
          setPowerData(latestPowerDataOfAllChannels);
          return true;
        }

        const sortedLatestPowerData: PowerData[] = [];
        for (let channel = 1; channel <= 3; channel++) {
          const lastPowerData = latestPowerDataOfAllChannels.filter(
            (powerDataOfEachChannel) => powerDataOfEachChannel.channel === channel
          );

          sortedLatestPowerData.push({
            channel,
            lastPowerReading: lastPowerData[0]?.lastPowerReading ?? "NA",
          });
        }
        setPowerData(sortedLatestPowerData);
      }
    },
  });

  useInfrastructureWithMeterPositionSubscription({
    variables: {
      id: infrastructureId,
    },
    onSubscriptionData: (data) => {
      const nodes = data.subscriptionData.data?.infrastructures?.[0].meterPosition?.nodes;
      if (nodes) {
        setInfrastructureDetails((detail) => {
          if (!detail) {
            return detail;
          }
          return {
            ...detail,
            meterPosition: {
              ...detail?.meterPosition,
              nodes: detail?.meterPosition?.nodes.map((node) => ({
                ...node,
                ...nodes.find((n) => n.nodeMacId === node.nodeMacId),
              })),
            },
          } as InfrastructureDetailsDataType;
        });
      }
    },
  });

  const [updateCompressor] = useUpdateCompressorMutation(updateInfrastructureCallback);

  const [updateChiller] = useUpdateChillerMutation(updateInfrastructureCallback);

  const [updatePipe] = useUpdatePipeMutation(updateInfrastructureCallback);

  const [updatePump] = useUpdatePumpMutation(updateInfrastructureCallback);

  const [updateCoolingTower] = useUpdateCoolingTowerMutation(updateInfrastructureCallback);

  useEffect(() => {
    // open map node modal when passed openMapNode with value is true
    if (get(history, "location.state.openMapNode")) {
      openAddMappingModal();
      history.replace(history.location.pathname, {
        locationName,
      });
    }
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (loadInfraError) {
      errorHandler.handleError(loadInfraError);
      history.push(`/locations/${locationId}/infrastructures`);
    }
  }, [loadInfraError, history, locationId]);

  const onSubmitToUpdateInfra = (id: string, name: string, multiplier?: number, coolingSystemId?: string) => {
    let dataInput: any;
    if (infrastructureType.isCompressor() || infrastructureType.isCoolingSystem()) {
      dataInput = {
        id,
        name,
        object: {
          compressorName: name,
          ctMultiplier: multiplier,
          compressorId: coolingSystemId,
        },
      };
    } else {
      dataInput = {
        id,
        name,
        object: {
          name,
          ctMultiplier: multiplier,
          compressorId: coolingSystemId,
        },
      };
    }

    switch (infrastructureDetails?.type) {
      case InfrastructureDetailType.COMPRESSOR_1_PHASE:
      case InfrastructureDetailType.COMPRESSOR_3_PHASES:
      case InfrastructureDetailType.CHILLED_WATER: {
        updateCompressor({
          variables: dataInput,
        });
        break;
      }
      case InfrastructureDetailType.CHILLER_1_PHASE:
      case InfrastructureDetailType.CHILLER_3_PHASES: {
        updateChiller({
          variables: dataInput,
        });
        break;
      }
      case InfrastructureDetailType.PIPE: {
        updatePipe({
          variables: dataInput,
        });
        break;
      }
      case InfrastructureDetailType.PUMP_1_PHASE:
      case InfrastructureDetailType.PUMP_3_PHASES: {
        updatePump({
          variables: dataInput,
        });
        break;
      }
      case InfrastructureDetailType.COOLING_TOWER_1_PHASE:
      case InfrastructureDetailType.COOLING_TOWER_3_PHASES: {
        updateCoolingTower({
          variables: dataInput,
        });
        break;
      }

      default:
        break;
    }
  };

  const [changeInfraType] = useChangeInfraTypeMutation({
    onCompleted: () => {
      message.success("Change infrastructure type successful");
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  const onSubmitToChangeInfraType = (
    id: string,
    oldType: string,
    newType: string,
    hvacType?: string,
    coolingSystemId?: string
  ) => {
    changeInfraType({
      variables: {
        infraId: id,
        oldType,
        newType,
        hvacSystemType: hvacType,
        coolingSystemId,
      },
    });
  };

  const helpText = useMemo(() => {
    if (locationId && locationName && infrastructureDetails) {
      return `${locationName}: ${infrastructureDetails.name} ${getInfraDetailLink(
        locationId,
        infrastructureDetails.id
      )}`;
    }
  }, [locationId, locationName, infrastructureDetails]);

  const getPositionFunction = () => {
    switch (infrastructureType.type) {
      case InfrastructureTypeEnum.CHILLER:
        return PositionFunction.CHILLER;
      case InfrastructureTypeEnum.COOLING_TOWER:
        return PositionFunction.COOLING_TOWER;
      case InfrastructureTypeEnum.PUMP:
        return PositionFunction.PUMP;
      default:
        return PositionFunction.COMPRESSOR;
    }
  };

  useLocationGroupsAndKeysQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setGroupsAndKeys(data.positions);
    },
  });

  return (
    <InfrastructureDetailsContext.Provider value={infrastructureDetails}>
      <Breadcrumb className="header-bread-crumb">
        <Breadcrumb.Item>
          <Link to="/locations">Location</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/locations/${locationId}`}>{locationName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/locations/${locationId}/infrastructures`}>Infrastructures</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{infrastructureDetails?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="mb-m" align="middle" gutter={[4, 20]}>
        <Col flex="auto">
          <Row justify="start" align="middle" wrap={false}>
            <Col flex="none">
              <h2 className="font-weight-bold mr-s">
                {infrastructureDetails?.name}
                &nbsp;
                <EditFilled className="cursor-pointer" onClick={() => setIsOpenChangeInfraDetailModal(true)} />
              </h2>
            </Col>
          </Row>
        </Col>
        <Col flex="none">
          <Row>
            <Col flex="none" className="mr-s">
              {infrastructureDetails?.id &&
                (infrastructureType.isCompressor() || infrastructureType.isCoolingDevice()) && (
                  <InstallationModeButton
                    keyPositionId={infrastructureDetails.id}
                    currentPositionFunction={getPositionFunction()}
                    redirectUrl={`/locations/${locationId}/infrastructures/${infrastructureId}/${ActiveTab.CHECK_LIST}`}
                    buttonName={{ enter: "Start work", exit: "End Work" }}
                    activeModal
                    showStopWatch
                    automaticTestRun
                  />
                )}
            </Col>
            <Col flex="none">
              <GetHelpButton helpText={helpText} />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="d-flex blocks justify-content-between">
        <div className="compressor-section">
          <Row>
            <Col className="pr-m">
              <span className="title">TYPE</span>
              <h3 className="compressor-type">
                {infrastructureType.getLabel()}
                {(infrastructureType.isCompressor() || infrastructureType.isCoolingDevice()) && (
                  <>
                    &nbsp;
                    <EditFilled className="action" onClick={() => setIsOpenChangeInfraTypeModal(true)} />
                  </>
                )}
              </h3>
            </Col>
            {(infrastructureType.isCompressor() || infrastructureType.isCoolingDevice()) && (
              <Col className="px-m line-between">
                <span className="title">CT MULTIPLIER</span>
                <h3 className="compressor-type">{ctMultiplier ?? 0}</h3>
              </Col>
            )}
            {infrastructureType.isCoolingDevice() && coolingSystem && (
              <Col className="px-m line-between">
                <span className="title">COOLING SYSTEM</span>
                <h3 className="compressor-type">{coolingSystem?.compressorName}</h3>
              </Col>
            )}
            {infrastructureType.isCompressor() && hvacSystemType && (
              <Col className="px-m line-between">
                <span className="title">HVAC SYSTEM TYPE</span>
                <h3 className="compressor-type">{hvacSystemTypes[hvacSystemType]}</h3>
              </Col>
            )}
            {infrastructureType.isCompressor() && roleAndPermission.isPC() && (
              <Col className="px-m line-between">
                <span className="title">COMPRESSOR LOCATION</span>
                <h3 className="compressor-type">
                  {infrastructureDetails?.position?.positionName ?? ""}
                  &nbsp;
                  <EditFilled className="action" onClick={openUpdateCompressorLocationModalRef} />
                </h3>
              </Col>
            )}
          </Row>
        </div>
        {(infrastructureType.isCompressor() || infrastructureType.isCoolingDevice()) && (
          <div
            className={classNames("mb-xl mt-none right border bg-white py-s", {
              "mt-xl": !infrastructureType.is3Phases(),
            })}
          >
            {powerData?.map((data, index) => {
              return (
                <div className="title mx-s" key={data.channel}>
                  <b>Channel {data.channel ?? index + 1}: </b>
                  {data.lastPowerReading}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {!infrastructureType.isCoolingSystem() && (
        <Row justify="space-between" gutter={[16, 16]} className="flex-wrap-reverse">
          {infrastructureType.isCompressor() && (
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 5 }}>
              <div className="infra-details-table connected-rooms-table">
                <Row justify="space-between" className="header-section">
                  <Col>
                    <h3 className="font-weight-bold mb-none">Room Connected ({infrastructureDetails?.rooms.length})</h3>
                  </Col>
                  <Col>
                    <Button className="button-action" onClick={openAdjustRoomConnectionModal}>
                      {isAddingConnection ? "Add" : "Edit"} Connection
                    </Button>
                  </Col>
                </Row>
                <Col>
                  <ConnectedRoomTable dataSource={infrastructureDetails?.rooms!} />
                </Col>
              </div>
            </Col>
          )}

          <Col
            xs={{ span: 24 }}
            md={{ span: infrastructureType.isCompressor() ? 18 : 24 }}
            lg={{ span: infrastructureType.isCompressor() ? 19 : 24 }}
            className={classNames({
              "h-auto": !infrastructureType.isCompressor(),
            })}
          >
            <div className="infra-details-table mapped-nodes-table">
              <Row justify="space-between" className="header-section">
                <Col>
                  <h3 className="font-weight-bold mb-none">
                    Nodes Mapped ({infrastructureDetails?.meterPosition?.nodes.length || 0})
                  </h3>
                </Col>
                <Col>
                  <Button className="button-action" onClick={openAddMappingModal}>
                    Add Mapping
                  </Button>
                </Col>
              </Row>
              <Col>
                <MappedNodes
                  infraId={infrastructureDetails?.id!}
                  dataSource={infrastructureDetails?.meterPosition!}
                  reloadInfrastructure={reloadInfrastructure}
                />
              </Col>
            </div>
          </Col>
        </Row>
      )}

      <ModalWrapper
        ref={adjustRoomConnectionModalRef}
        modal={AdjustRoomConnectionModal}
        props={{
          keysAndRooms: keysAndRooms ?? [],
          connectedRooms: infrastructureDetails?.rooms ?? [],
          reloadInfrastructure,
        }}
      />
      <ModalWrapper ref={addMappingModalRef} modal={AddMappingModal} props={{ reloadInfrastructure }} />

      {isOpenChangeInfraDetailModal && (
        <ChangeInfraDetailModal
          onSubmit={onSubmitToUpdateInfra}
          locationId={locationId}
          objectName={infrastructureDetails?.name!}
          objectCtMultiplier={ctMultiplier}
          objectCoolingSystemId={coolingSystem?.compressorId}
          objectId={infrastructureId}
          hasCtMultiplier={infrastructureType.isCompressor() || infrastructureType.isCoolingDevice()}
          hasCoolingSystem={infrastructureType.isCoolingDevice()}
          close={() => setIsOpenChangeInfraDetailModal(false)}
          onClose={noop}
        />
      )}

      {isOpenChangeInfraTypeModal && (
        <ChangeInfraTypeModal
          onSubmit={onSubmitToChangeInfraType}
          locationId={locationId}
          objectType={infrastructureType}
          objectCoolingSystemId={coolingSystem?.compressorId}
          objectId={infrastructureId}
          objectNumOfPhase={infrastructureType.numbOfPhases}
          objectHvacSystemType={hvacSystemType}
          close={() => setIsOpenChangeInfraTypeModal(false)}
          onClose={noop}
        />
      )}
      {updateCompressorLocationEnabled ? (
        <ModalWrapper
          ref={updateCompressorLocationModalRef}
          modal={UpdateCompressorLocationModal}
          props={{
            groupsAndKeys,
            isOnlyUpdateCompressorPosition: true,
            selectedCompressorIds: [infrastructureDetails?.id],
            onSuccess: reloadInfrastructure,
          }}
        />
      ) : null}
    </InfrastructureDetailsContext.Provider>
  );
};

export default InfrastructureDetails;
