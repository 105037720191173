import React from "react";
import { Button, Col, Row, Typography } from "antd";
import ListFilter from "components/ListFilter/ListFilter";
import Can from "components/Can/Can";
import { PlusOutlined } from "@ant-design/icons";
import { Permission } from "pacts/permission";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { LocationHeaderProps } from "./LocationHeader.d";

const { Title } = Typography;

const LocationHeader: React.FC<LocationHeaderProps> = ({
  statMessage,
  filterOptions,
  onClickAddLocation,
  filter,
  onFilterChange,
}) => {
  const roleAndPermission = useRoleAndPermission();

  return (
    <Row className="location-header mb-l">
      <Col span={roleAndPermission.isContractor() ? "auto" : 24}>
        <Title level={3} className="header-title">
          Locations
        </Title>
        {!roleAndPermission.isContractor() && <p className="header-description">{statMessage}</p>}
      </Col>
      <Col flex="auto">
        <ListFilter
          currentFilter={filter}
          onChange={onFilterChange}
          searchField="locationName"
          searchPlaceholder="Type to search"
          filterOptions={filterOptions}
          hideAdvancedFilter={roleAndPermission.isContractor()}
        />
      </Col>
      <Can
        requiredPermission={Permission.LOCATION_CREATE}
        yes={
          <Col>
            <Button className="add-location" type="primary" onClick={onClickAddLocation}>
              <PlusOutlined />
              <span>Add Location</span>
            </Button>
          </Col>
        }
      />
    </Row>
  );
};
export default LocationHeader;
