import isNil from "lodash/isNil";

export const checkEnvVarAsBoolean = (key: string, defaultValue: boolean) => {
  if (isNil(process.env[key])) {
    return defaultValue;
  }
  return process.env[key] === "true";
};

export default checkEnvVarAsBoolean;
