import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbProps {
  steps: { title: string; link?: string }[];
}

const BreadcrumbV2: React.FC<BreadcrumbProps> = ({ steps }) => {
  return (
    <Breadcrumb className="header-bread-crumb">
      {steps.map((step, index) => {
        return (
          <Breadcrumb.Item key={step.title}>
            {index === steps.length - 1 ? step.title : <Link to={step.link}>{step.title}</Link>}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbV2;
