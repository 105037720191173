import moment, { Moment } from "moment";
import { PositionConfigurationInput } from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import { TIME_24H_FORMAT } from "../../../../utils/date";
import { FormPositionConfiguration, OperationalMode } from "../../types";
import { Room } from "./KeyConfiguration.d";

export const getDateRangeFromTimeRange = (timeFrom?: string | null, timeTo?: string | null) => {
  if (!timeFrom || !timeTo) return null;
  const from = moment(timeFrom, TIME_24H_FORMAT);
  const to = moment(timeTo, TIME_24H_FORMAT);

  return [from, to];
};

export const getTime = (date: Moment | null | undefined) => {
  if (!date) return null;
  return date.format(TIME_24H_FORMAT);
};

export const getCurrentConfig = (roomConfig: Room): FormPositionConfiguration => {
  return {
    automationMode: roomConfig.automationMode,
    minTemp: roomConfig.minTemp,
    maxTemp: roomConfig.maxTemp,
    heatingMinTemp: roomConfig.heatingMinTemp,
    heatingMaxTemp: roomConfig.heatingMaxTemp,
    hideSetpoint: roomConfig.hideSetpoint,
    heatingHideSetpoint: roomConfig.heatingHideSetpoint,
    // Automation hour is reverse of occNightStart and occNightEnd
    automationHours: getDateRangeFromTimeRange(roomConfig.acNightEnd, roomConfig.acNightStart),
    // Active hour is reverse of occNightStart and occNightEnd
    activeHours: getDateRangeFromTimeRange(roomConfig.occNightEnd, roomConfig.occNightStart),
    occTimeoutDayMins: roomConfig.occTimeoutDayMins,
    occTimeoutNightMins: roomConfig.occTimeoutNightMins,
    unoccupiedTemp: roomConfig.unoccupiedTemp,
    heatingUnoccupiedTemp: roomConfig.heatingUnoccupiedTemp,
    freezeProtectionTemp: roomConfig.freezeProtectionTemp,
    actuationTimeoutSeconds: roomConfig.actuationTimeoutSeconds,
    // Operational mode
    actingMode: roomConfig.actingMode,
    operationalMode: roomConfig.operationalMode,
    expiredAt: roomConfig.expiredAt,
    doorSensorTimeoutMins: roomConfig.doorSensorTimeoutMins,
    enableDoorSensor: roomConfig.enableDoorSensor,
    nightTimeSetpointEnabled: roomConfig.nightTimeSetpointEnabled,
    nightTimeSetpointOffset: roomConfig.nightTimeSetpointOffset,
    nightTimeSetpointMaxSetpoint: roomConfig.nightTimeSetpointMaxSetpoint,
    nightTimeSetpointTimeStartEnd: getDateRangeFromTimeRange(
      roomConfig.nightTimeSetpointStart,
      roomConfig.nightTimeSetpointEnd
    ),
    forceOccupancyAutomation: roomConfig.forceOccupancyAutomation,
  };
};

export const convertToPositionConfiguration = (
  positionId: string,
  roomConfig: FormPositionConfiguration
): PositionConfigurationInput => {
  return {
    positionId,
    automationMode: roomConfig.automationMode,
    minTemp: roomConfig.minTemp,
    maxTemp: roomConfig.maxTemp,
    heatingMinTemp: roomConfig.heatingMinTemp,
    heatingMaxTemp: roomConfig.heatingMaxTemp,
    hideSetpoint: roomConfig.hideSetpoint,
    heatingHideSetpoint: roomConfig.heatingHideSetpoint,
    // Reverse value when save automation hours
    acNightStart:
      roomConfig.automationHours && roomConfig.automationHours.length > 1
        ? getTime(roomConfig.automationHours[1])
        : null,
    acNightEnd:
      roomConfig.automationHours && roomConfig.automationHours.length > 1
        ? getTime(roomConfig.automationHours[0])
        : null,
    // Reverse value when save active hours
    occNightStart:
      roomConfig.activeHours && roomConfig.activeHours.length > 1 ? getTime(roomConfig.activeHours[1]) : null,
    occNightEnd:
      roomConfig.activeHours && roomConfig.activeHours.length > 1 ? getTime(roomConfig.activeHours[0]) : null,
    occTimeoutDayMins: roomConfig.occTimeoutDayMins,
    occTimeoutNightMins: roomConfig.occTimeoutNightMins,
    doorSensorTimeoutMins: roomConfig.doorSensorTimeoutMins,
    unoccupiedTemp: roomConfig.unoccupiedTemp,
    heatingUnoccupiedTemp: roomConfig.heatingUnoccupiedTemp,
    freezeProtectionTemp: roomConfig.freezeProtectionTemp,
    actuationTimeoutSeconds: roomConfig.actuationTimeoutSeconds,
    operationalMode: roomConfig.operationalMode || OperationalMode.Cooling,
    expiredAt: roomConfig.expiredAt,
    enableDoorSensor: roomConfig.enableDoorSensor,
    nightTimeSetpointEnabled: roomConfig.nightTimeSetpointEnabled,
    nightTimeSetpointOffset: roomConfig.nightTimeSetpointOffset,
    nightTimeSetpointMaxSetpoint: roomConfig.nightTimeSetpointMaxSetpoint,
    nightTimeSetpointStart:
      roomConfig.nightTimeSetpointTimeStartEnd && roomConfig.nightTimeSetpointTimeStartEnd.length > 1
        ? getTime(roomConfig.nightTimeSetpointTimeStartEnd[0])
        : null,
    nightTimeSetpointEnd:
      roomConfig.nightTimeSetpointTimeStartEnd && roomConfig.nightTimeSetpointTimeStartEnd.length > 1
        ? getTime(roomConfig.nightTimeSetpointTimeStartEnd[1])
        : null,
    forceOccupancyAutomation: roomConfig.forceOccupancyAutomation,
  };
};

export const isDifferentWithDefault = (newValues: any, defaultConfig?: any) => {
  return (
    (newValues?.actuationTimeoutSeconds &&
      newValues?.actuationTimeoutSeconds !== defaultConfig?.actuationTimeoutSeconds) ||
    (newValues?.freezeProtectionTemp && newValues?.freezeProtectionTemp !== defaultConfig?.freezeProtectionTemp) ||
    (newValues?.heatingUnoccupiedTemp && newValues?.heatingUnoccupiedTemp !== defaultConfig?.heatingUnoccupiedTemp) ||
    (newValues?.unoccupiedTemp && newValues?.unoccupiedTemp !== defaultConfig?.unoccupiedTemp) ||
    (newValues?.occTimeoutNightMins && newValues?.occTimeoutNightMins !== defaultConfig?.occTimeoutNightMins) ||
    (newValues?.occTimeoutDayMins && newValues?.occTimeoutDayMins !== defaultConfig?.occTimeoutDayMins) ||
    (newValues?.doorSensorTimeoutMins && newValues?.doorSensorTimeoutMins !== defaultConfig?.doorSensorTimeoutMins) ||
    (newValues?.occNightEnd && newValues?.occNightEnd !== defaultConfig?.occNightEnd) ||
    (newValues?.occNightStart && newValues?.occNightStart !== defaultConfig?.occNightStart) ||
    (newValues?.acNightEnd && newValues?.acNightEnd !== defaultConfig?.acNightEnd) ||
    (newValues?.acNightStart && newValues?.acNightStart !== defaultConfig?.acNightStart) ||
    (newValues?.heatingHideSetpoint !== null &&
      newValues?.heatingHideSetpoint !== undefined &&
      newValues?.heatingHideSetpoint !== defaultConfig?.heatingHideSetpoint) ||
    (newValues?.hideSetpoint !== null &&
      newValues.hideSetpoint !== undefined &&
      newValues.hideSetpoint !== defaultConfig?.hideSetpoint) ||
    (newValues?.heatingMaxTemp && newValues?.heatingMaxTemp !== defaultConfig?.heatingMaxTemp) ||
    (newValues?.heatingMinTemp && newValues?.heatingMinTemp !== defaultConfig?.heatingMinTemp) ||
    (newValues?.maxTemp && newValues?.maxTemp !== defaultConfig?.maxTemp) ||
    (newValues?.minTemp && newValues?.minTemp !== defaultConfig?.minTemp) ||
    (newValues?.automationMode && newValues?.automationMode !== defaultConfig?.automationMode) ||
    (newValues?.operationalMode && newValues?.operationalMode !== defaultConfig?.operationalMode) ||
    (newValues?.enableDoorSensor !== null &&
      newValues?.enableDoorSensor !== undefined &&
      newValues?.enableDoorSensor !== defaultConfig?.enableDoorSensor) ||
    (newValues?.nightTimeSetpointEnabled !== null &&
      newValues?.nightTimeSetpointEnabled !== undefined &&
      newValues?.nightTimeSetpointEnabled !== defaultConfig?.nightTimeSetpointEnabled) ||
    (newValues?.nightTimeSetpointOffset &&
      defaultConfig.nightTimeSetpointOffset !== newValues.nightTimeSetpointOffset) ||
    (newValues?.nightTimeSetpointMaxSetpoint &&
      defaultConfig.nightTimeSetpointMaxSetpoint !== newValues.nightTimeSetpointMaxSetpoint) ||
    (newValues?.nightTimeSetpointStart && defaultConfig.nightTimeSetpointStart !== newValues.nightTimeSetpointStart) ||
    (newValues?.nightTimeSetpointEnd && defaultConfig.nightTimeSetpointEnd !== newValues.nightTimeSetpointEnd) ||
    (newValues?.forceOccupancyAutomation !== null &&
      defaultConfig.forceOccupancyAutomation !== newValues.forceOccupancyAutomation)
  );
};
