import get from "lodash/get";
import { UserWithAuthTokenQuery } from "./pacts/app-webcore/hasura-webcore.graphql";

type Role = NonNullable<UserWithAuthTokenQuery["userWithAuthToken"]>["role"];
type Permission = NonNullable<Role["permissions"]>[number]["permissionKey"];

export const canPerform = (role: Role, requiredPermission: Permission, additionalData?: any) => {
  const staticPermissions = get(role, "permissions.static", []);
  const dynamicPermissions = get(role, "permissions.dynamic", []);

  if (staticPermissions.includes(requiredPermission)) return true;
  if (dynamicPermissions.includes(requiredPermission)) {
    return dynamicPermissions.find(dynamicPermissions)(additionalData);
  }

  return false;
};

export default canPerform;
