import React, { useState } from "react";

import { Modal } from "antd";
import { Closable } from "components/Modal/show";
import TextArea from "antd/lib/input/TextArea";

export type LockFwVersionModalProps = {
  onOk: (comment: string) => void;
  isLockMode: boolean;
};

const LockFwVersionModal = ({ isLockMode, onOk, close }: LockFwVersionModalProps & Closable) => {
  const [comment, setComment] = useState<string>();

  const handleSubmitAction = () => {
    if (comment) {
      onOk(comment);
      close();
    }
  };

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setComment(event.target.value);
  };

  return (
    <Modal
      title={isLockMode ? "Lock FW Version" : "Unlock FW Version"}
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !comment,
      }}
    >
      <p className="mb-m">
        {isLockMode
          ? "Selected nodes will be locked to this FW until it is unlocked. Enter comments to proceed."
          : "Selected nodes will be unlocked. Enter comments to proceed."}
      </p>
      <TextArea onChange={handleOnChange} rows={4} autoFocus />
    </Modal>
  );
};

export default LockFwVersionModal;
