import { Typography } from "antd";
import { CoolIcon } from "components/Icons";
import React from "react";
import "./PreCoolTimer.scss";
import { useTimer } from "react-timer-hook";

export interface PreCoolTimerProps {
  [x: string]: any;
  expiryTime: string;
  onlyReturnTimer?: boolean;
}

export const PreCoolTimer: React.FC<PreCoolTimerProps> = ({ expiryTime, onlyReturnTimer }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(expiryTime),
    autoStart: true,
  });

  const renderTime = () => `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return (
    <>
      {onlyReturnTimer ? (
        <span>{renderTime()}</span>
      ) : (
        <div className="pre-cool-timer">
          <div className="pre-cool-timer-ellipse d-flex flex-column align-items-center justify-content-center">
            <div className="pre-cool-timer-icon">
              <CoolIcon />
            </div>
            <div className="pre-cool-timer-remaining-time mt-xs">
              <Typography.Text>Remaining time</Typography.Text>
              <Typography.Title level={4} className="m-none">
                {renderTime()}
              </Typography.Title>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
