import { Button, Checkbox, Col, Modal, Row, message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import errorHandler from "errorHandler";
import {
  Sensorflow_V_Comfortplus_Key_Measurements,
  usePrecoolComfortPlusRoomMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { formatTemp } from "pages/Key/key.helper";
import ContentMessage from "pages/PrecoolSchedule/components/ContentMessage";
import React, { useEffect, useState } from "react";
import "./ChooseRoomToPrecoolModal.scss";

export type ListRoomWithAmbientTemp = Pick<
  Sensorflow_V_Comfortplus_Key_Measurements,
  "ambientTemperature" | "roomId" | "roomName"
>[];

export type ChooseRoomToPrecoolModalProps = {
  listRoomWithAmbientTemp: ListRoomWithAmbientTemp;
  isChooseRoomToPrecoolModalOpen: boolean;
  setIsChooseRoomToPrecoolModalOpen: (value) => void;
};

const ChooseRoomToPrecoolModal = ({
  listRoomWithAmbientTemp,
  isChooseRoomToPrecoolModalOpen,
  setIsChooseRoomToPrecoolModalOpen,
}: ChooseRoomToPrecoolModalProps) => {
  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);

  const [precoolComfortPlusRoom, { loading: addingPrecoolLoading }] = usePrecoolComfortPlusRoomMutation({
    onCompleted: (res) => {
      if (!res.precoolComfortPlusRoom) {
        return message.error("Sorry, we are unable to pre-cool your room at the moment. Please try again later.");
      }
      message.config({ top: 0 });
      message.open({
        content: <ContentMessage content="Great! Your room is being pre-cooled to a comfortable temperature." />,
        type: "success",
        className: "precool-comfortPlus",
      });
    },
    onError: errorHandler.handleError,
  });

  const onSelectRoom = (e: CheckboxChangeEvent) => {
    const { target } = e;
    if (target.checked) {
      setSelectedRoomIds([...selectedRoomIds, target.name as string]);
    } else {
      const filteredSelectedRooms = selectedRoomIds.filter((roomId) => roomId !== target.name);
      setSelectedRoomIds(filteredSelectedRooms);
    }
  };
  const onSelectAllRooms = (e: CheckboxChangeEvent) => {
    const { target } = e;
    if (target.checked) {
      setSelectedRoomIds(listRoomWithAmbientTemp.map((room) => room.roomId));
    } else {
      setSelectedRoomIds([]);
    }
  };

  const onPrecoolRoom = () => {
    precoolComfortPlusRoom({
      variables: {
        roomIds: selectedRoomIds,
      },
    });
    setIsChooseRoomToPrecoolModalOpen(false);
  };

  useEffect(() => {
    if (isChooseRoomToPrecoolModalOpen && listRoomWithAmbientTemp.length === 0) {
      message.error("Sorry, we are unable to pre-cool your room at the moment. Please try again later.");
      setIsChooseRoomToPrecoolModalOpen(false);
    }
    if (isChooseRoomToPrecoolModalOpen && listRoomWithAmbientTemp.length === 1) {
      precoolComfortPlusRoom({
        variables: {
          roomIds: [listRoomWithAmbientTemp[0].roomId],
        },
      });
      setIsChooseRoomToPrecoolModalOpen(false);
    }
  }, [
    isChooseRoomToPrecoolModalOpen,
    listRoomWithAmbientTemp,
    setIsChooseRoomToPrecoolModalOpen,
    precoolComfortPlusRoom,
  ]);

  return (
    <Modal
      className="select-test-run-modal"
      title="Choose room to pre-cool"
      centered
      visible={isChooseRoomToPrecoolModalOpen && listRoomWithAmbientTemp.length > 1}
      footer={null}
      width={400}
      onCancel={() => setIsChooseRoomToPrecoolModalOpen(false)}
    >
      <Row className="select-all-row mb-m">
        <Col>
          <b>
            Selected {selectedRoomIds.length} room{selectedRoomIds.length > 1 && "s"}
          </b>
        </Col>
        <Col>
          <Checkbox onChange={onSelectAllRooms} checked={selectedRoomIds.length === listRoomWithAmbientTemp.length}>
            Select all
          </Checkbox>
        </Col>
      </Row>
      <Row>
        {listRoomWithAmbientTemp.map((room) => (
          <div key={room.roomId} className="mb-s">
            <Checkbox
              name={room.roomId}
              onChange={onSelectRoom}
              checked={!!selectedRoomIds.find((roomId) => roomId === room.roomId)}
            >
              {room.roomName} - <b>{formatTemp(room.ambientTemperature, "")}</b>
            </Checkbox>
            <br />
          </div>
        ))}
      </Row>
      <Row className="select-all-row mt-m h-auto">
        <Col className="w-50 h-auto">
          <Button
            className="w-100 mr-s py-s h-auto"
            type="ghost"
            onClick={() => setIsChooseRoomToPrecoolModalOpen(false)}
            disabled={addingPrecoolLoading}
          >
            Cancel
          </Button>
        </Col>
        <Col className="w-50 h-auto">
          <Button
            className="w-100 ml-s py-s h-auto"
            type="primary"
            onClick={() => onPrecoolRoom()}
            disabled={addingPrecoolLoading}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChooseRoomToPrecoolModal;
