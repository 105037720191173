import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Input, Modal, Row, Select } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import { GetCoolingSystemQuery, useGetCoolingSystemQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import classnames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { infrastructureTypes, InfrastructureTypeEnum, InfraPhaseEnum, HvacSystemTypeEnum } from "../Infrastructures.d";
import { InfrastructureData } from "../InfrastructureDataType";
import useAddInfrastructureAction from "./AddInfrastructureAction";
import "./AddInfrastructureModal.scss";

interface AddInfrastructureModalProps {
  onSuccess: () => void;
}

const AddInfrastructureModal = ({
  onSuccess,
  modalWrapperProps,
  closeModal,
}: AddInfrastructureModalProps & ModalWrapperProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const roleAndPermission = useRoleAndPermission();

  const [name, setName] = useState<string>();
  const [type, setType] = useState<InfrastructureTypeEnum>();
  const [numbOfPhase, setNumbOfPhase] = useState<InfraPhaseEnum>();
  const [hvacSystemType, setHvacSystemType] = useState<HvacSystemTypeEnum>();
  const [coolingSystems, setCoolingSystems] = useState<GetCoolingSystemQuery["infrastructures"]>();
  const [compressorId, setCompressorId] = useState<string>();
  const [ctMultiplier, setCtMultiplier] = useState<number>();
  const screen = useBreakpoint();

  useGetCoolingSystemQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setCoolingSystems(data.infrastructures);
    },
  });

  const onSuccessCallback = () => {
    closeModal();
    onSuccess();
  };

  useEffect(() => {
    if (roleAndPermission.isContractor()) {
      setType(InfrastructureTypeEnum.COMPRESSOR);
      setCtMultiplier(1);
    }
  }, [roleAndPermission]);

  const { createInfrastructure, isLoading } = useAddInfrastructureAction({ onSuccessCallback });

  const infrastructure = new InfrastructureData(
    locationId,
    name,
    type,
    numbOfPhase,
    hvacSystemType,
    compressorId,
    ctMultiplier
  );

  const isCompressor = (infraType: string | undefined) => {
    return infraType === InfrastructureTypeEnum.COMPRESSOR;
  };

  const isCoolingDevice = (infraType: string | undefined) => {
    return (
      infraType === InfrastructureTypeEnum.CHILLER ||
      infraType === InfrastructureTypeEnum.PUMP ||
      infraType === InfrastructureTypeEnum.COOLING_TOWER
    );
  };

  const closePhaseSetting = type ? infrastructure.isPipe() || infrastructure.isCoolingSystem() : true;

  const handleNameChange = (e: any) => setName(e.target.value);

  const handleCtMultiplierChange = (e: any) => setCtMultiplier(parseFloat(e.target.value));

  return (
    <Modal
      title="Add Infrastructure"
      centered
      onOk={() => createInfrastructure(infrastructure)}
      width={450}
      okText="Confirm"
      confirmLoading={isLoading}
      okButtonProps={{
        disabled: infrastructure.isEmpty(),
      }}
      {...modalWrapperProps}
    >
      <Row>
        <p className="mb-xs">Infrastructure Name</p>
        <Input onChange={handleNameChange} value={name} placeholder="Enter Name" />
      </Row>

      <Row className="mt-m">
        <Col className="infrastructure-type">
          <p className="mb-xs">Select Infrastructure type</p>
          <Select
            className={classnames("infrastructure-type-selector", { "open-energy-setting": !closePhaseSetting })}
            onSelect={setType}
            value={type}
            disabled={roleAndPermission.isContractor()}
          >
            {(roleAndPermission.isContractor()
              ? infrastructureTypes.filter((t) => t.value === InfrastructureTypeEnum.COMPRESSOR)
              : infrastructureTypes
            ).map((infraType: any) => (
              <Select.Option key={infraType.value} value={infraType.value}>
                {infraType.label}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {!closePhaseSetting && (
          <Col flex="auto" className="energy-setting">
            <p className="mb-xs">Energy Setting</p>
            <Select className="w-100" defaultValue={numbOfPhase} onSelect={setNumbOfPhase}>
              <Select.Option value={InfraPhaseEnum.INFRA_1_PHASE}>1 Phase</Select.Option>
              <Select.Option value={InfraPhaseEnum.INFRA_3_PHASES}>3 Phases</Select.Option>
            </Select>
          </Col>
        )}
      </Row>
      <Row className="mt-m">
        {isCompressor(type) && (
          <Col className="infrastructure-type">
            <p className="mb-xs">HVAC System type</p>
            <Select
              className="infrastructure-type-selector hvac-system-type-selector open-energy-setting"
              defaultValue={hvacSystemType}
              onSelect={setHvacSystemType}
            >
              <Select.Option value={HvacSystemTypeEnum.VRV}>VRV</Select.Option>
              <Select.Option value={HvacSystemTypeEnum.SPLIT}>Split</Select.Option>
            </Select>
          </Col>
        )}
        {isCoolingDevice(type) && (
          <Col className="infrastructure-type">
            <p className="mb-xs" data-testid="cooling-system-selector">
              Cooling System
            </p>
            <Select
              className="infrastructure-type-selector cooling-system-selector open-energy-setting"
              defaultValue={compressorId}
              onSelect={setCompressorId}
            >
              {coolingSystems?.map((system) => {
                return <Select.Option value={system.id}>{system.name}</Select.Option>;
              })}
            </Select>
          </Col>
        )}
        {!closePhaseSetting && (
          <Col
            className={classnames("energy-setting", screen.desktopOny ? "w-40" : "w-100", {
              "d-none": roleAndPermission.isContractor(),
            })}
          >
            <p className="mb-xs">CT Multiplier</p>
            <Input
              type="number"
              min={0}
              value={ctMultiplier}
              placeholder="Enter CT Multiplier"
              onChange={handleCtMultiplierChange}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default AddInfrastructureModal;
