import React, { useEffect, useRef, useState } from "react";

import { Input, message, Modal } from "antd";
import { useRenameKeyCategoryMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import "./UpdateKeyCategoryModal.scss";

type UpdateKeyCategoryModalProps = {
  categoryName: string;
  id: string;
  closeModal: () => void;
  refetchCategories: () => void;
};

const UpdateKeyCategoryModal = ({ categoryName, id, closeModal, refetchCategories }: UpdateKeyCategoryModalProps) => {
  const [input, setInput] = useState<string>(categoryName);
  const categoryNameInputRef: any = useRef();
  const [renameKeyCategory] = useRenameKeyCategoryMutation({
    onCompleted: () => {
      closeModal();
      message.success("Rename category successfully.");
      refetchCategories();
    },
    onError: (error) => {
      message.error(`Update category failed: ${error}`);
    },
  });

  useEffect(() => {
    categoryNameInputRef.current!.focus();
  }, [categoryNameInputRef]);

  const handleRenameCategorySubmit = () => {
    const inputChanged = input && input !== categoryName;
    if (inputChanged) {
      renameKeyCategory({
        variables: {
          primaryKey: {
            id,
          },
          updateKeyCategoryInput: {
            categoryName: input,
          },
        },
      });
    }
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Modal
      className="rename-category-modal"
      title="Rename Category"
      centered
      visible
      onCancel={closeModal}
      onOk={handleRenameCategorySubmit}
      width={400}
      okText="Rename Category"
    >
      <p>Category Name</p>
      <Input
        id="category-name-input"
        data-testid="category-name-input-testId"
        placeholder="Enter Category Name"
        onChange={handleInputChange}
        onPressEnter={handleRenameCategorySubmit}
        ref={categoryNameInputRef}
        value={input}
      />
    </Modal>
  );
};

export default UpdateKeyCategoryModal;
