export default [
  {
    positionId: "123410e2-9fc9-4b15-9902-c6d937f61d03",
    positionName: "Bedroom 9",
    slotMappings: [
      {
        slot: {
          id: "48617ccb-ca7c-414b-8a89-95dc357fa317",
        },
        node: {
          node_mac_id: "FFFF14B4577AC1EE",
          nodeOnlineStatus: {
            nodeStatus: "online",
            lastTransmission: "2021-04-16T03:58:42+00:00",
          },
          nodeJoinStrengthLive: {
            signalStrength: 22.323333,
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slot: {
          id: "ed40b435-9f63-4741-b464-5782f2d06cb2",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "FFFF14B4577AC5EE",
          nodeOnlineStatus: {
            nodeStatus: "offline",
            lastTransmission: "2021-02-10T09:45:44+00:00",
          },
          nodeJoinStrengthLive: {
            signalStrength: 15,
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slot: {
          id: "c09ed05a-c3c2-4e49-bfb6-f82712cb3fa6",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "FFFF14B4577AC3EE",
          nodeOnlineStatus: {
            nodeStatus: "booted",
            lastTransmission: "2021-02-10T09:45:44+00:00",
          },
          nodeJoinStrengthLive: {
            signalStrength: 15.05,
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
      {
        slot: {
          id: "33798e49-2f60-4053-8173-ec1625461438",
          __typename: "sensorflow_node_slots",
        },
        node: {
          node_mac_id: "",
          nodeOnlineStatus: {
            nodeStatus: "booted",
            lastTransmission: "2021-02-10T09:45:44+00:00",
          },
          nodeJoinStrengthLive: {
            signalStrength: 10.09,
          },
          __typename: "sensorflow_nodes",
        },
        __typename: "sensorflow_node_to_slot_mapping",
      },
    ],
    __typename: "sensorflow_positions",
  },
];
