/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Row } from "antd";
import SearchBar from "components/SearchBar/SearchBar";
import React from "react";
import { SpareNodesHeaderProps } from "./SpareNodesHeader.d";

const SpareNodesHeader = ({ onOpen, handleSearchChange = () => {} }: SpareNodesHeaderProps) => {
  return (
    <Row justify="space-between">
      <Col span={12}>
        <h2>Spare Nodes</h2>
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Button type="primary" onClick={onOpen}>
          Create spare node
        </Button>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} className="mt-l">
        <SearchBar onInputChange={handleSearchChange} placeholder="Type node mac id" autoFocus />
      </Col>
    </Row>
  );
};

export default SpareNodesHeader;
