import axios from "axios";
import fileDownload from "js-file-download";
import csvStringify from "csv-stringify/lib/sync";
import { CsvExportInput } from "types/types";
import { isEmpty } from "lodash";
import html2canvas from "html2canvas";
import JsPDF, { jsPDFOptions } from "jspdf";

const triggerDownloadFile = async (fileUrl: string, fileName: string) => {
  // to avoid This type of file can harm your computer warning in Cypress tests
  if (process.env.REACT_APP_DEVELOP_WITH_MOCK === "true") {
    // eslint-disable-next-line no-param-reassign
    fileName = fileName.replace(".img", ".png");
  }

  const link = document.createElement("a");
  link.download = fileName;
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateAndDownloadCsv = (input: CsvExportInput) => {
  if (!isEmpty(input.data) && !isEmpty(input.headers)) {
    const csvStr = csvStringify(input.data, {
      header: true,
      columns: input.headers.map(({ label, key }) => ({ header: label, key })),
      quoted: true,
    });

    // U+FEFF is the byte order mark (or BOM), and is used to tell the difference between big- and little-endian UTF-16 encoding.
    fileDownload(csvStr, `${input.fileName || "download"}.csv`, "text/csv;charset=utf-8", "\uFEFF");
  }
};

const generateAndDownloadPdf = async (
  element: HTMLElement,
  options: jsPDFOptions & {
    margin?: {
      x?: number;
      y?: number;
    };
    fileName?: string;
  } = {}
) => {
  const { margin = {}, fileName = "download", ...restOptions } = options;
  const { x: marginX = 0, y: marginY = 0 } = margin;

  const canvas = await html2canvas(element);
  const data = canvas.toDataURL("image/png");

  const pdf = new JsPDF({
    orientation: "p",
    format: "a4",
    unit: "px",
    ...restOptions,
  });

  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth() - marginX * 2;
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, "PNG", marginX, marginY, pdfWidth, pdfHeight);
  pdf.save(`${fileName}.pdf`);
};

const uploadToS3 = (uploadLink: string, file: File) => {
  if (process.env.REACT_APP_DEVELOP_WITH_MOCK) {
    return Promise.resolve();
  }

  return axios.put(uploadLink, file, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
};

const getFileNameFromLink = (link: string) => {
  return link.split("/").pop() || "";
};

export default {
  triggerDownloadFile,
  generateAndDownloadCsv,
  uploadToS3,
  getFileNameFromLink,
  generateAndDownloadPdf,
};
