import { PmsPropertyEodSurveysSubscription } from "pacts/app-webcore/hasura-webcore.graphql";

export enum EodSurveyStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export type EodSurveyData = {
  surveyUrl: string;
  editResponseUrl?: string;
};

export type EodSurveyRecord = Omit<PmsPropertyEodSurveysSubscription["surveys"][number], "data" | "status"> & {
  data?: EodSurveyData;
  status: EodSurveyStatus;
};
