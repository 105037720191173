import merge from "lodash/merge";

type ChartOptionCallbacks = {
  xAxesTicksCallback?: (value: string) => string | string[];
  onZoom?: ({ chart }: { chart: any }) => void;
  onPan?: ({ chart }: { chart: any }) => void;
  tooltipsCustom?: (tooltipModel: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const getChartOptions = (options: Record<string, any>, chartOptionCallbacks: ChartOptionCallbacks = {}) => {
  const { xAxesTicksCallback, onZoom, onPan, tooltipsCustom } = chartOptionCallbacks;

  return merge(
    {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 10,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            offset: true,
            ticks: {
              lineHeight: 1.5,
              callback: xAxesTicksCallback ?? ((value: string) => value),
            },
          },
        ],
      },
      zoom: {
        enabled: true,
        mode: "x",
        onZoom,
      },
      pan: {
        enabled: true,
        mode: "x",
        onPan,
      },
      tooltips: {
        enabled: false,
        custom: tooltipsCustom,
      },
    },
    options
  );
};
