import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, message, Modal } from "antd";
import React, { useState } from "react";

import {
  FirmwareReleasesQuery,
  Order_By as OrderBy,
  useDeleteFirmwareReleaseMutation,
  useFirmwareReleasesQuery,
  useUpdateFirmwareReleaseMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { AntdTablePagination } from "components/Table/Table.d";
import { getOrderByFromSort } from "utils/DataTransformer";
import errorHandler from "errorHandler";
import { getOtaNodeTypeDisplayValue } from "pages/Ota/OtaDetails/OtaDetails.helper";
import FirmwareTable from "../FirmwareTable/FirmwareTable";
import { FirmwareRelease } from "./FirmwareList.d";
import { OtaNodeTypes } from "../../OtaDetails/OtaDetails.d";
import "./FirmwareList.scss";

const defaultPagination = { current: 1, pageSize: 5, total: 0 };

interface Props {
  nodeSubType: OtaNodeTypes;
  openUploadModal: (nodeSubType: OtaNodeTypes, firmware: FirmwareRelease | null, reloadFirmwares: () => void) => void;
}

const FirmwareList = (props: Props) => {
  const { nodeSubType, openUploadModal } = props;

  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [firmwareReleases, setFirmwareReleases] = useState<FirmwareRelease[]>([]);
  const [sort, setSort] = useState<Record<string, OrderBy>>({ uploadedDate: OrderBy.Desc });

  const reloadBinding = (data: FirmwareReleasesQuery) => {
    setFirmwareReleases(data.firmwareReleases);
    setPagination({
      ...pagination,
      current: data.firmwareReleases?.length ? pagination.current : 1,
      total: data.firmwareReleaseAggregate.aggregate?.count || 0,
    });
  };

  const { loading, error, refetch } = useFirmwareReleasesQuery({
    variables: {
      order_by: [getOrderByFromSort(sort)],
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      where: {
        nodeSubType: {
          _eq: nodeSubType,
        },
      },
    },
    onCompleted: (data: FirmwareReleasesQuery) => {
      reloadBinding(data);
    },
    onError: errorHandler.handleError,
  });

  const reloadFirmwares = async () => {
    const { data } = await refetch();
    reloadBinding(data);
  };

  const [deleteFirmwareReleaseMutation] = useDeleteFirmwareReleaseMutation({
    onCompleted: async () => {
      await reloadFirmwares();
      message.success("Delete FW successfully");
    },
    onError: errorHandler.handleError,
  });

  const [updateFirmwareReleaseMutation] = useUpdateFirmwareReleaseMutation({
    onCompleted: async () => {
      await reloadFirmwares();
      message.success("Update FW successfully");
    },
    onError: errorHandler.handleError,
  });

  const handleTableChange = (page: AntdTablePagination) => {
    setPagination({
      current: page.current,
      pageSize: page.pageSize,
      total: pagination.total,
    });
  };

  const handleSortChange = (columnHeader: string) => {
    setSort({ [columnHeader]: sort[columnHeader] === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc });
  };

  const handleDeleteFirmware = (id: number) => {
    Modal.confirm({
      title: `Delete firmware`,
      content: "Firmware will be deleted",
      okText: "Delete",
      onOk() {
        deleteFirmwareReleaseMutation({
          variables: {
            id,
          },
        });
      },
    });
  };

  const handleDeprecated = (id: number, isDeprecated: boolean) => {
    Modal.confirm({
      title: isDeprecated ? "Deprecate firmware" : "Don't deprecate firmware",
      content: isDeprecated ? "Firmware will be deprecated" : "Firmware will be not deprecated",
      okText: "OK",
      onOk() {
        updateFirmwareReleaseMutation({
          variables: {
            id,
            input: {
              isDeprecated,
            },
          },
        });
      },
    });
  };

  return (
    <>
      <Card className="firmwares-list" data-testid="firmwares-list-testId">
        <div className="d-flex align-items-center mb-l">
          <h2 className="mb-none">{getOtaNodeTypeDisplayValue(nodeSubType)}</h2>
          <Button
            type="primary"
            size="middle"
            icon={<UploadOutlined />}
            className="ml-auto"
            onClick={() => openUploadModal(nodeSubType, null, reloadFirmwares)}
          >
            Upload
          </Button>
        </div>
        <FirmwareTable
          tableData={firmwareReleases}
          handleTableChange={handleTableChange}
          loading={loading}
          error={error}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            ...pagination,
          }}
          sortBy={handleSortChange}
          sort={sort}
          onDeleteFirmware={handleDeleteFirmware}
          onDeprecated={handleDeprecated}
          onUpdateFirmware={(fw: FirmwareRelease) => openUploadModal(nodeSubType, fw, reloadFirmwares)}
        />
      </Card>
    </>
  );
};

export default FirmwareList;
