export default [
  {
    keyEntryId: 175,
    startedAt: "2022-05-17T13:52:00+00:00",
    endedAt: null,
    userId: "auth0|607661f0d7cbed0070f6433a",
    keyPositionId: "333010e2-9fc9-4b15-9902-c6d937f61d03",
  },
  {
    keyEntryId: 175,
    startedAt: "2022-05-17T13:52:00+00:00",
    endedAt: "2022-05-17T14:02:00+00:00",
    userId: "auth0|607661f0d7cbed0070f6433a",
    keyPositionId: "333010e2-9fc9-4b15-9902-c6d937f61d03",
  },
  {
    keyEntryId: 176,
    startedAt: "2022-05-17T13:52:00+00:00",
    endedAt: null,
    userId: "auth0|607661f0d7cbed0070f6433a",
    keyPositionId: "333010e2-9fc9-4b15-9902-c6d937f61d04",
  },
  {
    keyEntryId: 177,
    startedAt: "2022-05-17T13:52:00+00:00",
    endedAt: "2022-05-17T14:02:00+00:00",
    userId: "auth0|607661f0d7cbed0070f6433b",
    keyPositionId: "333010e2-9fc9-4b15-9902-c6d937f61d05",
  },
  {
    keyEntryId: 178,
    startedAt: "2023-12-05T13:52:00+00:00",
    endedAt: null,
    userId: "auth0|607661f0d7cbed0070f6433a",
    keyPositionId: "333010e2-9fc9-4b15-9902-c6d937f61d06",
  },
];
