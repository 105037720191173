import { ApolloError } from "apollo-client";
import { get } from "lodash";
import { NodeMappingError } from "./NodeMappingError.d";
import NodeAlreadyMappedError from "./NodeAlreadyMappedError";
import NodeMappingErrorComponent from "./NodeMappingError";
import ExternalError from "./ExternalError";

export const getExternalErrorComponent = (error: ApolloError | undefined, nodeId: string | undefined) => {
  if (!error) return null;

  const { graphQLErrors } = error;
  const errorCode = get(graphQLErrors, "0.extensions.code");

  const errorComponentMap = {
    [NodeMappingError.NodeAlreadyMappedError]: {
      component: NodeAlreadyMappedError,
      props: {
        nodeId,
        ...get(graphQLErrors, "0.extensions.mappedNodeInSlot", {}),
      },
    },
    [NodeMappingError.NodeMappingError]: {
      component: NodeMappingErrorComponent,
      props: {
        nodeId,
      },
    },
  };

  return get(errorComponentMap, errorCode, {
    component: ExternalError,
    props: {
      message: "Something went wrong",
    },
  });
};

export default getExternalErrorComponent;
