import { Maybe } from "pacts/app-webcore/hasura-webcore.graphql";

export enum FormOperations {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export interface FormData {
  id?: number;
  nodeMacId?: Maybe<string>;
  eventType?: number;
  comment?: Maybe<string>;
  creationDate?: string;
  nodeTypeCode?: Maybe<string>;
}

export interface NodeMacInputOptions {
  title?: string;
  tabTitle?: string;
  isVisible?: boolean;
  disabled?: boolean;
}

export interface CreateNodeLifeCycleEventProps {
  formData: FormData;
  formOperation: FormOperations;
  closeModal: () => void;
  handleOnSubmit: (values: FormData) => void;
  nodeMacInputOptions?: {
    scan?: Omit<NodeMacInputOptions, "disabled">;
    input?: NodeMacInputOptions;
  };
}

export interface ChangeBatteryEventProps {
  formData: FormData;
  closeModal: () => void;
  handleOnSubmit: (values: FormData) => Promise<void>;
}

export enum NodeLifeCycleEventTypeId {
  REWORK_C4 = 7,
  REWORK_C4_AND_CR_BATTERY = 8,
  IN_MANUFACTURING = 9,
  STOCK = 10,
  INSTALLED = 11,
  RMA = 12,
  DECOMISIONED = 13,
  TRASH = 14,
  MAPPED = 15,
}
