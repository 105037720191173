export enum BookingStatus {
  CHECKED_IN = "Checked-In",
  CHECKED_OUT = "Checked-Out",
  AWAIT_CHECKING_IN = "await-checking-in",
}

export const BOOKING_STATUS_LABEL = {
  [BookingStatus.CHECKED_IN]: "Checked-In",
  [BookingStatus.CHECKED_OUT]: "Checked-Out",
  [BookingStatus.AWAIT_CHECKING_IN]: "Awaiting Checked-in",
};

export const BOOKING_STATUS_PMS_MAPPING = {
  [BookingStatus.CHECKED_IN]: ["arrived"],
  [BookingStatus.CHECKED_OUT]: ["departed"],
  [BookingStatus.AWAIT_CHECKING_IN]: ["confirmed", "unconfirmed"],
};

export enum SmartAllocReportType {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}
