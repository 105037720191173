import { ActingMode, AutomationMode } from "pages/Key/types";

export enum GraphQLErrorCode {
  CONSTRAINT_VIOLATION = "constraint-violation",
}

export enum ErrorMessage {
  DUPLICATE_KEYS = "Duplicate keys",
  INFRASTRUCTURE_NAME_ALREADY_EXISTS = "Infrastructure name already exists",
  FW_VERSION_NUMBER_ALREADY_EXISTS = "Version number already exists",
  BULK_CREATE_MAX_KEYS_LIMIT_ERROR = "Maximum of 30 keys can be created at a time",
}

export const BULK_CREATE_MAX_KEYS_LIMIT = 30;

export enum CsvExportJobName {
  NODE_LIST = "nodeList",
  ACTIVITY_LOG = "activityLog",
}

export const MAX_POSSIBLE_ROW_CNT = 1000 * 1000;

export enum PmsSystem {
  RMS = 0,
}

export const LOCATION_QUERY_PARAM_KEY = "location";

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 0,
};

export const POSITION_CONFIGURATION_DEFAULT = Object.freeze({
  automationMode: AutomationMode.SuperSave,
  occNightStart: "21:00",
  occNightEnd: "09:00",
  occTimeoutNightMins: 60,
  occTimeoutDayMins: 25,
  occupancySensitivity: 1,
  doorSensorTimeoutMins: 1,
  softstartWaitTime: 0,
  hysteresisOffDegrees: 0.5,
  hysteresisOnDegrees: 0.5,
  hideSetpoint: true,
  unoccupiedTemp: 27,
  minTemp: 19,
  maxTemp: 30,
  acNightEnd: "09:00",
  acNightStart: "21:00",
  actuationTimeoutSeconds: 300,
  bridgeAdaptorSetting: -1,
  heatingHideSetpoint: true,
  heatingMinTemp: 19,
  heatingMaxTemp: 30,
  freezeProtectionTemp: 16,
  heatingUnoccupiedTemp: 26,
  actingMode: ActingMode.TwoPFC,
  enableDoorSensor: true,
  nightTimeSetpointEnabled: false,
  nightTimeSetpointEnd: "07:00",
  nightTimeSetpointStart: "00:00",
  nightTimeSetpointOffset: 2,
  nightTimeSetpointMaxSetpoint: 23,
});

export const MAPPING_STATUS_UI_CONFIG = {
  NOT_STARTED: ["Not Started", "default"],
  IN_PROGRESS: ["In Progress", "warning"],
  COMPLETED: ["Completed", "success"],
};
export const MAPPING_STATUS_UI_CONFIG_CONTRACTOR: Record<
  string,
  [string, "error" | "default" | "warning" | "success" | "processing" | undefined]
> = {
  NOT_STARTED: ["Not Started", "default"],
  IN_PROGRESS: ["In Progress", "processing"],
  COMPLETED: ["Completed", "success"],
};

export enum EAuthType {
  ComfortPlus = "ComfortPlus",
}

export const SF_AUTH_TYP_HEADER = "x-sf-auth-type";

export const GATEWAY_DEFAULT_SETTINGS = Object.freeze({
  OtaMaxRetries: 5,
  OtaWindowStartTime: "01:00",
  OtaWindowEndTime: "06:00",
});

export enum EFeatureFlags {
  SmartSaveUnoccupiedLimit = "SmartSaveUnoccupiedLimit",
  DisableSaveAsDefaultMaxResetTimeout = "DisableSaveAsDefaultMaxResetTimeout",
  NighttimeSetpoint = "NighttimeSetpointIncrease",
  MapVisualization = "MapVisualization",
}
