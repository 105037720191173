import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import isEqual from "lodash/isEqual";
import { Button, Col, Divider, message, Row } from "antd";
import {
  FilterOption,
  FilterOptionType,
  GatewayHealthStatus,
  OnlineGatewayHealthDataByLocationQuery,
  TemporaryGatewayKittingProcessMetadataQuery,
  useEditGroupOfGatewaysMutation,
  useOnlineGatewayHealthDataByLocationQuery,
  useTemporaryGatewayKittingProcessMetadataQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import ListFilter from "components/ListFilter/ListFilter";
import { AntdTablePagination } from "components/Table/Table.d";
import ProgressComponent from "components/ProgressComponent/ProgressComponent";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import "./GatewayList.scss";
import Can from "components/Can/Can";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import classnames from "classnames";
import useRoleAndPermission from "hooks/use-role-and-permission";
import moment from "moment";
import EditKeysFormModal from "pages/Key/KeyList/EditKeysModal";
import errorHandler from "errorHandler";
import GatewayListTable from "../GatewayListTable/GatewayListTable";
import { Gateway } from "./GatewayList.d";
import useBreakpoint from "../../../hooks/use-breakpoint";

const filterOptions: FilterOption[] = [
  {
    field: "statuses",
    value: GatewayHealthStatus.PreparingImage,
    label: "Preparing Image",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.NotBooted,
    label: "Not Booted",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.Error,
    label: "Error",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.LoraError,
    label: "Lora Error",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.Healthy,
    label: "Healthy",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.Verifying,
    label: "Verifying",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.ErrorProvisioningImage,
    label: "Error Provisioning Image",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.Online,
    label: "Online",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
  {
    field: "statuses",
    value: GatewayHealthStatus.Offline,
    label: "Offline",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    type: FilterOptionType.Checkbox,
  },
];

type GateWayFilterInput = {
  statuses?: Array<string>;
  gatewayName: "";
};

const filterGateways = (gateways: Gateway[], filter: GateWayFilterInput) => {
  const healthStatuses =
    filter.statuses && filter.statuses?.length !== 0
      ? filter.statuses
      : [
          GatewayHealthStatus.PreparingImage,
          GatewayHealthStatus.NotBooted,
          GatewayHealthStatus.Error,
          GatewayHealthStatus.Healthy,
          GatewayHealthStatus.LoraError,
          GatewayHealthStatus.Verifying,
          GatewayHealthStatus.ErrorProvisioningImage,
          GatewayHealthStatus.Online,
          GatewayHealthStatus.Offline,
        ];

  return gateways
    .filter(({ gatewayName }) => gatewayName.includes(filter.gatewayName))
    .filter(({ kittingStatus }) => healthStatuses.includes(kittingStatus));
};

type GatewayListProps = {
  locationName: string;
};

const extractGateways = (data: TemporaryGatewayKittingProcessMetadataQuery) => {
  let newGateways: Gateway[] = data.gatewayKittingProcessMetadata
    .filter((metadata: any) => metadata.gateway)
    .map((metadata: any) => ({
      gatewayId: metadata.balenaId,
      gatewayName: metadata.gateway.gatewayName,
      kittingStatus: metadata.status as GatewayHealthStatus,
      healthStatus: GatewayHealthStatus.Offline,
      position: metadata.gateway.position,
      wifiMacAddress: metadata.gateway.wifiMacAddress as string,
      ethernetMacAddress: metadata.gateway.ethernetMacAddress as string,
      gatewayMac: metadata.gateway.gatewayMac,
      locationId: metadata.locationId,
      gatewayHealthData: metadata.gateway.gatewayHealthData,
      wlanLinkGatewayHealthData: metadata.gateway.wlanLinkGatewayHealthData,
      wlan1LinkGatewayHealthData: metadata.gateway.wlan1LinkGatewayHealthData,
      networkConnectionStatus: metadata.gateway.networkConnectionStatus as string,
    }));

  // remove online and offline status from kitting status, so shows Online and Offline gateways as Healthy
  newGateways = newGateways.map((gateway) => ({
    ...gateway,
    kittingStatus: [GatewayHealthStatus.Online, GatewayHealthStatus.Offline].includes(gateway.kittingStatus)
      ? GatewayHealthStatus.Healthy
      : gateway.kittingStatus,
  }));

  return newGateways;
};

const extractHealthStatuses = (gateways: Gateway[], healthStatuses: OnlineGatewayHealthDataByLocationQuery) => {
  const newGateways: Gateway[] = gateways.map((gateway) => {
    const healthStatusData = healthStatuses.gateways.find(
      (healthStatus) => gateway.gatewayId === healthStatus.gatewayId
    );
    let healthStatus: GatewayHealthStatus = GatewayHealthStatus.Offline;
    if (healthStatusData && healthStatusData.gatewayHealthData.length > 0) {
      healthStatus = GatewayHealthStatus.Online;
    }
    return {
      ...gateway,
      healthStatus,
    };
  });
  return newGateways;
};

const GatewayList = ({ locationName }: GatewayListProps) => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [filter, setFilter] = useState<GateWayFilterInput>({ gatewayName: "" });
  const { locationId } = useParams<{ locationId: string }>();
  const roleAndPermission = useRoleAndPermission();
  const [rowSelectionSetting, setRowSelectionSetting] = useState<any>();
  const [selectedGateways, setSelectedGateways] = useState<Gateway[]>([]);
  const [isEditGatewaysPopupOpen, setIsEditGatewaysPopupOpen] = useState<boolean>(false);

  const screen = useBreakpoint();

  const { refetch: reloadOnlineStatus } = useOnlineGatewayHealthDataByLocationQuery({
    variables: {
      locationId,
      offlineTime: moment().add(-5, "minutes").utc().format(),
    },
    onCompleted: (data: OnlineGatewayHealthDataByLocationQuery) => {
      const newGateways = extractHealthStatuses(gateways, data);
      if (!isEqual(newGateways, gateways)) {
        setGateways(newGateways);
      }
    },
    skip: true,
  });

  const reloadBinding = async (data: TemporaryGatewayKittingProcessMetadataQuery) => {
    let newGateways = extractGateways(data);
    const newHealthyStatuses = await reloadOnlineStatus();
    newGateways = extractHealthStatuses(newGateways, newHealthyStatuses.data);

    if (!isEqual(newGateways, gateways)) {
      setGateways(newGateways);
    }
  };

  const { loading, error, refetch } = useTemporaryGatewayKittingProcessMetadataQuery({
    variables: {
      locationId,
    },
    onCompleted: async (data: TemporaryGatewayKittingProcessMetadataQuery) => {
      let newGateways = extractGateways(data);
      const newHealthyStatuses = await reloadOnlineStatus();
      newGateways = extractHealthStatuses(newGateways, newHealthyStatuses.data);

      if (!isEqual(newGateways, gateways)) {
        setGateways(newGateways);
      }
    },
  });

  const reloadGateways = async () => {
    const data = await refetch();
    reloadBinding(data.data);
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    setPagination((page) => ({
      current: page.current,
      pageSize: page.pageSize,
      total: gateways?.length,
    }));
  }, [gateways]);

  const displayingGateways = filterGateways(gateways, filter);
  const numberOfHealthyGateways = useMemo(
    () => gateways.filter(({ kittingStatus }) => kittingStatus === GatewayHealthStatus.Healthy).length,
    [gateways]
  );

  const numberOfOnlineGateways = useMemo(
    () => gateways.filter(({ healthStatus }) => healthStatus === GatewayHealthStatus.Online).length,
    [gateways]
  );

  const statMessage = useMemo(() => {
    if (error) return "Error on loading gateways.";

    if (displayingGateways) {
      const { current, pageSize } = pagination;
      const total = displayingGateways.length;
      const start = (current - 1) * pageSize + 1;
      const end = Math.min(total, start + pageSize - 1);

      return `Showing ${start}-${end} of ${total} gateways`;
    }

    return "";
  }, [displayingGateways, error, pagination]);

  const handleTableChange = (page: any) => {
    setPagination({
      current: page.current,
      pageSize: page.pageSize,
      total: pagination.total,
    });
  };

  const handleFilterChange = (newFilter: GateWayFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  const getFilterOptionsByRole = () => {
    // if not Installer then cannot filter by Offline and Online because both show as Healthy
    if (!roleAndPermission.isInstaller()) {
      return filterOptions.filter(
        (option) =>
          ![GatewayHealthStatus.Online, GatewayHealthStatus.Offline].includes(option.value as GatewayHealthStatus)
      );
    }
    return filterOptions;
  };

  useEffect(() => {
    if (roleAndPermission.canPerform(Permission.KEY_EDIT) && !roleAndPermission.isInstaller()) {
      setRowSelectionSetting({
        selectedRowGateways: selectedGateways.map((p) => p.gatewayId),
        type: "checkbox",
        onChange: (selectedRowGateways: React.Key[], selectedRows: Gateway[]) => {
          setSelectedGateways(selectedRows);
        },
        getCheckboxProps: (record: Gateway) => ({
          name: record.gatewayId,
        }),
      });
    }
    // Include roleAndPermission will result infinity re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGateways]);

  const [editGateways, { loading: gatewaysEditing }] = useEditGroupOfGatewaysMutation({
    onCompleted: async () => {
      message.info("Update gateways' groups completed.");
      await reloadGateways();
    },
    onError: (e) => {
      errorHandler.handleError(e);
    },
  });

  const editGroupOfGateways = async (gatewayIds: string[], groupId: string) => {
    await editGateways({
      variables: {
        gatewayIds,
        groupId,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="mb-m">
        <Col>
          <h2 className="d-inline mr-l">Gateways</h2>
          <p className="d-inline">{statMessage}</p>
        </Col>
        <Col
          className={classnames({
            "mt-s": screen.mobileAndTabletOnly,
          })}
        >
          <div className="d-flex align-items-center">
            <Button
              disabled={selectedGateways.length === 0}
              onClick={() => {
                setIsEditGatewaysPopupOpen(true);
              }}
              type="primary"
              className="mr-m"
            >
              <EditFilled /> Update gateways&apos; groups
            </Button>
            <Can
              requiredPermission={Permission.GATEWAY_CREATE}
              yes={
                <Link
                  to={{
                    pathname: `/locations/${locationId}/gateways-creation-mode`,
                    state: {
                      locationName,
                    },
                  }}
                >
                  <Button type="primary" className="gateways-creation-mode-button" disabled={!locationName}>
                    <PlusOutlined />
                    Add Gateway
                  </Button>
                </Link>
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Row>
            <Col span={24}>
              <ListFilter
                currentFilter={filter}
                onChange={handleFilterChange}
                searchField="gatewayName"
                searchPlaceholder="Search gateway name"
                filterOptions={getFilterOptionsByRole()}
                containerClassName="mb-xl"
              />
            </Col>
            <Col span={24}>
              <GatewayListTable
                reloadGateways={reloadGateways}
                tableData={displayingGateways}
                handleTableChange={handleTableChange}
                loading={loading}
                error={error}
                rowSelection={screen.desktopUp ? rowSelectionSetting : null}
              />
            </Col>
          </Row>
        </Col>
        <Col
          lg={{ span: 6 }}
          className={classnames("gateway-stats", {
            "d-none": screen.mobileAndTabletOnly,
          })}
        >
          <div className="content-right-section">
            <p className="content-header">Overall Status</p>
            <Divider type="horizontal" />
            <ProgressComponent
              name={roleAndPermission.isInstaller() ? "Online Gateways" : "Healthy Gateways"}
              doneCount={roleAndPermission.isInstaller() ? numberOfOnlineGateways : numberOfHealthyGateways}
              totalCount={gateways.length}
            />
            <p className="description">Gateways should come online within 5 mins of connecting to power.</p>
          </div>
        </Col>
      </Row>
      {isEditGatewaysPopupOpen && (
        <EditKeysFormModal
          locationId={locationId}
          closeModal={() => setIsEditGatewaysPopupOpen(false)}
          editObjects={editGroupOfGateways}
          editing={gatewaysEditing}
          objectType="gateway"
          objects={selectedGateways.map((g) => ({ id: g.gatewayId, name: g.gatewayName }))}
        />
      )}
    </>
  );
};

export default GatewayList;
