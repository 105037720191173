import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Alert } from "antd";

import {
  UserCreateMutation,
  useUserQuery,
  useUserCreateMutation,
  useUserUpdateMutation,
  UserInput,
} from "pacts/app-webcore/hasura-webcore.graphql";

import logger from "../../../services/logger/logger";
import UserCreateEditForm from "./UserCreateEditForm/UserCreateEditForm";
import UserAdditionalActions from "./UserAdditionalActions";

import { FormOperations } from "./UserCreateEdit.d";

// which one to use here? create/modify/read
type User = UserCreateMutation["userCreate"];
type FormOperationStatus = "USER_CREATE_SUCCESS" | "USER_UPDATE_SUCCESS" | null;

const UserCreateEdit = () => {
  // get userId from params, if any, from react router
  // if route is /new this will be undefined
  const { userId } = useParams();

  // set component state to either update or create
  // this might be refactored out
  const [formOperation, setFormOperation] = useState<FormOperations>(userId ? "UPDATE" : "CREATE");
  const [formOperationStatus, setFormOperationStatus] = useState<FormOperationStatus>(null);
  const [userDataFromServer, setUserDataFromServer] = useState<User | null>(null);

  // query for existing user
  const { loading: queryLoading, error: queryError } = useUserQuery({
    variables: { userId },
    // don't fire query if no userId,
    skip: userId === undefined,
    onCompleted: (data) => {
      if (data && data.user) {
        setUserDataFromServer(data.user);
      }
    },
  });

  // mutation for create
  const [userCreate, { data: createResponseData, loading: createLoading, error: createError }] = useUserCreateMutation({
    onCompleted() {
      setFormOperationStatus("USER_CREATE_SUCCESS");
    },
    // TODO 2020-08-21: remove dummy onError callback once apollo doesn't throw error for mutations in Jest
    onError() {},
  });

  // mutation for update
  const [userUpdate, { data: updateResponseData, loading: updateLoading, error: updateError }] = useUserUpdateMutation({
    onCompleted() {
      setFormOperationStatus("USER_UPDATE_SUCCESS");
    },
    // TODO 2020-08-21: remove dummy onError callback once apollo doesn't throw error for mutations in Jest
    onError() {},
  });

  useEffect(() => {
    if (createResponseData?.userCreate) {
      setUserDataFromServer(createResponseData.userCreate);
    }
  }, [createResponseData]);

  useEffect(() => {
    if (updateResponseData?.userUpdate) {
      setUserDataFromServer(updateResponseData.userUpdate);
    }
  }, [updateResponseData]);

  // end graphql

  // submit handler
  const handleSubmit = (formData: any) => {
    const { email, userId: userIdFromForm, ...rest } = formData;
    const user: UserInput = { ...rest };

    if (formOperation === "CREATE") {
      userCreate({ variables: { email, user } });
    } else if (formOperation === "UPDATE") {
      userUpdate({ variables: { userId: userIdFromForm, user } });
    }
  };

  const userCreateSuccessMessage = (user: User) => (
    <span>
      User {user.name} created successfully with id {user.userId}
      <br />
      <br />
      <Link to={`${user.userId}`} onClick={() => setFormOperation("UPDATE")}>
        Edit User
      </Link>
    </span>
  );

  const userUpdateSuccessMessage = (user: User) => <p>User {user.name} updated successfully</p>;

  if (createError) {
    logger.error(createError);
  }
  if (updateError) {
    logger.error(updateError);
  }

  return (
    <Row>
      <Col xs={24} md={12}>
        <Link to="/users" style={{ marginBottom: "8px" }}>
          &lt; Users
        </Link>
        <h2 style={{ marginBottom: "24px" }}>
          {formOperation === "CREATE" ? "Add user" : `Edit user: ${userDataFromServer?.name || ""}`}
        </h2>
        {queryLoading && <p>Loading...</p>}
        {formOperation === "CREATE" &&
          formOperationStatus === "USER_CREATE_SUCCESS" &&
          createResponseData?.userCreate && (
            <Alert
              type="success"
              message="User Created Successfully"
              description={userCreateSuccessMessage(createResponseData.userCreate)}
              style={{ marginBottom: "24px" }}
            />
          )}
        {formOperation === "UPDATE" &&
          formOperationStatus === "USER_UPDATE_SUCCESS" &&
          updateResponseData?.userUpdate && (
            <Alert
              type="success"
              message="User Updated Successfully"
              description={userUpdateSuccessMessage(updateResponseData.userUpdate)}
              style={{ marginBottom: "24px" }}
            />
          )}
        {createError && (
          <Alert
            type="error"
            message="Error creating user"
            description={createError.message}
            style={{ marginBottom: "24px" }}
          />
        )}
        {updateError && (
          <Alert
            type="error"
            message="Error updating user"
            description={updateError.message}
            style={{ marginBottom: "24px" }}
          />
        )}
        {queryError && (
          <Alert
            type="error"
            message="Error getting user's details"
            description={queryError.message}
            style={{ marginBottom: "24px" }}
          />
        )}
        {formOperation === "UPDATE" && userDataFromServer && <UserAdditionalActions user={userDataFromServer} />}
        {!queryLoading && (
          <UserCreateEditForm
            user={userDataFromServer || null}
            handleSubmit={handleSubmit}
            formOperation={formOperation}
            loading={createLoading || updateLoading}
          />
        )}
      </Col>
    </Row>
  );
};

export default UserCreateEdit;
