import ReactDOM from "react-dom";
import React from "react";

type ShowModalProps<T> = {
  element: any;
  config: T;
};

export type Closable = {
  close: () => void;
  onClose: () => void;
};

export default function showModal<T>(props: ShowModalProps<T>) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  function close() {
    const config = props.config as any;
    if (typeof config.onClose === "function") {
      config.onClose();
    }
    destroy();
  }

  setTimeout(() => {
    ReactDOM.render(React.createElement(props.element, { ...props.config, close }), div);
  });
}
