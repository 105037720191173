import React from "react";

import { isEmpty } from "lodash";
import { Button, Col, Input, Row } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Occupancy, Room } from "./DataTypes";
import "./Occupancies.scss";

const DEFAULT_LIMIT_OCCUPANCIES = 9;

type OccupanciesProps = {
  typeDisplay: string;
  room: Room;
  limit?: number;
  occupancies: Array<Occupancy>;
  onOccupancyAddCallback: () => void;
  onSessionChangeCallback: () => void;
};

const Occupancies = ({
  typeDisplay,
  room,
  limit = DEFAULT_LIMIT_OCCUPANCIES,
  occupancies,
  onOccupancyAddCallback,
  onSessionChangeCallback,
}: OccupanciesProps) => {
  const reachedTheLimit = occupancies.length === limit;

  const handleOccupancyAdd = () => {
    if (!reachedTheLimit) {
      onOccupancyAddCallback();
      onSessionChangeCallback();
    }
  };

  const handleOccupancyRename = (occupancy: Occupancy, name: string) => {
    occupancy.setName(name);
    onSessionChangeCallback();
  };

  const handleOccupancyDelete = (occupancy: Occupancy) => {
    room.removeOccupancy(occupancy);
    onSessionChangeCallback();
  };

  return (
    <div className="occupancy">
      <Button type="primary" className="add-button" onClick={handleOccupancyAdd} disabled={reachedTheLimit}>
        Add {typeDisplay}
      </Button>

      <div className="preview-section">
        {occupancies.map((occupancy: Occupancy, index: number) => (
          <div className="preview-element" key={occupancy.id}>
            <p>{`${typeDisplay} ${index + 1} Name`}</p>
            <Row className="input-field with-action with-preview">
              <Col flex="350px">
                <Input
                  placeholder={`${typeDisplay} ${index + 1}`}
                  onChange={(e) => handleOccupancyRename(occupancy, e.target.value)}
                  value={occupancy.name}
                />
              </Col>
              <Col className="action-icon">
                <DeleteOutlined onClick={() => handleOccupancyDelete(occupancy)} />
              </Col>
            </Row>
            <p className="hint">
              Preview: <b>{occupancy.getPreview(room)}</b>
            </p>
            {isEmpty(occupancy.name) && <p className="required-text">It is required</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Occupancies;
