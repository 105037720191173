import React from "react";
import QrReader from "react-camera-qr";
import { message, Select } from "antd";
import useScanCamera from "components/ScanCamera/ScanCamera";
import isEmpty from "lodash/isEmpty";

interface ScannedNodeMacIdProps {
  handleQRCodeScan: (data: any) => void;
  options?: {
    scan?: {
      tabTitle?: string;
      title?: string;
    };
    input?: {
      tabTitle?: string;
      title?: string;
    };
  };
}

export default function ScannedNodeMacId({ options: optionsProps = {}, handleQRCodeScan }: ScannedNodeMacIdProps) {
  const { videoDeviceId, videoDevices, onCameraDeviceChange, qrReaderProps } = useScanCamera({
    skipSettingDefaultDevice: true,
  });

  const options = {
    scan: {
      tabTitle: "Scan with camera",
      title: "Scan QR Code with camera",
      ...optionsProps.scan,
    },
    input: {
      tabTitle: "Enter Node ID",
      title: "Type Node ID",
      ...optionsProps.input,
    },
  };

  const handleScanQRCodeError = () => {
    message.error("Error during scan QR Code.");
  };

  return (
    <>
      <h4>{options.scan.title}:</h4>

      {!isEmpty(videoDevices) ? (
        <QrReader
          delay={300}
          onScan={handleQRCodeScan}
          onError={handleScanQRCodeError}
          className="camera-view"
          {...qrReaderProps}
        />
      ) : (
        <span>No camera available.</span>
      )}
      <br />
      {videoDevices && videoDevices.length > 0 && (
        <Select
          placeholder="Select camera"
          className="camera-view"
          value={videoDeviceId}
          onChange={onCameraDeviceChange}
        >
          {videoDevices?.map((option: any) => (
            <Select.Option key={option.id} value={option.id}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      )}
      <br />
      <br />
    </>
  );
}
