import React, { useCallback, useEffect, useRef, useState } from "react";

import { Form, Input, Modal } from "antd";
import { Closable } from "components/Modal/show";

export type RenameFormModalProps = {
  objectId: string;
  objectName: string;
  onSubmit: (id: string, name: string) => void;
  popupTitle?: string;
  popupOkText?: string;
  fieldLabel?: string;
  placeholderText?: string;
  message?: string;
  regexPattern: string;
};

const RenameFormModal = ({
  objectId,
  objectName,
  onSubmit,
  close,
  popupTitle,
  popupOkText,
  fieldLabel,
  placeholderText,
  message,
  regexPattern,
}: RenameFormModalProps & Closable) => {
  const [form] = Form.useForm();
  const [input, setInput] = useState<string>(objectName);
  const objectNameInputRef: any = useRef();
  const [disableConfirm, setDisableConfirm] = useState(true);

  const checkFormValidity = useCallback(async () => {
    try {
      await form.validateFields();
      form.setFieldsValue({
        target: input,
      });
    } catch (e) {
      setDisableConfirm(true);
    }
  }, [form, input, setDisableConfirm]);

  useEffect(() => {
    checkFormValidity();
    objectNameInputRef.current!.focus();
  }, [checkFormValidity, objectNameInputRef, form]);

  const handleSubmitRenameObject = () => {
    const inputChanged = input && input !== objectName;
    if (inputChanged) {
      checkFormValidity();
      onSubmit(objectId, input);
      close();
    }
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Modal
      title={popupTitle ?? "Rename"}
      centered
      open
      onCancel={close}
      onOk={handleSubmitRenameObject}
      width={400}
      okText={popupOkText ?? "Rename"}
      okButtonProps={{ disabled: disableConfirm }}
    >
      <p className="mb-xs">{fieldLabel ?? "Name"}</p>
      <Form
        form={form}
        onFieldsChange={() => setDisableConfirm(form.getFieldsError().some((field) => field.errors.length > 0))}
      >
        <Form.Item
          name="target"
          rules={[
            {
              message,
              pattern: new RegExp(regexPattern),
            },
          ]}
        >
          <Input
            id="object-name-input"
            data-testid="object-name-input-testId"
            ref={objectNameInputRef}
            value={input}
            placeholder={placeholderText}
            onChange={handleInputChange}
            onPressEnter={handleSubmitRenameObject}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RenameFormModal;
