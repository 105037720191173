import { Input, message, Form, Upload, InputNumber } from "antd";
import Modal from "antd/lib/modal/Modal";
import { every, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import "./FirmwareUploadModal.scss";
import {
  useCreateFirmwareReleaseMutation,
  Sensorflow_Firmware_Releases_Insert_Input,
  useUpdateFirmwareReleaseMutation,
  useFirmwareUploadLinkQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import fileUtil from "utils/file";
import { InboxOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { randomString } from "utils/string";
import { ErrorMessage, GraphQLErrorCode } from "utils/constants";
import { ApolloError } from "apollo-client";
import { getOtaNodeTypeDisplayValue } from "pages/Ota/OtaDetails/OtaDetails.helper";
import {
  CreateFirmwareFormFieldKey,
  CreateFirmwareModalProps,
  FieldData,
  FirmwareInput,
} from "./FirmwareUploadModal.d";

const { TextArea } = Input;
const { Dragger } = Upload;

const FirmwareUploadModal = (props: CreateFirmwareModalProps) => {
  const { visible, closeModal, nodeSubType, firmware, reloadFirmwareList } = props;
  const isEdit = !!firmware;

  const nodeSubTypeDisplayValue = getOtaNodeTypeDisplayValue(nodeSubType);
  const [form] = Form.useForm<FirmwareInput>();

  const [validInput, setValidInput] = useState<boolean>(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const checkFormValdity = React.useCallback(async (changedFields, allFields: FieldData[]) => {
    const validatedFields = allFields.filter((f) => !f.validating);
    if (isEmpty(validatedFields)) {
      setValidInput(true);
      return;
    }
    setValidInput(every(validatedFields, (f) => isEmpty(f.errors)));
  }, []);

  const [createFirmwareReleaseMutation] = useCreateFirmwareReleaseMutation({
    onCompleted: () => {
      closeModal();
      reloadFirmwareList();
      form.resetFields();
      message.success(`${nodeSubTypeDisplayValue} FW Uploaded`);
    },
    onError: (error: ApolloError) => {
      errorHandler.handleError(error, {
        [GraphQLErrorCode.CONSTRAINT_VIOLATION]: ErrorMessage.FW_VERSION_NUMBER_ALREADY_EXISTS,
      });
    },
  });

  const [updateFirmwareReleaseMutation] = useUpdateFirmwareReleaseMutation({
    onCompleted: () => {
      closeModal();
      reloadFirmwareList();
      form.resetFields();
      message.success(`${nodeSubTypeDisplayValue} FW Updated`);
    },
    onError: errorHandler.handleError,
  });

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  const { refetch: firmwareUploadLinkQuery } = useFirmwareUploadLinkQuery({
    onError: errorHandler.handleError,
    skip: true,
  });

  const handleSubmit = async () => {
    let downloadLink = form.getFieldValue(CreateFirmwareFormFieldKey.DOWNLOAD_LINK);
    if (fileList[0].lastModified) {
      try {
        const fileName = `${randomString()}_${form.getFieldValue(CreateFirmwareFormFieldKey.DOWNLOAD_LINK)}`;
        const { data } = await firmwareUploadLinkQuery({
          fileName,
        });
        await fileUtil.uploadToS3(data.firmwareUploadLink, fileList[0] as any);
        [downloadLink] = data.firmwareUploadLink.split("?");
      } catch (error) {
        message.error("Cannot upload firmware");
        return;
      }
    }

    const firmwareInput: Sensorflow_Firmware_Releases_Insert_Input = {
      nodeSubType,
      versionName: form.getFieldValue(CreateFirmwareFormFieldKey.VERSION_NAME),
      versionNumber: form.getFieldValue(CreateFirmwareFormFieldKey.VERSION_NUMBER),
      comment: form.getFieldValue(CreateFirmwareFormFieldKey.COMMENT),
      downloadLink,
    };

    if (isEdit) {
      updateFirmwareReleaseMutation({
        variables: {
          id: firmware?.id as number,
          input: {
            downloadLink: firmwareInput.downloadLink,
            versionName: firmwareInput.versionName,
            comment: firmwareInput.comment,
          },
        },
      });
    } else {
      createFirmwareReleaseMutation({
        variables: {
          input: firmwareInput,
        },
      });
    }
  };

  useEffect(() => {
    if (firmware) {
      form.setFieldsValue({
        [CreateFirmwareFormFieldKey.COMMENT]: firmware.comment,
        [CreateFirmwareFormFieldKey.VERSION_NAME]: firmware.versionName,
        [CreateFirmwareFormFieldKey.VERSION_NUMBER]: firmware.versionNumber,
        [CreateFirmwareFormFieldKey.DOWNLOAD_LINK]: firmware.downloadLink,
      });
      setFileList([
        {
          name: fileUtil.getFileNameFromLink(firmware.downloadLink) || "",
          url: firmware.downloadLink,
        } as UploadFile,
      ]);
    }
  }, [firmware, form]);

  const CREATE_FIRMWARE_FORM_CONFIG = [
    {
      label: "",
      name: CreateFirmwareFormFieldKey.DOWNLOAD_LINK,
      defaultValue: "",
      trigger: "",
      rules: [
        {
          required: true,
          message: "Please upload a firmware",
        },
      ],
      component: (
        <Dragger
          className="firmware-uploader"
          data-testid="firmware-uploader-testId"
          multiple={false}
          showUploadList={{
            showRemoveIcon: false,
          }}
          beforeUpload={() => {
            return false;
          }}
          onChange={(info) => {
            form.setFieldsValue({
              [CreateFirmwareFormFieldKey.DOWNLOAD_LINK]: info.file.name,
            });
            setFileList([info.file]);
          }}
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      ),
    },
    {
      label: "FW Version Name",
      name: CreateFirmwareFormFieldKey.VERSION_NAME,
      defaultValue: "",
      trigger: "onChange",
      rules: [
        {
          required: true,
          message: "Version name is required",
        },
        {
          max: 255,
          message: "Version name must be between 0-255 and not an existing version",
        },
      ],
      component: <Input placeholder="Enter Version Name" />,
    },
    {
      label: "FW Version Number",
      name: CreateFirmwareFormFieldKey.VERSION_NUMBER,
      defaultValue: "",
      trigger: "onChange",
      rules: [
        {
          required: true,
          message: "Version number is required",
        },
      ],
      component: (
        <InputNumber
          className="version-number-input"
          placeholder="Enter Version Number"
          min={0}
          max={999999999}
          disabled={!!firmware}
        />
      ),
    },
    {
      label: "Enter Comments below to describe the uploaded FW file.",
      name: CreateFirmwareFormFieldKey.COMMENT,
      defaultValue: "",
      trigger: "onChange",
      component: <TextArea placeholder="Enter Comments" rows={5} />,
    },
  ];

  return (
    <Modal
      title={isEdit ? `Edit ${nodeSubTypeDisplayValue} Firmware` : `Upload ${nodeSubTypeDisplayValue} Firmware`}
      okText="OK"
      cancelText="Cancel"
      className="upload-firmware-modal"
      visible={visible}
      onCancel={handleClose}
      onOk={() => form.submit()}
      okButtonProps={{
        disabled: !validInput,
      }}
      destroyOnClose
      width={580}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={Object.fromEntries(CREATE_FIRMWARE_FORM_CONFIG.map((c) => [c.name, c.defaultValue]))}
        onFieldsChange={checkFormValdity}
        onFinish={handleSubmit}
        onFinishFailed={() => setValidInput(false)}
      >
        {CREATE_FIRMWARE_FORM_CONFIG.map((c) => (
          <Form.Item
            key={c.name}
            label={c.label}
            name={c.name}
            trigger={c.trigger}
            validateTrigger={c.trigger}
            rules={c.rules}
          >
            {c.component}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default FirmwareUploadModal;
