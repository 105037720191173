import { Smplr } from "@smplrspace/smplr-loader";

export type Env = "dev" | "prod";

export interface Ids {
  env: "dev" | "prod";
  spaceId: string;
  clientToken: string;
  organizationId: string;
}

export type IdSet = Record<Env, Ids>;

export interface SpaceViewerProps extends Ids {
  mode?: "2d" | "3d";
  onReady: (space: InstanceType<Smplr["Space"]>, smplr: Smplr) => void;
  onResize?: () => void;
}

export interface SmplrCoord2d {
  x: number;
  z: number;
  levelIndex: number;
}

export interface SmplrCoord3d extends SmplrCoord2d {
  elevation: number;
}

export type Polygon = {
  id: string;
  name: string;
  coordinates: SmplrCoord2d[];
};

export type Point = {
  id: string;
  name: string;
  position: SmplrCoord3d;
  type: string;
};

export type DispatchPolygonAction =
  | {
      type: "addCoordinate";
      id: string;
      coordinate: SmplrCoord2d;
      name: string;
    }
  | {
      type: "updateCoordinates";
      id: string;
      coordinates: SmplrCoord2d[];
      name: string;
    }
  | {
      type: "addPolygon";
      id: string;
      coordinates?: SmplrCoord2d[];
      name: string;
    }
  | { type: "removePolygon"; id: string }
  | { type: "clear" };

export const mapTypes = [
  { display: "Polygon", value: "polygon" },
  { display: "Room", value: "room" },
  { display: "Point", value: "point" },
];

export type DispatchPointAction =
  | {
      type: "add";
      point: Point;
    }
  | {
      type: "update";
      id: string;
      updates: Partial<Point>;
    }
  | {
      type: "remove";
      id: string;
    }
  | {
      type: "clear";
    };

export enum DataLayer {
  SIGNAL = "SIGNAL",
  LABEL = "LABEL",
  TEMPERATURE = "TEMPERATURE",
  HUMIDITY = "HUMIDITY",
}

export enum SignalColors {
  LEVEL1 = "#a50026",
  LEVEL2 = "#d73027",
  LEVEL3 = "#f46d43",
  LEVEL4 = "#fdae61",
  LEVEL5 = "#fee08b",
  LEVEL6 = "#d9ef8b",
  LEVEL7 = "#a6d96a",
  LEVEL8 = "#66bd63",
  LEVEL9 = "#1a9850",
  LEVEL10 = "#006837",
}

export enum TemperatureColors {
  LEVEL1 = "#2166ac",
  LEVEL2 = "#4393c3",
  LEVEL3 = "#74add1",
  LEVEL4 = "#abd9e9",
  LEVEL5 = "#ffffbf",
  LEVEL6 = "#fee090",
  LEVEL7 = "#fdae61",
  LEVEL8 = "#f46d43",
  LEVEL9 = "#d53e4f",
  LEVEL10 = "#d73027",
}
