import { useLocationQuery, useGetPmsPropertyByLocationQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { useContext, useState } from "react";
import { LOCATION_QUERY_PARAM_KEY } from "utils/constants";
import { LocationContext } from "../contexts/location-context";
import useQueryString from "./use-query-string";

const useSensorflowLocation = () => {
  const locationContext = useContext(LocationContext);

  return {
    locationId: locationContext.location?.id,
    locationName: locationContext.location?.locationName,
    timezone: locationContext.location?.timezone,
    reloadLocation: locationContext.reloadLocation,
    location: locationContext.location,
  };
};

export default useSensorflowLocation;

export const useSfLocationWithPmsProperty = () => {
  const query = useQueryString();
  const [locationId, setLocationId] = useState<string | undefined>(
    query[LOCATION_QUERY_PARAM_KEY] as string | undefined
  );
  const { data } = useLocationQuery({
    skip: !locationId,
    variables: {
      locationId: locationId || "",
    },
  });

  const { data: propertyData } = useGetPmsPropertyByLocationQuery({
    skip: !locationId,
    variables: {
      locationId: locationId || "",
    },
  });

  return {
    location: data?.location
      ? {
          ...data.location,
          pms: propertyData?.property?.[0],
        }
      : undefined,
    onLocationIdSelected: setLocationId,
  };
};
