import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { MappedNodeDataType } from "../InfrastructureDetails.d";

export const emonSettings: any[] = [
  {
    label: "White CT Clamp 20A",
    value: 2,
  },
  {
    label: "Blue CT Clamp 20A",
    value: 1,
  },
  {
    label: "Blue CT Clamp 100A",
    value: 0,
  },
  {
    label: "White CT clamp KCT10 30A",
    value: 5,
  },
  {
    label: "ASCT-500A",
    value: 6,
  },
  {
    label: "ASCT-800A",
    value: 7,
  },
  {
    label: "ASCT-1000A",
    value: 8,
  },
];

export interface MappedNodesProps {
  dataSource: MappedNodeDataType[];
  handleAdjustMappedNode: (nodeData: MappedNodeDataType) => void;
  handleUnmapNode: (nodeMacId: string) => void;
  startChangeGateway: (positionId: string) => void;
}

export interface MappedNodesWrapperProps {
  infraId: string;
  reloadInfrastructure: () => void;
}

export const NodeTypeCodeIdentifiers: any[] = [
  {
    type: NodeType.Aircon,
    code: "01",
  },
  {
    type: NodeType.Occupancy,
    code: "03",
  },
  {
    type: NodeType.Door,
    code: "07",
  },
  {
    type: NodeType.Energy,
    code: "00",
  },
  {
    type: NodeType.FlowMeter,
    code: "06",
  },
  {
    type: NodeType.PipeTemp,
    code: "05",
  },
];
