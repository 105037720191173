import { ExclamationCircleFilled, PlusCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Menu, message, Modal, Progress, Select, Typography } from "antd";
import confirm from "antd/lib/modal/confirm";
import classnames from "classnames";
import ActionButton from "components/ActionButton";
import Can from "components/Can/Can";
import GetHelpButton from "components/GetHelpButton";
import { InstallationModeButton } from "components/InstallationMode";
import { countBy, Dictionary, get } from "lodash";
import {
  KeyQuery,
  MappingStatus,
  useDeletePositionMutation,
  useKeyCategoriesQuery,
  useKeyQuery,
  useLabelPrintingKeyRoomsQuery,
  useLocationQuery,
  useTemporaryKeyNodeHealthQuery,
  useUpdateKeyCategoryToKeyMappingMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import SelectTestRunModeModal from "pages/Checklist/SelectTestRunModeModal";
import React, { useMemo, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getKeyDetailLink } from "utils/link";
import GetHelpMenuItem from "components/GetHelpMenuItem";
import MenuDropdown from "../../../components/MenuDropdown";
import ConfirmDeleteModal, { ConfirmDeleteModalProps } from "../../../components/Modal/ConfirmDeleteModal";
import showModal, { Closable } from "../../../components/Modal/show";
import ObjectHealth from "../../../components/ObjectHealth";
import errorHandler from "../../../errorHandler";
import useBreakpoint from "../../../hooks/use-breakpoint";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import { PositionFunction } from "../../Checklist/Checklist.d";
import KeyAndRooms from "../KeyList/KeyAndRooms";
import ChangeCategoryModal from "./ChangeCategoryModal";
import CreateRoomFormModal from "./CreateRoomFormModal";
import { Key } from "./KeyDetail.d";
import "./KeyDetail.scss";
import RenameKeyFormModal from "./RenameKeyFormModal";
import RoomWithSlot from "./RoomWithSlots";

const KeyDetailsPage = () => {
  const { Title } = Typography;

  const { keyId, locationId } = useParams<{ keyId: string; locationId: string }>();
  const [locationName, setLocationName] = useState<string>();
  const [keyDetail, setKeyDetail] = useState<Key>();
  const [mappedCount, setMappedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isModalReprintOpen, setModalReprintOpen] = useState<boolean>(false);
  const [isRenameKeyModelOpen, setRenameKeyModelOpen] = useState<boolean>(false);
  const [isCreateRoomModalOpen, setIsCreateRoomModalOpen] = useState<boolean>(false);
  const [isSelectTestRunModeModalOpen, setSelectTestRunModeModalOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>();
  const [currentCategory, setCurrentCategory] = useState<string>();
  const [keyNodeHealth, setKeyNodeHealth] = useState<Dictionary<number>>();
  const [isKeyNodeHealthLoading, setIsKeyNodeHealthLoading] = useState(true);

  const installationModeButtonRef = useRef<any>(null);

  const history = useHistory();

  const roleAndPermission = useRoleAndPermission();
  const isInstaller = roleAndPermission.isInstaller();
  const isContractor = roleAndPermission.isContractor();
  const isPC = roleAndPermission.isPC();

  useLocationQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setLocationName(data.location?.locationName);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const bindingData = (key: Key) => {
    setMappedCount(get(key, "keyStats.nodesStats.mappedCount", 0));
    setTotalCount(get(key, "keyStats.nodesStats.totalCount", 0));
    setKeyDetail(key);
    setCurrentCategory(key.categoryName);
  };

  const { refetch } = useKeyQuery({
    variables: {
      keyId,
    },
    onCompleted: ({ key }: KeyQuery) => {
      bindingData(key);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const { refetch: refetchKeyNodeHealth } = useTemporaryKeyNodeHealthQuery({
    skip: !(isInstaller || isPC),
    variables: {
      keyId,
    },
    onCompleted: (data) => {
      if (data && data.nodeOnlineStatuses) {
        setKeyNodeHealth(countBy(data.nodeOnlineStatuses, "nodeStatus"));
        setIsKeyNodeHealthLoading(false);
      }
    },
    onError: errorHandler.handleError,
  });

  const reloadKey = async () => {
    const data = await refetch({
      keyId,
    });
    bindingData(data.data.key);
    if (refetchKeyNodeHealth && (isInstaller || isPC)) {
      const keyNodeHealthData = await refetchKeyNodeHealth({
        keyId,
      });
      setKeyNodeHealth(countBy(keyNodeHealthData.data.nodeOnlineStatuses, "nodeStatus"));
    }
  };

  const [deleteKeyMutation] = useDeletePositionMutation({
    onCompleted: () => {
      message.info(`Key ${keyDetail?.keyName} has been deleted.`);
      history.push(`/locations/${locationId}/keys`);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  useKeyCategoriesQuery({
    variables: {
      locationIdInput: {
        location: {
          locationId: {
            _eq: locationId,
          },
        },
      },
    },
    onCompleted: ({ keyCategories }) => {
      setCategories(keyCategories);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const {
    data: labelPrintingKeyRoomsData,
    loading: labelPrintingKeyRoomsLoading,
    error: labelPrintingKeyRoomsError,
  } = useLabelPrintingKeyRoomsQuery({
    variables: { keyId },
  });

  const renameKey = () => {
    setRenameKeyModelOpen(true);
  };

  const submitDeleteKey = () => {
    deleteKeyMutation({
      variables: {
        positionId: keyDetail?.keyId!,
      },
    });
  };

  const deleteKey = () => {
    if (isInstaller) {
      showModal<ConfirmDeleteModalProps | Closable>({
        element: ConfirmDeleteModal,
        config: {
          message: "Are you sure? This will delete all the Rooms and mappings created in this Key",
          fieldLabel: "Enter Key Name to confirm",
          fieldPlaceholder: "Enter Key Name",
          onOk: () => {
            submitDeleteKey();
          },
          popupTitle: "Delete Key",
          confirmText: keyDetail?.keyName!,
        },
      });
    } else {
      confirm({
        className: "confirm-delete-key-popup",
        icon: <ExclamationCircleFilled className="text-warning" />,
        content: `Are you sure you wish to delete ${keyDetail?.keyName}?`,
        okText: `Delete ${keyDetail?.keyName}`,
        okButtonProps: { className: "btn-popup-primary" },
        onOk() {
          submitDeleteKey();
        },
      });
    }
  };

  const [updateKeyCategoryMutation] = useUpdateKeyCategoryToKeyMappingMutation({
    onCompleted: () => {
      message.info("Key category has been updated.");
      reloadKey();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const updateCategory = (value: any) => {
    setCurrentCategory(value);
    updateKeyCategoryMutation({ variables: { positionId: keyDetail?.keyId, categoryName: value } });
  };

  const updateCategoryWithConfirm = (value: any) => {
    if (isInstaller) {
      Modal.confirm({
        title: "Confirm changing Key Category",
        onOk: () => {
          updateCategory(value);
        },
      });
    } else {
      updateCategory(value);
    }
  };

  const showCategoryPopup = () => {
    showModal({
      element: ChangeCategoryModal,
      config: {
        onOk: updateCategoryWithConfirm,
        categories,
      },
    });
  };

  const screen = useBreakpoint();

  const bootedNodesCount = useMemo(() => {
    let bootedCount = 0;

    // all nodes that are node ONLINE or OFFLINE are considered booted
    Object.keys(keyNodeHealth || {})
      .filter((key) => !["ONLINE", "OFFLINE"].includes(key))
      .forEach((key) => {
        bootedCount += get(keyNodeHealth, [key], 0);
      });
    return bootedCount;
  }, [keyNodeHealth]);

  const helpText = useMemo(() => {
    if (locationId && locationName && keyDetail) {
      return `${locationName}: ${keyDetail.keyName} ${getKeyDetailLink(locationId, keyDetail.keyId)}`;
    }
  }, [locationId, locationName, keyDetail]);

  if (!keyDetail) {
    return null;
  }

  const getHelpButtonAs = (triggerHelp: () => void, innerHelpText?: string) => {
    return <GetHelpMenuItem onClick={triggerHelp} disabled={!!innerHelpText} className="px-m py-xs" key="Get help" />;
  };

  return (
    <div className="key-details">
      <Breadcrumb className="header-bread-crumb">
        <Breadcrumb.Item>
          <Link to="/locations">Location</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/locations/${locationId}`}>{locationName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{keyDetail?.keyName}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={classnames("key-details-header", {
          "justify-content-between d-flex align-items-center": screen.mobileAndTabletOnly,
        })}
      >
        <span
          className={classnames("title", {
            "d-inline-block": screen.mobileAndTabletOnly,
            "mb-xl": screen.desktopUp,
          })}
        >
          <Title>{keyDetail.keyName}</Title>
          <Can
            requiredPermission={Permission.KEY_EDIT}
            yes={
              !isContractor && (
                <ActionButton
                  data-testid="rename-key-testId"
                  btnClassName={screen.classes.hiddenOnMobile}
                  type="edit"
                  onClick={renameKey}
                />
              )
            }
          />
          <div className="ml-m">
            {screen.desktopOny && (
              <InstallationModeButton
                keyPositionId={keyDetail.keyId}
                currentPositionFunction={PositionFunction.AUTOSET}
                activeModal
                showStopWatch
                automaticTestRun
              />
            )}
          </div>
        </span>
        <div className="ml-s mr-auto">
          {screen.mobileAndTabletOnly && (
            <InstallationModeButton
              keyPositionId={keyDetail.keyId}
              currentPositionFunction={PositionFunction.AUTOSET}
              activeModal
              showStopWatch
              automaticTestRun
            />
          )}
        </div>
        <div
          className={classnames("align-items-center blocks ", {
            "d-flex w-100 justify-content-between": screen.desktopUp,
            "d-inline-flex w-50 justify-content-end": screen.mobileAndTabletOnly,
          })}
        >
          <div className="d-flex stacked-controls left">
            <div className={classnames("d-flex flex-column category", screen.classes.hiddenOnMobile)}>
              <span className="label text-scorpion font-weight-bold fs-sm text-uppercase mb-xs">category</span>
              <Can
                requiredPermission={Permission.KEY_EDIT}
                yes={
                  <Select
                    className="w-100"
                    value={currentCategory}
                    onChange={updateCategoryWithConfirm}
                    data-testid="key-category-testId"
                  >
                    {categories?.map((option) => (
                      <Select.Option key={option.categoryName} value={option.categoryName}>
                        {option.categoryName}
                      </Select.Option>
                    ))}
                  </Select>
                }
                no={<span className="content text-gray fs-l">{keyDetail.categoryName}</span>}
              />
            </div>
            {!isInstaller && (
              <>
                <div className={classnames("progress d-flex flex-column", screen.classes.hiddenOnMobile)}>
                  <span className="label text-scorpion font-weight-bold fs-sm text-uppercase mb-xs">progress</span>
                  <Progress
                    percent={totalCount === 0 ? 0 : (100 * mappedCount) / totalCount}
                    size="small"
                    className="content text-gray fs-l progress-bar"
                    strokeWidth={7}
                    format={() => `${mappedCount}/${totalCount} nodes`}
                    status="normal"
                  />
                </div>
                {isPC && (
                  <div className={classnames("key-health d-flex flex-column")} data-testid="key-health-testId">
                    <span className="label text-scorpion font-weight-bold fs-sm text-uppercase mb-xs">KEY HEALTH</span>
                    <ObjectHealth
                      online={keyNodeHealth?.ONLINE || 0}
                      offline={keyNodeHealth?.OFFLINE || 0}
                      booted={bootedNodesCount}
                      loading={isKeyNodeHealthLoading}
                      className="font-weight-bold fs-xl"
                    />
                  </div>
                )}
              </>
            )}
            {isInstaller && (
              <div className={classnames("key-health d-flex flex-column")} data-testid="key-health-testId">
                <span className="label text-scorpion font-weight-bold fs-sm text-uppercase mb-xs">KEY HEALTH</span>
                <ObjectHealth
                  online={keyNodeHealth?.ONLINE || 0}
                  offline={keyNodeHealth?.OFFLINE || 0}
                  booted={bootedNodesCount}
                  loading={isKeyNodeHealthLoading}
                  className="font-weight-bold fs-xl"
                />
              </div>
            )}
          </div>
          <div>
            <div className="d-flex align-items-center ml-m">
              <div className={screen.classes.hiddenOnDesktop}>
                <Can
                  requiredPermission={Permission.KEY_EDIT}
                  yes={
                    <MenuDropdown
                      mode="horizontal"
                      menu={
                        <Menu>
                          <Menu.Item onClick={() => renameKey()}>Rename Key</Menu.Item>
                          <Menu.Item onClick={() => deleteKey()}>Delete Key</Menu.Item>
                          <Menu.Divider />
                          <Menu.Item onClick={() => setIsCreateRoomModalOpen(true)}>Add Room</Menu.Item>
                          <Menu.Divider />
                          <Menu.Item onClick={() => showCategoryPopup()}>Change Category</Menu.Item>
                          <Menu.Item>
                            <GetHelpButton
                              helpText={helpText}
                              as={({ triggerHelp, helpText: innerHelpText }) =>
                                getHelpButtonAs(triggerHelp, innerHelpText)
                              }
                            />
                          </Menu.Item>
                        </Menu>
                      }
                    />
                  }
                />
              </div>
              <div
                className={classnames(
                  "d-flex align-items-center stacked-controls right pt-l",
                  screen.classes.hiddenOnMobile
                )}
              >
                <Can
                  requiredPermission={Permission.KEY_EDIT}
                  yes={
                    <ActionButton data-testid="delete-key-testId" type="delete" onClick={deleteKey} className="fs-xl" />
                  }
                />
                <Can
                  requiredPermission={Permission.KEY_EDIT}
                  yes={
                    <Button
                      type={screen.desktopUp ? "primary" : "link"}
                      className={classnames("ant-btn ant-btn-primary", {
                        "fs-xl btn-icon-only": screen.mobileAndTabletOnly,
                      })}
                      icon={screen.desktopUp ? null : <PlusCircleOutlined />}
                      onClick={() => setIsCreateRoomModalOpen(true)}
                    >
                      Add New Room
                    </Button>
                  }
                />
                <Can
                  requiredPermission={Permission.KEY_LABEL_PRINT}
                  yes={
                    keyDetail.keyStats.mappingStatus === MappingStatus.Completed && (
                      <Button
                        type="primary"
                        className="ant-btn ant-btn-primary"
                        icon={<PrinterOutlined />}
                        onClick={() => setModalReprintOpen(true)}
                      >
                        Reprint Labels
                      </Button>
                    )
                  }
                />

                {(isInstaller || isPC) && (
                  <GetHelpButton
                    helpText={helpText}
                    className={classnames("ant-btn ant-btn-primary", {
                      "fs-xl btn-icon-only": screen.mobileAndTabletOnly,
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RoomWithSlot
        locationId={locationId}
        keyDetail={keyDetail}
        reloadKey={reloadKey}
        reloadKeyBinding={bindingData}
        locationName={locationName}
      />

      {/* Rename key modal */}
      {isRenameKeyModelOpen && (
        <RenameKeyFormModal
          keyId={keyDetail.keyId}
          keyName={keyDetail.keyName}
          reloadKeys={reloadKey}
          closeModal={() => setRenameKeyModelOpen(false)}
        />
      )}

      {/* Create room modal */}
      {isCreateRoomModalOpen && (
        <CreateRoomFormModal
          locationId={locationId}
          keyId={keyDetail.keyId}
          onCompleted={reloadKey}
          closeModal={() => setIsCreateRoomModalOpen(false)}
        />
      )}

      {/* Reprint labels modal */}
      <Modal
        title="Reprint Labels"
        centered
        visible={isModalReprintOpen}
        footer={null}
        onCancel={() => setModalReprintOpen(false)}
        width={400}
      >
        <KeyAndRooms
          keyId={keyId}
          keyName={labelPrintingKeyRoomsData?.key.keyName}
          rooms={labelPrintingKeyRoomsData?.key.rooms}
          loading={labelPrintingKeyRoomsLoading}
          error={labelPrintingKeyRoomsError}
        />
      </Modal>
      {/*  Continue previous test run modal */}
      <SelectTestRunModeModal
        isSelectTestRunModeModalOpen={isSelectTestRunModeModalOpen}
        setSelectTestRunModeModalOpen={setSelectTestRunModeModalOpen}
        installationModeButtonRef={installationModeButtonRef}
      />
    </div>
  );
};

export default KeyDetailsPage;
