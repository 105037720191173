/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Select } from "antd";
import classNames from "classnames";

import { DownArrowIcon } from "components/Icons";
import useBreakpoint from "hooks/use-breakpoint";
import React, { useState } from "react";
import "./UnitSelect.scss";

export enum Units {
  localCurrency,
  dollar,
  kWh,
  wh,
  monthly,
  weekly,
}

type Props = {
  options: { value: Units; label: string }[];
  value: Units;
  onChange: (value: Units) => void;
  label: string;
};

const UnitSelect = (props: Props) => {
  const { options, value, onChange, label } = props;
  const [visible, setVisible] = useState(false);

  const screen = useBreakpoint();

  return (
    <div
      className={classNames("unit-select d-inline-block", {
        "bg-white": screen.mobileAndTabletOnly || visible,
      })}
    >
      <label
        className={classNames("unit-select__label cursor-pointer", {
          "pl-l": screen.desktopOny,
          "pl-m": screen.mobileAndTabletOnly,
        })}
        onClick={() => {
          setVisible(!visible);
        }}
      >
        {`${label}: `}
      </label>
      <Select
        className="unit-select__select"
        value={value}
        bordered={false}
        suffixIcon={<DownArrowIcon />}
        dropdownMatchSelectWidth={0}
        dropdownClassName="unit-select__dropdown"
        onDropdownVisibleChange={setVisible}
        open={visible}
        onChange={onChange}
      >
        {options.map((option) => {
          return (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default UnitSelect;
