import React, { useRef, useState } from "react";

import FirmwareList from "./FirmwareList/FirmwareList";
import FirmwareUploadModal from "./FirmwareUploadModal/FirmwareUploadModal";
import { FirmwareRelease } from "./FirmwareList/FirmwareList.d";
import { OTA_NODE_TYPES } from "../OtaDetails/OtaDetails.constants";
import { OtaNodeTypes } from "../OtaDetails/OtaDetails.d";

const FwManagement = () => {
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [currentNodeSubType, setCurrentNodeSubType] = useState<OtaNodeTypes | null>(null);
  const [currentFirmware, setCurrentFirmware] = useState<FirmwareRelease | null>(null);

  const reloadFirmwareList = useRef<(() => void) | null>(null);

  const handleCloseModal = () => {
    setOpenCreateModal(false);
    setCurrentNodeSubType(null);
    setCurrentFirmware(null);
    reloadFirmwareList.current = null;
  };

  return (
    <>
      {OTA_NODE_TYPES.map((nodeSubType) => {
        return (
          <FirmwareList
            nodeSubType={nodeSubType}
            key={nodeSubType}
            openUploadModal={(selectedNodeSubType, firmware, reloadFirmwares) => {
              reloadFirmwareList.current = reloadFirmwares;
              setCurrentNodeSubType(selectedNodeSubType);
              setCurrentFirmware(firmware);
              setOpenCreateModal(true);
            }}
          />
        );
      })}
      {currentNodeSubType && (
        <FirmwareUploadModal
          visible={openCreateModal}
          closeModal={handleCloseModal}
          nodeSubType={currentNodeSubType}
          firmware={currentFirmware}
          reloadFirmwareList={reloadFirmwareList.current as () => void}
        />
      )}
    </>
  );
};

export default FwManagement;
