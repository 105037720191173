import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Col, message, Modal, Row, Tooltip } from "antd";
import {
  NodeListForOtaQuery,
  Order_By,
  useBulkLockFirmwareMutation,
  useBulkNodeUpdateMutation,
  useFirmwareReleasesForDropdownQuery,
  useNodeListForOtaQuery,
  useBulkUpdateFirmwareMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import ListFilter from "components/ListFilter/ListFilter";
import { AntdTablePagination } from "components/Table/Table.d";
import { ReloadOutlined, SettingFilled } from "@ant-design/icons";
import useBreakpoint from "hooks/use-breakpoint";
import OtaNodeListTable from "../OtaNodeListTable/OtaNodeListTable";
import { INITIAL_FILTER, Node, NodeFilterInput } from "./OtaNodeList.d";
import errorHandler from "../../../../errorHandler";
import useNodeSelection from "./hooks/use-node-selection";
import { getNodeListWhereFromFilter, getOrderByFromSort, transform } from "./OtaNodeList.helper";
import showModal from "../../../../components/Modal/show";
import LockFwVersionModal from "./LockFwVersionModal/LockFwVersionModal";
import UpdateFwVersionModal from "./UpdateFwVersionModal/UpdateFwVersionModal";
import UpdateCommentModal from "./UpdateCommentModal/UpdateCommentModal";
import OtaNodeFilter from "./OtaNodeFilter/OtaNodeFilter";

const OtaNodeList = () => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [filter, setFilter] = useState<NodeFilterInput>(INITIAL_FILTER);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [sort, setSort] = useState<any>({});

  const screen = useBreakpoint();

  const {
    getSelectedNodeIds,
    selectedNodeSet,
    selectionStatsMessage,
    onSelectAll,
    handleNodeSelected,
    onTotalCountChange,
    resetSelection,
    onSourceBinding,
    isSelectAll,
  } = useNodeSelection();

  const handleSortChange = (columnHeader: string) => {
    setSort({ [columnHeader]: sort[columnHeader] === Order_By.Asc ? Order_By.Desc : Order_By.Asc });
  };

  const reloadBinding = (data: NodeListForOtaQuery) => {
    const newNodes = transform(data.nodes);
    setNodes(newNodes);
    onSourceBinding(newNodes);

    const nodeCount = data.nodeAggregate.aggregate?.count || 0;
    setPagination((currentPagination) => ({
      ...currentPagination,
      total: nodeCount,
    }));
    onTotalCountChange(nodeCount);
  };

  const {
    loading,
    error,
    refetch: reloadNodes,
  } = useNodeListForOtaQuery({
    variables: {
      where: getNodeListWhereFromFilter(filter),
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
      order_by: [getOrderByFromSort(sort)],
    },
    onCompleted(data: NodeListForOtaQuery) {
      reloadBinding(data);
    },
    onError: errorHandler.handleError,
  });

  const reloadList = async () => {
    const data = await reloadNodes();
    reloadBinding(data.data);
    resetSelection();
  };

  const [bulkNodeUpdateMutation] = useBulkNodeUpdateMutation({
    onCompleted: () => {
      message.success(`Nodes updated`);
      reloadList();
    },
    onError: errorHandler.handleError,
  });

  const [bulkLockFirmwareMutation] = useBulkLockFirmwareMutation({
    onCompleted: () => {
      message.success(`Nodes FW Version locked`);
      reloadList();
    },
    onError: errorHandler.handleError,
  });

  const [bulkUpdateFirmwareMutation] = useBulkUpdateFirmwareMutation({
    onCompleted: () => {
      message.success(`Nodes FW Version updated`);
      reloadList();
    },
    onError: errorHandler.handleError,
  });

  const { refetch: getFirmwareReleasesForDropdown } = useFirmwareReleasesForDropdownQuery({
    variables: {
      where: {
        nodeSubType: {
          _eq: filter.nodeSubType,
        },
      },
    },
    onError: errorHandler.handleError,
    skip: true,
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const statMessage = useMemo(() => {
    if (error) return "Error on loading nodes.";

    if (nodes) {
      const { current, pageSize, total } = pagination;
      const start = (current - 1) * pageSize + 1;
      const end = Math.min(total, start + pageSize - 1);

      return `Showing ${start}-${end} of ${total} nodes`;
    }

    return "";
  }, [nodes, error, pagination]);

  const handleTableChange = (page: any) => {
    setPagination({
      current: page.current,
      pageSize: page.pageSize,
      total: pagination.total,
    });
  };

  const handleFilterChange = (newFilter: NodeFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
    resetSelection();
  };

  const fetchSelectedNodes = (lockable: boolean = false, newFilters: Partial<NodeFilterInput> = {}) => {
    return getSelectedNodeIds({
      where: getNodeListWhereFromFilter({ ...filter, ...newFilters }, lockable),
    });
  };

  const bulkLockVersion = () => {
    showModal({
      element: LockFwVersionModal,
      config: {
        isLockMode: true,
        onOk: async (comment: string) => {
          const nodeIds = await fetchSelectedNodes();
          let lockableNodes = [];
          if (isSelectAll) {
            lockableNodes = await fetchSelectedNodes(true);
          } else {
            lockableNodes = nodes.filter((node) => {
              return nodeIds.includes(node.nodeMacId) && node.lockable;
            });
          }
          if (nodeIds?.length > lockableNodes?.length) {
            message.error("Cannot lock unlockable nodes");
            return;
          }
          await bulkLockFirmwareMutation({
            variables: {
              nodeIds,
              input: {
                firmwareVersionLocked: true,
                otaComment: comment,
              },
            },
          });
        },
      },
    });
  };

  const bulkUnlockVersion = () => {
    showModal({
      element: LockFwVersionModal,
      config: {
        isLockMode: false,
        onOk: async (comment: string) => {
          const nodeIds = await fetchSelectedNodes();
          await bulkNodeUpdateMutation({
            variables: {
              nodeIds,
              input: {
                firmwareVersionLocked: false,
                otaComment: comment,
              },
            },
          });
        },
      },
    });
  };

  const bulkUpdateComment = () => {
    showModal({
      element: UpdateCommentModal,
      config: {
        onOk: async (comment: string) => {
          const nodeIds = await fetchSelectedNodes();
          await bulkNodeUpdateMutation({
            variables: {
              nodeIds,
              input: {
                otaComment: comment,
              },
            },
          });
        },
      },
    });
  };

  const bulkUpdateFwVersion = async () => {
    const firmwareReleasesData = await getFirmwareReleasesForDropdown();
    showModal({
      element: UpdateFwVersionModal,
      config: {
        firmwares: firmwareReleasesData.data?.firmwareReleases || [],
        onOk: async (selectedVersion: number) => {
          const nodeIds = await fetchSelectedNodes();
          let unlockedFWNodes = [];
          if (isSelectAll) {
            unlockedFWNodes = await fetchSelectedNodes(false, { firmwareVersionExcludeLocked: true });
          } else {
            unlockedFWNodes = nodes.filter((node) => {
              return nodeIds.includes(node.nodeMacId) && !node.firmwareVersionLocked;
            });
          }
          if (nodeIds?.length > unlockedFWNodes?.length) {
            message.error("Cannot update firmware for locked nodes.");
            return;
          }
          await bulkUpdateFirmwareMutation({
            variables: {
              nodeIds,
              input: {
                targetFirmwareReleaseId: selectedVersion,
              },
            },
          });
        },
      },
    });
  };

  const resetFailedCount = async () => {
    Modal.confirm({
      title: `Reset failed count`,
      content: "Failed count will be reseted.",
      okText: "OK",
      onOk: async () => {
        const nodeIds = await fetchSelectedNodes();
        await bulkNodeUpdateMutation({
          variables: {
            nodeIds,
            input: {
              otaFailedUpgradeAttempts: 0,
            },
          },
        });
      },
    });
  };

  const handleReloadList = () => {
    reloadList();
    message.success("Reload success");
  };

  return (
    <>
      <Row justify="space-between" className="mb-m">
        <Col>
          <h2 className="d-inline mr-l">Nodes</h2>
          <p className="d-inline">{statMessage}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="mb-l">
            <ListFilter
              currentFilter={filter}
              onChange={handleFilterChange}
              searchField="nodeMacId"
              searchPlaceholder="Type to search"
              hideAdvancedFilter
            />
          </div>
          <Row justify="space-between" gutter={10}>
            <Col className={screen.mobileAndTabletOnly ? "mb-s" : ""}>
              <OtaNodeFilter onFilterChange={handleFilterChange} initialFilter={filter} />
            </Col>
            <Col className="text-right">
              <div className="d-inline-flex flex-row gap-s flex-wrap">
                <Button type="primary" onClick={bulkLockVersion} disabled={selectedNodeSet.size === 0}>
                  Bulk Lock FW
                </Button>
                <Button type="primary" onClick={bulkUnlockVersion} disabled={selectedNodeSet.size === 0}>
                  Bulk Unlock FW
                </Button>
                <Button type="primary" onClick={bulkUpdateComment} disabled={selectedNodeSet.size === 0}>
                  Bulk Update Comment
                </Button>
                <Button type="primary" onClick={bulkUpdateFwVersion} disabled={selectedNodeSet.size === 0}>
                  Bulk Update FW
                </Button>
                <Button type="primary" onClick={resetFailedCount} disabled={selectedNodeSet.size === 0}>
                  Reset Failed Count
                </Button>
                <Tooltip title="Reload nodes">
                  <Button type="link" onClick={handleReloadList} icon={<ReloadOutlined />} />
                </Tooltip>
                <Tooltip title="OTA Location Settings">
                  <Link to="/location-ota-settings">
                    <Button type="link" icon={<SettingFilled />} />
                  </Link>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <Row>
            {!loading && (
              <div className="py-s">
                <span className="text-primary">{selectionStatsMessage}</span>
              </div>
            )}
            <OtaNodeListTable
              tableData={nodes}
              handleTableChange={handleTableChange}
              loading={loading}
              error={error}
              sortBy={handleSortChange}
              sort={sort}
              pagination={{
                ...pagination,
                pageSizeOptions: ["10", "20", "50", "100"],
                showSizeChanger: true,
              }}
              rowSelection={{
                selectedRowKeys: Array.from(selectedNodeSet),
                type: "checkbox",
                onSelectAll: (selected: boolean) => {
                  onSelectAll(selected, nodes as Node[]);
                },
                onSelect: (record: Node, selected: boolean) => {
                  handleNodeSelected(nodes as Node[], [record] as Node[], selected);
                },
                getCheckboxProps: (record: Node) => ({
                  name: record.nodeMacId,
                }),
                preserveSelectedRowKeys: true,
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OtaNodeList;
