import React, { useState } from "react";
import errorHandler from "errorHandler";

import { Order_By, useGetNodeLifeCycleHistoryQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { NodeLifecycleEventTable } from "./NodeLifecycleEventTable";
import { NodeManagementHeader } from "./NodeManagementHeader";

const initialValues = {
  nodeMacId: "",
  eventType: undefined,
  comment: "",
};

export default () => {
  const [nodeMacId, setNodeMacId] = useState(initialValues.nodeMacId);

  const { data } = useGetNodeLifeCycleHistoryQuery({
    variables: { order_by: [{ changedAt: Order_By.Desc }] },
    onError: errorHandler.handleError,
  });

  return (
    <>
      <NodeManagementHeader handleSearchChange={setNodeMacId} />
      <NodeLifecycleEventTable
        data={(data?.sensorflow_v_node_life_cycle_events || []).filter((x) => x.nodeMacId?.includes(nodeMacId))}
      />
    </>
  );
};
