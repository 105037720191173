import { Moment } from "moment";
import moment from "moment-timezone";

const DATETIME_FORMAT = "DD/MM/YYYY, hh:mm:ss A";
export const TZ_FORMAT = "Z";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATETIME_24H_FORMAT = "DD/MM/YYYY HH:mm";
export const DATETIME_12H_FORMAT = "DD/MM/YYYY hh:mm A";

export const TIME_24H_FORMAT = "HH:mm";
export const TIME_12H_FORMAT = "hh:mm A";

export const SHORT_DATE_FORMAT = "DD MMM YYYY";
export const MONTH_YEAR_FORMAT = "MMM YYYY";
export const MONTH_FORMAT = "MMMM";
export const SHORT_MONTH_FORMAT = "MMM";
export const YEAR_FORMAT = "YYYY";
export const YEAR_MONTH_DATE_FORMAT = "YYYY-MM-DD";
export const LONG_DATE_FORMAT = "DD MMMM YYYY";
export const WEEK_FORMAT = "[W]WW";

export const formatDateTime = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(DATETIME_FORMAT);
  }
  return moment(date).format(DATETIME_FORMAT);
};

export const formatUnixDate = (date: any, tz?: string) => {
  if (!date) return "";
  if (tz) {
    return moment.unix(date).tz(tz).format(DATETIME_FORMAT);
  }
  return moment.unix(date).format(DATETIME_FORMAT);
};

export const parseDate = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz);
  }
  return moment(date);
};

export const formatFromNow = (date: any, tz?: string) => {
  if (!date) return "";
  if (tz) {
    return moment(date).tz(tz).fromNow();
  }
  return moment(date).fromNow();
};

export const formatDateTime24h = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(DATETIME_24H_FORMAT);
  }
  return moment(date).format(DATETIME_24H_FORMAT);
};

export const formatDateTime12h = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(DATETIME_12H_FORMAT);
  }
  return moment(date).format(DATETIME_12H_FORMAT);
};

export const formatTime12h = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(TIME_12H_FORMAT);
  }
  return moment(date).format(TIME_12H_FORMAT);
};

export const formatTime24h = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(TIME_24H_FORMAT);
  }
  return moment(date).format(TIME_24H_FORMAT);
};

export const formatDate = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(DATE_FORMAT);
  }
  return moment(date).format(DATE_FORMAT);
};

export const formatTimezone = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(TZ_FORMAT);
  }
  return moment(date).format(TZ_FORMAT);
};

export const formatUTCTimestamp = (date: Moment) => {
  if (!date) return null;
  return date.utc().format();
};

export const formatShortDate = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(SHORT_DATE_FORMAT);
  }
  return moment(date).format(SHORT_DATE_FORMAT);
};

export const formatShortDateAndTime = (date: any, tz?: string) => {
  if (!date) return null;
  const dateWithTz = !tz ? moment(date) : moment(date).tz(tz);
  return {
    day: dateWithTz.format(SHORT_DATE_FORMAT),
    time: dateWithTz.format(TIME_24H_FORMAT),
  };
};

export const formatMonth = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(MONTH_FORMAT);
  }
  return moment(date).format(MONTH_FORMAT);
};

export const formatShortMonth = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(SHORT_MONTH_FORMAT);
  }
  return moment(date).format(SHORT_MONTH_FORMAT);
};

export const formatYear = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(YEAR_FORMAT);
  }
  return moment(date).format(YEAR_FORMAT);
};

export const formatYearMonthDate = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(YEAR_MONTH_DATE_FORMAT);
  }
  return moment(date).format(YEAR_MONTH_DATE_FORMAT);
};

export const formatLongDate = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(LONG_DATE_FORMAT);
  }
  return moment(date).format(LONG_DATE_FORMAT);
};

export const formatWeek = (date: any, tz?: string) => {
  if (!date) return null;
  if (tz) {
    return moment(date).tz(tz).format(WEEK_FORMAT);
  }
  return moment(date).format(WEEK_FORMAT);
};

export const isOverdueDate = (date: any, tz?: string) => {
  if (!date) return null;

  let dueDate = moment(date);
  if (tz) dueDate = dueDate.tz(tz);
  const now = moment.now();
  return dueDate.diff(now, "s") < 0;
};

export const correctTimezone = (dateString: string, targetOffset: number): string => {
  const [datePart] = dateString.split("+");

  const offsetHours = Math.floor(Math.abs(targetOffset) / 60);
  const offsetMinutesPart = Math.abs(targetOffset) % 60;
  const sign = targetOffset >= 0 ? "+" : "-";
  const formattedOffset = `${sign}${String(offsetHours).padStart(2, "0")}:${String(offsetMinutesPart).padStart(
    2,
    "0"
  )}`;
  const correctedDateString = `${datePart}${formattedOffset}`;

  return correctedDateString;
};
