import React from "react";

const LiveValue = (props: { value: any; hasData: boolean; emptyValue?: any }): React.ReactElement => {
  const { value, hasData, emptyValue = <span>_</span> } = props;

  if (!hasData) {
    return <span>_</span>;
  }
  if ((value === null || value === undefined) && emptyValue) return emptyValue;
  return value;
};

export default LiveValue;
