import { Col, Pagination, Row, Select } from "antd";
import React from "react";
import { DEFAULT_PAGE_SIZE_OPTIONS } from "./constants";
import { getDefaultDisplayingHelpText } from "./helpers";
import { ITablePaginationProps } from "./Table.d";

const TablePagination = ({ pagination, onPaginationChanged, getDisplayText }: ITablePaginationProps) => {
  return (
    <Row justify="space-between" align="middle" className="my-xl">
      <Col>
        <Select
          data-testid="page-size-selector"
          className="w-36r mx-xs"
          value={pagination.pageSize}
          onSelect={(pgSz) => onPaginationChanged({ ...pagination, pageSize: pgSz, current: 1 })}
          options={DEFAULT_PAGE_SIZE_OPTIONS.map((pgSz) => ({ value: pgSz, label: `Displaying ${pgSz}` }))}
        />
        <span>{getDisplayText?.(pagination) || getDefaultDisplayingHelpText(pagination)}</span>
      </Col>
      <Col>
        <Pagination
          total={pagination.total}
          onChange={(page) => {
            window.scrollTo(0, 0);
            onPaginationChanged({ ...pagination, current: page });
          }}
          showSizeChanger={false}
          current={pagination.current}
          pageSize={pagination.pageSize}
        />
      </Col>
    </Row>
  );
};

export default TablePagination;
