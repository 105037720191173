import { Row, Col } from "antd";
import ListFilter from "components/ListFilter/ListFilter";
import { AntdTablePagination } from "components/Table/Table.d";
import React, { useEffect, useState } from "react";
import {
  KeyFilterInput,
  KeySortInput,
  SimpleKeyListQuery,
  SortOptions,
  useSimpleKeyListQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { isEmpty } from "lodash";
import errorHandler from "errorHandler";
import { getObjBySearch } from "utils/url";
import { useLocation, useParams } from "react-router-dom";
import { Key } from "../KeyList/KeyList.d";
import KeyListTable from "./KeyListTable";

const ContractorKeyList = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = useLocation();
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [filter, setFilter] = useState<KeyFilterInput>({ locationId });
  const [sort, setSort] = useState<KeySortInput>({ keyName: SortOptions.Asc });
  const [keys, setKeys] = useState<Key[]>([]);

  const keyName = new URLSearchParams(location.search).get("keyName");

  const reloadBinding = (data: SimpleKeyListQuery) => {
    if (data) {
      setKeys(data?.keys?.keys || []);
      setPagination({
        ...pagination,
        total: data?.keys?.pagination?.count || 0,
      });
    }
  };

  const { loading: keysLoading, error: keysError } = useSimpleKeyListQuery({
    variables: {
      filter: {
        ...filter,
        locationId,
      },
      sort,
      pagination: {
        offset: (pagination.current - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      },
    },
    onCompleted: (data) => {
      reloadBinding(data);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleTableChange = (desiredPagination: AntdTablePagination) => {
    setPagination(desiredPagination);
  };

  const handleSortChange = (columnHeader: keyof KeySortInput) => {
    setSort({ [columnHeader]: sort[columnHeader] === SortOptions.Asc ? SortOptions.Desc : SortOptions.Asc });
  };

  const handleFilterChange = (newFilter: KeyFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  useEffect(() => {
    const searchObj = getObjBySearch<KeyFilterInput>(location.search);
    if (!isEmpty(searchObj)) handleFilterChange(searchObj);
    // eslint-disable-next-line
  }, []);

  return (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 18 }}>
        <ListFilter
          onChange={handleFilterChange}
          searchField="keyName"
          searchPlaceholder="Search key name"
          containerClassName="mb-xl"
          hideAdvancedFilter
          isSaveKeySearch
          defaultKeySearch={keyName as string}
        />
        <KeyListTable
          tableData={keys}
          sortBy={handleSortChange}
          sort={sort}
          pagination={{
            ...pagination,
            pageSizeOption: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
          handleTableChange={handleTableChange}
          loading={keysLoading}
          error={keysError}
        />
      </Col>
    </Row>
  );
};

export default ContractorKeyList;
