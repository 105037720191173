import React, { useCallback, useEffect, useState } from "react";
import { FilterOption, FilterOptionType } from "types/types";
import { Button, Col, Drawer, Form, Row, Select, Switch } from "antd";
import { FilterV2Icon } from "components/Icons";
import { CloseOutlined } from "@ant-design/icons";
import { FilterOptionType as FilterOptionTypePact } from "pacts/app-webcore/hasura-webcore.graphql";
import { CheckSelect } from "components/CheckSelect";
import classNames from "classnames";
import "./FilterDrawer.scss";
import { kebabCase } from "lodash";

export interface FilterDrawerProps {
  [x: string]: any;
  filterOptions: Array<FilterOption>;
  currentFilter?: any;
  onFilterChange?: (filter: any) => void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ filterOptions, currentFilter, onFilterChange }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = useCallback((state: boolean) => () => setOpen(state), [setOpen]);

  const renderItem = useCallback((filterOption: FilterOption, value: any) => {
    const { type, options = [], field, placeholder, label, placeholderSearch, disabled } = filterOption;
    let customOptions = options || ([] as any);
    if (options && typeof options[0] === "string") {
      customOptions = options?.map((x) => ({ label: x, value: x }));
    }
    switch (type) {
      case FilterOptionType.MultipleSelect:
        return (
          <CheckSelect
            key={field}
            options={customOptions || []}
            value={value || []}
            placeholder={placeholder as string}
            label={label}
            placeholderSearch={placeholderSearch}
            disabled={disabled}
          />
        );
      case FilterOptionTypePact.Select:
        return <Select key={field} options={customOptions} placeholder={placeholder as string} disabled={disabled} />;
      case FilterOptionType.Switch:
        return <Switch key={field} disabled={disabled} />;
      default:
        return null;
    }
  }, []);

  const onFinish = useCallback(
    (values) => {
      handleOpen(false)();
      if (onFilterChange) onFilterChange(values);
    },
    [onFilterChange, handleOpen]
  );

  useEffect(() => {
    form.setFieldsValue(currentFilter);
  }, [form, currentFilter]);

  return (
    <>
      <Button onClick={handleOpen(true)}>
        Filter <FilterV2Icon className="ml-xs" />
      </Button>
      <Drawer
        title="Filter"
        placement="right"
        open={open}
        onClose={handleOpen(false)}
        width={320}
        closable={false}
        maskClosable={false}
        extra={<CloseOutlined onClick={handleOpen(false)} />}
        zIndex={10}
        footer={
          <Row gutter={[16, 16]} justify="end">
            <Col span={12}>
              <Button
                size="large"
                className="w-100"
                onClick={() => {
                  form.resetFields();
                  if (onFilterChange) onFilterChange({});
                  handleOpen(false)();
                }}
              >
                Clear Filter
              </Button>
            </Col>
            <Col span={12}>
              <Button size="large" className="w-100" type="primary" onClick={form.submit}>
                Apply
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form} onFinish={onFinish}>
          {filterOptions.map((filter) => (
            <Form.Item
              key={filter.field}
              name={filter.field}
              label={filter.label}
              className={classNames(kebabCase(filter.field), "custom-field")}
            >
              {renderItem(filter, currentFilter[filter.field])}
            </Form.Item>
          ))}
        </Form>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
