import React, { useState } from "react";

import { Col, Radio, Row, Select } from "antd";
import classnames from "classnames";
import "./RoomActuator.scss";
import { NodeType, useGetShowHeatingModeQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { ActingMode, OperationalMode } from "pages/Key/types";
import { useParams } from "react-router-dom";
import { AvailableOperationalModes } from "utils/locationMetadata";
import BaseACModelDropdown from "components/BaseACModelDropdown";
import { ACTUATOR, Actuator, ACIRActuator, FourPFCActuator, ActuatorFactory } from "./DataTypes";

type RoomActuatorProps = {
  actuator: Actuator;
  onActuatorChange: (actuator: Actuator) => void;
  onSessionChangeCallback: () => void;
};

const RoomActuator = ({ actuator, onActuatorChange, onSessionChangeCallback }: RoomActuatorProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [availableOperationalMode, setAvailableOperationalMode] = useState<AvailableOperationalModes>();

  const handleActualChange = (type: string) => {
    onActuatorChange(ActuatorFactory.getInstance().createActuator(type));
    onSessionChangeCallback();
  };

  const handleACIRModelChange = (acModelId: string) => {
    (actuator as ACIRActuator).setAcModelId(acModelId);
    onSessionChangeCallback();
  };

  const handleActingModeChange = (actingMode: string) => {
    (actuator as FourPFCActuator).setActingMode(actingMode);
    onSessionChangeCallback();
  };

  const handleOperationalModeChange = (operationalMode: string) => {
    (actuator as FourPFCActuator).setOperationalMode(operationalMode);
    onSessionChangeCallback();
  };

  useGetShowHeatingModeQuery({
    variables: {
      locationId,
    },
    onCompleted: ({ locationMetadata: data }) => {
      if (data?.availableOperationalModes) {
        setAvailableOperationalMode(data.availableOperationalModes as AvailableOperationalModes);
      }
    },
  });

  return (
    <Row className="room-actuator">
      <Col flex="auto">
        <p>Thermostat</p>
        <Radio.Group
          value={actuator.nodeSubType.toString()}
          onChange={(e) => handleActualChange(e.target.value)}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value={ACTUATOR.ACIR.toString()}>ACIR</Radio.Button>
          <Radio.Button value={ACTUATOR.TWOPFC.toString()}>2PFC</Radio.Button>
          <Radio.Button value={ACTUATOR.DAIKIN.toString()}>Daikin</Radio.Button>
          <Radio.Button value={ACTUATOR.FOURPFC.toString()}>4PFC</Radio.Button>
        </Radio.Group>
      </Col>

      {actuator.nodeType === NodeType.Aircon && actuator.nodeSubType === ACTUATOR.ACIR && (
        <Col>
          <BaseACModelDropdown
            label={<p>Base AC Model</p>}
            onChange={handleACIRModelChange}
            baseAcModelId={(actuator as ACIRActuator).acModelId}
            className={classnames("ac-model", { invalid: !actuator.isValid() })}
          />
          {actuator.isValid() || <p className="required-text">It is required</p>}
        </Col>
      )}
      {actuator.nodeSubType === ACTUATOR.FOURPFC && (
        <Col>
          <p>Acting Mode</p>
          <Select
            value={(actuator as FourPFCActuator).actingMode}
            className={classnames("ac-model", { invalid: !actuator.isValid() })}
            onChange={handleActingModeChange}
            placeholder="Select Acting Mode"
            defaultValue={ActingMode.Default}
          >
            <Select.Option value={ActingMode.Default}>Default</Select.Option>
            <Select.Option value={ActingMode.TwoPFC}>2PFC</Select.Option>
          </Select>
          {actuator.isValid() || <p className="required-text">It is required</p>}

          {(actuator as FourPFCActuator).actingMode === ActingMode.TwoPFC && (
            <>
              <p className="mt-m">Operational Mode</p>
              <Select
                value={(actuator as FourPFCActuator).operationalMode}
                className={classnames("ac-model", { invalid: !actuator.isValid() })}
                onChange={handleOperationalModeChange}
                placeholder="Select Operational Mode"
                defaultValue={
                  availableOperationalMode === AvailableOperationalModes.Heating
                    ? OperationalMode.Heating
                    : OperationalMode.Cooling
                }
              >
                <Select.Option
                  disabled={availableOperationalMode === AvailableOperationalModes.Heating}
                  value={OperationalMode.Cooling}
                >
                  Cooling
                </Select.Option>
                <Select.Option
                  disabled={availableOperationalMode === AvailableOperationalModes.Cooling}
                  value={OperationalMode.Heating}
                >
                  Heating
                </Select.Option>
              </Select>
            </>
          )}
        </Col>
      )}
    </Row>
  );
};

export default RoomActuator;
