import "reflect-metadata";
import capitalize from "lodash/capitalize";
import { Type } from "class-transformer";
import { v4 as uuid } from "uuid";
import {
  KeyCategoriesWithTemplatesQuery,
  NodeSubType,
  NodeType,
  Sensorflow_Key_Category_Templates_Insert_Input,
  Sensorflow_Key_Category_To_Key_Position_Insert_Input,
} from "pacts/app-webcore/hasura-webcore.graphql";
import omit from "lodash/omit";
import concat from "lodash/concat";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { ActingMode, OperationalMode, PositionConfigurationRecordType } from "pages/Key/types";
import { POSITION_CONFIGURATION_DEFAULT } from "utils/constants";

enum ACTUATOR {
  ACIR = NodeSubType.AirconAcir,
  TWOPFC = NodeSubType.Aircon_2pfc,
  DAIKIN = NodeSubType.AirconDaikin,
  FOURPFC = NodeSubType.Aircon_4pfc,
}

abstract class IDName {
  id: string;

  name: string;

  constructor(name: string) {
    this.id = uuid();
    this.name = name;
  }

  setName(name: string): void {
    this.name = name;
  }

  isValid(): boolean {
    return !isEmpty(this.name);
  }
}

class Actuator {
  slotName?: string;

  nodeSubType: ACTUATOR;

  nodeType: NodeType;

  constructor(type: ACTUATOR) {
    this.nodeSubType = type;
    this.nodeType = NodeType.Aircon;
  }

  setSlotNamePrefix(roomName: string) {
    this.slotName = `${roomName} ${this.nodeSubType}`;
  }

  isValid(): boolean {
    return true;
  }
}

class ACIRActuator extends Actuator {
  acModelId?: string;

  constructor(acModelId: string | null | undefined) {
    super(ACTUATOR.ACIR);
    if (acModelId) this.acModelId = acModelId;
  }

  setAcModelId(acModelId: string) {
    this.acModelId = acModelId;
  }

  isValid(): boolean {
    return isString(this.acModelId);
  }
}

class TwoPFCActuator extends Actuator {
  acModelId?: string;

  constructor(acModelId: string | null | undefined) {
    super(ACTUATOR.TWOPFC);
    if (acModelId) this.acModelId = acModelId;
  }

  setAcModelId(acModelId: string) {
    this.acModelId = acModelId;
  }
}

class DaikinActuator extends Actuator {
  acModelId?: string;

  constructor(acModelId: string | null | undefined) {
    super(ACTUATOR.DAIKIN);
    if (acModelId) this.acModelId = acModelId;
  }

  setAcModelId(acModelId: string) {
    this.acModelId = acModelId;
  }
}

class FourPFCActuator extends Actuator {
  actingMode?: string;

  operationalMode?: string;

  constructor(actingMode: string | null | undefined, operationalMode: string | null | undefined) {
    super(ACTUATOR.FOURPFC);
    if (actingMode) this.actingMode = actingMode;
    if (operationalMode) this.operationalMode = operationalMode;
  }

  setActingMode(actingMode: string) {
    this.actingMode = actingMode;
  }

  setOperationalMode(operationalMode: string) {
    this.operationalMode = operationalMode;
  }

  isValid(): boolean {
    return isString(this.actingMode);
  }
}

class ActuatorFactory {
  private static instance: ActuatorFactory;

  private constructor() {}

  static getInstance(): ActuatorFactory {
    if (!ActuatorFactory.instance) ActuatorFactory.instance = new ActuatorFactory();
    return ActuatorFactory.instance;
  }

  createActuator(type: string): Actuator {
    if (type === ACTUATOR.ACIR.toString()) {
      return new ACIRActuator(undefined);
    }

    if (type === ACTUATOR.TWOPFC.toString()) {
      return new TwoPFCActuator(undefined);
    }

    if (type === ACTUATOR.DAIKIN.toString()) {
      return new DaikinActuator(undefined);
    }

    if (type === ACTUATOR.FOURPFC.toString()) {
      return new FourPFCActuator(ActingMode.Default, undefined);
    }

    throw Error("invalid actuator type");
  }
}

abstract class Occupancy extends IDName {
  protected getPreviewUtil(room: Room, type: string): string {
    if (this.name.toLowerCase().includes(type.toLowerCase())) return `${capitalize(room.name)} ${this.name}`;

    return `${capitalize(room.name)} ${capitalize(type)} ${this.name}`;
  }

  getPreview(room: Room): string {
    return this.getPreviewUtil(room, "Occupancy");
  }
}

class OccupancyDoorLaser extends Occupancy {
  getPreview(room: Room): string {
    return this.getPreviewUtil(room, "Door");
  }
}

class OccupancyDoorMagnetic extends Occupancy {
  getPreview(room: Room): string {
    return this.getPreviewUtil(room, "Door");
  }
}

class OccupancyCeiling extends Occupancy {}
class OccupancyWall extends Occupancy {}

class Room extends IDName {
  @Type(() => Actuator, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: ACIRActuator, name: "ACIR" },
        { value: TwoPFCActuator, name: "2PFC" },
        { value: DaikinActuator, name: "DAIKIN" },
      ],
    },
  })
  actuator: Actuator;

  @Type(() => OccupancyDoorLaser)
  doorLasers: Array<OccupancyDoorLaser>;

  @Type(() => OccupancyDoorLaser)
  doorMagnetics: Array<OccupancyDoorMagnetic>;

  @Type(() => OccupancyWall)
  occupancyWalls: Array<OccupancyWall>;

  @Type(() => OccupancyCeiling)
  occupancyCeilings: Array<OccupancyCeiling>;

  constructor(name: string) {
    super(name);
    this.actuator = new ACIRActuator(undefined);
    this.doorLasers = [];
    this.doorMagnetics = [];
    this.occupancyWalls = [];
    this.occupancyCeilings = [];
  }

  setActuator(actuator: Actuator) {
    this.actuator = actuator;
  }

  addOccupancyLaser(name: string) {
    this.doorLasers.push(new OccupancyDoorLaser(name));
  }

  addOccupancyDoorMagnetic(name: string) {
    this.doorMagnetics.push(new OccupancyDoorMagnetic(name));
  }

  addOccupancyWall(name: string) {
    this.occupancyWalls.push(new OccupancyWall(name));
  }

  addOccupancyCeiling(name: string) {
    this.occupancyCeilings.push(new OccupancyCeiling(name));
  }

  removeOccupancy(occupancy: Occupancy) {
    if (occupancy instanceof OccupancyDoorLaser) {
      this.doorLasers = this.doorLasers.filter((o) => o !== occupancy);
    }

    if (occupancy instanceof OccupancyDoorMagnetic) {
      this.doorMagnetics = this.doorMagnetics.filter((o) => o !== occupancy);
    }

    if (occupancy instanceof OccupancyCeiling) {
      this.occupancyCeilings = this.occupancyCeilings.filter((o) => o !== occupancy);
    }

    if (occupancy instanceof OccupancyWall) {
      this.occupancyWalls = this.occupancyWalls.filter((o) => o !== occupancy);
    }
  }

  occupanciesToList() {
    const response: any[] = [];

    this.doorLasers.forEach((door) =>
      response.push({
        slotName: `${this.name} ${door.name}`,
        nodeType: NodeType.Door,
        nodeSubType: NodeSubType.DoorLaser,
      })
    );

    this.doorMagnetics.forEach((door) =>
      response.push({
        slotName: `${this.name} ${door.name}`,
        nodeType: NodeType.Door,
        nodeSubType: NodeSubType.DoorMagnetic,
      })
    );

    this.occupancyCeilings.forEach((occupancyCeiling) =>
      response.push({
        slotName: `${this.name} ${occupancyCeiling.name}`,
        nodeType: NodeType.Occupancy,
        nodeSubType: NodeSubType.OccupancyCeiling,
      })
    );

    this.occupancyWalls.forEach((occupancyWall) =>
      response.push({
        slotName: `${this.name} ${occupancyWall.name}`,
        nodeType: NodeType.Occupancy,
        nodeSubType: NodeSubType.OccupancyWall,
      })
    );
    return response;
  }

  isValid(): boolean {
    return (
      every(concat(this.doorLasers, this.doorMagnetics, this.occupancyWalls, this.occupancyCeilings), (data) =>
        data.isValid()
      ) &&
      this.actuator.isValid() &&
      !isEmpty(this.name)
    );
  }
}

class Key extends IDName {}

class KeyHelper {
  static inputsToKeys(inputs: string[]): Array<Array<Key>> {
    return inputs.map((input: string) => {
      const keyNames = input.split(/\n+/);
      return keyNames.filter((keyName) => !isEmpty(keyName)).map((keyName) => new Key(keyName));
    });
  }
}

class Category extends IDName {
  @Type(() => Room)
  rooms: Array<Room>;

  @Type(() => Key)
  keys: Array<Key>;

  constructor(name: string) {
    super(name);
    this.rooms = [];
    this.keys = [];
  }

  getFirstRoom(): Room | undefined {
    if (this.rooms.length > 0) return this.rooms[0];
  }

  addRoom(name: string, actuator: ACTUATOR): void {
    const room = new Room(name);
    room.setActuator(ActuatorFactory.getInstance().createActuator(actuator.toString()));
    this.rooms.push(room);
  }

  deleteRoom(room: Room) {
    this.rooms = this.rooms.filter((r) => r !== room);
  }

  addKeys(keys: Array<Key>): void {
    this.keys = keys;
  }

  isValidRooms(): boolean {
    return every(this.rooms, (data) => data.isValid());
  }
}

export interface ISession {
  addNewCategory(): Category;
  renameCategory(category: Category, name: string): Category;
  deleteCategory(category: Category): void;
  getFirstCategory(): Category | undefined;
  getCategories(): Array<Category>;
  isEmpty(): boolean;
}

class Session implements ISession {
  id: string;

  locationName: string;

  locationId: string;

  @Type(() => Category)
  categories: Array<Category>;

  constructor(locationId: string, locationName: string) {
    this.id = uuid();
    this.categories = [];
    this.locationId = locationId;
    this.locationName = locationName;
  }

  addNewCategory(): Category {
    // create category
    const category = new Category(`Category ${this.categories.length + 1}`);
    this.categories.push(category);

    // create first room for category
    category.addRoom("Room 1", ACTUATOR.ACIR);

    return category;
  }

  renameCategory(category: Category, name: string): Category {
    category.setName(name);
    return category;
  }

  deleteCategory(category: Category): void {
    this.categories = this.categories.filter((c) => c !== category);
  }

  getFirstCategory(): Category | undefined {
    if (this.categories.length > 0) return this.categories[0];
  }

  getCategories(): Array<Category> {
    return this.categories;
  }

  isEmpty(): boolean {
    return this.categories.length === 0;
  }

  isValidToCreateRoom(): boolean {
    return every(this.categories, (data) => data.isValidRooms());
  }

  transformToKeyCategoryTemplatesInsertInput(): Sensorflow_Key_Category_Templates_Insert_Input[] {
    const response: any[] = [];

    this.categories.forEach((categoryData) => {
      const roomTemplates: any[] = [];
      categoryData.rooms.forEach((roomData) => {
        const slotTemplates = roomData.occupanciesToList();
        roomData.actuator.setSlotNamePrefix(roomData.name);
        slotTemplates.push(roomData.actuator);

        const room = {
          roomName: roomData.name,
          slotTemplates: {
            data: slotTemplates,
          },
        };
        roomTemplates.push(room);
      });

      const category = {
        locationName: this.locationName,
        categoryName: categoryData.name,
        roomTemplates: {
          data: roomTemplates,
        },
      };

      response.push(category);
    });

    return response;
  }

  transformToKeyCategoryToKeyPositionInsertInput(): Sensorflow_Key_Category_To_Key_Position_Insert_Input[] {
    const response: any[] = [];
    this.categories.forEach((category) => {
      category.keys.forEach((key) => {
        const roomData = category.rooms.map((room) => {
          const nodeSlots = room.occupanciesToList();
          const acModelId = (room.actuator as ACIRActuator).acModelId || null;
          const actingMode = (room.actuator as FourPFCActuator).actingMode || null;
          const operationalMode = (room.actuator as FourPFCActuator).operationalMode || OperationalMode.Cooling;
          room.actuator.setSlotNamePrefix(room.name);
          nodeSlots.push(omit(room.actuator, "acModelId", "actingMode", "operationalMode"));

          return {
            positionName: room.name,
            positionType: "room",
            positionFunction: "autoset",
            locationId: this.locationId,
            positionConfiguration: {
              data: [
                {
                  ...POSITION_CONFIGURATION_DEFAULT,
                  acModelId,
                  actingMode,
                  operationalMode,
                  recordType: PositionConfigurationRecordType.DEFAULT,
                },
                {
                  ...POSITION_CONFIGURATION_DEFAULT,
                  acModelId,
                  actingMode,
                  operationalMode,
                  recordType: PositionConfigurationRecordType.CURRENT,
                },
              ],
            },
            nodeSlots: {
              data: nodeSlots,
            },
          };
        });

        const data = {
          locationName: this.locationName,
          categoryName: category.name,
          position: {
            data: {
              positionName: key.name,
              positionType: "key",
              positionFunction: "identifier",
              locationId: this.locationId,
              parentPositionId: this.locationId,
              rooms: {
                data: roomData,
              },
            },
          },
        };

        response.push(data);
      });
    });

    return response;
  }

  static deserialize(locationId: string, locationName: string, data: KeyCategoriesWithTemplatesQuery): Session {
    try {
      const session = new Session(locationId, locationName);
      data.keyCategories.forEach((categoryData) => {
        const category = new Category(categoryData.categoryName);
        categoryData.keyCategoryTemplate?.roomTemplates.forEach((roomData) => {
          const room = new Room(roomData.roomName);
          roomData.slotTemplates.forEach((slotData) => {
            const slotName = slotData.slotName.replace(roomData.roomName, "").trim();
            switch (slotData.nodeSubType) {
              case NodeSubType.DoorLaser:
                room.doorLasers.push(new OccupancyDoorLaser(slotName));
                break;
              case NodeSubType.DoorMagnetic:
                room.doorMagnetics.push(new OccupancyDoorMagnetic(slotName));
                break;
              case NodeSubType.OccupancyCeiling:
                room.occupancyCeilings.push(new OccupancyCeiling(slotName));
                break;
              case NodeSubType.OccupancyWall:
                room.occupancyWalls.push(new OccupancyWall(slotName));
                break;
              case NodeSubType.AirconAcir:
                room.setActuator(new ACIRActuator(slotData.acModelId));
                break;
              case NodeSubType.Aircon_2pfc:
                room.setActuator(new TwoPFCActuator(slotData.acModelId));
                break;
              case NodeSubType.AirconDaikin:
                room.setActuator(new DaikinActuator(slotData.acModelId));
                break;
              default:
                break;
            }
          });
          category.rooms.push(room);
        });
        session.categories.push(category);
      });
      return session;
    } catch (e) {
      return new Session(locationId, locationName);
    }
  }
}

export {
  Session,
  Category,
  Key,
  Room,
  OccupancyDoorLaser,
  OccupancyWall,
  OccupancyCeiling,
  Occupancy,
  ACTUATOR,
  Actuator,
  ACIRActuator,
  TwoPFCActuator,
  DaikinActuator,
  ActuatorFactory,
  FourPFCActuator,
  KeyHelper,
};
