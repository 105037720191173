import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";

const useQueryString = () => {
  const location = useLocation();
  return qs.parse(`${location.search}`.replace("?", ""));
};

export default useQueryString;

export const useLocationActionWithQueryString = () => {
  const history = useHistory();
  const location = useLocation();

  return {
    pushPathWithIntactSearch: (path: string) =>
      history.push({
        pathname: path,
        search: location.search,
      }),
  };
};

export const useUrlSearchParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};
