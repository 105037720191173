import React, { useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LocationBreadcrumb from "components/LocationBreadcrumb/LocationBreadcrumb";
import get from "lodash/get";
import { GatewayCreationStep } from "./GatewayCreation.d";
import GatewayCreationHeader from "./GatewayCreationHeader";
import AddWifiCredentialsPage from "./AddWifiCredentialsPage/AddWifiCredentialsPage";
import NameGatewaysPage from "./NameGatewaysPage/NameGatewaysPage";

const GatewayCreation = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<GatewayCreationStep>(GatewayCreationStep.ADD_WIFI_CREDENTIALS);
  const { locationId } = useParams<{ locationId: string }>();
  const locationName = get(useLocation(), "state.locationName", "");

  useMemo(() => {
    if (!locationName) history.push(`/locations/${locationId}/gateways`);
  }, [locationId, locationName, history]);

  const renderActiveStep = () => {
    switch (activeStep) {
      case GatewayCreationStep.ADD_WIFI_CREDENTIALS:
        return <AddWifiCredentialsPage goToNextStep={() => setActiveStep(GatewayCreationStep.NAME_GATEWAYS)} />;
      case GatewayCreationStep.NAME_GATEWAYS:
        return (
          <NameGatewaysPage goBackToPreviousStep={() => setActiveStep(GatewayCreationStep.ADD_WIFI_CREDENTIALS)} />
        );
      default:
        throw new Error("Invalid step in gateway creation mode!");
    }
  };

  return (
    <div className="gateways-creation">
      <LocationBreadcrumb locationName={locationName} />
      <GatewayCreationHeader step={activeStep} />
      {renderActiveStep()}
    </div>
  );
};

export default GatewayCreation;
