export default [
  {
    positionId: "e0f7d982-e527-11e9-ad3b-a45e60d743d3",
    positionName: "Bedroom 1",
    eventsStream: [
      {
        eventType: "door",
        currentValue: 1,
        previousValue: 0,
        changedBy: "AUTOMATION",
        hasChanged: 2,
        time: "2021-05-07T07:45:57.129093+00:00",
      },
      {
        eventType: "occupancy",
        currentValue: 1,
        previousValue: 0,
        changedBy: "GUEST_INTERACTION",
        hasChanged: 2,
        time: "2021-05-07T07:45:57.129093+00:00",
      },
      {
        eventType: "occupancy",
        currentValue: 0,
        previousValue: 1,
        changedBy: "GUEST_INTERACTION",
        hasChanged: 2,
        time: "2021-05-06T07:45:57.129093+00:00",
      },
      {
        eventType: "door",
        currentValue: 0,
        previousValue: 1,
        changedBy: "GUEST_INTERACTION",
        hasChanged: 2,
        time: "2021-05-07T07:50:57.129093+00:00",
      },
    ],
  },
  {
    positionId: "e10a0832-e527-11e9-ad3b-a45e60d743d3",
    positionName: "Bedroom 2",
    eventsStream: [],
  },
  {
    positionId: "e10a0832-e527-11e9-ad3b-a45e60d743d4",
    positionName: "Bedroom 3",
    acSettingCommands: [],
    eventsStream: [],
  },
];
