import { Button, Col, DatePicker, Divider, Row } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { range } from "lodash";
import moment from "moment";

import LocationSwitcher from "components/LocationSwitcher";
import useBreakpoint from "hooks/use-breakpoint";
import { useGetSmartAllocReportLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import useQueryString from "hooks/use-query-string";
import { formatNumber } from "utils/number";
import { formatYearMonthDate, MONTH_YEAR_FORMAT } from "utils/date";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import useLocationMetadata from "hooks/use-location-metadata";
import fileUtil from "../../../utils/file";
import OccupancyChart from "../components/OccupancyChart";
import RecommendationChart from "../components/RecommendationChart";
import EnergyConsumptionChart from "../components/EnergyConsumptionChart";
import CompressorChart from "../components/CompressorChart";
import { DownloadIcon, InformationIcon } from "../../../components/Icons";
import UnitSelect, { Units } from "../components/UnitSelect";
import { transform } from "./SmartAllocDashboard.helper";
import { SGD_UNIT, SmartAllocReport } from "./SmartAllocDashboard.d";
import EnergySavingValue from "../components/EnergySavingValue";

const { RangePicker } = DatePicker;

const SmartAllocDashboard = () => {
  const [reportData, setReportData] = useState<SmartAllocReport | null>(null);
  const [energySavingUnit, setEnergySavingUnit] = useState<Units>(Units.localCurrency);
  const [localCurrency, setLocalCurrency] = useState<string>("");
  const [fromMonth, setFromMonth] = useState<moment.Moment>(moment().startOf("month").subtract(1, "year"));
  const [toMonth, setToMonth] = useState<moment.Moment>(moment().endOf("month"));

  const isEmpty = !reportData;
  const fromWeek = useMemo(() => moment(fromMonth).startOf("isoWeek"), [fromMonth]);
  const toWeek = useMemo(() => moment(toMonth).startOf("isoWeek"), [toMonth]);

  const screen = useBreakpoint();
  const params = useQueryString();
  const location = useSensorflowLocation();
  const { hasSmartAlloc, hasSmartAllocPro } = useLocationMetadata({ locationId: params.location as string });

  const months = range(0, toMonth.diff(fromMonth, "month") + 1).map(
    (month) => formatYearMonthDate(moment(fromMonth).add(month, "month")) ?? ""
  );

  const weeks = range(0, toWeek.diff(fromWeek, "weeks") + 1).map(
    (week) => formatYearMonthDate(moment(fromWeek).add(week, "week")) ?? ""
  );

  const [getSmartAllocReport] = useGetSmartAllocReportLazyQuery({
    onError: errorHandler.handleError,
    onCompleted: (data) => {
      if (hasSmartAlloc) {
        setReportData(transform(data.sf_pms_smartalloc_report, months, weeks));
        setLocalCurrency(data.sf_pms_smartalloc_report?.[0]?.billingCurrency);
      } else {
        setReportData(null);
        setLocalCurrency("");
      }
      setEnergySavingUnit(Units.localCurrency);
    },
  });

  useEffect(() => {
    if (params.location) {
      getSmartAllocReport({
        variables: {
          where: {
            locationId: {
              _eq: params.location,
            },
            date: {
              _gte: formatYearMonthDate(fromWeek),
              _lte: formatYearMonthDate(toWeek),
            },
          },
        },
      });
    }
  }, [getSmartAllocReport, params.location, fromWeek, toWeek]);

  const handleChangeMonths = (values: (moment.Moment | null)[] | null) => {
    setFromMonth((values?.[0] as moment.Moment).startOf("month"));
    setToMonth((values?.[1] as moment.Moment).startOf("month"));
  };

  const renderValue = (valueElement: ReactNode, showEmpty?: boolean) => {
    if (isEmpty || showEmpty) {
      return <span className="fs-xl">--</span>;
    }

    return valueElement;
  };

  const savingUnitOptions = [
    localCurrency && {
      value: Units.localCurrency,
      label: localCurrency,
    },
    localCurrency !== SGD_UNIT && {
      value: Units.dollar,
      label: SGD_UNIT,
    },
    {
      value: Units.kWh,
      label: "kWh",
    },
  ];

  return (
    <div>
      <div className="mb-xl">
        <Row
          justify="space-between"
          className={classNames({
            "mb-l": screen.desktopOny,
            "mb-m": screen.mobileAndTabletOnly,
          })}
          gutter={[16, 16]}
        >
          <Col lg={12} span={24} className="d-flex flex-row align-items-center">
            <h1>DASHBOARD</h1>
            <div
              className={classNames({
                "ml-l": screen.desktopOny,
                "ml-auto": screen.mobileAndTabletOnly,
              })}
            >
              <LocationSwitcher />
            </div>
          </Col>
          <Col lg={12} span={24} className="d-flex justify-content-end">
            <RangePicker
              picker="month"
              format={MONTH_YEAR_FORMAT}
              separator="&ndash;"
              onChange={handleChangeMonths}
              value={[fromMonth, toMonth]}
              allowClear={false}
            />
            <Button
              className="ml-s"
              type="primary"
              onClick={() => {
                const downloadContent = document.getElementById("download-content");
                if (downloadContent)
                  return fileUtil.generateAndDownloadPdf(downloadContent, {
                    margin: {
                      x: 20,
                      y: 40,
                    },
                    fileName: location.locationName,
                  });
              }}
            >
              <DownloadIcon className="mr-s" />
              {screen.desktopOny ? " Download Report" : " Report"}
            </Button>
          </Col>
        </Row>
        <div id="download-content">
          <Row
            gutter={[
              {
                xs: 20,
                lg: 32,
              },
              {
                xs: 20,
                lg: 32,
              },
            ]}
            justify="space-between"
          >
            <Col lg={6} span={24}>
              <div
                className={classNames(
                  "w-100 h-100 d-flex flex-row justify-content-between bg-dark-orange radius-8 align-items-center",
                  {
                    "p-xl": screen.desktopOny,
                    "p-m": screen.mobileAndTabletOnly,
                  }
                )}
              >
                <div className="d-flex flex-column justify-content-between h-100 w-65">
                  <h2 className="fs-h4 lh-h4 font-weight-bold">
                    Occupancy
                    <InformationIcon className="ml-s mb-xs d-inline-block" />
                  </h2>
                  {renderValue(
                    <div>
                      <span className="fs-h2 font-weight-bold pr-xs font-heading">
                        {formatNumber(reportData?.averageOccupancy)}
                      </span>
                      <sup className="fs-l">%</sup>
                    </div>,
                    !hasSmartAlloc
                  )}
                </div>
                <div className="w-35 px-xs py-m">
                  <OccupancyChart value={hasSmartAlloc ? reportData?.averageOccupancy : undefined} />
                </div>
              </div>
            </Col>
            <Col lg={6} span={24}>
              <div
                className={classNames(
                  "w-100 h-100 d-flex flex-row justify-content-between bg-light-blue radius-8 align-items-center",
                  {
                    "p-xl": screen.desktopOny,
                    "p-m": screen.mobileAndTabletOnly,
                  }
                )}
              >
                <div className="d-flex flex-column justify-content-between h-100 w-65">
                  <h2 className="fs-h4 lh-h4 font-weight-bold">
                    Recommendations Followed
                    <InformationIcon className="ml-s mb-xs d-inline-block" />
                  </h2>
                  {renderValue(
                    <div>
                      <span className="fs-h2 font-weight-bold pr-xs font-heading">
                        {formatNumber(reportData?.followedRecommendationRatio)}
                      </span>
                      <sup className="fs-l">%</sup>
                    </div>,
                    !hasSmartAlloc
                  )}
                </div>
                <div className="w-35 px-xs py-m">
                  <RecommendationChart value={hasSmartAlloc ? reportData?.followedRecommendationRatio : undefined} />
                </div>
              </div>
            </Col>
            <Col lg={12} span={24}>
              <div
                className={classNames("w-100 bg-light-warning radius-8 h-100 d-flex flex-column", {
                  "p-xl": screen.desktopOny,
                  "p-m": screen.mobileAndTabletOnly,
                })}
              >
                <div
                  className={classNames("d-flex justify-content-between mb-l", {
                    "flex-row align-items-center": screen.desktopOny,
                    "flex-column align-items-start": screen.mobileAndTabletOnly,
                  })}
                >
                  <h2
                    className={classNames("fs-h4 font-weight-bold", {
                      "mb-none": screen.desktopOny,
                      "mb-m": screen.mobileAndTabletOnly,
                    })}
                  >
                    Energy Saving
                    <InformationIcon className="ml-s mb-xs d-inline-block" />
                  </h2>
                  {!isEmpty && (
                    <UnitSelect
                      label="Unit"
                      options={savingUnitOptions.filter(Boolean) as { value: Units; label: string }[]}
                      value={energySavingUnit}
                      onChange={setEnergySavingUnit}
                    />
                  )}
                </div>
                <Row justify="start" className="flex-fill">
                  <Col
                    lg={6}
                    xs={24}
                    className={classNames(
                      "d-flex justify-content-between",
                      screen.desktopOny ? "flex-column" : "flex-row align-items-center"
                    )}
                  >
                    <p
                      className={classNames("fs-m", {
                        "mb-xl": screen.desktopOny,
                        "mr-xl mb-none": screen.mobileAndTabletOnly,
                      })}
                    >
                      Saved
                    </p>
                    {renderValue(
                      <div className="text-green">
                        <EnergySavingValue
                          unit={energySavingUnit}
                          value={{
                            kWh: reportData?.savingsKwh,
                            dollar: reportData?.savingsSgd,
                            localCurrency: reportData?.savingsLocalCurrency,
                          }}
                          localCurrency={localCurrency}
                        />
                      </div>,
                      !hasSmartAllocPro
                    )}
                  </Col>
                  <Col lg={2} xs={0}>
                    <Divider type="vertical" className="h-100" />
                  </Col>
                  <Col lg={0} xs={24}>
                    <Divider type="horizontal" className="w-100 my-m" />
                  </Col>
                  <Col
                    lg={6}
                    xs={24}
                    className={classNames("d-flex justify-content-between", {
                      "flex-column": screen.desktopOny,
                      "flex-row align-items-center": screen.mobileAndTabletOnly,
                    })}
                  >
                    <p
                      className={classNames("fs-m", {
                        "mb-xl": screen.desktopOny,
                        "mr-xl mb-none": screen.mobileAndTabletOnly,
                      })}
                    >
                      Using SmartAlloc with current adherence
                    </p>
                    {renderValue(
                      <div className="text-primary">
                        <EnergySavingValue
                          unit={energySavingUnit}
                          value={{
                            kWh: reportData?.savingsKwh,
                            dollar: reportData?.savingsSgd,
                            localCurrency: reportData?.savingsLocalCurrency,
                          }}
                          localCurrency={localCurrency}
                        />
                      </div>,
                      !hasSmartAllocPro
                    )}
                  </Col>
                  <Col lg={0} xs={24}>
                    <Divider type="horizontal" className="w-100 my-m" />
                  </Col>
                  <Col
                    lg={{ span: 6, offset: 2 }}
                    xs={24}
                    className={classNames("d-flex justify-content-between", {
                      "flex-column": screen.desktopOny,
                      "flex-row align-items-center": screen.mobileAndTabletOnly,
                    })}
                  >
                    <p
                      className={classNames("fs-m", {
                        "mb-xl": screen.desktopOny,
                        "mr-xl mb-none": screen.mobileAndTabletOnly,
                      })}
                    >
                      Using SmartAlloc at 90% adherence
                    </p>
                    {renderValue(
                      <div className="text-primary">
                        <EnergySavingValue
                          unit={energySavingUnit}
                          value={{
                            kWh: reportData?.ninetyAdherenceSavingKwh,
                            dollar: reportData?.ninetyAdherenceSavingSgd,
                            localCurrency: reportData?.ninetyAdherenceSavingLocalCurrency,
                          }}
                          localCurrency={localCurrency}
                        />
                      </div>,
                      !hasSmartAllocPro
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div
                className={classNames("bg-light-gray radius-8", {
                  "p-xl": screen.desktopOny,
                  "p-m": screen.mobileAndTabletOnly,
                })}
              >
                <EnergyConsumptionChart
                  months={months || []}
                  weeks={weeks || []}
                  smartAllocReport={hasSmartAllocPro ? reportData : null}
                />
              </div>
            </Col>
            <Col span={24}>
              <div
                className={classNames("bg-light-gray radius-8", {
                  "p-xl": screen.desktopOny,
                  "p-m": screen.mobileAndTabletOnly,
                })}
              >
                <CompressorChart
                  months={months || []}
                  weeks={weeks || []}
                  smartAllocReport={hasSmartAlloc ? reportData : null}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SmartAllocDashboard;
