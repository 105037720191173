import React from "react";

import { Alert, Table as ANTDTable } from "antd";
import logger from "services/logger/logger";
import classnames from "classnames";
import { TableProps } from "./Table.d";
import "./Table.scss";

const Table = ({
  pagination,
  tableData,
  handleTableChange,
  loading,
  error,
  columns,
  dataItemName,
  rowSelection,
  expandable,
  onExpand,
  expandedRowKeys,
  rowKey,
  style = { width: "100%" },
  hidePagination,
  mobileView,
  classNames,
  onRow,
  rowClassName,
  scroll,
}: TableProps) => {
  const paginationConfig = hidePagination
    ? false
    : { showSizeChanger: false, position: ["bottomCenter" as const], ...pagination };

  if (loading) return <p className="mt-m">Loading...</p>;
  if (error) {
    logger.error(error);
    return (
      <Alert type="error" message={`Error getting ${dataItemName}`} data-testid="table-alert">
        {error.message}
      </Alert>
    );
  }

  return (
    <ANTDTable
      columns={columns}
      dataSource={tableData}
      rowKey={rowKey}
      rowSelection={rowSelection}
      expandable={expandable}
      pagination={paginationConfig}
      onChange={handleTableChange}
      onExpand={onExpand}
      expandedRowKeys={expandedRowKeys}
      rowClassName={rowClassName}
      style={style}
      className={classnames({ "m-table": mobileView, ...classNames })}
      onRow={onRow}
      scroll={scroll}
    />
  );
};

export default Table;
