import React, { useState } from "react";
import "./UnmappedNode.css";
import { useUnmapNodeMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { message } from "antd";
import errorHandler from "errorHandler";
import isFunction from "lodash/isFunction";
import ScannedNode from "./ScannedNode";
// eslint-disable-next-line import/no-cycle
import SelectingReason from "./SelectingReason";

const UnmapedNodeComponent = ({
  roomId,
  roomName,
  keyName,
  currentNodeMacId,
  nodeTypeCodeIdentifier,
  nodeSubTypeCode,
  nodeType,
  slotName,
  onUnmapSuccessfully,
  externalError,
}: any) => {
  const [isSelectingReason, setIsSelectingReason] = useState<boolean>(false);
  const [nodeMacInput, setNodeMacInput] = useState<string>(nodeTypeCodeIdentifier.slice(-2) + currentNodeMacId);
  const [scannedMacId, setScannedMacId] = useState<string>();

  const [unmapNode, { loading: isUnmapping }] = useUnmapNodeMutation({
    onCompleted: () => {
      message.success("Unmap successfully.");
      if (isFunction(onUnmapSuccessfully)) onUnmapSuccessfully();
    },
    onError: errorHandler.handleError,
  });

  const scannedMacIdSubmit = (isMatchedCurrentNodeId: boolean = true, nodeMacId?: string) => {
    if (isMatchedCurrentNodeId && nodeMacId) {
      setNodeMacInput(nodeMacId);
    } else if (nodeMacId) {
      setScannedMacId(nodeMacId.substring(2));
    }
    setIsSelectingReason(true);
  };

  const reasonSubmit = (rootCauseId: number, comment?: string) => {
    unmapNode({
      variables: {
        roomId,
        slotName,
        nodeType,
        nodeMacInput,
        scannedMacId: scannedMacId || nodeMacInput,
        rootCauseId,
        comment,
      },
    });
  };

  return (
    <>
      {!isSelectingReason ? (
        <ScannedNode
          roomId={roomId}
          roomName={roomName}
          keyName={keyName}
          currentNodeMacId={currentNodeMacId}
          nodeTypeCodeIdentifier={nodeTypeCodeIdentifier}
          nodeSubTypeCode={nodeSubTypeCode}
          slotName={slotName}
          scannedMacIdSubmit={scannedMacIdSubmit}
          externalError={externalError}
        />
      ) : (
        <>
          <SelectingReason
            currentNodeMacId={currentNodeMacId}
            slotName={slotName}
            reasonSubmit={reasonSubmit}
            isUnmapping={isUnmapping}
          />
        </>
      )}
    </>
  );
};

export default UnmapedNodeComponent;
