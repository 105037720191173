import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { formatShortDate } from "utils/date";
import { CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import { IBookingFilterItem, IBookingListQueryFilter, PmsRoomCategory } from "./types";
import { BOOKING_STATUS_LABEL } from "../constants";

interface BookingFilterValuesProps {
  filter?: IBookingListQueryFilter;
  roomCategories?: PmsRoomCategory[];
  onFilterSet: (filter: IBookingListQueryFilter | undefined) => void;
}

const BookingFilterValues = ({ filter, roomCategories, onFilterSet }: BookingFilterValuesProps) => {
  const [currentFilteredCriteria, setCurrentFilteredCriteria] = React.useState<IBookingFilterItem[]>([]);
  const refToElement = useRef<HTMLUListElement>(null);
  const interval = useRef<NodeJS.Timeout>();
  const screen = useBreakpoint();

  useEffect(() => {
    const newFilter: IBookingFilterItem[] = [];
    filter?.roomCategories?.forEach((rc) => {
      newFilter.push({ type: "category", value: rc, text: roomCategories?.find((r) => r.id === rc)?.name });
    });

    filter?.statuses?.forEach((s) => {
      newFilter.push({ type: "status", value: s, text: BOOKING_STATUS_LABEL[s] });
    });

    if (filter?.checkInDate) {
      newFilter.push({
        type: "checkin",
        value: filter.checkInDate,
        text: `Check-in from: ${formatShortDate(filter.checkInDate)}`,
      });
    }

    if (filter?.checkOutDate) {
      newFilter.push({
        type: "checkout",
        value: filter.checkOutDate,
        text: `Check-out from: ${formatShortDate(filter.checkOutDate)}`,
      });
    }
    setCurrentFilteredCriteria(newFilter);
  }, [filter, roomCategories]);

  const clearFilter = (filterItem: IBookingFilterItem) => {
    setCurrentFilteredCriteria(currentFilteredCriteria.filter((i) => i !== filterItem));
    let newFilter = filter;
    switch (filterItem.type) {
      case "category":
        newFilter = { ...filter, roomCategories: filter?.roomCategories?.filter((rc) => rc !== filterItem.value) };
        break;
      case "status":
        newFilter = { ...filter, statuses: filter?.statuses?.filter((status) => status !== filterItem.value) };
        break;
      case "checkin":
        newFilter = { ...filter, checkInDate: undefined };
        break;
      case "checkout":
        newFilter = { ...filter, checkOutDate: undefined };
        break;
      default:
        break;
    }
    onFilterSet(newFilter);
  };

  const handleMouseMove: React.MouseEventHandler<HTMLUListElement> = (e) => {
    const container = refToElement.current;

    if (container) {
      const parent = container.parentElement;

      if (parent) {
        const leftMin = parent.getBoundingClientRect().left;
        const leftMax = parent.getBoundingClientRect().left + parent.clientWidth * 0.1;
        const rightMin = parent.getBoundingClientRect().right - parent.clientWidth * 0.1;
        const rightMax = parent.getBoundingClientRect().right;

        let direction = 0;

        if (e.pageX > leftMin && e.pageX < leftMax) {
          direction = -1;
        } else if (e.pageX > rightMin && e.pageX < rightMax) {
          direction = 1;
        }

        if (interval.current) {
          clearInterval(interval.current);
        }

        interval.current = setInterval(() => {
          container.scrollLeft += 1 * direction;
        }, 5);
      }
    }
  };

  const handleMouseLeave = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  return (
    <div className="w-100 blur-left-right direction-rtl">
      {currentFilteredCriteria.length > 0 && (
        <ul
          className={classNames("list-style-none mb-none px-s text-right w-100", {
            "white-space-nowrap overflow-hidden cursor-default": screen.desktopOny,
            "scroll-x": !screen.desktopOny,
          })}
          ref={refToElement}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {currentFilteredCriteria.map((f) => {
            return (
              <li
                className="border px-s radius-30 border-semi-blue bg-dark-blue d-inline-block ml-xs direction-ltr"
                key={f.value}
              >
                {f.text}{" "}
                <Button type="link" className="py-none px-xs" onClick={() => clearFilter(f)}>
                  <CloseOutlined />
                </Button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default BookingFilterValues;
