import { useEffect, useState } from "react";
import errorHandler from "errorHandler";
import { KeysWithRoomIdsQueryVariables, useKeysWithRoomIdsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { PositionWithRooms, Position } from "../types";

const useKeyRoomSelection = () => {
  const [selectedPositionSet, setSelectedPositionSet] = useState<Set<string>>(new Set());
  const [selectedRoomSet, setSelectedRoomSet] = useState<Set<string>>(new Set());
  const [selectedRoomItemSet, setSelectedRoomItemSet] = useState<Set<PositionWithRooms | Position>>(new Set());
  const [selectedKeySet, setSelectedKeySet] = useState<Set<string>>(new Set());
  const [selectedKeyItemSet, setSelectedKeyItemSet] = useState<Set<PositionWithRooms>>(new Set());
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectionStatsMessage, setSelectionStatsMessage] = useState<string>("");
  const [unselectedPositionSet, setUnselectedPositionSet] = useState<Set<string>>(new Set());
  const [unselectedRoomSet, setUnselectedRoomSet] = useState<Set<string>>(new Set());
  const [unselectedKeySet, setUnselectedKeySet] = useState<Set<string>>(new Set());
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    const count = isSelectAll ? totalCount - unselectedKeySet.size : selectedKeySet.size;
    setSelectionStatsMessage(`Selected ${count} keys`);
  }, [selectedKeySet.size, unselectedKeySet.size, isSelectAll, totalCount, unselectedPositionSet.size]);

  const { refetch: keysWithRoomIdsQuery } = useKeysWithRoomIdsQuery({
    onError: errorHandler.handleError,
    skip: true,
  });

  const resetSelection = () => {
    setSelectedPositionSet(new Set());
    setSelectedRoomSet(new Set());
    setSelectedKeySet(new Set());
    setSelectedKeyItemSet(new Set());
    setUnselectedPositionSet(new Set());
    setUnselectedRoomSet(new Set());
    setSelectedRoomItemSet(new Set());
    setIsSelectAll(false);
  };

  const selectKey = (key: string, record: PositionWithRooms) => {
    selectedPositionSet.add(key);
    selectedKeySet.add(key);
    selectedKeyItemSet.add(record);
    unselectedPositionSet.delete(key);
    unselectedKeySet.delete(key);
  };

  const selectRoom = (room: PositionWithRooms | Position) => {
    const { positionId } = room;
    selectedPositionSet.add(positionId);
    selectedRoomSet.add(positionId);
    unselectedPositionSet.delete(positionId);
    unselectedRoomSet.delete(positionId);
    selectedRoomItemSet.add(room);
  };

  const deselectKey = (key: string, record: PositionWithRooms) => {
    selectedPositionSet.delete(key);
    selectedKeySet.delete(key);
    selectedKeyItemSet.delete(record);
    unselectedPositionSet.add(key);
    unselectedKeySet.add(key);
  };

  const deselectRoom = (room: PositionWithRooms | Position) => {
    const { positionId } = room;
    selectedPositionSet.delete(positionId);
    selectedRoomSet.delete(positionId);
    unselectedPositionSet.add(positionId);
    unselectedRoomSet.add(positionId);
    selectedRoomItemSet.delete(room);
  };

  const handlePositionSelected = (keys: PositionWithRooms[], records: PositionWithRooms[], selected: boolean) => {
    records.forEach((record) => {
      if (selected) {
        if (record.type === "key") {
          selectKey(record.positionId, record);
          // If a key selected, select all belong rooms
          record.rooms.forEach(selectRoom);
        } else {
          // Handle for room
          selectRoom(record);
          const key = keys.find((k) => !!k.rooms.find((r) => r.positionId === record.positionId));
          if (key && key.rooms.filter((r) => selectedPositionSet.has(r.positionId)).length > 0) {
            selectKey(key.positionId, key);
          }
        }
      } else if (record.type === "key") {
        deselectKey(record.positionId, record);
        record.rooms.forEach(deselectRoom);
      } else {
        deselectRoom(record);
        const key = keys.find((k) => !!k.rooms.find((r) => r.positionId === record.positionId));
        if (key && key.rooms.filter((r) => selectedPositionSet.has(r.positionId)).length === 0) {
          deselectKey(key.positionId, key);
        }
      }
    });

    // Rebind all collections
    setUnselectedPositionSet(new Set(unselectedPositionSet));
    setUnselectedRoomSet(new Set(unselectedRoomSet));
    setUnselectedKeySet(new Set(unselectedKeySet));

    setSelectedKeySet(new Set(selectedKeySet));
    setSelectedKeyItemSet(new Set(selectedKeyItemSet));
    setSelectedRoomSet(new Set(selectedRoomSet));
    setSelectedPositionSet(new Set(selectedPositionSet));
  };

  const hasRoomSelected = () => {
    return !isSelectAll ? selectedRoomSet && selectedRoomSet.size > 0 : totalCount - unselectedKeySet.size > 0;
  };

  const onSourceBinding = (keys: PositionWithRooms[]) => {
    if (!isSelectAll) return;
    keys.forEach((k) => {
      if (unselectedPositionSet.has(k.positionId)) return;
      selectKey(k.positionId, k);
      k.rooms.forEach((r) => {
        if (unselectedPositionSet.has(r.positionId)) return;
        selectRoom(r);
      });
    });
  };

  const onSelectAll = (selected: boolean, keys: PositionWithRooms[]) => {
    setIsSelectAll(selected);

    setUnselectedPositionSet(new Set());
    setUnselectedRoomSet(new Set());
    setUnselectedKeySet(new Set());

    if (selected) {
      handlePositionSelected(keys, keys, selected);
    } else {
      resetSelection();
    }
  };

  const onTotalCountChange = (count: number) => {
    setTotalCount(count);
  };

  const getSelectedRooms = async (variables: KeysWithRoomIdsQueryVariables) => {
    if (!isSelectAll) return Array.from(selectedRoomSet);

    const { data } = await keysWithRoomIdsQuery(variables);

    const keys = data.keys.filter((key) => key.rooms.length > 0);
    if (data) {
      let roomIds: string[] = [];
      keys.forEach((k) => {
        roomIds = roomIds.concat(k.rooms.filter((r) => !unselectedRoomSet.has(r.positionId)).map((r) => r.positionId));
      });
      return roomIds;
    }
    return [];
  };

  const selectByPositionsId = (keys: PositionWithRooms[], positionIds: Array<string>) => {
    const records: PositionWithRooms[] = [];
    const positionIdsSelected: Array<string> = [];

    positionIds.forEach((id) => {
      if (positionIdsSelected.includes(id)) return;
      keys.forEach((key) => {
        if (key.positionId === id) {
          positionIdsSelected.push(key.positionId);
          key.rooms.forEach((room) => {
            if (positionIds.includes(room.positionId)) {
              records.push(room as PositionWithRooms);
              positionIdsSelected.push(room.positionId);
            }
          });
        }
      });
    });
    handlePositionSelected(keys, records, true);
  };

  return {
    resetSelection,
    hasRoomSelected,
    selectionStatsMessage,
    selectedPositions: selectedPositionSet,
    selectedRoomItem: selectedRoomItemSet,
    selectedRooms: selectedRoomSet,
    selectedKey: selectedKeySet,
    selectedKeyItem: selectedKeyItemSet,
    onSourceBinding,
    onSelectAll,
    handlePositionSelected,
    onTotalCountChange,
    getSelectedRooms,
    selectByPositionsId,
  };
};

export default useKeyRoomSelection;
