import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, message, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  Sensorflow_Location_Metadata,
  useUpdateLocationMetadataMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import "./AddWifiCredentialsPage.scss";
import classNames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import AddWifiCredentialsPageDetail from "../AddWifiCredentialsPageDetail/AddWifiCredentialsPageDetail";
import errorHandler from "../../../errorHandler";

type AddWifiCredentialsPageProps = {
  goToNextStep: () => void;
};

const AddWifiCredentialsPage = ({ goToNextStep }: AddWifiCredentialsPageProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [wifiCredentials, setWifiCredentials] = useState<Partial<Sensorflow_Location_Metadata>>({
    secondaryWifiSsid: process.env.REACT_APP_DEFAULT_SECONDARY_WIFI_SSID,
    secondaryWifiPassword: process.env.REACT_APP_DEFAULT_SECONDARY_WIFI_PASSWORD,
  });

  const screen = useBreakpoint();

  const [updateLocationMetaData] = useUpdateLocationMetadataMutation({
    onCompleted: () => {
      message.success("Wifi credentials added.");
      goToNextStep();
    },
    onError: (error: any) => {
      errorHandler.handleError(error);
    },
  });

  const handleClickNameGateways = () => {
    updateLocationMetaData({
      variables: {
        input: {
          ...wifiCredentials,
        },
        where: {
          locationId: { _eq: locationId },
        },
      },
    } as any);
  };

  const onWifiCredentialsChange = (credentials: Partial<Sensorflow_Location_Metadata>) => {
    setWifiCredentials((currentCredentials) => ({
      ...currentCredentials,
      ...credentials,
    }));
  };

  return (
    <div>
      <Row className="add-wifi-credentials-section">
        {screen.desktopUp && (
          <Col span={8} className="left-section" data-testid="create-gateway-templates-menu">
            <div className="wifi-credentials-edit-title">
              <span role="none" className="text">
                WiFi Credentials
              </span>
            </div>
          </Col>
        )}
        <Col
          id="room-list"
          xs={{ span: 24 }}
          lg={{ span: 16 }}
          className="right-section"
          data-testid="add-wifi-credentials-page-detail"
        >
          <AddWifiCredentialsPageDetail
            onWifiCredentialsChange={onWifiCredentialsChange}
            wifiCredentials={wifiCredentials}
          />
        </Col>
      </Row>
      <Row className="footer-section">
        <Button
          className={classNames("button-name-gateways", {
            "button-mobile": screen.mobileAndTabletOnly,
          })}
          onClick={handleClickNameGateways}
        >
          Name Gateways <RightOutlined />
        </Button>
      </Row>
    </div>
  );
};

export default AddWifiCredentialsPage;
