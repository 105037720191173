import React, { useCallback, useState } from "react";

import { Input, Select, Tabs } from "antd";
import useScanCamera from "components/ScanCamera/ScanCamera";
import isEmpty from "lodash/isEmpty";
import QrReader from "react-camera-qr";
import "./NodeMapping.css";

const { TabPane } = Tabs;

const InternalError = (props: { message: string }) => {
  const { message } = props;
  return <p className="internal-error">{message}</p>;
};

const NodeScanningComponent = ({
  internalError,
  handleInputChange,
  input,
  mappingInput,
  onTabChanged,
  externalError,
  ExternalErrorComponentClass,
  handleScanQRCodeError,
  handleQRCodeScan,
}: any) => {
  const { videoDeviceId, videoDevices, onCameraDeviceChange, qrReaderProps } = useScanCamera({
    skipSettingDefaultDevice: true,
  });

  const [activeTabKey, setActiveTabKey] = useState("2");

  const onActiveTabChanged = useCallback(
    (tabKey) => {
      setActiveTabKey(tabKey);
      onTabChanged();
    },
    [onTabChanged]
  );

  return (
    <>
      <div className="scan-node-wrapper">
        <Tabs activeKey={activeTabKey} onChange={onActiveTabChanged}>
          <TabPane tab="Scan or enter Node ID" key="1">
            <h4>Type Node MAC ID</h4>
            <Input
              placeholder="01FFFF14B4570DF953"
              size="large"
              ref={mappingInput}
              id="mapping-input"
              value={input}
              onChange={handleInputChange}
              data-testid="mapping-input-testId"
            />
            {internalError && <InternalError message={internalError} />}
            {!internalError && ExternalErrorComponentClass && <ExternalErrorComponentClass {...externalError.props} />}
          </TabPane>

          <TabPane tab="Scan with camera" key="2" data-testid="scan-with-camera">
            <h4>Scan QR Code with camera:</h4>
            {!isEmpty(videoDevices) ? (
              <QrReader
                delay={300}
                onError={handleScanQRCodeError}
                onScan={handleQRCodeScan}
                className="camera-view"
                {...qrReaderProps}
              />
            ) : (
              <span>No camera available.</span>
            )}
            <br />
            {internalError && <InternalError message={internalError} />}
            {!internalError && ExternalErrorComponentClass && <ExternalErrorComponentClass {...externalError.props} />}
            {videoDevices && videoDevices.length > 0 && (
              <Select
                className="camera-view"
                placeholder="Select camera"
                value={videoDeviceId}
                onChange={onCameraDeviceChange}
                data-testid="camera-list-testId"
              >
                {videoDevices?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default NodeScanningComponent;
