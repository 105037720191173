export enum Permission {
  ORGANISATION_VIEW = "organisation:view",
  LOCATION_VIEW = "location:view",
  LOCATION_CREATE = "location:create",
  LOCATION_EDIT = "location:edit",
  LOCATION_VIEW_ALL = "location:view_all",
  USER_CREATE = "user:create",
  USER_VIEW = "user:view",
  USER_EDIT = "user:edit",
  USER_DELETE = "user:delete",
  USER_RESEND_VERIFICATION = "user:resend_verification",
  USER_RESET_PASSWORD = "user:reset_password",
  ROLE_VIEW = "role:view",
  SLOT_MAP = "slot:map",
  SLOT_SKIP_MAP = "slot:skip_map",
  SLOT_RAPID_MAP = "slot:rapid_map",
  KEY_LABEL_PRINT = "key:label_print",
  KEY_CREATE = "key:create",
  KEY_EDIT = "key:edit",
  GATEWAY_CREATE = "gateway:create",
  GATEWAY_DOWNLOAD_IMAGE = "gateway:download_image",
  GATEWAY_UPDATE = "gateway:update",
  GATEWAY_DELETE = "gateway:delete",
  GATEWAY_SET_HARDWARE_TYPE = "gateway:set_gateway_hardware_type",
  GATEWAY_SET_PROMISCUOUS_MODE = "gateway:set_promiscuous_mode",
  GATEWAY_SET_ENABLE_NO_KEYCARD_STATE = "gateway:set_enable_nokeycard_state",
  GATEWAY_SET_BEACON_SLOT_MANAGEMENT = "gateway:set_beacon_slot_management",
  NODES_LIST = "nodes:list",
  INFRASTRUCTURE_VIEW_ALL = "infrastructure:view_all",
  INFRASTRUCTURE_VIEW = "infrastructure:view",
  INFRASTRUCTURE_CREATE = "infrastructure:create",
  INFRASTRUCTURE_EDIT = "infrastructure:edit",
  INFRASTRUCTURE_DELETE = "infrastructure:delete",
  POSITION_CONFIGURATION_UPDATE = "position_configuration:update",
  FIRMWARE_UPLOAD = "ota:firmware-upload",
  LOCATION_METADATA_UPDATE = "location_metadata:update",
  PRECOOL_COMMAND_ADD = "precool_command:add",
}
