import { Button, Modal, Typography, message } from "antd";
import { TrashIcon } from "components/Icons";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useDeleteGuestPrecoolScheduleMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback, useState } from "react";
import ContentMessage from "./ContentMessage";

message.config({ top: 0 });

export interface PrecoolScheduleDeleteButtonProps {
  scheduleId: number;
  onDelete?: () => Promise<void> | void;
}

export const PrecoolScheduleDeleteButton: React.FC<PrecoolScheduleDeleteButtonProps> = ({ scheduleId, onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleOnOpen = useCallback(() => setOpen(true), []);
  const handleOnClose = useCallback(() => setOpen(false), []);

  const [deletePrecoolSchedule, { loading }] = useDeleteGuestPrecoolScheduleMutation({
    onCompleted: () => {
      message.open({
        type: "success",
        content: <ContentMessage content="A schedule has been deleted successfully." />,
        className: "precool-comfortPlus",
      });
      if (isFunction(onDelete)) onDelete();
      handleOnClose();
    },
    onError: errorHandler.handleError,
  });

  const handleOnConfirm = useCallback(() => {
    deletePrecoolSchedule({ variables: { scheduleId } });
  }, [deletePrecoolSchedule, scheduleId]);

  return (
    <>
      <Button
        type="default"
        icon={<TrashIcon />}
        className="precool-schedule-item-actions-btn precool-schedule-item-actions-delete"
        onClick={handleOnOpen}
      >
        Delete shedule
      </Button>
      <Modal
        centered
        open={open}
        onOk={handleOnConfirm}
        onCancel={handleOnClose}
        className="precool-schedule-item-actions-delete-modal"
        closeIcon={null}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <span className="precool-schedule-item-actions-delete-modal-icon">
          <TrashIcon />
        </span>
        <Typography.Title level={4}>Are you sure to delete your schedule?</Typography.Title>

        <div className="precool-schedule-item-actions">
          <Button
            className="w-100 precool-schedule-item-actions-btn precool-schedule-item-actions-cancel"
            disabled={loading}
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            className="w-100 precool-schedule-item-actions-btn precool-schedule-item-actions-confirm-del"
            loading={loading}
            onClick={() => handleOnConfirm()}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};
