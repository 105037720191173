import React, { useState } from "react";

import { Modal, Row, Select } from "antd";
import { Closable } from "components/Modal/show";
import { GetCoolingSystemQuery, useGetCoolingSystemQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import {
  infrastructureTypes,
  InfrastructureTypeEnum,
  HvacSystemTypeEnum,
} from "pages/Infrastructures/Infrastructures.d";
import { getInfraTypeValue, InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";

export type RenameFormModalProps = {
  objectId: string;
  locationId: string;
  objectType: InfrastructureType;
  objectCoolingSystemId?: string;
  objectNumOfPhase: number;
  objectHvacSystemType?: HvacSystemTypeEnum;
  onSubmit: (id: string, oldType: string, newType: string, hvacType?: string, coolingSystemId?: string) => void;
};

const ChangeInfraTypeModal = ({
  objectId,
  locationId,
  objectType,
  objectCoolingSystemId,
  objectNumOfPhase,
  objectHvacSystemType,
  onSubmit,
  close,
}: RenameFormModalProps & Closable) => {
  const [typeInput, setTypeInput] = useState<InfrastructureTypeEnum>(objectType.type);
  const [coolingSystemId, setCoolingSystemId] = useState<string | undefined>(objectCoolingSystemId);
  const [coolingSystems, setCoolingSystems] = useState<GetCoolingSystemQuery["infrastructures"]>();
  const [hvacSystemType, setHvacSystemType] = useState<HvacSystemTypeEnum | undefined>(objectHvacSystemType);

  useGetCoolingSystemQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setCoolingSystems(data.infrastructures);
    },
  });

  const isCoolingDevice = (type: string) => {
    if (
      type === InfrastructureTypeEnum.CHILLER ||
      type === InfrastructureTypeEnum.COOLING_TOWER ||
      type === InfrastructureTypeEnum.PUMP
    )
      return true;
    return false;
  };

  const handleSubmitChangeInfraType = () => {
    const compressorChanged = typeInput === InfrastructureTypeEnum.COMPRESSOR && hvacSystemType;
    const coolingDeviceChanged = isCoolingDevice(typeInput) && coolingSystemId;

    const inputChanged =
      (typeInput && typeInput !== objectType.type && (compressorChanged || coolingDeviceChanged)) ||
      (coolingDeviceChanged && objectCoolingSystemId !== coolingSystemId) ||
      (compressorChanged && objectHvacSystemType !== hvacSystemType);

    if (inputChanged) {
      const oldType = getInfraTypeValue(objectType.type, objectNumOfPhase);
      const newType = getInfraTypeValue(typeInput, objectNumOfPhase);
      onSubmit(objectId, oldType, newType, hvacSystemType, coolingSystemId);
      close();
    }
  };

  return (
    <Modal
      title="Change Infrastructure Type"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitChangeInfraType}
      width={400}
      okText="Change"
    >
      <Row>
        <p className="mb-xs">Infrastructure Type</p>
        <Select className="infrastructure-type-selector" defaultValue={typeInput} onSelect={setTypeInput}>
          {infrastructureTypes
            ?.filter(
              (infraType) =>
                infraType.value !== InfrastructureTypeEnum.CHILLED_WATER &&
                infraType.value !== InfrastructureTypeEnum.PIPE
            )
            .map((infraType) => (
              <Select.Option key={infraType.value} value={infraType.value}>
                {infraType.label}
              </Select.Option>
            ))}
        </Select>
      </Row>
      {isCoolingDevice(typeInput) && (
        <Row className="mt-xl">
          <p className="mb-xs">Cooling System</p>
          <Select className="infrastructure-type-selector" defaultValue={coolingSystemId} onSelect={setCoolingSystemId}>
            {coolingSystems?.map((system) => {
              return (
                <Select.Option value={system.id} key={system.id}>
                  {system.name}
                </Select.Option>
              );
            })}
          </Select>
        </Row>
      )}
      {typeInput === InfrastructureTypeEnum.COMPRESSOR && (
        <Row className="mt-xl">
          <p className="mb-xs">HVAC System Type</p>
          <Select className="infrastructure-type-selector" defaultValue={hvacSystemType} onSelect={setHvacSystemType}>
            <Select.Option value={HvacSystemTypeEnum.VRV}>VRV</Select.Option>
            <Select.Option value={HvacSystemTypeEnum.SPLIT}>Split</Select.Option>
          </Select>
        </Row>
      )}
    </Modal>
  );
};

export default ChangeInfraTypeModal;
