import { Space, Typography } from "antd";
import { CalendarEmptyIcon } from "components/Icons";
import React from "react";

export interface PrecoolScheduleEmptyProps {
  [x: string]: any;
}

export const PrecoolScheduleEmpty: React.FC<PrecoolScheduleEmptyProps> = () => {
  return (
    <Space direction="vertical" size="middle" align="center" style={{ paddingTop: "105px" }}>
      <CalendarEmptyIcon />
      <Typography.Text>
        You can setup a schedule to pre-cool your room at a specific time every day during your stay or even specific
        days of the week, so you always walk into a comfortable room environment when you need it the most.
      </Typography.Text>
    </Space>
  );
};
