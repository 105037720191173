import React from "react";

import { Form, Input, message, Modal } from "antd";
import { useCreateSlotMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import NodeTypeFormItem from "../NodeTypeFormItem";

type CreateSlotFormModalProps = {
  roomId: string;
  hasAircon: boolean;
  onCompleted: () => void;
  closeModal: () => void;
};

const validateMessages = {
  required: "This field is required.",
};

const CreateSlotFormModal = ({ roomId, onCompleted, closeModal, hasAircon }: CreateSlotFormModalProps) => {
  const [form] = Form.useForm();

  const [createSlotMutation] = useCreateSlotMutation({
    onCompleted() {
      message.info("Slot has been created.");
      onCompleted();
    },
  });

  const handleSubmitCreateSlot = (values: any) => {
    createSlotMutation({
      variables: {
        slotInput: {
          parentPositionId: roomId,
          slotName: values.slotName,
          nodeType: values.nodeType,
          nodeSubType: values.nodeSubType,
        },
      },
    });
    closeModal();
  };

  return (
    <Modal
      title="Add New Node Slot"
      centered
      visible
      onCancel={closeModal}
      onOk={form.submit}
      width={400}
      okText="Confirm"
    >
      <Form
        name="create-slot-form"
        form={form}
        layout="vertical"
        requiredMark={false}
        validateMessages={validateMessages}
        onFinish={handleSubmitCreateSlot}
      >
        <Form.Item name={["slotName"]} label="Slot Name" rules={[{ required: true }]}>
          <Input id="slot-name-input" data-testid="slot-name-input-testId" placeholder="Ceiling" />
        </Form.Item>
        <NodeTypeFormItem form={form} hasAircon={hasAircon} />
      </Form>
    </Modal>
  );
};

export default CreateSlotFormModal;
