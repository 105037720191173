/* eslint-disable prefer-promise-reject-errors */
import { message } from "antd";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useUnmapInfraNodeMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import SelectingReason from "pages/Node/ContractorNodeList/UnmapedNode/SelectingReason";
import React, { useState } from "react";
import NodeMacScan from "./NodeMacScan";
import { UnmapModalProps } from "./UnmapModal.d";

function UnmapModal(props: UnmapModalProps) {
  const [isSelectingReason, setIsSelectingReason] = useState<boolean>(false);
  const { onUnmapSuccessfully, currentNodeMacId, infraId, slotName, infraName } = props;
  const [scannedMacId, setScannedMacId] = useState<string>();

  const [unmapNode, { loading: isUnmapping }] = useUnmapInfraNodeMutation({
    onCompleted: () => {
      message.success("Unmap successfully.");
      if (isFunction(onUnmapSuccessfully)) onUnmapSuccessfully();
    },
    onError: errorHandler.handleError,
  });

  const handleOnSubmit = async (rootCauseId: number, comment?: string) => {
    await unmapNode({
      variables: {
        infraId,
        nodeMacId: currentNodeMacId,
        scannedMacId,
        rootCauseId,
        comment,
      },
    });
    if (isFunction(onUnmapSuccessfully)) onUnmapSuccessfully();
  };

  const scannedMacIdSubmit = (nodeMacId?: string) => {
    setScannedMacId(nodeMacId);
    setIsSelectingReason(true);
  };

  return (
    <>
      {!isSelectingReason ? (
        <NodeMacScan
          handleCannotScan={() => setIsSelectingReason(true)}
          scannedMacIdSubmit={scannedMacIdSubmit}
          currentNodeMacId={currentNodeMacId}
          infraName={infraName}
        />
      ) : (
        <>
          <SelectingReason
            currentNodeMacId={currentNodeMacId}
            slotName={slotName}
            reasonSubmit={handleOnSubmit}
            isUnmapping={isUnmapping}
          />
        </>
      )}
    </>
  );
}

export default UnmapModal;
