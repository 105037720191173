import { Button, Col, Modal, Row } from "antd";
import React from "react";

const AutoExitModal = ({
  open,
  onClose,
  onOk,
  keyName,
}: {
  keyName?: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
}) => {
  return (
    <Modal
      className="select-test-run-modal"
      title="Installation Mode Expired"
      centered
      width={450}
      okText="Confirm"
      visible={open}
      onCancel={onClose}
      footer={null}
    >
      <Row justify="center">
        <Col span={24}>
          <p>
            Key: <b>{keyName}</b>. Remember to restart all nodes if you resume the previous session
          </p>
          <br />
        </Col>
        <Col span={24}>
          <Button type="primary" className="ant-btn ant-btn-primary modal-button" onClick={onOk}>
            Resume previous session
          </Button>
        </Col>
        <Col span={24}>
          <Button type="default" className="modal-button button-no" onClick={onClose}>
            Ignore
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AutoExitModal;
