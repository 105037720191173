import { useEffect, useMemo, useState } from "react";

export interface IScanCameraOptions {
  skipSettingDefaultDevice?: boolean;
}

export default function useScanCamera(options?: IScanCameraOptions) {
  const [videoDevices, setVideoDevices] = useState<any[]>();
  const [videoDeviceId, setVideoDeviceId] = useState<string>();

  const qrReaderProps = useMemo(() => {
    if (videoDeviceId) {
      return {
        chosenCamera: videoDeviceId,
        constraints: {
          focusMode: "continuous",
          deviceId: videoDeviceId,
        },
      };
    }
    return {
      chosenCamera: "",
      constraints: {
        focusMode: "continuous",
        facingMode: {
          ideal: "environment",
        },
      },
    };
  }, [videoDeviceId]);

  useEffect(() => {
    const fetchVideoDeviceList = async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }
      // check for the permission to use the camera and prompt the user if needed
      await navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices: any[] = devices
        .filter((device) => device.kind === "videoinput" && device.deviceId)
        .map((device, index) => ({
          id: device.deviceId,
          label: device.label ? device.label : `Camera ${index + 1}`,
        }));

      // Set first device as default if no device selected
      if (!options?.skipSettingDefaultDevice) {
        if (videoInputDevices.length > 0 && !videoDeviceId) {
          setVideoDeviceId(videoInputDevices[0].id);
        }
      }

      setVideoDevices(videoInputDevices);
    };

    if (navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = () => {
        fetchVideoDeviceList();
      };
    }

    fetchVideoDeviceList();
  }, [options, videoDeviceId]);

  const onCameraDeviceChange = (deviceId: string) => {
    setVideoDeviceId(deviceId);
  };

  return { videoDevices, videoDeviceId, onCameraDeviceChange, qrReaderProps };
}
