import get from "lodash/get";
import { UserWithAuthTokenQuery } from "pacts/app-webcore/hasura-webcore.graphql";

export type UserWithAuthToken = NonNullable<UserWithAuthTokenQuery["userWithAuthToken"]> | undefined;
export const ROLE = {
  WAREHOUSE_STAFF: "Warehouse Staff",
  SUPPORT_SPECIALIST: "Support Specialist",
  PROJECT_COORDINATOR: "Project Coordinator",
  INSTALLER: "Installer",
  CUSTOMER: "Hotel Viewer",
};

const getRules = (user: UserWithAuthToken) => {
  const role = get(user, "role.name");
  if (
    role === ROLE.WAREHOUSE_STAFF ||
    role === ROLE.SUPPORT_SPECIALIST ||
    role === ROLE.PROJECT_COORDINATOR ||
    role === ROLE.INSTALLER ||
    role === ROLE.CUSTOMER
  ) {
    const permissions = user?.role.permissions.map(({ permissionKey }) => permissionKey) || [];
    return {
      defaultRoute: user?.role.defaultRoute,
      name: role,
      permissions: {
        static: [...permissions],
        dynamic: [],
      },
      roleName: role,
      isContractor: !!user?.isContractor && role === ROLE.INSTALLER,
      isManager: !!user?.isManager,
      isDev: !!user?.isDev,
      user,
    };
  }
};

export default getRules;
