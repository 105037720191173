import React, { useState } from "react";

import { Modal } from "antd";
import { Closable } from "components/Modal/show";
import { groupBy } from "lodash";
import FwVersionSelector from "../OtaNodeFilter/FwVersionSelector/FwVersionSelector";

export type LockFwVersionModalProps = {
  firmwares: any[];
  onOk: (fwVersion: number) => void;
};

const UpdateFwVersionModal = ({ firmwares, onOk, close }: LockFwVersionModalProps & Closable) => {
  const [selectedFirmware, setSelectedFirmware] = useState<number>();

  const groups = groupBy(firmwares, (p) => p.nodeSubType);

  const handleSubmitAction = () => {
    if (selectedFirmware) {
      onOk(selectedFirmware);
      close();
    }
  };

  const handleOnChange = (value: number) => {
    setSelectedFirmware(value);
  };

  return (
    <Modal
      title="Update Target FW Version"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !selectedFirmware,
      }}
    >
      <p className="mb-m">Selected nodes will receive the FW update at the next OTA window.</p>
      <FwVersionSelector groups={groups} onChange={handleOnChange} value={selectedFirmware} valueKey="id" />
    </Modal>
  );
};

export default UpdateFwVersionModal;
