import React, { useContext, useCallback } from "react";
import Table from "components/Table/Table";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { Tag } from "antd";
import { Order_By } from "pacts/app-webcore/hasura-webcore.graphql";
import { formatFromNow } from "utils/date";
import { capitalize, get, uniq } from "lodash";
import { Link } from "react-router-dom";
import { Infrastructure } from "../Infrastructures.d";
import { InfrastructureTableProps } from "./InfrastructureTable.d";
import { InfrastructureType } from "../InfrastructureDataType";

const sortOrder = (value: Order_By) => get({ [Order_By.Asc]: "ascend", [Order_By.Desc]: "descend" }, value, "");

const getColorTag = (status?: string) => {
  const defaultColor = "default";
  const colorTags = {
    online: "success",
    offline: "error",
  };

  return colorTags[status as keyof typeof colorTags] || defaultColor;
};

const InfrastructureTable: React.FC<InfrastructureTableProps> = ({
  data,
  pagination,
  sort = {},
  sortBy = () => {},
  onPaginationChange = () => {},
  rowSelection,
  locationId,
  locationName,
}) => {
  const { currentPositionId } = useContext(InstallationModeContext);

  const getSortConfig = (sortKey: string) => {
    return {
      onHeaderCell: () => ({
        onClick: () => sortBy(sortKey),
      }),
      sorter: true,
      sortOrder: sortOrder(sort[sortKey]),
    };
  };

  const columns = [
    {
      title: "Infrastructure",
      render: (record: Infrastructure) => (
        <Link
          to={{
            pathname: `/locations/${locationId}/infrastructures/${record.id}`,
            state: {
              locationName,
            },
          }}
        >
          <span>{record?.name}</span>
        </Link>
      ),
      visibleOnDetailView: false,
      ...getSortConfig("name"),
    },
    {
      title: "Status",
      render: (record: Infrastructure) => {
        const connectedNodes = record?.meterPosition?.nodes || [];
        const nodeConnected = connectedNodes[0];
        const status = (
          currentPositionId === record?.id ? nodeConnected?.nodeStatusInInstallationMode : nodeConnected?.status
        ) as string;
        const colorTag = getColorTag(status || "");

        return <Tag color={colorTag}>{capitalize(status || "Not mapped")}</Tag>;
      },
      visibleOnDetailView: false,
    },
    {
      title: "Compressor Location",
      render: (record: Infrastructure) => {
        const positionType = record.position?.positionType as string;
        const validTypes = new Set(["floor", "group", "key", "room", "zone"]);
        const location = validTypes.has(positionType) ? record.position?.positionName : "--";
        return <span>{location}</span>;
      },
      visibleOnDetailView: false,
    },
    {
      title: "Keys Connected",
      render: (record: Infrastructure) => {
        const infraType = new InfrastructureType(record?.type as string);
        if (!infraType.isCompressor()) return "";
        const aggregatedKeys =
          record?.rooms?.length > 0
            ? uniq(record?.rooms.map((r) => r.position?.parentPosition?.positionName)).join(", ")
            : "None";

        return <span>{aggregatedKeys}</span>;
      },
      visibleOnDetailView: false,
    },
    {
      title: "Node Connected",
      render: (record: Infrastructure) => {
        const infraType = new InfrastructureType(record?.type as string);
        const connectedNodes = record?.meterPosition?.nodes || [];
        const nodeConnected = connectedNodes[0];
        if (!nodeConnected || infraType.isCoolingSystem()) return "--";

        return <span>{nodeConnected.nodeMacId}</span>;
      },
      visibleOnDetailView: false,
    },
    {
      title: "Last Seen",
      render: (record: Infrastructure) => {
        const infraType = new InfrastructureType(record?.type as string);
        const connectedNodes = record?.meterPosition?.nodes || [];
        const nodeConnected = connectedNodes[0];
        if (!nodeConnected || infraType.isCoolingSystem()) return "";

        return <span>{formatFromNow(nodeConnected.lastSeen)}</span>;
      },
      visibleOnDetailView: false,
    },
  ];

  const genRowClassNames = useCallback(() => ({ "cursor-pointer": true }), []);

  return (
    <Table
      columns={columns}
      rowKey="id"
      tableData={data || []}
      rowClassName={genRowClassNames}
      pagination={{ ...pagination, pageSizeOption: ["10", "20", "50", "100"], showSizeChanger: true }}
      handleTableChange={onPaginationChange}
      rowSelection={rowSelection}
    />
  );
};

export default InfrastructureTable;
