import React from "react";

import { Col, Progress, Row } from "antd";

import "./SidebarStatusItem.scss";
import { round } from "lodash";
import { SidebarStatusItemData } from "./SidebarStatusItem.d";
import ObjectHealth from "../../../../components/ObjectHealth";

type SidebarStatusItemProps = {
  showAsHealthStatus?: boolean;
};

const SidebarStatusItem = (props: SidebarStatusItemProps & SidebarStatusItemData) => {
  const { name, total, current, missing, booted, showAsHealthStatus, showWithPercentSign } = props;

  const totalCount = !total ? missing! + current + (booted ?? 0) : total;

  const getDisplayText = () => {
    if (showAsHealthStatus) {
      return <ObjectHealth online={current} offline={missing!} booted={booted ?? 0} />;
    }
    if (showWithPercentSign) {
      const result = round((current / totalCount) * 100, 2);
      return `${result}%`;
    }
    return `${current}/${totalCount}`;
  };

  return (
    <div className="sidebar-status-item-container" data-testid="sidebar-status-item-container">
      <Row>
        <Col span={16}>
          <span className="sidebar-status-item-name">{name}</span>
        </Col>
        <Col span={8} className="sidebar-status-item-stats-text-container">
          <span className="sidebar-status-stats-text">{getDisplayText()}</span>
        </Col>
      </Row>
      <Row>
        <Progress
          percent={totalCount === 0 ? 0 : (100 * current) / totalCount}
          size="small"
          className="content progress-bar sidebar-status-item-progress-bar"
          strokeWidth={7}
          status="normal"
          showInfo={false}
          data-testid="sidebar-status-item-progress-bar"
        />
      </Row>
    </div>
  );
};

export default SidebarStatusItem;
