import React from "react";
import { Modal, Row, Select } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import {
  useAddLocationDomoEmbedMutation,
  useDomoEmbedsQuery,
  useRemoveLocationDomoEmbedMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { DomoEmbed } from "../Analytics.d";

interface ManageLocationDashboardsModalProps {
  locationId: string;
  locationDomoEmbeds: DomoEmbed[];
  refetch: () => void;
}

const ManageLocationDashboardsModal = ({
  locationId,
  locationDomoEmbeds,
  refetch,
  modalWrapperProps: { onCancel, ...modalWrapperOtherProps },
}: ManageLocationDashboardsModalProps & ModalWrapperProps) => {
  const [removeLocationDomoEmbed] = useRemoveLocationDomoEmbedMutation();
  const [addLocationDomoEmbed] = useAddLocationDomoEmbedMutation();

  const { data, loading } = useDomoEmbedsQuery();

  const handleOnCancel = async () => {
    await refetch();
    // Invoke the onCancel callback passed in by the ModalWrapper
    onCancel?.();
  };

  const handleSelect = (embedId: string) => {
    addLocationDomoEmbed({
      variables: {
        object: {
          location_id: locationId,
          embed_id: embedId,
        },
      },
    });
  };
  const handleDeselect = (embedId: string) => {
    removeLocationDomoEmbed({
      variables: {
        location_id: locationId,
        embed_id: embedId,
      },
    });
  };

  if (loading) return <p>Loading...</p>;

  const options = data?.domoEmbeds.map(({ name, embed_id }: any) => {
    return {
      label: `${name}`,
      value: `${embed_id}`,
    };
  });
  const defaultValue = locationDomoEmbeds.map(({ name, embed_id }) => {
    return {
      label: `${name}`,
      value: `${embed_id}`,
    };
  });

  return (
    <Modal
      title="Manage Location Dashboards"
      centered
      width={450}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={handleOnCancel}
      {...modalWrapperOtherProps}
    >
      <Row>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Please select"
          defaultValue={defaultValue}
          options={options}
          // @ts-expect-error Callback type is not correct
          onSelect={handleSelect}
          // @ts-expect-error  Callback type is not correct
          onDeselect={handleDeselect}
        />
      </Row>
    </Modal>
  );
};

export default ManageLocationDashboardsModal;
