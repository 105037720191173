import { Badge, Button, Card, Col, Divider, Row, Typography } from "antd";
import React, { useCallback } from "react";
import { MAPPING_STATUS_UI_CONFIG_CONTRACTOR } from "utils/constants";
import { Link } from "react-router-dom";
import { KeyIcon } from "components/Icons";
import { LocationsWithNodeHealthAndTasks } from "../LocationList.d";
import "./LocationListContractor.scss";

export interface LocationListContractorProps extends LocationsWithNodeHealthAndTasks {
  [x: string]: any;
}

export const LocationListContractor: React.FC<LocationListContractorProps> = ({ id, locationStats, locationName }) => {
  const TagStatus = useCallback(() => {
    const uiConfig = MAPPING_STATUS_UI_CONFIG_CONTRACTOR[locationStats?.mappingStatus];
    if (!locationStats && !uiConfig) return null;
    return <Badge status={uiConfig[1]} text={uiConfig[0]} />;
  }, [locationStats]);

  return (
    <Card
      title={locationName}
      extra={TagStatus()}
      className="location-contructor-card overflow-hidden radius-xs border-semi-blue"
    >
      <Row>
        <Col span={24}>
          <Typography.Text>
            <KeyIcon /> {locationStats?.keysStats?.totalCount || 0}
          </Typography.Text>
        </Col>
        <Divider className="my-m" />
        <Col span={24} className="text-right">
          <Link to={`/locations/${id}`}>
            <Button className="font-weight-bold w-100 radius-xs border-light-blue bg-light-blue text-primary">
              Work info
            </Button>
          </Link>
        </Col>
      </Row>
    </Card>
  );
};
