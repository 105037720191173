import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal, Row } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ApolloError } from "apollo-client";
import { useCreateKeysMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { BULK_CREATE_MAX_KEYS_LIMIT, ErrorMessage, GraphQLErrorCode } from "utils/constants";
import errorHandler from "errorHandler";
import { Category, Key, KeyHelper, Session } from "../DataTypes";
import CategoryList from "./CategoryList";
import KeyListInput from "./KeyListInput";
import "./KeysAssign.scss";

interface KeysAssignProps {
  session: Session;
  goBackKeyCreationStep: () => void;
}

const KeysAssign = ({ session, goBackKeyCreationStep }: KeysAssignProps) => {
  const history = useHistory();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);
  const inputsRef = useRef(Array(session?.getCategories().length));
  const [disableCreateKey, setDisableCreateKey] = useState<boolean>(false);

  const [createKeys] = useCreateKeysMutation({
    onCompleted: () => {
      setDisableCreateKey(false);
      history.push(`/locations/${session.locationId}/keys`);
    },
    onError: (error: ApolloError) => {
      setDisableCreateKey(false);
      errorHandler.handleError(error, {
        [GraphQLErrorCode.CONSTRAINT_VIOLATION]: ErrorMessage.DUPLICATE_KEYS,
      });
    },
  });

  const handleKeysAssign = () => {
    const inputs = inputsRef.current.map((inputRef: any) => inputRef.resizableTextArea.textArea.value);
    const keysList = KeyHelper.inputsToKeys(inputs);
    if (keysList.reduce((acc, keys) => acc + keys.length, 0) > BULK_CREATE_MAX_KEYS_LIMIT) {
      Modal.error({
        content: ErrorMessage.BULK_CREATE_MAX_KEYS_LIMIT_ERROR,
      });
      return;
    }
    keysList.forEach((keys: Array<Key>, index: number) => {
      session.getCategories()[index].addKeys(keys);
    });

    setDisableCreateKey(true);
    createKeys({
      variables: {
        keysInput: session.transformToKeyCategoryToKeyPositionInsertInput(),
      },
    });
  };

  const updateRef = (element: TextAreaRef, index: number) => {
    inputsRef.current[index] = element;
  };

  return (
    <div className="assign-keys">
      <Row>
        <Col span={8}>
          <CategoryList
            categories={session.getCategories()}
            onCategoryChange={setSelectedCategoryIndex}
            selectedCategoryIndex={selectedCategoryIndex}
          />
        </Col>
        <Col span={16}>
          {session.getCategories().map((category: Category, index: number) => (
            <KeyListInput
              key={category.id}
              categoryName={category.name}
              hidden={index !== selectedCategoryIndex}
              ref={(element: TextAreaRef) => updateRef(element, index)}
            />
          ))}
        </Col>
      </Row>
      <Row className="keys-assign-footer">
        <Col span={3}>
          <Button className="setup-categories" onClick={goBackKeyCreationStep}>
            <LeftOutlined /> Set Up Categories
          </Button>
        </Col>
        <Col span={3} offset={18} className="footer-right">
          <Button type="primary" className="create-keys" disabled={disableCreateKey} onClick={handleKeysAssign}>
            Create Keys <RightOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default KeysAssign;
