const { REACT_APP_NODE_LIST_LOOKER_DASHBOARD_URL } = process.env;

export const getNodeListLookerDashboardLink = (nodeMacId: string) => {
  return `${REACT_APP_NODE_LIST_LOOKER_DASHBOARD_URL}?Date%20Range=7%20days&Node%20Mac%20ID=${nodeMacId}`;
};

export const getCurrentDomainAndProtocol = () => {
  return window.location.href.split("/").slice(0, 3).join("/");
};

export const getKeyDetailLink = (locationId: string, keyId: string) => {
  return `${getCurrentDomainAndProtocol()}/locations/${locationId}/keys/${keyId}`;
};

export const getInfraDetailLink = (locationId: string, keyId: string) => {
  return `${getCurrentDomainAndProtocol()}/locations/${locationId}/infrastructures/${keyId}`;
};

export const getGatewayDetailLink = (balenaId: string) => {
  return `${process.env.REACT_APP_BALENA_DASHBOARD_BASE_URL}/devices/${balenaId}`;
};
