export interface JobsProps {
  [x: string]: any;
}

export type KeyAndTask = {
  keyName: string;
  keyId: string;
  categoryName?: string;
  totalRooms: number;
  totalKeys?: number;
  group?: {
    groupName?: string;
    groupType?: string;
  };
  possitionType?: string;
  isNew?: boolean;
  remainKeyEntries?: number;
  taskDueDate?: any;
};

export enum DueDateValue {
  THIS_WEEK = "THIS_WEEK",
  NEXT_WEEK = "NEXT_WEEK",
  OVERDUE = "OVERDUE",
  TOMORROW = "TOMORROW",
}
