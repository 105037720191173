import { Bar as BarChartjs, LinearComponentProps } from "react-chartjs-2";
import React, { ReactNode, useState } from "react";

import BaseLinear from "../BaseLinear";

const ARROW_POSITION = 20;

const defaultOptions = {
  tooltips: {
    mode: "index",
    position: "nearest",
  },
};

type TooltipData = {
  title: string;
  bodyLines: { value: string; backgroundColor: string }[];
};

type Props = {
  renderTooltip: (data: TooltipData | null) => ReactNode;
  xAxesTicksCallback: (value: string) => string | string[];
};

const Bar = (props: Props & Partial<LinearComponentProps>) => {
  const { data, options, renderTooltip, xAxesTicksCallback, ...restProps } = props;

  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

  const setTooltipContent = (tooltipModel: any) => {
    setTooltipData({
      title: tooltipModel.title?.[0] || "",
      bodyLines: tooltipModel.dataPoints.map((bodyItem: any, index: number) => {
        return {
          value: bodyItem.value,
          backgroundColor: tooltipModel.labelColors[index].backgroundColor,
        };
      }),
    });
  };

  return (
    <BaseLinear
      chart={BarChartjs}
      data={data}
      options={{ ...defaultOptions, ...options }}
      tooltipContent={renderTooltip(tooltipData)}
      setTooltipContent={setTooltipContent}
      tootipOffset={{
        x: ARROW_POSITION,
      }}
      xAxesTicksCallback={xAxesTicksCallback}
      {...restProps}
    />
  );
};

export default Bar;
