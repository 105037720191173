import { Select } from "antd";
import { MasterDataContext } from "contexts/master-data-context";
import React, { useContext } from "react";
import baseACModelFormatter from "../../utils/BaseACModelFormatter";

const BaseACModelDropdown = (props: {
  onChange: (value: any) => void;
  baseAcModelId?: string | null;
  placeholder?: string;
  label?: any;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
}): React.ReactElement => {
  const { baseAcModelId, onChange, placeholder, label, className, dataTestId, disabled } = props;
  const masterDataContext = useContext(MasterDataContext);
  return (
    <div>
      {label ?? (
        <span className="d-block text-scorpion font-weight-bold fs-sm text-uppercase mb-xs mt-s">Base AC Model</span>
      )}

      <Select
        className={className ?? "w-100"}
        data-testid={dataTestId}
        value={baseAcModelId}
        onChange={onChange}
        showSearch
        placeholder={placeholder}
        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        disabled={disabled}
      >
        {masterDataContext?.acModels?.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {baseACModelFormatter.getDisplayName(option)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BaseACModelDropdown;
