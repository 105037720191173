import { Gateway } from "pacts/app-webcore/hasura-webcore.graphql";

export type GatewaySetting = {
  defaultPrefix?: string;
  prefix?: string;
  quantity?: number;
  gateways: Partial<Gateway>[];
};

export const gatewayNamePattern = "^[a-zA-Z0-9_-]+$";
