import React, { useEffect, useRef, useState } from "react";

import { Input, message, Modal } from "antd";
import { useUpdatePositionMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../errorHandler";

type RenameRoomFormModalProps = {
  roomId: string;
  roomName: string;
  reloadKey: () => void;
  closeModal: () => void;
};

const RenameRoomFormModal = ({ roomId, roomName, reloadKey, closeModal }: RenameRoomFormModalProps) => {
  const [input, setInput] = useState<string>(roomName);
  const roomNameInputRef: any = useRef();

  const [renameKey] = useUpdatePositionMutation({
    onCompleted: () => {
      message.info("Room name has been updated.");
      reloadKey();
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  useEffect(() => {
    roomNameInputRef.current!.focus();
  }, [roomNameInputRef]);

  const handleSubmitRenameRoom = () => {
    const inputChanged = input && input !== roomName;
    if (inputChanged) {
      renameKey({
        variables: {
          positionId: roomId,
          updatePositionInput: {
            positionName: input,
          },
        },
      });
      closeModal();
    }
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <Modal
      title="Rename Room"
      centered
      visible
      onCancel={closeModal}
      onOk={handleSubmitRenameRoom}
      width={400}
      okText="Rename Room"
    >
      <p className="mb-xs">Room Name</p>
      <Input
        id="room-name-input"
        data-testid="room-name-input-testId"
        placeholder="Bedroom"
        onChange={handleInputChange}
        onPressEnter={handleSubmitRenameRoom}
        ref={roomNameInputRef}
        value={input}
      />
    </Modal>
  );
};

export default RenameRoomFormModal;
