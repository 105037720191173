import { useHistory } from "react-router-dom";
import { ApolloError } from "apollo-client";
import { message } from "antd";
import get from "lodash/get";
import { ErrorMessage } from "utils/constants";

enum ErrorStatusCode {
  NO_PEMISSION = 401,
}
export interface GraphqlErrorCustomMessageMap {
  [code: string]: ErrorMessage;
}

const handleError = (error: ApolloError, messages: GraphqlErrorCustomMessageMap = {}) => {
  const graphqlErrorCode: string = get(error, "graphQLErrors[0].extensions.code");
  const errorMessage: string = messages[graphqlErrorCode] || error.message;
  message.error(errorMessage);
  return errorMessage;
};

export const useErrorHandler = () => {
  const history = useHistory();

  const handleErrorWithStatusCode = (error: ApolloError) => {
    const statusCode = get(error, "graphQLErrors[0].extensions.exception.statusCode");

    if (parseInt(statusCode) === ErrorStatusCode.NO_PEMISSION) {
      history.push("/locations");
    }
    handleError(error);
  };

  return { handleErrorWithStatusCode };
};

export default { handleError };
