import React from "react";

import { Alert } from "antd";
import "./ExternalError.css";

const ExternalError = ({ message, details }: any) => {
  const description = details ? (
    <>
      {details.map((detail: any, index: number) => (detail === "" ? <br key={index} /> : <p key={index}>{detail}</p>))}
    </>
  ) : (
    []
  );
  return <Alert className="external-error" message={message} description={description} type="error" showIcon />;
};

export default ExternalError;
