import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Input, message, Modal } from "antd";
import isEqual from "lodash/isEqual";
import { useDeleteInfraMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { ModalWrapperProps } from "components/Modal/Modal";
import errorHandler from "errorHandler";

interface DeleteInfrastructureModalProps {
  name: string;
  infraId: string;
  onSuccess: () => void;
}

const DeleteInfrastructureModal = ({
  infraId,
  closeModal,
  onSuccess,
  modalWrapperProps,
  name,
}: DeleteInfrastructureModalProps & ModalWrapperProps) => {
  const [confirmByText, setConfirmByText] = useState<string>();

  const confirmedByText = isEqual(name, confirmByText);

  const [deleteInfra, { loading: deletingInfra }] = useDeleteInfraMutation({
    onCompleted: () => {
      message.success(`${name} Deleted`);
      closeModal();
      onSuccess();
    },
    onError: errorHandler.handleError,
  });

  const handleRemove = () => {
    deleteInfra({
      variables: {
        infraId,
      },
    });
  };

  const RenderTitle = () => (
    <h3 className="font-weight-bold">
      <ExclamationCircleOutlined className="text-warning mr-l" />
      Delete Infrastructure.
    </h3>
  );

  const validateConfirmationByName = (e: any) => {
    setConfirmByText(e.target.value);
  };

  return (
    <Modal
      {...modalWrapperProps}
      title={<RenderTitle />}
      centered
      onOk={handleRemove}
      width={400}
      okText="Confirm"
      confirmLoading={deletingInfra}
      okButtonProps={{
        disabled: !confirmedByText,
      }}
    >
      <h4>Are you sure you want to delete {name}?</h4>
      <h4>This will disconnect all the Autoset and nodes and is irreversible.</h4>

      <div className="mt-m">
        <span className="text-gray">Key in Infrastructure Name to confirm</span>
        <Input placeholder="Enter Infrastructure Name" value={confirmByText} onChange={validateConfirmationByName} />
      </div>
    </Modal>
  );
};

export default DeleteInfrastructureModal;
