import { Alert, Table as ANTDTable } from "antd";
import classnames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import React from "react";
import logger from "services/logger/logger";
import MobileTable from "./MobileTable";
import { TableV3Props } from "./Table.d";
import TablePaginationV3 from "./TablePaginationV3";

const TableV3 = ({
  pagination,
  tableData,
  loading,
  error,
  columns,
  dataItemName,
  rowSelection,
  expandable,
  onExpand,
  expandedRowKeys,
  rowKey,
  style = { width: "100%" },
  mobileView,
  classNames,
  onRow,
  onChange,
  rowClassName,
  scroll,
  onPaginationChanged,
}: TableV3Props) => {
  const screen = useBreakpoint();
  if (loading) return <p className="mt-m">Loading...</p>;
  if (error) {
    logger.error(error);
    return (
      <Alert type="error" message={`Error getting ${dataItemName}`} data-testid="table-alert">
        {error.message}
      </Alert>
    );
  }

  if (screen.mobileOnly) {
    return (
      <MobileTable
        pagination={pagination}
        onPaginationChanged={onPaginationChanged}
        tableData={tableData}
        rowKey={rowKey}
      />
    );
  }

  return (
    <>
      <ANTDTable
        columns={columns}
        dataSource={tableData}
        rowKey={rowKey}
        rowSelection={rowSelection}
        expandable={expandable}
        pagination={false}
        onExpand={onExpand}
        expandedRowKeys={expandedRowKeys}
        rowClassName={rowClassName}
        style={style}
        className={classnames({ "m-table": mobileView, ...classNames })}
        onRow={onRow}
        onChange={onChange}
        scroll={scroll}
      />
      {tableData?.length > 0 && (
        <TablePaginationV3
          pageItemSize={tableData?.length}
          pagination={pagination}
          onPaginationChanged={onPaginationChanged}
        />
      )}
    </>
  );
};

export default TableV3;
