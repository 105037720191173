import React from "react";

import { Input } from "antd";
import { Element } from "react-scroll";
import isEmpty from "lodash/isEmpty";
import { Actuator, Room } from "./DataTypes";
import RoomActuator from "./RoomActuator";
import Occupancies from "./Occupancies";

type RoomDetailProps = {
  room: Room;
  onSessionChangeCallback: () => void;
};

const RoomDetail = ({ room, onSessionChangeCallback }: RoomDetailProps) => {
  const handleRoomRename = (name: string) => {
    room.setName(name);
    onSessionChangeCallback();
  };

  const onActuatorChange = (actuator: Actuator) => {
    room.setActuator(actuator);
  };

  const onOccupancyDoorLaserAddCallback = () => {
    room.addOccupancyLaser(`Door Laser ${room.doorLasers.length + 1}`);
  };

  const onOccupancyDoorMagneticAddCallback = () => {
    room.addOccupancyDoorMagnetic(`Door Magnetic ${room.doorMagnetics.length + 1}`);
  };

  const onOccupancyCeilingAddCallback = () => {
    room.addOccupancyCeiling(`Occupancy Ceiling ${room.occupancyCeilings.length + 1}`);
  };

  const onOccupancyWallAddCallback = () => {
    room.addOccupancyWall(`Occupancy Wall ${room.occupancyWalls.length + 1}`);
  };

  return (
    <Element name={room.id} className="room-detail">
      <div className="input-field">
        <p>Room Name</p>
        <Input placeholder="Room Name" onChange={(e) => handleRoomRename(e.target.value)} value={room.name} />
        {isEmpty(room.name) && <p className="required-text">It is required</p>}
      </div>

      <RoomActuator
        actuator={room.actuator}
        onActuatorChange={onActuatorChange}
        onSessionChangeCallback={onSessionChangeCallback}
      />

      <Occupancies
        typeDisplay="Door (Laser)"
        room={room}
        occupancies={room.doorLasers}
        onOccupancyAddCallback={onOccupancyDoorLaserAddCallback}
        onSessionChangeCallback={onSessionChangeCallback}
      />

      <Occupancies
        typeDisplay="Door (Magnetic)"
        room={room}
        occupancies={room.doorMagnetics}
        onOccupancyAddCallback={onOccupancyDoorMagneticAddCallback}
        onSessionChangeCallback={onSessionChangeCallback}
      />

      <Occupancies
        typeDisplay="Occupancy (Ceiling)"
        room={room}
        occupancies={room.occupancyCeilings}
        onOccupancyAddCallback={onOccupancyCeilingAddCallback}
        onSessionChangeCallback={onSessionChangeCallback}
      />

      <Occupancies
        typeDisplay="Occupancy (Wall)"
        room={room}
        occupancies={room.occupancyWalls}
        onOccupancyAddCallback={onOccupancyWallAddCallback}
        onSessionChangeCallback={onSessionChangeCallback}
      />
    </Element>
  );
};

export default RoomDetail;
