import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { NodeTypeCodeIdentifiers } from "pages/InfrastructureDetails/ContractorMappedNodes/MappedNodes.d";

export const NODE_MAC_ID_LENGTH = 16;
export const FULL_NODE_MAC_ID_LENGTH = 18;

export const getNodeTypeCodeIdentifier = (nodeType?: string) => {
  const nodeTypeCode = NodeTypeCodeIdentifiers.find((nodeTypeCodeId) => nodeTypeCodeId.type === (nodeType as NodeType));
  return nodeTypeCode?.code ?? "";
};

export const getFullNodeMacInput = (nodeMacId: string, nodeType?: string) => {
  const nodeTypeCodeIdentifier = getNodeTypeCodeIdentifier(nodeType);
  if (nodeMacId.length < NODE_MAC_ID_LENGTH) {
    return nodeMacId;
  }
  if (nodeMacId.length === NODE_MAC_ID_LENGTH) {
    return nodeTypeCodeIdentifier + nodeMacId;
  }
  return nodeMacId.padStart(FULL_NODE_MAC_ID_LENGTH, "0");
};

export default getNodeTypeCodeIdentifier;
