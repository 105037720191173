import { isEmpty } from "lodash";
import React from "react";
import { EodSurveyRecord, EodSurveyStatus } from "./types";

interface IEodSurveyIframeProps {
  survey?: EodSurveyRecord;
}

const EodSurveyIframe = ({ survey }: IEodSurveyIframeProps) => {
  if (isEmpty(survey?.data)) {
    return null;
  }
  return (
    <iframe
      title={survey?.surveyMonkeyId}
      width="100%"
      className="h-36r"
      frameBorder="none"
      src={
        survey?.status === EodSurveyStatus.COMPLETED && survey.data?.editResponseUrl
          ? survey.data.editResponseUrl
          : survey?.data?.surveyUrl
      }
    />
  );
};

export default EodSurveyIframe;
