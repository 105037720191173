import React, { useCallback, useState } from "react";
import { TestRun, TestRunStatus } from "pages/Checklist/Checklist.d";
import useLocationDateTime from "hooks/use-location-datetime";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Dropdown, message } from "antd";
import {
  CircleOutlinedIcon,
  EditSquareIcon,
  InforIcon,
  LoadingTestRunIcon,
  RefreshIcon,
  ThreeDotsVerticalIcon,
} from "components/Icons";
import "./TestList.scss";
import TestNoteModal from "pages/Checklist/TestNoteModal";
import { ReloadTestModal } from "pages/Checklist/ReloadTestModal";
import { useUpdateTestRunStatusMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { formatUTCTimestamp } from "utils/date";
import moment from "moment";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import _ from "lodash";
import ModalWrapper from "components/Modal/Wrapper";
import classNames from "classnames";
import { TestListProps } from "./TestList.d";

const TestItem: React.FC<{
  isCurrentKeyEntry?: boolean;
  testRun: TestRun;
  handleUpdateStatus: (testRun: TestRun) => Promise<void> | void;
  handleOpenResetModal: (testRun: TestRun) => Promise<void> | void;
  handleOpenNoteModal: (testRun: TestRun) => Promise<void> | void;
  lastSuccessOn: (testRun: TestRun) => any;
}> = ({ isCurrentKeyEntry, testRun, handleUpdateStatus, lastSuccessOn, handleOpenNoteModal, handleOpenResetModal }) => {
  const testRunStatusIcon = useCallback((status: any, isIgnored?: boolean, isManual?: boolean) => {
    switch (status) {
      case TestRunStatus.PASSED:
        if (isIgnored) return <CheckCircleFilled className="fs-l text-blue mx-s status-icon" />;
        return <CheckCircleFilled className="fs-l text-green mx-s status-icon" />;
      case TestRunStatus.FAILED:
        return <CloseCircleFilled className="fs-l text-danger mx-s status-icon" />;
      default:
        if (isManual) return <CircleOutlinedIcon className="fs-l text-gray mx-s status-icon" />;
        return <LoadingTestRunIcon className="fs-l text-danger mx-s status-icon loading-status-icon" />;
    }
  }, []);

  return (
    <div className="test-list-item">
      <span className="d-flex align-content-start justify-content-between mb-m">
        <div className="d-flex align-content-start">
          <div>
            <button
              type="button"
              className="bg-0 bg-transparent border-none h-100 p-none d-inline-flex align-content-start"
              onClick={() => handleUpdateStatus(testRun)}
            >
              {testRunStatusIcon(testRun.status, testRun.isIgnored, testRun.isManual)}
            </button>
          </div>
          <div className="font-weight-semi-bold">
            <p className="mb-none">{testRun.description}</p>
            <div className="font-weight-light fs-sm text-gray">{lastSuccessOn(testRun)}</div>
          </div>
        </div>
        <div>
          <Dropdown
            key={`${testRun.testRunId}-menu`}
            disabled={!isCurrentKeyEntry}
            menu={{
              items: [
                {
                  key: `${testRun.testRunId}-01`,
                  icon: <RefreshIcon className="mr-s" />,
                  label: "Reset this test",
                  onClick: () => handleOpenResetModal(testRun),
                },
                {
                  key: `${testRun.testRunId}-02`,
                  icon: <EditSquareIcon className="mr-s" />,
                  label: "Note",
                  onClick: () => handleOpenNoteModal(testRun),
                },
                {
                  key: `${testRun.testRunId}-03`,
                  label: (
                    <a target="_blank" rel="noopener noreferrer" href={testRun.troubleshootingLink}>
                      <InforIcon className="mr-s" />
                      Help
                    </a>
                  ),
                },
              ],
            }}
          >
            <ThreeDotsVerticalIcon className="pl-s" />
          </Dropdown>
        </div>
      </span>
    </div>
  );
};

const TestList: React.FC<TestListProps> = ({ testRuns, isCurrentKeyEntry }: TestListProps) => {
  const { formatDateTime } = useLocationDateTime();
  const [testNoteEditRef, openTestNoteEditModal] = useModalWrapperTrigger();
  const [resetModalRef, openResetModalRef] = useModalWrapperTrigger();
  const [currentTestRun, setCurrentTestRun] = useState<TestRun>();

  const lastSuccessOn = useCallback(
    (testRun: TestRun) => {
      const formatDetail = (details: string, status: string) => {
        const items = details.split(",");
        return (
          <div>
            {" "}
            {status === TestRunStatus.PASSED ? "Details" : "Error on"}:
            {items.map((item) => (
              <div style={{ marginLeft: "2em" }} key={item}>
                {item}
              </div>
            ))}
          </div>
        );
      };

      if (testRun.status === TestRunStatus.PENDING) {
        if (testRun.isManual) return "Not completed yet";
        return "Not started yet";
      }
      if (testRun.status === TestRunStatus.PASSED && testRun.lastPassedAt) {
        return (
          <>
            <span>Last success at: {formatDateTime(testRun.lastPassedAt)}</span>
          </>
        );
      }
      return testRun.status !== TestRunStatus.PENDING && testRun?.details
        ? formatDetail(testRun.details, testRun.status)
        : "Not succeeded yet";
    },
    [formatDateTime]
  );

  const [updateTestRunStatus] = useUpdateTestRunStatusMutation({
    onCompleted: async () => {
      await message.success("Updated status.");
    },
  });

  const handleOnResetStatus = useCallback(
    async (testRunId?: number) => {
      if (!testRunId) return;

      await updateTestRunStatus({
        variables: {
          status: TestRunStatus.PENDING,
          testRunId,
          startedAt: formatUTCTimestamp(moment()),
        },
      });
    },
    [updateTestRunStatus]
  );

  const handleUpdateStatus = useCallback(
    async (testRun: TestRun) => {
      if (!testRun.isManual) return;
      await updateTestRunStatus({
        variables: {
          testRunId: testRun.testRunId,
          status: testRun.status === TestRunStatus.PENDING ? TestRunStatus.PASSED : TestRunStatus.PENDING,
          lastPassedAt: testRun.status === TestRunStatus.PENDING ? formatUTCTimestamp(moment()) : null,
          lastTestedAt: testRun.status === TestRunStatus.PENDING ? formatUTCTimestamp(moment()) : null,
          startedAt: testRun.startedAt || formatUTCTimestamp(moment()),
        },
      });
    },
    [updateTestRunStatus]
  );

  const handleOpenResetModal = (testRun: TestRun) => {
    setCurrentTestRun(testRun);
    openResetModalRef();
  };

  const handleOpenNoteModal = (testRun: TestRun) => {
    setCurrentTestRun(testRun);
    openTestNoteEditModal();
  };

  const [autoTestRuns, manualTestRuns] = _.partition(testRuns || [], (testRun) => !testRun.isManual);

  return (
    <div className={classNames("testRunList", testRuns?.length > 0 ? "has-test-list" : "")}>
      {testRuns?.length > 0 && (
        <>
          <h3 className="font-weight-bold text-warning m-m">Test function</h3>
          <div className="checklist-detail m-m">
            <div className="font-weight-bold my-s">Automation Tests</div>
            {autoTestRuns?.map((testRun) => {
              return (
                <TestItem
                  key={testRun.testRunId}
                  testRun={testRun}
                  isCurrentKeyEntry={isCurrentKeyEntry}
                  lastSuccessOn={lastSuccessOn}
                  handleUpdateStatus={handleUpdateStatus}
                  handleOpenNoteModal={handleOpenNoteModal}
                  handleOpenResetModal={handleOpenResetModal}
                />
              );
            })}

            <div className="font-weight-bold my-s mt-xl">Manual Tests</div>

            {manualTestRuns?.map((testRun) => {
              return (
                <TestItem
                  key={testRun.testRunId}
                  testRun={testRun}
                  isCurrentKeyEntry={isCurrentKeyEntry}
                  lastSuccessOn={lastSuccessOn}
                  handleUpdateStatus={handleUpdateStatus}
                  handleOpenNoteModal={handleOpenNoteModal}
                  handleOpenResetModal={handleOpenResetModal}
                />
              );
            })}
          </div>
        </>
      )}

      <ModalWrapper ref={testNoteEditRef} modal={TestNoteModal} props={{ testRun: currentTestRun }} />
      <ModalWrapper
        ref={resetModalRef}
        modal={ReloadTestModal}
        props={{ onReset: () => handleOnResetStatus(currentTestRun?.testRunId) }}
      />
    </div>
  );
};

export default React.memo(TestList, ({ testRuns: prevTestRuns }, { testRuns: nextTestRun }) => {
  return JSON.stringify(nextTestRun) === JSON.stringify(prevTestRuns);
});
