export default [
  {
    nodeMacId: "FFFF14B4577AC3EE1",
    nodeStatus: "ONLINE",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE1",
    nodeStatus: "OFFLINE",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE3",
    nodeStatus: "BOOTED",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE4",
    nodeStatus: "BOOTED",
  },
];
