import React, { useCallback, useEffect, useState } from "react";
import { Col, Collapse, DatePicker, Empty, Row } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { AutomationMode } from "pages/Key/types";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import ListFilter from "components/ListFilter/ListFilter";
import { CsvExportBtn } from "components/CsvExport/CsvExportBtn";
import { generateAndDownloadCsv } from "utils/file";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import _ from "lodash";
import {
  ActivityLogsSubscription,
  FilterOption,
  FilterOptionType,
  useActivityLogsSubscription,
} from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import {
  ActionFilterInput,
  ActivityLog,
  LogGroupTypes,
  filterOptions,
  Room,
  CsvExportButtonProps,
} from "./ActivityLog.d";
import { DATETIME_24H_FORMAT, formatDate, formatUTCTimestamp } from "../../../../utils/date";
import { getActivityLogCsvHeader, transformLogs, transformLogToCsvData } from "./ActivityLog.helper";
import RoomComponent from "./Room";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const ActivityLogPage = (props: { keyName?: string }) => {
  const { timezone } = useSensorflowLocation();
  const { keyName } = props;
  const { keyId } = useParams<{ keyId: string; locationId: string }>();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [filter, setFilter] = useState<any>({
    keyId,
    fromDate: moment().add(-2, "days").utc(),
    toDate: moment().add(1, "day").utc(),
  });
  const [actionFilter, setActionFilter] = useState<ActionFilterInput>();
  const [activityLogs, setActivityLogs] = useState<ActivityLogsSubscription>();
  const [logsToCSV, setLogsToCSV] = useState<{ [key: string]: ActivityLog[] }>({});
  const [loadingStates, setLoadingStates] = useState<{ positionId: string; loading: boolean }[]>();

  const setToLoading = useCallback((roomData) => {
    setLoadingStates(() => {
      return roomData.map((r: Room) => {
        return {
          positionId: r.positionId,
          loading: true,
        };
      });
    });
  }, []);

  const bindingData = useCallback(async () => {
    if (!activityLogs || !activityLogs.key) return;
    const roomData = activityLogs.key.rooms.map((r) => {
      return {
        ...r,
      };
    });

    setToLoading(roomData);
    setRooms(roomData);
  }, [activityLogs, setToLoading]);

  useActivityLogsSubscription({
    variables: {
      ...filter,
      fromDate: formatUTCTimestamp(filter.fromDate),
      toDate: formatUTCTimestamp(filter.toDate),
    },
    onSubscriptionData: (data) => {
      const roomsData = data.subscriptionData.data?.key?.rooms || [];
      setActivityLogs(data.subscriptionData.data!);
      setToLoading(roomsData);
    },
  });

  const onDateFilterChanged = (values: any) => {
    setFilter({
      ...filter,
      fromDate: values[0],
      toDate: values[1],
    });
  };

  useEffect(() => {
    setFilter({
      keyId,
      fromDate: moment().add(-2, "days"),
      toDate: moment().add(1, "day"),
    });
  }, [keyId]);

  const handleFilterChange = (newFilter: ActionFilterInput) => {
    setActionFilter(newFilter);
  };

  useEffect(() => {
    bindingData();
  }, [bindingData]);

  const getFilterOptions = (): FilterOption[] => {
    const actionFilterOptions = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const mode in AutomationMode) {
      actionFilterOptions.push({
        field: LogGroupTypes.AUTOMATION_MODE,
        value: Object.values(AutomationMode)[Object.keys(AutomationMode).indexOf(mode)],
        label: mode,
        count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
        groupName: "AUTOMATION MODE",
        type: FilterOptionType.Checkbox,
      });
    }
    return [...actionFilterOptions, ...filterOptions];
  };

  const triggerCsvExport = (room: Room) => {
    generateAndDownloadCsv({
      headers: getActivityLogCsvHeader(),
      data: transformLogToCsvData(transformLogs(logsToCSV[room.positionId], timezone), {
        headers: getActivityLogCsvHeader(),
      }),
      fileName: `Activity log_${keyName}_${room.positionName}_${formatDate(filter.fromDate)}-${formatDate(
        filter.toDate
      )}`,
    });
  };

  const CsvExportButton = (buttonProps: CsvExportButtonProps) => {
    const { room, loading } = buttonProps;
    return (
      <div className="blocks d-flex justify-content-between">
        <div className="d-flex right">
          <CsvExportBtn
            disabled={loading}
            onExportTrigger={() => triggerCsvExport(room)}
            classNames="mini-csv-button-in-collapse"
          />
        </div>
      </div>
    );
  };

  const onRoomActivityLogChanged = (positionId: string, logsData: ActivityLog[]) => {
    setLoadingStates((prevStates) => {
      return prevStates?.map((state) => (state.positionId === positionId ? { ...state, loading: true } : state));
    });
    logsToCSV[positionId] = logsData;
    setLogsToCSV({ ...logsToCSV });
  };

  const onLoadingComplete = (positionId: string) => {
    setLoadingStates((prevStates) => {
      return prevStates?.map((state) => (state.positionId === positionId ? { ...state, loading: false } : state));
    });
  };

  return (
    <div className="mb-l">
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
          <div className="d-flex align-items-center flex-column py-m bg-white">
            <Row>
              <Col xs={{ span: 21 }} sm={{ span: 18 }}>
                <RangePicker
                  value={[filter.fromDate, filter.toDate]}
                  format={DATETIME_24H_FORMAT}
                  showTime
                  showSecond={false}
                  showNow
                  className="mb-m"
                  onChange={onDateFilterChanged}
                  data-testid="range-picker-testId"
                />
              </Col>
              <Col xs={{ span: 3 }} sm={{ span: 6 }}>
                <ListFilter
                  currentFilter={actionFilter}
                  onChange={handleFilterChange}
                  searchField=""
                  searchPlaceholder=""
                  filterOptions={getFilterOptions()}
                  hideSearchBar
                  waitApplyAll
                />
              </Col>
            </Row>
          </div>
          {rooms.length > 0 ? (
            <Collapse defaultActiveKey={rooms.map((room) => room.positionId)}>
              {rooms.map((room) => {
                const loadingState = _.find(loadingStates, { positionId: room.positionId })?.loading || false;
                return (
                  <Panel
                    header={room.positionName}
                    extra={<CsvExportButton room={room} loading={loadingState} />}
                    key={room.positionId}
                    collapsible="header"
                    className="no-padding-collapsible-tab"
                  >
                    <RoomComponent
                      room={room}
                      filter={filter}
                      actionFilter={actionFilter}
                      onLogsChange={(positionId, logsData) => onRoomActivityLogChanged(positionId, logsData)}
                      onLoadingComplete={(positionId) => onLoadingComplete(positionId)}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityLogPage;
