import React, { useMemo, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ActiveTab } from "pages/Location/LocationDetails/LocationDetails.d";
import { ContractorIcon, DoorExitIcon, HourglassIcon } from "components/Icons";
import GetHelpButton from "components/GetHelpButton";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { getInfraDetailLink, getKeyDetailLink } from "utils/link";
import SupportButton from "pages/KeyEntryJobChecklist/SupportButton";
import StopWatch from "components/InstallationMode/StopWatch";
import {
  useJobEntryDataSubscription,
  useKeyWithTaskDuedateSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import useLocationDateTime from "hooks/use-location-datetime";
import { TagStatus } from "pages/Jobs/JobCard/JobCard";
import classNames from "classnames";
import { PositionFunction } from "types/InstallationMode.d";
import { JobDetailHeaderProps } from "./JobDetailHeader.d";

const JobDetailHeader: React.FC<JobDetailHeaderProps> = ({
  keyName,
  keyId,
  isInInstallation,
  handleOnExit,
  installationStartTime,
  jobsToBeDone = 0,
  isCurrentKeyEntry,
  remainingKeyEntries,
}) => {
  const { formatDate, isOverdueDate } = useLocationDateTime();
  const { locationName, locationId } = useSensorflowLocation();
  const [assignee, setAssignee] = useState<string | undefined>();
  const location = useLocation<{ taskDueDate?: string }>();
  const [duedate, setDuedate] = useState<string | undefined>(location?.state?.taskDueDate);
  const [positionFunction, setPositionFunction] = useState<string>();
  const filterParams = useMemo(() => new URLSearchParams(location.search), [location]);

  const helpText = useMemo(() => {
    if (keyName && locationName && locationId && keyId) {
      if (positionFunction === PositionFunction.COMPRESSOR) {
        return `${locationName}: ${keyName} ${getInfraDetailLink(locationId, keyId)}`;
      }
      return `${locationName}: ${keyName} ${getKeyDetailLink(locationId, keyId)}`;
    }
  }, [keyName, locationName, locationId, keyId, positionFunction]);

  useKeyWithTaskDuedateSubscription({
    skip: !keyId,
    variables: { keyId: [keyId ?? ""] },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.sensorflow_v_key_task_min_duedate) {
        setDuedate(data.subscriptionData.data?.sensorflow_v_key_task_min_duedate[0].keyTaskDuedate);
      }
    },
  });

  const { data } = useJobEntryDataSubscription({
    variables: {
      where: {
        keyPositionId: {
          _eq: keyId,
        },
      },
    },
    onSubscriptionData: ({ subscriptionData: { data: subscriptionData } }) => {
      if (subscriptionData?.jobEntries?.[0]) {
        setAssignee(subscriptionData?.jobEntries?.[0].user?.name);
        setPositionFunction(subscriptionData?.jobEntries?.[0].positionFunction ?? PositionFunction.AUTOSET);
      } else {
        setAssignee(undefined);
      }
    },
  });

  const jobEntrie = useMemo(() => {
    return data?.jobEntries[0];
  }, [data]);

  return (
    <div className="mx-m">
      <Row className="mb-l" justify="space-between">
        {!isInInstallation && (
          <Col span={24} className="mb-l">
            <Link to={`/locations/${locationId}/${ActiveTab.JOBS}?${filterParams.toString()}`}>
              <Button type="link" className="pl-none">
                <ArrowLeftOutlined /> Back to Jobs
              </Button>
            </Link>
          </Col>
        )}
        <Col>
          <h1 className="lh-s">{keyName}</h1>
        </Col>
        <Col>
          {isInInstallation && (
            <>
              <Button type="primary" danger onClick={handleOnExit} disabled={!isCurrentKeyEntry}>
                <DoorExitIcon className="pr-xs" />
                Exit
              </Button>
              <GetHelpButton helpText={helpText} as={SupportButton} />
            </>
          )}
        </Col>
      </Row>
      {assignee && (
        <Row className="my-s fs-l">
          <Col span={12}>
            <p>Assignee</p>
          </Col>
          <Col className="w-100" span={12}>
            <p className="text-right w-100 text-truncate">
              <ContractorIcon className="mr-xs" />
              {assignee}
            </p>
          </Col>
        </Row>
      )}
      {duedate && (
        <Row className="my-s fs-l">
          <Col span={12}>
            <p>Due date</p>
          </Col>
          <Col className="w-100 text-right" span={12}>
            <Typography.Text
              className={classNames("text-truncate", {
                "text-dark-orange": isOverdueDate(duedate),
              })}
            >
              <HourglassIcon className="mr-xs" fill={isOverdueDate(duedate) ? "#e8472e" : "#a4a4a4"} />
              {formatDate(duedate)}
            </Typography.Text>
          </Col>
        </Row>
      )}
      {!!remainingKeyEntries && (
        <Row className="my-s fs-l">
          <Col span={22}>
            <p>Open jobs</p>
          </Col>
          <Col className="w-100" span={2}>
            <p className="text-right w-100">{remainingKeyEntries}</p>
          </Col>
        </Row>
      )}
      <Row className="my-s fs-l">
        <Col span={22}>
          <p>Jobs to be done</p>
        </Col>
        <Col className="w-100" span={2}>
          <p className="text-right w-100">{jobsToBeDone}</p>
        </Col>
      </Row>
      <Row className="my-s fs-l">
        <Col span={4}>
          <p>Status</p>
        </Col>
        <Col className="text-right" span={20}>
          <TagStatus
            status={jobEntrie?.status}
            statusEndedAt={jobEntrie?.endedAt}
            statusStartedAt={jobEntrie?.startedAt}
          />
        </Col>
      </Row>

      {installationStartTime && (
        <Row className="my-s fs-l" justify="space-between">
          <Col>
            <p>Time</p>
          </Col>
          <Col>
            <StopWatch startTime={installationStartTime} />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default JobDetailHeader;
