import { Button, Col, message, Row } from "antd";
import { InstallationModeButton } from "components/InstallationMode";
import errorHandler from "errorHandler";
import useIsMounted from "hooks/use-is-mounted";
import {
  GetWorkInfoQuery,
  Order_By,
  Sensorflow_V_Tasks_With_Keys_Bool_Exp,
  useCurrentKeyEntriesSubscription,
  useGetKeyIdsForGroupLazyQuery,
  useGetPositionNameQuery,
  useGetPositionQuery,
  useMarkKeyAsDndMutation,
  useTemporaryInfrastructureLazyQuery,
  TemporaryInfrastructureQuery,
  useWorkInfoSubscription,
  useGroupTasksLazyQuery,
  useGetInfrasByPositionMeterIdLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback, useMemo, useState } from "react";
import { KeyEntryStatus, PositionFunction } from "types/InstallationMode.d";
import { JobDetailHeader, bindingWorkInfoData } from "components/JobDetailHeader";
import { CommentModal } from "components/CommentModal";
import useInstallationMode from "hooks/use-installation-mode";
import JobChecklist from "./JobChecklist/JobChecklist";
import {
  Executor,
  KeyEntryJobChecklistProps,
  RoomList,
  TaskParentEntityType,
  TaskStatus,
} from "./KeyEntryJobChecklist.d";
import "./KeyEntryJobChecklist.scss";
import "./styles.scss";
import { JobCheckListProps } from "./JobChecklist";

const KeyEntryJobChecklist: React.FC<KeyEntryJobChecklistProps> = ({ keyId, locationId }) => {
  const [keyIds, setKeyIds] = useState<string[]>([]);
  const [keyName, setKeyName] = useState<string>();
  const [keyType, setKeyType] = useState<string>();
  const [roomList, setRoomList] = useState<RoomList>();
  const [jobsToBeDone, setJobsToBeDone] = useState<number>(0);
  const [tasksWithRoom, setTasksWithRoom] = useState<GetWorkInfoQuery["tasksWithKeys"]>();
  const [isCompressor, setIsCompressor] = useState(false);
  const [comment, setComment] = useState<string | undefined>();
  const [infraData, setInfraData] = useState<JobCheckListProps["infraData"]>({});
  const [remainingKeyEntries, setRemainingKeyEntries] = useState(0);

  const isMounted = useIsMounted();

  const { currentKeyEntryOfInstaller, currentKeyEntryOfPositions, handleExitKey } = useInstallationMode();

  const [getKeyIds] = useGetKeyIdsForGroupLazyQuery({
    onCompleted: (data) => {
      const keyIdInGroups = data?.positions?.map((position) => position.positionId) || [];
      setKeyIds(keyIdInGroups);
    },
    onError: errorHandler.handleError,
  });

  const [getInfrasByPositionMeterId] = useGetInfrasByPositionMeterIdLazyQuery({
    onCompleted: (data) => {
      if (data.infrastructures[0]) {
        const infrastructure = data.infrastructures[0] || {};
        setInfraData({
          infraId: infrastructure?.id,
          infraName: infrastructure?.name,
          infraType: infrastructure?.type,
          meterPositionId: keyId,
          nodeMacId: infrastructure.meterPosition?.slotMappings[0]?.nodeMacId,
          slotName: infrastructure.meterPosition?.slotMappings[0]?.slotName,
        } as JobCheckListProps["infraData"]);
      }
    },
  });

  const [getInfra] = useTemporaryInfrastructureLazyQuery({
    variables: { id: keyId },
    onCompleted: ({ infrastructures }: TemporaryInfrastructureQuery) => {
      if (infrastructures[0]) {
        const infrastructure = infrastructures[0] || {};
        setKeyName(infrastructure?.name || "");
        setIsCompressor(true);
        setInfraData({
          infraId: infrastructure?.id,
          infraName: infrastructure?.name,
          infraType: infrastructure?.type,
          meterPositionId: infrastructure?.meterPositionId,
          nodeMacId: infrastructure.meterPosition?.nodes[0]?.nodeMacId,
          slotName: infrastructure.meterPosition?.nodeSlots[0]?.slotName,
        } as JobCheckListProps["infraData"]);
      }
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const reloadInfra = () => {
    return isCompressor
      ? getInfra()
      : getInfrasByPositionMeterId({
          variables: {
            meterPositionId: keyId,
          },
        });
  };

  const [getGroup] = useGroupTasksLazyQuery({
    onCompleted: (data) => {
      const groupTask = data.sensorflow_v_group_tasks[0];
      setRemainingKeyEntries(groupTask?.openJobEntryCount || 0);
    },
  });

  useGetPositionQuery({
    variables: { positionId: keyId },
    onCompleted: (data) => {
      if (data?.position) {
        setKeyType(data?.position?.positionType || "");
        setKeyName(data?.position?.positionName);
        if (
          data?.position?.positionType === TaskParentEntityType.KEY ||
          data?.position?.positionType === TaskParentEntityType.COMPRESSOR
        ) {
          setKeyIds([keyId]);
          if (data?.position?.positionType === TaskParentEntityType.COMPRESSOR) {
            getInfrasByPositionMeterId({
              variables: {
                meterPositionId: keyId,
              },
            });
          }
        } else {
          getKeyIds({ variables: { groupId: keyId } });
          getGroup({
            variables: {
              where: { locationId: { _eq: locationId }, positionId: { _in: [keyId] } },
              limit: 1,
              offset: 0,
            },
          });
        }
      } else {
        setKeyIds([keyId]);
        getInfra();
      }
    },
    onError: errorHandler.handleError,
  });

  const isCurrentKeyEntry = useMemo(() => {
    return (
      currentKeyEntryOfInstaller?.keyPositionId === keyId &&
      currentKeyEntryOfInstaller.status !== KeyEntryStatus.DO_NOT_DISTURB
    );
  }, [currentKeyEntryOfInstaller, keyId]);

  const { installationStartTime, isInInstallation, keyEntry } = useMemo(() => {
    const innerKeyEntry = currentKeyEntryOfPositions?.find((k) => {
      return (
        k?.keyPositionId === keyId &&
        ![KeyEntryStatus.VISITED_REQUIRED, KeyEntryStatus.DO_NOT_DISTURB].includes(k?.status as KeyEntryStatus) &&
        k.startedAt
      );
    });

    return {
      isInInstallation: !!innerKeyEntry,
      installationStartTime: innerKeyEntry ? innerKeyEntry?.startedAt : null,
      keyEntry: innerKeyEntry,
    };
  }, [currentKeyEntryOfPositions, keyId]);

  const getTasksCondition: Sensorflow_V_Tasks_With_Keys_Bool_Exp = useMemo(() => {
    if (isInInstallation && keyEntry) {
      return {
        _or: [
          {
            status: { _eq: TaskStatus.PENDING },
          },
          {
            _and: [{ status: { _eq: TaskStatus.DONE } }, { keyEntryId: { _eq: keyEntry?.keyEntryId } }],
          },
        ],
      };
    }

    return {
      status: { _eq: TaskStatus.PENDING },
    };
  }, [isInInstallation, keyEntry]);

  const reloadBinding = useCallback(
    (data: GetWorkInfoQuery["tasksWithKeys"]) => {
      const { countJobsNotDone, workInfo } = bindingWorkInfoData(data);
      setJobsToBeDone(countJobsNotDone);
      setTasksWithRoom(workInfo);
    },
    [setJobsToBeDone, setTasksWithRoom]
  );

  const { loading: workInfoLoading } = useWorkInfoSubscription({
    variables: {
      where: {
        keyId: { _in: keyIds },
        executor: { _eq: Executor.CONTRACTOR },
        ...getTasksCondition,
      },
    },
    onSubscriptionData: (data) => {
      reloadBinding(data?.subscriptionData.data?.tasksWithKeys || []);
    },
  });

  useGetPositionNameQuery({
    variables: { keyId },
    onCompleted: ({ positions: data }) => setRoomList(data),
    onError: errorHandler.handleError,
  });

  const subscriptionConditions = useMemo(() => {
    if (isInInstallation) {
      return {
        keyPositionId: { _eq: keyId },
        startedAt: { _is_null: false },
        endedAt: { _is_null: false },
      };
    }
    return {
      keyPositionId: { _eq: keyId },
    };
  }, [isInInstallation, keyId]);

  useCurrentKeyEntriesSubscription({
    variables: {
      where: {
        ...subscriptionConditions,
      },
      limit: 1,
      orderBy: [{ startedAt: Order_By.Desc }],
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data?.keyEntries;
      if (data && data.length > 0) {
        setComment(data[0].comment ?? undefined);
      } else {
        setComment(undefined);
      }
    },
  });

  const handleOnExit = useCallback(async () => {
    await handleExitKey().catch((err) => errorHandler.handleError(err));
  }, [handleExitKey]);

  const [isDndConfirmPopupVisible, setIsDndConfirmPopupVisible] = useState<boolean>(false);

  const [markKeyAsDndMutationFn] = useMarkKeyAsDndMutation({
    onCompleted() {
      message.success("Key marked as DND");
      if (isMounted()) {
        setIsDndConfirmPopupVisible(false);
      }
    },
    onError(error) {
      errorHandler.handleError(error);
    },
  });

  const onMarkKeyAsDnd = useCallback(() => {
    setIsDndConfirmPopupVisible(true);
  }, []);

  const markKeyAsDnd = useCallback(
    (status?: string, commentMsg?: string, isMeterPosition?: boolean) => {
      markKeyAsDndMutationFn({
        variables: {
          keyPositionId: keyId,
          comment: commentMsg ?? "Marked as DND",
          isMeterPosition,
        },
      }).catch(() => {});
    },
    [markKeyAsDndMutationFn, keyId]
  );

  return (
    <div>
      <JobDetailHeader
        installationStartTime={installationStartTime}
        handleOnExit={handleOnExit}
        isInInstallation={isInInstallation}
        jobsToBeDone={jobsToBeDone}
        keyId={keyId}
        keyName={keyName}
        isCompressor={isCompressor}
        isCurrentKeyEntry={isCurrentKeyEntry}
        remainingKeyEntries={remainingKeyEntries}
      />

      <JobChecklist
        keyId={keyId}
        roomList={roomList}
        data={tasksWithRoom || []}
        splitIntoRooms={isInInstallation}
        reloadInfra={reloadInfra}
        isCompressor={isCompressor}
        infraData={infraData}
        keyType={keyType}
        workInfoLoading={workInfoLoading}
        isCurrentKeyEntry={isCurrentKeyEntry}
      />

      {!isInInstallation && (
        <>
          {comment && (
            <div className="mx-m mt-m">
              <h2 className="font-weight-bold text-primary mb-xs">Comment</h2>
              <p>{comment}</p>
            </div>
          )}
          {(keyType === TaskParentEntityType.KEY || keyType === TaskParentEntityType.COMPRESSOR || isCompressor) && (
            <Row className="job-details-btn-parent p-s" justify="space-around">
              <Col span={11}>
                <Button className="w-100" type="primary" danger onClick={onMarkKeyAsDnd}>
                  Do not disturb
                </Button>
              </Col>
              <Col span={11}>
                <InstallationModeButton
                  keyPositionId={keyId}
                  currentPositionFunction={
                    isCompressor || keyType === TaskParentEntityType.COMPRESSOR
                      ? PositionFunction.COMPRESSOR
                      : PositionFunction.AUTOSET
                  }
                  buttonName={{ enter: "Enter", exit: "End Work" }}
                  className="w-100"
                  classNameButton="w-100"
                  automaticTestRun={false}
                  isMeterPosition={!isCompressor && keyType === TaskParentEntityType.COMPRESSOR}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <CommentModal
        title="Do not disturb"
        customDescription="Leave a comment before marking this key as DND"
        keyName={keyName}
        onConfirm={markKeyAsDnd}
        openCommentModal={isDndConfirmPopupVisible}
        setOpenCommentModal={setIsDndConfirmPopupVisible}
        isMeterPosition={!isCompressor && keyType === TaskParentEntityType.COMPRESSOR}
      />
    </div>
  );
};

export default KeyEntryJobChecklist;
