/* eslint-disable prefer-promise-reject-errors */
import { Button, Form, Modal } from "antd";
import NodeMacInput from "pages/InfrastructureDetails/MappedNodes/MapNodeModal/NodeMacInput";
import React from "react";
import { FormData } from "./UnmapModal.d";
import "./NodeMacScan.scss";

function NodeMacScan({ handleCannotScan, scannedMacIdSubmit, currentNodeMacId, infraName }: any) {
  const [form] = Form.useForm<FormData>();

  const handleSubmitWhenValidData = () => {
    const { nodeMacId } = form.getFieldsValue();
    if (currentNodeMacId === nodeMacId) {
      scannedMacIdSubmit(nodeMacId);
    } else {
      const closeModal = Modal.destroyAll();
      Modal.confirm({
        title: "Node ID doesn't match",
        content: `Are you in ${infraName}?`,
        className: "confirm-modal",
        okText: "No",
        cancelText: "Yes",
        onCancel: () => {
          scannedMacIdSubmit(nodeMacId);
        },
        onOk: () => closeModal,
      });
    }
  };

  return (
    <>
      <Form
        className="node-input"
        form={form}
        layout="vertical"
        initialValues={{ nodeMacId: "" }}
        onFinish={handleSubmitWhenValidData}
      >
        <p className="current-room-text" data-testid="center-room-testId">
          Scan to confirm
        </p>
        <Form.Item name="nodeMacId" rules={[{ required: true, message: "Node Mac Id is required" }]}>
          <NodeMacInput
            setNodeMacInput={(nodeMacId) => form.setFieldsValue({ nodeMacId })}
            autoFocus
            needTrimFirstTwoDigit
            handleSubmitWhenValidData={handleSubmitWhenValidData}
            clearForm={false}
          />
        </Form.Item>
      </Form>
      <Button onClick={handleCannotScan}>Can&apos;t scan</Button>
    </>
  );
}

export default NodeMacScan;
