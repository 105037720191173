import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Progress, Tag, Tooltip } from "antd";
import classNames from "classnames";
import ObjectHealth from "components/ObjectHealth";
import Table from "components/Table/Table";
import useBreakpoint from "hooks/use-breakpoint";
import useRoleAndPermission from "hooks/use-role-and-permission";
import get from "lodash/get";
import { Permission } from "pacts/permission";
import { tableRenderLoadingRow } from "pages/Key/KeyList/KeyList.util";
import React from "react";
import { Link } from "react-router-dom";
import { MAPPING_STATUS_UI_CONFIG } from "utils/constants";
import { LocationsWithNodeHealthAndTasks } from "../LocationList.d";
import { LocationListContractor } from "./LocationListContractor";
import LocationListGrid from "./LocationListGrid";

const sortOrder = (value: any) => get({ ASC: "ascend", DESC: "descend" }, value, "");

const LocationListTable = (props: any) => {
  const { sort, sortBy, onEditClick, onEditShowEnergyConsumptionClick } = props;
  const screen = useBreakpoint();
  const roleAndPermission = useRoleAndPermission();

  let COLUMN_CONFIG = [
    {
      title: "Status",
      render: (keysWithNodeHealth: LocationsWithNodeHealthAndTasks, _: any, index: number) => {
        if (!keysWithNodeHealth?.locationStats) return tableRenderLoadingRow(index, 10);
        const uiConfig = MAPPING_STATUS_UI_CONFIG[keysWithNodeHealth?.locationStats?.mappingStatus];
        if (!uiConfig) return null;
        return <Tag color={uiConfig[1]}>{uiConfig[0]}</Tag>;
      },
      onHeaderCell: () => ({
        onClick: () => {
          sortBy("mappingStatus");
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort.mappingStatus),
      isVisible: () => {
        return roleAndPermission.canPerform(Permission.LOCATION_VIEW);
      },
      showOnMobile: false,
    },
    {
      title: "Location Name",
      onHeaderCell: () => ({
        onClick: () => {
          sortBy("locationName");
        },
      }),
      render: (record: LocationsWithNodeHealthAndTasks) => {
        if (roleAndPermission.canPerform(Permission.LOCATION_CREATE))
          return <Link to={`locations/${record.id}/keys`}>{record.locationName}</Link>;
        return <span>{record.locationName}</span>;
      },
      sorter: true,
      defaultSortOrder: sortOrder(sort.locationName),
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: false,
    },
    {
      title: "Keys",
      width: 200,
      render: (keysWithNodeHealth: LocationsWithNodeHealthAndTasks, _: any, index: number) => {
        if (!keysWithNodeHealth?.locationStats) return tableRenderLoadingRow(index, 10);
        const mappedCount = keysWithNodeHealth?.locationStats?.keysStats?.mappedCount || 0;
        const totalCount = keysWithNodeHealth?.locationStats?.keysStats?.totalCount || 0;

        const percentage = totalCount === 0 ? 0 : (100 * mappedCount) / totalCount;

        return (
          <Progress percent={percentage} size="small" format={() => `${mappedCount}/${totalCount}`} status="normal" />
        );
      },
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: false,
    },
    {
      title: "Gateways",
      width: 200,
      render: (keysWithNodeHealth: LocationsWithNodeHealthAndTasks, _: any, index: number) => {
        if (!keysWithNodeHealth?.locationStats) return tableRenderLoadingRow(index, 10);
        const mappedCount = get(keysWithNodeHealth?.locationStats, "gatewaysStats.mappedCount", 0);
        const totalCount = get(keysWithNodeHealth?.locationStats, "gatewaysStats.totalCount", 0);
        const percentage = totalCount === 0 ? 0 : (100 * mappedCount) / totalCount;

        return (
          <Progress percent={percentage} size="small" format={() => `${mappedCount}/${totalCount}`} status="normal" />
        );
      },
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: false,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_CREATE),
      showOnMobile: false,
    },
    {
      title: "Location Health",
      width: 150,
      render: (keysWithNodeHealth: LocationsWithNodeHealthAndTasks, _: any, index: number) => {
        if (!keysWithNodeHealth?.locationStats) return tableRenderLoadingRow(index, 10);
        const nodeHealthStats = keysWithNodeHealth?.locationStats?.nodeHealthStats || null;
        return (
          <ObjectHealth
            online={nodeHealthStats?.onlineCount ?? 0}
            offline={nodeHealthStats?.offlineCount ?? 0}
            booted={nodeHealthStats?.bootedCount ?? 0}
          />
        );
      },
      isVisible: () => roleAndPermission.isInstaller(),
      showOnMobile: false,
    },
    {
      title: "Actions",
      render: (record: LocationsWithNodeHealthAndTasks) => <Link to={`/locations/${record.id}/keys`}>Keys</Link>,
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: false,
    },
    {
      title: "",
      render: (record: LocationsWithNodeHealthAndTasks) => (
        <Link to={`/locations/${record.id}/spare-nodes`}>Spare nodes</Link>
      ),
      isVisible: () => roleAndPermission.isWS(),
      showOnMobile: false,
    },
    {
      title: "",
      render: (record: LocationsWithNodeHealthAndTasks) => (
        <Link to={`/locations/${record.id}/gateways`}>Gateways</Link>
      ),
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: false,
    },
    {
      title: "",
      render: (record: LocationsWithNodeHealthAndTasks) => (
        <Link to={`/locations/${record.id}/infrastructures`}>Infrastructures</Link>
      ),
      isVisible: () => roleAndPermission.canPerform(Permission.INFRASTRUCTURE_VIEW_ALL),
      showOnMobile: false,
    },
    {
      title: "",
      render: (record: LocationsWithNodeHealthAndTasks) => <Link to={`/locations/${record.id}/nodes`}>Nodes</Link>,
      isVisible: () => roleAndPermission.canPerform(Permission.NODES_LIST) && !roleAndPermission.isContractor(),
      showOnMobile: false,
    },
    {
      title: "",
      width: 30,
      render: (record: LocationsWithNodeHealthAndTasks) => (
        <div className="d-flex">
          <Button
            type="link"
            icon={
              <Tooltip title="Location settings">
                <SettingOutlined data-testid="location-settings-testId" />
              </Tooltip>
            }
            onClick={() => onEditShowEnergyConsumptionClick(record)}
          />
          <Button
            type="link"
            icon={<EditOutlined data-testid="edit-icon-testId" />}
            onClick={() => onEditClick(record)}
          />
        </div>
      ),
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_EDIT),
      showOnMobile: false,
    },
    {
      title: "",
      width: "100%",
      render: (record: LocationsWithNodeHealthAndTasks) =>
        roleAndPermission.isContractor() ? <LocationListContractor {...record} /> : <LocationListGrid {...record} />,
      isVisible: () => roleAndPermission.canPerform(Permission.LOCATION_VIEW),
      showOnMobile: true,
      className: classNames({
        "p-none": roleAndPermission.isContractor(),
        "radius-xs": roleAndPermission.isContractor(),
      }),
    },
  ];

  COLUMN_CONFIG = COLUMN_CONFIG.filter(
    (column) => column.isVisible() && (screen.desktopUp ? !column.showOnMobile : column.showOnMobile)
  );

  return (
    <Table
      {...props}
      columns={COLUMN_CONFIG}
      mobileView={screen.mobileAndTabletOnly}
      dataItemName="locations"
      rowKey="id"
    />
  );
};

export default LocationListTable;
