import React, { useMemo } from "react";

import { Button, Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import errorHandler from "errorHandler";
import { useGetRmaRootCausesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
// eslint-disable-next-line import/no-cycle
import { displayNodeMacId } from "../ContractorNodeComponent";
import "./UnmappedNode.css";

type SelectingUnmapNodeReason = {
  rootCauseId: number;
  comment: string;
};

const SelectingReason = ({ currentNodeMacId, slotName, reasonSubmit, isUnmapping }: any) => {
  const [form] = Form.useForm<SelectingUnmapNodeReason>();

  const { data: { sf_support_root_causes: rootCauses = [] } = {}, loading } = useGetRmaRootCausesQuery({
    onError: errorHandler.handleError,
  });

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    reasonSubmit(values.rootCauseId, values.comment);
  };

  const initialValue = useMemo(() => {
    return {
      rootCauseId: rootCauses.find((r) => r.root_cause.trim().toLocaleLowerCase() === "C4 Rework".toLocaleLowerCase())
        ?.id,
    };
  }, [rootCauses]);

  return (
    <>
      <p className="current-room-text" data-testid="center-room-testId">
        {slotName}
      </p>
      <p>{displayNodeMacId(currentNodeMacId)}</p>
      <div className="scan-node-wrapper">
        {!loading ? (
          <Form form={form} initialValues={initialValue}>
            <Form.Item
              name="rootCauseId"
              label="Root Cause"
              rules={[{ required: true, message: "Root Cause Id is required" }]}
            >
              <Select>
                {rootCauses.map((value) => (
                  <Select.Option key={value.id} value={value.id}>
                    {value.root_cause}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="comment" label="Comment">
              <TextArea />
            </Form.Item>
            <Button type="primary" data-testId="unmapButton" onClick={handleSubmit} disabled={isUnmapping}>
              Unmap
            </Button>
          </Form>
        ) : (
          <p>...Loading</p>
        )}
      </div>
    </>
  );
};

export default SelectingReason;
