import React, { useCallback } from "react";
import { Col, Row } from "antd";
import { FilterOptionType } from "pacts/app-webcore/hasura-webcore.graphql";
import { FilterOption } from "types/types";
import { useHistory, useLocation } from "react-router-dom";
import SearchBar from "components/SearchBar/SearchBar";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { FilterV2 } from "components/FilterV2";
import { ListFilterProps } from "./ListFilter.d";

const ListFilter = ({
  currentFilter,
  onChange,
  searchField,
  searchPlaceholder,
  filterOptions,
  hideSearchBarAndFilter,
  hideAdvancedFilter,
  hideSearchBar,
  containerClassName,
  waitApplyAll,
  isSaveKeySearch,
  defaultKeySearch,
  filterType,
  locationId,
}: ListFilterProps) => {
  const history = useHistory();
  const location = useLocation();

  const handlePartialChange = useCallback(
    (partialFilter: Record<string, any>) => {
      onChange?.({
        ...currentFilter,
        ...partialFilter,
      });
    },
    [onChange, currentFilter]
  );

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...currentFilter,
        [field]: value,
      });
    }
    if (isSaveKeySearch) {
      const params = new URLSearchParams({ [field]: value });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  };

  const handleSearchChange = (value: string) => {
    handleChange(searchField, value);
  };

  const handleFilterOptionChange = (option: FilterOption, value: any, key: string = "") => {
    if (option.type === FilterOptionType.Checkbox) {
      let currentOptions = currentFilter ? currentFilter[option.field] || [] : [];
      if (value) {
        currentOptions = currentOptions.concat(option.value);
      } else {
        currentOptions = currentOptions.filter((optionValue: any) => optionValue !== option.value);
      }
      handleChange(option.field, currentOptions);
    } else if (option.type === FilterOptionType.Select || option.type === FilterOptionType.TimePicker) {
      handleChange(option.field, value);
    } else {
      let currentOptions = currentFilter ? currentFilter[option.field] || {} : {};
      currentOptions = {
        ...currentOptions,
        [key]: value,
      };
      handleChange(option.field, currentOptions);
    }
  };

  const customFilter = () => {
    if (filterType) {
      return (
        <FilterV2
          filterOptions={filterOptions ?? []}
          currentFilter={currentFilter}
          onFilterChange={handlePartialChange}
          locationId={locationId}
        />
      );
    }
    return (
      <FilterDropdown
        filterOptions={filterOptions ?? []}
        onOptionChange={handleFilterOptionChange}
        waitApplyAll={waitApplyAll}
        currentFilter={currentFilter}
        onFilterChange={onChange}
      />
    );
  };

  return (
    <Row className={containerClassName} gutter={filterType === "FilterV2" ? [16, 16] : undefined}>
      {!hideSearchBarAndFilter && !hideSearchBar && (
        <Col flex={1}>
          <SearchBar
            onInputChange={handleSearchChange}
            placeholder={searchPlaceholder}
            defaultValue={defaultKeySearch}
          />
        </Col>
      )}
      {!hideSearchBarAndFilter && !hideAdvancedFilter && <Col>{customFilter()}</Col>}
    </Row>
  );
};

export default ListFilter;
