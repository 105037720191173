import { Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import NodeMacInput from "pages/InfrastructureDetails/MappedNodes/MapNodeModal/NodeMacInput";
import { NodeSubType, NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { omit } from "lodash";
import { CreateSpareNodeProps, NodeSubTypeOption } from "./CreateSpareNode.d";

const nodeTypes = omit(NodeType, "Undefined", "FlowMeter", "PipeTemp");

export const nodeTypeCodeIdentifierMapping = new Map([
  ["00", NodeType.Energy],
  ["01", NodeType.Aircon],
  ["03", NodeType.Occupancy],
  ["05", NodeType.PipeTemp],
  ["06", NodeType.FlowMeter],
  ["07", NodeType.Door],
]);

const nodeSubTypeOptionAircon = [
  { value: NodeSubType.Aircon_2pfc, label: getNodeTypeDisplayValue(NodeSubType.Aircon_2pfc) },
  { value: NodeSubType.Aircon_4pfc, label: getNodeTypeDisplayValue(NodeSubType.Aircon_4pfc) },
  { value: NodeSubType.AirconAcir, label: getNodeTypeDisplayValue(NodeSubType.AirconAcir) },
  { value: NodeSubType.AirconDaikin, label: getNodeTypeDisplayValue(NodeSubType.AirconDaikin) },
];

const nodeSubTypeOptionDoor = [
  { value: NodeSubType.DoorLaser, label: getNodeTypeDisplayValue(NodeSubType.DoorLaser) },
  { value: NodeSubType.DoorMagnetic, label: getNodeTypeDisplayValue(NodeSubType.DoorMagnetic) },
];

const nodeSubTypeOptionOccupancy = [
  { value: NodeSubType.Occupancy, label: getNodeTypeDisplayValue(NodeSubType.Occupancy) },
];

const nodeSubTypeOptionEnergy = [
  { value: NodeSubType.EnergySwitchless, label: getNodeTypeDisplayValue(NodeSubType.EnergySwitchless) },
];

const nodeSubTypeOptionPipeTemp = [
  { value: NodeSubType.PipeTemp, label: getNodeTypeDisplayValue(NodeSubType.PipeTemp) },
];

const nodeSubTypeOptionFlowMeter = [
  { value: NodeSubType.FlowMeter, label: getNodeTypeDisplayValue(NodeSubType.FlowMeter) },
];

const CreateSpareNode = (props: CreateSpareNodeProps) => {
  const { closeModal, formData, handleOnSubmit } = props;
  const [isInvalidInputData, setInvalidInputData] = useState<boolean>();
  const [nodeTypeCode, setNodeTypeCode] = useState<string>();
  const [nodeSubTypes, setNodeSubTypes] = useState<NodeSubTypeOption[]>();
  const [nodeType, setNodeType] = useState<NodeType>();
  const [nodeMacInputActiveKey, setNodeMacInputActiveKey] = useState<string>("");
  const [form] = Form.useForm<CreateSpareNodeProps["formData"]>();

  const onSubmitForm = () => {
    handleOnSubmit(form.getFieldsValue(), nodeTypeCode as string);
  };

  useEffect(() => {
    if (nodeTypeCode) {
      const type = nodeTypeCodeIdentifierMapping.get(nodeTypeCode);
      form.setFieldsValue({ nodeType: type });
      setNodeType(type);
    }
  }, [nodeTypeCode, form]);

  useEffect(() => {
    switch (nodeType) {
      case NodeType.Aircon:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionAircon[0].value });
        setNodeSubTypes(nodeSubTypeOptionAircon);
        break;
      case NodeType.Energy:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionEnergy[0].value });
        setNodeSubTypes(nodeSubTypeOptionEnergy);
        break;
      case NodeType.Door:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionDoor[0].value });
        setNodeSubTypes(nodeSubTypeOptionDoor);
        break;
      case NodeType.PipeTemp:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionPipeTemp[0].value });
        setNodeSubTypes(nodeSubTypeOptionPipeTemp);
        break;
      case NodeType.FlowMeter:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionFlowMeter[0].value });
        setNodeSubTypes(nodeSubTypeOptionFlowMeter);
        break;
      case NodeType.Occupancy:
        form.setFieldsValue({ nodeSubType: nodeSubTypeOptionOccupancy[0].value });
        setNodeSubTypes(nodeSubTypeOptionOccupancy);
        break;
      default:
        setNodeSubTypes([]);
        break;
    }
  }, [nodeType, form]);

  return (
    <Modal
      title="Add spare node"
      centered
      visible
      width={600}
      onCancel={closeModal}
      onOk={form.submit}
      okText="Submit"
      okButtonProps={{
        disabled: isInvalidInputData,
      }}
    >
      <Form form={form} layout="vertical" initialValues={formData} onFinish={onSubmitForm}>
        <Form.Item
          name="nodeMacId"
          rules={[{ required: true, message: "Node Mac Id is required" }]}
          className="input-node-mac-id"
        >
          <NodeMacInput
            setNodeMacInput={(nodeMacId) => form.setFieldsValue({ nodeMacId })}
            defaultActiveKey="2"
            autoFocus
            validateNodeMacWithFullLen
            setNodeTypeCode={setNodeTypeCode}
            setInvalidInputData={setInvalidInputData}
            setNodeMacInputActiveKey={setNodeMacInputActiveKey}
          />
        </Form.Item>

        <Form.Item name="nodeType" label="Node type" rules={[{ required: true, message: "Node type is required" }]}>
          <Select
            defaultValue={nodeTypeCodeIdentifierMapping.get(nodeTypeCode || "")}
            disabled={nodeMacInputActiveKey === "1"}
            onSelect={setNodeType}
          >
            {Object.keys(nodeTypes).map((x) => (
              <Select.Option key={x} value={nodeTypes[x as keyof typeof nodeTypes]}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="nodeSubType"
          label="Node sub type"
          rules={[{ required: true, message: "Node sub type is required" }]}
        >
          <Select>
            {nodeSubTypes?.map((nodeSubType) => {
              return (
                <Select.Option value={nodeSubType.value} key={nodeSubType.label}>
                  {nodeSubType.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name="locationId" label="Location id" hidden>
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSpareNode;
