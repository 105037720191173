import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

// eslint-disable-next-line import/prefer-default-export
export const transformToInfrastructuresQueryVariables = (
  isContractor: boolean,
  locationId: string,
  currentFilter?: any
): any => {
  const nodeStatus = get(currentFilter, "node[0]");
  const infraName = get(currentFilter, "infraName");
  const types = get(currentFilter, "type");

  const nodeStatusFilter = !isEmpty(nodeStatus) && { nodeJoinStatuses: { status: { _eq: nodeStatus } } };

  const infraNameOptions: any = !isEmpty(infraName) && [{ name: { _ilike: `%${infraName}%` } }];

  if (infraNameOptions) {
    infraNameOptions.push({
      meterPosition: {
        slotMappings: {
          nodeMacId: { _like: `%${infraName}%` },
          decomissionedTime: { _is_null: true },
        },
      },
    });
  }
  const infraNameFilter = !isEmpty(infraName) && {
    _or: infraNameOptions,
  };

  const typesFilter = !isEmpty(types) && { type: { _in: types } };

  return {
    position: {
      locationId: { _eq: locationId },
      ...nodeStatusFilter,
    },
    ...infraNameFilter,
    ...typesFilter,
  };
};
