import React, { useEffect, useMemo, useState } from "react";
import { Col, message, Row } from "antd";
import isEqual from "lodash/isEqual";
import {
  FilterOption,
  FilterOptionType,
  NodeType,
  Order_By,
  TemporaryNodeListQuery,
  useTemporaryNodeListAggregateQuery,
  useTemporaryNodeListLazyQuery,
  useTemporaryNodeListQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import ListFilter from "components/ListFilter/ListFilter";
import { AntdTablePagination } from "components/Table/Table.d";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import { getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { CsvExportJobName, MAX_POSSIBLE_ROW_CNT } from "utils/constants";
import { CsvExportBtn } from "components/CsvExport/CsvExportBtn";
import errorHandler from "errorHandler";
import { useLazyQueryTrigger } from "hooks/use-lazy-query-trigger";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import useBreakpoint from "hooks/use-breakpoint";
import useLocationDateTime from "hooks/use-location-datetime";
import { generateAndDownloadCsv } from "utils/file";
import NodeListTable from "../NodeListTable/NodeListTable";
import { getNodeListWhereFromFilter, getOrderByFromSort, Node, NodeFilterInput } from "./NodeList.d";
import { getNodeListCsvHeader, transformNodes, transformNodeToCsvData } from "./NodeList.helpers";

const filterOptions: FilterOption[] = [
  {
    field: "statuses",
    value: "online",
    label: "Online",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE HEALTH",
  },
  {
    field: "statuses",
    value: "offline",
    label: "Offline",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE HEALTH",
  },
  {
    field: "signalStrength",
    value: "signalStrength",
    label: "Signal Strength",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE HEALTH",
    type: FilterOptionType.FloorCeilingValue,
  },
  {
    field: "nodeJoin",
    value: "nodeJoin",
    label: "Node Join",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE HEALTH",
    type: FilterOptionType.FloorCeilingValue,
  },
  {
    field: "firmwareVersion",
    value: "firmwareVersion",
    label: "FW Version",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE HEALTH",
    type: FilterOptionType.InputValue,
  },
];

const getFilterOptions = (): FilterOption[] => {
  const nodeTypeFilterOptions = Object.entries(NodeType).map(([key, value]) => ({
    field: "nodeTypes",
    value,
    label: getNodeTypeDisplayValue(key),
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "NODE TYPE",
    type: FilterOptionType.Checkbox,
  }));
  return [...filterOptions, ...nodeTypeFilterOptions];
};

const NodeList = () => {
  const screen = useBreakpoint();
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [filter, setFilter] = useState<NodeFilterInput>({ nodeMacId: "" });
  const { locationId, locationName } = useSensorflowLocation();
  const locationDateTimeUtils = useLocationDateTime();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [sort, setSort] = useState<any>({});

  const handleSortChange = (columnHeader: string) => {
    setSort({ [columnHeader]: sort[columnHeader] === Order_By.Asc ? Order_By.Desc : Order_By.Asc });
  };

  const where = getNodeListWhereFromFilter(locationId || "", filter);

  const { loading, error } = useTemporaryNodeListQuery({
    variables: {
      where,
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
      order_by: [getOrderByFromSort(sort)],
    },
    onCompleted(data: TemporaryNodeListQuery) {
      const newNodes = transformNodes(data.sensorflow_node_to_slot_mapping);
      if (!isEqual(newNodes, nodes)) {
        setNodes(newNodes);
      }
    },
    onError: errorHandler.handleError,
  });

  useTemporaryNodeListAggregateQuery({
    variables: {
      where,
    },
    onCompleted(data) {
      const nodeCount = data.sensorflow_node_to_slot_mapping_aggregate.aggregate?.count || 0;

      if (nodeCount !== pagination.total) {
        setPagination((currentPagination) => ({
          ...currentPagination,
          total: data.sensorflow_node_to_slot_mapping_aggregate.aggregate?.count || 0,
        }));
      }
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const statMessage = useMemo(() => {
    if (error) return "Error on loading nodes.";

    if (nodes) {
      const { current, pageSize, total } = pagination;
      const start = (current - 1) * pageSize + 1;
      const end = Math.min(total, start + pageSize - 1);

      return `Showing ${start}-${end} of ${total} nodes`;
    }

    return "";
  }, [nodes, error, pagination]);

  // export csv
  const nodeListCsvHeaders = useMemo(() => getNodeListCsvHeader(locationDateTimeUtils), [locationDateTimeUtils]);
  const { triggerFn: fetchAllNodes, loading: isFetchingAllNodes } = useLazyQueryTrigger(useTemporaryNodeListLazyQuery, {
    variables: {
      where,
      offset: 0,
      limit: MAX_POSSIBLE_ROW_CNT,
      order_by: [getOrderByFromSort(sort)],
    },
    onCompleted: (data: TemporaryNodeListQuery) => {
      generateAndDownloadCsv({
        headers: nodeListCsvHeaders,
        data: transformNodeToCsvData(transformNodes(data.sensorflow_node_to_slot_mapping), {
          headers: nodeListCsvHeaders,
        }),
        fileName: `${locationName}-${CsvExportJobName.NODE_LIST}`,
      });
    },
    onError: errorHandler.handleError,
  });

  const triggerCsvExport = React.useCallback(() => {
    return fetchAllNodes();
  }, [fetchAllNodes]);

  const handleTableChange = (page: any) => {
    setPagination({
      current: page.current,
      pageSize: page.pageSize,
      total: pagination.total,
    });
  };

  const handleFilterChange = (newFilter: NodeFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  return (
    <>
      <Row justify="space-between" className="mb-m">
        <Col>
          <h2 className="d-inline mr-l">Nodes</h2>
          <p className="d-inline">{statMessage}</p>
        </Col>
      </Row>
      <Row className="mb-l">
        <Col flex="auto">
          <ListFilter
            currentFilter={filter}
            onChange={handleFilterChange}
            searchField="nodeMacId"
            searchPlaceholder="Type to search"
            filterOptions={getFilterOptions()}
          />
        </Col>
        {screen.desktopUp && (
          <Col>
            <CsvExportBtn
              onExportTrigger={triggerCsvExport}
              disabled={loading || !pagination.total || isFetchingAllNodes}
            />
          </Col>
        )}
      </Row>
      <Row>
        <NodeListTable
          tableData={nodes}
          handleTableChange={handleTableChange}
          loading={loading}
          error={error}
          sortBy={handleSortChange}
          sort={sort}
          pagination={{
            ...pagination,
            pageSizeOption: ["10", "20", "50", "100"],
            showSizeChanger: true,
          }}
        />
      </Row>
    </>
  );
};

export default NodeList;
