import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Input, Modal } from "antd";
import isEqual from "lodash/isEqual";
import { ModalWrapperProps } from "components/Modal/Modal.d";

interface ConfirmToAddCompressorModalProps {
  amount: number;
  onConfirmedCallback: () => void;
}

const ConfirmToAddCompressorModal = ({
  amount,
  onConfirmedCallback,
  modalWrapperProps,
  closeModal,
}: ConfirmToAddCompressorModalProps & ModalWrapperProps) => {
  const [confirmByText, setConfirmByText] = useState<number>();

  const isConfirmedByText = isEqual(confirmByText, amount);

  const renderTitle = () => (
    <h3 className="font-weight-bold">
      <ExclamationCircleOutlined className="text-warning mr-l" />
      Add 1:1 Compressor
    </h3>
  );

  const handleConfirmByText = (e: any) => {
    setConfirmByText(parseInt(e.target.value));
  };

  const handleSubmit = () => {
    closeModal();
    onConfirmedCallback();
  };

  return (
    <Modal
      title={renderTitle()}
      centered
      onOk={handleSubmit}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !isConfirmedByText,
      }}
      {...modalWrapperProps}
    >
      <h4>This will create {amount} compressor(s) based on the amount of Autoset selected</h4>
      <h4>Do you wish to proceed?</h4>

      <div className="mt-m">
        <span className="text-gray">Key in the amount to confirm</span>
        <Input placeholder="Enter amount" onChange={handleConfirmByText} />
      </div>
    </Modal>
  );
};

export default ConfirmToAddCompressorModal;
