import { Sensorflow_V_Comfortplus_Precool_Status } from "pacts/app-webcore/hasura-webcore.graphql";

type PrecoolStatuses = Pick<
  Sensorflow_V_Comfortplus_Precool_Status,
  "positionName" | "totalMinutes" | "startedAt" | "failMessage" | "status" | "mode"
>[];

type OnDemandPrecoolRoomsProps = {
  precoolingRooms: PrecoolStatuses;
};

const precoolModes: any[] = [
  {
    label: "Auto",
    value: 0,
  },
  {
    label: "Cooling",
    value: 1,
  },
  {
    label: "Drying",
    value: 2,
  },
  {
    label: "Fanning",
    value: 3,
  },
  {
    label: "Heating",
    value: 4,
  },
];

export { PrecoolStatuses, OnDemandPrecoolRoomsProps, precoolModes };
