import set from "lodash/set";
import get from "lodash/get";
import { Tooltip } from "antd";
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import { isEmpty } from "lodash";
import {
  NodeListForOtaQuery,
  Sensorflow_Node_To_Slot_Mapping_Bool_Exp,
} from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import { Node, NodeFilterInput, NodeStatus, UpgradeStatus } from "./OtaNodeList.d";

export const getNodeListWhereFromFilter = (
  filter: NodeFilterInput,
  lockable: boolean = false
): Sensorflow_Node_To_Slot_Mapping_Bool_Exp => {
  const {
    nodeTypes,
    statuses,
    locations,
    upgradeStatuses,
    nodeSubType,
    firmwareVersionExcludeLocked,
    targetFwVersionSpecificVersion,
    currentFwVersionSpecificVersion,
    targetFwVersion,
    currentFwVersion,
  } = filter;

  const where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp = {
    node: {
      nodeFirmware: {},
    },
    position: {},
    decomissionedTime: {
      _is_null: true,
    },
  };

  if (lockable) {
    where.node!.nodeFirmware = {
      lockable: { _eq: true },
    };
  }

  if (filter.nodeMacId) {
    const ilikeQuery = `%${filter.nodeMacId}%`;
    where._or = [
      {
        nodeMacId: {
          _ilike: ilikeQuery,
        },
      },
      {
        position: {
          positionName: {
            _ilike: ilikeQuery,
          },
        },
      },
      {
        position: {
          parentPosition: {
            positionName: {
              _ilike: ilikeQuery,
            },
          },
        },
      },
    ];
  }

  if (nodeTypes?.length) {
    where.nodeType = {
      _in: nodeTypes,
    };
  }

  if (statuses?.length) {
    where.node!.nodeOnlineStatus = {
      nodeStatus: {
        _in: statuses.map((status) => status.toUpperCase()),
      },
    };
  }

  if (locations?.length) {
    where.position!.location = {
      locationId: {
        _in: locations,
      },
    };
  }

  if (nodeSubType) {
    where.node!.nodeSubType = {
      _eq: nodeSubType,
    };
  }

  if (upgradeStatuses?.length) {
    where.node!._or = [
      {
        nodeFirmware: {
          upgradeStatus: {
            _in: upgradeStatuses,
          },
        },
      },
    ];
    if (upgradeStatuses.includes(UpgradeStatus.UNKNOWN)) {
      where.node!._or.push({
        _not: {
          nodeFirmware: {},
        },
      });
    }
  }

  if (firmwareVersionExcludeLocked) {
    where.node!.firmwareVersionLocked = {
      _eq: false,
    };
  }

  if (targetFwVersionSpecificVersion && targetFwVersion) {
    where.node!.targetFirmwareRelease = {
      versionNumber: {
        _eq: targetFwVersion,
      },
    };
  }

  if (currentFwVersionSpecificVersion && currentFwVersion) {
    where.node!.currentFirmwareRelease = {
      versionNumber: {
        _eq: currentFwVersion,
      },
    };
  }

  if (where.node && isEmpty(where.node.nodeFirmware)) {
    delete where.node.nodeFirmware;

    if (isEmpty(where.node)) {
      delete where.node;
    }
  }

  if (isEmpty(where.position)) {
    delete where.position;
  }

  return where;
};

export const getOrderByFromSort = (sort: any) => {
  const obj = {};
  const sortKey = Object.keys(sort)[0];
  if (sortKey) {
    set(obj, sortKey, sort[sortKey]);
  }
  return obj;
};

export const transform = (nodes: NodeListForOtaQuery["nodes"]): Node[] => {
  return nodes.map(
    (nodeMapping) =>
      ({
        nodeMacId: nodeMapping.nodeMacId,
        nodeType: nodeMapping.nodeType,
        status: get(nodeMapping, "node.nodeOnlineStatus.nodeStatus", "").toLowerCase(),
        key: get(nodeMapping, "position.parentPosition"),
        room: nodeMapping.position,
        nodeSubType: get(nodeMapping, "node.nodeSubType"),
        location: nodeMapping?.position?.location,
        targetFwVersion: nodeMapping.node?.targetFirmwareRelease?.versionName,
        currentFwVersion: nodeMapping?.node?.currentFirmwareRelease?.versionName,
        failedCount: nodeMapping?.node?.otaFailedUpgradeAttempts,
        firmwareVersionLocked: nodeMapping?.node?.firmwareVersionLocked,
        comment: nodeMapping?.node?.otaComment,
        upgradeStatus: nodeMapping?.node?.nodeFirmware?.upgradeStatus,
        lockable: nodeMapping?.node?.nodeFirmware?.lockable,
      } as Node)
  );
};

export const getUpgradeStatusIcon = (upgradeStatus: UpgradeStatus) => {
  switch (upgradeStatus) {
    case UpgradeStatus.OK:
      return (
        <Tooltip title="Up to date">
          <CheckCircleFilled className="text-green" />
        </Tooltip>
      );
    case UpgradeStatus.PENDING:
      return (
        <Tooltip title="Upgrade pending">
          <ClockCircleFilled className="text-warning" />
        </Tooltip>
      );
    case UpgradeStatus.DEPRECATED:
      return (
        <Tooltip title="Node is deprecated">
          <InfoCircleFilled className="text-danger" />
        </Tooltip>
      );
    case UpgradeStatus.FAILED:
      return (
        <Tooltip title="Upgrade has failed">
          <InfoCircleFilled className="text-danger" />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Unknown">
          <CloseCircleFilled className="text-gray" />
        </Tooltip>
      );
  }
};

export const getNodeStatusIndicatorIcon = (nodeStatus: NodeStatus) => {
  if (nodeStatus === NodeStatus.Online) {
    return <span className="dot-icon bg-success" />;
  }
  return <span className="dot-icon bg-error" />;
};
