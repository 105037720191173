import { Badge, Col, DatePicker, Empty, Row, Select } from "antd";
import { SurveyIcon } from "components/Icons";
import LocationSwitcher from "components/LocationSwitcher";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import { formatLongDate, LONG_DATE_FORMAT } from "utils/date";
import { useEodSurveysFilter } from "../hooks/use-eod-survey-filter";
import EodSurveyIframe from "./EodSurveyIframe";
import "./styles.scss";
import { EodSurveyRecord, EodSurveyStatus } from "./types";

interface IEodSurveyMobileProps {
  location?: LocationWithPmsProperty;
  onLocationIdChosen: (locationId: string) => void;
  surveys: EodSurveyRecord[];
  focusedSurvey?: EodSurveyRecord;
  loading?: boolean;
}

const EodSurveyMobile = ({ onLocationIdChosen, location, surveys, focusedSurvey, loading }: IEodSurveyMobileProps) => {
  const { setSurveyDate, surveyDate, displayedSurveys } = useEodSurveysFilter(surveys, location);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h1 className="mr-l font-weight-bold mb-none">EOD SURVEY</h1>
        </Col>
        <Col>
          <LocationSwitcher onChange={onLocationIdChosen} />
        </Col>
      </Row>
      {loading || isEmpty(surveys) ? (
        <Empty className="mt-xl" />
      ) : (
        <>
          <DatePicker
            data-testid="eod-survey-date-filter"
            className="w-100 mt-s"
            placeholder="Filter by date"
            value={!surveyDate ? undefined : moment(surveyDate)}
            onChange={(value) => setSurveyDate(value?.toDate())}
            format={LONG_DATE_FORMAT}
          />
          <Select className="d-block w-100 eod-survey-selection-mobile mt-s" value={focusedSurvey?.surveyMonkeyId}>
            {displayedSurveys.map((survey) => (
              <Select.Option key={survey.surveyMonkeyId} value={survey.surveyMonkeyId}>
                <Row align="middle">
                  <Col className="px-xs">
                    <SurveyIcon />
                    <Badge dot color={survey.status === EodSurveyStatus.PENDING ? "gold" : "green"} />
                  </Col>
                  <Col>
                    <small className="pt-xs">Survey for date</small>
                    <p className="font-weight-bold">{formatLongDate(survey.createdAt, location?.timezone)}</p>
                  </Col>
                </Row>
              </Select.Option>
            ))}
          </Select>
          <div className="mt-m">
            <EodSurveyIframe survey={focusedSurvey} />
          </div>
        </>
      )}
    </>
  );
};

export default EodSurveyMobile;
