/* eslint-disable import/prefer-default-export */
import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { InProgressIcon, TodoListIcon } from "components/Icons";
import { KeyEntryDetailSubscription } from "pacts/app-webcore/hasura-webcore.graphql";
import { TestRun } from "pages/Checklist/Checklist.d";
import { TaskStatus } from "../KeyEntryJobChecklist.d";

export const statusIcon = (status?: string | null) => {
  switch (status) {
    case TaskStatus.DONE:
      return <CheckCircleFilled className="text-green taskStatus" />;
    case TaskStatus.IN_PROGRESS:
      return <InProgressIcon className="taskStatus" />;
    default:
      return <TodoListIcon className="taskStatus" />;
  }
};

export const toTestRuns = (data: KeyEntryDetailSubscription) => {
  const keyEntry = data.keyEntries[0];
  if (keyEntry) {
    const newTestRuns = data.keyEntries[0].testRuns.map((testRun) => {
      return {
        name: testRun.test.name,
        description: testRun.test?.description,
        troubleshootingLink: testRun.test?.troubleshootingLink,
        testId: testRun.test.testId,
        isManual: testRun.test.isManual,
        status: testRun.status,
        lastPassedAt: testRun?.lastPassedAt,
        testRunId: testRun.testRunId,
        positionId: testRun.autosetPositionId,
        note: testRun.note,
        details: testRun.details,
        isIgnored: testRun.isIgnored,
      } as TestRun;
    });
    return newTestRuns;
  }
  return undefined;
};
