import { Layout } from "antd";
import classNames from "classnames";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import PublicHeader from "components/Header/PublicHeader";
import { useNewRelicBrowserAgent } from "hooks/use-nr-browser-agent";
import ComfortPlus from "pages/ComfortPlus/ComfortPlus";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export interface IPublicRouteContext {
  setPublicHeaderShown?: (value: boolean) => void;
  setIsOnboarding?: (value: boolean) => void;
  isOnboarding?: boolean;
  setLayoutClassNames?: (classNames: Record<string, boolean>) => void;
}

const PublicRouteContext = React.createContext<IPublicRouteContext>({});
export const usePublicRouteContext = () => React.useContext(PublicRouteContext);

const PublicRoutes = () => {
  const [publicHeaderShown, setPublicHeaderShown] = React.useState<boolean>(true);
  const [layoutClassNames, setLayoutClassNames] = React.useState<Record<string, boolean>>({});
  const [isOnboarding, setIsOnboarding] = React.useState<boolean>(false);

  const { Content } = Layout;
  const { path } = useRouteMatch();
  const nrBrowserAgent = useNewRelicBrowserAgent();

  const publicRouteCtxValue = React.useMemo(
    () => ({
      setPublicHeaderShown,
      setIsOnboarding,
      isOnboarding,
      setLayoutClassNames,
    }),
    [setIsOnboarding, isOnboarding, setPublicHeaderShown, setLayoutClassNames]
  );

  return (
    <Layout className={classNames("layout comfort-plus-layout", layoutClassNames)}>
      <ErrorBoundary newrelicAgent={nrBrowserAgent}>
        <PublicRouteContext.Provider value={publicRouteCtxValue}>
          {publicHeaderShown && <PublicHeader />}
          <Content>
            <Switch>
              <Route path={`${path}/comfortplus`}>
                <ComfortPlus />
              </Route>
            </Switch>
          </Content>
        </PublicRouteContext.Provider>
      </ErrorBoundary>
    </Layout>
  );
};

export default PublicRoutes;
