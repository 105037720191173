import React from "react";
import { Modal, message } from "antd";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useUpdateLocationBeaconSlotManagementMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { ConfirmBeaconSlotManagementModalProps } from "./ConfirmBeaconSlotManagementModal.d";

const ConfirmBeaconSlotManagementModal = (props: ConfirmBeaconSlotManagementModalProps) => {
  const { onError, onSuccess, closeModal, modalWrapperProps, beaconSlotManagementEnabled, locationId } = props;
  const [updateLocationBeaconSlotManagement, { loading }] = useUpdateLocationBeaconSlotManagementMutation({
    onCompleted: () => {
      message.success("Successfully updated location's Beacon Slot Management!");
      if (isFunction(onSuccess)) onSuccess();
    },
    onError: (error) => {
      errorHandler.handleError(error);
      if (isFunction(onError)) onError();
    },
  });

  const handleOnConfirm = async () => {
    await updateLocationBeaconSlotManagement({ variables: { locationId, beaconSlotManagementEnabled } });
    closeModal();
  };

  return (
    <Modal
      title="Beacon slot management"
      centered
      onOk={handleOnConfirm}
      width={400}
      okText="Confirm"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      {...modalWrapperProps}
      onCancel={() => {
        if (isFunction(onError)) onError();
        closeModal();
      }}
    >
      Please confirm to update beacon slot management in the location
    </Modal>
  );
};
export default ConfirmBeaconSlotManagementModal;
