import { Button, Modal, Typography, message } from "antd";
import { isFunction } from "lodash";
import React, { useCallback, useState } from "react";
import { ApolloError } from "apollo-client";
import { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import classNames from "classnames";
import UnmapedNodeComponent from "pages/Node/ContractorNodeList/UnmapedNode/UnmappedNode";
import CopyButton from "components/CopyButton";
import { useSingleMapNodeMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import NodeMappingComponent from "./NodeMapping";
import { getExternalErrorComponent } from "./getExternalErrorComponent";

// Move on common component
export const displayNodeMacId = (nodeMacId?: any) => {
  if (!nodeMacId) return null;

  const subNodeMacIds: string[] = [];
  for (let i = 0; i < nodeMacId.length; i += 4) {
    const subNodeMacId = nodeMacId.substring(i, i + 4);
    subNodeMacIds.push(subNodeMacId);
  }
  const boldStr: string = subNodeMacIds.pop() ?? "";

  let normalStr: string = "";
  subNodeMacIds.forEach((str) => {
    normalStr += `${str}-`;
  });
  return (
    <>
      {normalStr}
      <b className="fs-l">{boldStr}</b>
      <CopyButton input={nodeMacId} />
    </>
  );
};

export interface NodeMappingButtonProps {
  onClose?: () => void;
  nodeMacInput?: string;
  onSubmit?: (nodeMacId: string, submitType: "Map" | "Unmap") => Promise<void> | void;
  nodeTypeCodeIdentifier: any;
  roomId: any;
  slotName: string;
  nodeSubTypeCode?: any;
  nodeType?: any;
  nodeSubType?: any;
  keyName?: any;
  roomName?: any;
  disabled?: boolean;
}

const HeaderContainer: React.FC<{ slotName: string; nodeSubType: string; nodeType: string }> = ({
  slotName,
  nodeSubType,
  nodeType,
}): React.ReactElement => (
  <>
    <p className="slot-name">{slotName}</p>
    <p className="node-type">{getNodeSubTypeDisplayName(nodeType || "", nodeSubType || "")}</p>
  </>
);

const NodeMappingButton: React.FC<NodeMappingButtonProps> = ({
  onClose,
  onSubmit,
  nodeMacInput,
  nodeTypeCodeIdentifier,
  roomId,
  slotName,
  nodeSubTypeCode,
  nodeSubType,
  nodeType,
  keyName,
  roomName,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [mapNodeError, setMapNodeError] = useState<ApolloError>();

  const [mapNodeMutation, { loading: isMapping }] = useSingleMapNodeMutation({
    onCompleted: () => message.success("Mapping Node Mac Id successful."),
    onError: setMapNodeError,
  });

  const handleMapNode = useCallback(
    async (request: { roomId: any; slotName: any; nodeType: any; currentNodeMacId: any; nodeSubType: any }) => {
      await mapNodeMutation({
        variables: {
          nodeType: request.nodeType,
          roomId: request.roomId,
          slotName: request.slotName,
          nodeSubType: request.nodeSubType,
          nodeMacInput: request.currentNodeMacId,
        },
      });
    },
    [mapNodeMutation]
  );

  const handleOnClose = useCallback(() => {
    setOpen(false);
    if (isFunction(onClose)) onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(
    async (currentNodeMacId: string) => {
      if (!nodeMacInput) {
        await handleMapNode({
          currentNodeMacId,
          nodeSubType,
          nodeType,
          roomId,
          slotName,
        });
        if (isFunction(onSubmit)) await onSubmit(currentNodeMacId, "Map");
      } else if (isFunction(onSubmit)) await onSubmit(currentNodeMacId, "Unmap");

      handleOnClose();
    },
    [onSubmit, handleOnClose, handleMapNode, nodeMacInput, nodeSubType, nodeType, roomId, slotName]
  );

  const customHeader = useCallback(
    () => <HeaderContainer nodeSubType={nodeSubType} nodeType={nodeType} slotName={slotName} />,
    [nodeSubType, nodeType, slotName]
  );

  return (
    <>
      {nodeMacInput && (
        <Typography.Text className="mb-xl d-block">Node Mac ID: {displayNodeMacId(nodeMacInput)}</Typography.Text>
      )}
      <Button className="w-100" type="primary" onClick={() => setOpen(true)} loading={isMapping} disabled={disabled}>
        {nodeMacInput ? "Unmap" : "Map"}
      </Button>
      <Modal
        className={classNames({
          "map-node-modal": !nodeMacInput,
          "unmap-node-modal": !!nodeMacInput,
        })}
        title={nodeMacInput ? "Unmap Node" : "Map Node"}
        footer={null}
        open={open}
        onCancel={handleOnClose}
        destroyOnClose
      >
        {nodeMacInput ? (
          <UnmapedNodeComponent
            roomId={roomId}
            roomName={roomName}
            keyName={keyName}
            currentNodeMacId={nodeMacInput}
            nodeTypeCodeIdentifier={nodeTypeCodeIdentifier}
            nodeSubTypeCode={nodeSubTypeCode}
            nodeType={nodeType}
            slotName={slotName}
            externalError={getExternalErrorComponent(mapNodeError, nodeMacInput)}
            onUnmapSuccessfully={() => handleOnSubmit(nodeMacInput)}
          />
        ) : (
          <NodeMappingComponent
            externalError={getExternalErrorComponent(mapNodeError, nodeMacInput)}
            isMapping={isMapping}
            nodeMapSubmit={handleOnSubmit}
            nodeTypeCodeIdentifier={nodeTypeCodeIdentifier}
            roomId={roomId}
            slotName={slotName}
            nodeSubType={nodeSubType}
            nodeSubTypeCode={nodeSubTypeCode}
            nodeType={nodeType}
            customHeader={customHeader}
          />
        )}
      </Modal>
    </>
  );
};
export default NodeMappingButton;
