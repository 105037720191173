import { AntdTablePagination } from "components/Table/Table.d";
import { isEmpty, isUndefined } from "lodash";
import { Order_By } from "pacts/app-webcore/hasura-webcore.graphql";
import { LocationWithPmsProperty } from "types/types";
import { BookingStatus, BOOKING_STATUS_PMS_MAPPING } from "../constants";
import { BookingRecord, IBookingListQueryFilter } from "./types";

export const convertBookingListFilterToQueryVariables = (
  locationPms: LocationWithPmsProperty["pms"],
  filter: IBookingListQueryFilter | undefined,
  orderBy: Record<string, any>[],
  pagination: AntdTablePagination
) => {
  let where: Record<string, any> = {
    pms_id: { _eq: isUndefined(locationPms?.pmsId) ? -1 : locationPms?.pmsId },
    property_id: { _eq: locationPms?.propertyId || "" },
    status: { _in: ["confirmed", "unconfirmed", "arrived", "departed"] },
  };
  if (filter?.checkInDate) {
    where = {
      ...where,
      check_in_date: {
        _gte: filter.checkInDate,
      },
    };
  }
  if (filter?.checkOutDate) {
    where = {
      ...where,
      check_out_date: {
        _lte: filter?.checkOutDate,
      },
    };
  }
  if (filter?.idSearchText) {
    where = {
      ...where,
      reservation_id: {
        _like: `%${filter.idSearchText}%`,
      },
    };
  }
  if (!isEmpty(filter?.statuses)) {
    where = {
      ...where,
      status: { _in: filter?.statuses?.flatMap((value) => BOOKING_STATUS_PMS_MAPPING[value]) },
    };
  }

  if (!isEmpty(filter?.roomCategories)) {
    where = {
      ...where,
      category_id: { _in: filter?.roomCategories },
    };
  }
  return {
    where,
    orderBy: isEmpty(orderBy?.[0])
      ? [
          {
            created_date: Order_By.Desc,
          },
        ]
      : orderBy,
    offset: (pagination.current - 1) * pagination.pageSize,
    limit: pagination.pageSize,
  };
};

export const getBookingStatus = (record: BookingRecord) => {
  return Object.values(BookingStatus).find((status) => BOOKING_STATUS_PMS_MAPPING[status].includes(record.status));
};

export const hasFilter = (filter?: IBookingListQueryFilter) => {
  return (
    filter?.checkInDate ||
    filter?.checkOutDate ||
    (filter?.statuses && filter.statuses.length > 0) ||
    (filter?.roomCategories && filter?.roomCategories.length > 0)
  );
};
