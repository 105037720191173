import { Button, Modal, Typography } from "antd";
import { displayNodeMacId } from "components/NodeMapping/NodeMappingButton";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import React, { useState } from "react";
import ModalWrapper from "components/Modal/Wrapper";
import ContractorMapNodeModal from "pages/InfrastructureDetails/ContractorMappedNodes/MapNodeModal/MapNodeModal";
import { UnmapModal } from "pages/Infrastructures/UnmapModal";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";

export interface NodeMappingButtonInfraProps {
  infraId?: string;
  infraType?: InfrastructureType;
  infraName?: string;
  meterPositionId?: string;
  slotName?: string;
  nodeMacId?: string;
  disabled?: boolean;
  reload?: () => Promise<void> | void;
}

const NodeMappingButtonInfra: React.FC<NodeMappingButtonInfraProps> = ({
  nodeMacId,
  infraId,
  infraName,
  infraType,
  meterPositionId,
  slotName,
  disabled,
  reload = () => {},
}) => {
  const [isUnmappedNodeModalOpen, setUnmappedNodeModalOpen] = useState(false);
  const [addMappingModalRef, openAddMappingModal] = useModalWrapperTrigger();

  const onMapOrUnMap = () => {
    if (!nodeMacId) openAddMappingModal();
    else setUnmappedNodeModalOpen(true);
  };

  const closeModalUnmapNode = () => {
    setUnmappedNodeModalOpen(false);
  };

  return (
    <>
      {nodeMacId && (
        <Typography.Text className="mb-xl d-block">Node Mac ID: {displayNodeMacId(nodeMacId)}</Typography.Text>
      )}

      <Button className="w-100" type="primary" onClick={onMapOrUnMap} disabled={disabled}>
        {nodeMacId ? "Unmap" : "Map"}
      </Button>

      <ModalWrapper
        ref={addMappingModalRef}
        modal={ContractorMapNodeModal}
        props={{
          infraId,
          infraType,
          meterPositionId,
          onSuccessfully: reload,
        }}
      />

      <Modal
        open={isUnmappedNodeModalOpen}
        className="unmap-node-modal"
        title="Unmap Node"
        footer={null}
        onCancel={closeModalUnmapNode}
        destroyOnClose
      >
        <UnmapModal
          currentNodeMacId={nodeMacId || ""}
          slotName={slotName}
          infraId={infraId || ""}
          onUnmapSuccessfully={async () => {
            await reload();
            closeModalUnmapNode();
          }}
          onCloseModal={closeModalUnmapNode}
          infraName={infraName}
        />
      </Modal>
    </>
  );
};

export default NodeMappingButtonInfra;
