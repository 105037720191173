import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useKeyCategoriesQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import CreateKeyCategoryModal from "pages/KeyCategory/CreateKeyCategoryModal/CreateKeyCategoryModal";
import KeyCategoryListTable from "./KeyCategoryListTable/KeyCategoryListTable";
import UpdateKeyCategoryModal from "./UpdateKeyCategoryModal/UpdateKeyCategoryModal";
import { KeyCategory, KeyCategoryListProps } from "./KeyCategoryList.d";
import errorHandler from "../../errorHandler";

const KeyCategoryListPage = ({ locationName }: KeyCategoryListProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [keyCategories, setKeyCategories] = useState<KeyCategory[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [editCategory, setEditCategory] = useState<KeyCategory | undefined>();

  const queryVariables = {
    locationIdInput: {
      location: {
        locationId: {
          _eq: locationId,
        },
      },
    },
  };

  const {
    loading: categoriesLoading,
    error: categoriesError,
    refetch,
  } = useKeyCategoriesQuery({
    variables: queryVariables,
    onCompleted: (data) => {
      setKeyCategories(data.keyCategories || []);
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const refetchCategories = async () => {
    const { data } = await refetch(queryVariables);
    setKeyCategories(data.keyCategories || []);
  };

  const handleEditClick = (category: KeyCategory) => {
    setEditCategory(category);
  };

  const statMessage = useMemo(() => {
    if (categoriesLoading) return "Loading...";
    if (categoriesError) return "Error on loading location";

    return `Showing all key categories in ${locationName}`;
  }, [categoriesLoading, categoriesError, locationName]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "1em" }}>
        <Col>
          <h2 style={{ display: "inline", marginRight: "20px" }}>Categories</h2>
          <p style={{ display: "inline" }}>{statMessage}</p>
        </Col>
        <Button type="primary" onClick={() => setOpenCreateModal(true)}>
          <PlusOutlined />
          <span>Add Category</span>
        </Button>
      </Row>
      <Row>
        <KeyCategoryListTable
          tableData={keyCategories}
          loading={categoriesLoading}
          error={categoriesError}
          onEditClick={handleEditClick}
        />
      </Row>
      <CreateKeyCategoryModal
        visible={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
        locationName={locationName}
        refetchCategories={refetchCategories}
      />
      {editCategory && (
        <UpdateKeyCategoryModal
          closeModal={() => setEditCategory(undefined)}
          refetchCategories={refetchCategories}
          id={editCategory.id || ""}
          categoryName={editCategory.categoryName}
        />
      )}
    </>
  );
};

export default KeyCategoryListPage;
