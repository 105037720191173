import React, { useContext, useEffect, useState } from "react";
import { message, Modal } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal";
import errorHandler from "errorHandler";
import { assign, isNil } from "lodash";
import InfrastructureDetailsContext from "pages/InfrastructureDetails/InfrastructureDetails.Context";
import {
  GetInfrasByPositionMeterIdQuery,
  useGetInfrasByPositionMeterIdLazyQuery,
  useSwapChannelsMutation,
  useUpdateInfraNodeMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { MappedNodeDataType } from "pages/InfrastructureDetails/InfrastructureDetails.d";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { transformToEditingNode, transformToUpdateNodeInput } from "./EditNodeModal.transformer";
import NodeInput from "../NodeInput/NodeInput";

interface EditNodeModalProps {
  node: MappedNodeDataType;
  reloadInfrastructure: () => void;
}

const EditNodeModal = ({
  node,
  modalWrapperProps,
  closeModal,
  reloadInfrastructure,
}: EditNodeModalProps & ModalWrapperProps) => {
  const [nodeInput, setNodeInput] = useState<any>(transformToEditingNode(node));
  const [isInvalidInputData, setInvalidInputData] = useState<boolean>();
  const infraDetails = useContext(InfrastructureDetailsContext);
  const infraType = new InfrastructureType(infraDetails?.type);
  const [isInputChannelChanged, setIsInputChannelChanged] = useState<boolean>(false);
  const [infraChannels, setInfraChannels] = useState<GetInfrasByPositionMeterIdQuery["infrastructures"]>();

  const { id } = infraDetails ?? { id: "" };
  const [swapChannels] = useSwapChannelsMutation({
    onCompleted: () => {
      message.success("Swap channels successfully.");
    },
    onError: errorHandler.handleError,
  });

  const [getInfrasByPositionMeterId] = useGetInfrasByPositionMeterIdLazyQuery({
    variables: {
      meterPositionId: infraDetails?.meterPositionId,
    },
    onCompleted: (data) => {
      setInfraChannels(data.infrastructures);
    },
  });

  const [updateInfraNode, { loading: updateInfraNodeLoading }] = useUpdateInfraNodeMutation({
    onCompleted: () => {
      message.success("Edit node successful");
      closeModal();
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  const handleSubmit = async () => {
    if (isInputChannelChanged && infraType.isCompressor() && infraDetails && infraChannels) {
      const destinationCompressor = infraChannels?.find((infra) => infra.phaseStreamIndex === nodeInput.mapInput);
      if (destinationCompressor) {
        Modal.confirm({
          title: "Warning",
          content: `This channel is already in use by ${destinationCompressor?.name}. If you wish to proceed the current channel used by this compressor will be swapped with the channel of the compressor using the desired channel right now.`,
          okText: "Proceed",
          onOk: async () => {
            await swapChannels({
              variables: {
                startingCompressorId: id,
                destinationCompressorId: destinationCompressor?.id,
              },
            });
            updateInfraNode({
              variables: transformToUpdateNodeInput(infraDetails!.id, nodeInput) as any,
            });
          },
        });
        return;
      }
    }
    updateInfraNode({
      variables: transformToUpdateNodeInput(infraDetails!.id, nodeInput) as any,
    });
  };

  const handleInputChange = (
    inputChange: any,
    isInvalidData: boolean | undefined,
    isInputChanged: boolean | undefined
  ) => {
    setNodeInput(assign(nodeInput, inputChange));
    setInvalidInputData(isInvalidData);
    if (!isNil(isInputChanged)) {
      setIsInputChannelChanged(isInputChanged);
    }
  };

  useEffect(() => {
    if (infraDetails?.meterPositionId) {
      getInfrasByPositionMeterId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infraDetails]);

  return (
    <Modal
      title="Edit Node"
      centered
      onOk={handleSubmit}
      width={450}
      okText="Confirm"
      okButtonProps={{
        disabled: isInvalidInputData,
      }}
      confirmLoading={updateInfraNodeLoading}
      {...modalWrapperProps}
    >
      <NodeInput type={infraType} node={nodeInput} handleInputChange={handleInputChange} />
    </Modal>
  );
};

export default EditNodeModal;
