import React from "react";

import { Divider } from "antd";
import { CurrentBreadcrumbStep, CompletedBreadcrumbStep, IncompleteBreadcrumbStep } from "./BreadcrumbStep";
import styles from "./Breadcrumb.module.scss";

type BreadcrumbProps = {
  steps: Array<string>;
  currentStep: string;
};

const BreadcrumbComponent = ({ steps, currentStep }: BreadcrumbProps) => {
  const currentStepIndex = steps.indexOf(currentStep);

  return (
    <div className={styles.breadcrumb}>
      {steps.map((step, index) => {
        if (index < currentStepIndex) {
          return (
            <React.Fragment key={index}>
              <CompletedBreadcrumbStep text={step} data-testid={`step-${index}-testId`} />
              <Divider className={styles.breadcrumbDivider} />
            </React.Fragment>
          );
        }

        if (index === currentStepIndex) {
          return (
            <CurrentBreadcrumbStep key={index} text={step} badge={index + 1} data-testid={`step-${index}-testId`} />
          );
        }

        return (
          <React.Fragment key={index}>
            <Divider className={styles.breadcrumbDivider} />
            <IncompleteBreadcrumbStep text={step} badge={index + 1} data-testid={`step-${index}-testId`} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadcrumbComponent;
