import React, { useCallback } from "react";
import "./ConfirmPreCoolModal.scss";
import { Button, Modal, Typography } from "antd";
import { StartButtonIcon } from "components/Icons";

export interface ConfirmPreCoolModalProps {
  nextScheduleInMinutes: number;
  isOpen: boolean;
  onConfirmed: (value: any) => void;
}

const ConfirmPreCoolModal = ({ nextScheduleInMinutes, isOpen, onConfirmed }: ConfirmPreCoolModalProps) => {
  const handleOnConfirm = useCallback(() => {
    onConfirmed(true);
  }, [onConfirmed]);

  const handleOnClose = useCallback(() => {
    onConfirmed(false);
  }, [onConfirmed]);

  return (
    <Modal
      centered
      open={isOpen}
      onOk={handleOnConfirm}
      onCancel={handleOnClose}
      className="precool-now-confirm-modal"
      closeIcon={null}
      closable={false}
      footer={null}
      destroyOnClose
    >
      <span className="precool-now-confirm-modal-icon">
        <StartButtonIcon />
      </span>
      <Typography.Title level={4}>Are you sure you want to pre-cool your room now?</Typography.Title>
      <Typography.Text>
        Your upcoming schedule will start in {nextScheduleInMinutes} minutes. If you start pre-cool your room now, the
        upcoming schedule will be canceled.
      </Typography.Text>
      <div className="precool-now-confirm-actions">
        <Button
          className="w-100 precool-now-confirm-actions-btn precool-now-confirm-actions-cancel"
          onClick={handleOnClose}
        >
          Cancel
        </Button>
        <Button
          className="w-100 precool-now-confirm-actions-btn precool-now-confirm-actions-confirm"
          onClick={handleOnConfirm}
        >
          Pre-cool now
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmPreCoolModal;
