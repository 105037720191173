import { Input } from "antd";
import CurrencySelector from "components/Currency/CurrencySelector";
import OrganizationAutoComplete from "components/OrganizationAutoComplete/OrganizationAutoComplete";
import TimeZoneAutoComplete from "components/TimeZoneAutoComplete/TimeZoneAutoComplete";
import React from "react";

export enum CreateLocationFormFieldKey {
  ORGANIZATION_NAME = "organisationName",
  LOCATION_NAME = "locationName",
  TIMEZONE = "timezone",
  CURRENCY = "currency",
  LATITUDE = "lat",
  LONGITUDE = "lng",
  CLICKUP_LIST_LINK = "clickupListLink",
}

export const clickupListLinkRegex = /^https:\/\/app\.clickup\.com\/\d+\/v\/li\/\d+$/;

export const CREATE_LOCATION_FORM_CONFIG = [
  {
    label: "Organization",
    name: CreateLocationFormFieldKey.ORGANIZATION_NAME,
    defaultValue: "",
    trigger: "handleChangedValue",
    rules: [
      {
        required: true,
        message: "Location name is required",
      },
    ],
    component: <OrganizationAutoComplete />,
  },
  {
    label: "Location Name",
    name: CreateLocationFormFieldKey.LOCATION_NAME,
    defaultValue: "",
    trigger: "onChange",
    rules: [
      {
        required: true,
        message: "Location name is required",
      },
    ],
    component: <Input placeholder="Location Name" />,
  },
  {
    label: "Currency",
    name: CreateLocationFormFieldKey.CURRENCY,
    defaultValue: "",
    trigger: "onChange",
    rules: [
      {
        required: true,
        message: "Currency is required",
      },
    ],
    component: <CurrencySelector />,
  },
  {
    label: "Timezone",
    name: CreateLocationFormFieldKey.TIMEZONE,
    trigger: "handleChangedValue",
    defaultValue: "",
    rules: [
      {
        required: true,
        message: "Timezone is required",
      },
    ],
    component: <TimeZoneAutoComplete />,
  },
  {
    label: "Latitude",
    name: CreateLocationFormFieldKey.LATITUDE,
    trigger: "onChange",
    defaultValue: "",
    rules: [
      {
        required: true,
        pattern: /^[+-]?[1-9]\d*(\.\d+)?$/gm,
        message: "Latitude must be in numeric format, e.g: 123.456",
      },
    ],
    component: <Input placeholder="Latitude. E.g: 123.456" />,
  },
  {
    label: "Longitude",
    name: CreateLocationFormFieldKey.LONGITUDE,
    trigger: "onChange",
    defaultValue: "",
    rules: [
      {
        required: true,
        pattern: /^[+-]?[1-9]\d*(\.\d+)?$/gm,
        message: "Longitude must be in numeric format, e.g: 123.456",
      },
    ],
    component: <Input placeholder="Longitude. E.g: 123.456" />,
  },
  {
    label: "Clickup List Link",
    name: CreateLocationFormFieldKey.CLICKUP_LIST_LINK,
    trigger: "onChange",
    defaultValue: "",
    rules: [{ required: true, pattern: clickupListLinkRegex, message: "Please enter the correct ClickUp List Link" }],
    component: <Input placeholder="Clickup List Link" />,
  },
];
