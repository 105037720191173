import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { DeleteFilled, SettingFilled } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row } from "antd";
import classNames from "classnames";
import Can from "components/Can/Can";
import { Permission } from "pacts/permission";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import { uniq } from "lodash";
import useLocationDateTime from "hooks/use-location-datetime";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { InfrastructureBlockProps, NodeConnected } from "./InfrastructureBlock.d";
import DeleteInfrastructureModal from "./DeleteInfrastructureModal/DeleteInfrastructureModal";
import { InfrastructureType } from "../InfrastructureDataType";
import "./InfrastructureBlock.scss";

const InfrastructureBlock = ({
  id,
  name,
  type,
  rooms,
  meterPosition,
  handleDeleteBlock,
  locationName,
}: InfrastructureBlockProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { currentPositionId } = useContext(InstallationModeContext);

  const [deleteInfrastructureModalRef, openDeleteInfrastructureModal] = useModalWrapperTrigger();

  const connectedNodes = meterPosition?.nodes || [];
  const infraType = new InfrastructureType(type);
  const { formatFromNow } = useLocationDateTime();

  const aggregatedKeys =
    rooms && rooms.length > 0 ? uniq(rooms.map((r) => r.position?.parentPosition?.positionName)).join(", ") : "None";

  const onDeleteButtonClick = () => {
    if (connectedNodes.length > 0) {
      Modal.warning({
        title: "Warning",
        content:
          "Deleting a compressor with mapped nodes is not allowed, please unmap all nodes from compressors before deleting.",
      });
    } else {
      openDeleteInfrastructureModal();
    }
  };

  return (
    <div className="compressor">
      <Row className="compressor-header">
        <Col flex="auto">
          <h4 className="name">{name}</h4>
        </Col>
        <Can
          requiredPermission={Permission.INFRASTRUCTURE_VIEW}
          yes={
            <Link
              to={{
                pathname: `/locations/${locationId}/infrastructures/${id}`,
                state: {
                  locationName,
                },
              }}
            >
              <SettingFilled className="action" />
            </Link>
          }
        />
        <Can
          requiredPermission={Permission.INFRASTRUCTURE_DELETE}
          yes={<DeleteFilled className="action" onClick={onDeleteButtonClick} />}
        />
      </Row>

      <div className="compressor-section">
        <p className="title">TYPE</p>
        <p className="compressor-type">{infraType.getLabel()}</p>
      </div>

      {infraType.isCompressor() && (
        <div className="compressor-section">
          <p className="title">keys connected</p>
          <p className="room-connected-counter">{aggregatedKeys}</p>
        </div>
      )}

      {!infraType.isCoolingSystem() && (
        <div className="compressor-section mb-none">
          <p className="title">node connected</p>

          {!connectedNodes || connectedNodes.length === 0 ? (
            <div className="empty-node-connected">
              <span className="message">None</span>
              <Link
                to={{
                  pathname: `/locations/${locationId}/infrastructures/${id}`,
                  state: {
                    openMapNode: true,
                    locationName,
                  },
                }}
              >
                <Button>Map</Button>
              </Link>
            </div>
          ) : (
            connectedNodes.map((nodeConnected: NodeConnected, index: number) => {
              const status =
                currentPositionId === id ? nodeConnected.nodeStatusInInstallationMode : nodeConnected.status;
              return (
                <div key={index} className="node-connected">
                  <Divider />
                  <Row>
                    <Col span={10} className="group">
                      <p className="node-mac text-eclipse">{nodeConnected.nodeMacId}</p>
                      <p className="node-type">{nodeConnected.nodeType}</p>
                    </Col>
                    <Col span={8} className="group">
                      <p className="last-seen-title">last seen</p>
                      <p className="last-seen">{formatFromNow(nodeConnected.lastSeen)}</p>
                    </Col>
                    <Col span={6}>
                      <div className={classNames("node-status", status)}>
                        <span>{status}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })
          )}
        </div>
      )}

      <ModalWrapper
        ref={deleteInfrastructureModalRef}
        modal={DeleteInfrastructureModal}
        props={{ infraId: id, name, onSuccess: handleDeleteBlock }}
      />
    </div>
  );
};

export default InfrastructureBlock;
