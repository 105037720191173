import { FormInstance } from "antd";

export interface FormValue {
  name: string;
  type: GroupType;
}

export enum GroupType {
  FLOOR = "floor",
  ZONE = "zone",
  GROUP = "group",
}

export enum PositionFunction {
  GROUP = "group",
}

export interface GroupModalProps {
  [x: string]: any;
  formValue: Partial<FormValue>;
  isUpdate?: boolean;
  onSubmit: (value: FormValue, helper: FormInstance<FormValue>) => Promise<void> | void;
  errors?: Record<keyof FormValue, string | string[]>;
  loading?: boolean;
  open?: boolean;
  onCancel?: () => void;
}
