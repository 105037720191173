/* eslint-disable import/no-mutable-exports */
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { getDevMockAuthService, getE2EStagingMockAuthService } from "./mockAuthService";

// toggles between actual and mock based on env var
let USE_MOCK_FOR_DEV = false;
let TEST_E2E_STAGING = false;

switch (true) {
  case localStorage.getItem("cypressAuthState") != null:
    TEST_E2E_STAGING = true;
    break;
  case process.env.REACT_APP_DEVELOP_WITH_MOCK === "true":
  case process.env.NODE_ENV === "test":
    USE_MOCK_FOR_DEV = true;
    break;
  default:
    break;
}

let provider: any;
let hook: any;

if (TEST_E2E_STAGING) {
  ({ provider, hook } = getE2EStagingMockAuthService());
} else if (USE_MOCK_FOR_DEV) {
  ({ provider, hook } = getDevMockAuthService());
} else {
  provider = Auth0Provider;
  hook = useAuth0;
}

export { provider as Auth0Provider, hook as useAuth0 };
