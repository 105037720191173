import { Collapse, Input, message, Modal, Select } from "antd";
import classNames from "classnames";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import errorHandler from "errorHandler";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { assign, isFunction, isNil } from "lodash";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import {
  GetInfrasByPositionMeterIdQuery,
  MapInfraNodeMutationVariables,
  NodeType,
  useGetCurrentNodeToPositionMappingLazyQuery,
  useGetInfrasByPositionMeterIdQuery,
  useGetNodeMappedPreviouslyQuery,
  useMapInfraNodeMutation,
  useSwapChannelsMutation,
  useValidateInfraNodeMappingLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { Link, useParams } from "react-router-dom";
import { NodeMappingError } from "components/NodeMapping/NodeMappingError.d";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import NodeInput from "../../MappedNodes/NodeInput/NodeInput";
import { NodeTypeOption, nodeTypes } from "../../MappedNodes/NodeInput/NodeInput.d";
import { NodeTypeCodeIdentifiers } from "../MappedNodes.d";
import NodeMacInput, { InternalError } from "./NodeMacInput";

interface MapNodeModalProps {
  infraId: string;
  infraType: InfrastructureType;
  reloadInfrastructures: (filterValue: any) => void;
  currentFilter: any;
  meterPositionId: string;
  onSuccessfully?: () => Promise<void> | void;
}

const INVALID_NODE_ID_MSG = "Node ID should begin with valid node type identifier. Scan or type Node ID again to retry";
const IS_INSTALLED_NODE_MSG = "Node is already mapped! Please choose different node.";

const messageNodeMappedError = (
  {
    locationId,
    locationName,
    infraName,
    infraId,
  }: {
    infraId: string;
    infraName: string;
    locationId: string;
    locationName: string;
  },
  currentLocation?: string
) => {
  return (
    <>
      Node is already mapped in infra:{" "}
      <Link to={`/locations/${locationId}/infrastructures/${infraId}`}>{infraName}</Link>
      {currentLocation !== locationId && `, in location: ${locationName}`}! Please choose different node.
    </>
  );
};

const ContractorMapNodeModal = ({
  infraId,
  infraType,
  modalWrapperProps,
  meterPositionId,
  closeModal,
  onSuccessfully,
}: MapNodeModalProps & ModalWrapperProps) => {
  const roleAndPermission = useRoleAndPermission();
  const { locationId } = useParams<{ locationId: string }>();

  const [nodeMacInput, setNodeMacInput] = useState<string>("");
  const [nodeInput, setNodeInput] = useState<MapInfraNodeMutationVariables>();
  const [isValidNodeData, setValidNodeData] = useState<boolean>(false);
  const [isInvalidInputData, setInvalidInputData] = useState<boolean>();
  const [slotName, setSlotName] = useState<string>(roleAndPermission.isContractor() ? NodeType.Energy : "");
  const [nodeType, setNodeType] = useState<NodeType>(NodeType.Energy);
  const [isNewNode, setIsNewNode] = useState<boolean>(false);
  const [isInstalledNode, setIsInstalledNode] = useState<boolean>(true);
  const [isInputChannelChanged, setIsInputChannelChanged] = useState<boolean>(false);
  const [emonSetting, setEmonSetting] = useState<number>();
  const [mapInput, setMapInput] = useState<number>();
  const [isRemappingNode, setIsRemappingNode] = useState<boolean>(false);
  const [internalError, setInternalError] = useState<any>();
  const [infraChannels, setInfraChannels] = useState<GetInfrasByPositionMeterIdQuery["infrastructures"]>();

  const infraNode: MapInfraNodeMutationVariables = { infraId, nodeMacInput, slotName, nodeType };
  const FULL_NODE_MAC_ID_LENGTH = 18;

  useEffect(() => {
    setNodeInput(infraNode);
    setInvalidInputData(some(infraNode, (data) => isEmpty(data)) && !isNewNode && isInstalledNode);
    // eslint-disable-next-line
  }, [infraId, nodeMacInput, isNewNode, isInstalledNode, setNodeInput, setInvalidInputData]);

  const { loading: nodeMappedPreviouslyLoading } = useGetNodeMappedPreviouslyQuery({
    variables: {
      infraId,
    },
    onCompleted: ({ getNodeMappedPreviously: data }) => {
      if (data) {
        setIsRemappingNode(true);
        setSlotName(data.slotName);
        setNodeType(data.nodeType as NodeType);

        if (data.desiredEmonSwitchPosition || data.desiredEmonSwitchPosition === 0) {
          setEmonSetting(data.desiredEmonSwitchPosition);
        }

        if (data.phaseStreamIndex || data.phaseStreamIndex === 0) {
          setMapInput(data.phaseStreamIndex);
        }
      }
    },
  });
  useGetInfrasByPositionMeterIdQuery({
    variables: {
      meterPositionId,
    },
    onCompleted: (data) => {
      setInfraChannels(data.infrastructures);
    },
  });

  const [checkIsInstalledNode, { loading: checkingIsInstalledNode }] = useGetCurrentNodeToPositionMappingLazyQuery({
    onCompleted: ({ sensorflow_node_to_position_mapping: data }) => {
      if (data[0] && !data[0].decomissionedTime) {
        setInternalError(IS_INSTALLED_NODE_MSG);
        setIsInstalledNode(true);
      } else {
        setIsInstalledNode(false);
        setIsNewNode(true);
      }
    },
  });

  const [validateInfraNodeMapping, { loading: validateInfraNodeMappingLoading }] = useValidateInfraNodeMappingLazyQuery(
    {
      onCompleted: () => {
        setValidNodeData(true);
        setInternalError(null);
        setInvalidInputData(false);
        checkIsInstalledNode({ variables: { nodeMacId: nodeMacInput } });
      },
      onError: (error) => {
        if (!isInputChannelChanged || !infraType.isCompressor()) {
          const { extensions } = error.graphQLErrors[0] || {};
          if (!extensions) return;

          const errorMessage =
            extensions.code === NodeMappingError.NodeAlreadyMappedError
              ? messageNodeMappedError(extensions?.mappedNodeInInfra || {}, locationId)
              : INVALID_NODE_ID_MSG;

          setInternalError(errorMessage);
          setInvalidInputData(true);
        }
      },
    }
  );

  const [mapInfraNode, { loading: mapInfraNodeLoading }] = useMapInfraNodeMutation({
    onCompleted: async () => {
      message.success("Mapped node successful");
      closeModal();
      if (isFunction(onSuccessfully)) await onSuccessfully();
    },
    onError: errorHandler.handleError,
  });

  const formatNodeMacId = () => {
    const nodeTypeCode = NodeTypeCodeIdentifiers.find(
      (NodeTypeCodeIdentifier) => NodeTypeCodeIdentifier.type === (nodeType as NodeType)
    );
    return nodeMacInput.length === FULL_NODE_MAC_ID_LENGTH ? nodeMacInput : nodeTypeCode.code + nodeMacInput;
  };

  const [swapChannels] = useSwapChannelsMutation({
    onCompleted: () => {
      message.success("Swap channels successfully.");
    },
    onError: errorHandler.handleError,
  });

  const handleConfirm = () => {
    const nodeMacId = formatNodeMacId();

    if (!isValidNodeData) {
      validateInfraNodeMapping({
        variables: {
          ...infraNode,
          nodeMacInput: nodeMacId,
        },
      });
    } else if (nodeInput) {
      if (isInputChannelChanged) {
        if (infraType.isCompressor()) {
          const destinationCompressor = infraChannels?.find((infra) => infra.phaseStreamIndex === nodeInput.mapInput);
          if (destinationCompressor) {
            Modal.confirm({
              title: "Warning",
              content: `This channel is already in use by ${destinationCompressor?.name}. If you wish to proceed the current channel used by this compressor will be swapped with the channel of the compressor using the desired channel right now.`,
              okText: "Proceed",
              onOk: async () => {
                await swapChannels({
                  variables: {
                    startingCompressorId: infraId,
                    destinationCompressorId: destinationCompressor?.id,
                  },
                });
                mapInfraNode({
                  variables: {
                    ...nodeInput,
                    nodeMacInput: nodeMacId,
                    emonSetting: nodeInput.emonSetting ?? emonSetting,
                    mapInput: nodeInput.mapInput ?? mapInput,
                  },
                });
              },
            });
            return;
          }
        }
        Modal.confirm({
          title: "Warning",
          content:
            "If you are replacing an emon make sure you are mapping the new one to the same channel as the old one. Changing channels will disconnect past data that was recorded on a different channel.",
          okText: "Proceed",
          onOk: () => {
            mapInfraNode({
              variables: {
                ...nodeInput,
                nodeMacInput: nodeMacId,
                emonSetting: nodeInput.emonSetting ?? emonSetting,
                mapInput: nodeInput.mapInput ?? mapInput,
              },
            });
          },
        });
        return;
      }
      mapInfraNode({
        variables: {
          ...nodeInput,
          nodeMacInput: nodeMacId,
          emonSetting: nodeInput.emonSetting ?? emonSetting,
          mapInput: nodeInput.mapInput ?? mapInput,
        },
      });
    }
  };

  useDebounce(
    () => {
      if (nodeMacInput) {
        const nodeMacId = formatNodeMacId();
        validateInfraNodeMapping({
          variables: {
            ...infraNode,
            nodeMacInput: nodeMacId,
          },
        });
      }
    },
    500,
    [nodeMacInput]
  );

  const onSlotNameChange = (e: any) => setSlotName(e.target.value);

  const handleInputChange = (
    inputChange: any,
    isInvalidData: boolean | undefined,
    isInputChanged: boolean | undefined
  ) => {
    setNodeInput(assign(infraNode, inputChange));
    setInvalidInputData(isInvalidData);
    if (inputChange.emonSetting !== emonSetting) {
      setEmonSetting(inputChange.emonSetting);
    }
    if (!isNil(isInputChanged)) {
      setIsInputChannelChanged(isInputChanged);
    }
  };

  const isLoadedPreviousConfig = () => {
    // return true if mapping with brand-new infra because there is no data to load
    if (!isRemappingNode) return true;

    // if loaded previous config, emonSetting shoud be loaded
    if (emonSetting || emonSetting === 0) {
      if (infraType.numbOfPhases === 1) {
        // mapInput should be loaded if infra type is 1 phase
        return !!(mapInput || mapInput === 0);
      }
      // with 3-phases infra, mapInput doesn't need to be checked
      return true;
    }
    return false;
  };

  return (
    <Modal
      title="Map Node"
      centered
      width={450}
      onOk={handleConfirm}
      okText={isRemappingNode || isValidNodeData ? "Done" : "Next"}
      confirmLoading={validateInfraNodeMappingLoading || mapInfraNodeLoading}
      okButtonProps={{
        disabled:
          isInvalidInputData ||
          nodeMappedPreviouslyLoading ||
          checkingIsInstalledNode ||
          !isNewNode ||
          !isLoadedPreviousConfig(),
      }}
      {...modalWrapperProps}
    >
      {!isRemappingNode && isValidNodeData ? (
        <NodeInput
          node={{
            ...nodeInput,
            emonSetting,
            mapInput,
          }}
          infraId={infraId}
          type={infraType}
          handleInputChange={handleInputChange}
        />
      ) : (
        <>
          <div
            className={classNames({
              "data-section": true,
              "d-none": roleAndPermission.isContractor(),
            })}
          >
            <p className="title">NODE TYPE</p>
            <Select className="w-100" defaultValue={nodeType} onSelect={setNodeType} placeholder="Node Type">
              {nodeTypes.map((type: NodeTypeOption, index: number) => (
                <Select.Option key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div
            className={classNames({
              "data-section": true,
              "d-none": roleAndPermission.isContractor(),
            })}
          >
            <p className="title">SLOT NAME</p>
            <Input placeholder="Slot Name" onChange={onSlotNameChange} value={slotName} />
          </div>

          <NodeMacInput
            setNodeMacInput={setNodeMacInput}
            value={nodeMacInput}
            isValidNodeData={isValidNodeData && !isInstalledNode}
          />
          {internalError && <InternalError className="mb-m" errorMessage={internalError} />}
          {isRemappingNode && (
            <Collapse>
              <Collapse.Panel key="advanced" header="Advanced">
                <NodeInput
                  node={{
                    ...nodeInput,
                    emonSetting,
                    mapInput,
                  }}
                  infraId={infraId}
                  type={infraType}
                  handleInputChange={handleInputChange}
                  isRemappingNode={isRemappingNode}
                />
              </Collapse.Panel>
            </Collapse>
          )}
        </>
      )}
    </Modal>
  );
};

export default ContractorMapNodeModal;
