import React from "react";

import ExternalError from "./ExternalError";

const NodeMappingError = ({ nodeId, reportId = "242354323423" }: any) => {
  const details = [
    "Retry again above by scanning Node ID",
    "If problem persists, set Node aside and try with a different node.",
    "",
    `Error report ID: ${reportId}`,
  ];

  return <ExternalError message={`Error mapping node ${nodeId}`} details={details} />;
};

export default NodeMappingError;
