import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ApolloError } from "apollo-client";
import { Alert, Button, Col, Divider, notification, Row, Space } from "antd";
import compact from "lodash/compact";
import get from "lodash/get";
import { usePreviousDistinct } from "react-use";
import {
  SlotRapidMappingStatus,
  useRapidMapMutation,
  useRapidMappingQuery,
  useSkipRapidMappingMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import NodeTypeDisplay, { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import NodeMapping, { getExternalErrorComponent } from "components/NodeMapping";
import CurrentRoomInfo from "./CurrentRoomInfo/CurrentRoomInfo";
import KeysStats from "./KeysStats/KeysStats";
import CurrentRoomNodeSlotList from "./CurrentRoomNodeSlotList/CurrentRoomNodeSlotList";
import NextRoomInfo from "./NextRoomInfo/NextRoomInfo";
import FullyMappedComponent from "./FullyMappedComponent/FullyMappedComponent";
import { NodeMappingResponse } from "./RapidMapping.d";
import "./RapidMapping.css";

interface MappingNodeType {
  nodeType: string;
  nodeSubType: string;
  nodeId: string;
}

const displaySuccessNotification = (mappingNode: MappingNodeType) => {
  notification.success({
    message: `${getNodeSubTypeDisplayName(mappingNode.nodeType, mappingNode.nodeSubType)} Node ${
      mappingNode.nodeId
    } mapped`,
    top: 90,
    duration: 5,
    style: {
      width: 450,
    },
  });
};

const displayExitingNotification = (locationName: string) => {
  notification.info({
    message: `You have reached the end of rapid mapping for ${locationName}, exiting…`,
    top: 90,
    duration: 5,
    style: {
      width: 450,
    },
  });
};

const isRoomFullyMapped = (
  prevRapidMappingData: NodeMappingResponse | undefined,
  rapidMappingData: NodeMappingResponse
) => {
  if (!prevRapidMappingData) return false;

  // check if the last mapping is of the last node in room
  if (
    prevRapidMappingData.currentRapidMappingKey?.currentRapidMappingRoom?.roomId !==
    rapidMappingData.currentRapidMappingKey?.currentRapidMappingRoom?.roomId
  ) {
    const slots = prevRapidMappingData.currentRapidMappingKey?.currentRapidMappingRoom?.slots;
    if (!slots || slots.length === 0) return false;
    return (
      compact(slots.map((slot) => slot.mappingStatus !== SlotRapidMappingStatus.Mapped)).length === 1 &&
      slots[slots.length - 1].mappingStatus === SlotRapidMappingStatus.NotStarted
    );
  }

  return false;
};

const hasNewKeyMapped = (
  prevRapidMappingData: NodeMappingResponse | undefined,
  rapidMappingData: NodeMappingResponse
) => {
  if (!prevRapidMappingData) return false;
  return rapidMappingData.keysStats.mappedCount - prevRapidMappingData.keysStats.mappedCount === 1;
};

const RapidMappingPage = () => {
  const browserHistory = useHistory();
  const { locationId } = useParams<{ locationId: string }>();
  const [mappingNode, setMappingNode] = useState<MappingNodeType>();
  const [externalError, setExternalError] = useState<ApolloError>();
  const [rapidMappingData, setRapidMappingData] = useState<NodeMappingResponse>();
  const [roomFullyMapped, setRoomFullyMapped] = useState<boolean>(false);
  const [keyFullyMapped, setKeyFullyMapped] = useState<boolean>(false);
  const prevRapidMappingData = usePreviousDistinct(
    rapidMappingData,
    (prev, next) => (prev && JSON.stringify(prev)) === JSON.stringify(next)
  );

  const { loading, error } = useRapidMappingQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data?.rapidMapping) {
        setRapidMappingData(data.rapidMapping);
      }
    },
  });

  const [skipRapidMapping] = useSkipRapidMappingMutation({
    onCompleted: (data) => {
      setRapidMappingData(data.skipRapidMapping);
      setExternalError(undefined);
    },
  });

  const currentSlot = useMemo(() => {
    const slots: Array<any> = get(rapidMappingData, "currentRapidMappingKey.currentRapidMappingRoom.slots", []);
    return get(
      slots.filter(({ isCurrentRapidMappingSlot }) => isCurrentRapidMappingSlot),
      "0"
    );
  }, [rapidMappingData]);

  const [rapidMap] = useRapidMapMutation({
    onCompleted: (data) => {
      if (data.mapNode) {
        displaySuccessNotification(mappingNode!);
        const isRoomFullyMappedStatus = isRoomFullyMapped(rapidMappingData, data.mapNode);
        setRoomFullyMapped(isRoomFullyMappedStatus);
        setKeyFullyMapped(isRoomFullyMappedStatus && hasNewKeyMapped(rapidMappingData, data.mapNode));
        setRapidMappingData(data.mapNode);
        setExternalError(undefined);
      }
    },
    onError: (err) => {
      setExternalError(err);
    },
  });

  useEffect(() => {
    const rapidMapping = roomFullyMapped && prevRapidMappingData ? prevRapidMappingData : rapidMappingData;

    if (rapidMapping && !rapidMapping.currentRapidMappingKey) {
      displayExitingNotification(rapidMapping.locationName);
      browserHistory.push(`/locations/${locationId}`);
    }
  }, [roomFullyMapped, prevRapidMappingData, rapidMappingData, browserHistory, locationId]);

  const submitRapidMap = (input: string) => {
    setMappingNode({
      nodeId: input.substring(2),
      nodeType: currentSlot.nodeType,
      nodeSubType: currentSlot.nodeSubType,
    });

    rapidMap({
      variables: {
        roomId: rapidMappingData!.currentRapidMappingKey!.currentRapidMappingRoom!.roomId,
        nodeType: currentSlot.nodeType,
        slotName: currentSlot.slotName,
        nodeSubType: currentSlot.nodeSubType,
        nodeMacInput: input,
      },
    });
  };

  const skipNode = () => {
    const roomId = rapidMappingData?.currentRapidMappingKey?.currentRapidMappingRoom?.roomId || "";
    const slotName = currentSlot?.slotName;
    const nodeType = currentSlot?.nodeType;
    skipRapidMapping({ variables: { roomId, slotName, nodeType } });
  };

  const continueScanning = () => {
    setRoomFullyMapped(false);
    setKeyFullyMapped(false);
  };

  /*
   * The reason we choose to use prevRapidMappingData or up-to-date rapidMappingData is that
   * in the common cases, the rapidMappingData is used, but when the room is fully mapped, the screen stays
   * for 30s before going ahead. In that 30s period, we use prevRapidMappingData to display the information
   * There is an exception for KeysStats as the value should always use the up-to-date value
   */
  const rapidMapping = roomFullyMapped && prevRapidMappingData ? prevRapidMappingData : rapidMappingData;
  const currentRoomSlots = get(rapidMapping, "currentRapidMappingKey.currentRapidMappingRoom.slots", []).map(
    (slot: any) => ({
      ...slot,
      mappingStatus: roomFullyMapped ? SlotRapidMappingStatus.Mapped : slot.mappingStatus,
    })
  );

  if (loading) return <p>Loading...</p>;
  if (error) {
    return (
      <Alert type="error" message={`Error getting rapid mapping information of ${locationId}`}>
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <Row justify="space-between" className="rapid-mapping-header">
        <Col span={16}>
          <h2 className="header-text">Rapid Mapping</h2>
          <p className="header-subtext">{rapidMapping?.locationName}</p>
        </Col>
        <Col span={8} md={6} lg={5}>
          <Link to={`/locations/${locationId}/keys`}>
            <Button type="primary" danger className="exit-button">
              Exit Rapid Mapping Mode
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="content-left" data-testid="current-room-content-left">
          <CurrentRoomInfo
            keyName={rapidMapping?.currentRapidMappingKey?.keyName}
            roomName={rapidMapping?.currentRapidMappingKey?.currentRapidMappingRoom?.roomName}
            categoryName={rapidMapping?.currentRapidMappingKey?.categoryName}
          />
          <Divider type="horizontal" />
          <CurrentRoomNodeSlotList slots={currentRoomSlots} />
        </Col>
        <Col span={12} className="content-center">
          {roomFullyMapped && (
            <FullyMappedComponent
              isKeyFullyMapped={keyFullyMapped}
              roomId={rapidMapping?.currentRapidMappingKey?.currentRapidMappingRoom?.roomId}
              roomName={rapidMapping?.currentRapidMappingKey?.currentRapidMappingRoom?.roomName}
              keyId={rapidMapping?.currentRapidMappingKey?.keyId}
              keyName={rapidMapping?.currentRapidMappingKey?.keyName}
              continueScanning={continueScanning}
            />
          )}
          {!roomFullyMapped && currentSlot && (
            <NodeMapping
              roomId={rapidMapping!.currentRapidMappingKey!.currentRapidMappingRoom!.roomId}
              nodeTypeCodeIdentifier={currentSlot.nodeTypeCodeIdentifier}
              nodeSubTypeCode={get(NodeTypeDisplay[currentSlot.nodeSubType], "code")}
              slotName={currentSlot.slotName}
              nodeMapSubmit={submitRapidMap}
              skipNode={skipNode}
              externalError={getExternalErrorComponent(externalError, mappingNode?.nodeId)}
              nodeType={currentSlot.nodeType}
            />
          )}
        </Col>
        <Col span={6} className="content-right">
          {rapidMappingData && rapidMapping && (
            <div className="content-right-section">
              <Space size={20} direction="vertical" className="content-right-section-body">
                <KeysStats
                  mappedCount={rapidMappingData.keysStats.mappedCount}
                  totalCount={rapidMappingData.keysStats.totalCount}
                />
                {rapidMapping.nextRapidMappingKey && (
                  <NextRoomInfo
                    hasNextKey={rapidMapping.currentRapidMappingKey?.keyId !== rapidMapping.nextRapidMappingKey?.keyId}
                    nextKeyName={rapidMapping.nextRapidMappingKey?.keyName}
                    nextRoomName={rapidMapping.nextRapidMappingKey?.nextRapidMappingRoom?.roomName}
                    nextRoomSlots={rapidMapping.nextRapidMappingKey?.nextRapidMappingRoom?.slots}
                  />
                )}
              </Space>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RapidMappingPage;
