import { Button, Form, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ModalWrapperProps } from "components/Modal/Modal";
import { useUpdateTestRunNoteMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useState } from "react";
import { FormTestNote, TestRun } from "./Checklist.d";

export interface TestNoteModalProps {
  testRun: TestRun;
}

const TroubleshootingGuideModal = ({
  testRun,
  modalWrapperProps,
  closeModal,
}: TestNoteModalProps & ModalWrapperProps) => {
  const [form] = Form.useForm<FormTestNote>();
  const [oldNote] = useState<FormTestNote>({
    note: testRun?.note,
  });

  const [updateTestRunNote] = useUpdateTestRunNoteMutation({
    onCompleted: () => {
      message.success("Note Saved");
      closeModal();
    },
  });

  const handleSaveNote = () => {
    updateTestRunNote({
      variables: {
        testRunId: testRun?.testRunId,
        note: form.getFieldValue("note"),
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue(oldNote);
  }, [form, oldNote]);

  return (
    <Modal
      title="Notes"
      centered
      wrapClassName="vh-100-modal"
      width={450}
      footer={[
        <Button key="save" type="primary" className="mb-s" onClick={() => handleSaveNote()}>
          Save
        </Button>,
      ]}
      {...modalWrapperProps}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name={["note"]}>
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TroubleshootingGuideModal;
