import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { FilterDropdownProps } from "./FilterDropdown.d";

const FilterDropdown = (props: FilterDropdownProps) => {
  const { component, name, onClear, onApply } = props;
  const [visible, setVisible] = useState(false);

  const handleClear = () => {
    onClear();
  };

  const handleApply = () => {
    onApply();
    setVisible(false);
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <div className="px-m py-s">{component}</div>
          <Menu.Divider />
          <div className="px-m py-xs text-right">
            <Button onClick={handleClear}>Clear</Button>
            <Button type="primary" className="ml-s" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Menu>
      }
      trigger={["click"]}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Button>{name}</Button>
    </Dropdown>
  );
};

export default FilterDropdown;
