import { Col, Divider, Row } from "antd";
import classnames from "classnames";
import { AntdTablePagination } from "components/Table/Table.d";
import TableV2 from "components/Table/TableV2";
import useBreakpoint from "hooks/use-breakpoint";
import { useTableSortByColumn } from "hooks/use-table-sort-by-cols";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import { getBookingListColumnConfig } from "./BookingListColumnConfig";
import { BookingRecord, IBookingListQueryFilter } from "./types";

interface IBookingListTableProps {
  onBookingChosen: (booking: BookingRecord) => void;
  onPaginationChanged: (pagination: AntdTablePagination) => void;
  data: BookingRecord[];
  loading?: boolean;
  error?: any;
  pagination: AntdTablePagination;
  location?: LocationWithPmsProperty;
  sortUtil: ReturnType<typeof useTableSortByColumn>;
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void;
  filter?: IBookingListQueryFilter;
}

interface IBookingListTableMobileRowProps {
  columns: { title: string; render: (record: BookingRecord) => JSX.Element; visibleOnMobile?: boolean }[];
  data: BookingRecord;
  order: number;
  onRow: (record: BookingRecord) => Record<string, any>;
  rowClassName: Record<string, boolean>;
}

const BookingListTableMobileRow = ({ columns, data, order, onRow, rowClassName }: IBookingListTableMobileRowProps) => {
  return (
    <div
      className={classnames({
        ...rowClassName,
        "bg-white": order % 2 === 1,
        "bg-light-gray": order % 2 === 0,
      })}
      {...onRow(data)}
    >
      <div className="px-m py-s">
        <small>Booking ID</small>
        <p className="font-weight-bold">{data.id}</p>
      </div>
      <Divider className="my-xs" />
      <div className="p-s">
        <ul className="px-xl">
          {columns
            .filter((c) => !!c.visibleOnMobile)
            .map((c) => (
              <li key={c.title}>
                <Row className="p-xs" justify="space-between">
                  <Col>{c.title}</Col>
                  <Col>{c.render(data)}</Col>
                </Row>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

const BookingListTable = ({
  onBookingChosen,
  onPaginationChanged,
  onChange,
  data,
  pagination,
  location,
  error,
  loading,
  sortUtil,
  filter,
}: IBookingListTableProps) => {
  const screen = useBreakpoint();
  const columns = React.useMemo(
    () => getBookingListColumnConfig({ location, sortUtil, screen, filter }),
    [location, sortUtil, screen, filter]
  );

  const genRowProps = React.useCallback(
    (record: BookingRecord) => {
      return {
        "data-testid": "booking-list-row",
        onClick: () => onBookingChosen(record),
      };
    },
    [onBookingChosen]
  );

  const genRowClassNames = React.useCallback(
    () => ({
      "cursor-pointer": true,
    }),
    []
  );

  const renderMobileRow = React.useCallback(
    (record: BookingRecord, order: number) => {
      return (
        <BookingListTableMobileRow
          columns={columns}
          data={record}
          order={order}
          rowClassName={genRowClassNames()}
          onRow={genRowProps}
        />
      );
    },
    [genRowProps, genRowClassNames, columns]
  );

  return (
    <TableV2
      columns={columns}
      onPaginationChanged={onPaginationChanged}
      onChange={onChange}
      tableData={data}
      pagination={pagination}
      loading={loading}
      error={error}
      rowKey="id"
      dataItemName="booking"
      getDisplayText={(p) => `of ${p.total} bookings`}
      rowClassName={genRowClassNames}
      onRow={genRowProps}
      renderMobileRow={renderMobileRow as any}
      getMobileDisplayText={(p) => `${Math.min(p.pageSize, p.total)}/${p.total} bookings`}
    />
  );
};

export default BookingListTable;
