export default [
  {
    nodeMacId: "FFFF14B4577AC3EE1",
    status: "online",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE1",
    status: "offline",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE3",
    status: "booted",
  },
  {
    nodeMacId: "FFFF14B4577AC3EE4",
    status: "booted",
  },
];
