import React from "react";
import { render } from "@testing-library/react";
import { Router, Route } from "react-router-dom";
import { createMemoryHistory } from "history";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import get from "lodash/get";

import isNil from "lodash/isNil";
import { isEmpty } from "lodash";
import { IntercomProvider } from "react-use-intercom";
import getRules, { UserWithAuthToken, ROLE } from "./rbac-rules";
import userWithAccessTokens from "./services/graphql/mock-data/userWithAuthTokens.json";
import { getDevMockAuthService } from "./services/auth/mockAuthService";
import useBreakpoint from "./hooks/use-breakpoint";

const { provider: MockAuthProvider } = getDevMockAuthService();

export type RenderWithAuthAndRouterOptions = {
  route?: string;
  path?: string;
  history?: any;
  overrideLoginStatusTo?: boolean;
  mockIntercomProvider?: boolean;
};

export function renderWithAuthAndRouter(
  ui: React.ReactElement,
  {
    route = "/",
    path = "/",
    history = createMemoryHistory({ initialEntries: [route] }),
    overrideLoginStatusTo = true,
    mockIntercomProvider = false,
  }: RenderWithAuthAndRouterOptions = {}
) {
  const Wrapper = ({ children }: { children?: React.ReactNode }) =>
    mockIntercomProvider ? (
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}>
        <MockAuthProvider overrideLoginStatusTo={overrideLoginStatusTo}>
          <Router history={history}>
            <Route path={path}>{children}</Route>
          </Router>
        </MockAuthProvider>
      </IntercomProvider>
    ) : (
      <MockAuthProvider overrideLoginStatusTo={overrideLoginStatusTo}>
        <Router history={history}>
          <Route path={path}>{children}</Route>
        </Router>
      </MockAuthProvider>
    );
  // make typescript happy as the children prop is optional in render from @testing-library/react
  Wrapper.defaultProps = { children: null };
  return {
    ...render(ui, { wrapper: Wrapper }),
    // adding `history` to the returned utilities to allow us
    // to reference it in our tests (just try to avoid using
    // this to test implementation details).
    history,
  };
}

/* eslint-disable */
export class LocalStorageMock {
  store: any = {};
  setItem = (key: string, val: any) => (this.store[key] = val);
  getItem = (key: string) => this.store[key];
  removeItem = (key: string) => {
    delete this.store[key];
  };
  clear = () => (this.store = {});
}
/* eslint-enable */

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function sortLocation(locations: any[], sort: any = {}) {
  const { locationName, mappingStatus } = sort;

  if (locationName) {
    return orderBy(locations, ["locationName"], [toLower(locationName) === "asc" ? "asc" : "desc"]);
  }
  if (mappingStatus) {
    return orderBy(locations, ["locationStats.mappingStatus"], [toLower(mappingStatus) === "asc" ? "asc" : "desc"]);
  }

  return orderBy(locations, ["locationName"], ["asc"]);
}

export function sortKey(keys: any[], sort: any = {}) {
  const { keyName, categoryName, mappingStatus } = sort;

  if (keyName) {
    return orderBy(keys, ["keyName"], [toLower(keyName) === "asc" ? "asc" : "desc"]);
  }
  if (categoryName) {
    return orderBy(keys, ["categoryName"], [toLower(categoryName) === "asc" ? "asc" : "desc"]);
  }
  if (mappingStatus) {
    return orderBy(keys, ["keyStats.mappingStatus"], [toLower(mappingStatus) === "asc" ? "asc" : "desc"]);
  }

  return orderBy(keys, ["keyName"], ["asc"]);
}

export function filterKey(keys: any[], filter: any = {}) {
  const { keyName, mappingStatus, categoryName, keyIds } = filter;

  let filteredKeys = keys;

  if (keyName) {
    filteredKeys = filteredKeys.filter((key) => includes(key.keyName.toLowerCase(), keyName.toLowerCase()));
  }
  if (mappingStatus?.length) {
    filteredKeys = filteredKeys.filter((key) => includes(mappingStatus, key.keyStats.mappingStatus));
  }
  if (categoryName?.length) {
    filteredKeys = filteredKeys.filter((key) => includes(categoryName, key.categoryName));
  }

  if (keyIds?.length) {
    filteredKeys = filteredKeys.filter((key) => keyIds.indexOf(key.keyId) > -1);
  }

  return filteredKeys;
}

const filterGteLteValue = (filterItem: any, currentValue: number) => {
  if (!filterItem) return true;

  if (isNil(currentValue)) return false;

  if (filterItem._gte) {
    return currentValue >= filterItem._gte;
  }

  if (filterItem._lte) {
    return currentValue <= filterItem._lte;
  }

  return true;
};

export function filterNodes(nodes: any[], where: any = {}) {
  const { firmwareVersion, signalStrength, recentJoinCount } = get(where, "node.nodeJoinStatus", {});
  const { nodeStatus } = get(where, "node.nodeOnlineStatus", {});
  const { nodeType } = where;
  const nodeMacId = get(where, "_or[0].nodeMacId");
  const roomName = get(where, "_or[1].position.positionName");
  const keyName = get(where, "_or[2].position.parentPosition.positionName");
  const gatewayName = get(where, "_or[3].node.gateway.gatewayName");
  const locationId = get(where, "position.location.locationId");
  const nodeSubType = get(where, "node.nodeSubType");
  const firmwareVersionLocked = get(where, "node.firmwareVersionLocked");
  const targetFwVersion = get(where, "node.targetFirmwareRelease.versionNumber");
  const currentFwVersion = get(where, "node.currentFirmwareRelease.versionNumber");
  const filterByUpgradeStatus = get(where, "node._or");

  return nodes
    .filter((node) => !nodeType || nodeType._in.includes(node.nodeType))
    .filter((node) => !nodeStatus || nodeStatus._in.includes(node.node.nodeOnlineStatus.nodeStatus))
    .filter(
      (node) =>
        !firmwareVersion ||
        node.node.nodeJoinStatus.firmwareVersion?.includes(firmwareVersion._ilike?.replace(/%/g, ""))
    )
    .filter((node) => filterGteLteValue(signalStrength, node.node.nodeJoinStatus.signalStrength))
    .filter((node) => filterGteLteValue(recentJoinCount, node.node.nodeJoinStatus.recentJoinCount))
    .filter((node) => {
      if (!nodeMacId && !roomName && !keyName && !gatewayName) {
        return true;
      }
      const isNodeMacIdValid = nodeMacId && node.nodeMacId.includes(nodeMacId._ilike?.replace(/%/g, ""));
      const isGatewayNameValid =
        gatewayName && node.node?.gateway?.gatewayName.includes(gatewayName._ilike?.replace(/%/g, ""));
      const isRoomNameValid = roomName && node.position?.positionName?.includes(roomName._ilike?.replace(/%/g, ""));
      const isKeyNameValid =
        keyName && node.position?.parentPosition?.positionName?.includes(keyName._ilike?.replace(/%/g, ""));
      return isNodeMacIdValid || isRoomNameValid || isKeyNameValid || isGatewayNameValid;
    })
    .filter((node) => !locationId || locationId._in.includes(node.position.location.locationId))
    .filter((node) => !nodeSubType || nodeSubType._eq === node.node.nodeSubType)
    .filter((node) => {
      if (!filterByUpgradeStatus) {
        return true;
      }
      if (filterByUpgradeStatus[0]?.nodeFirmware?.upgradeStatus?._in.includes(node.node.nodeFirmware?.upgradeStatus)) {
        return true;
      }
      return filterByUpgradeStatus[1]?._not?.nodeFirmware && isEmpty(node.node.nodeFirmware);
    })
    .filter((node) => !firmwareVersionLocked || firmwareVersionLocked._eq === Boolean(node.node.firmwareVersionLocked))
    .filter((node) => !targetFwVersion || targetFwVersion._eq === node.node.targetFirmwareRelease?.versionNumber)
    .filter((node) => !currentFwVersion || currentFwVersion._eq === node.node.currentFirmwareRelease?.versionNumber);
}

export function sortNodes(nodes: any[], sort: any = {}) {
  const sortKeys = [
    "node.nodeJoinStatus.lastSeen",
    "position.parentPosition.positionName",
    "node.nodeJoinStatus.signalStrength",
    "node.nodeJoinStatus.recentJoinCount",
    "node.nodeJoinStatus.bootTime",
    "node.gateway.gatewayName",
    "mappedTime",
    "position.positionName",
    "node.targetFirmwareRelease.versionName",
    "node.currentFirmwareRelease.versionName",
    "node.otaFailedUpgradeAttempts",
    "node.otaComment",
    "node.nodeFirmware.upgradeStatus",
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of sortKeys) {
    const value = get(sort, key);
    if (value) {
      return orderBy(nodes, [key], [toLower(value) === "asc" ? "asc" : "desc"]);
    }
  }
  return nodes;
}

export function filterLocation(locations: any[], filter: any = {}) {
  const { locationName, mappingStatus } = filter;

  let filteredLocations = locations;

  if (locationName) {
    filteredLocations = filteredLocations.filter((location) =>
      includes(location.locationName.toLowerCase(), locationName.toLowerCase())
    );
  }
  if (mappingStatus?.length) {
    filteredLocations = filteredLocations.filter((location) =>
      includes(mappingStatus, location.locationStats.mappingStatus)
    );
  }

  return filteredLocations;
}

const desktopBreakpoint = {
  mobileOnly: false,
  mobileUp: true,
  tabletOnly: false,
  tabletUp: true,
  desktopOny: true,
  desktopUp: true,
  mobileAndTabletOnly: false,
  classes: {
    hiddenOnMobile: "",
    hiddenOnDesktop: "d-none",
  },
};

const mobileBreakpoint = {
  mobileOnly: true,
  mobileUp: true,
  tabletOnly: false,
  tabletUp: true,
  desktopOny: false,
  desktopUp: false,
  mobileAndTabletOnly: true,
  classes: {
    hiddenOnMobile: "d-none",
    hiddenOnDesktop: "",
  },
};

export const useDesktopBreakpoint = () => {
  (useBreakpoint as jest.Mock).mockImplementation(() => desktopBreakpoint);
};

export const useMobileBreakpoint = () => {
  (useBreakpoint as jest.Mock).mockImplementation(() => mobileBreakpoint);
};

export const warehouseUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) => userWithAccessToken.role.name === ROLE.WAREHOUSE_STAFF
)[0].userWithAccessToken;
export const warehouseRole = getRules(warehouseUser as UserWithAuthToken);

export const supportUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) => userWithAccessToken.role.name === ROLE.SUPPORT_SPECIALIST
)[0].userWithAccessToken;
export const supportRole = getRules(supportUser as UserWithAuthToken);

export const pcUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) => userWithAccessToken.role.name === ROLE.PROJECT_COORDINATOR
)[0].userWithAccessToken;
export const pcRole = getRules(pcUser as UserWithAuthToken);

export const installerUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) =>
    userWithAccessToken.role.name === ROLE.INSTALLER && userWithAccessToken.isContractor === false
)[0].userWithAccessToken;
export const installerRole = getRules(installerUser as UserWithAuthToken);

export const contractorUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) =>
    userWithAccessToken.role.name === ROLE.INSTALLER && userWithAccessToken.isContractor === true
)[0].userWithAccessToken;
export const contractorRole = getRules(installerUser as UserWithAuthToken);

export const customerUser = userWithAccessTokens.filter(
  ({ userWithAccessToken }) => userWithAccessToken.role.name === ROLE.CUSTOMER
)[0].userWithAccessToken;
export const customerRole = getRules(customerUser as UserWithAuthToken);
