import { Select, Switch } from "antd";
import { useGroupsQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { GroupType } from "pages/Groups/Components/GroupModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const NO_GROUP_FILTER_KEY = "NO_GROUP";

interface IGroupFilterProps {
  locationId: string;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const GroupFilter = ({ locationId, value, onChange }: IGroupFilterProps) => {
  const [groupOptions, setGroupOptions] = useState<{ value: string; label: string }[]>([]);
  const [searchKey, setSearchKey] = useState<string | undefined>();

  const groupIds = useMemo(() => {
    return value?.filter((g) => g !== NO_GROUP_FILTER_KEY) ?? [];
  }, [value]);

  const inNoGroup = useMemo(() => value?.includes(NO_GROUP_FILTER_KEY) ?? false, [value]);

  const [groupIdFilters, setGroupIdFilters] = useState<string[]>(value ?? []);

  const handleSelectChange = useCallback((grpIds: string[]) => {
    setGroupIdFilters((filters) => {
      return filters.filter((f) => f === NO_GROUP_FILTER_KEY).concat(grpIds);
    });
  }, []);

  const onToggle = useCallback((showInNoGroup) => {
    setGroupIdFilters((filters) => {
      const newF = filters.filter((f) => f !== NO_GROUP_FILTER_KEY);
      if (showInNoGroup) {
        newF.push(NO_GROUP_FILTER_KEY);
      }
      return newF;
    });
  }, []);

  useEffect(() => {
    onChange?.(groupIdFilters);
  }, [groupIdFilters, onChange]);

  useGroupsQuery({
    variables: {
      where: {
        locationId: {
          _eq: locationId,
        },
        ...(searchKey
          ? {
              positionName: {
                _ilike: `%${searchKey}%`,
              },
            }
          : {}),
        positionType: {
          _in: Object.values(GroupType),
        },
      },
      offset: 0,
      limit: 15,
    },
    onCompleted(data) {
      if (data.sensorflow_v_group_with_stats) {
        setGroupOptions(
          data.sensorflow_v_group_with_stats.map((g) => ({
            label: g.positionName || "",
            value: g.positionId,
          }))
        );
      }
    },
  });

  return (
    <>
      <div>
        <Select
          value={groupIds}
          onChange={handleSelectChange}
          showSearch
          onSearch={setSearchKey}
          mode="multiple"
          placeholder="Type to search locations"
          notFoundContent="No category found"
          options={groupOptions}
          autoClearSearchValue={false}
          onBlur={() => setSearchKey("")}
          filterOption
        />
      </div>
      <div className="pt-m">
        <span className="font-weight-bold mr-s">Show keys without group</span>
        <Switch checked={inNoGroup} onChange={onToggle} />
      </div>
    </>
  );
};

export default GroupFilter;
