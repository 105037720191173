import classNames from "classnames";
import { useTableSortByColumn } from "hooks/use-table-sort-by-cols";
import React from "react";
import { LocationWithPmsProperty } from "types/types";
import BookingStatusIcon from "../components/BookingStatusIcon";
import BookingTimeDisplay from "../components/BookingTimeDisplay";
import { BookingStatus } from "../constants";
import { getBookingStatus } from "./helpers";
import { BookingRecord, IBookingListQueryFilter } from "./types";

interface IGetBookingListColumnConfigInput {
  location?: LocationWithPmsProperty;
  sortUtil?: ReturnType<typeof useTableSortByColumn>;
  screen?: { mobileOnly?: boolean };
  filter?: IBookingListQueryFilter;
}

export const getBookingListColumnConfig = ({
  location,
  sortUtil,
  screen,
  filter,
}: IGetBookingListColumnConfigInput) => {
  return [
    {
      title: "Booking ID",
      render: (record: BookingRecord) => <span className="font-weight-bold">{`#${record.id}`}</span>,
      visibleOnDetailView: false,
      ...sortUtil?.getSortConfig("reservation_id"),
    },
    {
      title: "SmartAlloc Recommended",
      render: (record: BookingRecord) => (
        <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>
          {record.recommendedRoom?.name || "-"}
        </span>
      ),
      visibleOnDetailView: true,
      visibleOnMobile: true,
    },
    {
      title: "Room Category",
      render: (record: BookingRecord) => (
        <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>{record.category?.name || "-"}</span>
      ),
      ...sortUtil?.getSortConfig("pms_room_category.room_category_name"),
      visibleOnDetailView: true,
      visibleOnMobile: true,
    },
    {
      title: "Status",
      key: "status",
      render: (record: BookingRecord) => <BookingStatusIcon status={getBookingStatus(record)} screen={screen} />,
      visibleOnMobile: true,
      visibleOnDetailView: false,
      filteredValue: filter ? filter.statuses : [],
      filters: [
        {
          text: <BookingStatusIcon status={BookingStatus.AWAIT_CHECKING_IN} screen={screen} />,
          value: BookingStatus.AWAIT_CHECKING_IN,
        },
        {
          text: <BookingStatusIcon status={BookingStatus.CHECKED_IN} screen={screen} />,
          value: BookingStatus.CHECKED_IN,
        },
        {
          text: <BookingStatusIcon status={BookingStatus.CHECKED_OUT} screen={screen} />,
          value: BookingStatus.CHECKED_OUT,
        },
      ],
    },
    {
      title: "Check-in date",
      render: (record: BookingRecord) => (
        <BookingTimeDisplay dateStr={record.checkInAt} location={location} screen={screen} />
      ),
      ...sortUtil?.getSortConfig("check_in_date"),
      visibleOnMobile: true,
      visibleOnDetailView: true,
    },
    {
      title: "Check-out date",
      render: (record: BookingRecord) => (
        <BookingTimeDisplay dateStr={record.checkOutAt} location={location} screen={screen} />
      ),
      ...sortUtil?.getSortConfig("check_out_date"),
      visibleOnMobile: true,
      visibleOnDetailView: true,
    },
    {
      title: "Final Allocated",
      render: (record: BookingRecord) => {
        const status = getBookingStatus(record);
        if (status && [BookingStatus.CHECKED_IN, BookingStatus.CHECKED_OUT].includes(status)) {
          return (
            <span
              className={classNames({
                "font-weight-bold": screen?.mobileOnly,
              })}
            >
              {record.allocatedRoom?.name || "-"}
            </span>
          );
        }
        return <span>-</span>;
      },
      visibleOnMobile: true,
      visibleOnDetailView: true,
      ...sortUtil?.getSortConfig("pms_room_to_reservation_mapping.room_name"),
    },
    {
      title: "Reason",
      render: () => <span className={classNames({ "font-weight-bold": screen?.mobileOnly })}>-</span>,
      visibleOnMobile: true,
      visibleOnDetailView: true,
    },
    {
      title: "Last Updated",
      render: (record: BookingRecord) => (
        <BookingTimeDisplay dateStr={record.lastUpdatedAt} location={location} screen={screen} />
      ),
      visibleOnMobile: true,
      visibleOnDetailView: true,
      ...sortUtil?.getSortConfig("modified_date"),
    },
  ];
};

export default getBookingListColumnConfig;
