import React, { useContext } from "react";
import { Col, Collapse, Row } from "antd";
import classNames from "classnames";
import { CaretRightOutlined, CopyFilled, DeleteFilled, EditFilled, EditOutlined } from "@ant-design/icons";
import CopyButton from "components/CopyButton";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { MappedNodeDataType } from "../InfrastructureDetails.d";
import InfrastructureDetailsContext from "../InfrastructureDetails.Context";
import { MappedNodesProps } from "./MappedNodes.d";
import "./MappedNodes.scss";
import useLocationDateTime from "../../../hooks/use-location-datetime";
import ActionButton from "../../../components/ActionButton";

const CollapseHeader = (mappedNode: MappedNodeDataType) => {
  const { formatFromNow } = useLocationDateTime();
  const { status, lastSeen, signalStrength } = mappedNode;
  return (
    <Row className="collapse-header">
      <Col span={6} className="node-status-block">
        <div className={classNames("node-status", status)}>
          <span>{status}</span>
        </div>
      </Col>
      <Col span={10} className="data-section">
        <p className="title">last seen</p>
        <p>{formatFromNow(lastSeen)}</p>
      </Col>
      <Col span={8} className="data-section">
        <p className="title">signal strength</p>
        <p>{signalStrength}</p>
      </Col>
    </Row>
  );
};

const MappedNodesGrid = ({
  dataSource,
  handleAdjustMappedNode,
  handleUnmapNode,
  startChangeGateway,
}: MappedNodesProps) => {
  const infraDetails = useContext(InfrastructureDetailsContext);
  const infraType = new InfrastructureType(infraDetails?.type!);

  const { formatDateTime } = useLocationDateTime();

  return (
    <div className="node-mapped-grid">
      {dataSource.map((data: MappedNodeDataType, index: number) => (
        <div className="node-mapped" key={index}>
          <Row justify="space-between">
            <Col className="data-section">
              <p className="title">slot name</p>
              <span>{data.slotName}</span>
            </Col>
            <Col className="actions">
              <EditFilled onClick={() => handleAdjustMappedNode(data)} />
              <DeleteFilled onClick={() => handleUnmapNode(data.nodeMacId)} />
            </Col>
          </Row>
          <Row>
            <Col span={12} className="data-section">
              <p className="title">node id</p>
              <div>
                <span>{data.nodeMacId}</span>
                <CopyButton input={data.nodeMacId} icon={<CopyFilled />} />
              </div>
            </Col>
            <Col span={9} className="data-section">
              <p className="title">node type</p>
              <p>{data.nodeTypeLabel}</p>
            </Col>
          </Row>
          <Row>
            <Collapse
              bordered={false}
              className="collapse"
              expandIcon={({ isActive }) => <CaretRightOutlined className="collapse-icon" rotate={isActive ? 90 : 0} />}
            >
              <Collapse.Panel key={index} header={<CollapseHeader {...data} />}>
                <Row className="content" justify="space-between">
                  <Col span={10} className="data-section">
                    <p className="title">associated gateway</p>
                    <div className="d-flex align-items-center">
                      {data?.mappedGateway?.gatewayName}
                      <ActionButton
                        btnClassName="p-none"
                        customIcon={<EditOutlined className="ml-s" />}
                        onClick={() => startChangeGateway(data.positionId)}
                      />
                    </div>
                  </Col>
                  {infraType.isCompressor() && (
                    <Col span={10} className="data-section">
                      <p className="title">settings</p>
                      <p>{data.settingsLabel}</p>
                    </Col>
                  )}
                  <Row>
                    <Col className="data-section">
                      <p className="title">mapped on</p>
                      <p>{formatDateTime(data.mappedTime)}</p>
                    </Col>
                  </Row>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default MappedNodesGrid;
