import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  TimePicker,
} from "antd";
import { FormInstance } from "antd/es/form";
import classnames from "classnames";
import { QuestionIcon } from "components/Icons";
import useBreakpoint from "hooks/use-breakpoint";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import { isNil, range, some } from "lodash";
import { Moment } from "moment";
import { ConfigurationToolTip } from "pages/Key/KeyConfigurationList/KeyConfigurationList.helper";
import React, { useEffect, useState } from "react";
import { EFeatureFlags, POSITION_CONFIGURATION_DEFAULT } from "utils/constants";
import { TIME_24H_FORMAT, formatDateTime24h, formatTime24h } from "utils/date";
import { AvailableOperationalModes } from "utils/locationMetadata";
import {
  MAX_TEMP,
  MIN_TEMP,
  NSI_MAX_TEMP,
  NSI_MAX_TEMP_RELATIVE_INCREASE,
  NSI_MIN_TEMP_RELATIVE_INCREASE,
  formatTemp,
  getAutomationModeText,
  isAutomate24h,
} from "../../key.helper";
import { ActingMode, AutomationMode, FormPositionConfiguration, OperationalMode } from "../../types";
import { HideSetpointToggle } from "./HideSetpointToggle";
import { RoomContainer } from "./KeyConfiguration.d";
import styles from "./KeyConfigurationRoomForm.module.scss";

const { Panel } = Collapse;

const CheckboxWrapper = (props: { propName: string; showCheckBox?: boolean; onCheckboxChange: any; children: any }) => {
  const { children, showCheckBox, propName, onCheckboxChange } = props;
  return (
    <div className="d-flex justify-content-start w-100">
      {showCheckBox && (
        <div className="pr-s">
          <Form.Item noStyle name={propName} valuePropName="checked">
            <Checkbox onChange={onCheckboxChange} />
          </Form.Item>
        </div>
      )}
      <div className="w-100">{children}</div>
    </div>
  );
};

const TimeToExpired = (props: { expiredAt?: string | null; isDesktop?: boolean; onCancelButtonClick?: () => void }) => {
  const { expiredAt, isDesktop, onCancelButtonClick } = props;
  return (
    <>
      {expiredAt && (
        <Row gutter={15} className={styles.timer} data-testid="time-to-expired">
          <Col className="w-100">
            <div className={classnames({ "align-items-center d-flex": isDesktop })}>
              <span className={classnames({ "d-flex align-content-start w-80": isDesktop })}>
                The configuration will be reset to default on {formatDateTime24h(expiredAt)}
              </span>
              <div
                className={classnames({
                  "w-100 pt-s": !isDesktop,
                  "mr-s ml-auto": isDesktop,
                })}
              >
                <Button type="default" onClick={onCancelButtonClick}>
                  Reset now
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const KeyConfigurationRoomForm = (props: {
  form: FormInstance;
  mobileMode?: boolean;
  showCheckBox?: boolean;
  onSelect?: any;
  currentConfig?: FormPositionConfiguration;
  room?: RoomContainer;
  showHeatingMode?: boolean;
  isAutomationModeDisabled?: boolean;
  isBulkConfiguration?: boolean;
  isFailedSaving?: boolean;
  availableOperationalModes?: AvailableOperationalModes;
  setIsFailedSaving?: (value: boolean) => void;
  onCancelButtonClick?: () => void;
}) => {
  const {
    mobileMode,
    showCheckBox,
    form,
    onSelect,
    currentConfig,
    room,
    showHeatingMode,
    isAutomationModeDisabled,
    isBulkConfiguration,
    isFailedSaving,
    availableOperationalModes,
    setIsFailedSaving,
    onCancelButtonClick,
  } = props;
  const [selectedAutomationMode, setSelectedAutomationMode] = useState<any>();
  const [activeHourNextDay, setActiveHourNextDay] = useState<string>();
  const [automationHourNextDay, setAutomationHourNextDay] = useState<string>();
  const [hideSetpoint, setHideSetpoint] = useState<boolean>(room?.config.hideSetpoint ?? true);
  const [minTemp, setMinTemp] = useState<number>(MIN_TEMP);
  const [maxTemp, setMaxTemp] = useState<number>(MAX_TEMP);
  const [heatingHideSetpoint, setHeatingHideSetpoint] = useState<boolean>(room?.config.heatingHideSetpoint ?? true);
  const [heatingMinTemp, setHeatingMinTemp] = useState<number>(MIN_TEMP);
  const [heatingMaxTemp, setHeatingMaxTemp] = useState<number>(MAX_TEMP);
  const [operationalMode, setOperationalMode] = useState<string>(OperationalMode.Cooling);
  const [activeAdvancedSection, setActiveAdvancedSection] = useState<string[]>([]);
  const [activeNigthtineSetpointSection, setActiveNigthtineSetpointSection] = useState<string[]>([]);
  const [doorSensorAutomation, setDoorSensorAutomation] = useState<boolean>();
  const [nsiEnabled, setNSIEnabled] = useState<boolean>();

  const { isPC } = useRoleAndPermission();

  const { location } = useSensorflowLocation();

  const syncNextDayState = (field: string, val: any) => {
    const values = val as [Moment, Moment];
    let isNextDay = false;
    let is24h = false;
    if (values && values.length > 1) {
      isNextDay = values[1].isBefore(values[0]);
      is24h = isAutomate24h(formatTime24h(values[1]), formatTime24h(values[0]));
    }
    let helpText;
    if (isNextDay) {
      helpText = "Next day";
    } else if (is24h) {
      helpText = "24 hours";
    }
    if (field === "activeHours") {
      setActiveHourNextDay(helpText);
    } else if (field === "automationHours") {
      setAutomationHourNextDay(helpText);
    }
  };

  useEffect(() => {
    const defaultHideSetpoint = !!room?.isMappingWithPFC;
    if (currentConfig) {
      setSelectedAutomationMode(currentConfig.automationMode);
      setMinTemp(isNil(currentConfig.minTemp) ? MIN_TEMP : currentConfig.minTemp);
      setMaxTemp(isNil(currentConfig.maxTemp) ? MIN_TEMP : currentConfig.maxTemp);
      setHideSetpoint(currentConfig.hideSetpoint ?? defaultHideSetpoint);
      setHeatingMinTemp(isNil(currentConfig.heatingMinTemp) ? MIN_TEMP : currentConfig.heatingMinTemp);
      setHeatingMaxTemp(isNil(currentConfig.heatingMaxTemp) ? MIN_TEMP : currentConfig.heatingMaxTemp);
      setHeatingHideSetpoint(currentConfig.heatingHideSetpoint ?? defaultHideSetpoint);
      syncNextDayState("activeHours", currentConfig.activeHours);
      syncNextDayState("automationHours", currentConfig.automationHours);
      setOperationalMode(currentConfig.operationalMode ?? OperationalMode.Cooling);
      setDoorSensorAutomation(currentConfig.enableDoorSensor ?? true);
      setNSIEnabled(currentConfig.nightTimeSetpointEnabled ?? false);
    }
  }, [currentConfig, room]);

  const screen = useBreakpoint();

  const isDesktop = () => {
    if (mobileMode) return false;
    return screen.desktopUp;
  };

  const fieldLgSize = isDesktop() ? 8 : 24;

  const checkboxKeys = [
    "heatingUnoccupiedTempChecked",
    "unoccupiedTempChecked",
    "automationModeChecked",
    "hideSetpointChecked",
    "heatingHideSetpointChecked",
    "setpointChecked",
    "heatingSetpointChecked",
    "automationHoursChecked",
    "activeHoursChecked",
    "occTimeoutDayMinsChecked",
    "occTimeoutNightMinsChecked",
    "freezeProtectionTempChecked",
    "actuationTimeoutSecondsChecked",
    "operationalModeChecked",
    "doorSensorTimeoutMinsChecked",
    "doorSensorAutomationChecked",
    "nightTimeSetpointStartEndChecked",
    "nightTimeSetpointOffsetChecked",
    "nightTimeSetpointMaxSetpointChecked",
    "nightTimeSetpointEnabledChecked",
    "forceOccupancyAutomationChecked",
  ];

  const onCheckboxChange = () => {
    if (onSelect) {
      onSelect(some(checkboxKeys, (k) => form.getFieldValue(k)));
    }
  };

  const onSelectAll = (e: any) => {
    const checkboxStatus = e.target.checked;
    checkboxKeys.forEach((field) => form.setFieldsValue({ [field]: checkboxStatus }));
    onCheckboxChange();
  };

  const getDefaultValue = (text: string | null) => {
    return <div className="text-gray mt-xs">{`Default is ${text ?? "empty"}`}</div>;
  };

  const getHeatingSetpointLimitDefaultText = () => {
    return `${formatTemp(room?.defaultConfig?.heatingMinTemp)} Min, ${formatTemp(
      room?.defaultConfig?.heatingMaxTemp
    )} Max and setpoint limits will be ${room?.defaultConfig?.heatingHideSetpoint ? "hidden" : "visible"}`;
  };

  const getSetpointLimitDefaultText = () => {
    return `${formatTemp(room?.defaultConfig?.minTemp)} Min, ${formatTemp(
      room?.defaultConfig?.maxTemp
    )} Max and setpoint limits will be ${room?.defaultConfig?.hideSetpoint ? "hidden" : "visible"}`;
  };

  const limitMinTemp = (value?: string | number) => {
    let newMinTemp: number;
    if (isNil(value)) {
      newMinTemp = MIN_TEMP;
    } else {
      newMinTemp = Math.min(parseInt(value.toString()), maxTemp - 1);
      newMinTemp = Math.max(newMinTemp, MIN_TEMP);
    }
    setMinTemp(newMinTemp);
    form.setFieldsValue({
      ...form,
      minTemp: newMinTemp,
    });
  };

  const limitHeatingMinTemp = (value?: string | number) => {
    let newMinTemp: number;
    if (isNil(value)) {
      newMinTemp = MIN_TEMP;
      setHeatingMinTemp(MIN_TEMP);
    } else {
      newMinTemp = Math.min(parseInt(value.toString()), heatingMaxTemp);
      newMinTemp = Math.max(newMinTemp, MIN_TEMP);
    }
    setHeatingMinTemp(newMinTemp);
    form.setFieldsValue({
      ...form,
      heatingMinTemp: newMinTemp,
    });
  };

  const limitMaxTemp = (value?: string | number) => {
    let newMaxTemp: number;
    if (isNil(value)) {
      newMaxTemp = MAX_TEMP;
    } else {
      newMaxTemp = Math.max(parseInt(value.toString()), minTemp + 1);
      newMaxTemp = Math.min(newMaxTemp, MAX_TEMP);
    }
    setMaxTemp(newMaxTemp);
    form.setFieldsValue({
      ...form,
      maxTemp: newMaxTemp,
    });
  };

  const limitHeatingMaxTemp = (value?: string | number) => {
    let newMaxTemp: number;
    if (isNil(value)) {
      newMaxTemp = MAX_TEMP;
    } else {
      newMaxTemp = Math.max(parseInt(value.toString()), heatingMinTemp);
      newMaxTemp = Math.min(newMaxTemp, MAX_TEMP);
      setHeatingMaxTemp(newMaxTemp);
    }
    setHeatingMaxTemp(newMaxTemp);
    form.setFieldsValue({
      ...form,
      heatingMaxTemp: newMaxTemp,
    });
  };

  const handleNSITimeStartEndChange = (value: any) => {
    form.setFieldsValue({
      ...form,
      nightTimeSetpointStart: formatTime24h(value[0]),
      nightTimeSetpointEnd: formatTime24h(value[1]),
    });
  };

  const handleNSIOffsetChange = (value: number) => {
    form.setFieldsValue({
      ...form,
      nightTimeSetpointOffset: value,
    });
  };

  const handleNSIMaxSetpointChange = (value: string | number) => {
    form.setFieldsValue({
      ...form,
      nightTimeSetpointMaxSetpoint: value,
    });
  };

  const onClickAdvancedHeader = (value: string[]) => {
    if (value.length === 0 && setIsFailedSaving) {
      setIsFailedSaving(false);
    }
    setActiveAdvancedSection(value);
  };

  const onClickNighttimeSetpointHeader = (value: string[]) => {
    if (value.length === 0 && setIsFailedSaving) {
      setIsFailedSaving(false);
    }
    setActiveNigthtineSetpointSection(value);
  };

  useEffect(() => {
    if (isFailedSaving) {
      setActiveAdvancedSection(["1"]);
    }
  }, [isFailedSaving]);

  useEffect(() => {
    switch (availableOperationalModes) {
      case AvailableOperationalModes.Heating: {
        setOperationalMode(OperationalMode.Heating);
        form.setFieldsValue({
          ...form,
          operationalMode: OperationalMode.Heating,
        });
        break;
      }
      case AvailableOperationalModes.Cooling: {
        setOperationalMode(OperationalMode.Cooling);
        form.setFieldsValue({
          ...form,
          operationalMode: OperationalMode.Cooling,
        });
        break;
      }
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableOperationalModes]);

  return (
    <>
      {showCheckBox && (
        <div className="px-l py-m bg-light-gray">
          <CheckboxWrapper propName="allChecked" onCheckboxChange={onSelectAll} showCheckBox={showCheckBox}>
            <span>Select all</span>
          </CheckboxWrapper>
        </div>
      )}
      <div className="p-l">
        <TimeToExpired
          expiredAt={room?.config?.expiredAt}
          isDesktop={isDesktop()}
          onCancelButtonClick={onCancelButtonClick}
        />
        <Row gutter={15}>
          <Col lg={fieldLgSize} xs={24}>
            <Row gutter={15}>
              <Col xs={24}>
                <CheckboxWrapper
                  propName="automationModeChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s">
                    <span>Automation Mode</span>&nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.AutomationMode} />
                  </div>
                  {isDesktop() && (
                    <Form.Item noStyle name="automationMode">
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(val) => setSelectedAutomationMode(val.target.value)}
                        disabled={isAutomationModeDisabled}
                      >
                        <Radio.Button value={AutomationMode.SuperSave}>SuperSave</Radio.Button>
                        <Radio.Button value={AutomationMode.SmartSave}>SmartSave</Radio.Button>
                        <Radio.Button value={AutomationMode.Disabled}>Disabled</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {!isDesktop() && (
                    <Form.Item noStyle name="automationMode">
                      <Select
                        className="w-50"
                        onChange={(val) => setSelectedAutomationMode(val)}
                        disabled={isAutomationModeDisabled}
                      >
                        <Select.Option value={AutomationMode.SuperSave}>SuperSave</Select.Option>
                        <Select.Option value={AutomationMode.SmartSave}>SmartSave</Select.Option>
                        <Select.Option value={AutomationMode.Disabled}>Disabled</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                  {room?.defaultConfig?.automationMode &&
                    getDefaultValue(getAutomationModeText(room?.defaultConfig?.automationMode))}
                </CheckboxWrapper>
              </Col>
              {selectedAutomationMode === AutomationMode.SmartSave && (
                <Col className="mt-xl" xs={24}>
                  <CheckboxWrapper
                    propName="unoccupiedTempChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s">
                      <span>Unoccupied Setpoint</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.UnoccupiedSetpoint} />
                    </div>
                    <Form.Item>
                      <Form.Item
                        noStyle
                        name="unoccupiedTemp"
                        rules={[{ required: selectedAutomationMode === AutomationMode.SmartSave }]}
                      >
                        <InputNumber
                          min={
                            (location?.enabledFeatures ?? []).includes(EFeatureFlags.SmartSaveUnoccupiedLimit)
                              ? Math.max(minTemp, 25)
                              : minTemp
                          }
                          max={maxTemp}
                          placeholder="26°C"
                          formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                          parser={(value) => (value ? value.replace("°C", "") : 0)}
                          data-testid="input-unoccupied-setpoint-testId"
                          disabled={isAutomationModeDisabled}
                        />
                      </Form.Item>
                      {getDefaultValue(
                        !isNil(room?.defaultConfig?.unoccupiedTemp)
                          ? formatTemp(room?.defaultConfig?.unoccupiedTemp)
                          : formatTemp(POSITION_CONFIGURATION_DEFAULT.unoccupiedTemp)
                      )}
                    </Form.Item>
                  </CheckboxWrapper>
                </Col>
              )}
              {showHeatingMode && selectedAutomationMode === AutomationMode.SmartSave && (
                <Col className="mt-xl" xs={24}>
                  <CheckboxWrapper
                    propName="heatingUnoccupiedTempChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s">
                      <span>Heating Unoccupied Setpoint</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.HeatingUnoccupiedSetpoint} />
                    </div>
                    <Form.Item>
                      <Form.Item
                        noStyle
                        name="heatingUnoccupiedTemp"
                        rules={[{ required: selectedAutomationMode === AutomationMode.SmartSave }]}
                      >
                        <InputNumber
                          min={heatingMinTemp}
                          max={heatingMaxTemp}
                          placeholder="16°C"
                          formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                          parser={(value) => (value ? value.replace("°C", "") : 0)}
                          data-testid="input-heating-unoccupied-setpoint-testId"
                          disabled={isAutomationModeDisabled}
                        />
                      </Form.Item>
                      {getDefaultValue(
                        !isNil(room?.defaultConfig?.heatingUnoccupiedTemp)
                          ? formatTemp(room?.defaultConfig?.heatingUnoccupiedTemp)
                          : formatTemp(POSITION_CONFIGURATION_DEFAULT.heatingUnoccupiedTemp)
                      )}
                    </Form.Item>
                  </CheckboxWrapper>
                </Col>
              )}
              {showHeatingMode && (room?.config?.actingMode === ActingMode.TwoPFC || isBulkConfiguration) && (
                <Col className="mt-xl" xs={24}>
                  <CheckboxWrapper
                    propName="operationalModeChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s">
                      <span>Operational mode</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.OperationalMode} />
                    </div>
                    <Form.Item noStyle name="operationalMode">
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => setOperationalMode(e.target.value)}
                        defaultValue={operationalMode}
                      >
                        <Radio.Button
                          disabled={availableOperationalModes === AvailableOperationalModes.Heating}
                          value={OperationalMode.Cooling}
                        >
                          Cooling
                        </Radio.Button>
                        <Radio.Button
                          disabled={availableOperationalModes === AvailableOperationalModes.Cooling}
                          value={OperationalMode.Heating}
                        >
                          Heating
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </CheckboxWrapper>
                </Col>
              )}
              <Col className="mt-xl" xs={24}>
                <CheckboxWrapper
                  propName="doorSensorAutomationChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s">
                    <span>Door Sensor Automation</span>&nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.DoorSensorAutomation} />
                  </div>
                  <Form.Item noStyle name="enableDoorSensor">
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(e) => setDoorSensorAutomation(e.target.value)}
                      defaultValue={doorSensorAutomation}
                    >
                      <Radio.Button value>On</Radio.Button>
                      <Radio.Button value={false}>Off</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </CheckboxWrapper>
              </Col>
            </Row>
          </Col>
          <Divider className={classnames("my-l", { "d-none": isDesktop() })} />
          {!isAutomationModeDisabled && (
            <Col lg={fieldLgSize} xs={24}>
              <div>
                <CheckboxWrapper
                  propName="setpointChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s">
                    <span>Setpoint Limits</span>&nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.SetpointLimits} />
                  </div>
                  <div className={classnames("d-flex justify-content-start align-items-center", styles.setPointLimit)}>
                    <span className="d-block fs-sm mr-xs">Min</span>
                    <span className="mr-xs">
                      <Form.Item noStyle name="minTemp">
                        <InputNumber
                          min={MIN_TEMP}
                          max={maxTemp - 1}
                          placeholder="16°C"
                          onChange={(value) => limitMinTemp(value ?? 0)}
                          className={styles.setpointInlineInput}
                          formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                          parser={(value) => (value ? value.replace("°C", "") : 0)}
                          data-testid="input-min-setpoint-testId"
                        />
                      </Form.Item>
                    </span>
                    <span className="mr-xs">
                      <Form.Item noStyle name="maxTemp">
                        <InputNumber
                          min={minTemp + 1}
                          max={MAX_TEMP}
                          placeholder="32°C"
                          onChange={(value) => limitMaxTemp(value ?? 0)}
                          className={styles.setpointInlineInput}
                          formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                          parser={(value) => (value ? value.replace("°C", "") : 0)}
                          data-testid="input-max-setpoint-testId"
                        />
                      </Form.Item>
                    </span>
                    <span className="d-block fs-sm">Max</span>
                    {room?.isMappingWithPFC && isPC() && (
                      <Form.Item name="hideSetpoint" valuePropName="hideSetpoint" trigger="toggleHideSetpoint" noStyle>
                        <HideSetpointToggle />
                      </Form.Item>
                    )}
                  </div>
                  {getDefaultValue(
                    !isNil(room?.defaultConfig?.minTemp) && !isNil(room?.defaultConfig?.maxTemp)
                      ? getSetpointLimitDefaultText()
                      : `${formatTemp(POSITION_CONFIGURATION_DEFAULT.minTemp)} Min, ${formatTemp(
                          POSITION_CONFIGURATION_DEFAULT.maxTemp
                        )} Max`
                  )}
                </CheckboxWrapper>
                <Form.Item name="hideSetpoint" valuePropName="checked" className="d-none">
                  <Switch className="ml-xs" checked={hideSetpoint} />
                </Form.Item>
              </div>
              {showHeatingMode && (
                <div className="mt-xl">
                  <CheckboxWrapper
                    propName="heatingSetpointChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s">
                      <span>Heating Setpoint Limits</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.HeatingSetpointLimits} />
                    </div>
                    <div
                      className={classnames("d-flex justify-content-start align-items-center", styles.setPointLimit)}
                    >
                      <span className="d-block fs-sm mr-xs">Min</span>
                      <span className="mr-xs">
                        <Form.Item noStyle name="heatingMinTemp">
                          <InputNumber
                            min={MIN_TEMP}
                            max={heatingMaxTemp - 1}
                            placeholder="16°C"
                            onChange={(value) => limitHeatingMinTemp(value ?? 0)}
                            className={styles.setpointInlineInput}
                            formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                            parser={(value) => (value ? value.replace("°C", "") : 0)}
                            data-testid="input-heating-min-setpoint-testId"
                          />
                        </Form.Item>
                      </span>
                      <span className="mr-xs">
                        <Form.Item noStyle name="heatingMaxTemp">
                          <InputNumber
                            min={heatingMinTemp + 1}
                            max={MAX_TEMP}
                            placeholder="32°C"
                            onChange={(value) => limitHeatingMaxTemp(value ?? 0)}
                            className={styles.setpointInlineInput}
                            formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                            parser={(value) => (value ? value.replace("°C", "") : 0)}
                            data-testid="input-heating-max-setpoint-testId"
                          />
                        </Form.Item>
                      </span>
                      <span className="d-block fs-sm">Max</span>
                      {room?.isMappingWithPFC && isPC() && (
                        <Form.Item
                          name="heatingHideSetpoint"
                          valuePropName="hideSetpoint"
                          trigger="toggleHideSetpoint"
                          noStyle
                        >
                          <HideSetpointToggle />
                        </Form.Item>
                      )}
                    </div>
                    {getDefaultValue(
                      !isNil(room?.defaultConfig?.heatingMinTemp) && !isNil(room?.defaultConfig?.heatingMaxTemp)
                        ? getHeatingSetpointLimitDefaultText()
                        : `${formatTemp(POSITION_CONFIGURATION_DEFAULT.heatingMinTemp)} Min, ${formatTemp(
                            POSITION_CONFIGURATION_DEFAULT.heatingMaxTemp
                          )}`
                    )}
                  </CheckboxWrapper>
                  <Form.Item name="heatingHideSetpoint" valuePropName="checked" className="d-none">
                    <Switch className="ml-xs" checked={heatingHideSetpoint} />
                  </Form.Item>
                </div>
              )}
            </Col>
          )}
          <Divider className={classnames("my-l", { "d-none": isDesktop() })} />
          <Col lg={fieldLgSize} xs={24}>
            <CheckboxWrapper
              propName="automationHoursChecked"
              onCheckboxChange={onCheckboxChange}
              showCheckBox={showCheckBox}
            >
              <div>
                <div className="mb-s">
                  <span>Automation Hours</span>&nbsp;
                  <QuestionIcon tooltip={ConfigurationToolTip.AutomationHours} />
                </div>
                <div className="d-flex align-items-center">
                  <Form.Item name="automationHours" rules={[{ required: true }]} className="mb-none">
                    <TimePicker.RangePicker
                      order={false}
                      format={TIME_24H_FORMAT}
                      allowClear={false}
                      onChange={(val) => syncNextDayState("automationHours", val)}
                      disabled={isAutomationModeDisabled}
                    />
                  </Form.Item>
                  {automationHourNextDay && <span className="text-warning ml-xs">{automationHourNextDay}</span>}
                </div>
                {getDefaultValue(
                  !isNil(room?.defaultConfig?.acNightEnd) && !isNil(room?.defaultConfig?.acNightStart)
                    ? `${room?.defaultConfig?.acNightEnd} - ${room?.defaultConfig?.acNightStart}`
                    : `${POSITION_CONFIGURATION_DEFAULT.acNightEnd} - ${POSITION_CONFIGURATION_DEFAULT.acNightStart}`
                )}
              </div>
            </CheckboxWrapper>
            <div className="mb-xl" />
          </Col>
        </Row>
      </div>
      <div>
        <Collapse
          activeKey={activeAdvancedSection}
          className="bg-white"
          destroyInactivePanel
          onChange={(value) => onClickAdvancedHeader(value as string[])}
        >
          <Panel header="Advanced" key="1" className="no-padding-collapsible-tab" forceRender>
            <Row className="p-l">
              <Col lg={fieldLgSize} xs={24}>
                <CheckboxWrapper
                  propName="activeHoursChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s">
                    <span>Active Hours</span>&nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.ActiveHours} />
                  </div>
                  <div className="mb-m">
                    <Form.Item className="mb-none">
                      <div className="d-flex align-items-center ">
                        <Form.Item noStyle name="activeHours" rules={[{ required: true }]}>
                          <TimePicker.RangePicker
                            order={false}
                            format={TIME_24H_FORMAT}
                            allowClear={false}
                            className={styles.timepicker}
                            onChange={(val) => syncNextDayState("activeHours", val)}
                            disabled={isAutomationModeDisabled}
                          />
                        </Form.Item>
                        {activeHourNextDay && <span className="text-warning ml-xs">{activeHourNextDay}</span>}
                      </div>
                      {getDefaultValue(
                        !isNil(room?.defaultConfig?.occNightEnd) && !isNil(room?.defaultConfig?.occNightStart)
                          ? `${room?.defaultConfig?.occNightEnd} - ${room?.defaultConfig?.occNightStart}`
                          : `${POSITION_CONFIGURATION_DEFAULT.occNightEnd} - ${POSITION_CONFIGURATION_DEFAULT.occNightStart}`
                      )}
                    </Form.Item>
                  </div>
                </CheckboxWrapper>
              </Col>
              <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
              <Col lg={fieldLgSize} xs={24}>
                <CheckboxWrapper
                  propName="occTimeoutDayMinsChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s d-flex align-content-start">
                    <span className="pr-xs">
                      Occupancy Timeout <span className="font-weight-bold">During</span> Active Hours
                    </span>
                    &nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.OccupancyTimeoutDuringActiveHours} />
                  </div>
                  <Form.Item>
                    <Form.Item noStyle name="occTimeoutDayMins" rules={[{ required: true }]}>
                      <InputNumber
                        min="0"
                        placeholder="25 mins"
                        formatter={(value) => (value ? `${parseInt(value.toString())} mins` : "")}
                        parser={(value) => (value ? value.replace(" mins", "") : 0)}
                        data-testid="input-occupancy-timeout-during-testId"
                        disabled={isAutomationModeDisabled}
                      />
                    </Form.Item>
                    {getDefaultValue(
                      !isNil(room?.defaultConfig?.occTimeoutDayMins)
                        ? `${room?.defaultConfig?.occTimeoutDayMins} mins`
                        : `${POSITION_CONFIGURATION_DEFAULT.occTimeoutDayMins} mins`
                    )}
                  </Form.Item>
                </CheckboxWrapper>
              </Col>
              <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
              <Col lg={fieldLgSize} xs={24}>
                <CheckboxWrapper
                  propName="occTimeoutNightMinsChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s d-flex align-content-start">
                    <span className="pr-xs">
                      Occupancy Timeout <span className="font-weight-bold">Outside</span> Active Hours
                    </span>
                    &nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.OccupancyTimeoutOutsideActiveHours} />
                  </div>
                  <Form.Item>
                    <Form.Item noStyle name="occTimeoutNightMins" rules={[{ required: true }]}>
                      <InputNumber
                        min="0"
                        placeholder="25mins"
                        formatter={(value) => (value ? `${parseInt(value.toString())} mins` : "")}
                        parser={(value) => (value ? value.replace(" mins", "") : 0)}
                        data-testid="input-occupancy-timeout-outside-testId"
                        disabled={isAutomationModeDisabled}
                      />
                    </Form.Item>
                    {getDefaultValue(
                      !isNil(room?.defaultConfig?.occTimeoutNightMins)
                        ? `${room?.defaultConfig?.occTimeoutNightMins} mins`
                        : `${POSITION_CONFIGURATION_DEFAULT.occTimeoutNightMins} mins`
                    )}
                  </Form.Item>
                </CheckboxWrapper>
              </Col>
            </Row>
            <Row className="p-l">
              {showHeatingMode && (
                <Col lg={fieldLgSize} xs={24}>
                  <CheckboxWrapper
                    propName="freezeProtectionTempChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s">
                      <span>Auto Freeze Protection Base Limit</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.FreezeProtectionTemp} />
                    </div>
                    <div className="mb-m">
                      <Form.Item>
                        <Form.Item noStyle name="freezeProtectionTemp" rules={[{ required: true }]}>
                          <Select
                            className={classnames({ "w-25": screen.desktopUp, "w-35": !screen.desktopUp })}
                            disabled={isAutomationModeDisabled}
                          >
                            {range(0, 33).map((n) => (
                              <Select.Option key={n} value={n}>
                                {formatTemp(n, "")}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {getDefaultValue(
                          !isNil(room?.defaultConfig?.freezeProtectionTemp)
                            ? formatTemp(room?.defaultConfig?.freezeProtectionTemp)
                            : formatTemp(POSITION_CONFIGURATION_DEFAULT.freezeProtectionTemp)
                        )}
                      </Form.Item>
                    </div>
                  </CheckboxWrapper>
                </Col>
              )}
              <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
              <Col lg={fieldLgSize} xs={24}>
                <CheckboxWrapper
                  propName="actuationTimeoutSecondsChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s d-flex align-content-start">
                    <span className="pr-xs">Cycles per Hour Control</span>&nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.ActuationTimeoutSeconds} />
                  </div>
                  <Form.Item>
                    <Form.Item noStyle name="actuationTimeoutSeconds" rules={[{ required: true }]}>
                      <InputNumber
                        min="0"
                        placeholder="300"
                        data-testid="input-occupancy-timeout-during-testId"
                        disabled={isAutomationModeDisabled}
                      />
                    </Form.Item>
                    {getDefaultValue(
                      !isNil(room?.defaultConfig?.actuationTimeoutSeconds)
                        ? `${room?.defaultConfig?.actuationTimeoutSeconds}`
                        : `${POSITION_CONFIGURATION_DEFAULT.actuationTimeoutSeconds}`
                    )}
                  </Form.Item>
                </CheckboxWrapper>
              </Col>
              <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
              <Col lg={fieldLgSize} xs={24}>
                <CheckboxWrapper
                  propName="doorSensorTimeoutMinsChecked"
                  onCheckboxChange={onCheckboxChange}
                  showCheckBox={showCheckBox}
                >
                  <div className="mb-s d-flex align-content-start">
                    <span className="pr-xs">Door Sensor Timeout</span>
                    &nbsp;
                    <QuestionIcon tooltip={ConfigurationToolTip.DoorSensorTimeoutMins} />
                  </div>
                  <Form.Item>
                    <Form.Item noStyle name="doorSensorTimeoutMins" rules={[{ required: true }]}>
                      <InputNumber
                        min="0"
                        placeholder="25 mins"
                        formatter={(value) => (value ? `${parseInt(value.toString())} mins` : "")}
                        parser={(value) => (value ? value.replace(" mins", "") : 0)}
                        data-testid="input-doorSensor-timeout-during-testId"
                        disabled={isAutomationModeDisabled}
                      />
                    </Form.Item>
                    {getDefaultValue(
                      !isNil(room?.defaultConfig?.doorSensorTimeoutMins)
                        ? `${room?.defaultConfig?.doorSensorTimeoutMins} mins`
                        : `${POSITION_CONFIGURATION_DEFAULT.doorSensorTimeoutMins} min ${
                            POSITION_CONFIGURATION_DEFAULT.doorSensorTimeoutMins === 1 ? "" : "s"
                          }`
                    )}
                  </Form.Item>
                </CheckboxWrapper>
              </Col>
              {isPC() && (
                <Col lg={fieldLgSize} xs={24}>
                  <CheckboxWrapper
                    propName="forceOccupancyAutomationChecked"
                    onCheckboxChange={onCheckboxChange}
                    showCheckBox={showCheckBox}
                  >
                    <div className="mb-s d-flex align-content-start">
                      <span className="pr-xs">Force Occupancy Automation</span>&nbsp;
                      <QuestionIcon tooltip={ConfigurationToolTip.ForceOccupancyAutomation} />
                    </div>
                    <Form.Item
                      noStyle
                      name="forceOccupancyAutomation"
                      valuePropName="checked"
                      rules={[{ required: true }]}
                    >
                      <Switch
                        className="mr-xs"
                        onChange={(value) => setForceOccupancyAutomation(value)}
                        data-testid="forceOccupancyAutomation"
                      />
                    </Form.Item>
                    <span>Activate</span>&nbsp;
                  </CheckboxWrapper>
                  {getDefaultValue(room?.defaultConfig?.forceOccupancyAutomation ? "Activated" : "Deactivated")}
                </Col>
              )}
            </Row>
          </Panel>
        </Collapse>
      </div>
      {(location?.enabledFeatures ?? []).includes(EFeatureFlags.NighttimeSetpoint) &&
        (room?.isMappingWithPFC || room?.isMappingWithDaikin || isBulkConfiguration) && (
          <div>
            <Collapse
              activeKey={activeNigthtineSetpointSection}
              className="bg-white"
              destroyInactivePanel
              onChange={(value) => onClickNighttimeSetpointHeader(value as string[])}
            >
              <Panel header="Nighttime setpoint increase" key="1" className="no-padding-collapsible-tab" forceRender>
                <Row className="p-l">
                  <Col lg={fieldLgSize} xs={24}>
                    <CheckboxWrapper
                      propName="nightTimeSetpointEnabledChecked"
                      onCheckboxChange={onCheckboxChange}
                      showCheckBox={showCheckBox}
                    >
                      <Form.Item
                        noStyle
                        name="nightTimeSetpointEnabled"
                        valuePropName="checked"
                        rules={[{ required: true }]}
                      >
                        <Switch
                          className="mr-xs"
                          onChange={(value) => setNSIEnabled(value)}
                          disabled={currentConfig?.operationalMode === OperationalMode.Heating}
                          data-testid="nightTimeSetpointEnabled"
                        />
                      </Form.Item>
                      <span>Activate</span>&nbsp;
                    </CheckboxWrapper>
                  </Col>
                  <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
                </Row>
                <Row className="p-l">
                  <Col lg={fieldLgSize} xs={24}>
                    <CheckboxWrapper
                      propName="nightTimeSetpointStartEndChecked"
                      onCheckboxChange={onCheckboxChange}
                      showCheckBox={showCheckBox}
                    >
                      <div className="mb-s">
                        <span>Time start & end</span>&nbsp;
                      </div>
                      <div className="mb-m">
                        <Form.Item className="mb-none">
                          <div className="d-flex align-items-center ">
                            <Form.Item noStyle name="nightTimeSetpointTimeStartEnd" rules={[{ required: true }]}>
                              <TimePicker.RangePicker
                                order={false}
                                format={TIME_24H_FORMAT}
                                allowClear={false}
                                className={styles.timepicker}
                                onChange={(val) => handleNSITimeStartEndChange(val)}
                                disabled={currentConfig?.operationalMode === OperationalMode.Heating || !nsiEnabled}
                                data-testid="nightTimeSetpointStartEnd"
                              />
                            </Form.Item>
                            {activeHourNextDay && <span className="text-warning ml-xs">{activeHourNextDay}</span>}
                          </div>
                          {getDefaultValue(
                            !isNil(room?.defaultConfig?.nightTimeSetpointEnd) &&
                              !isNil(room?.defaultConfig?.nightTimeSetpointStart)
                              ? `${room?.defaultConfig?.nightTimeSetpointStart} - ${room?.defaultConfig?.nightTimeSetpointEnd}`
                              : `${POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointStart} - ${POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointEnd}`
                          )}
                        </Form.Item>
                      </div>
                    </CheckboxWrapper>
                  </Col>
                  <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
                  <Col lg={fieldLgSize} xs={24}>
                    <CheckboxWrapper
                      propName="nightTimeSetpointOffsetChecked"
                      onCheckboxChange={onCheckboxChange}
                      showCheckBox={showCheckBox}
                    >
                      <div className="mb-s d-flex align-content-start">
                        <span className="pr-xs">Maximum relative increase</span>
                        <QuestionIcon tooltip={ConfigurationToolTip.NightTimeSetpointMaximumRelativeIncrease} />
                      </div>
                      <Form.Item>
                        <Form.Item noStyle name="nightTimeSetpointOffset">
                          <InputNumber
                            min={NSI_MIN_TEMP_RELATIVE_INCREASE}
                            max={NSI_MAX_TEMP_RELATIVE_INCREASE}
                            onChange={(value) => handleNSIOffsetChange(value ?? 0)}
                            className={styles.setpointInlineInput}
                            formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                            parser={(value) => (value ? value.replace("°C", "") : 0)}
                            disabled={currentConfig?.operationalMode === OperationalMode.Heating || !nsiEnabled}
                            data-testid="nightTimeSetpointOffset"
                          />
                        </Form.Item>
                        {getDefaultValue(
                          !isNil(room?.defaultConfig?.nightTimeSetpointOffset)
                            ? formatTemp(room?.defaultConfig?.nightTimeSetpointOffset)
                            : formatTemp(POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointOffset)
                        )}
                      </Form.Item>
                    </CheckboxWrapper>
                  </Col>
                  <Divider className={classnames("mt-xs mb-l", { "d-none": isDesktop() })} />
                  <Col lg={fieldLgSize} xs={24}>
                    <CheckboxWrapper
                      propName="nightTimeSetpointMaxSetpointChecked"
                      onCheckboxChange={onCheckboxChange}
                      showCheckBox={showCheckBox}
                    >
                      <div className="mb-s d-flex align-content-start">
                        <span className="pr-xs">Absolute maximum setpoint</span>
                        &nbsp;
                        <QuestionIcon tooltip={ConfigurationToolTip.NightTimeSetpointAbsoluteMaximumTemperature} />
                      </div>
                      <Form.Item>
                        <Form.Item noStyle name="nightTimeSetpointMaxSetpoint">
                          <InputNumber
                            min={currentConfig?.minTemp ?? MIN_TEMP}
                            max={Math.min(currentConfig?.maxTemp ?? MAX_TEMP, NSI_MAX_TEMP)}
                            onChange={(value) => handleNSIMaxSetpointChange(value ?? 0)}
                            className={styles.setpointInlineInput}
                            formatter={(value) => (value ? formatTemp(parseInt(value.toString())) : "")}
                            parser={(value) => (value ? value.replace("°C", "") : 0)}
                            disabled={currentConfig?.operationalMode === OperationalMode.Heating || !nsiEnabled}
                            data-testid="nightTimeSetpointMaxSetpoint"
                          />
                        </Form.Item>
                        {getDefaultValue(
                          !isNil(room?.defaultConfig?.nightTimeSetpointMaxSetpoint)
                            ? formatTemp(room?.defaultConfig?.nightTimeSetpointMaxSetpoint)
                            : formatTemp(POSITION_CONFIGURATION_DEFAULT.nightTimeSetpointMaxSetpoint)
                        )}
                      </Form.Item>
                    </CheckboxWrapper>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        )}
    </>
  );
};

export default KeyConfigurationRoomForm;
