import React, { useContext, useEffect, useState } from "react";
import { Input, message, Modal, Select } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import {
  GetInfrasByPositionMeterIdQuery,
  useEmonMapInputInfoLazyQuery,
  useGetInfrasByPositionMeterIdLazyQuery,
  useGetMeterPositionIdMappedNodeMacIdLazyQuery,
  useMapInfraNodeMutation,
  useSwapChannelsMutation,
  useValidateInfraNodeMappingLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import assign from "lodash/assign";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import InfrastructureDetailsContext from "pages/InfrastructureDetails/InfrastructureDetails.Context";
import { isNil } from "lodash";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import NodeInput, { formatNodeMacId } from "../NodeInput/NodeInput";
import NodeMacInput from "./NodeMacInput";
import { nodeTypes } from "../NodeInput/NodeInput.d";
import { getFullNodeMacInput } from "./MapNodeModal.helper";

interface MapNodeModalProps {
  reloadInfrastructure: () => void;
}

const MapNodeModal = ({
  modalWrapperProps,
  closeModal,
  reloadInfrastructure,
}: MapNodeModalProps & ModalWrapperProps) => {
  const infraDetails = useContext(InfrastructureDetailsContext);

  const { meterPositionId: contextMeterPositionId, id: contextInfraId } = infraDetails ?? { id: "" };

  const [nodeMacInput, setNodeMacInput] = useState<string>("");
  const [slotName, setSlotName] = useState<string>("");
  const [nodeInput, setNodeInput] = useState<any>();
  const [nodeType, setNodeType] = useState<any>();
  const [isValidNodeData, setValidNodeData] = useState<boolean>(false);
  const [isInvalidInputData, setInvalidInputData] = useState<boolean>();
  const [isInputChannelChanged, setIsInputChannelChanged] = useState<boolean>(false);
  const [infraChannels, setInfraChannels] = useState<GetInfrasByPositionMeterIdQuery["infrastructures"]>();
  const infraType = new InfrastructureType(infraDetails?.type);
  const [mapInputIsUsed, setMapInputIsUsed] = useState<boolean>(false);
  const [isNewMapping, setIsNewMapping] = useState<boolean>(false);
  const [meterPositionId, setMeterPositionId] = useState<string>();

  const infraNode = { infraId: infraDetails?.id!, nodeMacInput, slotName, nodeType };

  useEffect(() => {
    setNodeInput({
      ...infraNode,
      nodeMacInput: getFullNodeMacInput(infraNode.nodeMacInput, nodeType),
    });
    setInvalidInputData(some(infraNode, (data: any) => isEmpty(data)));
    if (infraDetails?.meterPositionId && !meterPositionId) {
      setMeterPositionId(contextMeterPositionId);
    }
    // eslint-disable-next-line
  }, [infraDetails, slotName, nodeMacInput, nodeType, setNodeInput, setInvalidInputData]);

  useEffect(() => {
    const nodeSlots = infraDetails?.meterPosition?.nodeSlots || [];
    const existingSlot = nodeSlots.find((slot) => slot.nodeType === nodeType);
    if (existingSlot) {
      setSlotName(existingSlot.slotName);
    }
  }, [nodeType, infraDetails]);

  const [reloadInfraChannels] = useGetInfrasByPositionMeterIdLazyQuery({
    onCompleted: (data) => {
      setInfraChannels(data.infrastructures);
    },
  });

  const [getMeterPosition] = useGetMeterPositionIdMappedNodeMacIdLazyQuery({
    onCompleted: async (data) => {
      if (data.sensorflow_node_to_slot_mapping.length > 0) {
        reloadInfraChannels({
          variables: {
            meterPositionId: data.sensorflow_node_to_slot_mapping[0].parentPositionId,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (meterPositionId) {
      reloadInfraChannels({
        variables: {
          meterPositionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meterPositionId]);

  const [emonMapInputInfo] = useEmonMapInputInfoLazyQuery({
    fetchPolicy: "no-cache",

    onCompleted: (data) => {
      if (
        nodeInput &&
        (nodeInput.mapInput || nodeInput.mapInput === 0) &&
        data.emonMapInputInfo.unavailableMapInputs.includes(nodeInput.mapInput)
      ) {
        setMapInputIsUsed(true);
      }
    },
  });

  useEffect(() => {
    if (isNewMapping && infraDetails) {
      const nodeMacId = formatNodeMacId(nodeInput.nodeMacInput);
      emonMapInputInfo({
        variables: {
          infraId: contextInfraId,
          nodeMacId,
        },
      });
      getMeterPosition({
        variables: {
          nodeMacId,
        },
      });
    }
  }, [nodeInput, isNewMapping, infraDetails, emonMapInputInfo, getMeterPosition, contextInfraId]);

  const [validateInfraNodeMapping, { loading: validateInfraNodeMappingLoading }] = useValidateInfraNodeMappingLazyQuery(
    {
      onCompleted: () => setValidNodeData(true),
      onError: errorHandler.handleError,
    }
  );

  const [mapInfraNode, { loading: mapInfraNodeLoading }] = useMapInfraNodeMutation({
    onCompleted: () => {
      message.success("Mapped node successful");
      closeModal();
      reloadInfrastructure();
    },
    onError: errorHandler.handleError,
  });

  const [swapChannels] = useSwapChannelsMutation({
    onCompleted: () => {
      message.success("Swap channels successfully.");
    },
    onError: errorHandler.handleError,
  });

  const handleConfirm = () => {
    if (!isValidNodeData) {
      validateInfraNodeMapping({
        variables: {
          ...infraNode,
          nodeMacInput: getFullNodeMacInput(infraNode.nodeMacInput, nodeType),
        },
      });
    } else {
      if (isInputChannelChanged) {
        if (infraType.isCompressor() && infraDetails) {
          const destinationCompressor = infraChannels?.find((infra) => infra.phaseStreamIndex === nodeInput.mapInput);
          if (destinationCompressor) {
            Modal.confirm({
              title: "Warning",
              content: `This channel is already in use by ${destinationCompressor?.name}. If you wish to proceed the current channel used by this compressor will be swapped with the channel of the compressor using the desired channel right now.`,
              okText: "Proceed",
              onOk: async () => {
                await swapChannels({
                  variables: {
                    startingCompressorId: contextInfraId,
                    destinationCompressorId: destinationCompressor?.id,
                  },
                });
                mapInfraNode({
                  variables: nodeInput,
                });
              },
            });
            return;
          }
        }
        Modal.confirm({
          title: "Warning",
          content:
            "If you are replacing an emon make sure you are mapping the new one to the same channel as the old one. Changing channels will disconnect past data that was recorded on a different channel.",
          okText: "Proceed",
          onOk: () => {
            mapInfraNode({
              variables: nodeInput,
            });
          },
        });
        return;
      }
      if (mapInputIsUsed && infraDetails) {
        const destinationCompressor = infraChannels?.find((infra) => infra.phaseStreamIndex === nodeInput.mapInput);
        if (destinationCompressor) {
          Modal.confirm({
            title: "Warning",
            content: `This channel is already in use by ${destinationCompressor?.name}. If you wish to proceed the current channel used by this compressor will be swapped with the channel of the compressor using the desired channel right now.`,
            okText: "Proceed",
            onOk: async () => {
              await swapChannels({
                variables: {
                  startingCompressorId: contextInfraId,
                  destinationCompressorId: destinationCompressor?.id,
                },
              });
              mapInfraNode({
                variables: nodeInput,
              });
            },
          });
          return;
        }
      }
      mapInfraNode({
        variables: nodeInput,
      });
    }
  };

  const handleInputChange = (
    inputChange: any,
    isInvalidData: boolean | undefined,
    isInputChanged: boolean | undefined,
    isNewMappingInfra: boolean | undefined
  ) => {
    setNodeInput(
      assign({ ...infraNode, nodeMacInput: getFullNodeMacInput(infraNode.nodeMacInput, nodeType) }, inputChange)
    );
    setInvalidInputData(isInvalidData);
    if (!isNil(isInputChanged)) {
      setIsInputChannelChanged(isInputChanged);
    }
    if (!isNil(isNewMappingInfra)) {
      setIsNewMapping(isNewMappingInfra);
    }
  };

  const onSlotNameChange = (e: any) => setSlotName(e.target.value);

  return (
    <Modal
      title="Map Node to Infra"
      centered
      width={450}
      onOk={handleConfirm}
      okText={isValidNodeData ? "Confirm" : "Next"}
      confirmLoading={validateInfraNodeMappingLoading || mapInfraNodeLoading}
      okButtonProps={{
        disabled: isInvalidInputData,
      }}
      {...modalWrapperProps}
    >
      {isValidNodeData ? (
        <NodeInput node={nodeInput} type={infraType} handleInputChange={handleInputChange} />
      ) : (
        <>
          <div className="data-section">
            <p className="title">NODE TYPE</p>
            <Select className="w-100" defaultValue={nodeType} onSelect={setNodeType} placeholder="Node Type">
              {nodeTypes.map((type: any, index: number) => (
                <Select.Option key={index} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="data-section">
            <p className="title">SLOT NAME</p>
            <Input placeholder="Slot Name" onChange={onSlotNameChange} value={slotName} />
          </div>

          <NodeMacInput
            setNodeMacInput={setNodeMacInput}
            setInvalidInputData={setInvalidInputData}
            validateNodeMacWithFullLen
          />
        </>
      )}
    </Modal>
  );
};

export default MapNodeModal;
