import _, { isEmpty } from "lodash";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import React from "react";
import classnames from "classnames";
import isNil from "lodash/isNil";
import { FilterOption, NodeType } from "pacts/app-webcore/hasura-webcore.graphql";
import {
  AutoModeIcon,
  CoolIcon,
  DoorCloseIcon,
  DoorOpenIcon,
  DryIcon,
  FanIcon,
  PreCoolIcon,
  SettingIcon,
  SunIcon,
  UnoccupiedIcon,
  UserIcon,
  WarningIcon,
} from "components/Icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { parseDate } from "utils/date";
import { Moment } from "moment";
import { formatTemp, getACFanSpeedText, NotAvailable, NotUpdated } from "../key.helper";
import { CustomerKeyListRawData, Key, PrecoolActiveStatuses, PreCoolStatus, RawKey, Room } from "./KeyList.d";
import { ACMode, ACStatus, AutomationMode, DoorStatus, OccupancyState } from "../types";

export const fixedFilterOptions: FilterOption[] = [
  {
    field: "occupancies",
    value: `${OccupancyState.Occupied}`,
    label: "Occupied",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Occupancy",
  },
  {
    field: "occupancies",
    value: `${OccupancyState.Unoccupied}`,
    label: "Unoccupied",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Occupancy",
  },
  {
    field: "occupancies",
    value: `${OccupancyState.NotUpdated}`,
    label: "Not Updated",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Occupancy",
  },
  {
    field: "doors",
    value: `${DoorStatus.Open}`,
    label: "Door Open",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Door",
  },
  {
    field: "doors",
    value: `${DoorStatus.Close}`,
    label: "Door Closed",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Door",
  },
  {
    field: "doors",
    value: `${DoorStatus.NotUpdated}`,
    label: "Not Updated",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Door",
  },
  {
    field: "acStatuses",
    value: `${ACStatus.On}`,
    label: "Power On",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
  },
  {
    field: "acStatuses",
    value: `${ACStatus.Off}`,
    label: "Power Off",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
  },
  {
    field: "acStatuses",
    value: `${ACStatus.NotUpdated}`,
    label: "Not Updated",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
  },
  {
    field: "automationModes",
    value: AutomationMode.SuperSave,
    label: "SuperSave",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "automationModes",
    value: AutomationMode.SmartSave,
    label: "SmartSave",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "automationModes",
    value: AutomationMode.Disabled,
    label: "Disabled",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "isDeviatedFromDefaultConfig",
    value: "1",
    label: "Settings Overridden",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Settings",
  },
];

export const getOccupancyStateForKey = (rooms: any[]) => {
  if (isEmpty(rooms)) return OccupancyState.NotUpdated;

  let occupancyState = OccupancyState.Unoccupied;

  rooms.some((r) => {
    if (r.occupancyState === OccupancyState.Occupied) {
      occupancyState = OccupancyState.Occupied;
      return true;
    }
    if (r.occupancyState === null) {
      occupancyState = OccupancyState.NotUpdated;
      return true;
    }
    return false;
  });

  return occupancyState;
};

export const getDoorStatusForKey = (rooms: any[]) => {
  if (isEmpty(rooms)) return DoorStatus.NotUpdated;

  let doorStatus = DoorStatus.Close;
  rooms.some((r) => {
    if (r.doorStatus === null) {
      doorStatus = DoorStatus.NotUpdated;
      return true;
    }
    if (r.doorStatus === DoorStatus.Open) {
      doorStatus = DoorStatus.Open;
      return true;
    }
    return false;
  });

  return doorStatus;
};

const getAutomationModeForKey = (rooms: any[]) => {
  if (isEmpty(rooms)) return null;
  if (rooms.length === 1) {
    return rooms[0].automationMode;
  }
  return "";
};

const hasNodeType = (room: Room, type: NodeType) => {
  return !!room.slotMappings.find(({ nodeType }) => nodeType === type);
};

/**
 * @function mergeSubscriptionDataWithInitialQueryData - merge keys and rooms accordingly from initial query data to subscription data
 * @param data
 */
export const mergeSubscriptionDataWithInitialQueryData = (data: CustomerKeyListRawData) => {
  const keyMapByPositionId = _.keyBy(
    data.queryData?.locationOne?.locationKeys?.map((k) => ({
      ...k,
      roomMapByPositionId: _.keyBy(k.rooms, "positionId"),
    })),
    "positionId"
  );
  const sortingArr = Object.keys(keyMapByPositionId);
  return data.subscriptionData?.keyList
    ?.map((key) => ({
      ...key,
      ...keyMapByPositionId[key.positionId],
      rooms: key.rooms.map((r) => _.merge(r, keyMapByPositionId[key.positionId].roomMapByPositionId[r.positionId])),
    }))
    .sort((a, b) => {
      return sortingArr.indexOf(a.positionId) - sortingArr.indexOf(b.positionId);
    });
};

export const transformKeys = (data: CustomerKeyListRawData): Key[] => {
  return (mergeSubscriptionDataWithInitialQueryData(data) as RawKey[])
    .map((k) => ({
      ...k,
      type: "key",
      roomCount: k.rooms.length,
      rooms: k.rooms.map((r) => {
        const nodeMeasurement = r.nodeMeasurements.length > 0 ? r.nodeMeasurements[0] : null;
        const occupancyState = nodeMeasurement ? nodeMeasurement.occupancyState : null;
        const doorStatus = nodeMeasurement ? nodeMeasurement.door : null;
        const acMode = nodeMeasurement ? nodeMeasurement.acMode : null;
        const acSetPoint = nodeMeasurement ? nodeMeasurement.acSetPoint : null;
        const fanSpeed = nodeMeasurement ? nodeMeasurement.fanSpeed : null;
        const acPrecoolStatus = r.precoolCommands[0]?.status as PreCoolStatus;
        let acPrecoolStartedAt: Moment | null = null;
        if (r.precoolCommands[0]?.startedAt) {
          acPrecoolStartedAt = parseDate(r.precoolCommands[0].startedAt);
        } else if (r.precoolCommands[0]?.creationDate) {
          acPrecoolStartedAt = parseDate(r.precoolCommands[0]?.creationDate);
        }
        const acPrecoolExpiredTime =
          r.precoolCommands[0]?.totalMinutes && acPrecoolStartedAt
            ? acPrecoolStartedAt.add(r.precoolCommands[0]?.totalMinutes, "minutes")
            : null;
        const ambientTemperature = nodeMeasurement ? nodeMeasurement.ambientTemperature : null;
        const ambientHumidity = nodeMeasurement ? nodeMeasurement.ambientHumidity : null;
        const acStatus = nodeMeasurement ? nodeMeasurement.acStatus : null;
        const isDefaultConfig =
          r.positionConfiguration.length > 0 ? r.positionConfiguration[0].isDeviatedFromDefaultConfig ?? false : false;
        const automationMode = r.positionConfiguration.length > 0 ? r.positionConfiguration[0].automationMode : null;

        return {
          ...r,
          type: "room",
          parentId: k.positionId,
          roomCount: k.rooms.length,
          isDefaultConfig,
          automationMode,
          occupancyState,
          doorStatus,
          acMode,
          acSetPoint,
          fanSpeed,
          acPrecoolStatus,
          acPrecoolExpiredTime,
          ambientTemperature,
          acStatus,
          ambientHumidity,
          hasDoor: hasNodeType(r as Room, NodeType.Door),
          hasOccupancy: hasNodeType(r as Room, NodeType.Occupancy),
          hasHVAC: hasNodeType(r as Room, NodeType.Aircon),
        };
      }),
    }))
    .map((k) => {
      const acOnCount = k.rooms.filter((r) => r.acStatus === ACStatus.On).length;
      const acOffCount = k.rooms.filter((r) => r.acStatus === ACStatus.Off).length;

      return {
        ...k,
        occupancyState: getOccupancyStateForKey(k.rooms),
        doorStatus: getDoorStatusForKey(k.rooms),
        isDefaultConfig: !!k.rooms.find((r) => r.isDefaultConfig),
        automationMode: getAutomationModeForKey(k.rooms),
        acModeSummary: {
          onCount: acOnCount,
          offCount: acOffCount,
        },
        acSetPoint: null,
        acPrecoolExpiredTime: null,
        ambientTemperature: null,
      };
    });
};

export const getOccupancyStateIcon = (val: any, className: string = "px-m") => {
  if (val === OccupancyState.Occupied) {
    return <UserIcon className={classnames(className, "red-svg-icon")} tooltip="Occupied" />;
  }
  if (val === OccupancyState.Unoccupied) {
    return <UnoccupiedIcon className={classnames(className, "green-svg-icon")} tooltip="Unoccupied" />;
  }
  return <WarningIcon className={className} tooltip="Not Updated" />;
};

export const getDoorStatusIcon = (val: any, className: string = "px-m") => {
  if (val === DoorStatus.Close) {
    return <DoorCloseIcon className={className} tooltip="Door Close" />;
  }
  if (val === DoorStatus.Open) {
    return <DoorOpenIcon className={className} tooltip="Door Open" />;
  }
  return <WarningIcon className={className} tooltip="Not Updated" />;
};

export const getIsDeviatedFromDefaultIcon = (val: any, className: string = "px-m") => {
  if (val) {
    return <SettingIcon className={classnames(className, "text-warning")} tooltip="Settings Overridden" />;
  }
  return <SettingIcon className={classnames(className, "text-gray")} tooltip="Default Settings" />;
};

export const getACIcon = (val: any, className: string = "px-xs") => {
  switch (val) {
    case ACMode.Auto:
      return <AutoModeIcon className={className} tooltip="HVAC Auto Mode" />;
    case ACMode.Cool:
      return <CoolIcon className={className} tooltip="HVAC Cool Mode" />;
    case ACMode.Dry:
      return <DryIcon className={className} tooltip="HVAC Dry Mode" />;
    case ACMode.Fan:
      return <FanIcon className={className} tooltip="HVAC Fan Mode" />;
    case ACMode.Heat:
      return <SunIcon className={className} tooltip="HVAC Heat Mode" />;
    default:
      return <WarningIcon className={className} />;
  }
};

export const getACSetPoint = (record: Key) => {
  return (
    <span data-testid="acSetpoint">
      {record.acMode === ACMode.Fan || isNil(record.acSetPoint) ? "NA" : formatTemp(record.acSetPoint, "")}
    </span>
  );
};

export const getACView = (
  record: Key,
  precoolStatus: PreCoolStatus | null | undefined,
  remainingTime: string,
  onAction?: (e: any) => void | Promise<void>
) => {
  let acStatusDisplay: JSX.Element | null = null;
  switch (record.acStatus) {
    case ACStatus.On: {
      acStatusDisplay = (
        <>
          <span className="pr-xs d-flex align-items-center">
            {getACIcon(record.acMode)}
            {getACSetPoint(record)}
          </span>
          <span className="pr-xs d-flex align-items-center">
            <FanIcon className="px-xs" tooltip="Fan speed" />
            <span>{!isNil(record.fanSpeed) ? getACFanSpeedText(record.fanSpeed, true) : "NA"}</span>
          </span>
        </>
      );
      break;
    }
    case ACStatus.Off: {
      acStatusDisplay = <span className="text-warning">Off</span>;
      break;
    }
    default: {
      acStatusDisplay = <NotUpdated />;
    }
  }
  if (record.type === "room") {
    if (!record.hasHVAC) return <NotAvailable />;
    return (
      <div className="d-flex justify-content-start">
        {acStatusDisplay}
        {precoolStatus && PrecoolActiveStatuses.indexOf(precoolStatus) > -1 && (
          <span className="d-flex align-items-center">
            {(precoolStatus !== PreCoolStatus.SUCCESS || !isEmpty(remainingTime)) && (
              <PreCoolIcon
                className="px-xs text-warning"
                tooltip={
                  <>
                    Pre-set <CloseCircleOutlined className="px-xs text-white cursor-pointer" onClick={onAction} />
                  </>
                }
              />
            )}{" "}
            {!isEmpty(remainingTime) && <span className="text-warning cursor-pointer">{remainingTime}</span>}
          </span>
        )}
      </div>
    );
  }
  return "";
};
