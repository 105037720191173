import React from "react";

import classnames from "classnames";
import { Button } from "antd";
import { Category } from "../DataTypes";
import "./CategoryList.scss";

type CategoryListProps = {
  categories: Array<Category>;
  onCategoryChange: (index: number) => void;
  selectedCategoryIndex: number;
};

const CategoryList = ({ categories, onCategoryChange, selectedCategoryIndex }: CategoryListProps) => {
  const handleCategoryChange = (index: number) => {
    onCategoryChange(index);
  };

  return (
    <div className="category-list">
      {categories.map((category: Category, index: number) => (
        <Button
          block
          key={category.id}
          onClick={() => handleCategoryChange(index)}
          className={classnames("category", { "is-selected": index === selectedCategoryIndex })}
        >
          {category.name}
        </Button>
      ))}
    </div>
  );
};

export default CategoryList;
