import { NodeType } from "pacts/app-webcore/hasura-webcore.graphql";

export interface ConnectedRoomDataType {
  position: {
    positionId: string;
    positionName: string;
    parentPosition: {
      positionName: string;
    };
  };
}

export interface MappedNodeDataType {
  slotName: string;
  nodeMacId: string;
  nodeType: string;
  positionId: string;
  nodeTypeLabel: string;
  lastSeen: string;
  status: string;
  signalStrength: number;
  mappedTime: string;
  settings: number;
  settingsLabel: string;
  mappedGateway: {
    gatewayName: string;
    gatewayId: string;
  };
  joinnedGateway: {
    gatewayName: string;
  };
}

export interface PositionDataType {
  nodes: MappedNodeDataType[];
  positionConfiguration: [
    {
      desiredEmonSwitchPosition: number;
    }
  ];
  nodeSlots: {
    slotName: string;
    nodeType: NodeType;
  }[];
}

export interface CoolingSystemDataType {
  compressorId: string;
  compressorName: string;
}

export interface InfrastructureDetailsDataType {
  id: string;
  name: string;
  type: string;
  compressor: {
    phaseStreamIndex: number;
  };
  position: {
    positionId: string;
    positionName?: stirng;
  };
  rooms: ConnectedRoomDataType[];
  meterPosition?: PositionDataType;
  coolingSystem?: CoolingSystemDataType;
  hvacSystemType?: string;
  ctMultiplier?: number;
  meterPositionId?: string;
}

export enum InfrastructureDetailType {
  COOLING_TOWER_1_PHASE = "COOLING_TOWER_1_PHASE",
  COOLING_TOWER_3_PHASES = "COOLING_TOWER_3_PHASES",
  PUMP_1_PHASE = "PUMP_1_PHASE",
  PUMP_3_PHASES = "PUMP_3_PHASES",
  COMPRESSOR_1_PHASE = "COMPRESSOR_1_PHASE",
  COMPRESSOR_3_PHASES = "COMPRESSOR_3_PHASES",
  CHILLER_1_PHASE = "CHILLER_1_PHASE",
  CHILLER_3_PHASES = "CHILLER_3_PHASES",
  PIPE = "PIPE",
  CHILLED_WATER = "CHILLED_WATER",
}

export enum ActiveTab {
  COMPRESSOR = "compressor",
  CHECK_LIST = "check-list",
}
