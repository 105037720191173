/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Col, DatePicker, Form, InputNumber, Modal, Row, Select } from "antd";
import { Closable } from "components/Modal/show";
import { FormInstance } from "antd/lib/form";
import { DATETIME_24H_FORMAT, TIME_24H_FORMAT } from "utils/date";
import moment from "moment";
import { useFeatureFlag } from "hooks/use-feature-flag";
import { PresetValues } from "../KeyList.d";
import { ACMode, OperationalMode } from "../../types";
import { CoolIcon, SunIcon } from "../../../../components/Icons";

export type SetupPreCoolTimeModalProps = {
  onOk: (values: PresetValues, form: FormInstance<PresetValues>) => void | Promise<void>;
  showHeatingMode: boolean;
  operationalMode: OperationalMode;
};

const minutesInThePastAllowed = 3;

const DEFAULT_TEMPERATURE = 24;

const DEFAULT_MINUTES = 30;

const SetupPreCoolTimeModal = ({
  onOk,
  close,
  showHeatingMode,
  operationalMode,
}: SetupPreCoolTimeModalProps & Closable) => {
  const [hour, setHour] = useState<any>(0);
  const [minute, setMinute] = useState<any>(DEFAULT_MINUTES);
  const [mode, setMode] = useState<ACMode>(operationalMode === OperationalMode.Cooling ? ACMode.Cool : ACMode.Heat);
  const [form] = Form.useForm<PresetValues>();
  const { precoolingWithSchduled: precoolingWithScheduledEnabled } = useFeatureFlag();

  const handleSubmitAction = async () => {
    try {
      const values = form.getFieldsValue();
      if (!values.mode) values.mode = ACMode.Cool;
      await onOk(values, form);
      close();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      mins: hour * 60 + minute,
    });
  }, [hour, minute, form]);

  useEffect(() => {
    form.setFieldsValue({
      temperature: DEFAULT_TEMPERATURE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <Modal
      title="How long would you like to Pre-set the room for?"
      centered
      visible
      onCancel={close}
      onOk={form.submit}
      width={400}
      okText="Confirm"
    >
      <Form layout="vertical" onFinish={handleSubmitAction} form={form}>
        {precoolingWithScheduledEnabled && (
          <Form.Item
            label="Start time"
            name="startTime"
            rules={[
              {
                validator(rule, value, callback) {
                  if (value === null) return callback("Please select time!");
                  try {
                    const isValidDateTime = moment(value).diff(moment.now(), "m") >= -minutesInThePastAllowed;

                    if (!isValidDateTime) return callback("Please select a start time later than now");
                    callback();
                  } catch (error) {
                    callback(new Error(error as string).message);
                  }
                },
              },
            ]}
          >
            <DatePicker
              className="mr-m w-100"
              placeholder="Start time"
              format={DATETIME_24H_FORMAT}
              showTime={{ format: TIME_24H_FORMAT }}
              defaultValue={moment()}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Duration"
          name="mins"
          rules={[{ required: true, min: 1, type: "number", message: "Duration needs to be 1 min or longer" }]}
        >
          <Row gutter={10}>
            <Col span={12}>
              <InputNumber
                min={0}
                max={24}
                placeholder="0"
                data-testid="input-hours-testId"
                formatter={(value) => (value ? `${value} Hrs` : "")}
                parser={(value) => (value ? parseInt(value.replace(" Hrs", "")) : 0)}
                onChange={(val) => setHour(val)}
                className="w-100"
                value={hour}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                min={0}
                max={59}
                data-testid="input-minutes-testId"
                placeholder="0"
                formatter={(value) => (value ? `${value} Mins` : "")}
                parser={(value) => (value ? parseInt(value.replace(" Mins", "")) : 0)}
                onChange={(val) => setMinute(val)}
                className="w-100"
                value={minute}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="Mode" name="mode">
          <Select
            data-testid="input-mode-testId"
            disabled={!showHeatingMode}
            defaultValue={mode}
            onChange={(value) => setMode(value)}
          >
            <Select.Option value={ACMode.Cool} disabled={operationalMode !== OperationalMode.Cooling}>
              <CoolIcon /> Cool
            </Select.Option>
            <Select.Option value={ACMode.Heat} disabled={operationalMode !== OperationalMode.Heating}>
              <SunIcon /> Heat
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Temperature"
          name="temperature"
          rules={[{ required: true, message: "Please enter a setpoint temperature" }]}
        >
          <InputNumber
            min={16}
            max={32}
            defaultValue={mode === ACMode.Cool ? 23 : 21}
            className="w-100"
            data-testid="input-temperature-testId"
          />
        </Form.Item>
        <p className="my-m">
          The AC will stay on until the timer elapse or it is switched off via the Dashboard or someone enters and
          leaves the room.
        </p>
      </Form>
    </Modal>
  );
};

export default SetupPreCoolTimeModal;
