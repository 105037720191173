import { Button, Modal, Tooltip } from "antd";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { isFunction, uniq } from "lodash";
import { KeyEntryCommentStatus, PositionFunction } from "types/InstallationMode.d";
import { useAuth0 } from "services/auth/authService";
import classNames from "classnames";
import useRoleAndPermission from "hooks/use-role-and-permission";
import errorHandler from "errorHandler";
import { useGetEntryHasUnResolvedCommentLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { InstallationModeButtonProps } from "./InstallationModeButton.d";
import StopWatch from "./StopWatch";
import { CommentModal, CommentModalProps } from "./CommentModal";

const InstallationModeButton = (props: InstallationModeButtonProps) => {
  const {
    keyPositionId,
    buttonName: buttonNameProps = {},
    onEnterEntry,
    onExitEntry,
    redirectUrl,
    classNameButton,
    className,
    currentPositionFunction,
    automaticTestRun,
    isMeterPosition,
    activeModal,
    showStopWatch,
  } = props;

  const {
    currentKeyEntryOfInstaller,
    currentKeyEntryOfPositions,
    keyName,
    isLoading,
    currentPositionId,
    handleExitKey,
    handleEnterKey,
    setKeyPositionIds,
  } = useContext(InstallationModeContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalExit, setOpenModalExit] = useState<boolean>(false);
  const [modalComment, setModalComment] = useState<Pick<CommentModalProps, "comment" | "keyEntryId" | "open">>({
    open: false,
    comment: "",
  });
  const { isContractor } = useRoleAndPermission();

  const { user = {} } = useAuth0();
  const userId = user.sub;

  const currentKeyEntryOfPosition = useMemo(
    () => currentKeyEntryOfPositions?.find((x) => x.keyPositionId === keyPositionId),
    [currentKeyEntryOfPositions, keyPositionId]
  );

  const isExitKey = useMemo(() => currentPositionId === keyPositionId, [currentPositionId, keyPositionId]);
  const activeKey = useMemo(() => {
    const noKeyEntryId = !currentKeyEntryOfInstaller?.keyEntryId && !currentKeyEntryOfPosition?.keyEntryId;
    return noKeyEntryId || isExitKey;
  }, [currentKeyEntryOfInstaller, currentKeyEntryOfPosition, isExitKey]);

  const buttonName = useMemo(() => ({ enter: "Enter", exit: "Exit", ...buttonNameProps }), [buttonNameProps]);

  const handleOnEnterKey = async (isResumedEntry = false) => {
    try {
      if (activeModal && !openModal && !isExitKey && !isContractor()) return setOpenModal(true);

      await handleEnterKey({
        isResumedEntry,
        keyPositionId,
        positionFunction: currentPositionFunction,
        redirectUrl,
        automaticTestRun,
        isMeterPosition,
      });
      if (isFunction(onEnterEntry)) onEnterEntry(keyPositionId);
      setOpenModal(false);
      setModalComment({});
    } catch (error: any) {
      errorHandler.handleError(error);
    }
  };

  const [getEntryHasUnResolved, { loading: isGetEntryHasUnResolved }] = useGetEntryHasUnResolvedCommentLazyQuery({
    variables: { keyPositionId },
    onCompleted: async (data) => {
      if (!data?.keyEntries[0] || data?.keyEntries[0]?.commentStatus === KeyEntryCommentStatus.RESOLVED) {
        await handleOnEnterKey();
      } else {
        setModalComment({
          open: true,
          comment: data?.keyEntries[0]?.comment || "",
          keyEntryId: data?.keyEntries[0]?.keyEntryId,
        });
      }
    },
  });

  const handleOnClick = async () => {
    if (!activeKey && currentPositionId) return setOpenModalExit(true);
    if (isExitKey) {
      await handleExitKey(() => isFunction(onExitEntry) && onExitEntry(keyPositionId));
      return;
    }
    getEntryHasUnResolved({ variables: { keyPositionId } });
  };

  const reasonCannotEnterKey = () => {
    const positionType =
      currentKeyEntryOfInstaller?.positionFunction === PositionFunction.AUTOSET ? "key" : "compressor";
    const currentKeyPositionType = currentPositionFunction === PositionFunction.AUTOSET ? "key" : "compressor";
    if (!activeKey) {
      if (currentKeyEntryOfPosition && currentKeyEntryOfPosition.userId !== userId)
        return `This ${currentKeyPositionType} is being installed by another user.`;
      return `You're on ${positionType} ${keyName}. Please exit it first.`;
    }
  };

  useEffect(() => {
    if (setKeyPositionIds) {
      setKeyPositionIds((prev) => uniq([...prev, keyPositionId]));
    }
  }, [keyPositionId, setKeyPositionIds]);

  const buttonDisabled = useMemo(() => {
    return (
      isLoading ||
      isGetEntryHasUnResolved ||
      (!!currentKeyEntryOfPosition?.startedAt &&
        !!currentKeyEntryOfPosition?.userId &&
        currentKeyEntryOfPosition?.userId !== userId)
    ); // the key entry has started, and it's not assigned to the current user
  }, [isLoading, currentKeyEntryOfPosition, userId, isGetEntryHasUnResolved]);

  return (
    <>
      <Tooltip title={reasonCannotEnterKey()} className={classNames("installation-mode", className)}>
        <Button type="primary" onClick={handleOnClick} disabled={buttonDisabled} className={classNameButton}>
          {isExitKey ? buttonName.exit : buttonName.enter}
        </Button>
        {showStopWatch && isExitKey && currentKeyEntryOfInstaller?.startedAt && (
          <StopWatch startTime={currentKeyEntryOfInstaller?.startedAt} />
        )}
      </Tooltip>

      {activeModal && (
        <Modal
          className="select-test-run-modal"
          title="Select test run mode"
          centered
          visible={openModal}
          footer={null}
          onCancel={() => setOpenModal(false)}
          width={400}
        >
          <Button
            type="primary"
            className="ant-btn ant-btn-primary modal-button"
            onClick={() => handleOnEnterKey(false)}
          >
            Start new session
          </Button>
          <br />
          <Button type="default" className="modal-button button-no" onClick={() => handleOnEnterKey(true)}>
            Resume previous session
          </Button>
        </Modal>
      )}

      <Modal
        className="select-test-run-modal"
        title="Exit Test Run Mode"
        centered
        visible={openModalExit}
        footer={null}
        onCancel={() => setOpenModalExit(false)}
        width={400}
      >
        <p>
          You are currently in another key: <b>{keyName}</b>. In order to enter to a new key, you need to exit the
          current key first.
        </p>
        <br />
        <Button
          type="primary"
          onClick={() =>
            handleExitKey(() => {
              setOpenModalExit(false);
              if (activeModal) setOpenModal(true);
              else getEntryHasUnResolved({ variables: { keyPositionId } });
            })
          }
          disabled={isLoading}
          className="ant-btn ant-btn-primary modal-button"
        >
          {buttonName.exit}
        </Button>
        <Button
          type="default"
          className="modal-button button-no"
          onClick={() => {
            setOpenModalExit(false);
          }}
        >
          Cancel
        </Button>
      </Modal>

      <CommentModal
        open={modalComment?.open}
        comment={modalComment?.comment}
        keyEntryId={modalComment?.keyEntryId}
        onClose={() => setModalComment({})}
        onResolved={() => handleOnEnterKey()}
      />
    </>
  );
};

export default InstallationModeButton;
