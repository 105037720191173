import React from "react";
import { TableV2Props } from "./Table.d";
import TablePaginationMobile from "./TablePaginationMobile";

const MobileTable = ({
  renderMobileRow,
  rowKey,
  tableData,
  pagination,
  getMobileDisplayText,
  onPaginationChanged,
}: Omit<TableV2Props, "columns">) => {
  return (
    <>
      {tableData.map((rowData, order) => (
        <React.Fragment key={typeof rowKey === "string" ? (rowData as Record<string, any>)[rowKey] : rowKey?.(rowData)}>
          {renderMobileRow?.(rowData, order)}
        </React.Fragment>
      ))}
      <div className="h-4r" />
      {tableData?.length ? (
        <TablePaginationMobile
          pagination={pagination}
          onPaginationChanged={onPaginationChanged}
          getMobileDisplayText={getMobileDisplayText}
        />
      ) : null}
    </>
  );
};

export default MobileTable;
