import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

type LocationBreadcrumbProps = {
  locationName: string;
};

const LocationBreadcrumb = ({ locationName }: LocationBreadcrumbProps) => {
  return (
    <Breadcrumb className="header-breadcrumb">
      <Breadcrumb.Item>
        <Link to="/locations">Location</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{locationName}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default LocationBreadcrumb;
