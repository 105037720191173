import React, { useState } from "react";

import { Modal, Select } from "antd";
import { Closable } from "components/Modal/show";

export type ChangeCategoryModalProps = {
  onOk: (category: any) => void;
  categories: any[];
};

const ChangeCategoryModal = ({ onOk, close, categories }: ChangeCategoryModalProps & Closable) => {
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const handleSubmitAction = () => {
    if (selectedCategory) {
      onOk(selectedCategory);
      close();
    }
  };

  const handleOnChange = (value: any) => {
    setSelectedCategory(value);
  };

  return (
    <Modal
      title="Change Category"
      centered
      visible
      onCancel={close}
      onOk={handleSubmitAction}
      width={400}
      okText="Confirm"
      okButtonProps={{
        disabled: !selectedCategory,
      }}
    >
      <p className="mb-xs">Select Category</p>
      <Select className="w-100" data-testid="key-category-testId" onChange={handleOnChange}>
        {categories?.map((option) => (
          <Select.Option key={option.categoryName} value={option.categoryName}>
            {option.categoryName}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ChangeCategoryModal;
