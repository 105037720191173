import { NodeFilterInput, UpgradeStatus } from "../OtaNodeList.d";

export interface OtaNodeFilterProps {
  onFilterChange: (filters: Record<string, any>) => void;
  initialFilter: NodeFilterInput;
}

export const UPGRADE_STATUS_OPTIONS = [
  {
    label: "Up to date",
    value: UpgradeStatus.OK,
  },
  {
    label: "Upgrade pending",
    value: UpgradeStatus.PENDING,
  },
  {
    label: "Node is deprecated",
    value: UpgradeStatus.DEPRECATED,
  },
  {
    label: "Upgrade has failed",
    value: UpgradeStatus.FAILED,
  },
  {
    label: "Unknown",
    value: UpgradeStatus.UNKNOWN,
  },
];
