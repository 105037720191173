import React from "react";

import UserListHeader from "./UserListHeader/UserListHeader";
import UserListTable from "./UserListTable/UserListTable";

const UserList = () => {
  return (
    <>
      <UserListHeader />
      <UserListTable />
    </>
  );
};

export default UserList;
