import React from "react";
import { Modal, message } from "antd";
import errorHandler from "errorHandler";
import { isFunction } from "lodash";
import { useUpdateLocationSetEnableNoKeyCardStateMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import { ConfirmEnableNoKeyCardStateModalProps } from "./ConfirmEnableNoKeyCardStateModal.d";

const ConfirmEnableNoKeyCardStateModal = (props: ConfirmEnableNoKeyCardStateModalProps) => {
  const { onError, onSuccess, closeModal, modalWrapperProps, enableNoKeyCardState, locationId } = props;
  const [updateLocationSetEnableNoKeyCardState, { loading }] = useUpdateLocationSetEnableNoKeyCardStateMutation({
    onCompleted: () => {
      message.success("Successfully updated location's No Keycard State!");
      if (isFunction(onSuccess)) onSuccess();
    },
    onError: (error) => {
      errorHandler.handleError(error);
      if (isFunction(onError)) onError();
    },
  });

  const handleOnConfirm = async () => {
    await updateLocationSetEnableNoKeyCardState({ variables: { locationId, enableNoKeyCardState } });
    closeModal();
  };

  return (
    <Modal
      title="Enable no keycard state"
      centered
      onOk={handleOnConfirm}
      width={400}
      okText="Confirm"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      {...modalWrapperProps}
      onCancel={() => {
        if (isFunction(onError)) onError();
        closeModal();
      }}
    >
      Please confirm to update the no keycard state in the location
    </Modal>
  );
};
export default ConfirmEnableNoKeyCardStateModal;
