import { GatewayHardwareType } from "pacts/app-webcore/hasura-webcore.graphql";

export const getGatewayHardwareTypeDisplayText = (type?: GatewayHardwareType | null) => {
  switch (type) {
    case GatewayHardwareType.MultiAsSingleChannel:
      return "Multi as single channel";
    case GatewayHardwareType.SingleAsMultiChannel:
      return "Single as multi Channel";
    case GatewayHardwareType.MultiChannel:
      return "Multi channel";
    case GatewayHardwareType.SingleChannel:
      return "Single channel";
    default:
      return "-";
  }
};

export default getGatewayHardwareTypeDisplayText;
