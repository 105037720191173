import { ExclamationCircleFilled } from "@ant-design/icons";
import { message } from "antd";
import confirm from "antd/lib/modal/confirm";
import React from "react";
import errorHandler from "../../../errorHandler";
import { useResetPositionConfigurationsMutation } from "../../../pacts/app-webcore/hasura-webcore.graphql";

const useResetConfigDefault = (onCompleted: any, title?: string, content?: string, onCancel?: () => void) => {
  const modalTitle = title ?? "Do you want to reset these rooms?";
  const modalContent = content ?? "You will be resetting these rooms back to their default configurations.";

  const [resetPositionConfigurationsMutation, { loading }] = useResetPositionConfigurationsMutation({
    onCompleted: () => {
      onCompleted();
      message.success("Rooms Configuration Reset Back to Default");
    },
    onError: errorHandler.handleError,
  });

  const resetConfigToDefault = (selectedRooms: string[]) => {
    if (selectedRooms.length > 0) {
      confirm({
        icon: <ExclamationCircleFilled className="text-warning" />,
        title: modalTitle,
        content: modalContent,
        okText: "Confirm",
        okButtonProps: { className: "btn-popup-primary" },
        onOk() {
          resetPositionConfigurationsMutation({
            variables: {
              positionIds: selectedRooms,
            },
          });
        },
        onCancel,
      });
    }
  };

  return {
    loading,
    resetConfigToDefault,
  };
};

export default useResetConfigDefault;
