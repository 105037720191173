import React, { useEffect, useMemo, useState } from "react";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import some from "lodash/some";
import useBreakpoint from "hooks/use-breakpoint";
import {
  KeyDetailWithNodeMeasurementsSubscription,
  NodeType,
  PositionConfigurationSubscription,
  useKeyDetailWithNodeMeasurementsSubscription,
  usePositionConfigurationSubscription,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { PositionConfigurationRecordType } from "pages/Key/types";
import { Room } from "./KeyDetail.d";
import RoomComponent from "./Room";

const { Panel } = Collapse;

const KeyDetail = (props: { markKeyAsWarning?: any }) => {
  const { keyId } = useParams<{ keyId: string }>();
  const { markKeyAsWarning } = props;
  const [outOfDateSensors, setOutOfDateSensors] = useState<string[]>([]);
  const [positionConfigurations, setPositionConfigurations] = useState<PositionConfigurationSubscription>();
  const [keyDetailWithNodeMeasurements, setKeyDetailWithNodeMeasurements] =
    useState<KeyDetailWithNodeMeasurementsSubscription>();

  const screen = useBreakpoint();

  usePositionConfigurationSubscription({
    variables: { keyId },
    onSubscriptionData(data) {
      if (data.subscriptionData.data?.position) {
        setPositionConfigurations(data.subscriptionData.data!);
      }
    },
  });

  useKeyDetailWithNodeMeasurementsSubscription({
    variables: { keyId },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.position) {
        setKeyDetailWithNodeMeasurements(data.subscriptionData.data!);
      }
    },
  });

  const rooms = useMemo(() => {
    if (keyDetailWithNodeMeasurements?.position && positionConfigurations?.position?.rooms) {
      const currentConfig = positionConfigurations?.position?.rooms.map((x) => {
        return {
          ...x,
          positionConfiguration: x.positionConfiguration.filter(
            (o) => o.recordType === PositionConfigurationRecordType.CURRENT
          ),
        };
      });
      const mapConfigToRooms = keyDetailWithNodeMeasurements?.position?.rooms.map((x) => ({
        ...x,
        positionConfiguration: currentConfig.find((o) => o.positionId === x.positionId)?.positionConfiguration,
      })) as Room[];
      return mapConfigToRooms || [];
    }
    return [];
  }, [keyDetailWithNodeMeasurements, positionConfigurations]);

  useEffect(() => {
    const sensors: string[] = [];
    rooms.forEach((r) => {
      if (r.nodeMeasurements[0]) {
        if (some(r.slotMappings, (s) => s.nodeType === NodeType.Door) && r.nodeMeasurements[0].door === null) {
          sensors.push(`${r.positionName} Door`);
        }
        if (
          some(r.slotMappings, (s) => s.nodeType === NodeType.Occupancy) &&
          r.nodeMeasurements[0].occupancyState === null
        ) {
          sensors.push(`${r.positionName} Occupancy`);
        }
        if (some(r.slotMappings, (s) => s.nodeType === NodeType.Aircon) && r.nodeMeasurements[0].acStatus === null) {
          sensors.push(`${r.positionName} HVAC`);
        }
      }
    });
    setOutOfDateSensors(sensors);
    if (markKeyAsWarning) {
      markKeyAsWarning(sensors.length > 0);
    }
  }, [rooms, markKeyAsWarning]);

  const defaultActiveRoomIds = useMemo(() => {
    if (screen.mobileAndTabletOnly && rooms.length > 1) {
      return [];
    }
    // if desktop then open all rooms by default
    return rooms.map(({ positionId }) => positionId);
  }, [rooms, screen]);

  return (
    <div className="mb-l">
      {outOfDateSensors.length > 0 && (
        <>
          <div className="p-m bg-warning d-inline-block border border-warning">
            The data from these sensors are not up to date:
            <br />
            <span className="font-weight-bold">{outOfDateSensors.join(", ")}</span>
          </div>
          <div className="py-m" />
        </>
      )}
      {rooms.length > 0 && (
        <Collapse defaultActiveKey={defaultActiveRoomIds}>
          {rooms.map((r) => (
            <Panel header={r.positionName} key={r.positionId}>
              <RoomComponent room={r} key={r.positionId} />
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default KeyDetail;
