import { GraphQLError } from "graphql";
import { every, some } from "lodash";
import moment from "moment";
import categories from "./keyCategories";

import { Sensorflow_Positions_Bool_Exp } from "../../../pacts/app-webcore/hasura-webcore.graphql";

const getKeys = () => {
  const categoryNames: string[] = [];
  categories.forEach((category) => {
    if (category.locationName === "Pigeon Hotel") {
      categoryNames.push(category.categoryName);
    }
  });
  const locationCategories = categories.filter((c) => c.locationName === "Pigeon Hotel");
  const categoryCount = categoryNames.length;

  return [
    {
      positionId: "333010e2-9fc9-4b15-9902-c6d937f61d03",
      positionName: "1000",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
        __typename: "sensorflow_positions",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[0 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "e0f7d982-e527-11e9-ad3b-a45e60d743d3",
          positionName: "Bedroom 1",
          positionConfiguration: [
            {
              automationMode: "SUPERSAVE",
              isDeviatedFromDefaultConfig: true,
              minTemp: 16,
              maxTemp: 32,
              heatingMinTemp: 23,
              heatingMaxTemp: 25,
              hideSetpoint: true,
              heatingHideSetpoint: true,
              acNightStart: "23:00",
              acNightEnd: "00:45",
              unoccupiedTemp: 25,
              heatingUnoccupiedTemp: 27,
              operationalMode: "cooling",
              actingMode: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 1,
              acMode: 0,
              acSetPoint: 25,
              fanSpeed: 3,
              acStatus: 0,
              ambientTemperature: 28,
              ambientHumidity: 50,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              status: "SUCCESS",
              startedAt: moment().utc().format(),
              totalMinutes: 1,
              creationDate: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
        {
          positionId: "e10a0832-e527-11e9-ad3b-a45e60d743d3",
          positionName: "Bedroom 2",
          positionConfiguration: [
            {
              automationMode: "SMARTSAVE",
              isDeviatedFromDefaultConfig: true,
              minTemp: 18,
              maxTemp: 23,
              heatingMinTemp: 23,
              heatingMaxTemp: 25,
              hideSetpoint: false,
              heatingHideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              unoccupiedTemp: 26,
              heatingUnoccupiedTemp: 27,
              operationalMode: "cooling",
              actingMode: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 1,
              door: 0,
              acMode: 1,
              acSetPoint: 25,
              fanSpeed: 3,
              acStatus: 1,
              ambientTemperature: 26,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              status: "SUCCESS",
              startedAt: moment().utc().format(),
              totalMinutes: 100,
              creationDate: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
        {
          positionId: "e0f7d982-e527-11e9-ad3b-a45e60d753d4",
          positionName: "Bedroom 3",
          positionConfiguration: [
            {
              automationMode: "SUPERSAVE",
              isDeviatedFromDefaultConfig: true,
              minTemp: 16,
              maxTemp: 32,
              heatingMinTemp: 23,
              heatingMaxTemp: 25,
              hideSetpoint: true,
              heatingHideSetpoint: true,
              acNightStart: "23:00",
              acNightEnd: "00:45",
              unoccupiedTemp: 25,
              heatingUnoccupiedTemp: 27,
              operationalMode: "cooling",
              actingMode: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 1,
              door: 0,
              acMode: 0,
              acSetPoint: 25,
              fanSpeed: 3,
              acStatus: 1,
              ambientTemperature: 28,
              ambientHumidity: 50,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF180",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF181",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF182",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "a8af7dda-1dd5-45d9-be92-a2b2412ffe73",
      positionName: "1001",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[1 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "d010a506-23dd-47cd-bbc5-46d4939adf74",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 28,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: null,
              door: null,
              acMode: 3,
              acSetPoint: 23,
              fanSpeed: 2,
              acStatus: null,
              ambientTemperature: 24,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              status: "SUCCESS",
              startedAt: moment().utc().format(),
              totalMinutes: 100,
              creationDate: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [
        {
          checkInAt: "2023-11-28T07:00:00+00:00",
          checkOutAt: "2024-03-23T05:00:00+00:00",
          id: 78,
          keyPositionId: "c5586e9a-c356-4008-82fe-211a53798702",
          status: null,
          comfortplusActivations: [
            {
              precoolDailyLimit: 3,
              __typename: "sensorflow_comfortplus_activations",
            },
          ],
          reservationGuests: [
            {
              user: {
                userId: "ba58302e-4a02-43c0-b996-db3fc9cdba4d",
                name: "email",
                email: "thinh.pham@mission.plus",
                __typename: "sensorflow_users",
              },
              comfortplusAccessToken: "fXQUIZ32nPgT1pPwRiOIDm29DBwEISH9DZzkrUxkn7YIPVJDaS",
              __typename: "sensorflow_reservation_guests",
            },
          ],
          __typename: "sensorflow_reservations",
        },
      ],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "78c78d14-03e4-4afb-8dd1-d1ed6d30ad08",
      positionName: "1003",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[2 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "24d63397-2297-4506-af7f-75c31ce536ec",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              status: "SUCCESS",
              startedAt: moment().utc().format(),
              totalMinutes: 100,
              creationDate: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    // Start
    {
      positionId: "186b8ead-f2a8-4875-a1df-68fef9af20a7",
      positionName: "1004",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[3 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "a15aa11e-18cf-4afb-8925-953130b517d8",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              status: "SUCCESS",
              startedAt: moment().utc().format(),
              totalMinutes: 100,
              creationDate: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "5cbf0fd8-1eba-42f3-bf24-9a01f3e00c9e",
      positionName: "1005",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[4 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "08f76865-9c07-4561-afa2-9d80dae8ab65",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 30,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "b8103772-089b-4802-8cef-e4a5f53eee86",
      positionName: "1006",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[5 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "bb163367-c039-47d2-98e5-17365628ff8e",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 45,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "4e7613ad-0ef1-40ff-b867-5ba70794e217",
      positionName: "1007",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[6 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "b3fb1ab7-fbc1-4e59-b909-53ceb1da9bc6",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 60,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "bf51a5df-bbca-4fa3-accd-647a50dd555b",
      positionName: "1008",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[7 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "f9881b0d-148a-4391-9583-98bfcd9ec5b8",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 0,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "298afe36-6016-464c-9892-e4c36074e980",
      positionName: "1009",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[8 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "d87a9a63-4cbe-41f9-b8ae-a35c7c7b39bd",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 100,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "77b6400e-c330-46e2-91a1-4a17fbe7cf4e",
      positionName: "1010",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[9 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "5f2b507b-2bd1-4d34-ba20-adfcc04755a9",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 100,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "121d8f29-b751-400e-a78e-5db5d8ab51f6",
      positionName: "2000",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[10 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "4c494371-8b21-4ffc-bd2e-b8a624449055",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 100,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
    {
      positionId: "d832c6f8-5cfb-4e24-896f-2c30b5022ef2",
      positionName: "2001",
      parentPosition: {
        positionId: "1dfb0f9d-a754-4d80-a56c-ac83f1d8f452",
        positionName: "root",
        positionType: "root",
        positionFunction: "group",
      },
      keyCategoryPositionMapping: {
        categoryName: categoryNames[11 % categoryCount],
        __typename: "sensorflow_key_category_to_key_position",
      },
      location: {
        keyCategories: locationCategories,
      },
      rooms: [
        {
          positionId: "5c416cad-bd07-410c-9630-24869d031d20",
          positionName: "Single",
          positionConfiguration: [
            {
              automationMode: "NONE",
              isDeviatedFromDefaultConfig: false,
              minTemp: 16,
              maxTemp: 30,
              hideSetpoint: true,
              acNightStart: null,
              acNightEnd: null,
              __typename: "sensorflow_position_configurations",
            },
          ],
          nodeMeasurements: [
            {
              occupancyState: 0,
              door: 0,
              acMode: 2,
              acSetPoint: 20,
              fanSpeed: 2,
              acStatus: 1,
              ambientTemperature: 27,
              ambientHumidity: 60,
              __typename: "sensorflow_node_measurement_view",
            },
          ],
          precoolCommands: [
            {
              totalMinutes: 100,
              creationDate: moment().utc().format(),
              status: "SUCCESS",
              startedAt: moment().utc().format(),
            },
          ],
          slotMappings: [
            {
              nodeType: "door",
              nodeMacId: "FFFF14B4570DF168",
            },
            {
              nodeType: "occupancy",
              nodeMacId: "FFFF14B4570DF167",
            },
            {
              nodeType: "aircon",
              nodeMacId: "FFFF14B4570DF169",
            },
          ],
          __typename: "sensorflow_positions",
        },
      ],
      reservation: [],
      __typename: "sensorflow_positions",
    },
  ];
};

export default (filter?: Sensorflow_Positions_Bool_Exp, searchbykeyname?: string) => {
  const sampleKeys = getKeys();
  if (filter) {
    // Empty location
    if (filter.locationId?._eq === "27407651-106f-4bb3-98f6-efd87478b5d6") {
      return [];
    }
    // Error location
    if (filter.locationId?._eq === "cfbc10e2-9fc9-4b15-9902-c6d937f61d98") {
      throw new GraphQLError("Error getting keys");
    }
    return sampleKeys
      .filter((k) => {
        // Filter by key name
        if (filter.positionName || searchbykeyname) {
          const searchKeyName = searchbykeyname ?? filter.positionName?._ilike;
          return k.positionName.indexOf((searchKeyName ?? "").replace("%", "").replace("%", "")) >= 0;
        }
        return true;
      })
      .filter((k) => {
        // Filter by cateogry name
        if (filter.keyCategoryPositionMapping?.keyCategory?.categoryName) {
          return filter.keyCategoryPositionMapping?.keyCategory.categoryName?._in?.find(
            (c) => c === k.keyCategoryPositionMapping.categoryName
          );
        }
        return true;
      })
      .filter((k) => {
        // Filter by node measurement
        if (filter.rooms && filter.rooms.nodeMeasurements?._and && filter.rooms.nodeMeasurements?._and.length > 0) {
          // Filter by Occupancy
          return every(filter.rooms.nodeMeasurements?._and, (andCondition) => {
            return some(andCondition?._or, (orCondition) => {
              if (orCondition?.occupancyState?._in) {
                return !!orCondition?.occupancyState?._in.find((n) =>
                  (k.rooms as any[]).find((r) => r.nodeMeasurements[0].occupancyState === parseInt(n))
                );
              }

              if (orCondition?.occupancyState?._is_null) {
                return !!(k.rooms as any[]).find((r) => r.nodeMeasurements[0].occupancyState === null);
              }

              if (orCondition?.door?._in) {
                return !!orCondition?.door?._in.find((n) =>
                  (k.rooms as any[]).find((r) => r.nodeMeasurements[0].door === parseInt(n))
                );
              }

              if (orCondition?.door?._is_null) {
                return !!(k.rooms as any[]).find((r) => r.nodeMeasurements[0].door === null);
              }

              if (orCondition?.acStatus?._in) {
                return !!orCondition?.acStatus?._in.find((n) =>
                  (k.rooms as any[]).find((r) => r.nodeMeasurements[0].acStatus === parseInt(n))
                );
              }

              if (orCondition?.acStatus?._is_null) {
                return !!(k.rooms as any[]).find((r) => r.nodeMeasurements[0].acStatus === null);
              }

              return false;
            });
          });
        }
        return true;
      })
      .filter((k) => {
        // Filter by automation mode
        if (filter.rooms && filter.rooms.positionConfiguration?.automationMode?._in) {
          return !!filter.rooms.positionConfiguration?.automationMode?._in?.find((n) =>
            (k.rooms as any[]).find((r) => r.positionConfiguration[0].automationMode === n)
          );
        }
        return true;
      })
      .filter((k) => {
        // Filter by settings overridden
        if (filter.rooms && filter.rooms.positionConfiguration?.isDeviatedFromDefaultConfig?._eq) {
          return !!(k.rooms as any[]).find(
            (r) =>
              r.positionConfiguration[0].isDeviatedFromDefaultConfig ===
              filter.rooms!.positionConfiguration?.isDeviatedFromDefaultConfig?._eq
          );
        }
        return true;
      })
      .filter((k) => {
        // Filter by min temp
        if (filter.rooms && filter.rooms.positionConfiguration?.minTemp?._gte) {
          return !!(k.rooms as any[]).find(
            (r) => parseInt(r.positionConfiguration[0].minTemp) >= filter.rooms!.positionConfiguration?.minTemp?._gte!
          );
        }
        return true;
      })
      .filter((k) => {
        // Filter by max temp
        if (filter.rooms && filter.rooms.positionConfiguration?.maxTemp?._lte) {
          return !!(k.rooms as any[]).find(
            (r) => parseInt(r.positionConfiguration[0].maxTemp) <= filter.rooms!.positionConfiguration?.maxTemp?._lte!
          );
        }
        return true;
      })
      .filter((k) => {
        // Filter by automation hour start
        if (filter.rooms && filter.rooms.positionConfiguration?.acNightStart?._eq) {
          return !!(k.rooms as any[]).find(
            (r) => r.positionConfiguration[0].acNightStart === filter.rooms!.positionConfiguration?.acNightStart?._eq
          );
        }

        return true;
      })
      .filter((k) => {
        // Filter by automation hour end
        if (filter.rooms && filter.rooms.positionConfiguration?.acNightEnd?._eq) {
          return !!(k.rooms as any[]).find(
            (r) => r.positionConfiguration[0].acNightEnd === filter.rooms!.positionConfiguration?.acNightEnd?._eq
          );
        }
        return true;
      })
      .filter((k) => {
        if (filter.positionId?._in) {
          return filter.positionId?._in.indexOf(k.positionId) !== -1;
        }
        return true;
      });
  }
  return sampleKeys;
};
