import { InfrastructuresQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { TestRun } from "pages/Checklist/Checklist";

enum PositionFunction {
  AUTOSET = "AUTOSET",
  COMPRESSOR = "COMPRESSOR",
  CHILLER = "CHILLER",
  PUMP = "PUMP",
  COOLING_TOWER = "COOLING_TOWER",
  GATEWAY = "GATEWAY",
}

enum PositionType {
  COMPRESSOR = "compressor",
  KEY = "key",
  METER_POSITION = "meter_position",
  GATEWAY = "gateway",
}

enum KeyEntryStatus {
  IN_PROGRESS = "in-progress",
  DONE = "done",
  DO_NOT_DISTURB = "do-not-disturb",
  PENDING = "pending",
  VISITED_REQUIRED = "visited required",
}

type KeyEntry = {
  keyEntryId: number;
  startedAt: Maybe<string>;
  endedAt?: Maybe<string>;
  userId?: string;
  keyPositionId: string;
  positionFunction: string | PositionFunction;
  isResumedEntry?: boolean;
  automaticTestRun?: boolean;
  isMeterPosition?: boolean;
  expiredAt: Maybe<string>;
  user?: {
    name: string;
  };
  status?: KeyEntryStatus;
};

enum KeyEntryCommentStatus {
  RESOLVED = "resolved",
}

type Infrastructure = InfrastructuresQuery["infrastructures"][number];

type OptionsEnterKey = {
  isResumedEntry: boolean;
  positionFunction: PositionFunction;
  redirectUrl?: string;
  keyPositionId: string;
  automaticTestRun?: boolean;
  isMeterPosition?: boolean;
};

interface InstallationModeContextState {
  currentPositionId: string;
  handleEnterKey: (options: OptionsEnterKey) => Promise<void>;
  handleExitKey: (onExit?: () => void) => Promise<void>;
  currentKeyEntryOfInstaller?: KeyEntry;
  currentKeyEntryOfPositions?: KeyEntry[];
  claimKeys?: KeyEntry[];
  keyName: string;
  isLoading: boolean;
  setCurrentPositionId: React.Dispatch<React.SetStateAction<string>>;
  setCurrentKeyEntryOfInstaller: React.Dispatch<React.SetStateAction<KeyEntry | undefined>>;
  setKeyPositionIds: React.Dispatch<React.SetStateAction<string[]>>;
  userId: any;
  testRuns: TestRun[];
  [x: string]: any;
}

export {
  KeyEntry,
  Infrastructure,
  InstallationModeContextState,
  PositionFunction,
  OptionsEnterKey,
  KeyEntryStatus,
  KeyEntryCommentStatus,
  PositionType,
};
