import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Col, Collapse, Pagination, Row } from "antd";
import { useParams } from "react-router-dom";
import { sum } from "lodash";

import { DEFAULT_PAGINATION } from "utils/constants";
import { AntdTablePagination } from "components/Table/Table.d";
import { generateAndDownloadCsv } from "utils/file";
import EnergyConsumptionFilter from "./EnergyConsumptionFilter";
import { EnergyConsumptionFilterInput, Key, Room } from "./EnergyConsumption.d";
import styles from "./EnergyConsumption.module.scss";
import {
  useKeysWithEnergyConsumptionLazyQuery,
  useKeysWithEnergyConsumptionQuery,
  useLocationQuery,
} from "../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../errorHandler";
import { formatDate, formatUTCTimestamp } from "../../utils/date";
import {
  getEnergyConsumptionFormat,
  getEnergyConsumptionCsvHeader,
  transformData,
  transformEnergyConsumptionDataToCsvData,
} from "./EnergyConsumption.helper";

const { Panel } = Collapse;

const EnergyConsumption = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [filter, setFilter] = useState<EnergyConsumptionFilterInput>();
  const [keys, setKeys] = useState<Key[]>();
  const [pagination, setPagination] = useState<AntdTablePagination>(DEFAULT_PAGINATION);
  const [locationName, setLocationName] = useState<string>();
  const limit = pagination.pageSize;
  const offset = (pagination.current - 1) * pagination.pageSize;

  useLocationQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data.location) {
        setLocationName(data.location.locationName);
      }
    },
  });

  const [keysWithEnergyConsumptionQuery, { loading }] = useKeysWithEnergyConsumptionLazyQuery({
    onCompleted: (data) => {
      setKeys(data.keys.keys);
      setPagination({
        ...pagination,
        total: data.keys.pagination.count!,
      });
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    if (!filter) return;
    keysWithEnergyConsumptionQuery({
      variables: {
        fromDate: formatUTCTimestamp(filter.dateFrom)!,
        toDate: formatUTCTimestamp(filter.dateTo)!,
        filter: {
          locationId,
          keyIds: filter.keys,
        },
        pagination: {
          limit,
          offset,
        },
      },
    });
  }, [filter, locationId, keysWithEnergyConsumptionQuery, limit, offset]);

  const keyHeaderView = (key: Key) => {
    const totalEnergyConsumption = sum(key.rooms.filter((r) => r.energyConsumption).map((r) => r.energyConsumption));
    const hasRoomData =
      key.rooms.filter((r) => r.energyConsumption !== null && r.energyConsumption !== undefined).length > 0;
    return (
      <div className="d-flex justify-content-between">
        <span>{key.keyName}</span>
        <span>{`${getEnergyConsumptionFormat(hasRoomData ? totalEnergyConsumption : null)}`}</span>
      </div>
    );
  };

  const roomListView = (rooms: Room[]) => {
    return (
      <ul className={classnames("p-none m-none", styles.rooms)}>
        {rooms.map((r) => {
          return (
            <li className={classnames("d-flex justify-content-between", styles.collapsibleRowPadding)} key={r.roomId}>
              <span className={styles.collapsibleRowIndent}>{r.roomName}</span>
              <span>{getEnergyConsumptionFormat(r.energyConsumption)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const handleChangePage = (page: number) => {
    setPagination({
      ...pagination,
      current: page,
    });
  };

  const { refetch } = useKeysWithEnergyConsumptionQuery();

  const triggerCsvExport = async () => {
    if (filter && filter.keys) {
      let keysData: Key[] = [];
      let offsetOfRefetchData = 0;
      while (offsetOfRefetchData < filter.keys.length) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await refetch({
          fromDate: formatUTCTimestamp(filter.dateFrom)!,
          toDate: formatUTCTimestamp(filter.dateTo)!,
          filter: {
            locationId,
            keyIds: filter.keys,
          },
          pagination: {
            limit: 100,
            offset: offsetOfRefetchData,
          },
        });
        keysData = [...keysData, ...data.keys.keys];
        offsetOfRefetchData += 100;
      }
      generateAndDownloadCsv({
        headers: getEnergyConsumptionCsvHeader(),
        data: transformEnergyConsumptionDataToCsvData(transformData(keysData), {
          headers: getEnergyConsumptionCsvHeader(),
        }),
        fileName: `Energy Consumption_${locationName}_${formatDate(filter.dateFrom)}-${formatDate(filter.dateTo)}`,
      });
    }
  };

  return (
    <div>
      <h2 className="fs-xl font-weight-bold mb-m">Energy Consumption Per Key</h2>
      <EnergyConsumptionFilter
        locationId={locationId}
        onFilterChanged={setFilter}
        isEmptyData={!keys}
        onExportData={triggerCsvExport}
      />
      <div className="p-xs" />
      <Row>
        <Col lg={12} xs={24}>
          {loading && <p>Loading...</p>}
          {!loading && keys?.length && (
            <>
              <div className="mb-l">
                <div
                  className={classnames(
                    "d-flex justify-content-between bg-light-gray",
                    styles.collapsibleRowBorder,
                    styles.collapsibleRowPadding
                  )}
                >
                  <span className={classnames("font-weight-bold", styles.collapsibleRowIndent)}>Key</span>
                  <span className="font-weight-bold">ENERGY CONSUMED</span>
                </div>
                <Collapse>
                  {keys?.map((k) => {
                    return (
                      <Panel header={keyHeaderView(k)} className="no-padding-collapsible-tab" key={k.keyId}>
                        {roomListView(k.rooms)}
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
              <div className="mb-l">
                <Pagination onChange={handleChangePage} showSizeChanger={false} {...pagination} />
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EnergyConsumption;
