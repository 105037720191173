import { Col, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  LocationFilterInput,
  LocationsForCustomerQuery,
  SortOptions,
  useLocationsForCustomerQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { AntdTablePagination } from "components/Table/Table.d";
import ListFilter from "components/ListFilter/ListFilter";
import { Location } from "./LocationList.d";
import LocationListTable from "./LocationListTable";
import useMenuNavigation from "../../../hooks/use-menu-navigation";
import useRoleAndPermission from "../../../hooks/use-role-and-permission";
import { getPaginationMessage } from "../Location.helper";

const LocationList = () => {
  const defaultPagination = { current: 1, pageSize: 10, total: 0 };
  const [pagination, setPagination] = useState<AntdTablePagination>(defaultPagination);
  const [tableData, setTableData] = useState<Location[]>([]);
  const [filter, setFilter] = useState<LocationFilterInput>({});
  const menuNavigation = useMenuNavigation();

  const roleAndPermission = useRoleAndPermission();

  const queryVariables = {
    filter,
    sort: { locationName: SortOptions.Asc },
    pagination: {
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
  };

  const handleCompletedDataFetching = (data: LocationsForCustomerQuery) => {
    setTableData(data.locationsAssignedToUser?.locations || []);
    // we're only interested in the total from the server, the current and pageSize are managed in local state
    setPagination({
      ...pagination,
      total: data.locationsAssignedToUser?.pagination?.count || 0,
    });
    menuNavigation.setupLocationMenu(data.locationsAssignedToUser?.locations);
  };

  const { loading, error } = useLocationsForCustomerQuery({
    variables: queryVariables,
    onCompleted: handleCompletedDataFetching,
  });

  const handleTableChange = (desiredPagination: AntdTablePagination) => {
    setPagination(desiredPagination);
  };

  const statMessage = useMemo(() => {
    if (tableData.length === 0) return "";
    return getPaginationMessage(pagination);
  }, [tableData, pagination]);

  const handleFilterChange = (newFilter: LocationFilterInput) => {
    setPagination(defaultPagination);
    setFilter(newFilter);
  };

  useEffect(() => {
    if (tableData) {
      menuNavigation.setupLocationMenu(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <>
      <Row className="mb-m">
        <Col>
          <h2 className="d-inline font-weight-bold mr-l">Locations</h2>
          <span className="d-inline">{statMessage}</span>
        </Col>
      </Row>
      <Row className="mb-l">
        <Col span={24}>
          <ListFilter
            currentFilter={filter}
            onChange={handleFilterChange}
            searchField="locationName"
            searchPlaceholder="Type to search"
            hideAdvancedFilter={roleAndPermission.isCustomer()}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LocationListTable
            tableData={tableData}
            pagination={{
              ...pagination,
              pageSizeOption: ["10", "20", "50", "100"],
              showSizeChanger: true,
            }}
            handleTableChange={handleTableChange}
            loading={loading}
            error={error}
          />
        </Col>
      </Row>
    </>
  );
};

export default LocationList;
