import { Button, Typography } from "antd";
import { CalendarIcon, ClockIcon, EditIcon } from "components/Icons";
import React, { useMemo } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { DATE_FORMAT } from "utils/date";
import { ScheduleType } from "./PrecoolScheduleModal";
import { PrecoolScheduleDeleteButton } from "./PrecoolScheduleDeleteConfirm";

export interface PrecoolScheduleItemProps {
  scheduleId: number;
  time: number;
  startedAt: string;
  endedAt: string;
  weekDay: any;
  type: any;
  onEdit?: () => Promise<void> | void;
  onDelete?: () => Promise<any> | void;
}

export const PrecoolScheduleItem: React.FC<PrecoolScheduleItemProps> = ({
  type,
  weekDay,
  startedAt,
  endedAt,
  time,
  scheduleId,
  onDelete,
  onEdit,
}) => {
  const calendarValue = useMemo(() => {
    return type === ScheduleType.DAYS_OF_THE_WEEK
      ? (weekDay || "")
          .split(", ")
          .map((day: string) => capitalize(day))
          .join(" • ")
      : `${moment(startedAt).format(DATE_FORMAT)} - ${moment(endedAt).format(DATE_FORMAT)}`;
  }, [endedAt, startedAt, type, weekDay]);

  const timeValue = useMemo(() => {
    return moment().startOf("day").add(time, "minutes").format("hh:mm A");
  }, [time]);
  return (
    <div
      className="d-flex flex-column align-items-start radius-xs border border-dark-blue precool-schedule-item"
      data-testid="precool-schedule-item"
      data-itemId={`precool-schedule-item-${scheduleId}`}
    >
      <div className="precool-schedule-item-content">
        <div className="precool-schedule-item-content-desc">
          <Typography.Text typeof="p">
            <CalendarIcon /> {calendarValue}
          </Typography.Text>
          <Typography.Text typeof="p">
            <ClockIcon /> {timeValue}
          </Typography.Text>
        </div>
      </div>
      <div className="precool-schedule-item-actions">
        <Button
          type="default"
          icon={<EditIcon />}
          className="precool-schedule-item-actions-btn precool-schedule-item-actions-edit"
          onClick={onEdit}
        >
          Edit schedule
        </Button>
        <PrecoolScheduleDeleteButton scheduleId={scheduleId} onDelete={onDelete} />
      </div>
    </div>
  );
};
