import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Row, Space, Typography } from "antd";
import Can from "components/Can/Can";
import ListFilter from "components/ListFilter/ListFilter";
import useBreakpoint from "hooks/use-breakpoint";
import { Permission } from "pacts/permission";
import {
  InfrastructuresWithOrderByAndTotalCountQueryVariables,
  Order_By,
  useInfrastructureListSubscription,
  useInfrastructuresWithOrderByAndTotalCountQuery,
  useLocationGroupsAndKeysQuery,
  useLocationKeysAndRoomsQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import ModalWrapper from "components/Modal/Wrapper";
import useModalWrapperTrigger from "hooks/use-modal-wrapper-trigger";
import useRoleAndPermission from "hooks/use-role-and-permission";
import { getObjBySearch } from "utils/url";
import usePagination from "pages/Key/hooks/use-pagination";
import { getOrderByFromSort } from "utils/DataTransformer";
import { getPaginationMessage } from "pages/Location/Location.helper";
import { filterOptions, Infrastructure } from "./Infrastructures.d";
import AddInfrastructureModal from "./AddInfrastructureModal/AddInfrastructureModal";
import AddCompressorModal from "./AddCompressorModal/AddCompressorModal";
import { transformToInfrastructuresQueryVariables } from "./Infrastructures.transform";
import "./Infrastructures.scss";
import { InfrastructureTable } from "./InfrastructureTable";
import UpdateCompressorLocationModal from "./UpdateCompressorLocation/UpdateCompressorLocation";

type InfrastructuresProps = {
  locationName?: string;
};

const InfrastructuresV2: React.FC<InfrastructuresProps> = ({ locationName }) => {
  const screen = useBreakpoint();
  const location = useLocation();
  const roleAndPermission = useRoleAndPermission();
  const { locationId } = useParams<{ locationId: string }>();
  const [infrastructures, setInfrastructures] = useState<Infrastructure[]>([]);
  const [roomAutosets, setRoomAutosets] = useState<any[]>([]);
  const [groupsAndKeys, setGroupsAndKeys] = useState<any[]>([]);
  const [currentFilter, setCurrentFilter] = useState(
    getObjBySearch<{ infraName: string | undefined }>(location.search)
  );
  const [sort, setSort] = useState<any>({
    name: Order_By.Asc,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedInfras, setSelectedInfras] = useState<Infrastructure[]>([]);
  const { pagination, resetPagination, setPagination, handlePaginationChange } = usePagination();

  const [addInfrastructureModalRef, openAddInfrastructureModal] = useModalWrapperTrigger();
  const [addCompressorModalRef, openAddCompressorModal] = useModalWrapperTrigger();
  const [updateCompressorLocationModalRef, openUpdateCompressorLocationModalRef] = useModalWrapperTrigger();

  const infraVariables: InfrastructuresWithOrderByAndTotalCountQueryVariables = useMemo(
    () => ({
      where: transformToInfrastructuresQueryVariables(roleAndPermission.isContractor(), locationId, currentFilter),
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      order_by: [getOrderByFromSort(sort)],
    }),
    [roleAndPermission, locationId, currentFilter, pagination, sort]
  );

  const { loading: isLoadingList } = useInfrastructuresWithOrderByAndTotalCountQuery({
    variables: infraVariables,
    onCompleted: (infrastructureData) => {
      setInfrastructures(infrastructureData?.infrastructures);
      setPagination((prev) => ({
        ...prev,
        total: infrastructureData?.sensorflow_v_infrastructures_aggregate?.aggregate?.count || 0,
      }));
      setIsLoading(false);
    },
  });

  const infraIds = useMemo(() => infrastructures.map((infra) => infra.id), [infrastructures]);

  useInfrastructureListSubscription({
    skip: isLoading || infraIds.length === 0,
    variables: {
      where: { id: { _in: infraIds } },
      offset: 0,
      limit: pagination.pageSize,
    },
    onSubscriptionData: (data) => {
      const infrastructureData = data.subscriptionData.data;
      setInfrastructures(infrastructureData?.infrastructures || []);
    },
  });

  useLocationKeysAndRoomsQuery({
    variables: { locationId },
    onCompleted: (data) => setRoomAutosets(data.positions),
  });

  useLocationGroupsAndKeysQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setGroupsAndKeys(data.positions);
    },
  });

  const reloadInfrastructures = useCallback(
    async (filter?: any) => {
      resetPagination();
      setCurrentFilter(filter);
      setSelectedInfras([]);
    },
    [resetPagination]
  );

  const handleSortChange = (columnHeader: string) => {
    setSort({ [columnHeader]: sort[columnHeader] === Order_By.Asc ? Order_By.Desc : Order_By.Asc });
  };

  useEffect(() => {
    setIsLoading(isLoadingList);
  }, [isLoadingList]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24} md={4}>
          <Space direction={screen.desktopUp ? "horizontal" : "vertical"}>
            <Typography.Title level={3} className="mb-none">
              Infrastructures
            </Typography.Title>
          </Space>
        </Col>
        <Col span={24} md={20} style={{ textAlign: "right" }}>
          <Space direction={screen.desktopUp ? "horizontal" : "vertical"} wrap>
            <ListFilter
              onChange={reloadInfrastructures}
              currentFilter={currentFilter}
              searchField="infraName"
              searchPlaceholder="Type to search"
              filterOptions={filterOptions}
              hideAdvancedFilter={roleAndPermission.isContractor()}
              isSaveKeySearch={roleAndPermission.isContractor()}
              defaultKeySearch={roleAndPermission.isContractor() ? currentFilter?.infraName : undefined}
            />
            <Can
              requiredPermission={Permission.INFRASTRUCTURE_EDIT}
              yes={
                <Button
                  type="primary"
                  onClick={openUpdateCompressorLocationModalRef}
                  disabled={!selectedInfras?.length}
                >
                  Update Compressor Location
                </Button>
              }
            />
            <Can
              requiredPermission={Permission.INFRASTRUCTURE_CREATE}
              yes={
                <Button type="primary" onClick={openAddInfrastructureModal}>
                  Add Infrastructure
                </Button>
              }
            />
            <Can
              requiredPermission={Permission.INFRASTRUCTURE_CREATE}
              yes={
                <Button type="primary" onClick={openAddCompressorModal}>
                  Add 1:1 Compressor
                </Button>
              }
            />
          </Space>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space direction={screen.desktopUp ? "horizontal" : "vertical"}>
            <Typography.Text>
              {getPaginationMessage(pagination, "infrastructures")} in {locationName}
            </Typography.Text>
          </Space>
        </Col>
      </Row>

      <Row className="infra-list mt-l" gutter={[16, 16]}>
        <Col span={24}>
          <InfrastructureTable
            locationName={locationName}
            locationId={locationId}
            data={infrastructures}
            pagination={pagination}
            sort={sort}
            sortBy={handleSortChange}
            onPaginationChange={handlePaginationChange}
            rowSelection={{
              selectedRowKeys: selectedInfras.map((infra) => infra.id),
              type: "checkbox",
              onChange: (selectedRowKeys: React.Key[], selectedRows: Infrastructure[]) => {
                setSelectedInfras(selectedRows);
              },
            }}
          />
        </Col>
      </Row>

      <ModalWrapper
        ref={addInfrastructureModalRef}
        modal={AddInfrastructureModal}
        props={{ onSuccess: reloadInfrastructures }}
      />
      <ModalWrapper
        ref={addCompressorModalRef}
        modal={AddCompressorModal}
        props={{ roomAutosets, onSuccess: reloadInfrastructures }}
      />
      <ModalWrapper
        ref={updateCompressorLocationModalRef}
        modal={UpdateCompressorLocationModal}
        props={{
          groupsAndKeys,
          selectedCompressorIds: selectedInfras.map((infra) => infra.id),
          selectedCompressorLocationsAndTypes: selectedInfras.map((infra) => [
            infra.position?.positionName,
            infra.position?.positionType,
          ]),
          onSuccess: reloadInfrastructures,
        }}
      />
    </div>
  );
};

export default InfrastructuresV2;
