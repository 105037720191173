import React from "react";
import "./PrecoolOverallStatusDetail.scss";
import { ClockIcon, CoolIcon, FanFilledIcon, ThermostatIcon } from "components/Icons";
import { Modal } from "antd";
import { capitalize } from "lodash";
import classNames from "classnames";
import { PrecoolOverallStatusDetailProps } from "./PrecoolOverallStatusDetail.d";

const PrecoolOverallStatusDetail: React.FC<PrecoolOverallStatusDetailProps> = ({
  modalWrapperProps,
  precoolKeyStatus,
}) => {
  return (
    <Modal
      className="pre-cool-overall-status-detail"
      title={<span className="pre-cool-overall-status-detail-title">Room temperature</span>}
      {...modalWrapperProps}
      footer={null}
    >
      <div className="pre-cool-overall-status-detail-temp precool-overall-status">
        <div className="precool-overall-status-temp">
          <p className="precool-overall-status-temp-title">Average Temp.</p>
          <p className="precool-overall-status-temp-content">
            <ThermostatIcon /> {precoolKeyStatus?.averageTemp}°C
          </p>
        </div>
      </div>

      <div className="pre-cool-overall-status-detail-list">
        {precoolKeyStatus.rooms.map(({ acModeCoolOn, fanOn, name, scheduleOn, status, temp }) => {
          return (
            <div className="pre-cool-overall-status-detail-list-item">
              <div className="pre-cool-overall-status-detail-list-item-title">{name}</div>
              <div className={classNames("pre-cool-overall-status-detail-list-item-chip", status)}>
                {capitalize(status)}
              </div>
              <div className="pre-cool-overall-status-detail-list-item-icons">
                {acModeCoolOn && <CoolIcon className="precool-overall-icon ac-mode-icon" />}
                {fanOn && <FanFilledIcon className="precool-overall-icon fan-icon" />}
                {scheduleOn && <ClockIcon className="precool-overall-icon schedule-icon" />}
              </div>
              <div className="pre-cool-overall-status-detail-list-item-content">{temp}°C</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default PrecoolOverallStatusDetail;
