import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider } from "antd";
import ObjectHealth from "components/ObjectHealth";
import get from "lodash/get";
import useLocationActions from "pages/Location/LocationActions/LocationActions";

export default function LocationListGrid(record: any) {
  const actionsForLocation = useLocationActions();

  const { locationName, id, locationStats } = record;

  return (
    <>
      <div>
        <h3 className="fs-xl">{locationName}</h3>
      </div>
      <div>
        {actionsForLocation(id).map((action) => (
          <Button key={action.name} type="primary" className="mb-s mr-s">
            <Link to={action.linkTo}>{action.name}</Link>
          </Button>
        ))}
      </div>
      <Divider className="my-m" />
      <div className="d-flex justify-content-between">
        <div>
          <span className="d-block font-weight-bold fs-sm">KEYS</span>
          <span className="d-block fs-sm">{`${locationStats?.keysStats?.mappedCount || 0}/${
            locationStats?.keysStats?.totalCount || 0
          }`}</span>
        </div>
        <div>
          <span className="d-block font-weight-bold fs-sm">GATEWAYS</span>
          <span className="d-block fs-sm">{`${get(locationStats, "gatewaysStats.mappedCount", 0)}/${get(
            locationStats,
            "gatewaysStats.totalCount",
            0
          )}`}</span>
        </div>
        <div>
          <span className="d-block font-weight-bold fs-sm">LOCATION HEALTH</span>
          <ObjectHealth
            online={get(locationStats, "nodeHealthStats.onlineCount", 0)}
            offline={get(locationStats, "nodeHealthStats.offlineCount", 0)}
            booted={get(locationStats, "nodeHealthStats.bootedCount", 0)}
          />
        </div>
      </div>
    </>
  );
}
