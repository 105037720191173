import React, { useMemo, useState } from "react";
import { Button, Col, Row, Typography, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import BreadcrumbV2 from "components/Breadcrumb/BreadcrumbV2";
import {
  ComfortplusKeyDetailsQuery,
  GetPrecoolSchedulesQuery,
  useComfortplusKeyDetailsQuery,
  useGetPrecoolSchedulesQuery,
  useSaveGuestPrecoolScheduleMutation,
} from "pacts/app-webcore/hasura-webcore.graphql";
import moment from "moment";
import errorHandler from "errorHandler";
import useSetComfortPlusParams from "pages/ComfortPlus/use-comfortplus-params";
import InvalidUrl from "pages/ComfortPlus/InvalidUrl";
import { PrecoolScheduleProps } from "./PrecoolSchedule.d";
import { FormValues, PrecoolScheduleModal, ScheduleType } from "./components/PrecoolScheduleModal";
import "./PrecoolSchedule.scss";
import { PrecoolScheduleEmpty } from "./components/PrecoolScheduleEmpty";
import { PrecoolScheduleItem } from "./components/PrecoolScheduleItem";
import { DoWOptions } from "./components/DaysOfTheWeek";
import ContentMessage from "./components/ContentMessage";

message.config({ top: 0 });

const initForm: FormValues = {
  scheduleType: ScheduleType.EVERY_DAY,
  weekDay: [],
  time: null,
  id: null,
};

const formatFormPrecoolSchedule = (
  value: FormValues,
  comfortplusKeyDetail: ComfortplusKeyDetailsQuery["data"][number]
) => {
  const { time, dateRange = [], weekDay = [], id, scheduleType } = value;
  const timeAsMinutes = moment.duration(moment(time).format("HH:mm")).asMinutes();
  const startedAt =
    scheduleType === ScheduleType.DATE_RANGE ? moment(dateRange[0]).startOf("day") : comfortplusKeyDetail.checkInDate;
  const endedAt =
    scheduleType === ScheduleType.DATE_RANGE ? moment(dateRange[1]).endOf("day") : comfortplusKeyDetail.checkOutDate;

  return {
    time: timeAsMinutes,
    startedAt: moment(startedAt).format(),
    endedAt: moment(endedAt).format(),
    weekDay: weekDay.join(", "),
    id: id || undefined,
    type: scheduleType,
  };
};

const PrecoolSchedule: React.FC<PrecoolScheduleProps> = () => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initForm);

  const { accessToken } = useSetComfortPlusParams();
  const { data: comfortplusKeyDetails } = useComfortplusKeyDetailsQuery({ skip: !accessToken });
  const { data, refetch } = useGetPrecoolSchedulesQuery({ skip: !accessToken, variables: {} });

  const handleOnClose = () => {
    setOpenModal(false);
    setFormData(initForm);
  };

  const [savePrecoolSchedule] = useSaveGuestPrecoolScheduleMutation({
    onError: errorHandler.handleError,
    onCompleted: () => {
      const msg = formData.id
        ? "A new schedule has been updated successfully."
        : "A new schedule has been set successfully.";
      message.open({
        type: "success",
        content: <ContentMessage content={msg} />,
        className: "precool-comfortPlus",
      });
      handleOnClose();
      refetch();
    },
  });
  const comfortplusKeyDetail = useMemo(() => comfortplusKeyDetails?.data[0], [comfortplusKeyDetails]);

  const handleOnOpeModal = () => {
    const { checkInDate, checkOutDate } = comfortplusKeyDetail || {};
    setFormData({
      ...initForm,
      dateRange: checkInDate && checkOutDate ? [moment(checkInDate), moment(checkOutDate)] : [],
      weekDay: DoWOptions,
    });
    setOpenModal(true);
  };

  const handleOnEdit = (value: GetPrecoolSchedulesQuery["sensorflow_precool_schedules"][number]) => {
    const { id, endedAt, time, type, weekDay, startedAt } = value;
    const formatTime = moment().startOf("day").add(time, "minutes");
    setFormData({
      id,
      time: formatTime,
      scheduleType: type as any,
      dateRange: [moment(startedAt), moment(endedAt)],
      weekDay: (weekDay || "").split(", "),
    });
    setOpenModal(true);
  };

  const handleOnSubmit = async (values: FormValues) => {
    if (!comfortplusKeyDetail) return;
    const formatValue = formatFormPrecoolSchedule(values, comfortplusKeyDetail);
    await savePrecoolSchedule({ variables: { input: { ...formatValue } } });
  };

  if (!accessToken) return <InvalidUrl />;

  return (
    <div>
      <BreadcrumbV2 steps={[{ title: "Home", link: "/public/comfortplus" }, { title: "My schedule" }]} />
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Typography.Title level={3}>My schedule</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Button icon={<PlusOutlined />} type="primary" onClick={handleOnOpeModal} data-testId="btn-add-schedule">
            Add schedule
          </Button>
        </Col>

        <Col span={24}>
          {!data?.sensorflow_precool_schedules?.length ? (
            <PrecoolScheduleEmpty />
          ) : (
            <Row gutter={[12, 12]}>
              {data?.sensorflow_precool_schedules.map((item) => {
                return (
                  <Col span={24} key={item.id}>
                    <PrecoolScheduleItem
                      scheduleId={item.id}
                      startedAt={item.startedAt}
                      endedAt={item.endedAt}
                      type={item.type}
                      time={item.time}
                      weekDay={item.weekDay}
                      onEdit={() => handleOnEdit(item)}
                      onDelete={() => refetch()}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row>
      <PrecoolScheduleModal
        open={openModal}
        values={formData}
        checkInDate={comfortplusKeyDetail?.checkInDate}
        checkOutDate={comfortplusKeyDetail?.checkOutDate}
        onClose={handleOnClose}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
};

export default PrecoolSchedule;
