import React, { useState } from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
import { Button, Drawer, Menu } from "antd";
import { useAuth0 } from "services/auth/authService";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { MenuConsumer } from "contexts/menu-context";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useBreakpoint from "../../hooks/use-breakpoint";
import { URL_CONFIG } from "../../config";
import { ReactComponent as Logo } from "../Icons/assets/logo.svg";
import LeftMenu from "./LeftMenu";
import styles from "./header.module.scss";

const { Item } = Menu;

const Header = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [showMenu, setShowMenu] = useState(false);

  const userRole = useRoleAndPermission();

  const screen = useBreakpoint();

  const dashboardName = !userRole.isCustomer() && userRole.getRoleName();

  const showDrawer = () => {
    setShowMenu(true);
  };

  return (
    <header className={styles.headerBgColor}>
      <div
        className={classnames("d-flex align-items-center py-m justify-content-between", {
          "main-container-lg": screen.desktopUp,
          "main-container m-menu": screen.mobileAndTabletOnly,
        })}
      >
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
        {isAuthenticated && (
          <>
            {screen.desktopUp && (
              <>
                <LeftMenu theme="light" />
                <div className={styles.accountName}>
                  <span>{user.name}</span>
                  <span>{dashboardName}</span>
                </div>
                <span key="loginLogoutButton">
                  <Button type="primary" onClick={() => logout({ returnTo: URL_CONFIG.AFTER_LOGOUT_RETURN_TO })}>
                    Log out
                  </Button>
                </span>
              </>
            )}
            {screen.mobileAndTabletOnly && (
              <>
                <Button icon={<MenuOutlined />} type="default" onClick={showDrawer} />
                <Drawer
                  title={
                    <div>
                      <div className="d-flex align-items-center">
                        <span className={classnames("text-eclipse", styles.mobileMenuHeaderText)}>
                          Hi, <span className="fs-m font-weight-bold">{user.name}</span>
                        </span>
                        <Button
                          className={styles.sideMenuCloseBtn}
                          type="link"
                          icon={<CloseOutlined />}
                          onClick={() => setShowMenu(false)}
                        />
                      </div>
                      <span className={styles.dashboardName}>{dashboardName}</span>
                    </div>
                  }
                  placement="right"
                  closable={false}
                  className={classnames({
                    [styles.mobileMenu]: screen.mobileAndTabletOnly,
                  })}
                  onClose={() => {
                    setShowMenu(false);
                  }}
                  visible={showMenu}
                >
                  <LeftMenu theme="light" onItemClick={() => setShowMenu(false)} />
                  <MenuConsumer>{(ctx) => ctx.dynamicMenu}</MenuConsumer>
                  <Menu mode={screen.desktopUp ? "horizontal" : "inline"} theme="light">
                    <Item>
                      <Button
                        type="link"
                        className="p-none"
                        onClick={() => {
                          setShowMenu(false);
                          logout({ returnTo: URL_CONFIG.AFTER_LOGOUT_RETURN_TO });
                        }}
                      >
                        Log out
                      </Button>
                    </Item>
                  </Menu>
                </Drawer>
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
