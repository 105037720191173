import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Row, Select } from "antd";
import classnames from "classnames";
import moment, { Moment } from "moment";
import { CsvExportBtn } from "components/CsvExport/CsvExportBtn";
import { DATETIME_24H_FORMAT } from "../../utils/date";
import useBreakpoint from "../../hooks/use-breakpoint";
import { EnergyConsumptionFilterInput, KeyDropdown } from "./EnergyConsumption.d";
import {
  useKeysLazyQuery,
  useGetKeysForDropdownQuery,
  GetKeysForDropdownQuery,
} from "../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../errorHandler";

const EnergyConsumptionFilter = (props: {
  locationId: string;
  onFilterChanged: (input: EnergyConsumptionFilterInput) => void;
  onExportData: () => void;
  isEmptyData: boolean;
}) => {
  const { onFilterChanged, onExportData, locationId, isEmptyData } = props;
  const [dateRange, setDateRange] = useState<Moment[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [keys, setKeys] = useState<KeyDropdown[]>([]);
  const [allKeys, setAllKeys] = useState<GetKeysForDropdownQuery["positions"]>([]);

  const screen = useBreakpoint();

  const isAllValuesInputted = dateRange && dateRange.length > 1 && selectedKeys.length > 0;

  const [keyQuery, { loading }] = useKeysLazyQuery({
    onCompleted: (data) => {
      const keyData: KeyDropdown[] = data.keys.keys.map((key) => {
        return {
          keyId: key.keyId,
          keyName: key.keyName,
        };
      });
      setKeys(keyData);
    },
    onError: errorHandler.handleError,
  });

  useGetKeysForDropdownQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      setAllKeys(data.positions);
    },
  });

  const onSearch = (search: string) => {
    keyQuery({
      variables: {
        filter: {
          keyName: search,
          locationId,
        },
        pagination: {
          limit: 10,
          offset: 0,
        },
      },
    });
  };

  useEffect(() => {
    onSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = () => {
    if (!isAllValuesInputted) return;
    onFilterChanged({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      keys: selectedKeys,
    });
  };

  const clearFilters = () => {
    setDateRange([]);
    setSelectedKeys([]);
  };

  const selectAll = () => {
    const selectedIds: string[] = allKeys.map((key) => key.positionId);
    const keyDropdownData: KeyDropdown[] = allKeys.map((key) => {
      return {
        keyId: key.positionId,
        keyName: key.positionName,
      };
    });
    setSelectedKeys(selectedIds);
    setKeys(keyDropdownData);
  };

  return (
    <Row align="bottom">
      <Col lg={6} xs={24} className="mb-m">
        <span>Date Range</span>
        <DatePicker.RangePicker
          value={dateRange as any}
          format={DATETIME_24H_FORMAT}
          data-testid="range-picker-testId"
          showTime={{
            defaultValue: [moment("00:00", "HH:mm"), moment("00:00", "HH:mm")],
          }}
          className="w-100"
          onChange={(val) => setDateRange(val as Moment[])}
        />
      </Col>
      <Col lg={6} xs={24} className={classnames("mb-m", { "ml-m": screen.desktopUp })}>
        <div className="d-flex justify-content-between">
          <span className="pt-s">Keys</span>
          <Button className="d-flex justify-content-end p-none pt-s" onClick={selectAll} type="link">
            Select all
          </Button>
        </div>
        <Select
          className="w-100"
          mode="multiple"
          data-testid="selected-keys-testId"
          placeholder="Please select keys"
          showSearch
          loading={loading}
          filterOption={false}
          notFoundContent="No key found"
          onSearch={onSearch}
          onChange={(val) => setSelectedKeys(val as string[])}
          value={selectedKeys}
          maxTagCount={5}
        >
          {keys.map((k) => (
            <Select.Option key={k.keyId} value={k.keyId}>
              {k.keyName}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col
        lg={6}
        xs={24}
        className={classnames("mb-m", { "ml-m": screen.desktopUp, "text-center": !screen.desktopUp })}
      >
        <Button type="default" onClick={clearFilters}>
          Clear
        </Button>
        <Button type="primary" className="ml-m" onClick={onConfirm} disabled={!isAllValuesInputted}>
          Confirm
        </Button>
        <CsvExportBtn onExportTrigger={() => onExportData()} classNames="ml-m" disabled={isEmptyData} />
      </Col>
    </Row>
  );
};

export default EnergyConsumptionFilter;
