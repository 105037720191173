import React, { useMemo, useState } from "react";
import { Modal, TreeSelect, message } from "antd";
import { ModalWrapperProps } from "components/Modal/Modal.d";
import { transformAutosetsToTreeNodes } from "utils/DataTransformer";
import { useBulkAssignCompressorPositionsMutation } from "pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "errorHandler";
import { PositionType } from "types/types";

interface AddCompressorModalProps {
  groupsAndKeys: any[];
  selectedCompressorIds: string[];
  selectedCompressorLocationsAndTypes?: [string, PositionType][];
  isOnlyUpdateCompressorPosition?: boolean;
  onSuccess: () => void;
}

const validTypes = new Set([
  PositionType.FLOOR,
  PositionType.GROUP,
  PositionType.KEY,
  PositionType.ROOM,
  PositionType.ZONE,
]);

const UpdateCompressorLocationModal = ({
  groupsAndKeys,
  onSuccess,
  modalWrapperProps,
  closeModal,
  selectedCompressorIds,
  selectedCompressorLocationsAndTypes,
  isOnlyUpdateCompressorPosition,
}: AddCompressorModalProps & ModalWrapperProps) => {
  const initCompressorLoc = useMemo(() => {
    if (selectedCompressorLocationsAndTypes && selectedCompressorLocationsAndTypes.length === 1) {
      const [name, type] = selectedCompressorLocationsAndTypes[0];
      return validTypes.has(type) ? name : "--";
    }
    return "--";
  }, [selectedCompressorLocationsAndTypes]);

  const [position, setPosition] = useState<string>(initCompressorLoc);

  const [updateCompressors] = useBulkAssignCompressorPositionsMutation({
    onCompleted: () => {
      message.success("Updated completed.");
    },
    onError: (error) => {
      errorHandler.handleError(error);
    },
  });

  const handleSubmit = async () => {
    if (position) {
      let positionId;
      try {
        positionId = JSON.parse(position).positionId;
      } catch (e) {
        positionId = position;
      }
      await updateCompressors({
        variables: {
          compressorIds: selectedCompressorIds,
          positionId,
        },
      });
    }
  };

  const onSuccessCallback = async () => {
    await handleSubmit();
    closeModal();
    onSuccess();
  };

  const positionSelectorProps = {
    treeData: transformAutosetsToTreeNodes(groupsAndKeys),
    value: position,
    onChange: (data: string) => setPosition(data),
    treeCheckable: false,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: "Please Select Position",
    showSearch: true,
    className: "w-100",
    allowClear: true,
    treeDefaultExpandAll: true,
  };

  return (
    <Modal
      title={isOnlyUpdateCompressorPosition ? "Update Compressor Location" : "Update 1:1 Compressor"}
      centered
      onOk={onSuccessCallback}
      width={450}
      okText="Update"
      {...modalWrapperProps}
    >
      <div>
        <p className="mb-xs">Compressor Location</p>
        <TreeSelect {...positionSelectorProps} />
      </div>
    </Modal>
  );
};

export default UpdateCompressorLocationModal;
