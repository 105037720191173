import React from "react";
import { Col, Divider, Row, Table } from "antd";
import { formatDateTime24h } from "utils/date";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import useBreakpoint from "hooks/use-breakpoint";
import { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import { NodeLifecycleType, NodeLifecycleEventTableProps } from "./NodeLifecycleEventTable.d";

const NodeLifecycleEventTable = (props: NodeLifecycleEventTableProps) => {
  const { data } = props;
  const screen = useBreakpoint();
  const columnConfig: ColumnsType<NodeLifecycleType> = [
    {
      title: "Node Mac ID",
      dataIndex: "nodeMacId",
      responsive: ["sm"],
    },
    {
      title: "Node Type",
      render: (record: NodeLifecycleType) => {
        return getNodeSubTypeDisplayName(record?.nodeType as string, record?.nodeSubType as string);
      },
      responsive: ["sm"],
    },
    {
      title: "Event type",
      dataIndex: "eventType",
      responsive: ["sm"],
    },
    {
      title: "Comment",
      dataIndex: "comment",
      responsive: ["sm"],
    },
    {
      title: "Creation date",
      dataIndex: "changedAt",
      render: (text: string) => formatDateTime24h(text),
      responsive: ["sm"],
    },
    // {
    //   title: "Actions",
    //   render: (record: NodeLifecycleType) => <Button onClick={() => handleOnEdit(record)}>Edit</Button>,
    //   responsive: ["sm"],
    // },
    {
      title: "",
      render: (record: NodeLifecycleType) => {
        return (
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col span={12}>
              <b>Node ID</b>: {record.nodeMacId}
            </Col>
            {/* <Col span={12} style={{ textAlign: "right" }}>
              <Button onClick={() => handleOnEdit(record)}>Edit</Button>
            </Col> */}
            <Divider className="my-m" />
            <Col span={24}>
              <b>Event type</b>: {record.eventType}
            </Col>
            <Col span={24}>
              <b>Comment</b>: {record.comment}
            </Col>
            <Col span={24}>
              <b>Creation date</b>: {formatDateTime24h(record.changedAt)}
            </Col>
          </Row>
        );
      },
      responsive: ["xs"],
    },
  ];

  return (
    <Table
      columns={columnConfig}
      dataSource={data}
      rowKey="changedAt"
      scroll={{ x: true }}
      className={classNames({ "m-table": screen.mobileAndTabletOnly })}
    />
  );
};

export default NodeLifecycleEventTable;
