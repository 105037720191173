import React from "react";
import classnames from "classnames";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styles from "./styles.module.scss";

const ActionButton = (props: {
  type?: string;
  onClick?: any;
  className?: string;
  btnClassName?: string;
  customIcon?: React.ComponentElement<any, any>;
  "data-testid"?: string;
}): React.ReactElement => {
  const { type, onClick, customIcon, className, "data-testid": dataTestid, btnClassName } = props;
  let actionIcon = customIcon;
  if (type === "edit") {
    actionIcon = <EditOutlined className={classnames("text-primary", className)} />;
  } else if (type === "delete") {
    actionIcon = <DeleteOutlined className={classnames("text-primary", className)} />;
  }

  return (
    <button
      data-testid={dataTestid}
      type="button"
      className={classnames(styles.btnActionLink, btnClassName)}
      onClick={onClick}
    >
      {actionIcon}
    </button>
  );
};

export default ActionButton;
